import React from "react";
import { react2angular } from "react2angular";
import { Flex, Icon, Text, Link } from "@chakra-ui/react";
import { IoEllipsisVertical } from "react-icons/io5";
import { createLaunchPadConfig } from "@skylark/lib";
import { LaunchPad } from "@skylark/react";
import {
  SkylarkClassicIconLight,
  SkylarkClassicLegacyOutlineIcon,
} from "@skylark/icons";
import withChakraUiProvider from "hocs/with-chakra-ui-provider";
import withStoreProvider from "hocs/with-store-provider";
import useLocation from "hooks/use-location";
import useAngularInjection from "hooks/use-angular-injection";

/**
 * Header for the navigation panel
 */
const NavigationHeader = (props) => {
  const [, { setUrl }] = useLocation();
  const rootScope = useAngularInjection("$rootScope");

  const launchPadProps = createLaunchPadConfig({
    launcherUrl: __LAUNCHER_URL__,
    classicCmsOnClick: () => setUrl("/"),
  });

  const Header = () => (
    <Flex
      justifyContent="space-between"
      direction="row"
      alignItems="center"
      mt="4"
      mb="3"
    >
      <Flex
        direction="row"
        alignItems="center"
        pos="relative"
        className="navigation__skylark-logo-container"
      >
        {rootScope.rebrandStyling ? (
          <SkylarkClassicIconLight fontSize="55px" mr="3" />
        ) : (
          <SkylarkClassicLegacyOutlineIcon
            fill="white"
            fontSize="48px"
            mr="2"
          />
        )}
        <div>
          <Text
            lineHeight="20px"
            pr="7px"
            mb="4px"
            className="navigation__logo-text"
          >
            Skylark
          </Text>
          {props.withLaunchPad && (
            <Text
              textTransform="none"
              letterSpacing="0"
              pt="0"
              m="0"
              className="navigation__logo-subtitle"
            >
              Skylark Classic
            </Text>
          )}
        </div>
      </Flex>
      {props.withLaunchPad && (
        <Icon
          as={IoEllipsisVertical}
          fontSize="1.2em"
          data-test="header-chakra-icon"
        />
      )}
    </Flex>
  );

  return (
    <Flex
      alignItems="center"
      justifyContent="flex-start"
      h="100%"
      py="10px"
      px="20px"
      pr="23px"
    >
      {props.withLaunchPad ? (
        <LaunchPad {...launchPadProps}>
          <Header />
        </LaunchPad>
      ) : (
        <Link
          href={props.homeHref}
          _hover={{ textTransform: "none" }}
          _focus={{ border: "0" }}
        >
          <Header />
        </Link>
      )}
    </Flex>
  );
};

const reactNavigationHeader = angular
  .module("Skylark.Components.React.NavigationHeader", [])
  .component(
    "navigationHeader",
    react2angular(withChakraUiProvider(withStoreProvider(NavigationHeader)), [
      "homeHref",
      "withLaunchPad",
    ])
  );

export default NavigationHeader;

export { reactNavigationHeader };
