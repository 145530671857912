import React, { useMemo } from "react";
import { useEffectOnce } from "react-use";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import Dropdown from "components/_react/dropdown/dropdown.component";
import { fetchTagCategories } from "store/tag-categories/tag-categories.actions";
import { getTagCategories } from "store/tag-categories/tag-categories.selectors";
import { RootState } from "store/store.types";
import { TagCategory } from "store/tag-categories/tag-categories.types";
import { FilterPayload, GenericWidget } from "../search-and-filter.types";

interface CategoryFilterProps extends GenericWidget<FilterPayload> {
  category: {
    param: string;
  };
}

const defaultProps = {
  filter: {},
};

const CategoryFilter = ({
  filter,
  updateFilter,
  category,
}: CategoryFilterProps) => {
  const dispatch = useDispatch();
  const { param } = category;
  const tagCategories: Array<TagCategory> = useSelector((state: RootState) =>
    getTagCategories(state)
  );
  const defaultOption = {
    value: "*",
    label: "All",
  };

  const options = useMemo(() => {
    if (!_.isArray(tagCategories)) {
      return [defaultOption];
    }

    const mappedCategories = tagCategories.map(({ name, slug }) => ({
      value: slug,
      label: _.capitalize(name),
    }));

    return [defaultOption, ...mappedCategories];
  }, [tagCategories, defaultOption]);

  const value = useMemo(
    () => options.find(({ value }) => value === filter.value) || defaultOption,
    [filter, options, defaultOption]
  );

  // fetch the categories on mount
  useEffectOnce(() => {
    if (_.isEmpty(tagCategories)) {
      dispatch(fetchTagCategories());
    }
  });

  const onChangeAction = (option) => {
    updateFilter([
      {
        bucket: "property",
        name: param,
        payload: option,
      },
    ]);
  };

  return (
    <Dropdown
      ariaLabel="category filter"
      options={options}
      placeholder="Select Category"
      value={value}
      onChangeAction={onChangeAction}
    />
  );
};

CategoryFilter.defaultProps = defaultProps;

export default CategoryFilter;
