import angular from "angular";
import chosenRelationshipDirective from "./chosen-relationship.directive";
import selectRelationshipInput from "./select-relationship-input.directive";

const RelationshipDropdown = angular
  .module("Skylark.Components.Fields.RelationshipDropdown", [])
  .directive("chosenRelationship", chosenRelationshipDirective)
  .directive("selectRelationshipInput", selectRelationshipInput);

export default RelationshipDropdown;
