import angular from "angular";
import TranslationFactory from "./translation-factory/translation.factory";
import translationDirective from "./translation-module/translation-module.directive";

const Translation = angular
  .module("Skylark.Modules.Translation", [])
  .factory("TranslationFactory", TranslationFactory)
  .directive("translationModule", translationDirective);

export default Translation;
