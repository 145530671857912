import { connect } from "react-redux";
import { getConfigByEntity } from "store/entities-config/entities-config.selector";
import EditorControls from "./editor-controls";

const mapProps = (state) => ({
  configuration: getConfigByEntity(
    state,
    state.entity.entityName,
    state.entity.data.article_type_slug
  ).editor,
  editor: state.editor,
});

export default connect(mapProps)(EditorControls);
