import ConnectToStore from "hocs/connect-to-store/connect-to-store";

import { popErrorToast } from "store/toaster/toaster.actions";

import MediaPlaceholderBlock from "./media-placeholder-block";

const config = {
  props: [],
  actions: [{ popErrorToast }],
};

export default ConnectToStore(config, MediaPlaceholderBlock);
