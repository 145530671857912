/**
 * @param {object} BatchRequestFactory
 * @param {Object} _
 * @returns {{}}
 * @constructor
 */
function TranslationFactory(BatchRequestFactory, ApiUrlAdapterService, _) {
  const factory = {};

  /**
   * _getConfigForLanguageFilters
   * @param   {object} moduleConfig
   * @returns {string}
   */
  function _getConfigForLanguageFilters(moduleConfig) {
    const defaultFields = [
      "language_modified_by",
      "version_number",
      "modified_by",
      "uid",
      "title",
      "language",
    ];
    const fields =
      "fields_to_append" in moduleConfig ? moduleConfig.fields_to_append : [];

    return _.uniq(defaultFields.concat(fields)).join(",");
  }

  /**
   * get all the metadata versions for list of languages
   * @param {array} languages
   * @param {string} entityUrl
   * @param {object} moduleConfig
   * @returns {Promise}
   */
  factory.getLanguageVersions = (languages, entityUrl, moduleConfig) => {
    const url = ApiUrlAdapterService.replaceUrl(entityUrl);

    languages.forEach((language) => {
      BatchRequestFactory.addRequest({
        id: "language-version",
        url: `${url}language-versions/?fields=${_getConfigForLanguageFilters(
          moduleConfig
        )}`,
        method: "GET",
        headers: {
          "Accept-Language": language,
          "Cache-Control": "no-cache",
        },
      });
    });

    return BatchRequestFactory.process();
  };

  return factory;
}

export default TranslationFactory;
