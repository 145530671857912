export const globalParamsSet = {
  expired: "true",
  current: "true",
  future: "true",
};

const GLOBAL_PARAM_CONSTANTS = {
  defaultParams: {
    globalParamsUnset: {
      all: "true",
    },
    globalParamsSet,
  },
  channelNames: {
    globalLanguageChanged: "GlobalLanguage.changed",
  },
  cookieNames: {
    globalLanguage: "skylark.GlobalLanguage",
    globalFilters: "GlobalFilters",
  },
};

export default GLOBAL_PARAM_CONSTANTS;
