/**
 * Relationship factory. Handles functionality for adding and removing relationships
 * @constructor
 * @param {Object} $q
 * @param {Object} EntityFactory
 * @param {Object} GlobalParamsService
 * @param {Object} ModulePagingService
 * @returns {Object} - relationship factory
 */
function RelationshipFactory(
  $q,
  EntityFactory,
  GlobalParamsService,
  ModulePagingService
) {
  const factory = {};

  /**
   * Convert an array of schedules consisting of schedule objects to stings.
   * @param {Array.<Object>} items
   * @private
   */
  function _parseScheduleObjectsToStrings(items) {
    return items.map((entity) => ({
      ...entity,
      schedule_urls: entity.schedule_urls.map((schedule) => schedule.self),
    }));
  }

  /**
   * Create/'s the basic structure of the filters needed for the related entities
   * API call.
   * @param {string} parentUrl
   * @param {object} [paginationSettings]
   * @param {string} [fieldConfig].type
   * @param {string} [fieldConfig].filters.tabs.param
   * @returns {Promise}
   * @private
   */
  function _createRelatedEntitiesFilters(
    parentUrl,
    paginationSettings,
    fieldConfig
  ) {
    const filters = {
      ...GlobalParamsService.getDefaultParams(),
      ...(paginationSettings && paginationSettings),
      parent_url: parentUrl,
      fields_to_expand: ["schedule_urls,image_urls"],
    };

    if (fieldConfig) {
      if (fieldConfig.type) {
        filters[fieldConfig.filters.tabs.param] = fieldConfig.type;
      }

      if (fieldConfig?.name === "assets") {
        filters.fields_to_expand[0] = `${filters.fields_to_expand[0]},asset_type_url`;
      }
    }

    return filters;
  }

  /**
   * Save a set of data
   * @access public
   * @param {object} entity - the data set to save
   * @returns {Promise} - the api service deferred promise
   */
  factory.save = (entity) =>
    EntityFactory.patchByUrl(
      entity.self,
      entity,
      GlobalParamsService.getDefaultParams()
    );

  /**
   * Saves each individual item in the container. Needed when the relation need to be save on the
   * item rather than the parent container
   * @access public
   * @param {array} items - update items
   * @returns {Promise} - the api service deferred promise
   */
  factory.saveItems = (items) => {
    const parsedItems = items[0].schedule_urls
      ? _parseScheduleObjectsToStrings(items)
      : items;
    const requests = parsedItems.map((entity) =>
      factory.save({ self: entity.self, parent_url: entity.parent_url })
    );

    return $q.all(requests);
  };

  /**
   * Gets all of the entities related to this entity
   * @access public
   * @param {Array} relationshipUrls - an array of the available urls
   * @param {Object} paginationSettings - pagination settings
   * @returns {Promise} - deferred promise
   */
  factory.getRelatedEntities = (relationshipUrls, paginationSettings) => {
    const paginatedRelationshipUrls = ModulePagingService.urlsToGet(
      relationshipUrls,
      paginationSettings.start,
      paginationSettings.limit
    );

    const requests = paginatedRelationshipUrls.map((url) =>
      EntityFactory.getByUrl(url)
    );

    return $q.all(requests);
  };

  /**
   * Uses a parent url to get entites. Used by modules on the content tab
   * @param {string} entityType
   * @param {string} parentUrl
   * @param {object} paginationSettings
   * @param {object} fieldConfig
   * @returns {Promise|*|Object}
   *
   * @todo The fields_to_expand param has been used in multiple places
   * throughout the cms, so Adding this as a global variable would be
   * a good idea.
   */
  factory.getRelatedEntitiesByParent = (
    entityType,
    parentUrl,
    paginationSettings,
    fieldConfig
  ) => {
    const typesWithOrder = ["episodes", "seasons"];
    const filters = _createRelatedEntitiesFilters(
      parentUrl,
      paginationSettings,
      fieldConfig
    );
    const singularName = entityType.slice(0, -1);

    if (typesWithOrder.indexOf(entityType) !== -1) {
      filters.order = `${singularName}_number`;
    }

    return EntityFactory.getAll(entityType, filters);
  };

  /**
   * Uses a parent url to get the count of the entities.
   * Used by modules on the content tab
   * @param {string} entityType
   * @param {string} parentUrl
   * @param {string} [fieldConfig].type
   * @param {string} [fieldConfig].filters.tabs.param
   * @returns {Promise}
   */
  factory.getCountOfRelatedEntitiesByParent = (
    entityType,
    parentUrl,
    fieldConfig
  ) => {
    const filters = _createRelatedEntitiesFilters(parentUrl, null, fieldConfig);

    return EntityFactory.getCount(entityType, filters);
  };

  return factory;
}

export default RelationshipFactory;
