import { merge } from "lodash";

import { Dimensions } from "skylarklib/helpers";
import API_REQUEST_CONSTANTS from "./api-request-config.constants";

/**
 * _getAllParam
 * @todo rectify hardcoded limit
 * @return {Object} params
 */
function _getAllParam() {
  return {
    params: { ...API_REQUEST_CONSTANTS.globalParamsAll },
  };
}

/**
 * _getFilterParams
 * @param  {Object} filters
 * @return {Object}
 */
function _getFilterParams(filters) {
  return {
    params: { ...filters },
  };
}

/**
 * _getMultipartType
 * @return {Object} headers with multipart content type
 */
function _getMultipartType() {
  return {
    headers: {
      "content-type": "multipart/form-data",
    },
  };
}

/**
 * _isGlobalParamsSet
 * @param  {Object} options
 * @returns {Boolean}
 */
function _isGlobalParamsSet(filters) {
  return (
    filters &&
    API_REQUEST_CONSTANTS.globalParamsTypes.some((type) => filters[type])
  );
}

/**
 * _getGlobalParams
 * Adds the schedule filters in the API request. Additionally, we
 * check if the user has passed custom globalParamsSet (current,
 * future, expired) and we make sure not to overwrite them otherwise
 * we will add them to the request.
 * @return {Object}
 */
function _getGlobalParams(filters) {
  const dimensionCookie = Dimensions.getGlobalFilterCookie();
  const hasGlobalParams = _isGlobalParamsSet(filters);
  const globalParams = dimensionCookie
    ? API_REQUEST_CONSTANTS.globalParamsSet
    : API_REQUEST_CONSTANTS.globalParamsAll;

  return {
    params: {
      ...dimensionCookie,
      ...(!hasGlobalParams && { ...globalParams }),
    },
  };
}

/**
 * buildRequestConfig
 * @param  {Object} options
 * @returns {Object}
 */
function build(options = {}) {
  const { all, filters, multipart, useWildCard, useGlobalParams } = options;

  return merge(
    {},
    all ? _getAllParam() : {},
    filters ? _getFilterParams(filters) : {},
    multipart ? _getMultipartType() : {},
    useGlobalParams ? _getGlobalParams(filters) : {},
    useWildCard ? { useWildCard } : {}
  );
}

export { build };
