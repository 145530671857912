import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";
import * as MediaHelpers from "components/_react/editor/helpers/media-helpers/media-helpers";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { DropdownArticleObjects } from "components/_react/fields/dropdowns/dropdowns";

import styles from "./media-placeholder-block.scss";

/**
 * Image Block Component for rendering images in the editor
 * @param {Object} props
 */
export default class MediaPlaceholderBlock extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    block: PropTypes.shape({
      getKey: PropTypes.func,
      getLength: PropTypes.func,
    }).isRequired,
    blockProps: PropTypes.shape({
      renderMedia: PropTypes.func,
      replaceMedia: PropTypes.func,
      onModalSave: PropTypes.func,
    }).isRequired,
    popErrorToast: PropTypes.func.isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.replaceWithImage = this.replaceWithImage.bind(this);

    this.setWrapperRef = (ref) => (this.wrapperRef = ref);
  }

  /**
   * state
   * @type {Object}
   */
  state = {
    isDragActive: false,
  };

  /**
   * componentDidMount
   */
  componentDidMount() {
    this.dragTargets = [];
  }

  /**
   * componentWillReceiveProps
   * @param  {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this._setHasFocus(nextProps);
  }

  /**
   * Handle when dragged file enters placeholder bounds
   * @param  {Event} event
   */
  onDragEnter(event) {
    this._preventBrowserBehaviour(event);

    if (!this.dragTargets.includes(event.target)) {
      this.dragTargets.push(event.target);
    }

    this.setState({ isDragActive: true });
  }

  /**
   * handle when dragged file leaves placeholder bounds
   * @param  {Event} - event
   */
  onDragLeave(event) {
    this._preventBrowserBehaviour(event);
    this.dragTargets = this._hasDragTarget(event);

    if (this.dragTargets.length) {
      return;
    }

    this.disableDragState();
  }

  /**
   * Handle when the user drops a file on the placeholder.
   * @param  {Event} - event
   */
  onFileSelect(event) {
    this.dragTargets = [];
    this._preventBrowserBehaviour(event);
    this.disableDragState();

    const imageConfig = this._getImageConfig(event);

    if (!MediaHelpers.isFileSupported(imageConfig.file)) {
      return this.props.popErrorToast(
        `image - filetype '${imageConfig.file.type}' is unsupported`,
        "create"
      );
    }

    return this.replaceWithImage("image", imageConfig);
  }

  /**
   * disable drag state of placeholder to its default
   */
  disableDragState() {
    this.setState({ isDragActive: false });
  }

  /**
   * get files dragged onto placeholder.
   * @param  {Event} - event
   * @return {Array} - array of dragged files
   */
  _getImageConfig(event) {
    let file;
    const data = event.dataTransfer;

    if (data && data.files && data.files.length) {
      file = data.files[0];
    } else if (event.target && event.target.files) {
      file = event.target.files[0];
    }

    return MediaHelpers.createImageData(file);
  }

  /**
   * _setHasFocus
   * @param {Objedt} props
   */
  _setHasFocus(props) {
    this.setState({ hasFocus: this._isSelected(props) });
  }

  /**
   * _preventBrowserBehaviour
   * @param  {Event} event
   */
  _preventBrowserBehaviour(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /**
   * _isSelected
   * @param  {Object} props
   * @return {Boolean}
   */
  _isSelected(props) {
    return props.block.getKey() === props.selection.getAnchorKey();
  }

  /**
   * _hasDragTarget
   * @param  {Event} event
   * @return {Array}
   */
  _hasDragTarget(event) {
    return this.dragTargets.filter(
      (element) => element !== event.target && this.wrapperRef.contains(element)
    );
  }

  /**
   * _toggleDropView
   */
  _toggleDropView() {
    this.setState({ dropVisible: !this.state.dropVisible });
  }

  /**
   * replaceWithImage
   * @param   {String} type
   * @param   {Object} imageConfig
   * @returns {Function}
   */
  replaceWithImage(type, imageConfig) {
    return this.props.blockProps.replaceMedia(
      DraftHelpers.createBlockSelection(
        this.props.block.getKey(),
        0,
        this.props.block.getLength()
      ),
      this.props.block,
      imageConfig,
      type
    );
  }

  /**
   * render
   * We need en empty paragraph block to be able to handle keybindings correctly
   * for placeholders, everything else needs to be contentEditable false,
   * see p tag with class of 'media-placeholder__selectable'
   * @returns {element}
   */
  render() {
    return (
      <div>
        <div
          className={styles["media-placeholder"]}
          onDragEnter={this.onDragEnter}
          onDragOver={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          onDrop={this.onFileSelect}
          ref={this.setWrapperRef}
          suppressContentEditableWarning="true"
          contentEditable="false"
        >
          <div
            className={classnames(
              styles["media-placeholder__block"],
              {
                [styles["media-placeholder__block--drag-active"]]:
                  this.state.isDragActive,
              },
              {
                [styles["media-placeholder__block--has-focus"]]:
                  this.state.hasFocus,
              }
            )}
          >
            <CSSTransition
              timeout={10}
              in={this.state.isDragActive}
              unmountOnExit
              onEntered={() => this._toggleDropView()}
              onExiting={() => this._toggleDropView()}
              classNames={{
                enter: styles["media-placeholder__drop--enter"],
                enterActive: styles["media-placeholder__drop--enter-active"],
                exit: styles["media-placeholder__drop--exit"],
                exitActive: styles["media-placeholder__drop--exit-active"],
              }}
            >
              <div
                className={classnames(styles["media-placeholder__drop"], {
                  [styles["media-placeholder__drop--visible"]]:
                    this.state.dropVisible,
                })}
              >
                <div className={styles["media-placeholder__drop-text"]}>
                  Drop File Here
                </div>
              </div>
            </CSSTransition>
            <SkylarkIcon
              iconName="image"
              extraClassName={styles["media-placeholder__icon"]}
            />
            <p
              className={styles["media-placeholder__caption"]}
              suppressContentEditableWarning="true"
              contentEditable="false"
            >
              Drag and drop an image here or
            </p>
            <div className={styles["media-placeholder__dropdown"]}>
              <DropdownArticleObjects
                renderMedia={this.replaceWithImage}
                isDisabled={false}
                copy="Place Object"
                block={this.props.block}
                onModalSave={this.props.blockProps.onModalSave}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
