/**
 * @ngInject
 */
function UiHelpersService(
  $http,
  $q,
  $templateCache,
  _,
  APP_SETTINGS,
  UtilitiesService,
  ApiService
) {
  const service = {};

  /**
   * Return a positioning object containing pixel values for offset top and left
   *
   * @param {String} template The $templateCache URI of the template to be loaded.
   * @returns {Object} An promise object containing the template
   */
  service.loadTemplate = function (template) {
    if (!template) {
      return "";
    }

    return $templateCache.get(template) || $http.get(template, { cache: true });
  };

  service.get = (pk) => {
    const deferred = $q.defer();
    let url;

    /*
     * Checks to see if url has query
     * string before appending final /
     */
    if (pk.indexOf("?") > -1) {
      url = `/api/${pk}`;
    } else {
      url = `/api/${pk}/`;
    }

    $http
      .get(url, ApiService.getRequestConfig({}))
      .success((data) => {
        deferred.resolve(data);
      })
      .error((err, status) => {
        deferred.reject(err, status);
      });

    return deferred.promise;
  };

  /**
   * Takes and object and a key and returns an array of objects
   * with a title value and the value set the value of identifier in config
   * @param {Object} - A Config object to enumerate
   * @param {String} - A key to lookup in the passed object
   * @returns {Array}
   */
  service.getColumns = function (config, identifiers) {
    const columns = [];

    Object.keys(config).forEach((key) => {
      const item = {};
      item.title =
        identifiers.title in config[key] ? config[key][identifiers.title] : "";
      item.centered =
        identifiers.centered in config[key]
          ? config[key][identifiers.centered]
          : "";
      columns.push(item);
    });

    return columns;
  };

  service.getRow = function (obj, fields) {
    const row = [];
    // loop over the list config fields
    _.forEach(fields, (field) => {
      const column = {};

      // loop over inner object
      for (const key in field) {
        /*
         * Merge config and raw data
         * ---------------------------------------------------
         * use the config field's items to query the raw data:
         * if the value exists in the data, return that against the config field,
         * else return the config's field
         */

        column[key] = key in obj ? obj[key] : field[key];

        /*
         * tack on SourceDataUrl for objs that
         * need to load additional resources
         */
        if ("sourceDataUrl" in field) {
          column.sourceDataUrl = UtilitiesService.resolveAttribute(
            field.sourceDataUrl,
            obj
          );
        }
      }

      row.push(column);
    });

    return row;
  };

  /**
   * Takes an array of data and a config object and returns an array of objects
   * dictated by passed configuration returning a row of widgetised blocks ready to be
   * stamped into the UI
   * @param {Object} - A raw data API collection of objects
   * @param {Object} - A Config object to enumerate
   * @returns {Array}
   */
  service.getRows = function (data, config) {
    return data.map((item) => {
      const row = [];
      // loop over the list config fields
      for (const key in config) {
        const column = {};

        // a reference to the current config field
        const configValue = config[key];

        // loop over inner object
        for (const innerKey in configValue) {
          /*
           * Merge config and raw data
           * ---------------------------------------------------
           * use the config field's items to query the raw data:
           * if the value exists in the data, return that against the config field,
           * else return the config's field
           */

          column[innerKey] =
            innerKey in item ? item[innerKey] : configValue[innerKey];

          /*
           * tack on SourceDataUrl for items that
           * need to load additional resources
           */
          if ("sourceDataUrl" in configValue) {
            column.sourceDataUrl = UtilitiesService.resolveAttribute(
              configValue.sourceDataUrl,
              item
            );
          }
        }

        row.push(column);
      }

      return row;
    }, this);
  };

  return service;
}

export default UiHelpersService;
