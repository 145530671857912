import template from "./modal-localisation-navigation.html";

/**
 * ModalLocalisationNavigation
 * @file Renders Localisation Navigation modal.
 */
/**
 * @memberof  Components.Modal
 * @classdesc Renders localisation navigation modal.
 */
class ModalLocalisationNavigation {
  /**
   * @constructor
   * @param   {Object} MessageService
   * @param   {Object} _ lodash
   */
  constructor(MessageService, _) {
    this.MessageService = MessageService;
    this._ = _;
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this.counts = {};
    this.context = "localnav";
    this.initialDimensions = angular.copy(this.options.dimensions, []);
    this.dimensions = this.options.dimensions;
    this.config.dimensions.forEach((dimension) =>
      this._assignDimensions(dimension)
    );
    this.currentTab = this.config.dimensions[0].name;

    this.initialData = angular.copy(this.data, {});

    this.MessageService.publish("Modal.LocalisationNavigation.Filters.Toggle");

    if (!this.data.amountSelected) {
      this._initialFilterCount(this.initialData);
    }
  }

  /**
   * setCurrentTab
   * @param {string} tab
   * @returns {void}
   */
  setCurrentTab(tab) {
    this.currentTab = tab;
  }

  /**
   * _initialFilterCount - sets correct initial data when it doesn't alreay exist.
   * @param {Object} initialData - an object with initial filter parameters
   * @returns {void}
   */
  _initialFilterCount(initialData) {
    this.data.amountSelected = 0;
    for (const dimension in initialData) {
      if (Object.prototype.hasOwnProperty.call(initialData, dimension)) {
        this.data.amountSelected += initialData[dimension].length;
      }
    }
  }

  /**
   * _assignDimensions
   * @description  sets available dimensions being passed from parent
   * and generates arrays of allowed options for a given dimension for the logged in user
   * @param {object} dimension - dimension name for endpoint
   * @param {array} userDimensions - array of allowed dimensions for a specific user
   * @returns {void}
   * @deprecated
   */
  _assignDimensions(dimension) {
    this[dimension.name] = this.dimensions[dimension.name];
    this.counts[dimension.name] = this.data[dimension.name]
      ? this.data[dimension.name].length
      : 0;
  }

  /**
   * _filterSelected - build filtered lists of names
   * @deprecated
   * @returns {void}
   */
  _filterSelectedNames() {
    this.data.filterNames = {};
    this.config.dimensions.forEach((dimension) => {
      this.data.filterNames[dimension.name] = this.data[dimension.name].map(
        (currentDimension) => this._findName(dimension.name, currentDimension)
      );
    });
  }

  /**
   * _findName
   * @private
   * @param   {string} dimensionName
   * @param   {string} slug - the slug we are iterating over
   * @returns {string} the option name
   */
  _findName(dimensionName, slug) {
    return this[dimensionName].find((option) => option.slug === slug).name;
  }

  /**
   * save
   * @returns {void}
   * // @todo send amount selected
   */
  save() {
    this._filterSelectedNames();
    this.data.amountSelected = this._calculateAmountSelected();
    this.MessageService.publish(this.options.channels.save, this.data);
    this.destroyModal();
  }

  /**
   * _calculateTotalAmountSelected
   * @returns {Number} the total amount of selected checkboxes for comparison in checkbox
   */
  _calculateAmountSelected() {
    return this._.sum(this._.values(this.counts));
  }

  /**
   * _resetFilters
   * @returns {void}
   */
  _resetFilters() {
    this.data = angular.copy(this.initialData, this.data);
    this.dimensions = angular.copy(this.initialDimensions);

    this.currentTab = null;
  }

  /**
   * cancel
   * @returns {void}
   */
  cancel() {
    this._resetFilters();
    this.destroyModal();
  }
}

/**
 * modalLocalisationNavigation
 * @returns {Object} DDO
 */
function modalLocalisationNavigation() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      data: "=",
      config: "=",
      options: "=",
      destroyModal: "&",
    },
    controller: ModalLocalisationNavigation,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default modalLocalisationNavigation;
