import Api from "skylarklib/helpers/api/api";
import * as ApiRequestConfig from "skylarklib/helpers/api-request-config/api-request-config";
import * as Pagination from "skylarklib/helpers/pagination/pagination";

import { CORE_ENTITIES } from "skylarklib/constants/core-entities";

/**
 * @classdesc EntityFactory
 * A wrapper around Api to interface with the API
 * in a standard manner. Prefer using this Factory as opposed
 * to the lower level Api.
 */
export default class Entity {
  /**
   * create
   * @static
   * @param    {String} entity
   * @param    {Object} data
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static create(entity, data) {
    return Api.post(`/api/${entity}/`, data, ApiRequestConfig.build());
  }

  /**
   * get (GET)
   * @static
   * @param    {String} entity
   * @param    {String} uid
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static get(entity, uid) {
    return Api.get(
      `/api/${entity}/${uid}/`,
      ApiRequestConfig.build({
        useWildcard: true,
      })
    );
  }

  /**
   * getAll (GET)
   * @static
   * @param    {String} entity
   * @param    {Object} [requestOptions={}]
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static getAll(entity, requestOptions = {}) {
    return Api.get(
      `/api/${entity}/`,
      ApiRequestConfig.build({
        useWildcard: true,
        ...requestOptions,
        useGlobalParams: true,
      })
    );
  }

  /**
   * getItems (GET)
   * @static
   * @param    {String} entity
   * @param    {String} uid
   * @param    {Object} [filters={}]
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static getItems(entity, uid, filters = {}) {
    return Api.get(
      `/api/${entity}/${uid}/items/`,
      ApiRequestConfig.build({
        useWildcard: true,
        filters,
        useGlobalParams: true,
      })
    );
  }

  /**
   * getCount (GET)
   * @static
   * @param    {String}  entity
   * @param    {Object}  [requestOptions={}]
   * @param    {string}  [isSearching=false]
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static getCount(entity, requestOptions = {}, isSearching = false) {
    const options = isSearching
      ? Pagination.buildCountSearchParam(requestOptions)
      : {};
    const filterOptions = isSearching ? options : requestOptions;

    return Api.get(
      `/api/${entity}/count/`,
      ApiRequestConfig.build({
        ...filterOptions,
        useGlobalParams: true,
      })
    );
  }

  /**
   * adds item to an entity (POST)
   * @static
   * @param    {String}  entity
   * @param    {String}  uid
   * @param    {Object}  body
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static addItem(entity, uid, body) {
    return Api.post(
      `/api/${entity}/${uid}/items/`,
      body,
      ApiRequestConfig.build()
    );
  }

  /**
   * update (PATCH)
   * @static
   * @param    {String} entity
   * @param    {String} uid
   * @param    {Object} data
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static update(entity, uid, data) {
    return Api.patch(`/api/${entity}/${uid}/`, data, ApiRequestConfig.build());
  }

  /**
   * replace (PUT)
   * @static
   * @param    {String} entity
   * @param    {String} uid
   * @param    {Object} data
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static replace(entity, uid, data) {
    return Api.put(`/api/${entity}/${uid}/`, data, ApiRequestConfig.build());
  }

  /**
   * delete (DELETE)
   * @static
   * @param    {String} entity
   * @param    {String} uid
   * @returns  {Promise}
   * @memberof EntityFactory
   */
  static delete(entity, uid) {
    return Api.delete(`/api/${entity}/${uid}/`);
  }

  /**
   * Checks if the entity is in the list of core entities
   * @static
   * @param    {string} entityType
   * @returns  {boolean}
   * @memberof EntityFactory
   */
  static isCoreEntity(entityType) {
    return !!CORE_ENTITIES.find((entity) => entity === entityType);
  }

  /**
   * Does current page have entity data
   * @return {Boolean}
   */
  static hasEntityData(entity) {
    return entity && Object.keys(entity).length > 0;
  }
}
