import { getActiveAccounts } from "store/selectors/filter-accounts-with-upload";

class EntityVersionsService {
  /**
   * Making entity-versions
   * functions reusable again
   * @constructor
   * @param {Object} $q
   * @param {Object} _
   * @param {Object} GlobalParamsService
   * @param {Object} EntityFactory
   * @param {Object} ConfigurationFactory
   *
   */
  constructor($q, _, GlobalParamsService, EntityFactory, ConfigurationFactory) {
    this.$q = $q;
    this._ = _;
    this.GlobalParamsService = GlobalParamsService;
    this.EntityFactory = EntityFactory;
    this.ConfigurationFactory = ConfigurationFactory;
  }

  /**
   * loads all accounts
   *
   * @return {Promise}
   */
  getAccounts() {
    return this.EntityFactory.getAll("accounts");
  }

  /**
   * filtering ovps by creation type ingest
   *
   * @param {Array} accountsWithUpload
   * @param versions
   * @return {Array} - same structure as it comes in but filtered
   */
  filterIngestedVersions(accountsWithUpload, versions) {
    if (Array.isArray(versions)) {
      return versions.filter(
        (version) =>
          !accountsWithUpload.some(
            (account) => account.self === version.account_url
          )
      );
    }

    return [];
  }

  /**
   * filter accounts which create assets by upload
   *
   * @param {Array} accounts
   * @param {Object} config
   * @return {Array}
   */
  filterAccountsWithUpload(accounts, config) {
    return getActiveAccounts(accounts, config);
  }

  /**
   * get a version from the ovps by passing account in
   * @param {Object} data
   * @param {Object} account
   * @return {Object|undefined} version
   */
  getVersionByAccount(data, account) {
    const ovps = this._.get(data, "ovps");

    return (
      Array.isArray(ovps) &&
      ovps.find((version) => account.self === version.account_url)
    );
  }

  /**
   * reloading the ovps from entity, used as an callback when upload is complete
   * @param {String} entityName
   * @param {String} entityId
   *
   * @returns {promise}
   */
  refreshOvpsAndImages(entityName, entityId) {
    return this.EntityFactory.get(entityName, entityId, {
      fields: "ovps,image_urls",
      fields_to_expand: "image_urls",
    });
  }

  /**
   * delete ingested ovp version of entity
   * @param {string} uid
   * @param {array} ovps
   * @param version
   */
  deleteVersion(uid, ovps, version) {
    if (!Array.isArray(ovps)) {
      return;
    }

    const existingOvps = ovps.filter((ovp) => !this._.isEqual(ovp, version));

    return this.EntityFactory.patch("assets", {
      uid,
      ovps: existingOvps,
    }).then((entityData) => {
      if (this._.isEqual(entityData.ovps, ovps)) {
        return this.$q.reject();
      }

      return entityData;
    });
  }
}

export default EntityVersionsService;
