/**
 * CustomersFactory
 * @param {Object} ApiService
 * @returns {Object} factory
 */
function CustomersFactory(ApiService) {
  const factory = {};

  /**
   * @returns {Promise}
   */
  factory.getCurrentCustomer = () => ApiService.get("/api/customers/me/");

  /**
   * @returns {Promise}
   */
  factory.getCustomerList = () => ApiService.get("/api/customers/");

  return factory;
}

export default CustomersFactory;
