import template from "./modal-settings.html";

/**
 * @file A simpler version of the edit modal.
 */

/**
 * @memberOf  Components.Modal
 * @classdesc Renders a non-tabbed modal using
 * configurable fields with two methods only:
 * save or cancel.
 * Expects an options object with the following format: { channels: { save: string } }
 */
class ModalSettingsCtrl {
  /**
   * @constructor
   * @param   {object} MessageService
   */
  constructor(MessageService) {
    this.MessageService = MessageService;
    this.initialData = { ...this.data };
  }

  /**
   * save
   * @returns {void}
   */
  save() {
    this.MessageService.publish(this.options.channels.save, this.data);
    this.destroyModal();
  }

  /**
   * cancel
   * @returns {void}
   * merge will allow initial values to take precedence and modify the object in place
   */
  cancel() {
    Object.assign(this.data, this.initialData);
    this.destroyModal();
  }
}

/**
 * simpleModalDirective
 * @returns {object} - directive definition object
 */
function modalSettingsDirective() {
  return {
    restrict: "E",
    bindToController: {
      data: "=",
      config: "=",
      options: "=",
      instanceUid: "@",
      destroyModal: "&",
    },
    scope: {},
    controller: ModalSettingsCtrl,
    controllerAs: "module",
    template,
    link: () => {},
  };
}

export default modalSettingsDirective;
