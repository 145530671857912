import { zipObject, map, keys, values } from "lodash";
import Cookie from "skylarklib/helpers/cookies/cookies";
import { COOKIE_NAMES } from "skylarklib/constants/global.constants";

/**
 * slugifyKeys
 * @param {string} key
 * @return {String}
 */
function slugifyKeys(key) {
  return key.replace("_", "-");
}

/**
 * slugifyFilterCookie
 * @return {Object}
 */
function slugifyFilterCookie() {
  const dimensionsCookie = this.getGlobalFilterCookie();

  return zipObject(
    map(keys(dimensionsCookie), slugifyKeys),
    values(dimensionsCookie)
  );
}

/**
 * getGlobalFilterCookie
 * @return {Object}
 */
function getGlobalFilterCookie() {
  return Cookie.get(COOKIE_NAMES.GLOBAL_FILTERS);
}

/**
 * setGlobal
 * @param {string} language
 */
function setGlobalFilterCookie(dimensions) {
  Cookie.set(COOKIE_NAMES.GLOBAL_FILTERS, dimensions, {
    path: "/",
  });
}

export { slugifyFilterCookie, getGlobalFilterCookie, setGlobalFilterCookie };
