import * as OvpAssetUploadSelector from "store/ovp-asset-upload/ovp-asset-upload.selector";
import NotificationMessage from "skylarklib/constants/notification-text";
import * as TextTrackSelector from "store/text-track-upload/text-track-upload.selector";
import * as TextTrackUploadAction from "store/text-track-upload/text-track-upload.action";
import template from "./ovp-asset-block.html";

class OvpAssetBlockController {
  /**
   *
   * @param $element
   * @param $scope
   * @param $ngRedux,
   * @param _
   * @param ReduxConnector
   * @param MessageService
   * @param ModalTriggerService
   * @param NotificationService
   * @param OvpAssetUploadService
   * @param OvpAssetUploadStoreService
   * @param OvpAssetDownloadService
   * @param OVP_ASSET_UPLOAD
   */
  constructor(
    $element,
    $scope,
    $ngRedux,
    _,
    ReduxConnector,
    MessageService,
    ModalTriggerService,
    NotificationService,
    OvpAssetUploadService,
    OvpAssetUploadStoreService,
    OvpAssetDownloadService,
    OVP_ASSET_UPLOAD,
    TextTrackUploadService
  ) {
    this.$element = $element;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.ReduxConnector = ReduxConnector;
    this._ = _;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.NotificationService = NotificationService;
    this.OvpAssetUploadService = OvpAssetUploadService;
    this.OvpAssetUploadStoreService = OvpAssetUploadStoreService;
    this.OvpAssetDownloadService = OvpAssetDownloadService;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;
    this.TextTrackUploadService = TextTrackUploadService;

    this.ovpAsset = {};
    this.isWorkflowService = this.account.name.startsWith("workflow-service-");
    this.connectToStore();

    this.deleteVersion = this.deleteVersion.bind(this);
    this.uploadComplete = this.uploadComplete.bind(this);

    this.selectedVideo = undefined;
  }

  /**
   * initialize component
   * @returns {void}
   */
  $onInit() {
    this.setSubscriptions();

    // Recovering pending uploads is not supported for workflow-services
    if (
      this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.PROVIDERS.includes(
        this.account.slug
      )
    ) {
      this.OvpAssetUploadService.recoverWorkflowServiceUpload(
        this.entityId,
        this.account
      );
    } else {
      this.OvpAssetUploadService.recoverPendingUpload({
        entityId: this.entityId,
        accountUrl: this.account.self,
      }).catch((error) => this.NotificationService.notifyError(error));
    }
  }

  /**
   * on scope changes
   */
  $onChanges() {
    if (this.entity) {
      this.entityId = this.entity.uid;

      /*
       * mapStateToThis is not updating when component state changes,
       * need to trigger it programmatically
       */
      this.mapStateToThis(this.$ngRedux.getState());
    }
  }

  /**
   * subscribe to upload channels
   * @returns {void}
   */
  setSubscriptions() {
    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
      this.uploadComplete
    );
    this.MessageService.subscribe(
      `${this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.DELETE_ASSET}.${this.entityId}`,
      this.deleteVersion
    );
  }

  /**
   * remove subscriptions
   * @returns {void}
   */
  removeSubscriptions() {
    this.MessageService.off(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
      this.uploadComplete
    );
    this.MessageService.off(
      `${this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.DELETE_ASSET}.${this.entityId}`,
      this.deleteVersion
    );
  }

  /**
   * setSelectedFile
   * @param {*} { file, url, filename } Either the file to upload or the URL to upload from
   */
  setSelectedFile({ file, url, filename }) {
    this.selectedVideo = {
      file,
      url,
      filename,
    };
  }

  /**
   * prepareAndUploadSelectedFile
   * triggers the uploading of the file or URL
   */
  prepareAndUploadSelectedFile() {
    return this.prepareAndUpload(this.selectedVideo);
  }

  /**
   * There are some scenario in which
   * onBeforeUpload needs to be called if defined
   * like when you need to create an assets
   * before actually doing the upload
   *
   * It executes the onBeforeUpload and
   * binds all the properties from data
   * to `this`
   *
   * onBeforeUpload so executes a fn(locals)
   * as a reference. If not defined, it'll
   * return a no-op fn
   * @param {Object}
   * @returns {Promise}
   */
  prepareAndUpload({ file, url, filename }) {
    if (file) {
      return this.onBeforeUpload({ filename }).then((data) => {
        this._.extend(this, data);

        this.uploadFile(file);
        this.selectedVideo = undefined;
      });
    }

    if (url) {
      return this.onBeforeUpload({ filename }).then((data) => {
        this._.extend(this, data);

        this.uploadUrl(url);
        this.selectedVideo = undefined;
      });
    }
  }

  /**
   * kick off the upload
   * needs file, entityId and accountUrl to be set
   * @param {File} file
   * @returns {Promise}
   */
  uploadFile(file) {
    return this.OvpAssetUploadService.upload(file, {
      entityId: this.entityId,
      provider: this.account.slug,
      accountUrl: this.account.self,
    }).catch((error) => this.onUploadError(error));
  }

  /**
   *
   * @param url
   * @return {Promise<void>}
   */
  uploadUrl(url) {
    return this.OvpAssetUploadService.uploadFileFromUrl({
      url,
      entityId: this.entityId,
      provider: this.account.slug,
      accountUrl: this.account.self,
    }).catch((error) => this.onUploadError(error));
  }

  /**
   * Error handling in case the upload is failing
   * @param {Error|String} error
   * @returns {void}
   */
  onUploadError(error) {
    this.NotificationService.notifyStickyError(error);
  }

  /**
   * text for drag and drop
   * @return {string}
   */
  getDragDropText() {
    if (this.ovpAssetType === "video") {
      return "Drag and drop a video file here";
    }
    if (this.ovpAssetType === "audio") {
      return "Drag and drop an audio file here";
    }
    return "Drag and drop a file here";
  }

  /**
   * Message handler for triggering onUploadComplete, the function which can be passed as a binding
   * @param {string} channel
   * @param {string} accountUrl
   * @param {string} entityId
   */
  uploadComplete(channel, { accountUrl, entityId }) {
    if (
      this.isMessageForThisEntity(entityId, accountUrl) &&
      this._.isFunction(this.onUploadComplete)
    ) {
      this.onUploadComplete();
    }
  }

  /**
   * checks if the component is ready for a new upload
   * @returns {boolean}
   */
  isReadyForNewUpload() {
    return [
      undefined,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
    ].includes(this.ovpAsset.status);
  }

  /**
   * checks status of video to determine if upload is in progress
   * @returns {boolean}
   */
  isUploadInProgress() {
    return [
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
    ].includes(this.ovpAsset.status);
  }

  /**
   * checks status of video to determine if upload can have retry
   * @returns {boolean}
   */
  isRetryPossible() {
    return (
      this.ovpAsset.status ===
        this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3 &&
      this.ovpAsset.file instanceof File
    );
  }

  /**
   * checks status of video to determine if upload to s3 is in progress
   * @returns {boolean}
   */
  isUploadingToBucket() {
    return [
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
    ].includes(this.ovpAsset.status);
  }

  /**
   * checks status of video to determine if video is ingesting
   * @returns {boolean}
   */
  isIngesting() {
    return (
      this.ovpAsset.status ===
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED
    );
  }

  /**
   * checks status of video to determine if video can be cancelled
   * @returns {boolean}
   */
  isCancelable() {
    return [
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
    ].includes(this.ovpAsset.status);
  }

  /**
   * checks status of video to determine the ingest has errored
   * @returns {boolean}
   */
  isErrored() {
    return (
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED ===
      this.ovpAsset.status
    );
  }

  /**
   * video upload service messages needs filtering if these message relevant for the current visible entity
   *
   * @param entityId
   * @param accountUrl
   * @returns {boolean}
   */
  isMessageForThisEntity(entityId, accountUrl) {
    return entityId === this.entityId && accountUrl === this.account.self;
  }

  /**
   * Cancels upload
   * on success will reset values so a new upload can start
   * on error will show a notification with the error
   * @returns {void}
   */
  cancelUpload() {
    const { pelicanId, entityId, accountUrl } = this.ovpAsset;

    if (this.isRetryPossible()) {
      this.OvpAssetUploadService.cancelStoppedUpload(
        pelicanId,
        entityId,
        accountUrl
      );
    } else {
      this.MessageService.publish(
        this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD,
        { pelicanId, entityId, accountUrl }
      );
    }
  }

  /**
   * is trying to upload the file which failed
   * @returns {void}
   */
  retryUpload() {
    const { file, pelicanId, entityId, accountUrl, provider } = this.ovpAsset;
    this.OvpAssetUploadService.upload(file, {
      pelicanId,
      entityId,
      accountUrl,
      provider,
    }).catch((error) => this.onUploadError(error));
  }

  /**
   * Checks if in the ovps the s3_backup_path is defined or not
   *
   * @return {boolean}
   */
  isDownloadable() {
    if (!this.ovp) {
      return false;
    }

    return (
      this._.isString(this.ovp.s3_backup_path) ||
      this._.isString(this.ovp.external_s3_path)
    );
  }

  /**
   * Triggers a request to download the original asset
   *
   * @return {void}
   */
  downloadAssetFromStorage() {
    this.OvpAssetDownloadService.downloadAssetFromStorage(this.entityId)
      .then(({ download_url }) => {
        window.open(download_url);
      })
      .catch(() => {
        this.NotificationService.notifyError(NotificationMessage.downloadError);
      });
  }

  /**
   * is triggering MODAL_NOTIFICATION.delete
   * @returns {void}
   */
  triggerDeleteAssetWarning() {
    this.ModalTriggerService.triggerNotification({
      notificationType: "delete",
      channels: {
        confirm: `${this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.DELETE_ASSET}.${this.entityId}`,
      },
    });
  }

  /**
   * calls the onDeleteVersion property and removes the asset from the store
   */
  deleteVersion() {
    if (this._.isFunction(this.onDeleteVersion)) {
      this.isRemoving = true;
      this.onDeleteVersion({ version: this.ovp })
        .then(() => {
          if (this.ovpAsset && this.ovpAsset.pelicanId) {
            this.MessageService.publish(
              this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.REMOVE_UPLOAD_FROM_STORE,
              {
                pelicanId: this.ovpAsset.pelicanId,
              }
            );
          }
          if (!this._.isEmpty(this.textTracks)) {
            this.textTracks.forEach(({ id }) => this.store.clearUpload(id));
          }
        })
        .finally(() => {
          this.isRemoving = false;
        });
    }
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToStore = {
      ...TextTrackUploadAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToStore
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    return {
      ovpAssetUploads: state.ovpAssets,
      ovpAsset: OvpAssetUploadSelector.getUploadByEntityIdAndAccountUrl(
        state,
        this.entity && this.entity.uid,
        this.account.self
      ),
      textTracks: TextTrackSelector.getUploadsByEntityId(
        state,
        this.entity && this.entity.uid
      ),
    };
  }

  /**
   * on component removed
   * @returns {void}
   */
  $onDestroy() {
    this.removeSubscriptions();
    this.disconnect();
  }

  /**
   * If the cms-config contains config for text-tracks, return it
   * @returns the cms-config for text-tracks
   */
  getTextTracksConfig() {
    const config = this.config?.modules?.find(
      ({ name }) => name === "text-tracks"
    );
    return config;
  }

  /**
   * Returns whether the cms-config contains config for text-tracks
   * @returns boolean
   */
  hasTextTracks() {
    return !!this.getTextTracksConfig();
  }

  /**
   * Returns the account header, defaults to account name if one isn't found
   * @param {*} accountName string
   * @returns string
   */
  getHeader() {
    const label = this.accountConfigOptions?.label || this.account.name;
    if (this.isWorkflowService) {
      if (this.ovp) {
        return `Preview (${label})`;
      }
      return `Uploader`;
    }
    return label;
  }

  /**
   * Whether the upload button should be disabled
   * @returns boolean
   */
  disableUploadButton() {
    return (
      !this.selectedVideo ||
      (this.ovp && !this.isReadyForNewUpload()) ||
      this.isUploadInProgress()
    );
  }

  /**
   * Whether the reencode button should be disabled
   */
  disableReEncodeButton() {
    return !this.ovp || this.isUploadingToBucket() || this.isIngesting();
  }

  /**
   * reingestWorkflowServiceAsset - triggers the workflow service to start a reingest of an asset
   */
  reingestWorkflowServiceAsset() {
    if (this.isWorkflowService) {
      this.OvpAssetUploadService.reingestWorkflowServiceAsset(
        this.entityId,
        {
          entityId: this.entityId,
          provider: this.account.slug,
          accountUrl: this.account.self,
          pelicanId: this.entityId,
        },
        this.ovp
      );
    }
  }

  getIngestError() {
    if (this._.has(this, "ovpAsset.status_description")) {
      return this.ovpAsset.status_description;
    }
    return "Error processing file. Try again.";
  }
}

const ovpAssetBlockComponent = {
  bindings: {
    account: "<",
    accountConfigOptions: "<?",
    ovp: "<",
    filename: "<",
    entity: "<?",
    config: "<",
    ovpAssetType: "<?",
    hasStatusBarHidden: "<?",
    hasHeader: "<?",
    hasVideoFrameHidden: "<?",
    hasUrlUpload: "<?",
    onBeforeUpload: "&?",
    onUploadComplete: "&?",
    onDeleteVersion: "&?",
  },
  template,
  controller: OvpAssetBlockController,
  controllerAs: "component",
};

export default ovpAssetBlockComponent;
