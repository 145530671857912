import React, { useMemo } from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import { SCHEDULE_STATUSES } from "skylarklib/constants/search-and-filter";
import Dropdown from "components/_react/dropdown/dropdown.component";

/**
 * @param {object} filterSlice
 * @param {function} updateFilter
 *
 */
const StatusFilter = ({ filter, updateFilter }) => {
  const options = SCHEDULE_STATUSES.map(({ name, slug }) => ({
    value: slug,
    label: name,
  }));

  const selected = useMemo(() => {
    if (filter.value && filter.label) {
      return filter;
    }

    return _.find(options, { value: filter.value });
  }, [filter, options]);

  /**
   * @param {object} option
   * @param {string} option.value
   * @param {string} option.label
   *
   * @return {void}
   */
  const onChangeAction = (option) => {
    updateFilter([
      {
        bucket: "schedule",
        name: "status",
        payload: option,
      },
    ]);
  };

  return (
    <Dropdown
      value={selected}
      options={options}
      onChangeAction={onChangeAction}
      ariaLabel="status filter"
    />
  );
};

StatusFilter.propTypes = {
  updateFilter: PropTypes.func,
  filter: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

StatusFilter.defaultProps = {
  filter: {},
};

export default StatusFilter;
