/**
 * Service that manages when and where display the breadcrumbs. Maintains position and display
 * values throughout the app
 */
class BreadcrumbService {
  /**
   * @constructor
   * @param {Object} $location
   * @param {Object} HistoryService
   */
  constructor($location, HistoryService) {
    this.$location = $location;
    this.HistoryService = HistoryService;

    this.historyItems = this.HistoryService.history;
    this.contentBreadcrumbItems = [];

    this.isHistoryBreadcrumbVisible = false;
    this.isHistoryBreadcrumbLocked = true;

    this.isContentBreadcrumbLocked = false;

    const setUrlPattern = "^/sets/?(type(-[a-zA-Z0-9]*)*/)?";

    // The regular expression matches the paths that start with /sets/col or /sets/type-{entityType}/col
    this.setContentUrlPattern = new RegExp(`${setUrlPattern}col`);

    // The regular expression matches the following paths: /sets, /sets/ or /sets/type-{entityType}/
    this.setListingUrlPattern = new RegExp(`${setUrlPattern}$`);
  }

  /**
   * @set
   * Stores the previous breadcrumb when navigating by clicking a history item
   * @param {Object} value
   */
  set previousBreadcrumb(value) {
    this._previousBreadcrumb = value;
  }

  /**
   * @get
   * @returns {Object}
   */
  get previousBreadcrumb() {
    return this._previousBreadcrumb;
  }

  /**
   * Helper that informs if the user has navigated from a set listing page
   * @returns {boolean}
   */
  _hasNavigatedFromSetListing() {
    const previousPage = this.HistoryService.previousPage();

    return !!previousPage && this.setListingUrlPattern.test(previousPage.path);
  }

  /**
   * Helper that informs if the user navigated from another set page
   * @returns {boolean}
   * @private
   */
  _hasNavigatedFromSetContents() {
    const previousPage = this.HistoryService.previousPage();

    return !!previousPage && this.setContentUrlPattern.test(previousPage.path);
  }

  /**
   * Helper function to decipher if user has navigated from a listing page
   * @returns {boolean}
   */
  _hasNavigatedFromEntityListing() {
    const previousPage = this.HistoryService.previousPage();

    return !!previousPage && !!previousPage.pageTitle.match(/listing/i);
  }

  /**
   * Helper function to check if the user has navigated through from a relationship
   * @returns {boolean}
   * @private
   */
  _hasNavigatedFromARelationship() {
    return (
      this.isContentBreadcrumbLocked &&
      !!this.HistoryService.previousPage().path.match(/custom/)
    );
  }

  /**
   * Helper that checks if this is the first page the user has visited either via refresh or
   * navigating directly here
   * @returns {boolean}
   * @private
   */
  _isInitialLandingPage() {
    return this.historyItems.length < 2;
  }

  /**
   * Starts a new History Breadcrumb and removes its locked state
   * @returns {void}
   * @private
   */
  _startNewHistory() {
    this.isHistoryBreadcrumbLocked = false;
    this.isHistoryBreadcrumbVisible = true;
    this.contentBreadcrumbItems = [];

    this.HistoryService.reset();
    this.historyItems = this.HistoryService.history;
  }

  /**
   * Returns a copy of the array
   * @param {Array} array
   * @returns {Array}
   * @private
   */
  _copyArray(array) {
    return array.slice(0);
  }

  /**
   * Reassigns the global history array to the services history array. Used when navigating back
   * through breadcrumbs so that continuing forward will update the now unlocked breadcrumb as new
   * pages added to history will automatically appear in the breadcrumb.
   * @private
   * @returns {void}
   */
  _reassignHistory() {
    if (this.isHistoryBreadcrumbLocked) {
      this.historyItems = this.HistoryService.history.slice(0);
    } else {
      this.historyItems = this.HistoryService.history;
    }
  }

  /**
   * Stores information about the last item breadcrumb so that we can restore the breadcrumb to it's previous state
   * upon a user pressing the back button.
   * @private
   * @returns {void}
   */
  _setupBackComparisonData() {
    this.previousBreadcrumb = {
      url: this.HistoryService.pathWithoutTab(this.$location.path()),
      items: this.historyItems.slice(0),
    };
  }

  /**
   * Check to see if the user has just created the page
   * @private
   * @returns {boolean}
   */
  _hasNavigatedFromACreatePage() {
    return (
      this.HistoryService.previousPage() &&
      this.HistoryService.previousPage().stateName.indexOf("Create") > -1
    );
  }

  /**
   * Check to see if the user has entered a 'back loop'. I.e. they have gone back via breadcrumb and
   * then pressed back on the browser entering a loop and returning back at the first page.
   * @returns {boolean}
   */
  isBackLoop() {
    return (
      !!this.previousBreadcrumb &&
      this.previousBreadcrumb.url === this.HistoryService.currentPage().path
    );
  }

  /**
   * Starts a new content breadcrumb and removes it's locked state.
   * @returns {void}
   */
  startNewContentBreadcrumb() {
    this.isContentBreadcrumbLocked = false;
    this.contentBreadcrumbItems = [];
  }

  /**
   * Updates the visibility based on route change
   * @returns {void}
   */
  updateVisibility() {
    if (
      this._hasNavigatedFromSetListing() ||
      this._hasNavigatedFromEntityListing()
    ) {
      this.isHistoryBreadcrumbVisible = false;
    }

    if (!this.historyItems.length) {
      this.isHistoryBreadcrumbVisible = false;
    }

    if (this._hasNavigatedFromACreatePage()) {
      this.isHistoryBreadcrumbVisible = false;
    }

    if (this._hasNavigatedFromSetContents()) {
      this.isHistoryBreadcrumbVisible = true;
    }

    if (this._hasNavigatedFromARelationship()) {
      this.isHistoryBreadcrumbVisible = false;
      this.isContentBreadcrumbLocked = false;
    }
  }

  /**
   * Called to start the breadcrumbs when navigating to a child entity.
   * Checks if user has navigated to a page from the set listing page, in which case it will create
   * a new history. Also creates a new history item when this set has naviagted directly to (i.e.
   * after refresh)
   * @returns {void}
   */
  setNavigationHandler() {
    if (
      this._hasNavigatedFromSetListing() ||
      this._isInitialLandingPage() ||
      this._hasNavigatedFromACreatePage()
    ) {
      this._startNewHistory();
    }
  }

  /**
   * Lock's the current breadcrumb by making a copy of it removing any binding back ot the main app
   * history array
   * @returns {void}
   */
  lockHistoryBreadcrumb() {
    this.isHistoryBreadcrumbLocked = true;
    this.historyItems = this._copyArray(this.historyItems);
  }

  /**
   * Lock content breadcrumb
   * @returns {void}
   */
  lockContentBreadcrumb() {
    if (!this.isContentBreadcrumbLocked) {
      this.isContentBreadcrumbLocked = true;
    }
  }

  /**
   * Unlock content breadcrumb
   * @returns {void}
   */
  unlockContentBreadcrumb() {
    if (this.isContentBreadcrumbLocked) {
      this.isContentBreadcrumbLocked = false;
    }
  }

  /**
   * Manipulates history when a breadcrumb item has been interacted with. Resets history back to
   * the location of item to be navigated to and removes any navigation lock that was previously
   * applied
   * @param {Object} item
   * @returns {void}
   */
  breadcrumbNavigateTo(item) {
    const { position } = item;
    this.isHistoryBreadcrumbLocked = false;

    this._setupBackComparisonData();
    this._reassignHistory();
    this.historyItems.length = position;
    this.updateVisibility();
  }

  /**
   * Updates the app history to reflect our current position.
   * @param {array} history - History items
   * @returns {void}
   */
  updateHistory(history) {
    this.HistoryService.history = history;
    this._reassignHistory();
  }

  /**
   * Returns the combined length of the breadcrumbs
   * @returns {number}
   */
  totalBreadcrumbItems() {
    return this.contentBreadcrumbItems.length + this.historyItems.length;
  }
}

export default BreadcrumbService;
