import angular from "angular";
import TimePicker from "./time-picker/time-picker.module";
import DateTimeService from "./date-time.service";
import dateTimeRangeFieldDirective from "./date-time-range-field/date-time-range-field.directive";

const DateTime = angular
  .module("Skylark.Components.Fields.DateTime", [TimePicker.name])
  .service("DateTimeService", DateTimeService)
  .directive("dateTimeRangeField", dateTimeRangeFieldDirective);

export default DateTime;
