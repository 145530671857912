function queryString() {
  return (input) => {
    if (input) {
      return `?tab=${input}`;
    }

    return "";
  };
}

export default queryString;
