import * as EntityAction from "store/entity/entity.action";
import template from "./scheduling-item.html";

/**
 *  @fileOverview
 * Directive for managing the list of schedules owned by an entity.
 *
 */
class SchedulingItemController {
  /**
   * @constructor
   * @param  {Object} $scope
   * @param  {Object} ReduxConnector
   * @param  {Object} SchedulesFactory
   * @param  {Object} MessageService
   * @param  {Object} NotificationService
   * @param  {Object} ModalTriggerService
   */
  constructor(
    $scope,
    ReduxConnector,
    SchedulesFactory,
    MessageService,
    NotificationService,
    ModalTriggerService
  ) {
    this.$scope = $scope;
    this.ReduxConnector = ReduxConnector;
    this.SchedulesFactory = SchedulesFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ModalTriggerService = ModalTriggerService;

    this.scheduleRemoveChannel = "Schedule.Remove";

    this.connectToStore();
  }

  /**
   * $onInit
   * @returns {void}
   */
  $onInit() {
    this._setupSubscriptions();
  }

  /**
   * removeSchedule()
   * passes on scope data to parent for removal from entity
   *
   * @fires MessageService#Schedule.Remove
   * @returns {void}
   */
  removeSchedule() {
    if (this.count === 1) {
      this.$scope.$applyAsync(() => {
        this.ModalTriggerService.triggerNotification(
          this._buildNotificationOptions()
        );
      });
    } else {
      this.MessageService.publish(this.scheduleRemoveChannel, this.data);
    }
  }

  /**
   * triggers the modal to edit Schedule
   */
  triggerEditModal() {
    this.ModalTriggerService.triggerModal(
      this.data,
      this.config.fields,
      "edit",
      undefined,
      this._buildModalOptions()
    );
  }

  /**
   * _setupSubscriptions description
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this._buildModalOptions().channels.save,
      (channel, data) => {
        this._updateSchedule(data);
      }
    );

    this.MessageService.subscribe(`deleteSelf.${this.data.uid}`, () => {
      this.removeSchedule();
    });

    this.MessageService.subscribe(
      `Schedule.Remove.Confirm.${this.data.uid}`,
      () => {
        this.MessageService.publish(this.scheduleRemoveChannel, this.data);
      }
    );
  }

  /**
   * remove subscriptions
   */
  _removeSubscriptions() {
    this.MessageService.unregisterChannel(
      this._buildModalOptions().channels.save
    );
    this.MessageService.unregisterChannel(`deleteSelf.${this.data.uid}`);
    this.MessageService.unregisterChannel(
      `Schedule.Remove.Confirm.${this.data.uid}`
    );
  }

  /**
   * _updateSchedule
   * @param   {Object} data - data from modal
   * @returns {void}
   */
  _updateSchedule(data) {
    const successMessage = `You have successfully updated ${data.title}`;
    const errorMessage = `Something went wrong when updating ${data.title}`;

    this.store.updateSchedule(data.self, data, successMessage, errorMessage);
  }

  /**
   * _buildModalOptions
   * @returns {Object}
   */
  _buildModalOptions() {
    return {
      isRemoval: true,
      channels: { save: `itemblock:${this.data.uid}-haschanged` },
      entity: "schedules",
    };
  }

  /**
   * _buildNotificationOptions
   * @returns {Object}
   */
  _buildNotificationOptions() {
    return {
      notificationType: "removeLastSchedule",
      channels: {
        confirm: `Schedule.Remove.Confirm.${this.data.uid}`,
      },
    };
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };

    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  $onDestroy() {
    this._removeSubscriptions();
    this.disconnect();
  }
}

const schedulingItemComponent = {
  bindings: {
    data: "<",
    config: "<",
    count: "<",
  },
  controller: SchedulingItemController,
  controllerAs: "component",
  template,
};

export default schedulingItemComponent;
