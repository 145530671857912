/**
 * ConfigurationFactory
 *
 *
 *
 * @description Configuration factory is your one stop
 * shop for loading any configuration object in
 * Skylark.
 */

/**
 * The configuration factory
 * @param {Object} $q - Angular queue
 * @param {Object} ApiService - Skylark ApiService
 * @returns {Object} - the directive
 * @constructor
 */
function ConfigurationFactory($q, ApiService) {
  const factory = {};

  /**
   * @param {string} type
   * @param {string} entity
   * @returns {Promise}
   */
  factory.getArticleBaseConfiguration = (type, entity = "articles") =>
    factory.getTabConfiguration(entity, undefined, type);

  /**
   * @param {string} type
   * @param {string} entity
   * @returns {Promise}
   */
  factory.getArticleEditorConfiguration = (type, entity = "articles") =>
    factory.getTabConfiguration(entity, "editor", type);

  /**
   * get information config for entity,
   * @param {string} entity
   * @param {string} type
   * @returns {Promise}
   */
  factory.getInformationConfiguration = (entity, type) =>
    factory.getTabConfiguration(entity, "information", type);

  /**
   * @param {string} type
   * @param {string} entity
   * @returns {Promise}
   */
  factory.getArticleImageryConfiguration = (type, entity = "cms-articles") =>
    factory.getTabConfiguration(entity, "imagery", type);

  /**
   *  getSetRootConfiguration gets set base configuration
   *  @param {string} type - set type slug
   *  @returns {Promise} - deferred promise
   */
  factory.getSetBaseConfiguration = (type = "default") =>
    factory.getTabConfiguration("sets", undefined, type);

  /**
   * getSetInfoConfigurationByType - gets set info configuration
   * from cms-config API.
   *
   * /cms-config/sets/{settype}/information/
   *
   * @param  {String} type - Set type
   * @returns {Promise} - deferred promise
   */
  factory.getSetInfoConfigurationByType = (type = "default") =>
    factory.getTabConfiguration("sets", "information", type);

  /**
   * getSetContentsConfigurationByType - gets set configuration
   * from cms-config API.
   *
   * /cms-config/sets/{settype}/information/
   *
   * @param  {String} type - Set type
   * @returns {Promise} deferred - promise
   *
   */
  factory.getSetContentsConfigurationByType = (type = "default") =>
    factory.getTabConfiguration("sets", "contents", type);

  /**
   * Gets the imagery configuration based by the type of the set
   * @param  {String} type - Set type
   * @returns {Promise} deferred - promise
   */
  factory.getSetImageryConfigurationByType = (type = "default") =>
    factory.getTabConfiguration("sets", "imagery", type);

  /**
   * getEntityRootConfiguration - getsBaseConfiguration for entities
   * @param {string} entity type
   * @returns {Promise} deferred - promise
   */
  factory.getEntityBaseConfiguration = (entity) =>
    factory.getTabConfiguration(entity);

  /**
   * getBaseConfiguration - Gets Base configuration
   * @param {string} entity
   * @param {string} [entityType]
   * @returns {Promise}
   */
  factory.getBaseConfiguration = (entity, entityType) =>
    factory.getTabConfiguration(entity, undefined, entityType);

  /**
   * getEntityConfiguration - Gets entity configuration from the api.
   *
   * @param  { string } entity - entity type (e.g. episodes)
   * @returns { Promise } deferred promise
   *
   */
  factory.getEntityConfiguration = (entity) =>
    factory.getTabConfiguration(entity, "information");

  /**
   * getEntityListingConfiguration - Gets the entity listing config from api
   * @param  { string } entity - entity type (e.g. episodes)
   * @returns { Promise }
   */
  factory.getEntityListingConfiguration = (entity) =>
    factory.getTabConfiguration(entity);

  /**
   * getTabConfiguration
   *
   * assets have one unified information config
   * @param {string} entity - entity name (e.g. episodes)
   * @param {string} [tab] - name of the tab
   * @param {string} [type] - entity type
   * @returns { Promise } deferred promise
   */
  factory.getTabConfiguration = (entity, tab, type) => {
    if (entity === "assets" && tab) {
      type = undefined;
    }

    const typePath = type ? `/${type}` : "";
    const tabPath = tab ? `/${tab}` : "";
    const url = `/cms-config/${entity}${typePath}${tabPath}/static.json`;

    return ApiService.get(url, {}, false);
  };

  /**
   * getModalConfiguration
   * @param {string} type - set type slug
   * @returns { Promise } deferred promise
   */
  factory.getModalConfigurationByType = (type) =>
    factory.getTabConfiguration("modal", type);

  /**
   * getTabsAndContentTypes
   * @param {string} entity - entity type (e.g. episodes)
   * @param {string} tab name - name of the tab
   * @returns { promise } deferred promise
   */
  factory.getTabsAndContentTypes = (entity, tab) => {
    const deferred = $q.defer();

    factory.getTabConfiguration(entity, tab).then((config) => {
      deferred.resolve({
        config,
        contentTypes: config.content_types,
      });
    });

    return deferred.promise;
  };

  return factory;
}

export default ConfigurationFactory;
