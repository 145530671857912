import angular from "angular";
import talentDirective from "./talent-module.directive";
import talentUpdateDirective from "./talent-update.directive";

const Talent = angular
  .module("Skylark.Modules.Talent", [])
  .directive("talent", talentDirective)
  .directive("talentUpdate", talentUpdateDirective);

export default Talent;
