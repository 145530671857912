import angular from "angular";
import { UID_PATTERN } from "../metadata";

const ContentRouter = angular.module("ContentRoute", []).config(router);

/**
 * Clears all messaging channels
 * @param {object} MessageService - the messaging service
 * @returns {void}
 */
function clearChannels(MessageService) {
  MessageService.clearChannels();
}

/**
 * reset metadata versions
 * @returns {void}
 */
function resetVersions(MetadataFactory) {
  MetadataFactory.reset();
}

/**
 * resetLanguages
 * @param   {Object} EntityDimensionsService
 * @returns {void}
 */
function resetLanguages(EntityDimensionsService) {
  EntityDimensionsService.reset();
}

/**
 * resetEditingState
 * @param   {Object} EditingService
 * @returns {void}
 */
function resetEditingState(EditingService) {
  EditingService.disableEditing();
}

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlRouterProvider - ui-router's urlRouterProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router(
  $stateProvider,
  $locationProvider,
  $urlRouterProvider,
  $urlMatcherFactoryProvider
) {
  const entities = [
    "brands",
    "seasons",
    "episodes",
    "assets",
    "quotes",
    "channels",
    "articles",
    "cms-articles",
    "links",
    "talent",
    "sets",
    "credits",
  ];

  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);

  entities.forEach((entityName) => {
    applyRoute(entityName, $stateProvider, $urlRouterProvider);
  });
}

/**
 * Routing settings for each of the content Objects
 * @param {String} entityName - name of the content object
 * @param {object} $stateProvider - ui-router's stateProvider service
 * @param {object} $urlRouterProvider - ui-router's urlRouterProvider service
 * @returns {void}
 */
function applyRoute(entityName, $stateProvider, $urlRouterProvider) {
  const entityIDRegex = UID_PATTERN;

  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();

    if (path[path.length - 1] !== "/") {
      return `${path}/`;
    }
  });

  const routeTypes = [
    {
      name: "route",
      url: "",
    },
    {
      name: "routeByType",
      url: "/type-{entityType}",
    },
  ];

  const tabRoutes = [
    {
      tabName: "information",
      component: "entityInfoPage",
      entityName: "information",
    },
    {
      tabName: "relationships",
      component: "entityRelationshipsPage",
      entityName: "relationships",
    },
    {
      tabName: "imagery",
      component: "entityImageryPage",
      entityName: "imagery",
    },
    {
      tabName: "documents",
      component: "entityDocumentsPage",
      entityName: "documents",
    },
    {
      tabName: "versions",
      component: "entityVersionsPage",
      entityName: "versions",
    },
    {
      tabName: "content",
      component: "entityContentsPage",
      entityName: "assets",
    },
    {
      tabName: "contents",
      component: "setContentsPage",
      entityName: "contents",
    },
    {
      tabName: "epg",
      component: "entityEPGPage",
      entityName: "epg",
    },
    {
      tabName: "editor",
      component: "editor",
      entityName: "editor",
    },
  ];

  routeTypes.forEach((routeType) => {
    $stateProvider
      .state(`${entityName}${routeType.name}`, {
        resolve: {
          clearChannels,
          resetLanguages,
          resetEditingState,
          resetVersions,
        },
        url: `/${entityName}${routeType.url}`,
        title: entityName,
        pageTitle: `${entityName}:listing`,
        views: angular.extend({
          main: {
            component: "entityListingPage",
          },
        }),
      })
      .state(`${entityName}${routeType.name}Detail`, {
        resolve: {
          clearChannels,
          resetLanguages,
          resetEditingState,
        },
        url: `/${entityName}${routeType.url}/{id:${entityIDRegex}}/`,
        title: entityName,
        pageTitle: `${entityName}:detail`,
        views: angular.extend({
          main: {
            component: "entityDetailPage",
          },
        }),
      });

    tabRoutes.forEach((tabRoute) => {
      $stateProvider.state(
        `${entityName}${routeType.name}Detail.${tabRoute.entityName}`,
        {
          url: `${tabRoute.entityName}/`,
          title: entityName,
          pageTitle: `${entityName}:${tabRoute.tabName}`,
          views: {
            info: {
              component: tabRoute.component,
            },
          },
        }
      );
    });

    $urlRouterProvider.when(
      `/${entityName}${routeType.url}/{id:${entityIDRegex}}`,
      ($state, $match, ConfigurationFactory, _) =>
        navigateToFirstTab(
          $state,
          $match,
          ConfigurationFactory,
          _,
          entityName,
          routeType
        )
    );
  });

  $stateProvider
    .state(`${entityName}Create`, {
      resolve: {
        clearChannels,
      },
      url: `/${entityName}/create/`,
      pageTitle: `${entityName}:details:create`,
      title: entityName,
      views: angular.extend({
        main: {
          component: "entityDetailPage",
        },
      }),
    })
    .state(`${entityName}CreateByType`, {
      resolve: {
        clearChannels,
      },
      url: `/${entityName}/create/:entityType/`,
      title: "assets",
      pageTitle: "assets:details:create",
      abstract: true,
      views: angular.extend({
        main: {
          component: "entityDetailPage",
        },
      }),
    })
    .state(`${entityName}Create.information`, {
      url: "information/",
      pageTitle: `${entityName}:information:create`,
      title: entityName,
      views: {
        info: {
          component: "entityInfoPage",
        },
      },
    })
    .state(`${entityName}CreateByType.information`, {
      url: "information/",
      pageTitle: `${entityName}:information:create`,
      title: entityName,
      views: {
        info: {
          component: "entityInfoPage",
        },
      },
    });

  $urlRouterProvider
    .when(`/${entityName}/create`, `/${entityName}/create/information/`)
    .when("/assets/create/:assetType", "/assets/create/:assetType/information/")
    .when(
      `/${entityName}/create/:entityType`,
      `/${entityName}/create/:entityType/information/`
    )
    .otherwise(`/${entityName}`);
}

/**
 * changes state to the first Tab from configuration, otherwise falling back to default tab
 * @param {Object} $state
 * @param {Object} $match
 * @param {Object} ConfigurationFactory
 * @param {Object} _
 * @param {String} entityName
 * @param {Object} routeType
 */
function navigateToFirstTab(
  $state,
  $match,
  ConfigurationFactory,
  _,
  entityName,
  routeType
) {
  const defaultTab = "information";
  ConfigurationFactory.getEntityBaseConfiguration(entityName)
    .then((config) => {
      const firstTab = _.get(config, "tabs[0].name", defaultTab);
      $state.go(`${entityName}${routeType.name}Detail.${firstTab}`, $match);
    })
    .catch(() => {
      $state.go(`${entityName}${routeType.name}Detail.${defaultTab}`, $match);
    });
}

export default ContentRouter;
