import React from "react";
import { Text, TextProps } from "@chakra-ui/react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { BUILD_TIMESTAMP, SKYLARK_RELEASE } from "@skylark/lib";

dayjs.extend(utc);
dayjs.extend(timezone);

interface VersionProps extends TextProps {
  skylarkRelease?: string;
  buildTimestamp?: string;
}

export const Version: React.FC<VersionProps> = ({
  skylarkRelease = SKYLARK_RELEASE,
  buildTimestamp = BUILD_TIMESTAMP,
  ...props
}) => {
  const parsedTimestamp =
    buildTimestamp &&
    dayjs
      .tz(parseInt(buildTimestamp, 10) * 1000, "Europe/London")
      .format("hhmm-DDMMYYYY");
  // Add all identifiers into array, filter out empty ones and add dash to separate
  const version = [skylarkRelease, parsedTimestamp]
    .filter((el) => !!el)
    .join("-");
  return (
    <>
      {version.length > 0 && (
        <Text
          color="gray.400"
          data-testid="app-version"
          fontSize="sm"
          {...props}
        >
          {`Version: ${version}`}
        </Text>
      )}
    </>
  );
};
