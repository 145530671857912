import collapsibleTemplate from "./relationship-item-collapsible.html";
import defaultTemplate from "./relationship-item.html";

/**
 * @class TagUpdateCtrl
 */
class RelationshipItemCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $element
   * @param {Object} $timeout
   * @param {Object} BreadcrumbService
   * @param {Object} MessageService - Skylark message service
   * @param {Object} EntityFactory
   * @param {Object} EntityTypeHelpersService
   * @returns {void}
   */
  constructor(
    $scope,
    $element,
    $timeout,
    BreadcrumbService,
    MessageService,
    EntityFactory,
    EntityTypeHelpersService
  ) {
    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.BreadcrumbService = BreadcrumbService;
    this.MessageService = MessageService;
    this.EntityFactory = EntityFactory;
    this.EntityTypeHelpersService = EntityTypeHelpersService;

    this.remove = this.remove.bind(this);
  }

  $onInit() {
    this.removeRelationshipChannelName = `Relationship.${this.item.uid}.remove`;
    this.addSchedulesChannelName = `Schedules.${this.item.uid}.update`;

    this._setupSubscriptions();
    this._buildNotificationOptions();
  }

  /**
   * Setup pub sub subscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this.removeRelationshipChannelName,
      this.remove
    );

    this.MessageService.subscribe(this.addSchedulesChannelName, (ch, data) => {
      this.item.schedule_urls = [...data];
    });
  }

  /**
   * Initialise the component
   * @returns {void}
   */
  init() {
    const hasTypes = this.config.restrict_to_type;
    const { name } = this.config;
    const type = this.EntityTypeHelpersService.getRawType(this.item.self);
    const urlPrefix = this.EntityFactory.isCoreEntity(type) ? "" : "/custom";

    this.setupCollapsibleIfEnabled();
    this.displayName = this.buildDisplayName();
    this.url = `${urlPrefix}/${hasTypes ? `${type}/type-${name}` : name}/${
      this.item.uid
    }`;
  }

  /**
   * The css transition is .25s long
   * Adding 1 ms more to ensure the
   * transition is over
   * @retuns {void}
   *
   */
  setupCollapsibleIfEnabled() {
    if (this.isCollapsible) {
      this.isOpen = false;
      this.isAnimating = false;
      this.animationTimeMS = 251;
      this.$content = this.$element.find(".table__row__collapsible-content");
      this.close();
    }
  }

  /**
   * Gets each field that makes up the displayAs array and output their contents as a sting
   * Uses an additional nameAsSeparator to have a custom separator
   * @returns {string} - the name of the relationship item (e.g. John Doe or Athletics)
   */
  buildDisplayName() {
    const displayNameArray = [];

    this.config.nameAs.forEach((namedField) => {
      displayNameArray.push(this.item[namedField]);
    });

    const separator = this.config?.nameAsSeparator
      ? ` ${this.config?.nameAsSeparator} `
      : " ";

    return displayNameArray.join(separator);
  }

  /**
   * Method to remove item block and relationship
   * @returns {void}
   */
  remove() {
    const channelNameDelete = `delete.${
      this.config.type || this.config.name
    }.relationship`;
    this.item.displayName = this.displayName;
    this.MessageService.publish(channelNameDelete, this.item);
  }

  /**
   * Locks the content breadcrumb if we navigate through a relationship & we have an history breadcrumb
   * @returns {void}
   */
  lockBreadcrumbs() {
    if (
      !this.config.content_tab &&
      this.BreadcrumbService.historyItems.length
    ) {
      this.BreadcrumbService.lockContentBreadcrumb();
    }

    if (this.config.content_tab) {
      this.BreadcrumbService.previousBreadcrumb = null;
      this.BreadcrumbService.lockHistoryBreadcrumb();
    }
  }

  /**
   *
   * @returns {void}
   *
   */
  open() {
    this.isAnimating = true;
    this.$content.css("max-height", this.$content[0].scrollHeight);
    this.$timeout(() => {
      this.$content.css("max-height", "none");
      this.isAnimating = false;
    }, this.animationTimeMS);
  }

  /**
   *
   * @returns {void}
   *
   */
  close() {
    this.isAnimating = true;
    this.$content.css("max-height", this.$content[0].scrollHeight);
    this.$timeout(() => this.$content.css("max-height", 0));
    this.$timeout(() => {
      this.isAnimating = false;
    }, this.animationTimeMS);
  }

  /**
   * @returns {void}
   *
   */
  toggle() {
    if (!this.isAnimating) {
      this.isOpen = !this.isOpen;

      this.isOpen ? this.open() : this.close();
    }
  }

  /**
   * _buildNotificationOptions
   * @returns {void}
   */
  _buildNotificationOptions() {
    this.notificationOptions = {
      notificationType: "removeItem",
      channels: {
        confirm: `Relationship.${this.item.uid}.remove`,
      },
    };
  }

  $onDestroy() {
    this.MessageService.off(this.removeRelationshipChannelName, this.remove);
  }
}

/**
 * relationshipItem
 * @returns {object} directive definition object
 */
function RelationshipItemBlock() {
  return {
    restrict: "A",
    replace: true,
    transclude: true,
    scope: {},
    bindToController: {
      item: "=",
      config: "=",
      isCollapsible: "<?",
    },
    controller: RelationshipItemCtrl,
    controllerAs: "component",
    template(elem, attr) {
      if (attr.isCollapsible) {
        return collapsibleTemplate;
      }

      return defaultTemplate;
    },
    link: (scope, element) => {
      scope.component.init();
      element.find(".table-grid--actions .icon-close").on("click", (event) => {
        event.preventDefault();
      });
    },
  };
}

export default RelationshipItemBlock;
