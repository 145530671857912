import template from "./linear-progress-indicator.html";

const linearProgressIndicatorComponent = {
  bindings: {
    percentage: "<",
    hasError: "<?",
    stop: "<?",
  },
  template,
  controllerAs: "component",
};

export default linearProgressIndicatorComponent;
