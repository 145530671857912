import _ from "lodash";

class DateTimeService {
  /**
   * @constructor
   * @param {Object} momentJS
   * @param {Object} MessageService
   */
  constructor(momentJS, MessageService) {
    this.momentJS = momentJS;
    this.MessageService = MessageService;
    // Object that will have [uuid] property that will container date, time and combined values
    this.dateTimes = {
      start: {},
      end: {},
    };
  }

  /**
   * @param {string} uuid
   * @returns {void}
   */
  updateCombinedStartDate(uuid) {
    this.updateDateTimesCombined(uuid, "start");

    this.MessageService.publish(`DateAndTime.update.${uuid}`);
  }

  /**
   * @param {string} uuid
   * @returns {void}
   */
  updateCombinedEndDate(uuid) {
    const combined = this.updateDateTimesCombined(uuid, "end");
    this.MessageService.publish(`DateAndTime.update.${uuid}`);
    this.MessageService.publish("DateAndTime.updateFilters", {
      start: this.getValueByUUID(uuid, "start", "combined"),
      end: combined,
    });
  }

  /**
   * Initialises the values
   * @param {string} start
   * @param {string} end
   * @param {string} uuid
   * @returns {void}
   */
  initialiseValues(start, end, uuid) {
    this.updateDateTimes(
      uuid,
      "start",
      start,
      start ? this.momentJS(start).format("HH:mm") : "0:00"
    );
    this.updateDateTimes(
      uuid,
      "end",
      end,
      end ? this.momentJS(end).format("HH:mm") : "0:00"
    );

    this.updateCombinedStartDate(uuid);
    this.updateCombinedEndDate(uuid);
  }

  /**
   * Combines the time widget into the date widget. Will work for start or end dates.
   * @param {String} date
   * @param {String} time
   * @returns {String | void}
   */
  _combineDateAndTime(date, time) {
    if (!date) {
      return null;
    }

    const hours = time ? time.split(":")[0] : 0;
    const minutes = time ? time.split(":")[1] : 0;

    date = this.momentJS(date);

    date.hours(hours);
    date.minutes(minutes);
    date.seconds(0);
    date.milliseconds(0);

    return date.toISOString();
  }

  /**
   * @param {string} uuid - uuid
   * @param {string} which - start or end
   * @param {string?} date - the date value
   * @param {string?} time - time value
   * @param {string?} combined - combined value
   *
   * @returns {void}
   *
   */
  updateDateTimes(uuid, which, date, time, combined) {
    if (!this.dateTimes[which][uuid]) {
      this.dateTimes[which][uuid] = {};
    }

    const {
      date: existingDate,
      time: existingTime,
      combined: existingCombined,
    } = this.dateTimes[which][uuid];

    this.dateTimes[which][uuid] = {
      date: date || existingDate,
      time: time || existingTime,
      combined: combined || existingCombined,
    };
  }

  /**
   *
   * @param {string} uuid - uuid
   * @param {string} which - start or end
   */
  resetDataTimeByUUID(uuid, which) {
    this.dateTimes[which][uuid] = {
      date: null,
      time: null,
      combined: null,
    };
  }

  /**
   *
   * @param {string} uuid - uuid
   * @param {string} which - start or end
   *
   * @return {string|void}
   */
  updateDateTimesCombined(uuid, which) {
    const dateTime = this.dateTimes[which][uuid];

    if (_.isEmpty(dateTime)) {
      return;
    }

    const combined = this._combineDateAndTime(dateTime.date, dateTime.time);

    this.dateTimes[which][uuid].combined = combined;
    return combined;
  }

  /**
   * @param {string} uuid
   * @param {string} which - start/end
   * @param {string} value - date/time/combined
   *
   * @return {string}
   */
  getValueByUUID(uuid, which, value) {
    if (_.isEmpty(this.dateTimes[which][uuid])) {
      return "";
    }

    return this.dateTimes[which][uuid][value];
  }
}

export default DateTimeService;
