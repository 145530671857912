import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { isString, get } from "lodash";

/**
 * mapProps
 * @param  {Array} arr
 * The arr array receives strings or objects with name and state
 * properties like { name: 'fetching', state: 'detail.isFetching' }
 */
const mapProps = (arr) => (state) =>
  arr.reduce((acc, curr) => {
    if (!isString(curr)) {
      acc[curr.name] = get(state, curr.state);
    } else {
      acc[curr] = state[curr];
    }

    return acc;
  }, {});

const mapDispatch = (actions) => (dispatch) =>
  bindActionCreators(Object.assign({}, ...actions), dispatch);

const connectToStore = (config, component) =>
  connect(mapProps(config.props), mapDispatch(config.actions))(component);

export default connectToStore;
