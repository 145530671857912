/**
 * @file DEFAULT_ITEM_CONFIG
 * Constant use when a set item doesnt have matching config
 */

const DEFAULT_ITEM_CONFIG = {
  columns: [
    {
      name: "position",
      widget: "itemPositionForm",
      size: 2,
    },
    {
      name: "content_type",
      widget: "itemType",
      size: 4,
    },
    {
      name: "title",
      widget: "itemTitle",
      size: 7,
    },
    {
      size: 4,
    },
    {
      size: 7,
    },
  ],
};

export default DEFAULT_ITEM_CONFIG;
