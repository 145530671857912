/**
 * @constructor
 * @param {Object} SetsFactory
 * @param {Object} SetContentsStore
 * @param {Object} SetContentsDispatcher
 * @param {Object} QueryStringHelpersService
 * @param {Object} SET_CONTENTS_ACTIONS
 * @param {Object} momentJS
 * @param {Object} _
 */
function PreviewActions(
  SetsFactory,
  SetContentsStore,
  SetContentsDispatcher,
  QueryStringHelpersService,
  SET_CONTENTS_ACTIONS,
  momentJS,
  _
) {
  const factory = {};
  const todayStart = momentJS().startOf("day").toISOString();
  const todayEnd = momentJS().endOf("day").toISOString();

  /**
   * _isDynamicObject
   * @param   {Object} item
   * @returns {Boolean}
   */
  function _isDynamicObject(item) {
    return item.type === "computed-scheduled-items";
  }

  /**
   * whether this item is positioned
   * @param   {Object} item
   * @returns {Boolean}
   */
  function _isPositioned(item) {
    return item.scheduledItem[item.displayedLanguage].position > 0;
  }

  /**
   * assign positions to preview items
   * This isn't strictly necessary, but it looks better here than in the DOM
   * @param   {Array} items
   * @param   {Number} currentPosition
   * @returns {Array}
   */
  function _assignPositionsToPreviewItems(items, currentPosition) {
    return items.map((item, index) => {
      if (!_isDynamicObject(item)) {
        item.displayPosition = `${currentPosition + index}`;
      }

      return item;
    });
  }

  /**
   * _generatePositionInterval
   * @param   {Object} item
   * @param   {Number} currentPosition
   * @param   {Object} queryObject
   * @returns {Object}
   */
  function _generatePositionInterval(item, currentPosition, queryObject) {
    item.displayPosition = `${currentPosition + 1} - ${
      currentPosition + parseInt(queryObject.limit)
    }`;
    item.previewItems = _assignPositionsToPreviewItems(
      item.previewItems,
      currentPosition + 1
    );

    return item;
  }

  /**
   * In Preview the items which are unscheduled on the set level or are in future are hidden.
   * to show the user a position, the items has a displayPosition, which skips the hidden items.
   * @param   {Array} items from state
   * @param   {Array<String>} previewItems - list of uid which are in preview
   * @returns {Array} previewItems from state
   */
  function _assignDisplayPositions(items, previewItems) {
    let currentPosition = 0;

    const sortedItems = _.sortBy(
      items,
      (itemObject) =>
        itemObject.scheduledItem[itemObject.displayedLanguage].position
    );

    return sortedItems.map((item) => {
      const isPreview =
        previewItems.findIndex((previewItem) => item.uid === previewItem) > -1;
      if (_isPositioned(item) && isPreview) {
        if (_isDynamicObject(item)) {
          const queryObject = QueryStringHelpersService.queryStringToObject(
            item.contentItem[item.displayedLanguage].url
          );
          _generatePositionInterval(item, currentPosition, queryObject);
          currentPosition += parseInt(queryObject.limit);
        } else {
          item.displayPosition = `${currentPosition + 1}`;
          currentPosition++;
        }
      }

      return item;
    });
  }

  /**
   * requestPreviewItems
   * @param  {String} start ISO date
   * @param  {String} end   ISO date
   */
  function _requestPreviewItems(start, end) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.requestPreviewItems,
    });

    return SetsFactory.getSetById(SetContentsStore.getState().set.uid, {
      at: start,
      until: end,
      fields: "items,items__uid",
    });
  }

  /**
   * receivePreviewItems
   * @param {Object} previewItems
   */
  function _receivePreviewItems(previewItems) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receivePreviewItems,
      previewItems: previewItems.items.map((item) => item.uid),
    });
  }

  /**
   * fetch the filtered preview items
   * @param  {String} start ISO date
   * @param  {String} end   ISO date
   */
  factory.fetchPreviewItems = (start = todayStart, end = todayEnd) => {
    const { uid } = SetContentsStore.getState().set;

    if (uid) {
      _requestPreviewItems(start, end).then((previewItems) => {
        _receivePreviewItems(previewItems);
      });
    }
  };

  /**
   * generate display positions for preview
   */
  factory.generateDisplayPositions = () => {
    const state = SetContentsStore.getState();

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.generateDisplayPositions,
      itemsWithDisplayPositions: _assignDisplayPositions(
        state.items,
        state.previewItems
      ),
    });
  };

  return factory;
}

export default PreviewActions;
