import angular from "angular";
import CollectionField from "./collection-field/collection-field.module";
import DateTime from "./+date-time/date-time.module";
import Dropdown from "./dropdown/dropdown.module";
import DropdownSelect from "./dropdown-select/dropdown-select.module";
import RelationshipDropdown from "./relationship-dropdown/relationship-dropdown-select.module";
import DynamicField from "./dynamic-field/dynamic-field.module";
import ImageMetadata from "./image-with-metadata/image-with-metadata.module";
import ImageUploader from "./image-uploader/image-uploader.module";
import FilePicker from "./file-picker/file-picker.component";
import MultipleCheckbox from "./multiple-checkbox/multiple-checkbox.module";
import PasswordReset from "./password-reset/password-reset.module";
import DateInput from "./+date-input/date-input.module";

const Fields = angular.module("Skylark.Components.Fields", [
  CollectionField.name,
  DateInput.name,
  DateTime.name,
  Dropdown.name,
  DropdownSelect.name,
  RelationshipDropdown.name,
  DynamicField.name,
  ImageMetadata.name,
  ImageUploader.name,
  FilePicker.name,
  MultipleCheckbox.name,
  PasswordReset.name,
]);

export default Fields;
