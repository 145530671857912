import ReduceReducers from "reduce-reducers";
import _ from "lodash";

import { createApiReducer } from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import { generateApiQuery } from "skylarklib/helpers/entity-listing-filter-helper";
import {
  FETCH_ENTITY_LISTING,
  FETCH_ENTITY_LISTING_COUNT,
  namespaced,
  RESET_SEARCH_FILTER,
  SET_ACTIVE_FILTERS,
} from "./entity-listing.constant";
import {
  ActiveFilterParams,
  ActiveFilters,
  EntityListing,
} from "./entity-listing.types";
import { globalParamsSet } from "../../../skylarklib/angularjs/services/global-param/global-param.constants";

export const initialState: EntityListing = {
  data: {
    results: [],
    count: null,
  },
  /**
   * This part of the reducer takes care of the filters that are applied to the search from a listing view and from a modal view.
   * It representing the ACTIVE filters, so the one you can actually see in the UI.
   * The filters are separated in buckets as they have some sort of grouping around how they are rendered, or a logical dependency
   */
  activeFilters: {
    // search bucket can hold only one set of configuration, there is currently no use case of multiple search terms
    search: {
      value: "",
    },
    // schedule bucket takes care of filters related to dimensions or licence status
    schedule: {
      status: {
        value: "all",
        label: "All",
      },
    },
    // pagination bucket takes care of order, limit, start
    pagination: {
      limit: {
        value: 10,
      },
      start: {
        value: 0,
      },
    },
    // property bucket are indexed properties and therefore go in to the q parameter and will be joined by an AND
    property: {},
    // these are like property bucket but they are not indexed properties and therefore are just a key value part of the URL search
    nonIndexedProperty: {},
  },
  apiQuery: undefined,
  countQuery: undefined,
  selectedItem: [],
};

/**
 * manipulating the state for one instance, separated by entity,
 * it is holding the entities found by the query and the amount of items,
 * the active filter represents the current selected variety of filters
 * the api query object, should be used for querying for items
 */
export const createEnhancedReducer =
  (namespace: string) =>
  (state = initialState, action): EntityListing => {
    const { type, response } = action;

    switch (type) {
      case ApiConstant.getSuccessType(
        namespaced(namespace, FETCH_ENTITY_LISTING)
      ): {
        const { objects } = response;
        return {
          ...state,
          data: {
            ...state.data,
            results: objects,
          },
        };
      }
      case ApiConstant.getSuccessType(
        namespaced(namespace, FETCH_ENTITY_LISTING_COUNT)
      ): {
        if (
          !response ||
          !Object.prototype.hasOwnProperty.call(response, "count")
        ) {
          return state;
        }

        const { count }: { count: number } = response;

        return {
          ...state,
          data: {
            ...state.data,
            count,
          },
        };
      }
      case namespaced(namespace, SET_ACTIVE_FILTERS): {
        const { activeFiltersParams } = action;

        const activeFilters = generateActiveFilters({
          existingFilters: state.activeFilters,
          activeFiltersParams,
        });
        const apiQuery = generateApiQuery(activeFilters);

        if (_.isEqual(state.apiQuery, apiQuery)) {
          return state;
        }

        let countQuery = _.omit(apiQuery, ["limit", "start", "order"]);

        if (_.isEqual(state.countQuery, countQuery)) {
          countQuery = state.countQuery;
        }

        return {
          ...state,
          activeFilters,
          apiQuery,
          countQuery,
        };
      }
      case namespaced(namespace, RESET_SEARCH_FILTER):
        return {
          ...initialState,
        };
      default: {
        return state;
      }
    }
  };

export const createGetApiReducer = (namespace: string) =>
  createApiReducer(initialState, namespaced(namespace, FETCH_ENTITY_LISTING));

export const createListingReducer = (namespace: string) =>
  ReduceReducers(
    initialState,
    createGetApiReducer(namespace),
    createEnhancedReducer(namespace)
  );

/**
 * generating an new active filter, from the current active filters and new filters
 * @param {string} activeFiltersParams.entityName
 * @param {object} activeFiltersParams.dynamicProperties
 * @param {string} activeFiltersParams.dynamicProperties.name
 * @param {string} activeFiltersParams.dynamicProperties.bucket
 * @param {object|string|number|boolean} activeFiltersParams.dynamicProperties
 */
export const generateActiveFilters = ({
  existingFilters,
  activeFiltersParams,
}: {
  existingFilters: ActiveFilters;
  activeFiltersParams: ActiveFilterParams;
}) => {
  const newFilters = _.cloneDeep(existingFilters);
  const { dynamicProperties = [] } = activeFiltersParams;

  dynamicProperties.forEach((property) => {
    const { payload, bucket, name } = property;

    if (!bucket || !newFilters[bucket]) {
      return;
    }

    if (bucket === "search") {
      return (newFilters[bucket] = payload);
    }

    newFilters[bucket][name] = payload;
  });

  /*
   * By default if multiple schedule statuses are given it will use the last one
   * If all are given (globalParamsSet) then change it to be "all"
   *
   * The fix isn't great - couldn't figure out why the getDefaultParams() in app/js/skylarklib/angularjs/services/global-param/global-param.service.js
   * sends "all" when global params are not set VS "expired", "current" and "future" when it is set
   * Best guess is that this was broken when the component was moved from AngularJS to React/Redux
   */
  const scheduleStatusesArr = dynamicProperties
    .filter(({ bucket, name }) => bucket === "schedule" && name === "status")
    .map(({ payload }) => payload.value);
  const allStatusesGiven = Object.keys(globalParamsSet).every((status) =>
    scheduleStatusesArr.includes(status)
  );

  if (allStatusesGiven) {
    newFilters.schedule.status = { value: "all" };
  }

  // reset pagination when the query changed but not the start
  const hasPaginationChanged = dynamicProperties.some(
    ({ bucket, name }) => bucket === "pagination" && name === "start"
  );

  if (!hasPaginationChanged && newFilters.pagination) {
    newFilters.pagination.start = { value: 0 };
  }

  return newFilters;
};
