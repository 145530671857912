import ReduceReducers from "reduce-reducers";
import _ from "lodash";

import ApiConstant from "./api.constant";

/**
 * creating a default reducer to handle, request, success, and error
 * @param {*} initialState
 * @param {String} type
 * @returns {function(...[*]=)}
 */
const createApiReducer =
  (initialState, type) =>
  (state = initialState, action) => {
    switch (action.type) {
      case ApiConstant.getRequestType(type):
        return {
          ...state,
          loading: true,
          error: undefined,
          errorStatus: undefined,
        };
      case ApiConstant.getSuccessType(type):
        return {
          ...state,
          loading: false,
        };
      case ApiConstant.getFailureType(type):
        return {
          ...state,
          error: action.error,
          errorStatus: action.errorStatus,
          loading: false,
        };
      default:
        return {
          ...state,
          data: state.data || {}, // adds to all reducer by default a data object
        };
    }
  };

/**
 * on success api response is applied to data
 * it extends the api reducer
 * @param initialState
 * @param type
 * @return {*}
 */
const createAttachDataReducer = (initialState, type) => {
  const overrideDataSuccess = (state = initialState, action) => {
    if (action.type === ApiConstant.getSuccessType(type)) {
      /*
       * action is for updating a related entity
       * the update of child entries is not supported yet
       *
       * TODO: Refactoring – move this code to the specific reducer
       *       doesn't look to me a good idea to have this rule on every
       *       reducer that uses this helper
       */
      if (action.self && action.self !== state.data.self) {
        return state;
      }

      return {
        ...state,
        data: _.mergeWith(
          _.cloneDeep(state.data),
          action.response,
          mergeDataUpdates
        ),
      };
    }
    return state;
  };

  return ReduceReducers(
    initialState,
    createApiReducer(initialState, type),
    overrideDataSuccess
  );
};

/**
 * reset on request the data,
 * extends api reducer and attach data on success
 * @param initialState
 * @param type
 * @return {*}
 */
const createResetDataApiReducer = (initialState, type) => {
  const resetDataReducer = (state = initialState, action) => {
    switch (action.type) {
      case ApiConstant.getRequestType(type):
        return {
          ...state,
          data: {},
        };
      default:
        return state;
    }
  };

  return ReduceReducers(
    initialState,
    createApiReducer(initialState, type),
    createAttachDataReducer(initialState, type),
    resetDataReducer
  );
};

/**
 * a customizer function which is passed to lodash
 * it is checking if the merge can go ahead, it should do some special cases
 * when the object has some expanded data, and the new source data is not expanded
 * when returning undefined the _.merge will make the decision
 * https://lodash.com/docs/4.17.15#mergeWith
 * @param {*} dest - one of the value of the destination data structure
 * @param {*} src - one of the value of the source data structure
 */
const mergeDataUpdates = (dest, src) => {
  // when both are an array then we cannot make yet a decision on which one to take
  const bothAreArrays = _.isArray(dest) && _.isArray(src);
  if (!bothAreArrays) {
    return src;
  }

  const expandedVsUnexpanded =
    _.isObject(dest[0]) && typeof src[0] === "string";
  if (expandedVsUnexpanded) {
    return dest;
  }

  return src;
};

export {
  createApiReducer,
  createAttachDataReducer,
  createResetDataApiReducer,
  mergeDataUpdates,
};
