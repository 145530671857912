import template from "./translation-module.html";

class TranslationController {
  /**
   * @param {object} MessageService
   * @param {object} TranslationFactory
   * @param {object} UsersService
   * @param {object} LanguagesService
   * @param {object} GlobalParamsService
   * @param {object} NotificationService
   * @param {object} $q
   * @param {object} _
   */
  constructor(
    MessageService,
    TranslationFactory,
    UsersService,
    LanguagesService,
    GlobalParamsService,
    NotificationService,
    $q,
    _
  ) {
    this.MessageService = MessageService;
    this.TranslationFactory = TranslationFactory;
    this.UsersService = UsersService;
    this.LanguagesService = LanguagesService;
    this.GlobalParamsService = GlobalParamsService;
    this.NotificationService = NotificationService;
    this.$q = $q;
    this.translations = [];
    this._ = _;
    this.languageSetting = {
      language: "en",
    };

    this._onSaveMetaData = this._onSaveMetaData.bind(this);
  }

  /**
   * @returns {void}
   */
  init() {
    if (!this.isCreating) {
      this._getAvailableLanguages();
    }
    this._buildModalOptions();
    this._setupSubscriptions();
  }

  /**
   * @returns {void}
   */
  reset() {
    this.allowedLanguages = [];
    this.translations = [];
    this._getAvailableLanguages();
    this._buildModalOptions();
  }

  /**
   * _buildModalOptions
   * @returns {void}
   * @private
   */
  _buildModalOptions() {
    this.modalOptions = {
      title: "Select Language",
      channels: {
        save: `Modal.Translation.${this.data.uid}.save`,
      },
    };
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   * @private
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this.modalOptions.channels.save,
      (channel, data) => {
        this._changeLanguage(data);
      }
    );
    this.MessageService.subscribe(
      `${this.data.uid}.saveMetadata`,
      this._onSaveMetaData
    );
  }

  /**
   * remove Listeners
   * @private
   */
  _unregisterSubscriptions() {
    this.MessageService.unregisterChannel(this.modalOptions.channels.save);
    this.MessageService.off(
      `${this.data.uid}.saveMetadata`,
      this._onSaveMetaData
    );
  }

  _onSaveMetaData(channel, data) {
    this.data = angular.copy(data);
    this.reset();
  }

  /**
   * _savePermissions - updates entity permissions in the API on publish from modal
   * @param {object} data edited in modal
   * @returns {void}
   * @private
   */
  _changeLanguage(data) {
    this.GlobalParamsService.setLanguageWithPublish(data.language);
  }

  /**
   * build options object for modal / settings view
   * @returns {void}
   * @private
   */
  _buildSettingsModalOptions() {
    const options = this.allowedLanguages.map((allowedLanguage) => {
      const optionsObj = {
        id: allowedLanguage.slug,
        value: allowedLanguage.iso_code,
        display_name: allowedLanguage.name,
        subtext: allowedLanguage.iso_code,
      };

      return optionsObj;
    });
    this.TRANSLATION_MODULE_OPTIONS = [
      {
        name: "language",
        display_name: "Language",
        widget: "radio",
        options,
      },
    ];
  }

  /**
   * updates the displayAs property to either title or field from config
   * @param {object} translation
   * @private
   */
  _updateTranslationDisplayName(translation) {
    if (this.config.display_as) {
      translation.displayName = translation[this.config.display_as];
    } else {
      translation.displayName = translation.title;
    }
  }

  /**
   * add customer name to translation item block
   * @param {object} translation
   * @private
   * @returns {void}
   */
  _appendCustomerName(translation) {
    this.UsersService.getUserName(translation.language_modified_by)
      .then((customer) => {
        if (customer && customer.first_name) {
          translation.customerName = `${customer.first_name} ${customer.last_name}`;
        }
        this._updateTranslationDisplayName(translation);

        this.translations.push(translation);
      })
      .catch(() => {
        this.NotificationService.notifyRefresh();
      });
  }

  /**
   * get array of json body objects and make them into array of js objects
   * @param {object} translationBatchData
   * @private
   * @returns {void}
   */
  _buildTranslationList(translationBatchData) {
    translationBatchData.forEach((translationRaw) => {
      const translationList = JSON.parse(translationRaw.body);
      const translationListFiltered = translationList.filter(
        (translation) => translation.language_version_number !== null
      );
      const translation = this._.last(
        this._.orderBy(
          translationListFiltered,
          "language_version_number",
          "asc"
        )
      );
      this._appendCustomerName(translation);
    });
  }

  /**
   * @private
   * @returns {void}
   */
  _getVersionData() {
    this.TranslationFactory.getLanguageVersions(
      this.data.all_languages,
      this.data.self,
      this.config
    ).then((translationBatchData) => {
      this._buildTranslationList(translationBatchData);
    });
  }

  /**
   * remove any languages that are used already from list of available languages
   * @param {array} languagesAvailable languages available on system
   * @param {array} languagesFromEntity list of languages available on entity
   * @returns {Array} unusedLanguages
   * @private
   */
  _getUnusedLanguages(languagesAvailable, languagesFromEntity) {
    return languagesAvailable.filter(
      (languageObj) =>
        !this._.includes(languagesFromEntity, languageObj.iso_code)
    );
  }

  /**
   * @private
   * @returns {void}
   */
  _setAllowedLanguages() {
    this.LanguagesService.getLanguages().then((data) => {
      this.allowedLanguages = this._getUnusedLanguages(
        data,
        this.data.all_languages
      );
      this._getVersionData();
      this._buildSettingsModalOptions();
    });
  }

  /**
   * @private
   * @returns {void}
   */
  _getAvailableLanguages() {
    this._setAllowedLanguages();
  }

  /**
   * onDestroy Lifecycle
   */
  $onDestroy() {
    this._unregisterSubscriptions();
  }
}

/**
 * Translation directive
 * @returns {Object} directive
 */
function translationDirective() {
  return {
    restrict: "A",
    bindToController: {
      data: "=",
      config: "=",
      channel: "=",
      isCreating: "=",
    },
    scope: {},
    controller: TranslationController,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default translationDirective;
