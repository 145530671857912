import angular from "angular";
import imageCreateDirective from "./image-create/image-create.directive";
import imageUpdateDirective from "./image-update/image-update.directive";

const Imagery = angular
  .module("Skylark.Modules.Imagery", [])
  .directive("imageCreate", imageCreateDirective)
  .directive("imageUpdate", imageUpdateDirective);

export default Imagery;
