/**
 * @class NavigationService
 */
class NavigationService {
  /**
   * @constructor
   * @param   {Object} GlobalParamsService
   */
  constructor(GlobalParamsService) {
    this.GlobalParamsService = GlobalParamsService;
  }

  /**
   * Recurses items and flattens into single level array of items
   * @param    {Array} items
   * @returns  {Array}
   */
  flattenItems(items) {
    return items.reduce((allItems, currentGroup) => {
      if (currentGroup.items) {
        return allItems.concat(this.flattenItems(currentGroup.items));
      }

      return allItems.concat(currentGroup);
    }, []);
  }

  /**
   * Checks if navigation section has permissions to display based on user
   * @param    {Object} section
   * @param    {Object} permissions
   * @returns  {Boolean}
   */
  hasPermissions(section, permissions) {
    return !section.permission || permissions[section.permission];
  }
}

export default NavigationService;
