import React from "react";
import PropTypes from "prop-types";
import styles from "./link.scss";

/**
 * Link
 * @param {Object} props
 */
export default function Link(props) {
  const { url } = props.contentState.getEntity(props.entityKey).getData();

  return (
    <a href={url} className={styles.link}>
      {props.children}
    </a>
  );
}

Link.propTypes = {
  entityKey: PropTypes.string.isRequired,
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }).isRequired,
  children: PropTypes.arrayOf(PropTypes.object),
};

Link.defaultProps = {
  children: undefined,
};
