import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./text-placeholder-block.scss";

/**
 * Text Placeholder Block
 * @param {Object} props
 * @returns {JSX}
 */
export default function TextPlaceholderBlock(props) {
  const blockStyle =
    styles[
      `text-placeholder-block__${props.blockProps.config.get("field_name")}`
    ] || styles["text-placeholder-block__default"];

  return (
    <span
      className={classnames(styles["text-placeholder-block"], blockStyle)}
      data-text-placeholder={props.blockProps.config.get("display_name")}
    >
      &#8203;
    </span>
  );
}

TextPlaceholderBlock.propTypes = {
  blockProps: PropTypes.shape({
    config: PropTypes.shape({
      get: PropTypes.func,
    }),
  }).isRequired,
};
