/**
 * Service for tracking user activities
 * integrated with Matomo analytics
 */
class AnalyticsService {
  /**
   * @constructor
   * @param {Object} $transitions
   * @param {Object} $location
   */
  constructor($transitions, $location) {
    this.$transitions = $transitions;
    this.$location = $location;
  }

  /**
   * watch transition changes in order to track page changes
   */
  startTrackNavigation() {
    this.$transitions.onSuccess({}, (transition) =>
      this.pushPageChange(transition.to())
    );
  }

  /**
   * notify page change
   * @param {object} newState - https://ui-router.github.io/core/docs/latest/interfaces/state.statedeclaration.html
   */
  pushPageChange(newState) {
    this.push(["setCustomUrl", `${this.$location.path()}`]);
    this.push(["setDocumentTitle", newState.pageTitle]);
    this.push(["trackPageView"]);
  }

  /**
   * matomo specific push event
   * @param array
   */
  push(array) {
    if (window._paq) {
      window._paq.push(array);
    }
  }
}

export default AnalyticsService;
