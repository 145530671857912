/**
 * Images Factory
 *
 *
 *
 * @description Contains factory methods for working with
 * the images endpoint
 */

/**
 * Angular factory for Images
 * @param {object} _ - lodash
 * @param {object} $q - Angular service
 * @param {object} SchedulesFactory
 * @param {object} MediaFactory
 * @returns {object} - the factories public methods
 */
function ImagesFactory(_, $q, SchedulesFactory, MediaFactory) {
  const factory = {
    ...MediaFactory,
  };

  /**
   * getImagesGroupedBy - Fetches all image
   * objects in array and groups them for
   * presentation in order of image types
   * API response
   *
   * @param {Array} images - Array of image API endpoints
   * @param {String} groupBy - object property to group by
   * @returns {Promise} deferred promise
   *
   */
  factory.getImagesGroupedBy = (images, groupBy) => {
    const requests = [];
    const filter = {
      fields_to_expand: "schedule_urls",
    };

    requests.push(factory.getImageTypes());
    images.forEach((image) => requests.push(MediaFactory.get(image, filter)));

    return $q.all(requests).then((data) => {
      const types = _.map(data[0].objects, "name");
      const len = types.length;
      const unsorted = data.slice(1, data.length);

      const sorted = _.sortBy(unsorted, (image) => {
        const index = _.indexOf(types, image.type);

        return index === -1 ? len : index;
      });

      return _.chain(sorted)
        .groupBy(groupBy)
        .map((value, key) => {
          const mappedObject = {
            type: key,
            objects: value,
          };

          return mappedObject;
        })
        .value();
    });
  };

  /**
   * Get image types
   * @returns {Promise} - deferred promise
   */
  factory.getImageTypes = () => MediaFactory.get("/api/image-types/");

  /**
   * Updates a group
   * @param {array} group
   * @returns {Promise}
   */
  factory.updateGroup = (group) => {
    const requests = group.map((resource) =>
      MediaFactory.update(resource.self, resource)
    );

    return $q.all(requests);
  };

  /**
   * create - Creates new image
   * resource and uploads image. Returns notify
   * event for progress of image upload
   *
   * @param {object} postData - the data
   * @param {object} file - file to upload
   * @returns {Promise} - deferred promise
   */
  factory.createImage = (postData, file) =>
    MediaFactory.create("images", postData).then((data) => {
      if (data && data.uid) {
        if (file) {
          return MediaFactory.upload(data.upload_image_url, file);
        }

        return data;
      }
    });

  /**
   * creating an image with always schedule
   * @param postData
   * @param file
   * @return {Promise}
   */
  factory.createImageWithAlwaysSchedule = (postData, file) =>
    SchedulesFactory.getAlwaysSchedule().then((schedule) => {
      postData.schedule_urls = [schedule.self];
      return factory.createImage(postData, file);
    });

  return factory;
}

export default ImagesFactory;
