/**
 * @fileOverview
 * An attribute directive that allows a ul element
 * to be navigatable using the up and down keys.
 */

class NavigatableListCtrl {
  /**
   * @constructor
   * @param   {Object} $jQuery
   */
  constructor($jQuery) {
    this.$jQuery = $jQuery;
  }

  /**
   * navigateList - key bindings for navigation
   * @param   {$event} $event
   * @returns {void}
   */
  navigateList($event) {
    if ($event.keyCode === 40) {
      this.$jQuery($event.currentTarget).next().trigger("focus");
    } else if ($event.keyCode === 38) {
      this.$jQuery($event.currentTarget).prev().trigger("focus");
    }
  }
}

/**
 * navigatableListDirective
 * @returns {void}
 */
function navigatableListDirective() {
  return {
    restrict: "A",
    controller: NavigatableListCtrl,
    controllerAs: "attributeNavigatableList",
    link: (scope, element) => {
      element.on("keyup", "li", ($event) => {
        scope.attributeNavigatableList.navigateList($event);
      });
    },
  };
}

export default navigatableListDirective;
