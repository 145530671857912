const TOASTER_TYPES = {
  save: {
    successAction: "saved",
    errorAction: "saving",
    prefix: "",
  },
  create: {
    successAction: "created",
    errorAction: "creating",
    prefix: "",
  },
  add: {
    successAction: "added",
    errorAction: "adding",
    prefix: "",
  },
  get: {
    successAction: "retrieved",
    errorAction: "retrieving",
    prefix: "",
  },
  delete: {
    successAction: "deleted",
    errorAction: "deleting",
    prefix: "",
  },
  remove: {
    successAction: "removed",
    errorAction: "removing",
    prefix: "",
  },
  retrieve: {
    successAction: "retrieved",
    errorAction: "retrieving",
    prefix: "",
  },
  update: {
    successAction: "updated",
    errorAction: "updating",
    prefix: "",
  },
  discard: {
    successAction: "discarded",
    errorAction: "discarding",
    prefix: "Changes to ",
  },
  publish: {
    successAction: "published",
    errorAction: "publishing",
    prefix: "",
  },
  unpublish: {
    successAction: "unpublished",
    errorAction: "unpbublishing",
    prefix: "",
  },
};

const CATASTROPHIC_ERROR_MESSAGE = "Error, something went wrong - Refresh";

export { TOASTER_TYPES, CATASTROPHIC_ERROR_MESSAGE };
