const FEATURE_TOGGLE_CONSTANTS = {
  releaseCookieName: "skylark.releaseCookie",
  allowedValues: ["alpha"],
  alpha: [
    {
      name: "image-article-drawer",
      type: "component",
      dateIntroduced: "03/08/2018",
      alphaFiles: [
        "v2/app/features/editor/components/text-editor/text-editor.jsx",
      ],
      filesToDeprecate: [],
    },
  ],
};

export default FEATURE_TOGGLE_CONSTANTS;
