import { SET_DIMENSION, SET_LANGUAGE } from "./global-params.constant";

const initialState = {
  filters: {},
  language: "",
};

const GlobalParamsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIMENSION: {
      return {
        ...state,
        filters: action.filters,
      };
    }
    case SET_LANGUAGE: {
      return {
        ...state,
        language: action.language,
      };
    }
    default: {
      return state;
    }
  }
};

export default GlobalParamsReducer;
