import { cloneDeep } from "lodash";

import modalConstants from "components/_react/modal/modal-notification/modal-notification-constants";

/**
 * _replacePlaceholders
 * @param {object} configObject
 * @param {array} placeholderValues
 * @returns {Object} new copy object with replaced strings
 */
function _replacePlaceholders(configObject, placeholderValues) {
  const copy = cloneDeep(configObject);

  copy.body = copy.body.map((paragraph) => {
    if (paragraph.hasPlaceholders) {
      const placeholderRegex = /(\[\w+\])/g;
      const escapeTermRegex = /[[\\\]]+/g;
      const placeholderList = paragraph.text.match(placeholderRegex);

      placeholderList.forEach((placeholder) => {
        const term = placeholder.replace(escapeTermRegex, "");
        paragraph.text = paragraph.text.replace(
          placeholder,
          placeholderValues[term]
        );
      });
    }

    return paragraph;
  });

  return copy;
}

/**
 * getNotificationTypeConfig
 * @access public
 * @param   {string} type - the type of notification
 * @param  {Object} placeholderValues
 * @returns {object} the config for the notification
 */
function getNotificationTypeConfig(type, placeholderValues) {
  const notificationConstants = modalConstants[type] || modalConstants.default;

  return placeholderValues
    ? _replacePlaceholders(notificationConstants, placeholderValues)
    : notificationConstants;
}

export default getNotificationTypeConfig;
