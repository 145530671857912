import template from "./tooltip.html";

/**
 * @fileOverview tooltip Directive
 * renders a tooltip when user hovers over its parent element.
 */

/**
 * Tooltip directive
 *
 * @access private
 * @returns {object} - directive config
 */
function tooltipDirective() {
  return {
    restrict: "E",
    scope: {
      messageData: "=",
      messageString: "<",
      tooltipAlign: "@",
    },
    template,
    link: (scope, element) => {
      scope.tooltipAlign = scope.tooltipAlign || "left";
      /*
       * Use css rather than show/hide to use display block
       * Otherwise there will be positioning inconsistency between moz & webkit
       */
      element.parent().on("mouseover", () => {
        element.css({ display: "block" });
      });
      element.parent().on("mouseleave", () => {
        element.css({ display: "none" });
      });
    },
  };
}

export default tooltipDirective;
