import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import { createApiAction } from "store/api/api.actions";
import { ApiAction } from "store/api/api.types";
import { GET_TAG_CATEGORIES } from "./tag-categories.constants";

const fetchTagCategories = (): ApiAction => {
  const TagsFactory = Injector.get("TagsFactory");

  return createApiAction(GET_TAG_CATEGORIES, TagsFactory.getCategories());
};

export { fetchTagCategories };
