import TEXT_TRACK_UPLOAD from "store/text-track-upload/text-track-upload.constant";
import * as TextTrackActions from "store/text-track-upload/text-track-upload.action";
import * as UploadSelector from "store/upload/upload.selector";
import template from "./upload-progress-modal.html";

class UploadProgressModalController {
  /**
   * @constructor
   * @param ReduxConnector
   * @param OvpAssetUploadService
   * @param OvpAssetUploadStoreService
   * @param MessageService
   * @param RouteService
   * @param OVP_ASSET_UPLOAD
   */
  constructor(
    ReduxConnector,
    OvpAssetUploadService,
    OvpAssetUploadStoreService,
    MessageService,
    RouteService,
    OVP_ASSET_UPLOAD
  ) {
    this.ReduxConnector = ReduxConnector;
    this.OvpAssetUploadService = OvpAssetUploadService;
    this.OvpAssetUploadStoreService = OvpAssetUploadStoreService;
    this.MessageService = MessageService;
    this.RouteService = RouteService;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;
    this.STATUS_TYPES = this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE;

    this.connectToStore();
  }

  /**
   * get status text mapping to status of upload
   * @param {string} status
   * @param {string} fileSize
   * @returns {string}
   */
  getItemStatusText({ status, fileSize }) {
    switch (status) {
      case this.STATUS_TYPES.UPLOAD_FAILED:
      case this.STATUS_TYPES.UPLOAD_FAILED_S3:
      case TEXT_TRACK_UPLOAD.UPLOAD_FAILED:
        return "Failed";
      case this.STATUS_TYPES.UPLOAD_COMPLETE:
      case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE:
        return "Done";
      case this.STATUS_TYPES.UPLOAD_CANCELED:
        return "Cancelled";
      case this.STATUS_TYPES.INGEST_STARTED:
        return "Processing";
      case TEXT_TRACK_UPLOAD.INGEST_STARTED:
        return "Synchronising";
      case this.STATUS_TYPES.UPLOAD_PROGRESS:
      case this.STATUS_TYPES.UPLOAD_STARTED:
        return `${Math.round(fileSize / 1024 / 1024)}MB`;
      case TEXT_TRACK_UPLOAD.UPLOAD_PROGRESS:
        return "Uploading";
      case TEXT_TRACK_UPLOAD.DOCUMENT_CREATED:
        return "Upload finished";
      default:
        return "";
    }
  }

  /**
   * get class modifier for the item
   * @param upload
   * @returns {string}
   */
  getItemClassModifier({ status }) {
    switch (status) {
      case this.STATUS_TYPES.UPLOAD_FAILED:
      case this.STATUS_TYPES.UPLOAD_FAILED_S3:
      case TEXT_TRACK_UPLOAD.UPLOAD_FAILED:
        return "upload-progress-modal__item--error";
      case this.STATUS_TYPES.UPLOAD_COMPLETE:
      case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE:
        return "upload-progress-modal__item--complete";
      case this.STATUS_TYPES.UPLOAD_CANCELED:
        return "upload-progress-modal__item--canceled";
      default:
        return "";
    }
  }

  /**
   * get actions text mapping to status of upload
   * @param {string} status
   * @returns {string}
   */
  getItemActionsText({ status }) {
    switch (status) {
      case this.STATUS_TYPES.UPLOAD_PROGRESS:
        return "Cancel";
      case this.STATUS_TYPES.UPLOAD_COMPLETE:
      case this.STATUS_TYPES.UPLOAD_CANCELED:
      case this.STATUS_TYPES.UPLOAD_FAILED:
      case TEXT_TRACK_UPLOAD.UPLOAD_FAILED:
      case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE:
        return "Clear";
      case this.STATUS_TYPES.UPLOAD_FAILED_S3:
        return "Retry";
      default:
        return "";
    }
  }

  /**
   *
   * @param upload
   */
  onItemActionClick(upload) {
    const { status } = upload;
    switch (status) {
      case this.STATUS_TYPES.UPLOAD_PROGRESS: {
        this.cancelUpload(upload);
        break;
      }
      case this.STATUS_TYPES.UPLOAD_COMPLETE:
      case this.STATUS_TYPES.UPLOAD_CANCELED:
      case this.STATUS_TYPES.UPLOAD_FAILED: {
        this.removeUploadFromStore(upload);
        break;
      }
      case this.STATUS_TYPES.UPLOAD_FAILED_S3: {
        this.retryUpload(upload);
        break;
      }
      case TEXT_TRACK_UPLOAD.DOCUMENT_CREATED:
      case TEXT_TRACK_UPLOAD.UPLOAD_FAILED:
      case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE: {
        this.hideUploadFromModal(upload);
        break;
      }
    }
  }

  /**
   * test status for when the progress bar should be stopped
   * @param upload
   * @returns {boolean}
   */
  shouldStopProgressAnimation(upload) {
    return [
      this.STATUS_TYPES.UPLOAD_CANCELED,
      this.STATUS_TYPES.UPLOAD_COMPLETE,
      TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE,
      TEXT_TRACK_UPLOAD.DOCUMENT_CREATED,
    ].includes(upload.status);
  }

  /**
   * true when upload has error
   * @param upload
   * @return {boolean}
   */
  hasError(upload) {
    return [
      this.STATUS_TYPES.UPLOAD_FAILED,
      TEXT_TRACK_UPLOAD.UPLOAD_FAILED,
    ].includes(upload.status);
  }

  /**
   * cancel upload by sending a message
   * @param {string} accountUrl
   * @param {number} pelicanId
   * @param {string} entityId
   */
  cancelUpload({ accountUrl, entityId, pelicanId }) {
    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD,
      { accountUrl, entityId, pelicanId }
    );
  }

  /**
   * remove upload from store
   * @param {number} pelicanId
   */
  removeUploadFromStore({ pelicanId }) {
    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.REMOVE_UPLOAD_FROM_STORE,
      { pelicanId }
    );
  }

  /**
   *
   * @param upload
   */
  hideUploadFromModal(upload) {
    this.store.hideFromModal(upload);
  }

  /**
   *
   * @param {File} file
   * @param {string} accountUrl
   * @param {string} entityId
   * @param {number} pelicanId
   */
  retryUpload({ file, accountUrl, entityId, pelicanId }) {
    this.OvpAssetUploadService.upload(file, {
      accountUrl,
      entityId,
      pelicanId,
    });
  }

  /**
   * get url for the entities versions tab
   * @TYPE_NAME_FIX this can not be implemented in a robust manner, as relationships have type and name mixed up
   *
   * @param {string} uid - ovp upload is stored in entityId
   * @param {object} [entity] - text track upload is stored in entity
   * @return {string}
   */
  getUrl({ entityId, entity }) {
    let uid;

    if (entityId) {
      uid = entityId;
    }

    if (entity && entity.uid) {
      uid = entity.uid;
    }

    const entityUrl = this.RouteService.buildURL({
      name: "assets",
      uid,
    });

    return `${entityUrl}versions/`;
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...TextTrackActions,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    const uploads = UploadSelector.getUploads(state);
    return {
      uploads,
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

const uploadProgressModalComponent = {
  template,
  controller: UploadProgressModalController,
  controllerAs: "component",
};

export default uploadProgressModalComponent;
