import template from "./scheduling-information.html";

class SchedulingInformationController {
  /**
   * @constructor
   * @param {Object} SchedulingService
   */
  constructor(SchedulingService, momentJS, _) {
    this.SchedulingService = SchedulingService;
    this.momentJS = momentJS;
    this._ = _;
  }

  /**
   * Angular hook $onInit
   */
  $onInit() {
    this._setDisplayProperties();
  }

  /**
   * Angular hook $onInit
   */
  $onChanges() {
    this._setDisplayProperties();
  }

  /**
   * Setup the display properties for the component
   * @private
   */
  _setDisplayProperties() {
    this.isUnassigned = false;

    const isSingleSchedule =
      this.schedulesData &&
      (!this._.isArray(this.schedulesData) || this.schedulesData.length === 1);

    if (isSingleSchedule) {
      this.hasMultipleSchedules = false;
      const schedule = this._.isArray(this.schedulesData)
        ? this.schedulesData[0]
        : this.schedulesData;

      this._setSchedulingStatus(schedule);
      this._setDateString(schedule);
    } else if (this.schedulesData && this.schedulesData.length > 1) {
      this.buildStatusForMultipleSchedules();
    } else {
      this.isUnassigned = true;
    }
  }

  /**
   * Adds properties for items which only have one schedule
   * @param {Object} schedule
   * @private
   */
  _setSchedulingStatus(schedule) {
    this.hasRightsSchedules = schedule.rights;
    this.isFuture = this.SchedulingService.isFuture(schedule);
    this.isAvailable = this.SchedulingService.isAvailable(schedule);
    this.isExpired = this.SchedulingService.isExpired(schedule);
    this.isExpiring = this.isAvailable
      ? this.SchedulingService.isExpiring(schedule)
      : false;
    this.isBecomingActive = this.isFuture
      ? this.SchedulingService.isBecomingActive(schedule)
      : false;
  }

  /**
   * Filters attached schedules by a passed status
   * @param {string} status
   * @private
   */
  _filterScheduleByStatus(status) {
    return this.schedulesData.filter((schedule) => schedule.status === status);
  }

  /**
   * Adds properties for items which only have multiple schedules
   * @private
   */
  buildStatusForMultipleSchedules() {
    this.hasMultipleSchedules = true;
    this.hasRightsSchedules = this.schedulesData.some(
      (schedule) => schedule.rights
    );

    this.availableSchedules = this._filterScheduleByStatus("current");
    this.plannedSchedules = this._filterScheduleByStatus("future");
    this.expiredSchedules = this._filterScheduleByStatus("expired");

    this.hasSchedulesBecomingActive = this.plannedSchedules.length
      ? this.SchedulingService.hasSchedulesBecomingActive(this.plannedSchedules)
      : false;

    this.hasExpiringSchedules = this.availableSchedules.length
      ? this.SchedulingService.hasExpiringSchedules(this.availableSchedules)
      : false;

    this.hasExpiredSchedules = !!this.expiredSchedules.length;
  }

  /**
   * Add a date string for use in the dom
   * @private
   */
  _setDateString(schedule) {
    const dateFormat = "DD/MM/YY";
    const startDate = this.momentJS(schedule.starts).format(dateFormat);
    const endDate = schedule.ends
      ? this.momentJS(schedule.ends).format(dateFormat)
      : "";

    this.isInfinite = !schedule.ends;

    if (this.isExpiring) {
      this.dateString = this.SchedulingService.buildTimeRemaining(
        schedule.ends
      );
    } else if (this.isBecomingActive) {
      this.dateString = this.SchedulingService.buildTimeRemaining(
        schedule.starts
      );
    } else if ((this.isAvailable || this.isFuture) && !this.isInfinite) {
      this.dateString = `${startDate} \u2013 ${endDate}`;
    } else if (this.isInfinite) {
      this.dateString = `${startDate} \u2013 `;
    } else if (this.isExpired) {
      this.dateString = endDate;
    }
  }
}

const schedulingInformationComponent = {
  template,
  controller: SchedulingInformationController,
  controllerAs: "component",
  bindings: {
    schedulesData: "<",
    hasExpiredParent: "<",
  },
};

export default schedulingInformationComponent;
