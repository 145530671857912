import template from "./repeatable-multisearch.html";

/**
 * @fileOverview  Repeatable Multisearch
 * Renders multiple instances of a multisearch interface.
 * It enforces certain features, such as locking and
 * intercepts the message passing to parent view.
 */
class RepeatableMultisearchCtrl {
  /**
   * @constructor RepeatableMultisearch
   * @param   {Object} $scope
   * @param   {Object} MessageService
   * @param   {Object} QueryStringHelpersService
   * @param   {Object} ModalFactory
   * @param   {Object} _
   */
  constructor(
    $scope,
    MessageService,
    QueryStringHelpersService,
    ModalFactory,
    _
  ) {
    this.$scope = $scope;
    this.MessageService = MessageService;
    this.ModalFactory = ModalFactory;
    this.QueryStringHelpersService = QueryStringHelpersService;
    this._ = _;
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this.componentInstances = [];
    if (
      this.existingParams &&
      (this.existingParams.q || this.existingParams.ancestors)
    ) {
      this._appendExistingParams();
    } else {
      this.appendNewComponent();
    }
  }

  /**
   * appendNewComponent
   * @access  public
   * @param {object} param - param to pass in to each multisearch instance
   * @param {array} ancestors - ancestors value
   * @param {string} q - q value
   * @returns {void}
   */
  appendNewComponent(param, ancestors = null, q = null) {
    this.componentInstances.push(this._buildInstance(param, ancestors, q));
    this._setupNewSubscriptions(this.componentInstances.length - 1);
  }

  /**
   * appendExistingParams
   * @returns {void}
   */
  _appendExistingParams() {
    if (this.existingParams.q) {
      this._handleRelationshipParams();
    } else if (this.existingParams.ancestors) {
      this._handleAncestorParams();
    }
  }

  /**
   * handleRelationshipParams
   * @returns {void}
   */
  _handleRelationshipParams() {
    const relationshipParams = this.existingParams.q.split(" AND ");
    const relationshipParamsList = relationshipParams.map((param) =>
      this.QueryStringHelpersService.luceneParamsByType(param)
    );

    relationshipParamsList.forEach((param) => {
      const q = param
        .map((str) => {
          str.entity = str.entity === "title" ? this.entity : str.entity;

          return `${str.entity}:${str.q}`;
        })
        .filter((val) => val)
        .join(" OR ");
      this.appendNewComponent(param, param.ancestors, q);
    });
  }

  /**
   * handleAncestorParams
   * @returns {void}
   */
  _handleAncestorParams() {
    if (this.existingParams.ancestors) {
      this.QueryStringHelpersService.ancestorsByName({
        ancestors: this.existingParams.ancestors,
      }).then((ancestors) => {
        ancestors.forEach((ancestor) => {
          this.appendNewComponent(
            [
              {
                entity: ancestor.type.toLowerCase(),
                q: ancestor.title,
              },
            ],
            ancestor.ancestors,
            null
          );
        });
      });
    }
  }

  /**
   * destroyChannels
   * @returns {void}
   */
  destroyChannels() {
    this.componentInstances.forEach((curr, index) => {
      this.MessageService.unregisterChannel(`MultiSearch.chosenParams${index}`);
    });
  }

  /**
   * registerNewChannels
   * @param   {Number} index - index from ng-repeat
   * @returns {void}
   */
  _setupNewSubscriptions(index) {
    this.MessageService.registerChannel(`MultiSearch.chosenParams${index}`);
    this.MessageService.on(
      `MultiSearch.chosenParams${index}`,
      (channel, queryObj) => {
        this.componentInstances[index].query = queryObj;
        this._notifyParent();
      }
    );
  }

  /**
   * notifyParent
   * @access private
   * @returns {void}
   */
  _notifyParent() {
    this.MessageService.publish(
      "MultiSearch.Repeatable",
      this._buildQueryObject()
    );
  }

  /**
   * buildQueryObject
   * @access private
   * @returns {Object} query object resulting
   * from merging the query object from each multisearch
   */
  _buildQueryObject() {
    return {
      ancestors: this._mergeQueryParams("ancestors", ","),
      q: this._mergeQueryParams("q", " AND "),
    };
  }

  /**
   * mergeQueryParams
   * @access private
   * @param   {string} field  - ancestors or q
   * @param   {string} separator - AND or OR
   * @returns {string} merged param values
   */
  _mergeQueryParams(field, separator) {
    const arr = this.componentInstances
      .map((instance) => instance.query[field])
      .filter((value) => value)
      .map((value) => {
        const str = field === "q" ? `(${value})` : value;

        return this._.isString(str)
          ? str.replace(this.entity, this._findDisplayProperty())
          : str;
      });

    return this._joinQueryParams(arr, separator);
  }

  /**
   * find display property
   * @returns {String}
   */
  _findDisplayProperty() {
    return this.config.entities.find(
      (entityConfig) => entityConfig.entity === this.entity
    ).display_as;
  }

  /**
   * fallback for non arrays
   * @param   {array} arr - merged params by type
   * @param   {string} separator the specified separator
   * @returns {string} empty string or url of strings
   */
  _joinQueryParams(arr, separator) {
    const filteredArray = arr.filter((item) => item.length);
    if (filteredArray.length) {
      return filteredArray.join(separator);
    }
    return "";
  }

  /**
   * buildInstance
   * @access private
   * @param {array} ancestors
   * @param {string} q
   * @param {array} terms
   * @returns {object} epresents the instance of the widget
   * being output to the DOM
   */
  _buildInstance(terms = [], ancestors = null, q = null) {
    return {
      entity: this.entity,
      config: this.config,
      query: {
        ancestors,
        q,
      },
      currentExistingParams: terms,
    };
  }
}

/**
 * repeatableMultisearchDirective
 * @returns {Object} directive definition object
 * @todo  verify that this needs an isolate scope
 */
function repeatableMultisearchDirective() {
  return {
    restrict: "E",
    controller: RepeatableMultisearchCtrl,
    controllerAs: "component",
    template,
    bindToController: {
      config: "=",
      entity: "<",
      existingParams: "=",
    },
    scope: {},
    link: (scope, element) => {
      scope.component.init();

      element.on("$destroy", () => {
        scope.component.destroyChannels();
      });
    },
  };
}

export default repeatableMultisearchDirective;
