import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";

import ImagePlayback from "components/_react/editor/image-playback/image-playback";
import Image from "components/_react/image/image";

import styles from "./image-preview.scss";

/**
 * @class  Image Preview Component for rendering images in preview mode
 * @param {Object} props
 */
export default function ImagePreview(props) {
  if (isObject(props.data.media)) {
    const { url } = props.data.media;
    const isGif = url.includes(".gif") || url.includes(".webp");

    return (
      <Fragment>
        {isGif ? (
          <ImagePlayback src={url} />
        ) : (
          <div className={styles["image-preview"]}>
            <Image src={url} />
          </div>
        )}
      </Fragment>
    );
  }

  return null;
}

ImagePreview.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        url: PropTypes.string,
      }),
    ]),
  }).isRequired,
};
