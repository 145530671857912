import * as TextTrackActions from "store/text-track-upload/text-track-upload.action";
import backendServices from "skylarklib/constants/backend-services";
import NotificationMessage from "skylarklib/constants/notification-text";
import { getUploadsByEntityId } from "store/text-track-upload/text-track-upload.selector";

const FETCH_INTERVAL = 1000 * 3;
const PELICAN_FETCH_INTERVAL = 1000 * 5;

/**
 * Service to upload a new text track to an existing video asset
 */
class TextTrackUploadService {
  constructor(
    $q,
    $http,
    ApiService,
    $ngRedux,
    DocumentFactory,
    EntityFactory,
    SchedulesFactory,
    WindowService,
    UtilitiesService,
    GlobalParamsService,
    SkylarkApiInfoService,
    OVP_ASSET_UPLOAD
  ) {
    this.$q = $q;
    this.$http = $http;
    this.ApiService = ApiService;
    this.$ngRedux = $ngRedux;
    this.DocumentFactory = DocumentFactory;
    this.EntityFactory = EntityFactory;
    this.SchedulesFactory = SchedulesFactory;
    this.WindowService = WindowService;
    this.UtilitiesService = UtilitiesService;
    this.GlobalParamsService = GlobalParamsService;
    this.SkylarkApiInfoService = SkylarkApiInfoService;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;
  }

  /**
   * initiating the upload can handle url or file
   * the upload progress is a multi step progress
   * 1. create a document object with the url|file
   * 2. take the url from the document and pass it to Pelican,
   *    to update the Video with the text track
   * 3. Checking the status until complete, when complete we are done
   *
   * In case of errors the service returns an exception with a message why it happened
   *
   * @param {File} [options.file]
   * @param {String} [options.url]
   * @param {String} options.filename
   * @param {String} options.entity
   * @return {Promise<Object>}
   */
  upload(options) {
    const { file, url } = options;

    if ((!url && !file) || (url && file)) {
      return this.$q.reject(new TypeError("You must define an url or a file"));
    }

    this.WindowService.attachOnBeforeUnloadWarning(options.entity.uid);

    return this.createTextTrack(options)
      .then((options) => this.createDocument(options))
      .catch((error) => this.onError(error, options));
  }

  /**
   * creating a text track linked to the asset_url
   * @param {Object} options
   * @return {Promise}
   */
  createTextTrack(options) {
    const { entity } = options;
    const data = {
      asset_urls: [entity.self],
    };
    const filter = {
      fields: [
        "asset_urls",
        "document_urls",
        "self",
        "uid",
        "is_closed_captions",
        "external_track_url",
        "display_label",
        "language",
        "language_code",
      ].join(","),
    };

    return this.SchedulesFactory.getAlwaysSchedule()
      .then((schedule) => {
        data.schedule_urls = [schedule.self];

        return this.EntityFactory.create("text-tracks", data, filter);
      })
      .then((data) => {
        options.textTrack = data;

        // the first time there is a uid which can be used through out the process of upload
        this.$ngRedux.dispatch(
          TextTrackActions.startUpload({
            id: data.uid,
            ...options,
          })
        );

        this.$ngRedux.dispatch(
          TextTrackActions.textTrackCreated({
            id: data.uid,
            textTrack: data,
          })
        );

        return options;
      });
  }

  /**
   * @param {object} options
   * @return {Promise}
   */
  createDocument(options) {
    const { url, file, filename, textTrack } = options;

    const data = {
      title: filename,
      file_name: filename,
      content_url: textTrack.self,
    };

    if (url && !file) {
      data.document_location = url;
    }

    if (file && !url) {
      data.file = file;
    }

    return this.DocumentFactory.createDocumentWithAlwaysSchedule(data)
      .then(null, null, (progress) =>
        this.updateDocumentUploadProgress(options, progress)
      )
      .then((data) => {
        options.document = data;
        this.$ngRedux.dispatch(
          TextTrackActions.documentCreated({
            id: textTrack.uid,
            document: data,
          })
        );

        this.WindowService.detachOnBeforeUnloadWarning(options.entity.uid);

        return options;
      });
  }

  /**
   * synchronise text track with provider, when language code changes and update is required to reflect that in the video
   * @param options
   * @return {Promise}
   */
  synchroniseTextTrack(options, assetHasOvp) {
    return this.attachUrl(options, assetHasOvp)
      .then((options) => {
        if (options) {
          if (__V8_ENVIRONMENT__) {
            if (assetHasOvp) this.fetchStatusUntilComplete(options);
          } else {
            this.fetchStatusFromPelicanUntilComplete(options);
          }
        }
      })
      .catch((error) => this.onError(error, options));
  }

  /**
   * ingesting uploaded document's url to the asset
   * @param {String} options.document.url
   * @param {String} options.textTrack.uid
   * @param {String} options.entity.uid
   * @return {Promise}
   */
  attachUrl(options, assetHasOvp) {
    const { document, entity, textTrack } = options;

    if (typeof document.url !== "string") {
      return this.$q.reject(new TypeError("Url should be a string"));
    }

    return this.SkylarkApiInfoService.hasPelican().then((hasPelican) => {
      if (hasPelican) {
        this.$ngRedux.dispatch(TextTrackActions.startIngest(textTrack.uid));

        return this.ApiService.patch(
          `${backendServices.pelicanPath}/ingest/${entity.uid}`,
          {
            cc_file: document.url,
            text_track_uid: textTrack.uid,
          }
        ).then((data) => {
          options.pelican = data;

          return options;
        });
      }
      if (__V8_ENVIRONMENT__ && assetHasOvp) {
        this.$ngRedux.dispatch(TextTrackActions.startIngest(textTrack.uid));
        return this.ApiService.post(
          `/api/text-tracks/${textTrack.uid}/sync-upstream/`
        ).then(() => options);
      }
    });
  }

  /**
   * the status needs to be checked in an interval until complete
   * a text track may update multiple file formats,
   * for simplicity if one is failing then all failing
   * or all complete then upload is done
   *
   * @param {Object} options.pelican
   * @param {Array<Object{status: String, id: String}>} options.pelican.details
   * @param {String} options.pelican.provider
   * @param {String} options.entity.uid
   * @param {String} options.textTrack.uid
   * @return {Promise}
   */
  fetchStatusFromPelicanUntilComplete(options) {
    const { pelican, textTrack } = options;
    const { details } = pelican;
    const oneHasError = details.some(
      ({ status }) => status === "update_error" || status === "error"
    );
    const allComplete = details.every(({ status }) => status === "complete");

    if (oneHasError) {
      return this.$q.reject(new Error("Ingest Error"));
    }

    if (!allComplete) {
      this.$ngRedux.dispatch(TextTrackActions.startIngest(textTrack.uid));

      return this.UtilitiesService.delayPromise(PELICAN_FETCH_INTERVAL)
        .then(() => this.getTextTrackStatusFromPelican(options))
        .then((options) => this.fetchStatusFromPelicanUntilComplete(options));
    }

    this.$ngRedux.dispatch(TextTrackActions.completeUpload(textTrack.uid));

    return this.$q.resolve(options);
  }

  /**
   * the status needs to be checked in an interval until complete
   * The workflow-service may have a delay in resetting the state from COMPLETED to QUEUED/PROCESSING
   * Due to this we have a seenToBeProcessing flag which becomes true when the text track is seen as QUEUED/PROCESSING
   * We also use timesSeenAsCompleted to mark the text track as completed in the event that we never receive a  QUEUED/PROCESSING state
   *
   * @param {String} options.textTrack.uid
   * @param {Boolean} seenToBeProcessing
   * @param {Number} timesSeenAsCompleted
   * @return {Promise}
   */
  fetchStatusUntilComplete(
    options,
    seenToBeProcessing = false,
    timesSeenAsCompleted = 0
  ) {
    const { textTrack } = options;
    return this.UtilitiesService.delayPromise(FETCH_INTERVAL).then(() =>
      this.getTextTrackStatus(options).then((data) => {
        const { ingest_status, status_description } = data;

        if (
          ingest_status ===
          this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.FAILED
        ) {
          return this.$q.reject(
            new Error(
              `Ingest Error${
                status_description ? `: ${status_description}` : ""
              }`
            )
          );
        }

        if (
          ingest_status ===
          this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.COMPLETED
        ) {
          // If seenToBeProcessing is still false after receiving the COMPLETED status multiple times, mark the sync as completed
          const ignoreSeenToBeProcessingFlag = timesSeenAsCompleted > 9;
          if (seenToBeProcessing || ignoreSeenToBeProcessingFlag) {
            this.$ngRedux.dispatch(
              TextTrackActions.completeUpload(textTrack.uid)
            );
            return this.$q.resolve(options);
          }
          timesSeenAsCompleted++;
        }

        if (
          ingest_status ===
            this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.QUEUED ||
          ingest_status ===
            this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.PROCESSING
        ) {
          seenToBeProcessing = true;
        }

        this.$ngRedux.dispatch(TextTrackActions.startIngest(textTrack.uid));
        return this.fetchStatusUntilComplete(
          options,
          seenToBeProcessing,
          timesSeenAsCompleted
        );
      })
    );
  }

  /**
   * updating progress of file upload
   * @param {Object} options
   * @param {Object} options.textTrack.uid
   * @param {Number} progress
   */
  updateDocumentUploadProgress(options, progress) {
    const percentage = progress / 100;
    this.$ngRedux.dispatch(
      TextTrackActions.updateProgress(options.textTrack.uid, percentage)
    );
  }

  /**
   * get manifest status
   * @param {String} options.textTrack.uid
   * @param {String} options.entity.uid
   * @return {Promise}
   */
  getTextTrackStatusFromPelican(options) {
    return this.SkylarkApiInfoService.hasPelican().then((hasPelican) => {
      if (hasPelican) {
        return this.ApiService.get(
          `${backendServices.pelicanPath}/ingest/${options.entity.uid}/text-tracks/${options.textTrack.uid}/status`
        ).then((data) => {
          options.pelican = { ...options.pelican, ...data };
          return options;
        });
      }
    });
  }

  /**
   * get ingest status for text track using workflow-service
   * @param {String} options.textTrack.uid
   * @param {String} options.entity.uid
   * @return {Promise}
   */
  getTextTrackStatus(options) {
    return this.EntityFactory.get("text-tracks", options.textTrack.uid, {
      ...this.GlobalParamsService.getDefaultParams,
      fields: ["self", "uid", "ingest_status", "status_description"].join(","),
    });
  }

  /**
   * Error event handler
   * bubble up the error so component can handle notifications
   * check if all required objects for a valid text track handling are existing, if one of the items is missing
   * it deletes documents and text track
   * @param {Error} error
   * @param {Object} options
   * @param {String} options.entity.uid
   * @param {String} options.textTrack.uid
   * @param {String} [options.document.url]
   * @return {Promise<Error>}
   */
  onError(error, options) {
    const { entity, textTrack, document } = options;

    this.WindowService.detachOnBeforeUnloadWarning(entity.uid);

    if (textTrack && textTrack.uid && error.code !== "not_found") {
      this.$ngRedux.dispatch(TextTrackActions.failedUpload(textTrack.uid));
    }

    if (!textTrack || !document || !document.url) {
      this.deleteUpload(options);
    }

    return this.$q.reject(error);
  }

  /**
   * fetching the existing text tracks based on assets, and puts them into the store
   * the fetch is filtering out known text tracks for that entity as we know already the state of it
   * the inserted text track will not have a status yet, that needs to be initiated by another function.
   * @param {String} assetId
   */
  recoverTextTracks(assetId) {
    const ignoreTextTracks = getUploadsByEntityId(
      this.$ngRedux.getState(),
      assetId
    );
    const filteredTextTracksUids = ignoreTextTracks
      .map(({ textTrack }) => textTrack.uid)
      .join(",");

    return this.EntityFactory.getAll("text-tracks", {
      ...this.GlobalParamsService.getDefaultParams(),
      assets__uid: assetId,
      "-uid": filteredTextTracksUids,
      fields: [
        "self",
        "uid",
        "is_closed_captions",
        "external_track_url",
        "display_label",
        "language",
        "data_source_id",
        "language_code",
        "document_urls",
        "document_urls__file_name",
        "document_urls__url",
        "document_urls__uid",
      ].join(","),
      fields_to_expand: ["document_urls"].join(","),
    }).then((response) => {
      const textTracks = response.objects;

      textTracks.forEach((textTrack) => {
        const options = {
          id: textTrack.uid,
          entity: {
            uid: assetId,
          },
          textTrack,
          document: textTrack.document_urls[0], // text track and documents have a "1:1" relationship
        };

        this.$ngRedux.dispatch(TextTrackActions.addUpload(options));

        if (options.document) {
          this.$ngRedux.dispatch(TextTrackActions.documentCreated(options));
        }

        return this.getTextTrackStatusFromPelican(options)
          .then((options) => {
            if (options)
              return this.fetchStatusFromPelicanUntilComplete(options);
          })
          .catch((error) => this.onError(error, options));
      });
    });
  }

  /**
   * delete uploaded resources
   * @param {String} [options.entity.uid]
   * @param {String} [options.textTrack.uid]
   * @return {Promise}
   */
  deleteUpload(options) {
    const { textTrack } = options;

    if (!textTrack || !textTrack.uid) {
      return this.$q.reject(NotificationMessage.generalError);
    }

    // deleting text track, will remove document, and from ovp if linked
    return this.EntityFactory.delete("text-tracks", textTrack.uid).then(() => {
      this.$ngRedux.dispatch(TextTrackActions.clearUpload(textTrack.uid));
    });
  }
}

export default TextTrackUploadService;
