/**
 * check value if isEmpty
 * @param _
 * @return {*}
 */
function isEmpty(_) {
  return _.isEmpty;
}

export default isEmpty;
