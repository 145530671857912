import NotificationMessage from "skylarklib/constants/notification-text";

/**
 * @classdesc All actions. Action creators are individual files while
 * simple actions live here.
 */
class SetContentsActionCreators {
  /**
   * @constructor
   * @param {Object} SetContentsDispatcher
   * @param {Object} EntityTypeHelpersService
   * @param {Object} GlobalParamsService
   * @param {Object} SET_CONTENTS_ACTIONS
   * @param {Object} AsyncSetActions
   * @param {Object} ItemDecorationActions
   * @param {Object} SortingActions
   */
  constructor(
    SetContentsDispatcher,
    EntityTypeHelpersService,
    GlobalParamsService,
    NotificationService,
    SET_CONTENTS_ACTIONS,
    SetContentsStore,
    AsyncSetActions,
    ItemDecorationActions,
    PositioningActions,
    SortingActions,
    UpdateItemActions,
    EntityCreationActions,
    PreviewActions
  ) {
    this.SetContentsDispatcher = SetContentsDispatcher;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
    this.GlobalParamsService = GlobalParamsService;
    this.NotificationService = NotificationService;
    this.SET_CONTENTS_ACTIONS = SET_CONTENTS_ACTIONS;
    this.SetContentsStore = SetContentsStore;

    this.fetchSetData = AsyncSetActions.fetchSetData;
    this.saveSetData = AsyncSetActions.saveSetData;
    this.decorateItems = ItemDecorationActions.decorateItems;
    this.assignAppearanceStatus = ItemDecorationActions.assignAppearanceStatus;
    this.assignActiveStatus = ItemDecorationActions.assignActiveStatus;
    this.positionItem = PositioningActions.positionItem;
    this.sortItems = SortingActions.sortItems;
    this.updateItem = UpdateItemActions.updateItem;
    this.updateScheduledItem = UpdateItemActions.updateScheduledItem;
    this.updateScheduledItemWithLanguages =
      UpdateItemActions.updateScheduledItemWithLanguages;
    this.updateContentAndScheduleItem =
      UpdateItemActions.updateContentAndScheduleItem;
    this.createEntity = EntityCreationActions.createEntity;
    this.fetchPreviewItems = PreviewActions.fetchPreviewItems;
    this.generateDisplayPositions = PreviewActions.generateDisplayPositions;
  }

  /**
   * togglePreviewMode
   * Switches the page into togglePreviewMode
   */
  togglePreviewMode() {
    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.togglePreviewMode,
    });
  }

  /**
   * toggleEditMode
   * Switches the page into edit mode
   */
  toggleEditMode() {
    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.toggleEditMode,
    });
  }

  /**
   * addItems
   * @param {Object} contentItem
   */
  addItem(contentItem) {
    const type =
      contentItem.set_type_slug ||
      this.EntityTypeHelpersService.getRawType(contentItem.self);

    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.addItem,
      contentItem,
      type,
      currentLanguage: this.GlobalParamsService.getLanguage(),
    });

    this.decorateItems();
    this.assignActiveStatus();
    this.sortItems();

    const contentTypeString =
      this.NotificationService.createNotificationString(type);

    this.NotificationService.notifyInfo(
      NotificationMessage.addToSetSuccess(contentTypeString)
    );
  }

  /**
   * removeScheduledItem
   * @param {Object} scheduledItem
   */
  removeScheduledItem(scheduledItem) {
    const fullItem = this.SetContentsStore.getState().items.find(
      (item) => item.uid === scheduledItem.uid
    );

    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.removeScheduledItem,
      scheduledItem,
    });

    this.decorateItems();
    this.sortItems();

    const contentTypeString = this.NotificationService.createNotificationString(
      fullItem.type
    );

    this.NotificationService.notifyInfo(
      NotificationMessage.removeFromSetSuccess(contentTypeString)
    );
  }

  /**
   * removeScheduledItem
   * @param {Array} scheduledItems
   */
  removeMultipleScheduledItems(scheduledItems) {
    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.removeMultipleScheduledItems,
      scheduledItems,
    });

    this.decorateItems();
    this.sortItems();

    this.NotificationService.notifyInfo(
      NotificationMessage.multipleRemoveFromSetSuccess
    );
  }

  /**
   * discardChanges
   */
  discardChanges() {
    const { title } = this.SetContentsStore.getState().set;

    this.SetContentsDispatcher.dispatch({
      actionType: this.SET_CONTENTS_ACTIONS.discardChanges,
    });

    this.NotificationService.notifyInfo(
      NotificationMessage.discardSuccess(title)
    );
  }
}

export default SetContentsActionCreators;
