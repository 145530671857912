import template from "./image-with-metadata.html";

/**
 * @NOTICE
 * Becuase properties are applied to this component's scope and no way to pass it back to its
 * parent component through flux, we have to mutate item urls on parent data.
 * This is bad because we have to rely on mutations and therefore data
 * is not passed in a unidirectional flow.
 */
class ImageWithMetadataController {
  /**
   * @constructor
   */
  constructor(SetContentsActionCreators, MessageService) {
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.MessageService = MessageService;
    this.parentData.image_urls =
      this.parentData.image_urls && this.parentData.image_urls.length
        ? this.parentData.image_urls
        : [
            {
              uid: this.parentData.uid,
              content_url: this.parentData.self || "",
            },
          ];
    this.data = this.parentData.image_urls[0];
    this.isDisabled = this.parentData.hasBeenAdded;

    this._setupSubscriptions();
  }

  /**
   * Setup pub / sub subscription
   * @private
   */
  _setupSubscriptions() {
    const uid = this.data.uid || this.parentData.uid;

    this.MessageService.subscribe(`ImageUploader.${uid}`, (channel, image) => {
      this.hasImageBeenRemoved = false;
      this.data.imageFile = image.file;
      this.data.imagePreview = image.filePreview;
    });

    this.MessageService.subscribe(`ImageUploader.Remove.${uid}`, () => {
      this.hasImageBeenRemoved = true;
      this.data.imageFile = null;
      this.data.imagePreview = null;
    });
  }

  /**
   * $onDestroy
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(
      `ImageUploader.${this.parentData.uid}`
    );
    this.MessageService.unregisterChannel(
      `ImageUploader.Remove.${this.parentData.uid}`
    );
  }
}

const imageWithMetadataComponent = {
  bindings: {
    isModal: "<",
    parentData: "=",
    fields: "<",
    modalOptions: "<",
  },
  template,
  controller: ImageWithMetadataController,
  controllerAs: "module",
};

export default imageWithMetadataComponent;
