import _ from "lodash";
import OVP_ASSET_UPLOAD from "skylarklib/angularjs/services/ovp-asset-upload/ovp-asset-upload.constant";

const initialState = {};

const OvpAssetUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED: {
      return {
        ...state,
        [action.data.pelicanId]: {
          ...action.data,
          status: action.type,
        },
      };
    }
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS: {
      return {
        ...state,
        [action.data.pelicanId]: {
          ...state[action.data.pelicanId],
          progress: action.data.progress.percentage,
          status: action.type,
        },
      };
    }
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED: {
      return {
        ...state,
        [action.data.pelicanId]: {
          ...state[action.data.pelicanId],
          progress: 1,
          status: action.type,
        },
      };
    }
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE:
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED: {
      return {
        ...state,
        [action.data.pelicanId]: {
          ...state[action.data.pelicanId],
          status: action.type,
          status_description: action.data.status_description,
        },
      };
    }
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3: {
      return {
        ...state,
        [action.data.pelicanId]: {
          ...state[action.data.pelicanId],
          progress: null,
          status: action.type,
        },
      };
    }
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED:
    case OVP_ASSET_UPLOAD.MESSAGE_SERVICE.REMOVE_UPLOAD_FROM_STORE: {
      if (action.data && action.data.pelicanId) {
        return _.omit(state, [action.data.pelicanId]);
      }
      return state;
    }
    default:
      return state;
  }
};

export default OvpAssetUploadReducer;
