/**
 * Toasters reducer
 * @param   {Object} state - toasters
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = { newToast: {} }, action) {
  switch (action.type) {
    case "TOGGLE_TOASTER":
      return { ...state, newToast: {} };
    case "POP_TOAST":
      return { ...state, newToast: action.toast };
    default:
      return state;
  }
}
