import OVP_ASSET_UPLOAD from "skylarklib/angularjs/services/ovp-asset-upload/ovp-asset-upload.constant";

/**
 * start upload
 * @param data
 * @return {{data: *, type: string}}
 */
const startUpload = (data) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
  data,
});

/**
 * update progress of uploading asset to remote
 * @param pelicanId
 * @param progress
 * @return {{data: {progress: *, pelicanId: *}, type: string}}
 */
const updateProgress = (pelicanId, progress) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
  data: {
    pelicanId,
    progress,
  },
});

/**
 * start ingest to pelican
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const startIngest = (pelicanId) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
  data: {
    pelicanId,
  },
});

/**
 * upload is complete
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const completeUpload = (pelicanId) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
  data: {
    pelicanId,
  },
});

/**
 * cancel upload
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const cancelledUpload = (pelicanId) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
  data: {
    pelicanId,
  },
});

/**
 * failed upload during any step
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const failedUpload = (pelicanId, errorMessage) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
  data: {
    pelicanId,
    status_description: errorMessage,
  },
});

/**
 * failure when uploading to s3
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const failedUploadToS3 = (pelicanId) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
  data: {
    pelicanId,
  },
});

/**
 * remove upload from store
 * @param pelicanId
 * @return {{data: {pelicanId: *}, type: string}}
 */
const clearUpload = (pelicanId) => ({
  type: OVP_ASSET_UPLOAD.MESSAGE_SERVICE.REMOVE_UPLOAD_FROM_STORE,
  data: {
    pelicanId,
  },
});

export {
  startUpload,
  updateProgress,
  startIngest,
  completeUpload,
  cancelledUpload,
  failedUpload,
  failedUploadToS3,
  clearUpload,
};
