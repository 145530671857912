/**
 * Banner
 *
 * @author Lewis Nixon <lewis.nixon@ostmodern.co.uk>
 * @copyright 2015 OstModern
 *
 * @description Render Banners
 */

class BannerDirectiveCtrl {
  /**
   * @constructor
   * @param   {Object} $scope
   * @param   {Object} $timeout
   * @param   {Object} EditingService
   * @param   {Object} MessageService
   * @param   {Object} ModalTriggerService
   */
  constructor(
    $scope,
    $timeout,
    EditingService,
    MessageService,
    ModalTriggerService
  ) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.EditingService = EditingService;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;

    this.parentEntity = undefined;
    this.linkUrl = undefined;
    this.isSavingInProgress = false;
    this._buildNotificationOptions();
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this.setSubscriptions();
    this._buildNotificationOptions();
    if (this.parentEntity) {
      this.setLinkUrl();
    }
  }

  /**
   * Angular hook called on destory
   * @returns {void}
   */
  $onDestroy() {
    this.MessageService.unregisterChannel("setConfirm.discardAndToggle");
    this.MessageService.unregisterChannel("setConfirm.saveAndToggle");
  }

  /**
   * setSubscriptions
   * @returns {void}
   */
  setSubscriptions() {
    this.MessageService.subscribe("setConfirm.discardAndToggle", () =>
      this.discard()
    );

    this.MessageService.subscribe("setConfirm.saveAndToggle", () => {
      this.save();
      this._toggle();
    });
  }

  /**
   * setLinkUrl
   * @returns {void}
   */
  setLinkUrl() {
    if (this.parentEntity && this.parentEntity.childOf) {
      this.linkUrl = `${this.parentEntity.childOf}`;
    } else {
      this.linkUrl = `/${this.parentEntity.parentType}/${this.parentEntity.parentId}`;
    }
  }

  /**
   * popupRequired
   * @returns {void}
   */
  popupRequired() {
    if (this.EditingService.hasChanged) {
      this.ModalTriggerService.triggerNotification(this.notificationOptions);
    } else {
      this._toggle();
      this._finish();
    }
  }

  /**
   * toggle
   * @returns {void}
   */
  _toggle() {
    if (this.$scope.toggle) {
      this.$scope.toggle();
    }

    this.EditingService.setHasChangedFalse();
    this.EditingService.isEditing = false;
    this.MessageService.publish("setContent:updateFilter");
  }

  /**
   * saveToScope
   * @returns {void}
   */
  saveToScope() {
    this.isSavingInProgress = true;
    this.$scope.save();

    this.$timeout(() => {
      this.isSavingInProgress = false;
      this.EditingService.setHasChangedFalse();
      this._finish();
    }, 1000);
  }

  /**
   * save
   * @returns {void}
   */
  save() {
    if (!this.isSavingInProgress) {
      this.saveToScope();
    }
  }

  /**
   * undo
   * @returns {void}
   */
  undo() {
    this.$scope.undo();
    this.EditingService.setHasChangedFalse();
  }

  /**
   * discard
   * @returns {void}
   */
  discard() {
    this.toggle();
    this.undo();
  }

  /**
   * finish editing and call back to scope method
   * @returns {void}
   */
  _finish() {
    this.$scope.finish();
  }

  /**
   * _buildNotificationOptions
   * @returns {void}
   */
  _buildNotificationOptions() {
    this.notificationOptions = {
      notificationType: "discardChanges",
      channels: {
        alternative: "setConfirm.discardAndToggle",
        confirm: "setConfirm.saveAndToggle",
      },
    };
  }
}

/**
 * bannerDirective
 * @param   {Object} UiHelpersService
 * @param   {Object} UtilitiesService
 * @param   {Object} $templateCache
 * @param   {Object} $q
 * @param   {Object} $compile
 * @returns {Object} Directive Definition Object
 */
function bannerDirective(
  UiHelpersService,
  UtilitiesService,
  $templateCache,
  $q,
  $compile
) {
  return {
    restrict: "A",
    scope: {
      save: "&",
      finish: "&",
      undo: "&",
    },
    bindToController: {
      parentEntity: "=",
      toggle: "&",
      entityName: "@",
    },
    controller: BannerDirectiveCtrl,
    controllerAs: "component",
    link: (scope, element, attrs) => {
      const options = {
        template: attrs.template,
        triggeredBy: attrs.triggeredBy,
      };

      scope.component.init();

      /*
       * creating a DOM element here avoids an infinite digest loop by not
       * passing "element" to compile
       */
      const banner = angular.element("<div></div>");

      /**
       * Makes the banner visible
       * @returns {void}
       */
      function showBanner() {
        $q.when(UiHelpersService.loadTemplate(options.template)).then(
          (template) => {
            banner.html(template);
            $compile(banner)(scope);
            element.append(banner);
          }
        );
      }

      /**
       * Hides the banner
       * @returns {void}
       */
      function removeBanner() {
        element.children().remove();
      }

      attrs.$observe("triggeredBy", (bannerActive) => {
        if (UtilitiesService.toBoolean(bannerActive)) {
          showBanner();
        } else {
          removeBanner();
        }
      });
    },
  };
}

export default bannerDirective;
