/**
 * @fileOverview  Modal Notification Service
 * Service to handle modal popovers.
 * Gets copy and keeps track of disabled notifications.
 */
/**
 * @memberOf  Components.Modal
 */
class ModalNotificationService {
  /**
   * @constructor
   * @param   {Object} $cookies
   * @param   {Object} MODAL_NOTIFICATION
   * @param   {Object} MessageService
   * @param   {Object} _
   */
  constructor($cookies, MODAL_NOTIFICATION, MessageService, _) {
    this.$cookies = $cookies;
    this.MODAL_NOTIFICATION = MODAL_NOTIFICATION;
    this.MessageService = MessageService;
    this._ = _;
    this.cookieName = "skylark.disabledNotifications";

    this.init();
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this.disabledTypes = this.$cookies.getObject(this.cookieName) || [];
  }

  /**
   * getNotificationTypeConfig
   * @access public
   * @param   {string} type - the type of notification
   * @param  {Object} placeholderValues
   * @returns {object} the config for the notification
   */
  getNotificationTypeConfig(type, placeholderValues) {
    const notificationConfig =
      this.MODAL_NOTIFICATION[type] || this.MODAL_NOTIFICATION.default;

    return placeholderValues
      ? this._replacePlaceholders(notificationConfig, placeholderValues)
      : notificationConfig;
  }

  /**
   * _replacePlaceholder
   * @param {object} configObject
   * @param {array} placeholderValues
   * @returns {Object} new copy object with replaced strings
   */
  _replacePlaceholders(configObject, placeholderValues) {
    const copy = this._.cloneDeep(configObject);
    copy.body = copy.body.map((paragraph) => {
      if (paragraph.hasPlaceholders) {
        const placeholderRegex = /(\[\${\w+}])/g;
        const escapeTermRegex = /[[${}\]]+/g;

        const placeholderList = paragraph.text.match(placeholderRegex);

        placeholderList.forEach((placeholder) => {
          const term = placeholder.replace(escapeTermRegex, "");
          paragraph.text = paragraph.text.replace(
            placeholder,
            placeholderValues[term]
          );
        });
      }

      return paragraph;
    });

    return copy;
  }

  /**
   * triggerDefaultAction
   * @access public
   * @param {Object} notificationOptions
   * @returns {boolean}
   */
  triggerDefaultAction(notificationOptions) {
    if (
      this._isNotificationTypeDisabled(notificationOptions.notificationType)
    ) {
      this.MessageService.publish(notificationOptions.channels.confirm);

      return true;
    }

    return false;
  }

  /**
   * isNotificationTypeDisabled
   * @access private
   * @param {string} notificationType
   * @returns {Boolean}
   */
  _isNotificationTypeDisabled(notificationType) {
    return !!this.disabledTypes.find((type) => type === notificationType);
  }

  /**
   * getDisabled
   * @returns {array}
   */
  getDisabled() {
    return this.disabledTypes;
  }

  /**
   * updateDisabled
   * @access public
   * @param {string} notificationType
   * @returns {void}
   */
  updateDisabled(notificationType) {
    this.disabledTypes.push(notificationType);
    this.$cookies.putObject(this.cookieName, this.disabledTypes);
  }

  /**
   * resetDisabled
   * @access public
   * @returns {void}
   */
  resetDisabled() {
    this.disabledTypes.length = 0;
    this.$cookies.remove(this.cookieName);
  }
}

export default ModalNotificationService;
