import _ from "lodash";
import {
  FILTER_TO_PAYLOAD,
  SCHEDULE_STATUSES,
} from "skylarklib/constants/search-and-filter";
import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import { getEntityTypeFilterKey } from "./entities";

const searchQueryRegex = /\*(.*)\*/;
const propertyQueryRegex = /(.+):(.+)/;
const statuses = SCHEDULE_STATUSES.map(({ slug }) => slug);

/**
 * helper function to simplify the construction of object
 * @param bucket
 * @param name
 * @param value
 * @return {{bucket, payload: {value}, name}}
 */
const generateValueLabel = ({ bucket, name, value }) => ({
  name,
  bucket,
  payload: {
    value,
  },
});
/**
 * Transform the params from a query shape to an activeFilters shape
 * this is the opposite of the generateApiQuery
 * @param {object} params
 * @return {object}
 */
export const generateActiveFilter = (params) => {
  const map = {
    dynamicProperties: [],
  };
  const keys = _.keys(params);

  _.forEach(keys, (key) => {
    const bucket = FILTER_TO_PAYLOAD[key] || "nonIndexedProperty";
    const isStatus = statuses.includes(key);
    const value = _.isFinite(Number(params[key]))
      ? Number(params[key])
      : params[key];
    const name = key;

    if (key === "q") {
      /*
       * q = *searchquery*
       * q = *searchquery* AND category:genres
       * q = *searchquery* AND category:genres AND cms_roles:/api/cms-roles/
       */
      const queries = value.split(" AND ");
      _.each(queries, (query) => {
        if (query.match(searchQueryRegex)) {
          map.dynamicProperties.push(
            generateValueLabel({
              bucket: "search",
              name: "search",
              value: query.replace(searchQueryRegex, "$1"),
            })
          );
          return;
        }
        if (query.match(propertyQueryRegex)) {
          map.dynamicProperties.push(
            generateValueLabel({
              bucket: "property",
              name: query.replace(propertyQueryRegex, "$1"),
              value: query.replace(propertyQueryRegex, "$2"),
            })
          );
        }
      });
      return;
    }

    if (isStatus) {
      map.dynamicProperties.push(
        generateValueLabel({
          bucket,
          name: "status",
          value: key,
        })
      );
      return;
    }

    map.dynamicProperties.push(generateValueLabel({ bucket, name, value }));
  });

  return map;
};

/**
 * generate defaults for certain entities
 * @param entityName
 * @param entityType
 * @param config
 * @return {{dynamicProperties: []}}
 */
export const generateDefaultActiveFilter = (entityName, entityType, config) => {
  const map = {
    dynamicProperties: [],
  };

  if (config && config.default_order) {
    map.dynamicProperties.push(
      generateValueLabel({
        bucket: "pagination",
        name: "order",
        value: config.default_order,
      })
    );
  }

  if (entityName && entityType && getEntityTypeFilterKey(entityName)) {
    map.dynamicProperties.push(
      generateValueLabel({
        bucket: "nonIndexedProperty",
        name: getEntityTypeFilterKey(entityName),
        value: entityType,
      })
    );
  }

  if (["editorial-schedules", "licensing"].includes(entityName)) {
    map.dynamicProperties.push(
      generateValueLabel({
        bucket: "nonIndexedProperty",
        name: "rights",
        value: entityName === "licensing",
      })
    );
  }

  return map;
};

/**
 * transform from and active filter shape to a query shape
 * this is the opposite of the generateActiveFilter
 * @param {object} activeFilter
 * @return {object}
 */
export const generateApiQuery = (activeFilter) => ({
  ...buildQ(activeFilter.search, activeFilter.property),
  ...buildPaginationQuery(activeFilter.pagination),
  ...buildScheduleQuery(activeFilter.schedule),
  ...buildNonIndexedPropertyQuery(activeFilter.nonIndexedProperty),
});
/**
 * combine the properties which go in the q parameter
 * @param {object} search
 * @param {object} property
 * @return {undefined|{q: string}}
 */
export const buildQ = (search, property) => {
  const q = [buildSearchQuery(search), buildPropertyQuery(property)]
    .filter((item) => !!item)
    .join(" AND ");
  return q && { q };
};
/**
 * combining key and search term to a string which can be put in to the q property
 * @param {object} property
 * @param {string} property.value
 * @return {undefined|string}
 */
export const buildSearchQuery = (property) => {
  if (!property) {
    return;
  }

  const { key } = property;
  let { value } = property;

  if (value === "" || !value) {
    return;
  }
  value = `*${value}*`;
  return key ? `${key}:${value}` : value;
};
/**
 * combining multiple objects of property filters to a string which can be put in to the q property
 * @param {object} properties - a range of properties which need be combined by an AND
 * @return {undefined|string}
 */
export const buildPropertyQuery = (properties) => {
  if (!properties) {
    return;
  }
  return _.flatMap(properties, (item, key) => {
    if (item && _.isObject(item)) {
      return `${key}:${item.value}`;
    }
    return `${key}:${item}`;
  }).join(" AND ");
};
/**
 * mapping to a key value pair for URL search
 * @param {object} properties
 * @return {undefined|object}
 */
export const buildPaginationQuery = (properties) => {
  if (!properties) {
    return;
  }
  return _.mapValues(properties, (item) => {
    if (item && _.isObject(item)) {
      item = item.value;
    }
    return item;
  });
};
/**
 * mapping to a key value pair for URL search
 * @param {object} properties
 * @return {undefined|object}
 */
export const buildNonIndexedPropertyQuery = (properties) => {
  if (!properties) {
    return;
  }
  return _.mapValues(properties, (item) => {
    if (item && _.isObject(item)) {
      item = item.value;
    }
    return String(item);
  });
};
/**
 * combination of schedule oriented filters like a device type dimension, or the licence status
 * as always all=true is the filter which ignores andy dimension filter, therefore when this is set no other dimension is used in the query
 * when you want to set a dimension like device type, you need to remove the status all=true as well
 * @param schedule
 * @return {undefined|object}
 */
export const buildScheduleQuery = (schedule) => {
  const GlobalParamsService = Injector.get("GlobalParamsService");

  if (!schedule) {
    return;
  }

  if (schedule.status.value === "all") {
    return GlobalParamsService.getDefaultParams();
  }

  const scheduleQuery = {};

  Object.keys(schedule).forEach((key) => {
    const { value } = schedule[key];
    if (key === "status") {
      scheduleQuery[value] = "true";
      return;
    }
    scheduleQuery[key] = value;
  });

  return scheduleQuery;
};
