import angular from "angular";
import DEFAULT_ITEM_CONFIG from "./constants/default-item-config.constant";
import FIELDS_TO_REQUEST from "./constants/fields-to-request.constant";
import SetContentsActions from "./set-contents-actions/set-contents-actions.module";
import SetContentsDispatcher from "./set-contents-dispatcher/set-contents-dispatcher.module";
import SetContentsStore from "./set-contents-store/set-contents-store.module";

const Shared = angular
  .module("Skylark.Pages.Sets.Shared", [
    SetContentsActions.name,
    SetContentsDispatcher.name,
    SetContentsStore.name,
  ])
  .constant("DEFAULT_ITEM_CONFIG", DEFAULT_ITEM_CONFIG)
  .constant("FIELDS_TO_REQUEST", FIELDS_TO_REQUEST);

export default Shared;
