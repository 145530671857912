import { createSelector } from "reselect";
import { initialState } from "./entity-listing.reducer";
import {
  ActiveFilters,
  ApiQuery,
  GenericSelectorParams,
} from "./entity-listing.types";
import { NONE_TYPE } from "../entity-lisitng-page.constant";
import { MODAL_LISTING_NAMESPACE } from "../modal-listing.constants";

export const getEntityListingData: GenericSelectorParams = (
  state,
  entityName,
  entityType,
  namespace
) => getInstance(state, entityName, entityType, namespace).data;

export const getEntityListingLoading: GenericSelectorParams = (
  state,
  entityName,
  entityType,
  namespace
): boolean => getInstance(state, entityName, entityType, namespace).loading;

export const getActiveFiltersByEntity: GenericSelectorParams = (
  state,
  entityName,
  entityType,
  namespace
): ActiveFilters =>
  getInstance(state, entityName, entityType, namespace).activeFilters;

export const getApiQuerySelector: GenericSelectorParams = (
  state,
  entityName,
  entityType,
  namespace
): ApiQuery => getInstance(state, entityName, entityType, namespace).apiQuery;

export const getCountApiQuerySelector = createSelector(
  (state, entityName, entityType, namespace) =>
    getInstance(state, entityName, entityType, namespace),
  (instance) => instance.countQuery
);

/**
 * getting the correct slice from the entityListing based on its entity and entity type
 * if the entity has no type then the data is attached to the NONE_TYPE key
 */
export const getInstance = (
  { entityListing, modalListing },
  entityName: string,
  entityType = NONE_TYPE,
  namespace: string
) => {
  if (namespace === MODAL_LISTING_NAMESPACE) {
    return modalListing;
  }

  if (!entityListing) {
    return initialState;
  }

  if (entityListing[entityName] && entityListing[entityName][entityType]) {
    return entityListing[entityName][entityType];
  }

  return initialState;
};
