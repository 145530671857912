class EditingService {
  /**
   * @constructor
   */
  constructor() {
    /**
     * Defines if anything has changed
     * @type {boolean}
     */
    this.hasChanged = false;

    /**
     * Defines if the page is currently in an editing state
     * @type {boolean}
     */
    this.isEditing = false;

    /**
     * Defines if the fields are read only or not
     * @type {boolean}
     */
    this.isReadOnly = false;
  }

  /**
   * Enable Editing
   * @memberOf  editingthis
   * @description : enable this editing state on
   * @returns {void}
   */
  enableEditing() {
    this.isEditing = true;
  }

  /**
   * Disable Editing
   * @memberOf  editingthis
   * @description : disable this editing state on
   * @returns {void}
   */
  disableEditing() {
    this.isEditing = false;
  }

  /**
   * Disable Editing
   * @memberOf  editingthis
   * @description : toggle this editing state off
   * @returns {void}
   */
  toggleEditing() {
    this.isEditing = !this.isEditing;
  }

  /**
   * Toggles the status of hasChanged
   * @returns {void}
   */
  toggleChange() {
    this.hasChanged = !this.hasChanged;
  }

  /**
   * Manages the status of the controls
   * @returns {void}
   */
  setHasChangedTrue() {
    this.hasChanged = true;
  }

  /**
   * Manages the status of the controls
   * @returns {void}
   */
  setHasChangedFalse() {
    this.hasChanged = false;
  }

  /**
   * Manages if the editable fields are read only or not
   * @returns {void}
   */
  setReadOnly() {
    this.isReadOnly = true;
  }

  /**
   * Manages if the editable fields are read only or not
   * @returns {void}
   */
  unsetReadOnly() {
    this.isReadOnly = false;
  }
}

export default EditingService;
