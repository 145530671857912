import Api from "skylarklib/helpers/api/api";
import * as ApiRequestConfig from "skylarklib/helpers/api-request-config/api-request-config";
import * as ApiFilteringAndExpanding from "skylarklib/helpers/api-filtering-and-expanding/api-filtering-and-expanding";
import Entity from "skylarklib/helpers/entity/entity";
import * as Schedules from "skylarklib/helpers/schedules/schedules";

/**
 * get image
 * @param   {String} entity
 * @param   {Object} data
 * @returns {Promise}
 * @todo  finish this & Change this
 */
function get(entity, uid) {
  return Entity.get(entity, uid);
}

/**
 * create - creates an image on the api
 * @param {Object} postData - the data required to create article image resource
 */
function createImage(file) {
  return Schedules.getAlwaysSchedule().then((schedule) => {
    const postData = { schedule_urls: [schedule.data.self] };

    return this.createImageMetadata(postData).then((image) => {
      if (image.data.uid && file) {
        return this.uploadImage(image.data.upload_image_url, file);
      }

      return Promise.resolve();
    });
  });
}

/**
 * uploadImage
 * @param  {string} uploadUrl
 * @param  {Object} file
 */
function uploadImage(uploadUrl, file) {
  return Api.postMultipart(
    uploadUrl,
    file,
    ApiRequestConfig.build({
      multipart: true,
    })
  );
}

/**
 * createImageMetaData
 * @param  {Object} postData
 * @param  {Object} file
 */
function createImageMetadata(data) {
  return Api.post("/api/images/", data);
}

/**
 * _setContentAttribute
 * @param {Object} data
 * @param {String} contentType
 * @return {String} Content
 */
function _setContentAttribute(data, contentType) {
  switch (contentType) {
    case "image":
      return `<img src="${data.url}" />`;
    case "video":
      return `<div>${data.title}</div>`;
    default:
      return "<p></p>";
  }
}

/**
 * _buildParams
 * @returns {Object}
 */
function _buildParams(config) {
  return ApiFilteringAndExpanding.build(config);
}

/**
 * _expandMediaFromItems
 * @param  {Array} items
 * @return {Array}
 */
function _expandMediaFromItems(items, config) {
  return items.map((item) => {
    if (item.media) {
      return Api.get(item.media, {
        params: _buildParams(config.items_fields_to_filter[item.content_type]),
      })
        .then((data) => ({
          ...item,
          media: data.data,
          content: _setContentAttribute(data.data, item.content_type),
        }))
        .catch(() => Promise.resolve(item).then(() => null));
    }

    return Promise.resolve(item).then((result) => result);
  });
}

/**
 * expandEntityItemMedia
 * @param  {Array} items
 * @return {Array}
 */
function expandEntityItemMedia(items, config) {
  if (!items.length) {
    return Promise.resolve(items).then((results) => results);
  }

  return Promise.all(_expandMediaFromItems(items, config)).then((result) =>
    result.filter((item) => !!item)
  );
}

export {
  get,
  createImage,
  uploadImage,
  createImageMetadata,
  expandEntityItemMedia,
};
