import angular from "angular";
import versionsDirective from "./version.directive";
import VersionsFactory from "./version.factory";

const Version = angular
  .module("Skylark.Modules.Version", [])
  .directive("versionModule", versionsDirective)
  .factory("VersionsFactory", VersionsFactory);

export default Version;
