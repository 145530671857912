import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchBaseConfig } from "store/entities-config/entities-config.action";
import { getBaseConfig } from "store/entities-config/entities-config.selector";

import { MODAL_LISTING_NAMESPACE } from "store/listing/modal-listing.constants";
import {
  getApiQuerySelector,
  getEntityListingData,
  getEntityListingLoading,
} from "store/listing/entity-listing/entity-listing.selector";
import {
  fetchEntityListing,
  resetSearchFilter,
  setActiveFilters,
} from "store/listing/entity-listing/entity-listing.actions";
import ModalList from "./modal-list";

const mapProps = (state, props) => ({
  configuration: getBaseConfig(state, props.options.metadata.entityType).data,
  modalList: getEntityListingData(
    state,
    undefined,
    undefined,
    MODAL_LISTING_NAMESPACE
  ).results,
  loading: getEntityListingLoading(
    state,
    undefined,
    undefined,
    MODAL_LISTING_NAMESPACE
  ),
  query: getApiQuerySelector(
    state,
    undefined,
    undefined,
    MODAL_LISTING_NAMESPACE
  ),
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      fetchBaseConfig,
      fetchEntityListing,
      resetSearchFilter,
      setActiveFilters,
    },
    dispatch
  );

export default connect(mapProps, mapDispatch)(ModalList);
