import { generateRandomKey, Toaster } from "skylarklib/helpers";
import { CATASTROPHIC_ERROR_MESSAGE } from "skylarklib/constants/toaster.constants";

import actionNames from "./toaster.actions.constants";

/**
 * _toggleToasterState
 * @returns {Object}
 */
function _toggleToasterState() {
  return {
    type: actionNames.TOGGLE_TOASTER,
  };
}

/**
 * _popToast
 * @param {Array} toasts
 * @returns {Object}
 */
function _popToast(toast) {
  return {
    type: actionNames.POP_TOAST,
    toast,
  };
}

/**
 * toggleToasterState
 * @return {function}
 */
function toggleToasterState() {
  return (dispatch) => {
    dispatch(_toggleToasterState());
  };
}

/**
 * Pops info toast
 * @return {function} dispatch
 */
function popToast(message, type) {
  return (dispatch) => {
    dispatch(
      _popToast({
        message,
        type,
        id: generateRandomKey(),
      })
    );
  };
}

/**
 * Pops info toast
 * @return {function} dispatch
 */
function popInfoToast(title, verb) {
  const message = Toaster.build(title, verb, "info");

  return (dispatch) => {
    dispatch(popToast(message, "info"));
  };
}

/**
 * popErrorToast
 * @param   {String} title
 * @param   {String} verb
 * @returns {Function}
 */
function popErrorToast(title, verb) {
  const message = Toaster.build(title, verb, "error");

  return (dispatch) => {
    dispatch(popToast(message, "error"));
  };
}

/**
 * pops unrecoverable error toast
 * @returns {function} dispatch
 */
function popCatastrophicErrorToast() {
  const message = CATASTROPHIC_ERROR_MESSAGE;

  return (dispatch) => {
    dispatch(popToast(message, "error"));
  };
}

export {
  toggleToasterState,
  popInfoToast,
  popErrorToast,
  popCatastrophicErrorToast,
};
