const NotificationMessage = {
  generalError: "Something went wrong, please try again",
  refreshError: "Error, something went wrong - Refresh",
  saveSuccess: (name) => `Successfully saved "${name}"`,

  createSuccess: (name) => `You have successfully created ${name}`,
  createError: (name) =>
    `Something went wrong when creating ${name}. Please try again.`,

  addSuccess: (name) => `You have successfully added ${name}`,
  addError: (name) =>
    `Something went wrong when adding ${name}. Please try again.`,

  updateSuccess: (name) => `You have successfully updated ${name}`,
  updateError: (name) =>
    `Something went wrong when updating ${name}. Please try again.`,

  deleteSuccess: (name) => `You have deleted ${name}`,
  deleteError: (name) =>
    `Something went wrong when deleting ${name}. Please try again.`,

  removeSuccess: (name) => `You have successfully removed ${name}`,
  removeError: (name) => `Something went wrong when removing ${name}`,

  publishSuccess: (name) => `Successfully published "${name}"`,
  unpublishSuccess: (name) => `Successfully unpublished "${name}"`,

  removeFileSuccess: "You have successfully removed a file.",
  removeFileError: "Sorry, file couldn't be removed.",

  updateDataSourceIdSuccess: "You have successfully changed data source id.",
  updateDataSourceIdError:
    "Something went wrong when trying to change data source id.",

  // sets
  addToSetSuccess: (name) => `Successfully added ${name} to the set`,
  removeFromSetSuccess: (name) => `Successfully removed ${name} from the set`,
  multipleRemoveFromSetSuccess:
    "Successfully removed multiple items from the set",
  updateContentInSetSuccess: (name) =>
    `Successfully updated ${name} in the set`,

  // miscellaneous success message
  passwordResetMailSuccess: "A password reset email has been sent", // pragma: allowlist secret
  scheduleUpdateSuccess: "Your schedule settings have been updated.",
  saveArticleSuccess: (name) => `You have successfully ${name} this article.`,
  discardSuccess: (name) => `Discarded all changes to "${name}"`,

  // miscellaneous error messages
  urlError: "Check the url or contact support",
  saveTagError: "Something went wrong when saving a tag.",
  saveTalentError: "Something went wrong when saving a talent.",
  downloadError:
    "Something went wrong when trying to download the file. Please try again.",
  viewingError: "Something went wrong when retrieving the viewing.",
  bookmarkError: (action) =>
    `Something went wrong when trying to ${action} a bookmark.`,
  imageMissingError: "You must add an image.",
  imageTypeMissingError: "You must select an image type.",
  updatePositionError: "Something went wrong while updating Positions.",
  languageLoadingError: "Something went wrong when loading languages",
  invalidSlugError:
    "Enter a valid 'slug' consisting of letters, numbers, underscores or hyphens.",
  saveCreditsError: "Something went wrong when saving a credit.",
};

export default NotificationMessage;
