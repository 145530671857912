import angular from "angular";

const ErrorRoute = angular.module("ErrorRoute", []).config(router);

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("Error", {
    url: "/error",
    pageTitle: "Error 404",
    views: {
      main: {
        component: "error404",
      },
    },
  });
}

export default ErrorRoute;
