import React from "react";
import PropTypes from "prop-types";
import { buildURL } from "skylarklib/helpers/entities";
import { ConfigResult } from "../search-results/configResult";

export interface SearchResultComponent {
  configResult: ConfigResult;
  result: {
    uid: string;
    displayTitle: string;
  };
}

const SearchResult = ({ configResult, result }: SearchResultComponent) => {
  const { url } = configResult;
  const { uid, displayTitle } = result;
  const href = buildURL({ ...url, uid });

  return (
    <div className="search-results__result" key={uid}>
      <a href={href}>{displayTitle}</a>
    </div>
  );
};

SearchResult.propTypes = {
  configResult: PropTypes.shape({
    url: PropTypes.shape({
      name: PropTypes.string,
      type: PropTypes.string,
    }),
  }),
  result: PropTypes.shape({
    uid: PropTypes.string,
    self: PropTypes.string,
  }),
};

export default SearchResult;
