import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import { DATE_FORMAT, TRANSITIONING_SETTINGS } from "../schedules.constants";

import styles from "./schedules-date.scss";

/**
 * Renders the date information for a Schedule.
 * There are two supported formats:
 * Absolute dates, in the format specified in the settings;
 * Relative dates, which are either in days and hours or days and minutes, depending
 * on whether the duration is longer than a day.
 * @export
 * @class SchedulesDate
 * @extends {Component}
 */
export default class SchedulesDate extends Component {
  static propTypes = {
    scheduleStatus: PropTypes.string.isRequired,
    schedule: PropTypes.shape({
      status: PropTypes.oneOf(["expired", "current", "future"]),
      starts: PropTypes.string,
      ends: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    }).isRequired,
  };

  /**
   * gets a display date.
   * @param {Object} a schedule shaped object
   * @param {String} the type of schedule
   * @returns {String} A DOM friendly string.
   * @memberof SchedulesDate
   */
  getDisplayDate = (schedule, scheduleType) => {
    if (scheduleType === "expiring" || scheduleType === "planned") {
      return this._getRelativeDate(schedule);
    }

    return this._getAbsoluteDate(schedule);
  };

  /**
   * builds a display string containing a relative, human readable amount of time representing
   * the time difference between a given datetime and now.
   * @memberof SchedulesDate
   */
  _getRelativeDate(schedule) {
    const { field } = TRANSITIONING_SETTINGS[schedule.status];
    const timeDelta = moment(schedule[field]).diff(moment());
    const duration = moment.duration(timeDelta);

    const dates = {
      days: duration.get("days"),
      hours: duration.get("hours"),
      minutes: duration.get("minutes"),
    };

    return Object.keys(dates)
      .filter((key) => !!dates[key])
      .slice(0, 2)
      .map((key) => `${dates[key]} ${dates[key] > 1 ? key : key.slice(0, -1)}`)
      .join(" ");
  }

  /**
   * Builds a display string containing the textual information representing absolute date range in
   * the specified format.
   * @param {Object} schedule
   * @returns {String} - String with the date information.
   * @memberof SchedulesDate
   */
  _getAbsoluteDate(schedule) {
    const starts = this._formatDate(schedule.starts);
    const ends = schedule.ends ? this._formatDate(schedule.ends) : "";

    if (schedule.status === "expired") {
      return ends;
    }

    return `${starts} - ${ends}`;
  }

  /**
   * @param {String} date
   * @returns {String} Formatted date in specified format
   * @memberof SchedulesDate
   * @todo extract into helpers
   */
  _formatDate(date) {
    return moment(date).format(DATE_FORMAT);
  }

  /**
   * Whether to display the infinity icon (for schedules with no end).
   * @memberof SchedulesDate
   * @returns {Boolean}
   */
  hasInfinite = () =>
    !this.props.schedule.ends && this.props.scheduleStatus !== "planned";

  /**
   * Renders the component.
   * @returns {JSX}
   * @memberof SchedulesDate
   */
  render() {
    return (
      <div className={styles["schedules-date"]}>
        <div className={styles["schedules-date__text"]}>
          {this.getDisplayDate(this.props.schedule, this.props.scheduleStatus)}
        </div>
        {this.hasInfinite() && (
          <SkylarkIcon
            iconName="infinity"
            extraClassName={styles["schedules-date__icon"]}
          />
        )}
      </div>
    );
  }
}
