import { createSelector } from "reselect";
import { getVersionConfig } from "store/entities-config/entities-config.selector";
import { getAccounts } from "store/accounts/accounts.selector";

/**
 * getActiveAccounts - returns the active accounts as listed in the config
 * Will return a single enabled workflow-account and all enabled non-workflow accounts
 * @param {*} accounts
 * @param {*} config
 * @returns array of active accounts
 */
export const getActiveAccounts = (accounts, config) => {
  if (
    Array.isArray(accounts) &&
    config &&
    Array.isArray(config.accountsWithDirectUpload)
  ) {
    const enabledAccounts = accounts.filter(
      (account) =>
        (config.accountsWithDirectUpload.includes(account.name) &&
          // If the account does not have the account enabled property, set it to active for compatiblity
          !Object.prototype.hasOwnProperty.call(account, "enabled")) ||
        account.enabled
    );

    // Show all non-workflow-service accounts
    const activeAccounts = enabledAccounts.filter(
      (account) => !account.name.startsWith("workflow-service-")
    );

    // Only show a single workflow-service account
    const workflowServiceAccount = enabledAccounts.find((account) =>
      account.name.startsWith("workflow-service-")
    );
    if (workflowServiceAccount) {
      activeAccounts.push(workflowServiceAccount);
    }

    return activeAccounts;
  }

  return [];
};

/**
 * filter accounts which create assets by upload
 * @param {Object} state
 * @param {Object} entity
 * @param {String} entityType
 * @returns {Array}
 */
export const filterAccountsWithUpload = createSelector(
  [getAccounts, getVersionConfig],
  ({ data: accounts }, { data: config }) => getActiveAccounts(accounts, config)
);
