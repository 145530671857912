import angular from "angular";
import ovpAssetBlockComponent from "./ovp-asset-block/ovp-asset-block.component";
import videoJsBlockComponent from "./videojs-block/videojs.component";

const OvpAsset = angular
  .module("Skylark.Components.OvpAsset", [])
  .component("ovpAssetBlock", ovpAssetBlockComponent)
  .component("videoJsBlock", videoJsBlockComponent);

export default OvpAsset;
