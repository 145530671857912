import sanitizeHTML from "sanitize-html";
import options from "./sanitize-html.constants";

/**
 * sanitize
 * @param {String} a string containing HTML
 * @returns {String} sanitised HTML as a string
 */
function sanitize(dirtyHTML) {
  return sanitizeHTML(
    dirtyHTML,
    Object.assign(options, {
      transformTags: {
        a: sanitizeHTML.simpleTransform("a", { target: "_blank" }),
      },
    })
  );
}

export { sanitize };
