import angular from "angular";
import SCHEDULE_TYPES from "./entity-listing.constants";
import entityListingPageComponent from "./entity-listing.controller";

const EntityListing = angular
  .module("Skylark.Pages.Entity.EntityListing", [])
  .constant("SCHEDULE_TYPES", SCHEDULE_TYPES)
  .component("entityListingPage", entityListingPageComponent);

export default EntityListing;
