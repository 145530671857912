import template from "./content-listing-block.html";
/**
 * @classdesc View actions for the content-listing blocks in set contents
 */
class contentListingBlockController {
  /**
   * @constructor
   * @param {Object} SetContentsActionCreators
   * @param {Object} PreviewActions
   */
  constructor(SetContentsActionCreators) {
    this.SetContentsActionCreators = SetContentsActionCreators;
  }

  /**
   * Update items to display on change
   */
  $onChanges() {
    this.itemsToDisplay = this.items;

    if (this.itemsToDisplay) {
      this.hasAppearanceSettings = this.itemsToDisplay.some((item) => {
        const appearanceConfig = item?.config?.columns?.find(
          (column) => column?.widget === "itemAppearance"
        );
        return !!appearanceConfig;
      });
    }

    if (this.items && this.previewItems && !this.isEditing) {
      this.itemsToDisplay = this.items.filter(
        (item) => this.previewItems.indexOf(item.uid) > -1
      );

      this.SetContentsActionCreators.generateDisplayPositions();
    }
  }

  /**
   * Remove all the items in the module
   */
  removeAllItems() {
    const scheduleItemsLanguageVersion = this.items.map(
      (item) => item.scheduledItem[item.displayedLanguage]
    );
    this.SetContentsActionCreators.removeMultipleScheduledItems(
      scheduleItemsLanguageVersion
    );
  }
}

const ContentListingBlockComponent = {
  bindings: {
    items: "<",
    previewItems: "<",
    isEditing: "<",
    type: "@",
  },
  template,
  controller: contentListingBlockController,
  controllerAs: "component",
};

export default ContentListingBlockComponent;
