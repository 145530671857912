import { createSelector } from "reselect";

/**
 * takes the state and entityId to find all text track uploads matching the id
 *
 * @param {Object} - state
 * @param {String} - entityId
 * @return {Array} matching uploads
 */
const getUploadsByEntityId = createSelector(
  [(state) => state.textTracks, (state, entityId) => entityId],
  (uploads, entityId) => {
    if (!entityId) {
      return [];
    }
    return (
      Object.values(uploads).filter(
        (upload) =>
          upload.entity && upload.entity.uid && upload.entity.uid === entityId
      ) || []
    );
  }
);

/**
 * get all language codes from text tracks matching the entity id
 * @param {Object} - state
 * @param {String} - entityId
 * @return {Array} language codes
 */
const getLanguageCodes = createSelector(
  [(state, entityId) => getUploadsByEntityId(state, entityId)],
  (textTracks) => {
    if (!textTracks) {
      return [];
    }

    return textTracks.map((textTrack) => textTrack.textTrack.language_code);
  }
);

const getDisplayLabels = createSelector(
  [(state, entityId) => getUploadsByEntityId(state, entityId)],
  (textTracks) => {
    if (!textTracks) {
      return [];
    }

    return textTracks.map((textTrack) => textTrack.textTrack.display_label);
  }
);

export { getUploadsByEntityId, getLanguageCodes, getDisplayLabels };
