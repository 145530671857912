class AlwaysScheduleService {
  /**
   * Creates an instance of AlwaysScheduleService.
   * @param {Object} APP_SETTINGS
   * @param {Object} StoreFactory
   * @param {Object} SchedulesFactory
   * @param {Object} $q
   * @memberof AlwaysScheduleService
   */
  constructor(APP_SETTINGS, StoreFactory, SchedulesFactory, $q) {
    this.APP_SETTINGS = APP_SETTINGS;
    this.StoreFactory = StoreFactory;
    this.SchedulesFactory = SchedulesFactory;
    this.$q = $q;

    this.AlwaysScheduleStore = this.StoreFactory.createInstance(
      this.SchedulesFactory.getAlwaysSchedule
    );
  }

  /**
   * get the always schedule
   * @returns {Promise}
   */
  getAlwaysSchedule() {
    const deferred = this.$q.defer();
    this.AlwaysScheduleStore.getData().then((data) => {
      deferred.resolve(data);
    });

    return deferred.promise;
  }
}

export default AlwaysScheduleService;
