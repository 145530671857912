import React from "react";
import PropTypes from "prop-types";
import Button from "components/_react/button/button";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { BUTTON_SECONDARY_ON_DARK } from "skylarklib/constants/button.constants";

import styles from "./edit-banner.scss";

/**
 * EditBanner
 * @param {Object} props
 */
export default function EditBanner(props) {
  return (
    <div className={styles["edit-banner"]}>
      <div>
        <div className={styles["edit-banner__icon"]}>
          <SkylarkIcon
            iconName="pencil"
            extraClassName={styles["edit-banner__icon"]}
          />
        </div>
        <div className={styles["edit-banner__text"]}>Editing Article</div>
      </div>
      <div className={styles["edit-banner__button-container"]}>
        <Button
          buttonType="small"
          icon="reset"
          isDisabled={!props.hasChanged}
          copy="Discard Changes"
          action={props.discard}
          id="discard-changes-button"
          buttonColor={BUTTON_SECONDARY_ON_DARK}
        />
        <Button
          buttonType="small"
          isDisabled={!props.hasChanged}
          icon="tick-small"
          copy="Save"
          action={props.save}
          id="save-changes-button"
          buttonColor={BUTTON_SECONDARY_ON_DARK}
        />
        <Button
          buttonType="small"
          copy="Finish Editing"
          action={props.finish}
          id="finish-editing-button"
          buttonColor={BUTTON_SECONDARY_ON_DARK}
        />
      </div>
    </div>
  );
}

EditBanner.propTypes = {
  discard: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  finish: PropTypes.func.isRequired,
  hasChanged: PropTypes.bool.isRequired,
};
