import template from "./talent-update.html";

/**
 * @fileOverview talentupdate.js
 * This class manages talent in the context of their relationship with their parent.
 * We do not edit the talent entity itself here.
 */

/**
 * @class TalentUpdateCtrl
 */
class TalentUpdateCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {string} UID_PATTERN
   * @param {Object} $scope
   * @param {Object} MessageService
   * @param {Object} TalentFactory
   * @param {Object} NotificationService
   */
  constructor(
    _,
    UID_PATTERN,
    $scope,
    MessageService,
    TalentFactory,
    NotificationService
  ) {
    this._ = _;
    this.uidPattern = UID_PATTERN;
    this.$scope = $scope;
    this.MessageService = MessageService;
    this.TalentFactory = TalentFactory;
    this.NotificationService = NotificationService;
  }

  /**
   * @param {object} talentEntity
   * @param {object} parentEntity
   * @param {object} config
   * @param {object} context
   * @returns {void}
   */
  bindParams(talentEntity, parentEntity, config, context) {
    this.parentEntity = parentEntity;
    this.config = config;
    this.talentEntity = talentEntity;
    this.context = context;
    this.data = {};
    this.uid = this.talentEntity.talent_url.match(this.uidPattern)[0];
    this.getTalent();
    this.setSubscriptions();
    this._buildModalOptions();
  }

  /**
   * set pub sub subscriptions
   * @returns {void}
   */
  setSubscriptions() {
    this.talentUpdateChannelName = `itemblock:${this.uid}-haschanged`;

    this.MessageService.registerChannel(this.talentUpdateChannelName);
    this.MessageService.on(this.talentUpdateChannelName, (ch, data) =>
      this.updateTalent(data)
    );

    this.talentDeleteChannelName = `deleteSelf.${this.uid}`;

    this.MessageService.registerChannel(this.talentDeleteChannelName);
    this.MessageService.on(this.talentDeleteChannelName, (ch, data) =>
      this.removeTalent(data)
    );

    this.talentUpdateRoleChannelName = "updateRoles";

    this.MessageService.registerChannel(this.talentUpdateRoleChannelName);
    this.MessageService.on(this.talentUpdateRoleChannelName, () =>
      this.updateRoles()
    );

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions();
    });
  }

  /**
   * De-register pub/sub subscriptions
   * @returns {void}
   */
  deregisterSubscriptions() {
    this.MessageService.unregisterChannel(this.talentUpdateChannelName);
    this.MessageService.unregisterChannel(this.talentDeleteChannelName);
    this.MessageService.unregisterChannel(this.talentUpdateRoleChannelName);
  }

  /**
   * get a match from parent this.types (array of role objects)
   * and return title for displaying in dom
   * @returns {string}
   */
  getTalentRoleTitle() {
    let title = "";

    if (this.roleUrl && this.context.types) {
      const currentRole = this._.find(
        this.context.types,
        (type) => type.self === this.roleUrl
      );

      if (currentRole && currentRole.title) {
        title = currentRole.title;
      }
    }

    return title;
  }

  /**
   * getTalent
   * gets Talent metadata and assigns its id to the relationship data.
   * This allows us to have both the talent entity and its relationship context in scope.
   * sets the title of role to type
   * @returns {void}
   */
  getTalent() {
    this.TalentFactory.getByUrl(this.talentEntity.talent_url)
      .then((data) => {
        this.roleUrl = this.talentEntity.roles[0];
        this.data = angular.extend({}, data);
        this.talentEntity.relationshipId = this.data.uid;
        this.talentEntity.type = this.getTalentRoleTitle();
        this.talentEntity.role = this.roleUrl;
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * update talent on controller
   * @param  {object} talent in the context of its relationship with the parent entity
   * @returns {void}
   */
  updateTalent(talent) {
    const name = `${this.data.first_name} ${this.data.last_name}`;
    talent.roles = [talent.role];
    this.talentEntity = talent;
    this.context.updateTalent(talent, name);
  }

  /**
   * update roles on message from module parent
   * @returns {void}
   */
  updateRoles() {
    this.roleUrl = this.talentEntity.roles[0];
    this.talentEntity.type = this.getTalentRoleTitle();
  }

  /**
   * call controller to remove from api
   * @returns {void}
   */
  removeTalent() {
    this.context.removeTalent(this.data);
  }

  /**
   * generateModalOptions
   * @return {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: { save: `itemblock:${this.uid}-haschanged` },
      isRemoval: true,
      entity: "talent",
    };
  }
}

/**
 * talentUpdateDirective
 * @returns {object} directive definition object
 */
function talentUpdateDirective() {
  return {
    restrict: "EA",
    replace: true,
    scope: {
      talentEntity: "=",
      config: "=",
      entity: "=",
      context: "=",
    },
    controller: TalentUpdateCtrl,
    controllerAs: "module",
    template,
    link(scope) {
      if (scope.talentEntity) {
        scope.module.bindParams(
          scope.talentEntity,
          scope.entity,
          scope.config,
          scope.context
        );
      }
    },
  };
}

export default talentUpdateDirective;
