/**
 * ChangelogFactory
 * @param {[type]} ApiService [description]
 */
function ChangelogFactory(ApiService) {
  const factory = {};

  factory.getChangelog = (entitySelf) =>
    ApiService.get(`/api/datalog/?object=${entitySelf}`);

  return factory;
}

export default ChangelogFactory;
