import { useEffect, useMemo, useState } from "react";
import useAngularInjection from "./use-angular-injection";

/**
 * an angular wrapper is required for the location object to receive the URL object
 * this hook gives you the current URL object
 * even though we using angular JS here for the moment it returns a real native URL object
 * so that we don't have to do migration across the react app later when angular is replaced totally
 * @return {[URL, {setSearch: function}]}
 */
const useLocation = () => {
  const $rootScope = useAngularInjection("$rootScope");
  const $location = useAngularInjection("$location");
  const [state, setState] = useState(new URL($location.absUrl()));

  const setSearch = useMemo(
    () => (newParams) => {
      $location.search(newParams);
    },
    [$location]
  );

  const setUrl = useMemo(
    () => (url) => {
      $location.url(url);
      $rootScope.$apply();
    },
    [$location, $rootScope]
  );

  useEffect(() => {
    const handler = (event, url) => {
      setState(new URL(url));
    };

    const removeEvent = $rootScope.$on("$locationChangeSuccess", handler);

    return () => {
      removeEvent();
    };
  }, [$rootScope, setState, $location]);

  return [state, { setSearch, setUrl }];
};

export default useLocation;
