/**
 * The version factory
 * @param {Object} $q
 * @param {Object} ApiService
 * @returns {Object}
 * @constructor
 */
function VersionsFactory($q, ApiService) {
  const factory = {};

  /**
   * Gets versions data
   * @param {string} url
   * @returns {void}]
   */
  factory.getAccountData = (url) => ApiService.get(url);

  /**
   * Updates both components of a version
   * @param {Object} entityData
   * @param {Object} accountData
   * @returns {Promise}
   */
  factory.update = (entityData, accountData) => {
    const deferred = $q.defer();
    const requests = [];

    requests.push(ApiService.put(entityData.self, entityData));
    if (accountData) {
      requests.push(ApiService.put(accountData.self, accountData));
    }

    $q.all(requests).then((response) => {
      deferred.resolve(response);
    });

    return deferred.promise;
  };

  return factory;
}

export default VersionsFactory;
