/**
 * deal with data source manipulation
 * like adding and removing fields
 * @return {Object}
 */
function DataSourceFactory() {
  const factory = {};

  /**
   * toggleDataSource - unlink or relink an ingested attribute from/to it's data source
   * @param {Array} dataSourceFields - data_source_fields
   * @param {object} updatedField - the config for the field to toggle
   * @returns {Array}
   */
  factory.toggleDataSource = (dataSourceFields, updatedField) => {
    if (factory.hasDataSource(dataSourceFields, updatedField)) {
      return factory._removeDataSource(dataSourceFields, updatedField);
    }
    return factory._addDataSource(dataSourceFields, updatedField);
  };

  /**
   * @param {Array} dataSourceFields - data_source_fields
   * @param {Object} field - config for the field that may have a data source
   * @return {boolean}
   */
  factory.hasDataSource = (dataSourceFields, field) => {
    if (!dataSourceFields || !Array.isArray(dataSourceFields)) {
      return false;
    }
    const names = field.names || [field.name];
    return names.some((name) => dataSourceFields.includes(name));
  };

  /**
   * Adds a data source to the data source array on the main data
   * @param {Array} dataSourceFields - data_source_fields
   * @param {Object} updatedField - config for the field that has been updated
   * @return {Array}
   */
  factory._addDataSource = (dataSourceFields, updatedField) => {
    const names = updatedField.names || [updatedField.name];
    dataSourceFields = dataSourceFields || [];

    names.forEach((name) => {
      dataSourceFields = [...dataSourceFields, name];
    });

    return dataSourceFields;
  };

  /**
   * Remove a data source to the data source array on the main data
   * @param {Array} dataSourceFields - data_source_fields
   * @param {Object} updatedField - config for the field that has been updated
   * @return {undefined|Array}
   */
  factory._removeDataSource = (dataSourceFields, updatedField) => {
    const names = updatedField.names || [updatedField.name];

    if (!Array.isArray(dataSourceFields) || dataSourceFields.length === 0) {
      return;
    }

    names.forEach((name) => {
      dataSourceFields = dataSourceFields.filter((field) => field !== name);
    });

    return dataSourceFields;
  };

  return factory;
}

export default DataSourceFactory;
