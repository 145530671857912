import template from "./text-scroller.html";

/**
 *  textScroller
 *  @fileOverview scrolls text on hover
 *
 */
class TextScrollerController {
  /**
   * @constructor
   * @param {Object} $element
   * @param {Object} $timeout
   */
  constructor($element, $timeout) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.elements = {};
  }

  /**
   * $onInit
   * @returns {void}
   */
  $onInit() {
    this._setElements();
    this._bindEvents();
  }

  /**
   * apply component elements to object this.elements
   * @returns {void}
   */
  _setElements() {
    this.elements.textScroller = this.$element.find(".text-scroller");
    this.elements.wrapper = this.$element.find(".text-scroller__wrapper");
    this.elements.inner = this.$element.find(".text-scroller__inner");
  }

  /**
   * startScrolling
   * @returns {void}
   */
  startScrolling() {
    this.$timeout(() => {
      if (this._isElementScrollable() && this._isElementHovered()) {
        this._duplicateTitle();
        this._setAnimationDuration();
      }
    }, 400);
  }

  /**
   * addElementScrollableClass
   * @returns {void}
   */
  addElementScrollableClass() {
    if (this._isElementScrollable()) {
      this.elements.textScroller.addClass("text-scroller--scrollable");
    } else {
      this.elements.textScroller.removeClass("text-scroller--scrollable");
    }
  }

  /**
   * bindEvents
   * @private
   * @returns {void}
   */
  _bindEvents() {
    this.$element.on({
      mouseenter: () => {
        this.startScrolling();
      },
    });

    this.elements.wrapper.on(
      "webkitAnimationEnd mozAnimationEnd animationend",
      () => {
        this._isElementHovered() && this._restartScrolling();
      }
    );
  }

  /**
   * isElementScrollable
   * @private
   * @returns {void}
   */
  _isElementScrollable() {
    return (
      this.elements.wrapper.outerWidth() >=
      this.elements.textScroller.outerWidth()
    );
  }

  /**
   * isElementHovered
   * @private
   * @returns {void}
   */
  _isElementHovered() {
    return this.$element.is(":hover");
  }

  /**
   * setAnimationDuration
   * @private
   * @returns {void}
   */
  _setAnimationDuration() {
    this.elements.wrapper.css(
      "animation-duration",
      `${(this.elements.wrapper.outerWidth() / 100) * 1.5}s`
    );
  }

  /**
   * duplicateTitle
   * @private
   * @returns {void}
   */
  _duplicateTitle() {
    if (!this.isTitleDuplicated) {
      this.elements.inner.append(
        `<span class='text-scroller__duplicate'>${this.itemTitle}</span>`
      );
      this.isTitleDuplicated = true;
    }
  }

  /**
   * restartScrolling
   * @returns {void}
   */
  _restartScrolling() {
    const newElement = this.elements.wrapper.clone(true);
    this.elements.wrapper.before(newElement);
    this.elements.wrapper.remove();
    this._setElements();
  }
}

/**
 * textScrollerDirective
 * @returns {Object} - directive definition object
 */
function textScrollerDirective() {
  return {
    restrict: "E",
    controller: TextScrollerController,
    controllerAs: "component",
    scope: {},
    bindToController: {
      itemTitle: "<",
    },
    template,
  };
}

export default textScrollerDirective;
