import { Modal } from "skylarklib/helpers";

import actionNames from "./modal.actions.constants";

/**
 * _createModal
 * @private
 * @returns {Object}
 */
function _createModal(options) {
  return {
    type: actionNames.CREATE_MODAL,
    options,
  };
}

/**
 * _destroyModal
 * @private
 * @returns {Object}
 */
function _destroyModal(instances) {
  return {
    type: actionNames.DESTROY_MODAL,
    instances,
  };
}

/**
 * Triggers new modal
 * @return {function} dispatch
 */
function triggerModal(options) {
  return (dispatch) => {
    dispatch(_createModal(options));
  };
}

/**
 * Destroys current modal
 * @param  {Array} current modal instances
 * @return {function} dispatch
 */
function destroyModal(modalInstances) {
  return (dispatch) => {
    const updatedInstances = Modal.destroy(modalInstances);
    dispatch(_destroyModal(updatedInstances));
  };
}

export { triggerModal, destroyModal };
