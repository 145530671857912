import React, { useEffect, useMemo, useState } from "react";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "react-use";
import _ from "lodash";
import { getNavigation } from "store/navigation/navigation.selector";
import { fetchNavigation } from "store/navigation/navigation.action";
import { setSelectedFilter } from "store/search/search.action";
import { fetchBaseConfig } from "store/entities-config/entities-config.action";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { getSelectedFilter } from "store/search/search.selector";
import { getBaseConfig } from "store/entities-config/entities-config.selector";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SkylarkIcon extraClassName="icon-down" iconName="down" />
  </components.DropdownIndicator>
);

const SelectFilter = () => {
  const dispatch = useDispatch();
  const selectOptions = useSelector(getNavigation);
  const selectedFilter = useSelector(getSelectedFilter);
  const [isFocused, setIsFocused] = useState(false);

  /**
   * `selectedOption` needs to be null if the selectedFilter is empty otherwise
   * the Select component would show an empty value
   *
   *
   */
  const selectedOption = useMemo(
    () => (_.isEmpty(selectedFilter) ? null : selectedFilter),
    [selectedFilter]
  );

  /**
   * Calculate the classNames based on focus/blur events
   *
   */
  const classNames = useMemo(() => {
    const className = "select-filter-container";
    const hasSelectedOption = !_.isEmpty(selectedOption);

    if (!isFocused && !hasSelectedOption) {
      return className;
    }

    if (isFocused) {
      return `select-filter-container--focus ${className}`;
    }

    if (hasSelectedOption) {
      return `select-filter-container--selected ${className}`;
    }
  }, [isFocused, selectedOption]);

  /**
   * Sets the state to focused
   *
   * @return {void}
   *
   */
  const onFocus = () => {
    setIsFocused(true);
  };

  /**
   * Set the state to blur
   *
   * @return {void}
   *
   */
  const onBlur = () => {
    setIsFocused(false);
  };

  /**
   * Triggers a redux state change to set the current filter
   * once an option has been selected
   *
   * @param {object} option - has the shape of {label, value, configName}
   */
  const setSelectedOption = (option) => {
    dispatch(setSelectedFilter(option));
  };

  /**
   * generic configuration of selected filter
   */
  const { data: config, loading: configLoading } = useSelector((state) => {
    const entityName = selectedOption && selectedOption.configName;
    return getBaseConfig(state, entityName);
  });

  /**
   * Retrieve the navigation on bootstrap
   * but just once.
   *
   */
  useEffectOnce(() => {
    if (_.isEmpty(selectOptions)) {
      dispatch(fetchNavigation());
    }
  });

  /**
   * Fetch specific configuration every time
   * we select a different object.
   * Interrupt if the config is already there
   *
   */
  useEffect(() => {
    if (_.isEmpty(selectedOption) || !_.isEmpty(config) || configLoading) {
      return;
    }

    const { configName } = selectedOption;

    dispatch(fetchBaseConfig(configName));
  }, [selectedOption, dispatch, config, configLoading]);

  return (
    <div className={classNames}>
      <SkylarkIcon extraClassName="icon-search" iconName="search" />
      <Select
        aria-label="select-input"
        className="select-filter"
        classNamePrefix="select-filter"
        placeholder="Select a filter"
        blurInputOnSelect={true}
        components={{ DropdownIndicator }}
        value={selectedOption}
        options={selectOptions}
        onChange={setSelectedOption}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </div>
  );
};

export default SelectFilter;
