import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { isEqual } from "lodash";

import ListHeadings from "./list-headings/list-headings";

import ListItem from "./list-item/list-item";

import styles from "./entity-list.scss";

/**
 * EntityList
 */
export default class EntityList extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    context: PropTypes.string.isRequired,
    createListRef: PropTypes.func.isRequired,
    canSelectMultiple: PropTypes.bool.isRequired,
    items: PropTypes.arrayOf(Object).isRequired,
    onSelect: PropTypes.func.isRequired,
    config: PropTypes.shape({
      columns: PropTypes.arrayOf(Object),
    }).isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    this.setIsBiggest();
    this.setInitialState();
  }

  /**
   * onSelect
   * @param  {Object} entity
   */
  onSelect(entity) {
    const entityToSet = isEqual(this.state.selectedEntity, entity)
      ? {}
      : entity;

    this.setSelectedEntity(entityToSet);
    this.props.onSelect(entityToSet);
  }

  /**
   * setSelectedState
   * @return {Boolean}
   */
  setSelectedState(entity) {
    return this.hasSelectionState()
      ? entity.uid === this.state.selectedEntity.uid
      : false;
  }

  /**
   * setIsBiggest
   */
  setIsBiggest() {
    this.setState({
      isBiggest: this.isBiggest(),
    });
  }

  /**
   * setSelectedEntity
   */
  setSelectedEntity(entity) {
    this.setState({
      selectedEntity: entity,
    });
  }

  /**
   * setInitialState
   */
  setInitialState() {
    this.setState({
      selectedEntity: {},
    });
  }

  /**
   * isSelectionEmpty
   * @return {Boolean}
   */
  hasSelectionState() {
    return Object.keys(this.state.selectedEntity).length;
  }

  /**
   * Make rows large to support images if images exist in config
   * @returns {void}
   */
  isBiggest() {
    return !!this.props.config.columns.find(
      (column) => column.name === "image"
    );
  }

  /**
   * sets the list ref to the parent component
   * @param  {Node} element
   */
  _createListRef = (element) => {
    this.props.createListRef(element);
  };

  /**
   * render component
   */
  render() {
    return (
      <div className={styles["entity-list"]}>
        <ListHeadings
          context={this.props.context}
          columns={this.props.config.columns}
          classes={styles["entity-list__headings"]}
        />
        <div
          className={classnames(
            styles["entity-list__items"],
            styles[`entity-list__items--${this.props.context}`]
          )}
          ref={this._createListRef}
        >
          {!!this.props.items.length &&
            this.props.items.map((entity) => (
              <ListItem
                key={entity.uid}
                onSelect={this.onSelect}
                config={this.props.config}
                context={this.props.context}
                entity={entity}
                isBiggest={this.state.isBiggest}
                isSelected={this.setSelectedState(entity)}
                canSelectMultiple={this.props.canSelectMultiple}
              />
            ))}
        </div>
      </div>
    );
  }
}
