import React from "react";
import { Provider } from "react-redux";

import store from "store/index";

/**
 * Provides Store to Component
 *
 * when using this with react2angular make sure the propTypes are defined for the ComponentWithStore
 * otherwise react2angular won't map the properties. Also make sure to pass them as properties, as
 * the second argument to react2angular – otherwise it won't work, this because the component that you're
 * actually passing to react2angular is (props) => (), and not the original component.
 * this hint is for all hoc's used with react2angular
 * @param WrappedComponent
 * @return {function(*): JSX.Element}
 */
const withStoreProvider = (WrappedComponent) => (props) =>
  (
    <Provider store={store}>
      <WrappedComponent {...props} />
    </Provider>
  );

export default withStoreProvider;
