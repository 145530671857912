import _ from "lodash";
/**
 * DataListFactory - wrangles data for lists
 * @param {object} $q
 * @param {object} EntityFactory       factory
 * @returns {object} factory - factory object
 */
function DataListFactory($q, EntityFactory) {
  const factory = {};

  let pageOffset = 0;
  let filteredAmount = 0;

  /**
   * filters entities that already exist in parent (i.e. schedules)
   * @access private
   * @param  {Array} dataArr - array of data to be filtered
   * @param  {Array} existingUrls - array of urls that already exist in the entity
   * @returns {Array} filtered data array without items that already exist
   */
  factory.filterExisting = (dataArr, existingUrls) =>
    dataArr.filter((obj) => existingUrls.every((url) => url !== obj.self));

  /**
   * getPage - get 'slice of data' which populates a page in a paginated list
   * @access public
   * @param  {object} options     - options object from the list
   * @param  {Number} limit - amount of entities to get from the API
   * @param  {object} startEnd    - interval of data to get
   * @returns {Promise}              deferred promise
   */
  factory.getPage = (options, limit, startEnd) => {
    const params = { ...options };

    if (!_.isArray(options.queryParams)) {
      params.queryParams.start = startEnd.start;
    }
    return factory.getDataHandler(params, limit);
  };

  /**
   * resets service state
   * @access public
   * @returns {void}
   */
  factory.resetData = () => {
    pageOffset = 0;
    filteredAmount = 0;
  };

  /**
   * get current offset amount from page start to avoid repeating stuff in lists
   * @returns {Number} pageOffset - indicates the last starting point + any filtered entities
   */
  factory.getPageOffset = () => pageOffset;

  factory.setPageOffset = (offset) => {
    pageOffset = offset;
  };

  /**
   * handleDataCalls
   * @access private
   * @param   {Object} options - options object from list
   * @param   {Number} amountToGet - amount to get
   * @param   {Array} [temporaryData]
   * @returns {Promise}
   */
  factory.getDataHandler = (options, amountToGet, temporaryData = []) => {
    if (!_.isArray(options.queryParams)) {
      options.queryParams.limit = amountToGet || options.limit;
    }

    return EntityFactory.getAllWithParams(
      options.currentUrl,
      options.queryParams
    ).then(({ objects }) => {
      factory.setPageOffset(options.queryParams.start + objects.length);

      if (!objects.length || !options.existing || !options.existing.length) {
        return temporaryData.concat(objects);
      }

      return factory.handleFilterableData(
        options,
        amountToGet,
        objects,
        temporaryData
      );
    });
  };

  /**
   * handleFilterableData, for when there's data to be filtered
   * part of the recursive loop, fetch data, filter out existing items, if not enough items, fetch more
   * @access private
   * @param  {Object} options      - options object from list
   * @param  {Number} amountToGet  - amount to get
   * @param  {Array} receivedData  - received data from the API
   * @param  {Array} [temporaryData]
   * @returns {function} - call itself or return data
   */
  factory.handleFilterableData = (
    options,
    amountToGet,
    receivedData,
    temporaryData = []
  ) => {
    const filteredData = factory.filterExisting(receivedData, options.existing);
    factory.setFilteredAmount(receivedData, filteredData);
    const totalDataSize = receivedData.length;
    temporaryData = temporaryData.concat(filteredData);

    if (
      filteredData.length === totalDataSize ||
      totalDataSize !== amountToGet
    ) {
      return $q.resolve(temporaryData);
    }

    if (totalDataSize !== 0) {
      const newAmount = amountToGet - filteredData.length;
      options.queryParams.start = factory.getPageOffset();

      return factory.getDataHandler(options, newAmount, temporaryData);
    }
  };

  /**
   * setFilteredAmount
   *
   * receivedData could be empty and filteredData might not
   * because all the data related to that filtered has been
   * added as relationship/content.
   * We check if the receivedData is actually greater than
   * the filteredData to avoid setting a negative
   * value
   * @param   {array} receivedData
   * @param   {array} filteredData
   * @returns {number} difference between receivedData length and filteredData length
   */
  factory.setFilteredAmount = (receivedData, filteredData) => {
    const newFilteredAmount =
      receivedData.length >= filteredData.length
        ? receivedData.length - filteredData.length
        : receivedData.length;

    filteredAmount += newFilteredAmount;
  };

  /**
   * getFilteredAmount
   * @returns {Number} the filtered amount
   */
  factory.getFilteredAmount = () => filteredAmount;

  return factory;
}

export default DataListFactory;
