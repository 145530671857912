import angular from "angular";

const HomeRoute = angular.module("HomeRoute", []).config(router);

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("Home", {
    url: "/",
    pageTitle: "Home",
    views: angular.extend({
      main: {
        component: "homeSearch",
      },
    }),
  });
}

export default HomeRoute;
