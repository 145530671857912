import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import Button from "components/_react/button/button";

import styles from "./link-input.scss";

/**
 * LinkInput
 * @param {Object} props
 */
export default class LinkInput extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    toggleTooltip: PropTypes.func.isRequired,
    createLink: PropTypes.func.isRequired,
    updateLink: PropTypes.func.isRequired,
    url: PropTypes.string,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    url: "",
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);
    this.applyLink = this.applyLink.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleReturn = this.handleReturn.bind(this);

    this.state = {
      value: props.url,
    };

    this.isEditing = !!this.state.value;
  }

  /**
   * lifecycle hook
   */
  componentDidMount() {
    document.addEventListener("keyup", this.handleReturn);
  }

  /**
   * lifecycle hook
   */
  componentWillUnmount() {
    document.removeEventListener("keyup", this.handleReturn);
  }

  /**
   * onChange
   * @param   {Event} event
   */
  onChange(event) {
    this.isValid = this._isUrl(event);
    this.setState({
      value: event.target.value,
      isValid: this.isValid,
    });
  }

  /**
   * handleReturn
   * @param   {Event} event
   */
  handleReturn(event) {
    if (event.keyCode === 13 && this.state.isValid) {
      this.applyLink();
    }
  }

  /**
   * applyLink
   * @returns {Function}
   */
  applyLink() {
    return this.isEditing ? this._editLink() : this._createLink();
  }

  /**
   * isURL
   * @param   {Event} event
   * @returns {Boolean}
   */
  _isUrl(event) {
    return event.target.value && event.target.checkValidity();
  }

  /**
   * applyLink
   */
  _createLink() {
    this.props.createLink(this.state.value);
    this.props.toggleTooltip();
  }

  /**
   * editLink
   */
  _editLink() {
    this.props.updateLink(this.state.value);
    this.props.toggleTooltip();
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <div className={classnames(styles["link-input__container"])}>
        <input
          id="editor-link"
          className={styles["link-input__input"]}
          type="url"
          value={this.state.value}
          onChange={this.onChange}
          placeholder="http://www.example.com"
        />
        <div className={styles["link-input__button"]}>
          <Button
            action={this.applyLink}
            buttonType="standard"
            copy="Apply Link"
            isDisabled={!this.state.value || !this.state.isValid}
          />
        </div>
      </div>
    );
  }
}
