export const DATE_THRESHOLD = 30;
export const DATE_FORMAT = "DD/MM/YYYY";
export const TRANSITIONING_SETTINGS = {
  current: {
    field: "ends",
    transitioning: "expiring",
    active: "live",
  },
  future: {
    field: "starts",
    transitioning: "planned",
    active: "future",
  },
};

export const UNSCHEDULED_STATUS = {
  type: "unscheduled",
  copy: "Not Assigned",
  styling: "warning",
};

export const STATUSES = {
  rights: {
    live: {
      type: "live",
      copy: "Licensed for Use",
      styling: "standard",
    },
    expiring: {
      type: "expiring",
      copy: "License Expiring",
      styling: "warning",
    },
    expired: {
      type: "expired",
      copy: "Not Licensed",
      styling: "warning",
    },
    planned: {
      type: "planned",
      copy: "License Starting",
      styling: "upcoming",
    },
    future: {
      type: "future",
      copy: "Awaiting License",
      styling: "standard",
    },
  },
  editorial: {
    live: {
      type: "live",
      copy: "Live",
      styling: "standard",
    },
    expiring: {
      type: "expiring",
      copy: "Expiring",
      styling: "warning",
    },
    expired: {
      type: "expired",
      copy: "Expired",
      styling: "warning",
    },
    planned: {
      type: "planned",
      copy: "Going Live",
      styling: "upcoming",
    },
    future: {
      type: "future",
      copy: "Future",
      styling: "standard",
    },
  },
};

export const MULTIPLE_STATUSES = {
  rights: {
    copy: "Multiple Licenses",
  },
  editorial: {
    copy: "Multiple",
  },
  live: {
    type: "live",
    styling: "standard",
  },
  expiring: {
    type: "expiring",
    styling: "warning",
  },
  expired: {
    type: "expired",
    styling: "warning",
  },
  planned: {
    type: "planned",
    styling: "upcoming",
  },
  future: {
    type: "future",
    styling: "standard",
  },
};
