let isInitialised = false;
const globalPermissionName = "global_permissions.change_editability_state";

class AuthenticationService {
  /**
   * @constructor
   * @param {Object} $rootScope
   * @param {Object} $location
   * @param {Object} $q
   * @param {Object} $cookies
   * @param {Object} $timeout
   * @param {Object} $window
   * @param {Object} _
   * @param {Object} ApiService
   * @param {Object} CustomersFactory
   * @param {Object} APP_SETTINGS
   */
  constructor(
    $rootScope,
    $location,
    $q,
    $cookies,
    $timeout,
    $window,
    _,
    ApiService,
    CustomersFactory,
    APP_SETTINGS,
    AmplifyService
  ) {
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$q = $q;
    this.$cookies = $cookies;
    this.$timeout = $timeout;
    this.$window = $window;
    this._ = _;
    this.ApiService = ApiService;
    this.CustomersFactory = CustomersFactory;
    this.APP_SETTINGS = APP_SETTINGS;
    this.AmplifyService = AmplifyService;

    // no need to verify user ever navigation change, do it minimum every 5 seconds
    const verificationTimer = 5000;
    this.verifyUser = this._.debounce(
      this.verifyUser.bind(this),
      verificationTimer,
      {
        leading: true,
        trailing: false,
      }
    );
  }

  /**
   * Initialise service
   * @returns {void}
   */
  init() {
    if (!isInitialised) {
      this.$rootScope.$on("$locationChangeStart", () =>
        this._onLocationChangeStart()
      );
      this.$rootScope.$on("$unauthorized", () => this._onUnauthorized());
      this.verifyUser();

      isInitialised = true;
    }
  }

  /**
   * Location change callback
   * @returns {void}
   */
  _onLocationChangeStart() {
    if (this._isAuthenticated()) {
      this.verifyUser();
    } else {
      this.$rootScope.unauthorized = true;
      this.logout();
    }
  }

  /**
   * Unauthorized callback
   * @returns {void}
   */
  _onUnauthorized() {
    this.logout();
  }

  /**
   * hasPermissions - check if user has editable permissions
   * @param   {object} userData
   * @returns {Promise} promise - that resolves to boolean
   */
  _hasPermissions(userData) {
    const deferred = this.$q.defer();

    if (userData.is_superuser) {
      deferred.resolve(userData.is_superuser);
    } else {
      this.ApiService.get(`/api/permissions/${userData.id}/`).then(
        (userPermissions) => {
          deferred.resolve(
            this._findGlobalPermission(
              userPermissions.permissions,
              globalPermissionName
            )
          );
        }
      );
    }

    return deferred.promise;
  }

  /**
   * _findGlobalPermission
   * @param   {array} list - list of permissions for this user
   * @param   {string} requiredPermission - permission to look for
   * @returns {boolean}
   */
  _findGlobalPermission(list, requiredPermission) {
    return !!list.find((permission) => permission === requiredPermission);
  }

  /**
   * Check to see if user currently has valid access
   * @returns {void}
   */
  _isAuthenticated() {
    const deferred = this.$q.defer();

    if (__V8_ENVIRONMENT__) {
      this.AmplifyService.isAuthenticated().then((isAuthenticated) => {
        deferred.resolve(isAuthenticated);
      });
    } else {
      const store = this.$cookies.getObject("token");
      const isStoreExpired = store && new Date() > new Date(store.expires);

      deferred.resolve(isStoreExpired ? false : store);
    }

    return deferred.promise;
  }

  /**
   * verifyUser
   * verify if API recognises this user
   * @returns {void}
   */
  verifyUser() {
    this._isAuthenticated()
      .then((isAuthenticated) => {
        this.CustomersFactory.getCurrentCustomer()
          .then((userData) => {
            if (isAuthenticated) {
              this._hasPermissions(userData).then((hasPermissions) => {
                this.$rootScope.hasPermissions = hasPermissions;
                this.$rootScope.unauthorized = false;
              });
            } else {
              this.$rootScope.unauthorized = true;
              this.logout();
            }
          })
          .catch((err) => {
            if ((err && err.status === 401) || !isAuthenticated) {
              return this.redirectToAuthenticationApp();
            }

            return this.$rootScope.$broadcast("$unauthorized");
          });
      })
      .catch(() => this.redirectToAuthenticationApp());
  }

  /**
   * Logout a user
   * @returns {void}
   */
  logout() {
    if (this.$cookies.get("token")) {
      this.$cookies.remove("token");
      // Take user to login screen
      this.redirectToAuthenticationApp();
    }
  }

  /**
   * Logout a user and redirect to the page
   * @returns {void}
   */
  redirectToAuthenticationApp() {
    this.$cookies.remove("token");

    if (__V8_ENVIRONMENT__) {
      const params = new URLSearchParams({
        redirect: this.$location.absUrl(),
      });
      this.$window.location.assign(
        `${__LAUNCHER_URL__}/login?${params.toString()}`
      );
      return;
    }

    if (this.APP_SETTINGS.IN_NGINX_CONTAINER) {
      // Need to use non angular location to reload full browser and not just the app
      this.$window.location.reload();
    } else {
      // Need to use non angular location to navigate to login page and not just the app
      this.$window.location.assign(`${__BASE_PATH__}login.html`);
    }
  }
}

export default AuthenticationService;
