import template from "./modal-epg.html";

/**
 * @memberof  Components.Modal
 * @classdesc Allows for the creation and editing of generic entities which
 * conform to a configurable interface with tabs.
 * Supports data source fields and image fields.
 * Note: This component needs to be namespaced as module to conform with
 * the fields interface.
 * Expects an options object with the following format:
 * { channels: { save: string OR create: string, }, parent: object, entity: string }
 */
class ModalEPGCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $q
   * @param {Object} _
   * @param {Object} MessageService
   * @param {Object} ModalService
   * @param {Object} LanguagesService
   * @param {Object} MODEL_DEFAULTS
   * @param {Object} DataSourceFactory
   * @param {Object} ApiService
   * @param {Object} NotificationService
   * @param {Object} EntityFactory
   */
  constructor(
    $scope,
    $q,
    _,
    MessageService,
    ModalService,
    LanguagesService,
    MODEL_DEFAULTS,
    DataSourceFactory,
    ApiService,
    NotificationService,
    EntityFactory,
    BreadcrumbService
  ) {
    this.$scope = $scope;
    this._ = _;
    this.$q = $q;
    this.MessageService = MessageService;
    this.ModalService = ModalService;
    this.LanguagesService = LanguagesService;
    this.MODEL_DEFAULTS = MODEL_DEFAULTS;
    this.DataSourceFactory = DataSourceFactory;
    this.ApiService = ApiService;
    this.NotificationService = NotificationService;
    this.EntityFactory = EntityFactory;
    this.BreadcrumbService = BreadcrumbService;

    /*
     * react functions caveat, it needs a bind to this otherwise the passed down function loses the context
     * this.toggleDataSource = this.toggleDataSource.bind(this);
     */
  }

  /**
   * @returns {void}
   */
  init() {
    this.isModal = true;
    this.ignoreResetEnd = true;
    this.validatedForm = {};
    this.formFields = [
      {
        names: ["slot_start", "slot_end"],
        display_names: ["Start", "End"],
        widget: "dateandtimefield",
        start_field: "slot_start",
        end_field: "slot_end",
        date_settings: {
          display_name: "Schedule Date",
          has_forever_option: false,
        },
        time_settings: {
          display_name: "Schedule Time",
        },
        validation: {
          start_field: {
            required: {
              error_message: "Start Date is required",
            },
          },
          end_field: {
            required: {
              error_message: "End Date is required",
            },
          },
        },
      },
      {
        names: ["announced_start", "announced_end"],
        display_names: ["Announced Start", "Announced End"],
        widget: "dateandtimefield",
        start_field: "announced_start",
        end_field: "announced_end",
        date_settings: {
          display_name: "Schedule Date",
          has_forever_option: false,
        },
        time_settings: {
          display_name: "Schedule Time",
        },
        validation: {
          start_field: {
            required: {
              error_message: "Start Date is required",
            },
          },
          end_field: {
            required: {
              error_message: "End Date is required",
            },
          },
        },
      },
    ];

    this.programmesConfig = {
      name: "epg-programmes",
      entity: "epg-programmes",
      display_name: "EPG Programmes",
      singular_name: "EPG Programme",
      fields_to_expand: ["schedule_urls"],
      countEndpoint: true,
      singleItem: true,
      limit: 1,
      columns: [
        {
          display_name: null,
          name: "isSelected",
          size: "1",
          $$hashKey: "object:2129",
        },
        {
          name: "title",
          display_name: "Title",
          size: "4",
          $$hashKey: "object:2131",
        },
      ],
    };

    this._setupData();
    this._buildModalOptions();
    this._setupSubscriptions();
  }

  /**
   * Setup the modals initial data
   * @private
   */
  _setupData() {
    this.parentData = this.options.parent;

    this.initialData = angular.copy(this.data, {});
    this.isNewEntity = this._.isEmpty(this.data);

    if (this.isNewEntity) {
      this.data = {
        slot_start: undefined,
        slot_end: undefined,
        announced_start: undefined,
        announced_end: undefined,
      };
    } else {
      this.selectedProgramme = this.data.programme;
      this.getUrl();
    }
  }

  /**
   * save the scheduledItem data and close modal
   * @returns {void}
   */
  save() {
    let dataToSend = {
      epg_programme_url: this.selectedProgramme.self,
      slot_start: this.data.slot_start,
      slot_end: this.data.slot_end,
      announced_start: this.data.announced_start,
      announced_end: this.data.announced_end,
    };

    if (this.isNewEntity) {
      this.MessageService.publish(
        this.options.channels.createEpgItem,
        dataToSend
      );
    } else {
      dataToSend = {
        dataToSend,
        epgId: this.data.uid,
      };
      this.MessageService.publish(
        this.options.channels.editEpgItem,
        dataToSend
      );
    }

    Object.keys(this.data).forEach((key) => delete this.data[key]);
    this._closeModal();
  }

  /**
   * delete the scheduledItem data and close modal
   * @description pass the data in the publish as it is required to deal
   * with some more complex deleting workflows
   * @returns {void}
   */
  delete() {
    this.MessageService.publish(
      this.options.channels.deleteEpgItem,
      this.data.uid
    );
    this.destroyModal();
  }

  /**
   * @returns {void}
   */
  revert() {
    angular.copy(this.initialData, this.data);
  }

  /**
   * @returns {void}
   */
  cancel() {
    this.revert();
    this.data = angular.extend({}, this.initialData);
    this.destroyModal();
  }

  /**
   * Set up the pub/sub subscriptions for this directive
   * @returns {void}
   */
  _setupSubscriptions() {
    const availableChannels = {
      imageUpdate: this.data.uid
        ? `ImageUploader.${this.data.uid}`
        : "ImageUploader",
      imageRemove: this.data.uid
        ? `ImageUploader.Remove.${this.data.uid}`
        : "ImageUploader.Remove",
      revert: `${this.data.uid}.Metadata.CancelEdit`,
      dataChannel: `${this.data.uid}.dataChannel`,
    };

    this.MessageService.subscribe(
      "Modal.Instance.0",
      (instance, programmes) => {
        this.selectedProgramme = programmes[0];
        this.getUrl();
      }
    );

    this.$scope.$on("$destroy", () => {
      this._unregisterSubscriptions(availableChannels);
    });
  }

  /**
   * Resets the breadcrumb when navigating away from a set
   */
  setNavigationHandler() {
    this.BreadcrumbService.setNavigationHandler();
    this._closeModal();
  }

  /**
   * Gets the URL for the selected EPG Programme and sets it to be used to navigate directly to entity
   */
  getUrl() {
    this.contentUrl = `/custom/epg-programmes/${this.selectedProgramme.uid}/information/`;
  }

  /**
   * Remove subscriptions
   * @param {Object} availableChannels
   * @returns {void}
   */
  _unregisterSubscriptions(availableChannels) {
    this.MessageService.unregisterChannel("Modal.Instance.0");
  }

  /**
   * @returns {void}
   */
  _closeModal() {
    // see if we need more stuff in here
    this.destroyModal();
  }

  /**
   * Builds the options for the modal
   * @private
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: {
        save: `itemblock:${this.data.uid}-haschanged`,
      },
      parent: {
        parentId: this.data.uid,
        parentTitle: this.data.title,
      },
      entity: "epg-programmes",
      has_datasource: true,
    };
  }
}

/**
 * modalEpgDirective
 * @returns {Object} Directive Definition Object
 */
function modalEpgDirective() {
  return {
    restrict: "E",
    controller: ModalEPGCtrl,
    scope: {},
    bindToController: {
      data: "=",
      config: "=",
      options: "=",
      entityType: "<",
      instanceUid: "@",
      destroyModal: "&",
    },
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default modalEpgDirective;
