import { createApiAction } from "store/api/api.actions";
import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import NavigationConstant from "./navigation.constant";

const fetchNavigation = () => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");

  return createApiAction(
    NavigationConstant.FETCH_NAVIGATION,
    ConfigurationFactory.getEntityBaseConfiguration("navigation")
  );
};

export { fetchNavigation };
