class CalendarGridService {
  /**
   * @constructor
   * @param   {Object} momentJS
   */
  constructor(momentJS) {
    this.momentJS = momentJS;
    this.minuteHeight = 5;
  }

  /**
   * getMinuteHeight
   * @returns {Number} the current minute height value
   */
  getMinuteHeight() {
    return this.minuteHeight;
  }

  /**
   * isToday
   * @param   {string} date - date
   * @returns {Boolean} if this date is for today
   */
  isToday(date) {
    const today = this.momentJS();

    return this.momentJS(date).isSame(today, "day");
  }

  /**
   * containsToday
   * @param   {array} daysList - list of days
   * @returns {Boolean} whether today is in this range
   */
  containsToday(daysList) {
    const today = this.extractDay(this.momentJS().format());

    return daysList.some((day) => day === today);
  }

  /**
   * _extractDay
   * @param   {string} isoString
   * @returns {string} substring with date only
   */
  extractDay(isoString) {
    const endIndex = isoString.indexOf("T");

    return isoString.substring(0, endIndex);
  }

  /**
   * calculatePosition
   * @param   {Object} momentDate - a momentJS object to generate the position from
   * @param   {Number} offset - pixel value
   * @returns {Number} position (in pixels)
   */
  calculatePosition(momentDate, offset = 0) {
    const hour = momentDate.format("HH");
    const minute = momentDate.format("mm");

    const topPosition = parseInt(hour) * (this.minuteHeight * 60);

    return (
      parseInt(topPosition) + parseInt(minute) * this.minuteHeight - offset
    );
  }
}

export default CalendarGridService;
