import angular from "angular";
import MetadataCreateArticlesService from "./metadata-create-articles/metadata-create-articles.service";
import MetadataService from "./metadata-service/metadata.service";
import MetadataFactory from "./metadata-factory/metadata.factory";
import metadataComponent from "./metadata-module/metadata-module.component";

const Metadata = angular
  .module("Skylark.Modules.Metadata", [])
  .service("MetadataService", MetadataService)
  .service("MetadataCreateArticlesService", MetadataCreateArticlesService)
  .factory("MetadataFactory", MetadataFactory)
  .component("metadata", metadataComponent);

export default Metadata;
