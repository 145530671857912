/**
 * FeatureToggleService
 * @fileOverfiew
 * Handles interactions with the skylark release cookie
 * for release versions and allows other parts of the CMS
 * to know whether the feature can be toggled on or off.
 * Exposes a couple of public methods that can be called from the console.
 *
 */

class FeatureToggleService {
  /**
   * @constructor
   * @param   {Object} $cookies
   * @param   {Object} $log
   * @param   {Object} momentJS
   * @param   {Object} FEATURE_TOGGLE_CONSTANTS
   */
  constructor($cookies, $log, $rootScope, momentJS, FEATURE_TOGGLE_CONSTANTS) {
    this.$cookies = $cookies;
    this.$rootScope = $rootScope;
    this.$log = $log;
    this.momentJS = momentJS;

    this.FEATURE_TOGGLE_CONSTANTS = FEATURE_TOGGLE_CONSTANTS;
    this.skylarkReleaseCookieName = FEATURE_TOGGLE_CONSTANTS.releaseCookieName;
  }

  /**
   * init
   * @public
   * @returns {void}
   */
  init() {
    this.featureToggleFlag = this.getFlag();
    this.activeFeatures = [];
    this._logCurrentFlag();

    // Set global V8 environment variable to help distinguish features from old (NGINX) to new (AWS) deployments
    this.$rootScope.inV8Environment = __V8_ENVIRONMENT__;
    // Set CSS styling switcher
    this.$rootScope.rebrandStyling = __V8_ENVIRONMENT__;
  }

  /**
   * _getFlag
   * @public
   * @memberOf  FeatureToggleService
   * @returns {string|null}
   */
  getFlag() {
    return this.$cookies.get(this.skylarkReleaseCookieName) || null;
  }

  /**
   * setFlag
   * @public
   * @memberOf  SkylarkLib#FeatureToggleService
   * @param   {string} flag
   * @returns {void}
   */
  setFlag(flag) {
    if (this._isValidFlag(flag)) {
      this.featureToggleFlag = flag;
      this.$cookies.put(this.skylarkReleaseCookieName, flag);
      this._logCurrentFlag();
    } else {
      this.$log.error(`${flag} is not a valid release flag.`);
    }
  }

  /**
   * resetFlag
   * @memberOf  FeatureToggleService
   * @returns {void}
   */
  resetFlag() {
    this.featureToggleFlag = null;
    this.$cookies.remove(this.skylarkReleaseCookieName);
    this.$log.warn(
      "Skylark is now in STABLE. Non-stable features have been disabled."
    );
  }

  /**
   * isAlpha
   * @returns {Boolean}
   */
  isAlpha() {
    return this.featureToggleFlag === "alpha";
  }

  /**
   * isFeatureEnabled
   * @memberOf  FeatureToggleService
   * @param {String} featureName
   * @returns {Boolean}
   */
  isFeatureEnabled(featureName) {
    if (this.featureToggleFlag && this._isValidFlag(this.featureToggleFlag)) {
      return !!this.FEATURE_TOGGLE_CONSTANTS[this.featureToggleFlag].find(
        (feature) => feature.name === featureName
      );
    }

    return false;
  }

  /**
   * _logCurrentFlag
   * displays an helpful message when changing to alpha.
   * @returns {void}
   */
  _logCurrentFlag() {
    if (this.featureToggleFlag) {
      if (this._isValidFlag(this.featureToggleFlag)) {
        this.$rootScope.featureToggleFlag = "alpha";
        this.$log.warn(`Skylark is now in ${this.featureToggleFlag} mode.`);
      } else {
        this.$log.error(
          `${this.featureToggleFlag} is not a valid release flag.`
        );
      }
    }
  }

  /**
   * _isValidFlag
   * @param   {String} flag
   * @returns {Boolean} whether this flag is valid
   */
  _isValidFlag(flag) {
    return !!this.FEATURE_TOGGLE_CONSTANTS.allowedValues.find(
      (allowedFlag) => flag === allowedFlag
    );
  }
}

export default FeatureToggleService;
