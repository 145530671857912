import * as EntityAction from "store/entity/entity.action";
/**
 * @fileOverview
 * Make a form toggle between edit and preview states
 */
class EditableFormCtrl {
  /**
   * Class constructor
   * @constructor
   * @param {object} _
   * @param {object} $q
   * @param {object} $scope - angular $scope service
   * @param {object} $location
   * @param {object} ReduxConnector
   * @param {object} MessageService - Skylark.lib messaging service for pub/sub
   * @param {object} ModalTriggerService
   * @param {Object} EDITABLE_FORM_SETTINGS
   * @param {Object} EntityFactory
   * @returns {void}
   */
  constructor(
    _,
    $q,
    $scope,
    $location,
    ReduxConnector,
    MessageService,
    ModalTriggerService,
    EDITABLE_FORM_SETTINGS,
    EntityFactory
  ) {
    this._ = _;
    this.$q = $q;
    this.$scope = $scope;
    this.$location = $location;
    this.ReduxConnector = ReduxConnector;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.EDITABLE_FORM_SETTINGS = EDITABLE_FORM_SETTINGS;
    this.EntityFactory = EntityFactory;

    this.connectToStore();

    this.debouncedSave = this._.debounce(this.save.bind(this), 2000);
    this.bufferedChanges = [];
  }

  /**
   * Bind parameters to scope
   * @private
   * @returns {void}
   */
  $onInit() {
    this.resetEvent = this.isVersion
      ? `form.toggleEdit.Version${this.onResetEvent}`
      : `form.toggleEdit.${this.onResetEvent}`;
    this.assignDefaultState();

    this._setSubscriptions();
  }

  /**
   * assignDefaultState
   * @public
   * @returns {void}
   */
  assignDefaultState() {
    const isPreviewing = this.entityType ? this._isPreviewSupported() : false;

    this.editing = this.entityType
      ? this.isEditDefault || !isPreviewing
      : this.isEditDefault === true;
  }

  /**
   * should be used on ng-change, ng model is updating as normal, but this function delays the submit of that change
   * @param {string} fieldName
   */
  onFieldChange(fieldName) {
    this.getFormField(fieldName).isSaved = false;
    this.addToBuffer(fieldName);
    this.debouncedSave(this.bufferedChanges);
  }

  /**
   * add field names to the buffer, so that next save will update all fields in the buffer
   * @param {String|Array<String>}fields
   */
  addToBuffer(fields) {
    if (typeof fields === "string") {
      fields = [fields];
    }

    if (Array.isArray(fields)) {
      fields.forEach((field) => {
        if (!this.bufferedChanges.includes(field)) {
          this.bufferedChanges.push(field);
        }
      });
    }
  }

  /**
   * get all form fields as a list with their names
   * @return {Array<String>}
   */
  getListOfFieldNames() {
    return Object.values(this.getFormValues()).map((value) => value.$name);
  }

  /**
   * ngForm filtered to return only the properties which are the fields of that form
   * @return {Object}
   */
  getFormValues() {
    const formValues = {};
    Object.values(this.ngForm).forEach((value) => {
      if (typeof value === "object" && value.$name) {
        formValues[value.$name] = value;
      }
    });

    return formValues;
  }

  /**
   * save all form fields
   */
  saveAll() {
    this.debouncedSave.cancel();
    this.save(this.getListOfFieldNames());
  }

  /**
   * updating entity with the updated change
   * clearing the bufferedList of auto saved inputs
   * if the form is already saving, and another save is triggered,
   * then that save will be delayed until from is ready to receive another save
   * @param {Array<String>} fieldNames
   */
  save(fieldNames) {
    if (typeof this.onSubmit !== "function" || !Array.isArray(fieldNames)) {
      return;
    }

    if (this.isSaving) {
      this.addToBuffer(fieldNames);
      return this.debouncedSave(this.bufferedChanges);
    }

    const values = {};
    fieldNames.forEach((fieldName) => {
      const field = this.getFormField(fieldName);
      if (!field || field.isSaving || field.$invalid) {
        return;
      }

      field.isSaving = true;

      values[fieldName] = field.$modelValue;
    });

    this.bufferedChanges = [];

    if (this._.isEmpty(values)) {
      return;
    }

    this.isSaving = true;

    this.delegateSubmit(values)
      .then(() => this.saveSuccessful(values))
      .finally(() => this.saveComplete(values));
  }

  /**
   * when saving was successful then state of field is marked as saved
   * @param {Array<String>} fields
   */
  saveSuccessful(fields) {
    Object.keys(fields).forEach((fieldName) => {
      const field = this.getFormField(fieldName);

      if (!field) {
        return;
      }

      field.$setPristine();
      field.isSaved = true;
    });
  }

  /**
   * when saving was complete, complete is either successful or failure
   * @param {Array<String>} fields
   */
  saveComplete(fields) {
    this.isSaving = false;

    Object.keys(fields).forEach((fieldName) => {
      const field = this.getFormField(fieldName);

      if (!field) {
        return;
      }

      field.isSaving = false;
    });
  }

  /**
   * let the submit handle by passed in function,
   * it can handle a promise, or plain function
   * @param {Object} values - of form to be wished to be submitted
   */
  delegateSubmit(values) {
    const returnValue = this.onSubmit({ values });

    if (returnValue && typeof returnValue.then === "function") {
      return returnValue;
    }

    return this.$q.resolve();
  }

  /**
   * Checks if the field with the specified id of the form is being submitted to
   * the API and saved.
   * @param {string} fieldName
   * @returns {boolean}
   */
  isFieldSuccess(fieldName) {
    const field = this.getFormField(fieldName);
    return field && field.$valid && field.isSaved;
  }

  /**
   * get field from form
   * @param {string} fieldName
   * @return {Object}
   */
  getFormField(fieldName) {
    return this.ngForm[fieldName];
  }

  /**
   * _isPreviewAvailable
   * @returns {Boolean}
   */
  _isPreviewSupported() {
    return this.EDITABLE_FORM_SETTINGS.modal.includes(this.entityType);
  }

  /**
   * Toggle editing param
   * @public
   * @returns {void}
   */
  toggleEdit() {
    this.editing = !this.editing;
  }

  /**
   * Called by DOM and will scroll to the first form error
   * @public
   * @param {object} targetForm - the form object
   * @returns {void}
   */
  scrollToError(targetForm) {
    const firstError = this._findFirstErrorElementName(targetForm);
    if (firstError) {
      this._scrollToElement(firstError);
    }
  }

  /**
   * Set up and pub/sub subscriptions
   * @private
   * @returns {void}
   */
  _setSubscriptions() {
    this.MessageService.subscribe(
      this.resetEvent,
      this._resetFormStatus.bind(this)
    );

    this.MessageService.subscribe("Forms.CancelAllForms", () => {
      if (!this.isModal || (this.isModal && this._isPreviewSupported())) {
        this._resetFormStatus();
      }
    });

    if (this.enableOnBeforeUnload) {
      this.MessageService.subscribe(
        `${this.resetEvent}.EditableForm.NavigateAway`,
        this._navigateAway.bind(this)
      );

      this.locationListenerDeregister = this.$scope.$on(
        "$locationChangeStart",
        this._displayLeavingMessage.bind(this)
      );
    }
  }

  /**
   * resetting form to pristine shape,
   * this will not reset the data of the form, parent components need to take care of this
   * @private
   */
  _resetFormStatus() {
    if (this.editing && this.ngForm) {
      this.ngForm.$setPristine();
    }
    this.editing = false;
  }

  /**
   * after user confirmed that navigating away is ok
   * remove location change listener
   * cancel edit state
   * and navigate to new url
   * @private
   */
  _navigateAway() {
    this.locationListenerDeregister();
    this.store.cancelCreation();
    this.$location.url(this.nextPath);
  }

  /**
   * Display a 'Are you sure' message
   * @param event
   * @param {String} next - next URL
   * @private
   */
  _displayLeavingMessage(event, next) {
    if (this.editing && !this.isEditDefault && this._hasFormChanged()) {
      event.preventDefault();

      this.nextPath = new URL(next).pathname;

      this.ModalTriggerService.triggerNotification({
        notificationType: "discardAndNavigate",
        channels: {
          confirm: `${this.resetEvent}.EditableForm.NavigateAway`,
        },
      });

      return;
    }
    return true;
  }

  /**
   *
   * @return {boolean}
   */
  _hasFormChanged() {
    return this.ngForm && this.ngForm.$dirty;
  }

  /**
   * Get the first element from the a form that has errors
   * @private
   * @param {object} targetForm - the form object
   * @returns {string} - first element with error
   */
  _findFirstErrorElementName(targetForm) {
    if (targetForm.$error.required) {
      return targetForm.$error.required[0].$name;
    }
  }

  /**
   * Scroll to element found by name attr
   * @private
   * @param {string} elementName - element to scroll to
   * @returns {void}
   */
  _scrollToElement(elementName) {
    const elementSelector = `input[name="${elementName}"]`;
    const topOffset = 100;
    const top = angular.element(elementSelector).offset().top - topOffset;
    angular.element("html, body").animate(
      {
        scrollTop: top,
      },
      500
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };

    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  /**
   * destroy lifecycle
   * @return {void}
   */
  $onDestroy() {
    this.disconnect();

    if (typeof this.locationListenerDeregister === "function") {
      this.locationListenerDeregister();
    }
  }
}

/**
 * Directive config
 * @returns {object} - directive config
 */
function editableFormDirective() {
  return {
    controller: EditableFormCtrl,
    controllerAs: "form",
    scope: false,
    bindToController: {
      enableOnBeforeUnload: "<",
      isEditDefault: "<",
      onResetEvent: "<",
      entityType: "<",
      isModal: "<",
      isVersion: "<",
      ngForm: "<name",
      onSubmit: "&",
    },
  };
}

export default editableFormDirective;
