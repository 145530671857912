/**
 * The regExp accepts this urls
 *
 * https://www.google.com
 * http://www.google.com
 * https://google.com
 * http://google.com
 * yo.google.co.uk
 * google.com
 *
 */

const DYNAMIC_FIELD_VALIDATION_REG_EXP = {
  url: {
    regExp:
      /^(http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  },
};

export default DYNAMIC_FIELD_VALIDATION_REG_EXP;
