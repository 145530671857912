import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import angular from "angular";
import { react2angular } from "react2angular";
import withStoreProvider from "hocs/with-store-provider";

import { destroyModal } from "store/modal/modal.actions";

import Modal from "./modal";

const connectToStore = (component) => connect(mapProps, mapDispatch)(component);

const mapProps = (state) => ({
  modal: state.modal,
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ ...{ destroyModal } }, dispatch);

export default connectToStore(Modal);

const reactModal = angular
  .module("Skylark.Components.React.Toaster", [])
  .component(
    "reactModal", // prefix with react as the angular toaster cannot be replaced yet
    react2angular(withStoreProvider(connectToStore(Modal)))
  );

export { reactModal };
