import template from "./upload-area-drag-drop.html";

class DragDropController {
  /**
   * @param $element
   */
  constructor($element) {
    this.$element = $element;
  }

  /**
   * DOM elements are linked, html5 events which are not supported by angularJS
   */
  $postLink() {
    this.elements = {
      dragAndDrop: this.$element.find(".js-video-dragable"),
      fileInput: this.$element.find(".js-file-input"),
    };
    this.elements.dragAndDrop.on("drop", this.onDrop.bind(this));
    this.elements.dragAndDrop.on("dragover", this.onDragOver.bind(this));
    this.elements.dragAndDrop.on("dragenter", this.onDragOver.bind(this));
  }

  /**
   * a button click forwarding to file input, to browse from file
   * @returns {void}
   *
   */
  onFakeBrowseFileClick() {
    this.elements.fileInput.click();
  }

  /**
   * prevent default on dragover
   * @param dragEvent
   * @returns {void}
   */
  onDragOver(dragEvent) {
    dragEvent.stopPropagation();
    dragEvent.preventDefault();
  }

  /**
   * Event handling for drop file in area
   * @param dropEvent
   * @returns {void}
   */
  onDrop(dropEvent) {
    dropEvent.preventDefault();
    dropEvent.stopPropagation();
    this.file = dropEvent?.originalEvent?.dataTransfer?.files[0];
    if (this.file) {
      this.upload();
    }
  }

  /**
   * file is selected for action
   * onBeforeUpload so executes a fn(locals)
   * as a reference. If not defined, it'll
   * return a no-op fn
   * @returns {void}
   */
  upload() {
    this.onUpload({ file: this.file, filename: this.file.name });
  }

  /**
   * Error handling in case the upload is failing
   * @returns {void}
   */
  onUploadError() {
    this.file = null;
  }
}

const uploadAreaDragDropComponent = {
  bindings: {
    dragDropText: "@",
    acceptTypes: "<",
    onUpload: "&?",
    onClose: "&?",
    canBeClosed: "<",
  },
  template,
  controller: DragDropController,
  controllerAs: "component",
};

export default uploadAreaDragDropComponent;
