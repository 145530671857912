import { DefaultDraftBlockRenderMap } from "draft-js";

export default {
  ...DefaultDraftBlockRenderMap,
  ...{
    SELECTED: {
      textDecoration: "underline",
      color: "rgb(48, 160, 206)",
    },
  },
};
