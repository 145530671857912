import template from "./navigation-section.html";

/**
 * @navigationSection Component
 * Component to recursively render nested navigation sections.
 */
/**
 * @class NavigationSectionCtrl
 */
class NavigationSectionCtrl {
  /**
   * @constructor
   * @param {Object} $rootScope
   * @param {Object} $location
   * @param {Object} $transitions
   * @param {Object} $element
   * @param {Object} $timeout
   * @param {Object} $scope
   * @param {Object} $stateParams
   * @param {Object} PermissionsService
   * @param {Object} NavigationService
   * @param {Object}  RouteService
   */
  constructor(
    _,
    $rootScope,
    $location,
    $transitions,
    $element,
    $timeout,
    $scope,
    $stateParams,
    BreadcrumbService,
    NavigationService,
    RouteService
  ) {
    this._ = _;
    this.$rootScope = $rootScope;
    this.$location = $location;
    this.$transitions = $transitions;
    this.$element = $element;
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$stateParams = $stateParams;
    this.BreadcrumbService = BreadcrumbService;
    this.NavigationService = NavigationService;
    this.RouteService = RouteService;

    this.id = this.$scope.$id.toString();
    this.sectionHeight = null;
  }

  /**
   * $onInit
   * @returns {Void}
   */
  $onInit() {
    this._registerNavigationEvents();

    this.isOpen = this.data.items && this.isSectionOpen();
    this.hasActiveChild = this.isOpen;

    this._sectionHeightCalculation();
  }

  /**
   * $onChanges
   * @returns {Void}
   */
  $onChanges() {
    if (this.isDynamicSection && this.isExpanded) {
      this._sectionHeightCalculation(300);
    }
  }

  /**
   * _sectionHeightCalculation
   * @description Calculates the height of the section for setting the
   * maxHeight needed for the transition.
   * @param {Integer} timeout
   * @returns {Void}
   */
  _sectionHeightCalculation(timeout = 0) {
    this.$timeout(() => {
      this.sectionHeight =
        this.$element.outerHeight(true) > 120
          ? this.$element.outerHeight(true)
          : 120;
    }, timeout);
  }

  /**
   * detects if section is open when state changes
   * @returns  {Void}
   * @memberof NavigationSectionCtrl
   */
  _registerNavigationEvents() {
    this.$transitions.onSuccess({}, () => {
      this.hasActiveChild = this.isSectionOpen();
    });
  }

  /**
   * Builds Id's for the clickable html elements
   * @description The id's are used for the protractor test
   * @returns  {String}
   * @memberof NavigationSectionCtrl
   */
  buildId() {
    return this._.kebabCase(this.data.display_name.toLowerCase());
  }

  /**
   * get unique Id
   * @param    {Object} item
   * @returns  {Boolean}
   * @memberof NavigationSectionCtrl
   */
  getUniqueId(item) {
    return item.uid ? this.$stateParams.id : this.$rootScope.activeNavItem;
  }

  /**
   * Checks if section is open
   * @returns {Boolean}
   * @memberof NavigationSectionCtrl
   */
  isSectionOpen() {
    return (
      this.data.items &&
      this.NavigationService.flattenItems(this.data.items).some((item) => {
        const selected = item.uid ? item.uid : item.type || item.name;

        return selected === this.getUniqueId(item);
      })
    );
  }

  /**
   * Sets the current active navigation item globally
   * @returns  {void}
   * @memberof NavigationSectionCtrl
   */
  setOpenSection() {
    this.$rootScope.activeNavItem = this.getOpenSection();
    this.resetBreadcrumb();
  }

  /**
   * Resets the breadcrumb when we navigate to a specific content page
   * @returns  {void}
   * @memberof NavigationSectionCtrl
   */
  resetBreadcrumb() {
    if (this.data.uid) {
      this.BreadcrumbService.updateHistory([]);
    }
  }

  /**
   *
   * @returns
   * @memberof NavigationSectionCtrl
   */
  getOpenSection() {
    return this.data.uid ? this.data.uid : this.data.type || this.data.name;
  }

  /**
   * is item active
   * @returns
   * @memberof NavigationSectionCtrl
   */
  isActive() {
    return this.getOpenSection() === this.getUniqueId(this.data);
  }

  /**
   * Checks if nav section / link has user permissions
   * @param    {Object} section
   * @returns  {Boolean}
   * @memberof NavigationSectionCtrl
   */
  hasPermissions(section) {
    return this.NavigationService.hasPermissions(section, this.permissions);
  }

  /**
   * Toggles section to be open or closed when it is clicked
   * @returns {void}
   * @memberof NavigationSectionCtrl
   */
  toggleSection() {
    if (!this.isDisabled) {
      this.isOpen = this.isExpanded ? !this.isExpanded : !this.isOpen;
      this.isExpanded = false;
    }
  }

  /**
   * Handles the link click
   * @param    {Object} event
   * @memberof NavigationSectionCtrl
   */
  onClick(event) {
    this.setOpenSection();
    this.$location.url(this.RouteService.buildURL(this.data));
  }
}

const navigationSectionComponent = {
  bindings: {
    data: "<",
    isDisabled: "=?",
    isExpanded: "=?",
    isRoot: "=",
    permissions: "=",
    removeItem: "&?",
    isDynamicSection: "@",
    isBookmarksSection: "<?",
  },
  template,
  controller: NavigationSectionCtrl,
  controllerAs: "component",
};

export default navigationSectionComponent;
