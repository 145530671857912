import template from "./genre-update.html";

/**
 * @fileOverview genreupdate.js
 * This class manages genres in the context of their relationship with their parent.
 * We do not edit the genre entity itself here.
 */

/**
 * @class GenreUpdateCtrl
 */
class GenreUpdateCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {service} UID_PATTERN
   * @param {service} MessageService
   * @param {factory} GenresFactory
   * @param {service} NotificationService
   */
  constructor(
    $scope,
    $location,
    $cookies,
    UID_PATTERN,
    MessageService,
    GenresFactory,
    NotificationService
  ) {
    this.$scope = $scope;
    this.$location = $location;
    this.$cookies = $cookies;
    this.MessageService = MessageService;
    this.GenresFactory = GenresFactory;
    this.NotificationService = NotificationService;
    this.data = undefined;
    this.parentData = undefined;
    this.uidPattern = UID_PATTERN;
  }

  /**
   * Bind params
   * @returns {void}
   */
  $onInit() {
    this.parentData = this.genre;

    this.uid = this.parentData.genre_url.match(this.uidPattern)[0];

    this.getGenre();
    this.setSubscriptions();
    this._buildModalOptions();
  }

  /**
   * set pub sub subscriptions
   *
   * @listens ModalCtrl#itemBlock.$uid-haschanged
   * @callback genreUpdateCtrl#genreUpdate
   * @returns {void}
   */
  setSubscriptions() {
    this.genreUpdate = `itemblock:${this.uid}-haschanged`;

    this.MessageService.registerChannel(this.genreUpdate);
    this.MessageService.on(this.genreUpdate, (ch, data) => this.update(data));

    this.genreDelete = `deleteSelf.${this.uid}`;

    this.MessageService.registerChannel(this.genreDelete);
    this.MessageService.on(this.genreDelete, () => this.removeGenre());

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions();
    });
  }

  /**
   * @returns  {void}
   * @private
   */
  _hasChildEntity() {
    const cookieName = "genrechildEntity";
    const childEntity = this.$cookies.getObject(cookieName) || null;

    if (childEntity && childEntity.parent.parentId === this.uid) {
      this.$cookies.remove(cookieName);
      this.parentData.schedule_urls.push(childEntity.child.self);
      this.context.updateGenre(this.parentData, this.data.name || "Genre");
    }
  }

  /**
   * De-register pub/sub subscriptions
   * @returns {void}
   */
  deregisterSubscriptions() {
    this.MessageService.unregisterChannel(this.genreUpdate);
    this.MessageService.unregisterChannel(this.genreDelete);
  }

  /**
   * getGenre
   * gets Genre metadata and assigns its id to the relationship data.
   * This allows us to have both the genre entity and its relationship context in scope.
   * @returns {void}
   */
  getGenre() {
    this.GenresFactory.getByUrl(this.parentData.genre_url)
      .then((data) => {
        this.initialData = angular.extend({}, data);
        this.data = angular.extend({}, data);
        this._hasChildEntity();
        /*
         * create a temporary id based off entity uid
         * so we have a non-entity specific property to keep track of
         */
        this.parentData.relationshipId = this.data.uid;
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * update genre
   * @param  {object} genre in the context of its relationship with the parent entity
   * @returns {void}
   */
  update(genre) {
    this.parentData = genre;
    this.context.updateGenre(genre, this.data.name);
  }

  /**
   * Removes a genre
   * @returns {void}
   */
  removeGenre() {
    this.context.removeGenre(this.data);
  }

  /**
   * generateModalOptions
   * @return {void}
   */
  _buildModalOptions() {
    const parentEntity = {
      parentId: this.uid,
      parentType: "genre",
      parentTitle: this.entity.title,
      childOf: this.$location.url(),
    };

    this.modalOptions = {
      channels: { save: `itemblock:${this.uid}-haschanged` },
      isRemoval: true,
      parent: parentEntity,
      entity: "genres",
    };
  }
}

/**
 * genreUpdateDirective
 * @returns {object} directive definition object
 */
function genreUpdateDirective() {
  return {
    restrict: "EA",
    replace: true,
    scope: {},
    bindToController: {
      genre: "=",
      config: "=",
      entity: "=",
      context: "=",
    },
    controller: GenreUpdateCtrl,
    controllerAs: "module",
    template,
  };
}

export default genreUpdateDirective;
