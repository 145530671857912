import videojs from "video.js";

/**
 * videojs
 * @param   {Object} $window
 * @returns {Object} instance of videojs
 */
function videojsWrapper() {
  return videojs;
}

export default videojsWrapper;
