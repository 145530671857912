import backendServices from "skylarklib/constants/backend-services";

const OVP_ASSET_UPLOAD = {
  MESSAGE_SERVICE: {
    UPLOAD_STARTED: "OvpAssetUpload.Started",
    UPLOAD_FAILED: "OvpAssetUpload.Failed",
    UPLOAD_FAILED_S3: "OvpAssetUpload.FailedS3",
    UPLOAD_COMPLETE: "OvpAssetUpload.Completed",
    UPLOAD_PROGRESS: "OvpAssetUpload.Progress",
    INGEST_STARTED: "OvpAssetUpload.IngestStarted",
    CANCEL_UPLOAD: "OvpAssetUpload.Cancel",
    UPLOAD_CANCELED: "OvpAssetUpload.Canceled",
    REMOVE_UPLOAD_FROM_STORE: "OvpAssetUpload.RemoveFromStore",
    DELETE_ASSET: "OvpAssetUpload.DeleteAsset",
  },
  PELICAN: {
    PELICAN_URL: backendServices.pelicanPath,
    STORAGE: {
      INTERNAL: "s3",
      EXTERNAL: "external",
    },
    PROVIDER_CONFIG: {
      mux: {},
      bitmovin: {
        manifest_formats: ["dash", "hls"],
        create_thumbnail: true,
      },
    },
    // only valid choice for now, don't change
    UPLOAD_TYPE: "video",
    PARENT_TYPE: "asset",
    INITIAL_DELAY: 1000 * 10,
    FETCH_INTERVAL: 1000 * 3,
    STATUS_TYPES: {
      CREATED: "created",
      IN_PROGRESS: "in_progress",
      UPLOADED: "uploaded",
      COMPLETE: "complete",
      INGEST_ERROR: "ingest_error",
    },
  },
  WORKFLOW_SERVICE: {
    PROVIDERS: ["workflow-service-mux", "workflow-service-m2a"],
    INGEST_STATUS: {
      COMPLETED: "COMPLETED",
      QUEUED: "QUEUED",
      PROCESSING: "PROCESSING",
      FAILED: "FAILED",
    },
  },
  DEFAULT_OVP_ASSET_TYPE: "video", // Other option is "audio"
};

export default OVP_ASSET_UPLOAD;
