/**
 * Factory for CRUD functionally of a credits
 * @param ApiService
 * @returns {{}}
 * @constructor
 */
function CreditsFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  /**
   * request all credits entities
   */
  factory.getCredits = () =>
    ApiService.get(
      "/api/credits/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get a single credits or any entity by url
   * @param url
   */
  factory.getByUrl = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * put or post a data object to credits endpoint
   * @param data
   */
  factory.save = (data) =>
    data.uid
      ? ApiService.put(
          data.self,
          data,
          ApiRequestConfigFactory.createRequestConfig({
            overrideGlobalLanguage: true,
          })
        )
      : ApiService.post(
          "/api/credits/",
          data,
          ApiRequestConfigFactory.createRequestConfig({
            overrideGlobalLanguage: true,
          })
        );

  /**
   * get a array of roles the credits entity can have from api
   */
  factory.getRoles = () =>
    ApiService.get(
      "/api/roles/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get role details from a role url
   * @param url
   */
  factory.getRole = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  return factory;
}

export default CreditsFactory;
