import angular from "angular";
import { UID_PATTERN } from "../metadata";

const CustomObjectsRoute = angular
  .module("CustomObjectsRoute", [])
  .config(router);

/**
 * Clears all messaging channels
 * @param {object} MessageService - the messaging service
 * @returns {void}
 */
function clearChannels(MessageService) {
  MessageService.clearChannels();
}

/**
 * resetLanguages description]
 * @param   {Object} EntityDimensionsService
 * @returns {void}
 */
function resetLanguages(EntityDimensionsService) {
  EntityDimensionsService.reset();
}

/**
 * resetEditingState
 * @param   {Object} EditingService
 * @returns {void}
 */
function resetEditingState(EditingService) {
  EditingService.disableEditing();
}

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlRouterProvider - ui-router's urlRouterProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router(
  $stateProvider,
  $locationProvider,
  $urlRouterProvider,
  $urlMatcherFactoryProvider
) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);

  $urlRouterProvider.rule(($injector, $location) => {
    const path = $location.path();

    if (path[path.length - 1] !== "/") {
      return `${path}/`;
    }
  });

  const entityIDRegex = UID_PATTERN;
  const routeTypes = [
    {
      name: "custom",
      url: ":entity",
    },
    {
      name: "customByType",
      url: ":entity/type-{entityType}",
    },
  ];

  const tabRoutes = [
    {
      tabName: "information",
      component: "entityInfoPage",
      entityName: "information",
    },
    {
      tabName: "relationships",
      component: "entityRelationshipsPage",
      entityName: "relationships",
    },
    {
      tabName: "imagery",
      component: "entityImageryPage",
      entityName: "imagery",
    },
    {
      tabName: "documents",
      component: "entityDocumentsPage",
      entityName: "documents",
    },
    {
      tabName: "versions",
      component: "entityVersionsPage",
      entityName: "versions",
    },
    {
      tabName: "content",
      component: "entityContentsPage",
      entityName: "assets",
    },
    {
      tabName: "epg",
      component: "entityEPGPage",
      entityName: "epg",
    },
  ];

  routeTypes.forEach((routeType) => {
    $stateProvider
      .state(routeType.name, {
        resolve: {
          clearChannels,
          resetLanguages,
          resetEditingState,
        },
        url: `/custom/${routeType.url}`,
        title: "custom",
        pageTitle: "Custom Type:listing",
        views: angular.extend({
          main: {
            component: "entityListingPage",
          },
        }),
      })
      .state(`${routeType.name}Detail`, {
        resolve: {
          clearChannels,
          resetLanguages,
          resetEditingState,
        },
        url: `/custom/${routeType.url}/{id:${entityIDRegex}}/`,
        title: "custom",
        pageTitle: "Custom Type:detail",
        views: angular.extend({
          main: {
            component: "entityDetailPage",
          },
        }),
      });

    tabRoutes.forEach((tabRoute) => {
      $stateProvider.state(`${routeType.name}Detail.${tabRoute.entityName}`, {
        url: `${tabRoute.entityName}/`,
        title: "custom",
        pageTitle: `Custom Type:${tabRoute.tabName}`,
        views: {
          info: {
            component: tabRoute.component,
          },
        },
      });
    });

    $urlRouterProvider.when(
      `/custom/${routeType.url}/{id:${entityIDRegex}}`,
      ($state, $match, ConfigurationFactory, _) =>
        navigateToFirstTab($state, $match, ConfigurationFactory, _, routeType)
    );
  });

  $stateProvider
    .state("customCreate", {
      resolve: {
        clearChannels,
      },
      url: "/custom/:entity/create/",
      title: "custom",
      pageTitle: "Custom Type:detail:create",
      views: angular.extend({
        main: {
          component: "entityDetailPage",
        },
      }),
    })
    .state("customCreate.information", {
      url: "information/",
      title: "custom",
      pageTitle: "Custom Type:information:create",
      views: {
        info: {
          component: "entityInfoPage",
        },
      },
    });

  $urlRouterProvider
    .when("/custom/:entity/create", "/custom/:entity/create/information/")
    .otherwise("/custom/:entity/");
}

/**
 * changes state to the first Tab from configuration, otherwise falling back to default tab
 * @param {Object} $state
 * @param {Object} $match
 * @param {Object} ConfigurationFactory
 * @param {Object} _
 * @param {Object} routeType
 */
function navigateToFirstTab(
  $state,
  $match,
  ConfigurationFactory,
  _,
  routeType
) {
  const defaultTab = "information";
  ConfigurationFactory.getBaseConfiguration($match.entity, $match.entityType)
    .then((config) => {
      const firstTab = _.get(config, "tabs[0].name", defaultTab);
      $state.go(`${routeType.name}Detail.${firstTab}`, $match);
    })
    .catch(() => {
      $state.go(`${routeType.name}Detail.${defaultTab}`, $match);
    });
}

export default CustomObjectsRoute;
