/**
 * AssetFactory
 *
 *
 *
 * @description Assets Api methods here
 */

/**
 * Assets Factory
 * @param {Object} $q
 * @param {Object} ApiService
 * @param {Object} GlobalParamsService
 * @param {Object} _
 * @returns {Object}
 * @constructor
 */
function AssetsFactory($q, ApiService, GlobalParamsService, _) {
  const factory = {};

  /**
   * Returns the asset types for this installation
   * @returns {Promise}
   */
  factory.getAssetTypes = () => ApiService.get("/api/asset-types/");

  /**
   * Returns all assets from the asset endpoint
   * @param {Objects} filters
   * @returns {void}
   */
  factory.getAllAssets = (filters) =>
    ApiService.get("/api/assets/", {
      params: { ...GlobalParamsService.getGlobalParams(), ...filters },
    });

  /**
   * Returns a selection of assets
   * @param {array} urls
   * @param {Object} filters
   * @returns {Promise}
   */
  factory.getAssets = (urls, filters) => {
    const requests = [];

    urls.forEach((url) =>
      requests.push(
        ApiService.get(url, {
          params: { ...GlobalParamsService.getGlobalParams(), ...filters },
        })
      )
    );

    return $q.all(requests);
  };

  /**
   * Gets assets that have been grouped
   * @param {arrays} urls
   * @param {Object} contentTypes
   * @param {Object} filters
   * @returns {Promise}
   */
  factory.getGroupedAssets = (urls, contentTypes, filters) => {
    const deferred = $q.defer();

    factory.getAssets(urls, filters).then((data) => {
      const groups = _.groupBy(data, (item) =>
        item.self.replace("/api/", "").split("/").shift()
      );

      const groupsArr = [];
      _.forEach(groups, (assets, type) => {
        const contentType = contentTypes.find(
          (contentType) => type === contentType.name
        );

        assets.forEach((asset) => {
          asset.assetType = contentType;
        });

        groupsArr.push({
          type: contentType.display_name,
          assets,
        });
      });

      deferred.resolve(groupsArr);
    });

    return deferred.promise;
  };

  /**
   * Returns version data of a single asset
   * @param {string} uid
   * @param {*|string} metadataType (Unsure of the type)
   * @returns {void}
   */
  factory.getAssetVersions = (uid, metadataType) =>
    ApiService.get(`/api/assets/${uid}/${metadataType}/`);

  /**
   * Gets a singular asset
   * @param {sting} id
   * @returns {void}
   */
  factory.getAssetById = (id) => ApiService.get(`/api/assets/${id}/`);

  /**
   * getParents
   * fetch parents and grandparents of an item of content
   * @param {string} self - self url to start
   * @returns {void}
   */
  factory.getParents = (self) => {
    const deferred = $q.defer();
    const parents = [];

    const fetch = (self) => {
      ApiService.get(self).then((data) => {
        data.assetType = data.self.replace("/api/", "").split("/").shift();
        parents.push(data);
        if (data.parent_url !== null) {
          fetch(data.parent_url);
        } else {
          deferred.resolve(parents);
        }
      });
    };

    fetch(self);

    return deferred.promise;
  };

  /**
   * Sorts assets by their associated type based on their urls
   * @param {Array.<String>} assetUrls
   * @returns {Object}
   */
  factory.sortAssetsUrlsByType = (assetUrls) =>
    _.groupBy(assetUrls, (item) =>
      item.replace("/api/", "").split("/").shift()
    );

  /**
   * Saves an asset
   * @param {Object} data
   * @returns {Promise}
   */
  factory.save = (data) => {
    if (data.uid) {
      return ApiService.put(`/api/assets/${data.uid}/`, data);
    }
    return ApiService.post("/api/assets/", data);
  };

  /**
   * Removes an asset
   * @param {Object} uid
   * @returns {Promise}
   */
  factory.delete = (uid) => ApiService.delete(`/api/assets/${uid}/`);

  return factory;
}

export default AssetsFactory;
