/**
 * @constructor
 * @param {Object} SetContentsStore
 * @param {Object} SetContentsDispatcher
 * @param {Object} QueryStringHelpersService
 * @param {Object} SET_CONTENTS_ACTIONS
 * @param {Object} $q
 * @param {Object} _
 */
function DisplayTitleActions(
  SetContentsStore,
  SetContentsDispatcher,
  QueryStringHelpersService,
  SET_CONTENTS_ACTIONS,
  $q,
  _
) {
  const factory = {};

  /**
   * buildViewString - join parts of the filters object
   * @param {object} filters - filters object
   * @returns {string} the final copy for the UI
   */
  function _buildViewString(filters) {
    const objectType = _.capitalize(filters.objectType);
    const filtersLimitString = filters.limit ? `${filters.limit} ` : "";
    const objectRankingString = filters.objectRanking
      ? ` by ${filters.objectRanking}`
      : "";
    const relationshipString = filters.relationships
      ? `: ${filters.relationships}`
      : "";
    const ancestorsString = filters.ancestors
      ? ` in: ${filters.ancestors}`
      : "";

    return `${filtersLimitString}${objectType}${objectRankingString}${relationshipString}${ancestorsString}`;
  }

  /**
   * _isDynamicObject
   * @param   {Object} item
   * @returns {Boolean}
   */
  function _requestDisplayTitles() {
    const { items } = SetContentsStore.getState();
    const requests = [];

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.requestDisplayTitles,
    });

    items.forEach((item) => {
      if (item.type === "computed-scheduled-items") {
        const { url } = item.contentItem[item.displayedLanguage];
        requests.push(
          QueryStringHelpersService.buildViewString(url, item.config.filters)
        );
      }
    });

    return $q.all(requests);
  }

  /**
   * _requestDisplayTitle
   * @returns {Promise}
   */
  function _requestDisplayTitle(item) {
    if (item.type === "computed-scheduled-items") {
      const { url } = item.contentItem[item.displayedLanguage];

      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.requestDisplayTitle,
      });

      return QueryStringHelpersService.buildViewString(
        url,
        item.config.filters
      );
    }
  }

  /**
   * _receiveDisplayTitle
   */
  function _receiveDisplayTitle(item, title) {
    const itemWithDisplayTitle = {
      ...item,
      displayTitle: _buildViewString(title),
    };

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.generateDisplayTitle,
      itemWithDisplayTitle,
    });
  }

  /**
   * _receiveDisplayTitles
   * @param   {array} titles
   */
  function _receiveDisplayTitles(titles) {
    const { items } = SetContentsStore.getState();

    const itemsWithDisplayTitles = items.map((item) => {
      if (item.type === "computed-scheduled-items") {
        const title = titles.shift();
        item.displayTitle = _buildViewString(title);
        item.objectType = title.objectType;

        return item;
      }

      return item;
    });

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.generateDisplayTitles,
      itemsWithDisplayTitles,
    });
  }

  /**
   * generate all the display titles
   */
  factory.generateDisplayTitles = () => {
    const deferred = $q.defer();

    _requestDisplayTitles().then((titles) => {
      _receiveDisplayTitles(titles);
      deferred.resolve("");
    });

    return deferred.promise;
  };

  /**
   * generate single display title
   * needs wrapping around promise to make sure DOM updates in
   * a timely fashion
   * @param {Object} item
   * @returns {Promise}
   */
  factory.generateDisplayTitle = (uid) => {
    const deferred = $q.defer();
    const item = SetContentsStore.getState().items.find(
      (item) => item.uid === uid
    );
    if (item.type === "computed-scheduled-items") {
      _requestDisplayTitle(item).then((title) => {
        _receiveDisplayTitle(item, title);
        deferred.resolve("");
      });
    } else {
      deferred.resolve("");
    }

    return deferred.promise;
  };

  return factory;
}

export default DisplayTitleActions;
