/**
 * languages reducer
 * @param   {Object} state - languages
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = { isDisplayed: false }, action) {
  switch (action.type) {
    case "UPDATE_MEDIA_METADATA":
      return {
        ...state,
        [action.data.key]: Object.assign(
          state[action.data.key] || {},
          action.data
        ),
      };

    case "SHOW_MEDIA_METADATA":
      return { ...state, isDisplayed: true };

    case "HIDE_MEDIA_METADATA":
      return { ...state, isDisplayed: false };

    default:
      return state;
  }
}
