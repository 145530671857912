import * as editorActions from "store/editor/editor.actions";

import { getConfigByEntity } from "store/entities-config/entities-config.selector";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { MODAL_LISTING_NAMESPACE } from "store/listing/modal-listing.constants";
import { resetSearchFilter } from "store/listing/entity-listing/entity-listing.actions";
import TextEditor from "./text-editor";

const mapProps = (state) => ({
  editor: state.editor,
  configuration: getConfigByEntity(
    state,
    state.entity.entityName,
    state.entity.data.article_type_slug
  ).editor,
  editorMediaMetadata: state.editorMediaMetadata,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      ...editorActions,
      resetModalList: () =>
        resetSearchFilter({ namespace: MODAL_LISTING_NAMESPACE }),
    },
    dispatch
  );

export default connect(mapProps, mapDispatch)(TextEditor);
