import React from "react";
import { Flex, FlexProps, Text } from "@chakra-ui/react";
import { ArrowLeftIcon } from "@skylark/icons";
import { Link } from "../link";

export interface LauncherBackButtonProps extends FlexProps {
  href: string;
  text: string;
}

export const LauncherBackButton: React.FC<LauncherBackButtonProps> = ({
  text,
  href,
  ...props
}) => (
  <Flex justifyContent="flex-end" {...props}>
    <Link href={href}>
      <ArrowLeftIcon h="12px" viewBox="0 1 13 12" w="13px" />
      <Text as="span" fontSize="12px" ml="2">
        {text}
      </Text>
    </Link>
  </Flex>
);

export default LauncherBackButton;
