import template from "./modal-notification.html";

/**
 * @file Modal Notification Directive
 */
/**
 * @memberof  Components.Modal
 * @classdesc Renders modals of type "notification".
 * A simple modal interface that gives the user
 * information about the action they are carrying out,
 * optionally prompting the user for confirmation.
 * Expects an options object in the following format:
 * { notificationType: string,
 *   channels: { confirm: string, alternative: string, cancel: string},
 *   redirect: boolean
 *  }
 * @see {@link Components.Modal.ModalNotificationService}
 * @see {@link Components.Modal.ModalNotificationConstants}
 */
class ModalNotification {
  /**
   * @constructor
   * @param {Object} $state
   * @param {Object} ModalNotificationService
   * @param {Object} MessageService
   */
  constructor($scope, $state, ModalNotificationService, MessageService) {
    this.$scope = $scope;
    this.$state = $state;
    this.ModalNotificationService = ModalNotificationService;
    this.MessageService = MessageService;
  }

  /**
   * init
   * @access public
   * @returns {void}
   */
  init() {
    this._getNotificationConfig();
    this.isNotificationDisabled = false;
  }

  /**
   * doNotDisplayAgain
   * Disable this notification type.
   * @access public
   * @returns {void}
   */
  doNotDisplayAgain() {
    if (this.isNotificationDisabled) {
      this.ModalNotificationService.updateDisabled(
        this.options.notificationType
      );
    }
  }

  /**
   * cancel
   * @access public
   * @returns {void}
   */
  cancel() {
    if (this.options.channels.cancel) {
      this.MessageService.publish(this.options.channels.cancel);
    }

    this.destroyModal();
  }

  /**
   * confirm
   * @access public
   * @param {string} actionType (confirm or alternative)
   * @returns {void}
   */
  confirm(actionType = "confirm") {
    this.doNotDisplayAgain();

    // @Todo: This will become a redirect to an create state at which point the if can be removed
    if (!this._isInfoPage() && this.options.redirect) {
      this._redirectToInfoState().then(() => {
        this.MessageService.publish(this.options.channels[actionType]);
        this.destroyModal();
      });
    } else {
      this.MessageService.publish(this.options.channels[actionType]);
      this.destroyModal();
    }
  }

  /**
   * @returns {boolean}
   * @private
   */
  _isInfoPage() {
    const currentState = this.$state.current.name;

    return currentState.split(".")[1] === "info";
  }

  /**
   * Redirects the user to the info panel
   * @returns {*}
   * @private
   */
  _redirectToInfoState() {
    const currentState = this.$state.current.name;
    const infoState = `${currentState.split(".")[0]}.information`;

    return this.$state.go(infoState);
  }

  /**
   * renderCopy
   * @access private
   * @returns {void}
   */
  _getNotificationConfig() {
    this.config = this.ModalNotificationService.getNotificationTypeConfig(
      this.options.notificationType,
      this.options.placeholders
    );
  }
}

/**
 * modalNotificationDirective
 * @returns {Object} Directive Definition Object
 */
function modalNotificationDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      options: "=",
      instanceUid: "@",
      destroyModal: "&",
    },
    controller: ModalNotification,
    controllerAs: "component",
    template,
    link: (scope) => scope.component.init(),
  };
}

export default modalNotificationDirective;
