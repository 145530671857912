import React, { Fragment } from "react";
import PropTypes from "prop-types";
import * as ScheduleHelpers from "components/_react/schedules/helpers/schedule-helpers";
import Schedules from "../schedules";

import { MULTIPLE_STATUSES } from "../schedules.constants";

/**
 * Renders a label for multiple schedules.
 * @export
 * @param {Object} props
 * @returns {JSX}
 */
export default function SchedulesMultiple(props) {
  const type = ScheduleHelpers.getScheduleType(props.schedules[0]);
  const text = MULTIPLE_STATUSES[type].copy;
  const styling = ScheduleHelpers.getMultipleSchedulesStyle(props.schedules);

  return (
    <Fragment>
      <Schedules text={text} styling={styling} />
    </Fragment>
  );
}

SchedulesMultiple.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(["expired", "current", "future"]),
      starts: PropTypes.string,
      ends: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    })
  ).isRequired,
};
