import angular from "angular";
import ModalDimensionsSettingsComponent from "./modal-dimensions-settings/modal-dimensions-settings.component";
import modalContainer from "./modal-container/modal-container.directive";
import modalDisplayListComponent from "./modal-display-list/modal-display-list.directive";
import modalDynamicObjectDirective from "./modal-dynamic-object/modal-dynamic-object.directive";
import modalEditDirective from "./modal-edit/modal-edit.directive";
import modalEpgDirective from "./modal-epg/modal-epg.directive";
import modalListDirective from "./modal-list/modal-list.directive";
import modalCreateButton from "./modal-list/modal-create-button/modal-create-button.directive";
import modalLocalisationNavigation from "./modal-localisation-navigation/modal-localisation-navigation.directive";
import MODAL_NOTIFICATION_CONSTANTS from "./modal-notification/modal-notification.constants";
import modalNotificationDirective from "./modal-notification/modal-notification.directive";
import ModalNotificationService from "./modal-notification/modal-notification.service";
import modalSettingsDirective from "./modal-settings/modal-settings.directive";
import modalTranslationSettingsComponent from "./modal-translation-settings/modal-translation-settings.component";
import ModalMultiLanguageService from "./shared/modal-multi-langauge/modal-multi-language.service";
import ModalMultiSourceService from "./shared/modal-multi-source/modal-multi-source.service";
import modalTabsDirective from "./modal-tabs/modal-tabs.directive";
import modalTrigger from "./modal-trigger/modal-trigger.directive";
import modalPerformanceDetails from "./modal-performance-details/modal-performance-details.directive";

const Modal = angular
  .module("Skylark.Components.Modal", [])
  .constant("MODAL_NOTIFICATION", MODAL_NOTIFICATION_CONSTANTS)
  .service("ModalMultiLanguageService", ModalMultiLanguageService)
  .service("ModalMultiSourceService", ModalMultiSourceService)
  .directive("modalContainer", modalContainer)
  .component("modalDimensionsSettings", ModalDimensionsSettingsComponent)
  .component("modalDisplayList", modalDisplayListComponent)
  .directive("modalDynamicObject", modalDynamicObjectDirective)
  .directive("modalEdit", modalEditDirective)
  .directive("modalEpg", modalEpgDirective)
  .directive("modalList", modalListDirective)
  .directive("modalCreateButton", modalCreateButton)
  .directive("modalLocalisationNavigation", modalLocalisationNavigation)
  .directive("modalNotification", modalNotificationDirective)
  .service("ModalNotificationService", ModalNotificationService)
  .directive("modalSettings", modalSettingsDirective)
  .directive("modalTabs", modalTabsDirective)
  .component("modalTranslationSettings", modalTranslationSettingsComponent)
  .directive("modalTrigger", modalTrigger)
  .component("modalPerformanceDetails", modalPerformanceDetails);

export default Modal;
