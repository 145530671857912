import angular from "angular";
import ApiRequestConfigFactory from "./angularjs/factory/api-request-config/api-request-config.factory";
import ArticleTypeFactory from "./angularjs/factory/article-type/article-type.factory";
import AssetsFactory from "./angularjs/factory/assets/assets.factory";
import BatchRequestFactory from "./angularjs/factory/batch-request/batch-request.factory";
import ChangelogFactory from "./angularjs/factory/changelog/changelog.factory";
import ConfigurationFactory from "./angularjs/factory/configuration/configuration.factory";
import CustomersFactory from "./angularjs/factory/customers/customers.factory";
import DataListFactory from "./angularjs/factory/data-list/data-list.factory";
import DimensionsFactory from "./angularjs/factory/dimensions/dimensions.factory";
import DocumentFactory from "./angularjs/factory/document/document.factory";
import DynamicObjectFactory from "./angularjs/factory/dynamic-object/dynamic-object.factory";
import DynamicObjectContentsFactory from "./angularjs/factory/dynamic-object-contents/dynamic-objects-contents.factory";
import EntityFactory from "./angularjs/factory/entity/entity.factory";
import FeatureToggleDependencyFactory from "./angularjs/factory/feature-toggle-dependency-factory/feature-toggle-dependency.factory";
import GenresFactory from "./angularjs/factory/genres/genres.factory";
import ImagesFactory from "./angularjs/factory/images/images.factory";
import $jQuery from "./angularjs/vendor-wrappers/jquery/jquery.factory";
import _loDash from "./angularjs/vendor-wrappers/lodash/lodash.factory";
import MediaFactory from "./angularjs/factory/media/media.factory";
import ModalFactory from "./angularjs/factory/modals/modal.factory";
import OptionsFactory from "./angularjs/factory/options/options.factory";
import SchedulesFactory from "./angularjs/factory/schedules/schedules.factory";
import SearchFactory from "./angularjs/factory/search/search.factory";
import SetTypesFactory from "./angularjs/factory/set-types/set-types.factory";
import SetsFactory from "./angularjs/factory/sets/sets-factory.factory";
import StoreFactory from "./angularjs/factory/store/store.factory";
import TagsFactory from "./angularjs/factory/tags/tags.factory";
import TalentFactory from "./angularjs/factory/talent/talent.factory";
import TemplateFactory from "./angularjs/factory/template/template.factory";
import UserlistsFactory from "./angularjs/factory/userlists/userlists.factory";
import UsersFactory from "./angularjs/factory/users/users.factory";
import dropdownOptions from "./constants/dropdownoptions";
import dynamicObjectConstants from "./constants/dynamic-object.constants";
import MODEL_DEFAULTS from "./constants/model-defaults.constants";
import featureTogglerDirective from "./angularjs/directives/feature-toggle/feature-toggle.directive";
import ApiEndpointFactory from "./angularjs/factory/api-endpoint/api-endpoint.factory";
import moment from "./angularjs/vendor-wrappers/moment/moment.factory";
import pikaday from "./angularjs/vendor-wrappers/pikaday/pikaday.factory";
import videojs from "./angularjs/vendor-wrappers/videojs/videojs.factory";
import AlwaysScheduleService from "./angularjs/services/always-schedule/always-schedule.service";
import ApiService from "./angularjs/services/api-service/api-service.service";
import ApiUrlAdapterService from "./angularjs/services/api-url-adapter/api-url-adapter.service";
import AuthenticationService from "./angularjs/services/authentication/authentication.service";
import BatchAddingService from "./angularjs/services/batch-adding/batch-adding.service";
import BatchRequestService from "./angularjs/services/batch-request/batch-request.service";
import BookmarkService from "./angularjs/services/bookmark/bookmark.service";
import BreadcrumbService from "./angularjs/services/breadcrumb/breadcrumb.service";
import DefaultLanguageService from "./angularjs/services/default-language/default-language.service";
import DimensionsService from "./angularjs/services/dimensions/dimensions.service";
import EditingService from "./angularjs/services/editing/editing.service";
import EntityDimensionsService from "./angularjs/services/entity-dimensions/entity-dimensions.service";
import EntityTypeHelpers from "./angularjs/services/entity-type-helpers/entity-type-helpers.service";
import EntityVersionsService from "./angularjs/services/entity-versions/entity-versions.service";
import ErrorInterceptorService from "./angularjs/services/error-interceptor/error-interceptor.service";
import ExpiredContentService from "./angularjs/services/expired-content/expired-content.service";
import FEATURE_TOGGLE_CONSTANTS from "./angularjs/services/feature-toggle/feature-toggle.constants";
import FeatureToggleService from "./angularjs/services/feature-toggle/feature-toggle.service";
import FeatureToggleVersionService from "./angularjs/services/feature-toggle-version/feature-toggle-version.service";
import API_FILTERING from "./angularjs/factory/api-filter/api-filter.constant";
import ApiFilterFactory from "./angularjs/factory/api-filter/api-filter.factory";
import GlobalParamsService from "./angularjs/services/global-param/global-param.service";
import HistoryService from "./angularjs/services/history/history.service";
import LanguagesService from "./angularjs/services/languages/languages.service";
import LoadingService from "./angularjs/services/loading/loading.service";
import MessageService from "./angularjs/services/message/message.service";
import ModalService from "./angularjs/services/modal/modal-service.service";
import ModalTriggerService from "./angularjs/services/modal-trigger/modal-trigger.service";
import NetworkService from "./angularjs/services/network/network.service";
import NotificationService from "./angularjs/services/notification/notification.service";
import OvpAssetDownloadService from "./angularjs/services/ovp-asset-download/ovp-asset-download.service";
import OvpAssetUploadService from "./angularjs/services/ovp-asset-upload/ovp-asset-upload.service";
import OvpAssetUploadStoreService from "./angularjs/services/ovp-asset-upload-store/ovp-asset-upload-store.service";
import PermissionsService from "./angularjs/services/permissions/permissions.service";
import QueryStringHelpersService from "./angularjs/services/query-string-helpers/query-string-helpers.service";
import RouteService from "./angularjs/services/route/route.service";
import SchedulingService from "./angularjs/services/scheduling/scheduling.service";
import SkylarkApiInfoService from "./angularjs/services/skylark-api-info/skylark-api-info.service";
import UsersService from "./angularjs/services/users/users.service";
import ViewingService from "./angularjs/services/viewing/viewing.service";
import UiSharedService from "./angularjs/services/ui.service";
import OVP_ASSET_UPLOAD from "./angularjs/services/ovp-asset-upload/ovp-asset-upload.constant";
import isEmpty from "./filters/is-empty";
import queryString from "./filters/querystring";
import reverse from "./filters/reverse";
import toArray from "./filters/to-array";
import capitalise from "./filters/capitalize";
import startsWith from "./filters/starts-with/starts-with.filter";
import customname from "./filters/customdisplayname";
import momentFormat from "./filters/datetime";
import groupBy from "./filters/group-by";
import VERSION_FEATURE_TOGGLE_CONSTANTS from "./angularjs/services/feature-toggle-version/feature-toggle-version.constants";
import GLOBAL_PARAM_CONSTANTS from "./angularjs/services/global-param/global-param.constants";
import RavenApiService from "./angularjs/services/raven-api-service/raven-api-service.service";
import ReduxConnector from "./angularjs/factory/redux-connector/redux-connector.factory";
import { InjectorFactory } from "./angularjs/factory/injector/injector.factory";
import TextTrackUploadService from "./angularjs/services/text-track-upload/text-track-upload.service";
import WindowService from "./angularjs/services/window/window.service";
import UtilitiesService from "./angularjs/services/utilities/utilities";
import UiHelpersService from "./angularjs/services/ui-helper/uiHelpers";
import AnalyticsService from "./angularjs/services/analytics/analytics.service";
import QuixService from "./angularjs/services/quix-service/quix-service.service";
import DataSourceFactory from "./angularjs/factory/data-source/data-source.factory";
import TransitionService from "./angularjs/services/transition/transition.service";
import CONTENT_SETTINGS from "./constants/content-settings.constants";
import AmplifyService from "./angularjs/services/aws-amplify/amplify.service";
import CreditsFactory from "./angularjs/factory/credits/credits.factory";

const Lib = angular
  .module("Skylark.Lib", [])
  .constant("DROPDOWN_OPTIONS", dropdownOptions)
  .constant("DYNAMIC_OBJECT", dynamicObjectConstants)
  .constant("MODEL_DEFAULTS", MODEL_DEFAULTS)
  .constant("FEATURE_TOGGLE_CONSTANTS", FEATURE_TOGGLE_CONSTANTS)
  .constant("API_FILTERING", API_FILTERING)
  .constant("OVP_ASSET_UPLOAD", OVP_ASSET_UPLOAD)
  .constant(
    "VERSION_FEATURE_TOGGLE_CONSTANTS",
    VERSION_FEATURE_TOGGLE_CONSTANTS
  )
  .constant("GLOBAL_PARAM_CONSTANTS", GLOBAL_PARAM_CONSTANTS)
  .constant("CONTENT_SETTINGS", CONTENT_SETTINGS)

  .directive("featureToggler", featureTogglerDirective)
  .factory("ApiEndpointFactory", ApiEndpointFactory)
  .factory("ApiFilterFactory", ApiFilterFactory)
  .factory("ApiRequestConfigFactory", ApiRequestConfigFactory)
  .factory("ArticleTypeFactory", ArticleTypeFactory)
  .factory("AssetsFactory", AssetsFactory)
  .factory("BatchRequestFactory", BatchRequestFactory)
  .factory("ChangelogFactory", ChangelogFactory)
  .factory("ConfigurationFactory", ConfigurationFactory)
  .factory("CustomersFactory", CustomersFactory)
  .service("DataListFactory", DataListFactory)
  .factory("DataSourceFactory", DataSourceFactory)
  .factory("DimensionsFactory", DimensionsFactory)
  .factory("DocumentFactory", DocumentFactory)
  .factory("DynamicObjectFactory", DynamicObjectFactory)
  .factory("DynamicObjectContentsFactory", DynamicObjectContentsFactory)
  .factory("EntityFactory", EntityFactory)
  .factory("FeatureToggleDependencyFactory", FeatureToggleDependencyFactory)
  .factory("GenresFactory", GenresFactory)
  .factory("ImagesFactory", ImagesFactory)
  .factory("MediaFactory", MediaFactory)
  .factory("ModalFactory", ModalFactory)
  .factory("OptionsFactory", OptionsFactory)
  .factory("SchedulesFactory", SchedulesFactory)
  .factory("SearchFactory", SearchFactory)
  .factory("SetTypesFactory", SetTypesFactory)
  .factory("SetsFactory", SetsFactory)
  .factory("StoreFactory", StoreFactory)
  .factory("TagsFactory", TagsFactory)
  .factory("TalentFactory", TalentFactory)
  .factory("TemplateFactory", TemplateFactory)
  .factory("UserlistsFactory", UserlistsFactory)
  .factory("UsersFactory", UsersFactory)
  .factory("ReduxConnector", ReduxConnector)
  .factory("InjectorFactory", InjectorFactory)
  .factory("CreditsFactory", CreditsFactory)

  .factory("$jQuery", $jQuery)
  .factory("_", _loDash)
  .factory("momentJS", moment)
  .factory("Pikaday", pikaday)
  .factory("videojs", videojs)

  .service("AlwaysScheduleService", AlwaysScheduleService)
  .service("ApiService", ApiService)
  .service("QuixService", QuixService)
  .service("RavenApiService", RavenApiService)
  .service("ApiUrlAdapterService", ApiUrlAdapterService)
  .service("AuthenticationService", AuthenticationService)
  .service("BatchAddingService", BatchAddingService)
  .service("BatchRequestService", BatchRequestService)
  .service("BookmarkService", BookmarkService)
  .service("BreadcrumbService", BreadcrumbService)
  .service("DefaultLanguageService", DefaultLanguageService)
  .service("DimensionsService", DimensionsService)
  .service("EditingService", EditingService)
  .service("EntityDimensionsService", EntityDimensionsService)
  .service("EntityTypeHelpersService", EntityTypeHelpers)
  .service("EntityVersionsService", EntityVersionsService)
  .service("ErrorInterceptorService", ErrorInterceptorService)
  .service("ExpiredContentService", ExpiredContentService)
  .service("FeatureToggleService", FeatureToggleService)
  .service("FeatureToggleVersionService", FeatureToggleVersionService)
  .service("GlobalParamsService", GlobalParamsService)
  .service("HistoryService", HistoryService)
  .service("LanguagesService", LanguagesService)
  .service("LoadingService", LoadingService)
  .service("MessageService", MessageService)
  .service("ModalService", ModalService)
  .service("ModalTriggerService", ModalTriggerService)
  .service("NetworkService", NetworkService)
  .service("NotificationService", NotificationService)
  .service("OvpAssetDownloadService", OvpAssetDownloadService)
  .service("OvpAssetUploadService", OvpAssetUploadService)
  .service("OvpAssetUploadStoreService", OvpAssetUploadStoreService)
  .service("PermissionsService", PermissionsService)
  .service("QueryStringHelpersService", QueryStringHelpersService)
  .service("RouteService", RouteService)
  .service("SchedulingService", SchedulingService)
  .service("SkylarkApiInfoService", SkylarkApiInfoService)
  .service("UsersService", UsersService)
  .service("ViewingService", ViewingService)
  .service("UiSharedService", UiSharedService)
  .service("TextTrackUploadService", TextTrackUploadService)
  .service("WindowService", WindowService)
  .service("TransitionService", TransitionService)
  .service("AmplifyService", AmplifyService)

  .service("UtilitiesService", UtilitiesService)
  .service("UiHelpersService", UiHelpersService)
  .service("AnalyticsService", AnalyticsService)

  .filter("capitalise", capitalise)
  .filter("startsWith", startsWith)
  .filter("customname", customname)
  .filter("momentFormat", momentFormat)
  .filter("groupBy", groupBy)
  .filter("isEmpty", isEmpty)
  .filter("querystring", queryString)
  .filter("reverse", reverse)
  .filter("toArray", toArray);

export default Lib;
