const SCHEDULE_TYPES = [
  {
    name: "Reusable",
    value: "1",
  },
  {
    name: "Single-Use",
    value: "0",
  },
];

export default SCHEDULE_TYPES;
