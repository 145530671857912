import { popErrorToast } from "store/toaster/toaster.actions";
import * as modalActions from "store/modal/modal.actions";

import { connect } from "react-redux";
import { getConfigByEntity } from "store/entities-config/entities-config.selector";
import { bindActionCreators } from "redux";
import DropdownArticleObjectWrapper from "./dropdown-article-objects-wrapper";

const mapProps = (state) => ({
  configuration: getConfigByEntity(
    state,
    state.entity.entityName,
    state.entity.data.article_type_slug
  ).editor,
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ ...{ popErrorToast }, ...modalActions }, dispatch);

export default connect(mapProps, mapDispatch)(DropdownArticleObjectWrapper);
