import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { CSSTransition } from "react-transition-group";

import styles from "./toast.scss";

/**
 * Toast
 * @param {Object} props
 */
export default class Toast extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    closeToast: PropTypes.func.isRequired,
    toastCount: PropTypes.number.isRequired,
    data: PropTypes.shape({
      id: PropTypes.string,
      message: PropTypes.string,
      type: PropTypes.string,
    }).isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.closeToast = this.closeToast.bind(this);

    this.toastDuration = 3000;

    this.state = {
      isFirst: true,
    };
  }

  /**
   * componentWillMount lifecycle hook
   */
  componentWillMount() {
    this._setInitialState();

    if (this.props.toastCount > 1) {
      this.setState({ isFirst: false });
    }
  }

  /**
   * componentDidMount lifecycle hook
   */
  componentDidMount() {
    this._displayToast();
  }

  /**
   * sets initial component state
   */
  _setInitialState() {
    this.setState({
      isVisible: false,
      hasEntered: false,
      isFirst: true,
    });
  }

  /**
   * update state to display the toast
   */
  _displayToast() {
    this.setState({
      isVisible: true,
    });
  }

  /**
   * update state to close the toast
   */
  closeToast() {
    this.setState({
      isVisible: false,
    });
  }

  /**
   * decide what to do with the new toast
   * @returns {void}
   */
  toastEntered() {
    this.delayClose();
    this.setState({
      hasEntered: true,
    });
  }

  /**
   * toastExiting
   * @return {void}
   */
  toastExiting() {
    this.setState({
      hasEntered: false,
    });
  }

  /**
   * set up how long toast should display for
   * @returns {void}
   */
  delayClose() {
    this.toastTimeout(this.toastDuration).then(() => this.closeToast());
  }

  /**
   * toast timeout promise
   * @return {Promise}
   */
  toastTimeout(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <CSSTransition
        timeout={300}
        in={this.state.isVisible}
        unmountOnExit
        onEntered={() => this.toastEntered()}
        onExiting={() => this.toastExiting()}
        onExited={() => this.props.closeToast(this.props.data.id)}
        classNames={{
          enter: styles.toast__enter,
          enterActive: styles["toast__enter-active"],
          exit: styles.toast__exit,
          exitActive: styles["toast__exit-active"],
        }}
      >
        <div
          className={classnames(
            styles.toast,
            { [styles.toast__entered]: this.state.hasEntered },
            { [styles.toast__first]: this.state.isFirst },
            { [styles["toast--error"]]: this.props.data.type === "error" }
          )}
          role="alertdialog"
          aria-describedby="toast__body-message"
        >
          <div>
            <button
              className={styles.toast__close}
              type="button"
              aria-label="Close Notification"
              onClick={() => this.closeToast()}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <p
              className={styles["toast__body-message"]}
              id="toast__body-message"
            >
              {this.props.data.message}
            </p>
          </div>
        </div>
      </CSSTransition>
    );
  }
}
