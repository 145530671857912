import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./image-create.html";

/**
 * Image Create Directive
 * @description: Create Image Module
 *
 */
/**
 * ImageCreate Ctrl
 * @class
 * @description  Controller for ImageCreate Directive
 */
class ImageCreateCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} ImagesFactory
   * @param {Object} SchedulesFactory
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} toaster
   * @param {Object} APP_SETTINGS - app constants
   *
   */
  constructor(
    $scope,
    ImagesFactory,
    SchedulesFactory,
    MessageService,
    NotificationService,
    toaster,
    APP_SETTINGS
  ) {
    this.$scope = $scope;

    this.ImagesFactory = ImagesFactory;
    this.SchedulesFactory = SchedulesFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.toaster = toaster;
    this.APP_SETTINGS = APP_SETTINGS;

    this.entity = undefined;
    this.config = undefined;

    this.imageTypes = undefined;
    this.file = undefined;
    this.filePreview = undefined;
    this.uid = undefined;

    this.uploadProgress = undefined;
    this.isUploading = false;

    this.data = {};
  }

  /**
   * Binds set data passed through to
   * directive constructor
   * @memberOf ImageCreateCtrl
   * @param  {Object} config - set object
   * @param {Object} entity
   * @param {Number} counter
   * @returns {void}
   *
   */
  bindParams(config, entity, counter) {
    this.config = config;
    this.entity = entity;
    this.counter = counter;
    this.uid = this.data.uid || 0;

    this.setSubscriptions();
    this.loadData();
  }

  /**
   * Sets subscriptions from MessageService
   * @memberOf ImageCreateCtrl
   * @listens: ImageCreateCtrl.onDragDrop
   * @returns {void}
   */
  setSubscriptions() {
    this.MessageService.registerChannel("ImageUploader");
    this.MessageService.on("ImageUploader", (channel, data) => {
      this.file = data.file;
      this.filePreview = data.filePreview;
    });
  }

  /**
   * Loads image type data
   * @memberOf ImageCreateCtrl
   * @returns {void}
   */
  loadData() {
    this.ImagesFactory.getImageTypes()
      .then((data) => {
        this.imageTypes = data.objects;
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * @memberOf ImageCreateCtrl
   * Reset Form Input
   * @returns {void}
   */
  clear() {
    this.data = {};
    this.file = null;
    this.filePreview = null;
  }

  /**
   * Submit Form
   * @memberOf  ImageCreateCtrl
   * @fires ImagesFactory.createAndUploadNewImage
   * @fires MessageService.imageCreate
   * @returns {void}
   */
  submit() {
    if (!this.file) {
      return this.NotificationService.notifyError(
        NotificationMessage.imageMissingError
      );
    }

    if (!this.data.image_type_url) {
      return this.NotificationService.notifyError(
        NotificationMessage.imageTypeMissingError
      );
    }

    this.isUploading = true;
    const typeName = this.imageTypes.filter(
      (cur) => cur.self === this.data.image_type_url
    );
    const data = angular.extend(
      {
        content_url: this.entity,
        position:
          this.counter({
            imageType: typeName[0].name,
          }) + 1,
      },
      this.data
    );

    this.ImagesFactory.createImageWithAlwaysSchedule(data, this.file)
      .then(
        (data) => {
          const imageInfo = {
            uid: data.uid,
          };
          const title = data.title || "an image.";

          this.NotificationService.notifyInfo(
            NotificationMessage.addSuccess(title)
          );
          this.MessageService.publish("Image.Create", imageInfo);

          this.clear();
          // @todo: this toggles the accordion. Avoid using scope inheritance for calling methods in upper scopes
          this.$scope.$parent.$parent.toggle();
          this.isUploading = false;

          this.filePreview = undefined;
        },
        undefined,
        (progress) => {
          this.uploadProgress = progress;
        }
      )
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.addError(this.data.title || "an image")
        )
      );
  }
}

/**
 * imageCreateDirective
 *
 *  @function
 *  @description  Directive Constructor for creating images
 *  @returns { Object } Directive Definition Object
 *
 */
function imageCreateDirective() {
  return {
    restrict: "A",
    scope: {
      config: "=",
      entity: "=",
      counter: "&",
    },
    controller: ImageCreateCtrl,
    controllerAs: "module",
    template,
    link(scope) {
      scope.module.bindParams(scope.config, scope.entity, scope.counter);
    },
  };
}

export default imageCreateDirective;
