import * as OvpAssetUploadAction from "store/ovp-asset-upload/ovp-asset-upload.action";

class OvpAssetUploadStoreService {
  /**
   * @param _
   * @param MessageService
   * @param ReduxConnector
   * @param OVP_ASSET_UPLOAD
   */
  constructor(_, ReduxConnector, MessageService, OVP_ASSET_UPLOAD) {
    this._ = _;
    this.ReduxConnector = ReduxConnector;
    this.MessageService = MessageService;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;

    this.connectToStore();
    this.setSubscriptions();
  }

  /**
   * listening to events triggered by the upload service, in order to update the storage
   */
  setSubscriptions() {
    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      (channel, payload) => {
        this.store.startUpload(payload);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
      (channel, payload) => {
        this.store.updateProgress(payload.pelicanId, payload.progress);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
      (channel, payload) => {
        this.store.startIngest(payload.pelicanId, payload);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
      (channel, payload) => {
        this.store.completeUpload(payload.pelicanId);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
      (channel, payload) => {
        this.store.cancelledUpload(payload.pelicanId);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
      (channel, payload) => {
        this.store.failedUpload(payload.pelicanId, payload.status_description);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
      (channel, payload) => {
        this.store.failedUploadToS3(payload.pelicanId);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.REMOVE_UPLOAD_FROM_STORE,
      (channel, payload) => {
        this.store.clearUpload(payload.pelicanId);
      }
    );

    const channels = Object.values(this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE);
    channels.forEach((channel) => this.MessageService.lockChannel(channel));
  }

  /**
   * get the Upload by pelicanId which is the key of the object
   * @param pelicanId
   * @return {Object | undefined}
   */
  getUpload(pelicanId) {
    return this.uploads[pelicanId];
  }

  /**
   * get the Upload by entityId and accountUrl
   * @param entityId
   * @param accountUrl
   * @returns {Object | undefined}
   */
  getUploadByEntityIdAndAccountUrl(entityId, accountUrl) {
    return Object.values(this.uploads).find(
      (upload) =>
        upload.entityId === entityId && upload.accountUrl === accountUrl
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToStore = {
      ...OvpAssetUploadAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToStore
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    return {
      uploads: state.ovpAssets,
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

export default OvpAssetUploadStoreService;
