import "jquery-ui/ui/widget";
import "jquery-ui/ui/widgets/datepicker";
import "jquery-ui/ui/widgets/mouse";
import "jquery-ui/ui/widgets/slider";
import "jquery-ui-timepicker-addon";
/**
 * Time picker directive
 * @returns {Object}
 */
function timepickerDirective() {
  return {
    restrict: "A",
    link: (scope, element) => {
      angular.element(element).timepicker({
        showButtonPanel: false,
        stepMinute: 1,
      });
    },
  };
}

export default timepickerDirective;
