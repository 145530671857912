/**
 * @fileOverview
 * Callback function for handleKeyCommand when backspace is pressed
 * and handleBeforeInput when a character is entered in a text placeholder.
 *
 * A text placeholder is a specific block component with the given properties:
 * - Empty character list
 * - a block type of 'placeholder'
 * - a content type of placeholder.
 *
 * A text placeholder is non-editable: it's becomes a contentBlock as soon as any characters are entered.
 * A block will become a text placeholder on removal if the following conditions are met:
 * - the full character list of the block is removed, or the last character is removed;
 * - there are no more remaining blocks of that given type in the current contentState.
 *
 */
import { Modifier, EditorState, ContentState } from "draft-js";

import { Map } from "immutable";

import RenderMap from "components/_react/editor/helpers/draft-render-map/draft-render-map";
import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";
import * as MediaHelpers from "components/_react/editor/helpers/media-helpers/media-helpers";

import { MEDIA_BLOCK_TYPES } from "components/_react/editor/text-editor/text-editor.constants";

/**
 * _isBlockTypeMedia
 * @param  {Immutable.Record} block
 * @return {Boolean}
 */
function _isBlockTypeMedia(block) {
  return MEDIA_BLOCK_TYPES.includes(block.getType());
}

/**
 * remove blocks that are not text placeholders and have no text
 * @param   {Immutable.Record} editorState
 * @returns {Immutable.Record}
 */
function _removeEmptyBlocks(editorState) {
  let contentState = editorState.getCurrentContent();
  const blockArray = contentState
    .getBlocksAsArray()
    .filter((block) => !!block.getText() || block.getType() === "placeholder");

  contentState = ContentState.createFromBlockArray(
    blockArray,
    contentState.entityMap
  );

  return EditorState.set(editorState, {
    currentContent: contentState,
    directionMap: editorState.getDirectionMap(),
    selection: editorState.getCurrentContent().getSelectionBefore(),
    forceSelection: true,
    inlineStyleOverride: null,
  });
}

/**
 * replace the block text and commit the state change
 * @param   {Immutable.Record} editorState
 * @param   {Immutable.Record} selectionState
 * @param   {String} text - the new text
 * @returns {Immutable.Record}
 */
function _replaceBlockText(editorState, selectionState, text, inlineStyles) {
  const newContent = Modifier.replaceText(
    editorState.getCurrentContent(),
    selectionState,
    text,
    inlineStyles
  );

  return DraftHelpers.updateEditorState(
    editorState,
    newContent,
    "replace-text"
  );
}

/**
 * An item is last of type if there are no more items of the same type in the remaining blocks
 * and if it is the last one of a given type in the items being removed
 * @param   {Immutable.Record} state
 * @param   {Immutable.Map} currentBlock
 * @param   {Immutable.Seq} spannedBlocks
 * @param   {Immutable.Seq} subArray
 * @returns {Boolean}
 */
function _islastOfType(state, currentBlock, spannedBlocks, subArray) {
  const blocks = state.getCurrentContent().getBlocksAsArray();
  const blocksToJS = spannedBlocks.toJS();
  const subArr = subArray
    .toList()
    .toJS()
    .map((block) => block.data.contentType);

  const blockType = currentBlock.getData().get("contentType");
  const types = blocks
    .filter((block) => !blocksToJS[block.getKey()])
    .map((block) => block.getData().get("contentType"));

  return (
    !types.includes(blockType) &&
    subArr.filter((type) => type === blockType).length === 1
  );
}

/**
 * a full deletion spans the whole block
 * @param   {Immutable.Map} block
 * @param   {Number} startOffset
 * @param   {Number} endOffset
 * @returns {Boolean}
 */
function _isFullDeletion(block, startOffset, endOffset, startKey) {
  return (
    (startOffset === 0 && block.getKey() === startKey) ||
    endOffset === block.getText().length
  );
}

/**
 * an edge block is at the start or the end of the selection
 * @param   {String} blockKey
 * @param   {String} startKey
 * @param   {String} endKey
 * @returns {Boolean}
 */
function _isEdgeBlock(blockKey, startKey, endKey) {
  return blockKey === startKey || blockKey === endKey;
}

/**
 * _getType
 * @param   {Immutable.Map} blockMap
 * @param   {Immutable.Map} currentBlock
 * @returns {String}
 */
function _getType(blockMap, currentBlock) {
  const emptyInsertions = ["unordered-list-item", "ordered-list-item"];
  const el = blockMap.find(
    (block) =>
      block[1].element ===
      currentBlock.getData().getIn(["config", "default_element"])
  );

  const type = el ? el[0] : "unstyled";

  return emptyInsertions.includes(currentBlock.getType())
    ? currentBlock.getType()
    : type;
}

/**
 * _handleNonExistingBlocks
 * @param   {Immutable.Record} editorState
 * @param   {String} lastPlaceholder
 * @returns {Immutable.Record}
 */
function _handleNonExistingBlocks(editorState, lastPlaceholder) {
  const blockMap = editorState.getCurrentContent().getBlockMap();
  const currentBlockKey = editorState.getSelection().getFocusKey();
  if (blockMap.has(currentBlockKey)) {
    return editorState;
  }

  const lastSelection = DraftHelpers.createBlockSelection(
    lastPlaceholder,
    0,
    0
  );

  return EditorState.forceSelection(editorState, lastSelection);
}

/**
 * toggle a text placeholder off when characters are entered
 * @param   {String} characters
 * @param   {Immutable.Record} editorState
 * @param   {Function} cb - the callback to redux
 * @returns {String} handled or not handled
 */
function toggleOff(characters, editorState, cb) {
  const nestedItems = ["unordered-list-item", "ordered-list-item"];
  const contentState = editorState.getCurrentContent();
  const inlineStyles = editorState.getCurrentInlineStyle();
  const selection = editorState.getSelection();
  const key = selection.getEndKey();
  const currentBlock = contentState.getBlockForKey(key);

  if (
    currentBlock.getType() === "placeholder" ||
    nestedItems.includes(currentBlock.getType())
  ) {
    const blockMap = [...RenderMap.entries()];
    const type = _getType(blockMap, currentBlock);

    const newData = Map({
      contentType: currentBlock.getData().getIn(["config", "field_name"]),
    });

    const blockSelection = DraftHelpers.createBlockSelection(
      key,
      0,
      currentBlock.getText().length
    );

    let lastState = editorState;
    lastState = DraftHelpers.changeBlockType(lastState, selection, type);
    lastState = DraftHelpers.changeBlockData(
      lastState,
      lastState.getSelection(),
      newData
    );

    cb(_replaceBlockText(lastState, blockSelection, characters, inlineStyles));

    return "handled";
  }

  return "not-handled";
}

/**
 * toggle a text placeholder back on when characters have been deleted
 *
 * @param   {Immutable.Record} editorState
 * @param   {Function} cb - callback to update editor state
 * @returns {String} handled / not handled
 */
function toggleOn(editorState, callback, text) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const endKey = selection.getEndKey();
  const startKey = selection.getStartKey();
  const startBlock = contentState.getBlockForKey(startKey);
  const endBlock = contentState.getBlockForKey(endKey);
  let lastState = editorState;

  const newData = Map({
    contentType: "placeholder",
  });

  if (startKey === endKey) {
    const blockSelection = DraftHelpers.createBlockSelection(
      endKey,
      0,
      endBlock.getText().length
    );

    lastState = DraftHelpers.changeBlockType(
      lastState,
      blockSelection,
      "placeholder"
    );
    lastState = DraftHelpers.changeBlockData(
      lastState,
      blockSelection,
      newData
    );
    lastState = _replaceBlockText(lastState, blockSelection, "");
    callback(DraftHelpers.selectionToStart(lastState, startKey));

    return "handled";
  }

  const endOffset = selection.getEndOffset();
  const startOffset = selection.getStartOffset();
  const textToReplace = text || "";
  let lastPlaceholder = "";

  contentState
    .getBlockMap()
    .toSeq()
    .skipUntil((block) => block.getKey() === startKey)
    .takeUntil((block) => block.getKey() === endKey)
    .concat({ [endKey]: endBlock })
    .filter(
      (block, blockKey) =>
        !_isEdgeBlock(blockKey, startKey, endKey) ||
        (_isEdgeBlock(blockKey, startKey, endKey) &&
          _isFullDeletion(block, startOffset, endOffset, startKey))
    )

    .forEach((block, index, selectedBlocks) => {
      const blockSelection = DraftHelpers.createBlockSelection(
        index,
        0,
        block.getText().length
      );
      const remainingBlocks = selectedBlocks.skipUntil(
        (current) => index === current.getKey()
      );

      if (
        _islastOfType(lastState, block, selectedBlocks, remainingBlocks) &&
        (!textToReplace || (textToReplace && index !== startKey)) &&
        !_isBlockTypeMedia(block)
      ) {
        lastState = DraftHelpers.changeBlockType(
          lastState,
          blockSelection,
          "placeholder"
        );
        lastPlaceholder = blockSelection.toJS().focusKey;
        lastState = DraftHelpers.changeBlockData(
          lastState,
          blockSelection,
          newData
        );
      }

      if (!_isBlockTypeMedia(block)) {
        lastState = _replaceBlockText(lastState, blockSelection, "");
      } else {
        lastState = MediaHelpers.handleMediaDelete(
          lastState,
          true,
          blockSelection
        );
      }
    });

  if (!_isFullDeletion(startBlock)) {
    const selectionToReplace = DraftHelpers.createBlockSelection(
      startBlock.getKey(),
      startOffset,
      startBlock.getText().length
    );
    lastState = _replaceBlockText(lastState, selectionToReplace, textToReplace);
  }

  if (!_isFullDeletion(endBlock)) {
    const selectionToReplace = DraftHelpers.createBlockSelection(
      endBlock.getKey(),
      0,
      endOffset
    );
    lastState = _replaceBlockText(lastState, selectionToReplace, textToReplace);
  }

  lastState = _removeEmptyBlocks(lastState);
  lastState = _handleNonExistingBlocks(lastState, lastPlaceholder);

  callback(lastState);

  return "handled";
}

export { toggleOff, toggleOn };
