function QuixService($http, $q) {
  const service = {};

  const workspaceId = "ostmodern-skylarkdemo";
  const READ_API_URL = `https://telemetry-query-${workspaceId}.platform.quix.ai`;
  const token = localStorage.getItem("quix-token");

  const baseHeaders = {
    method: "POST",
    headers: {
      type: "application/json",
      Authorization: `bearer ${token}`,
    },
  };

  const baseMethodConf = {
    ...baseHeaders,
    url: `${READ_API_URL}/parameters/data`,
  };

  const stringParameters = [
    {
      parameterName: "eventType",
      aggregationType: "None",
    },
  ];

  const numericParameters = [
    {
      parameterName: "engagement",
      aggregationType: "Mean",
    },
  ];

  const baseQuery = ({ objectId, value, params }) => {
    const baseQuery = {
      ...params,
      groupBy: ["objectId", "eventType"],
      tagFilters: [
        {
          tag: "eventType",
          operator: "Equal",
          value,
        },
      ],
    };

    if (objectId) {
      baseQuery.tagFilters.push({
        tag: "objectId",
        operator: "Equal",
        value: objectId,
      });
    }

    return baseQuery;
  };

  const getAllQuery = (objectId, params) => ({
    ...params,
    groupBy: ["objectId", "eventType"],
    tagFilters: [
      {
        tag: "objectId",
        operator: "Equal",
        value: objectId,
      },
    ],
  });

  /**
   * Get clickThrough by objectId
   *
   *
   * @param {string} objectId
   *
   * @return {promise}
   */
  service.getClickThrough = (objectId) => {
    const deferred = $q.defer();
    const req = {
      ...baseMethodConf,
      data: baseQuery({
        objectId,
        value: "click through",
        params: {
          stringParameters,
        },
      }),
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  /**
   * Get view count by objectId
   *
   *
   * @param {string} objectId
   *
   * @return {promise}
   */
  service.getViewCount = (objectId) => {
    const deferred = $q.defer();
    const req = {
      ...baseMethodConf,
      data: baseQuery({
        objectId,
        value: "view count",
        params: {
          stringParameters,
        },
      }),
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  /**
   * Get play rate by objectId
   *
   *
   * @param {string} objectId
   *
   * @return {promise}
   */
  service.getPlayRate = (objectId) => {
    const deferred = $q.defer();
    const req = {
      ...baseMethodConf,
      data: baseQuery({
        objectId,
        value: "play rate",
        params: {
          stringParameters,
        },
      }),
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  /**
   * Get engagement by objectId
   *
   *
   * @param {string|null} objectId
   * @param {Array<string>} streamIds
   *
   * @return {promise}
   */
  service.getEngagement = (objectId, streamIds = []) => {
    const deferred = $q.defer();
    const req = {
      ...baseMethodConf,
      url: `${READ_API_URL}/parameters/data`,
      data: baseQuery({
        objectId,
        value: "engagement",
        params: {
          numericParameters,
          streamIds,
        },
      }),
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  service.getAllStringMetrics = (objectId) => {
    const deferred = $q.defer();

    const req = {
      ...baseMethodConf,
      data: getAllQuery(objectId, { stringParameters }),
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  /**
   *
   * Gets all the streams in a particular location
   *
   * @return {promise}
   */
  service.getStreamsByLocation = (location) => {
    const deferred = $q.defer();
    const req = {
      ...baseHeaders,
      url: `${READ_API_URL}/streams`,
      data: {
        location: `/homepage/${location}`,
        topic: "website",
      },
    };

    $http(req)
      .success((data) => deferred.resolve(data))
      .catch((error) => deferred.reject(error));

    return deferred.promise;
  };

  return service;
}

export default QuixService;
