class EntityRelationship {
  /**
   * @constructor
   */
  constructor() {
    this.relationshipData = {
      requiredProgressData: {},
      optionalProgressData: {},
      tabIndicator: { completed: true },
      completedRequiredRelationships: 0,
      completedOptionalRelationships: 0,
    };
  }

  /**
   * Sets the relationship counters for the page ui
   * @access private
   * @param {Object} data
   * @param {string} type - the type of relationship (for example required )
   * @returns {void}
   */
  setCounters(data, type) {
    const initialCount =
      this.relationshipData[`${data.relationshipName}Count`] || 0;

    if (initialCount === 0 && initialCount < data.numberOfAvailableItems) {
      this.relationshipData[`completed${type}Relationships`]++;
    } else if (
      data.numberOfAvailableItems === 0 &&
      initialCount > data.numberOfAvailableItems
    ) {
      this.relationshipData[`completed${type}Relationships`]--;
    }

    this.relationshipData[`${data.relationshipName}Count`] =
      data.numberOfAvailableItems;
  }

  /**
   * Sets the data for the required progress indicator
   * @access private
   * @param {number} percentage
   * @param {string} text
   * @returns {void}
   */
  setRequiredProgressData(percentage, text) {
    this.relationshipData.requiredProgressData = {
      channelName: "requiredProgress.update",
      text,
      completionPercentage: percentage,
      required: true,
    };
  }

  /**
   * Sets the data for the required progress indicator
   * @access private
   * @param {number} percentage
   * @param {string} text
   * @returns {void}
   */
  setOptionalProgressData(percentage, text) {
    this.relationshipData.optionalProgressData = {
      channelName: "optionalProgress.update",
      text,
      completionPercentage: percentage,
      required: false,
    };
  }

  /**
   * Updates the required indicator on the relationship tab
   * @access private
   * @param {number} percentage
   * @returns {object}
   */
  setTabIndicator(percentage) {
    this.relationshipData.tabIndicator = { completed: percentage === 100 };
  }

  /**
   * Returns the percentage of completion
   * @access private
   * @param {object} completedRelationships
   * @param {object} maxRelationships
   * @returns {number}
   */
  completionPercentage(completedRelationships, maxRelationships) {
    return (completedRelationships / maxRelationships) * 100;
  }

  /**
   * Returns the completion text to display
   * @param {object} completedRelationship
   * @param {object} maxRelationships
   * @returns {string}
   */
  completionText(completedRelationship, maxRelationships) {
    return `${completedRelationship}/${maxRelationships}`;
  }
}

/**
 * Entity relationship factory
 * Contains shared functionality between tabs for relationships
 * @param {object} $q
 * @param {object} ConfigurationFactory
 * @param {object} MessageService
 * @returns {object} - the directive
 * @constructor
 */
function EntityRelationshipFactory($q, ConfigurationFactory) {
  const factory = {};
  let entityRelationship = new EntityRelationship();

  /**
   * Gets the relationship configuration
   * @access public
   * @param {string} entityType
   * @returns {Promise}
   */
  factory.getRelationshipConfig = (entityType) => {
    const deferred = $q.defer();

    ConfigurationFactory.getTabConfiguration(entityType, "relationships").then(
      (config) => {
        deferred.resolve(config);
      }
    );

    return deferred.promise;
  };

  /**
   * Resets the controller where the relationship data is stored so that it creates a new
   * instance for each entity.
   * @returns {void}
   */
  factory.reset = () => {
    entityRelationship = new EntityRelationship();
  };

  /**
   * Updates the required relationship data
   * @param {object} data
   * @param {object} config
   * @returns {{
   *    requiredProgressData: {Object},
   *    optionalProgressData: {Object},
   *    tabIndicator: {completed: {boolean}},
   *    completedRequiredRelationships: {number},
   *    completedOptionalRelationships: {number}}}
   */
  factory.updateRequired = (data, config) => {
    entityRelationship.setCounters(data, "Required");

    entityRelationship.setRequiredProgressData(
      entityRelationship.completionPercentage(
        entityRelationship.relationshipData.completedRequiredRelationships,
        config.min_required_relationships
      ),
      entityRelationship.completionText(
        entityRelationship.relationshipData.completedRequiredRelationships,
        config.min_required_relationships
      )
    );

    entityRelationship.setTabIndicator(
      entityRelationship.completionPercentage(
        entityRelationship.relationshipData.completedRequiredRelationships,
        config.min_required_relationships
      )
    );

    return entityRelationship.relationshipData;
  };

  /**
   * Updates the optional relationship data
   * @param {object} data
   * @param {object} config
   * @returns {{
   *    requiredProgressData: {Object},
   *    optionalProgressData: {Object},
   *    tabIndicator: {completed: {boolean}},
   *    completedRequiredRelationships: {number},
   *    completedOptionalRelationships: {number}}}
   */
  factory.updateOptional = (data, config) => {
    entityRelationship.setCounters(data, "Optional");

    entityRelationship.setOptionalProgressData(
      entityRelationship.completionPercentage(
        entityRelationship.relationshipData.completedOptionalRelationships,
        config.optional_relationships.length
      ),
      entityRelationship.completionText(
        entityRelationship.relationshipData.completedOptionalRelationships,
        config.optional_relationships.length
      )
    );

    return entityRelationship.relationshipData;
  };

  return factory;
}

export default EntityRelationshipFactory;
