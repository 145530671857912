import Flux from "flux";
/**
 * @file SetContentsDispatcher
 * SetContents instance of dispatcher
 */

/**
 * Use a new instance of dispatcher from the Flux lib.
 * @type {Class}
 */
const setContentsDispatcher = Flux.Dispatcher;

export default setContentsDispatcher;
