import template from "./tag-update.html";

/**
 * @fileOverview tagupdate.js
 * This class manages tags in the context of their relationship with their parent.
 * We do not edit the tag entity itself here.
 */

/**
 * @class TagUpdateCtrl
 */
class TagUpdateCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {service} UID_PATTERN
   * @param {service} MessageService
   * @param {factory} TagsFactory
   * @param {service} NotificationService
   */
  constructor(
    $scope,
    $location,
    $cookies,
    UID_PATTERN,
    MessageService,
    TagsFactory,
    NotificationService
  ) {
    this.$scope = $scope;
    this.$location = $location;
    this.$cookies = $cookies;
    this.MessageService = MessageService;
    this.TagsFactory = TagsFactory;
    this.NotificationService = NotificationService;
    this.data = undefined;
    this.parentData = undefined;
    this.uidPattern = UID_PATTERN;
  }

  /**
   * Bind params
   * @returns {void}
   */
  $onInit() {
    this.parentData = this.tag;

    this.uid = this.parentData.tag_url.match(this.uidPattern)[0];

    this.getTag();
    this.setSubscriptions();
    this._buildModalOptions();
  }

  /**
   * set pub sub subscriptions
   *
   * @listens ModalCtrl#itemBlock.$uid-haschanged
   * @callback tagUpdateCtrl#tagUpdate
   * @returns {void}
   */
  setSubscriptions() {
    this.tagUpdate = `itemblock:${this.uid}-haschanged`;

    this.MessageService.registerChannel(this.tagUpdate);
    this.MessageService.on(this.tagUpdate, (ch, data) => this.update(data));

    this.tagDelete = `deleteSelf.${this.uid}`;

    this.MessageService.registerChannel(this.tagDelete);
    this.MessageService.on(this.tagDelete, () => this.removeTag());

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions();
    });
  }

  /**
   * @returns  {void}
   * @private
   */
  _hasChildEntity() {
    const cookieName = "tagchildEntity";
    const childEntity = this.$cookies.getObject(cookieName) || null;

    if (childEntity && childEntity.parent.parentId === this.uid) {
      this.$cookies.remove(cookieName);
      this.parentData.schedule_urls.push(childEntity.child.self);
      this.context.updateTag(this.parentData, this.data.name || "Tag");
    }
  }

  /**
   * De-register pub/sub subscriptions
   * @returns {void}
   */
  deregisterSubscriptions() {
    this.MessageService.unregisterChannel(this.tagUpdate);
    this.MessageService.unregisterChannel(this.tagDelete);
  }

  /**
   * getTag
   * gets Tag metadata and assigns its id to the relationship data.
   * This allows us to have both the tag entity and its relationship context in scope.
   * @returns {void}
   */
  getTag() {
    this.TagsFactory.getByUrl(this.parentData.tag_url)
      .then((data) => {
        this.initialData = angular.extend({}, data);
        this.data = angular.extend({}, data);
        this._hasChildEntity();
        /*
         * create a temporary id based off entity uid
         * so we have a non-entity specific property to keep track of
         */
        this.parentData.relationshipId = this.data.uid;
        this.getTagCategory();
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * Gets the tag's category
   * @returns {void}
   */
  getTagCategory() {
    this.TagsFactory.getCategory(this.data.category_url).then((data) => {
      this.data.type = data.name;
    });
  }

  /**
   * update tag
   * @param  {object} tag in the context of its relationship with the parent entity
   * @returns {void}
   */
  update(tag) {
    this.parentData = tag;
    this.context.updateTag(tag, this.data.name);
  }

  /**
   * Removes a tag
   * @returns {void}
   */
  removeTag() {
    this.context.removeTag(this.data);
  }

  /**
   * generateModalOptions
   * @return {void}
   */
  _buildModalOptions() {
    const parentEntity = {
      parentId: this.uid,
      parentType: "tag",
      parentTitle: this.entity.title,
      childOf: this.$location.url(),
    };

    this.modalOptions = {
      channels: { save: `itemblock:${this.uid}-haschanged` },
      isRemoval: true,
      parent: parentEntity,
      entity: "tags",
    };
  }
}

/**
 * tagUpdateDirective
 * @returns {object} directive definition object
 */
function tagUpdateDirective() {
  return {
    restrict: "EA",
    replace: true,
    scope: {},
    bindToController: {
      tag: "=",
      config: "=",
      entity: "=",
      context: "=",
    },
    controller: TagUpdateCtrl,
    controllerAs: "module",
    template,
  };
}

export default tagUpdateDirective;
