import { ArticleMedia } from "skylarklib/helpers";

import * as toasterActions from "store/toaster/toaster.actions";

import {
  toggleHasChanged,
  resetHasChanged,
} from "store/editing-state/editing-state.actions";

import { updateEntity, updateEntityWithData } from "store/entity/entity.action";
import NotificationMessage from "skylarklib/constants/notification-text";
import actionNames from "./editor.actions.constants";

/**
 * toggle editing action creator
 * @returns {Object}
 */
function _setEditorState(data, hasChanged) {
  return {
    type: actionNames.SET_EDITOR_STATE,
    data,
    hasChanged,
  };
}

/**
 * _discardEditorChanges
 * @returns {Object}
 */
function _discardEditorChanges() {
  return {
    type: actionNames.DISCARD_EDITOR_CHANGES,
  };
}

/**
 * _resetEditorState
 * @returns {Object}
 */
function _resetEditorState() {
  return {
    type: actionNames.RESET_EDITOR_STATE,
  };
}

/**
 * toggleEditing
 * @returns {Function}
 */
function setEditorState(data, hasChanged) {
  return (dispatch) => {
    dispatch(_setEditorState(data, hasChanged));
    dispatch(toggleHasChanged(hasChanged));
  };
}

/**
 * discardEditorChanges
 * @returns {Function}
 */
function discardEditorChanges(title) {
  return (dispatch, getState) => {
    dispatch(toasterActions.popInfoToast(title, "discard"));
    dispatch(_discardEditorChanges());
    dispatch(resetHasChanged());
  };
}

/**
 * _resetEditorState
 * @returns {Function}
 */
function resetEditorState() {
  return (dispatch) => {
    dispatch(_resetEditorState());
    dispatch(resetHasChanged());
  };
}

/**
 * expandEditorMedia
 * @param  {Object} editorData
 * @return {Function}
 */
function expandEditorMedia(editorData, config) {
  return (dispatch) =>
    ArticleMedia.expandEntityItemMedia(editorData.items, config).then(
      (items) => {
        dispatch(updateEntityWithData({ ...editorData, items }));
      }
    );
}

/**
 * saveArticle
 * @param  {String} self
 * @param  {Object} config
 * @param  {Object} items
 * @return {Function}
 */
function saveArticle(self, config, items) {
  return (dispatch) => {
    const successMessage = (data) =>
      NotificationMessage.saveSuccess(data.title);
    const updateAction = updateEntity(self, items, null, successMessage);

    const { promise } = updateAction.api;
    dispatch(updateAction);

    return promise
      .then((data) => {
        dispatch(expandEditorMedia(data, config));
      })
      .then(() => {
        dispatch(resetEditorState());
      });
  };
}

export {
  expandEditorMedia,
  setEditorState,
  resetEditorState,
  discardEditorChanges,
  saveArticle,
};
