import NotificationConstant from "./notification.constant";

const initialState = [];

const NotificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case NotificationConstant.SHOW_INFO_TOAST:
      return [
        ...state,
        {
          message: action.message,
          type: "info",
        },
      ];
    case NotificationConstant.SHOW_ERROR_TOAST:
      return [
        ...state,
        {
          message: action.message,
          type: "error",
        },
      ];
    case NotificationConstant.REMOVE_TOAST:
      return state.slice(1);
    default:
      return state;
  }
};

export default NotificationReducer;
