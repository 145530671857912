import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

import ModalWrapper from "./modal-wrapper/modal-wrapper-container";
import ModalOverlay from "./modal-overlay/modal-overlay";

export default class Modal extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    destroyModal: PropTypes.func.isRequired,
    modal: PropTypes.shape({
      modalInstances: PropTypes.arrayOf(Object),
    }).isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.destroyModal = this.destroyModal.bind(this);

    this.modalRoot = document.getElementById("modal-root");
  }

  /**
   * destroyModal
   */
  destroyModal() {
    this.props.destroyModal(this.props.modal.modalInstances);
  }

  /**
   * render and create portal
   * @returns {element}
   */
  render() {
    const modals = this.props.modal.modalInstances;

    return [
      <ModalOverlay
        shouldDisplay={!!this.props.modal.modalInstances.length}
        key="modalOverlay"
      />,
      modals.map((modal, index) =>
        createPortal(
          <ModalWrapper
            options={modal}
            destroyModal={this.destroyModal}
            modalIndex={index}
          />,
          this.modalRoot
        )
      ),
    ];
  }
}
