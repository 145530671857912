import { SET_DIMENSION, SET_LANGUAGE } from "./global-params.constant";

export const setDimension = (filters) => ({
  type: SET_DIMENSION,
  filters,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  language,
});
