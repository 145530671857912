import React from "react";

import classnames from "classnames";
import CSSTransition from "react-transition-group/CSSTransition";

import styles from "./loading.scss";
/*
 * const Loader = () => (
 *   <div className="loader__container">
 *     <div className="loader__pulse loader__pulse--left" />
 *     <div className="loader__pulse loader__pulse--right" />
 *   </div>
 * );
 */

/**
 * Loading
 */
export default function Loading(props) {
  const transitionClasses = {
    enter: styles.loading__enter,
    enterActive: styles["loading__enter--active"],
    exit: styles.loading__exit,
    exitActive: styles["loading__exit--active"],
  };

  return (
    <CSSTransition
      in={props.loading}
      timeout={100}
      mountOnEnter
      unmountOnExit
      classNames={transitionClasses}
    >
      <div className={classnames(styles.loading)}>
        <div className={styles.loading__container}>
          <div
            className={classnames(
              styles.loading__pulse,
              styles["loading__pulse--left"]
            )}
          />
          <div
            className={classnames(
              styles.loading__pulse,
              styles["loading__pulse--right"]
            )}
          />
        </div>
        <p className={styles.loading__text}>Loading...</p>
      </div>
    </CSSTransition>
  );
}
