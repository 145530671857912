import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import styles from "./schedules.scss";

/**
 * Renders the Schedule Label.
 * @param {Object} props
 * @returns {JSX}
 */
export default function Schedules(props) {
  return (
    <div
      className={classnames(
        styles.schedules,
        styles[`schedules--${props.styling}`]
      )}
    >
      {props.text}
    </div>
  );
}

Schedules.propTypes = {
  styling: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
};
