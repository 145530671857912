/**
 * @fileOverview SkylarkApiInfoService Service
 * A singleton service to set skylark api information in memory.
 */

class SkylarkApiInfoService {
  /**
   * @constructor
   * @param   {Object} ApiService
   * @param   {Object} $q
   */
  constructor(ApiService, $q) {
    this.ApiService = ApiService;
    this.$q = $q;
    this.skylarkInfo = null;
  }

  /**
   * _checkSkylarkInfoCache
   * @description Caches the promise
   * @returns {promise}
   */
  _checkSkylarkInfoCache() {
    if (!this.skylarkInfoPromise) {
      this.skylarkInfoPromise = this.ApiService.get("/api/about-skylark/");
    }

    return this.skylarkInfoPromise;
  }

  /**
   * getSkylarkInfo
   * @returns {promise} - a promise resolving 'about skylark'
   */
  getSkylarkInfo() {
    if (!this.skylarkInfo) {
      return this._checkSkylarkInfoCache().then((data) => {
        this.skylarkInfo = data;

        return data;
      });
    }

    return this.$q.resolve(this.skylarkInfo);
  }

  /**
   * getVersion
   * @returns {promise} - a promise resolving to api version
   */
  getVersion() {
    return this.getSkylarkInfo().then((data) => data.skylark_version);
  }

  /**
   * getDefaultLanguage
   * @returns {promise} - a promise resolving to api default language
   */
  getDefaultLanguage() {
    return this.getSkylarkInfo().then((data) => data.default_language);
  }

  /**
   * check if Raven is available
   * @returns {promise<Boolean>}
   */
  hasRaven() {
    return this.getSkylarkInfo().then((data) => data.raven === true);
  }

  /**
   * check if Pelican is available
   * @returns {promise<Boolean>}
   */
  hasPelican() {
    return this.getSkylarkInfo().then((data) => data.pelican === true);
  }

  /**
   * check if Skylark is using the AWS Cognito auth
   * @returns {promise}
   */
  hasCognitoAuth() {
    return this.getSkylarkInfo().then(
      (data) =>
        data.cognito_auth &&
        (data.cognito_auth === true || data.cognito_auth === 1)
    );
  }
}

export default SkylarkApiInfoService;
