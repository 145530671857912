const TEXT_TRACK_UPLOAD = {
  UPLOAD_STARTED: "TextTrackUpload.Started",
  UPLOAD_FAILED: "TextTrackUpload.Failed",
  UPLOAD_COMPLETE: "TextTrackUpload.Completed",
  UPLOAD_PROGRESS: "TextTrackUpload.Progress",
  INGEST_STARTED: "TextTrackUpload.IngestStarted",
  REMOVE_UPLOAD_FROM_STORE: "TextTrackUpload.RemoveFromStore",
  DELETE_ASSET: "TextTrackUpload.DeleteAsset",
  TEXT_TRACK_CREATED: "TextTrackUpload.TextTrackCreated",
  TEXT_TRACK_UPDATE: "TextTrackUpload.TextTrackUpdate",
  DOCUMENT_CREATED: "TextTrackUpload.DocumentCreated",
  ADD_UPLOAD: "TextTrackUpload.addUpload",
  HIDE_FROM_MODAL: "TextTrackUpload.hideFromModal",
};

export default TEXT_TRACK_UPLOAD;
