import React from "react";
import PropTypes from "prop-types";

import { DEFAULT_TITLE } from "./message-overlay.constants";

import styles from "./message-overlay.scss";

/**
 * MessageOverlay
 * @param {Object} props
 */
export default function MessageOverlay(props) {
  return (
    <div className={styles["message-overlay"]}>
      <p className={styles["message-overlay__title"]}>{props.title}</p>
      {props.children && (
        <div className={styles["message-overlay__content"]}>
          {props.children}
        </div>
      )}
    </div>
  );
}

MessageOverlay.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
};

MessageOverlay.defaultProps = {
  children: undefined,
  title: DEFAULT_TITLE,
};
