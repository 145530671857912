import template from "./entity-content.html";

/**
 * @fileOverview render Assets Tab Page
 *
 */

class EntityContentsPageCtrl {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} $scope
   * @param {Object} EntityFactory
   * @param {Object} ConfigurationFactory
   * @param {Object} AssetsFactory
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} EntityVersionsService
   * @param {Object} HistoryService
   * @param {Object} _
   */
  constructor(
    $q,
    $scope,
    EntityFactory,
    ConfigurationFactory,
    AssetsFactory,
    MessageService,
    NotificationService,
    EntityVersionsService,
    HistoryService,
    _
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this._ = _;

    this.EntityFactory = EntityFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.AssetsFactory = AssetsFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.EntityVersionsService = EntityVersionsService;
    this.HistoryService = HistoryService;

    this._init();
  }

  /**
   * Initialize the controller
   * @private
   * @returns {void}
   */
  _init() {
    this.entityName = this.EntityFactory.getEntityName();

    this._setupSubscriptions();
    this._getAssetTypes();

    // When switching between tabs, don't reload data, get it form entityDetail
    if (
      this.$scope.$parent.entity &&
      !this._.isEmpty(this.$scope.$parent.entity.data)
    ) {
      this.data = { ...this.$scope.$parent.entity.data };
      this._setupData();
    }

    const loadingAccounts = this._getAccounts();
    const loadingConfiguration = this._loadConfiguration();

    this.$q
      .all([loadingAccounts, loadingConfiguration])
      .then(([accounts, { versions }]) => {
        this.versions.accountsWithUpload =
          this.EntityVersionsService.filterAccountsWithUpload(
            accounts.objects,
            versions
          );
      });
  }

  /**
   * Sets up the data for the controller
   * @private
   * @returns {void}
   */
  _setupData() {
    this._sortItemsByType();
  }

  /**
   * Set up the pub/sub subscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    const dataChannelName = `${this.entityName}.onData`;
    const updateChannelName = "ContentTab.update";

    this.MessageService.subscribe(dataChannelName, (channel, data) => {
      this.data = angular.extend({}, data.data);
      this.channel = data.channel;

      this._setupData();
    });

    this.MessageService.subscribe(updateChannelName, (ch, updatedItemData) => {
      this._updateTabAndParents(updatedItemData);
    });

    this.$scope.$on("$destroy", () => {
      this.MessageService.unregisterChannel("ContentTab.update");
    });
  }

  /**
   * loadConfiguration
   * load entity specific config for tab and
   * the configuration for 'versions'
   * as it's needed for the `relationship-module`s
   * @returns {Promise}
   */
  _loadConfiguration() {
    const entityAssets = this.ConfigurationFactory.getTabConfiguration(
      this.entityName,
      "assets"
    );
    const assetsVersions = this.ConfigurationFactory.getTabConfiguration(
      "assets",
      "versions"
    );

    return this.$q
      .all([entityAssets, assetsVersions])
      .then(([config, versions]) => {
        this.config = config;
        this.contentTypes = config.content_types;
        this.versions = versions;

        return { config, versions };
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * loads all accounts
   *
   * @return {Promise}
   */
  _getAccounts() {
    return this.EntityVersionsService.getAccounts().then((data) => {
      this.accounts = data.objects;

      return data;
    });
  }

  /**
   * Split the items attached to this entity by type.
   * @private
   * @returns {void}
   */
  _sortItemsByType() {
    const sortedItems = this.AssetsFactory.sortAssetsUrlsByType(
      this.data.items
    );

    Object.keys(sortedItems).forEach((key) => {
      this.data[`${key}_urls`] = sortedItems[key];
    });
  }

  /**
   * Get the asset types and
   * sets them as a property
   * @returns {void}
   */
  _getAssetTypes() {
    this.AssetsFactory.getAssetTypes().then((data) => {
      this.assetTypes = data.objects;

      return data;
    });
  }

  /**
   * Combine the split item types
   * @returns {Array}
   * @private
   */
  _combinedItems() {
    let items = [];

    this.contentTypes.forEach((type) => {
      if (this.data[`${type.name}_urls`]) {
        items = items.concat(this.data[`${type.name}_urls`]);
      }
    });

    return items;
  }

  /**
   * Updates data in view to keep things in sync.
   * @param {Object} updatedItemData
   * @private
   * @returns {void}
   */
  _updateTabAndParents(updatedItemData) {
    if (updatedItemData.items.length) {
      const updatedUrls = updatedItemData.items.map((item) => item.self);
      this.data[updatedItemData.field] = this._.union(
        this.data[updatedItemData.field],
        updatedUrls
      );
    }

    this.data.items = this._combinedItems();
    this._updateParent();
  }

  /**
   * Update parent in view. Doesn't need save entity as the relationship is managed on the child.
   * @returns {void}
   */
  _updateParent() {
    if (this.$scope.$parent) {
      this.$scope.$parent.entity.data = this.data;
    }
  }
}

const entityContentsPageComponent = {
  controller: EntityContentsPageCtrl,
  controllerAs: "assets",
  template,
};

export default entityContentsPageComponent;
