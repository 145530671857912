const EntityConstant = {
  SET_CURRENT_ENTITY: "SET_CURRENT_ENTITY",
  FETCH_ENTITY: "FETCH_ENTITY",
  FETCH_AND_ATTACH_ENTITY: "FETCH_AND_ATTACH_ENTITY",
  CREATE_ENTITY: "CREATE_ENTITY",
  CREATE_ENTITY_CANCELLED: "CREATE_ENTITY_CANCELLED",
  CREATE_TRANSLATION: "CREATE_TRANSLATION",
  UPDATE_ENTITY: "UPDATE_ENTITY",
  UPDATE_SCHEDULE: "UPDATE_SCHEDULE",
};

export default EntityConstant;
