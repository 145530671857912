/**
 * Global error interception
 * @param {Object} $q
 * @param {Object} $injector
 * @param {Object} $rootScope
 * @returns {factory}
 * @constructor
 */
function ErrorInterceptorService($q, $injector, $rootScope) {
  const factory = {};

  factory.responseError = (response) => {
    const url = response.config && response.config.url;
    const isConfigUrl = url && url.includes("/cms-config/");
    const isInvalidApiUrl = url && url.endsWith("/api/:entity/");

    if (
      response.status === 404 &&
      (isConfigUrl || isInvalidApiUrl) &&
      !$rootScope.unauthorized
    ) {
      $injector.get("$state").go("Error");
    }

    return $q.reject(response);
  };

  return factory;
}

export default ErrorInterceptorService;
