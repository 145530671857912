/**
 * @class ContentSettingsService
 */
class ContentSettingsService {
  /**
   * @constructor
   * @param   {Object} LanguagesService
   */
  constructor(
    GlobalParamsService,
    LanguagesService,
    MessageService,
    NotificationService,
    CONTENT_SETTINGS
  ) {
    this.GlobalParamsService = GlobalParamsService;
    this.LanguagesService = LanguagesService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.CONTENT_SETTINGS = CONTENT_SETTINGS;
  }

  /**
   * init
   * @memberof ContentSettingsService
   */
  init() {
    this.languages = [];
    this._setLanguages();
  }

  /**
   * setLanguages
   * @returns
   * @memberof ContentSettingsService
   */
  _setLanguages() {
    this.LanguagesService.getLanguages()
      .then((data) => {
        this.languages = data;
        this.MessageService.publish(this.CONTENT_SETTINGS.LANGUAGE_SET);
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * getLanguages
   * @returns
   * @memberof ContentSettingsService
   */
  getLanguages() {
    return this.languages;
  }

  /**
   * getCurrentLanguage
   * @memberof ContentSettingsService
   */
  getCurrentLanguage() {
    return this.languages.find(
      (language) => language.iso_code === this.GlobalParamsService.getLanguage()
    );
  }
}

export default ContentSettingsService;
