const dynamicObjectConstants = {
  tooltips: {
    multisearch: {
      message:
        "Use this box to enter all of the OR terms for your item, e.g. only articles that include the tags 'fish' OR 'chips'",
    },
  },
};

export default dynamicObjectConstants;
