/**
 * ApiEndpointFactory
 *
 *
 *
 * @description ApiEndpointFactory allows us to switch between any number of end
 * points based on version number.
 */

/**
 * ApiEndpointFactory
 * @param {Object} $q
 * @param {Object} ApiService
 * @param {Object} SkylarkApiInfoService
 * @param {Object} API_ENDPOINTS
 * @returns {Object} Factory
 */
function ApiEndpointFactory(
  $q,
  ApiService,
  SkylarkApiInfoService,
  API_ENDPOINTS
) {
  const factory = {};

  /**
   * Loops through all available endpoints and returns the appropriate one
   * for this API version
   *
   * @param {Number} currentVersion - The current version number
   * @param {Object} endpointCollection   - Object containing all available
   *                                        endpoints for this object
   * @returns {void}
   */
  factory.matchVersionEndpoint = (currentVersion, endpointCollection) => {
    let versionEndpoint;
    endpointCollection.forEach((availableEndpoint) => {
      if (currentVersion >= availableEndpoint.version) {
        versionEndpoint = availableEndpoint.slug;
      }
    });

    return versionEndpoint;
  };

  /**
   * Gets the current version and returns the correct endpoint
   *
   * @param   {String} endpointType - The type of endpoint we are looking for
   * @returns {Promise}             - A deferred promise
   */
  factory.getEndpoint = (endpointType) => {
    const deferred = $q.defer();

    SkylarkApiInfoService.getVersion().then((data) => {
      const endpointCollection = API_ENDPOINTS[endpointType];
      const versionNumber = parseFloat(data);
      const endpoint = factory.matchVersionEndpoint(
        versionNumber,
        endpointCollection
      );
      deferred.resolve(endpoint);
    });

    return deferred.promise;
  };

  return factory;
}

export default ApiEndpointFactory;
