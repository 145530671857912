import angular from "angular";
import CreateEntityButton from "./create-entity-button/create-entity-button.module";
import EntityListDirective from "./entity-list.directive";
import ListingItemBlock from "./listing-item-block/listing-item-block.module";

const EntityList = angular
  .module("Skylark.Components.EntityList", [
    CreateEntityButton.name,
    ListingItemBlock.name,
  ])
  .directive("entityList", EntityListDirective);

export default EntityList;
