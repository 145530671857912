import angular from "angular";
import uploadAreaDragDropComponent from "./upload-area-drag-drop/upload-area-drag-drop.component";
import uploadAreaUrlComponent from "./upload-area-url/upload-area-url.component";
import uploadAreaComponent from "./upload-area/upload-area.component";

const UploadArea = angular
  .module("Skylark.Components.UploadArea", [])
  .component("uploadAreaDragDrop", uploadAreaDragDropComponent)
  .component("uploadAreaUrl", uploadAreaUrlComponent)
  .component("uploadArea", uploadAreaComponent);

export default UploadArea;
