import React, { useContext, useEffect, useState } from "react";
import { useDebounce } from "react-use";
import _ from "lodash";
import classnames from "classnames";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { ThemeContext } from "components/_react/theme/theme";
import { FilterPayload, GenericWidget } from "../search-and-filter.types";

interface SearchFilterProps extends GenericWidget<FilterPayload> {
  placeholder: string;
}

const defaultProps = {
  filter: {},
};

const SearchFilter = ({
  placeholder,
  filter,
  updateFilter,
}: SearchFilterProps) => {
  const [searchQueryValue, setSearchQueryValue] = useState(filter.value);
  const theme = useContext(ThemeContext);

  useEffect(() => {
    setSearchQueryValue(filter.value);
  }, [filter]);

  const [, cancel] = useDebounce(
    () => {
      updateFilter([
        {
          bucket: "search",
          name: "search",
          payload: {
            value: searchQueryValue,
          },
        },
      ]);
    },
    200,
    [searchQueryValue]
  );

  /**
   * @param {object} event
   * @return void
   */
  const handleChange = (event) => {
    cancel();

    const { value } = event.currentTarget;

    setSearchQueryValue(value);
  };

  return _.isString(searchQueryValue) ? (
    <label className="search-field__label">
      <input
        aria-label="search query"
        className={classnames(
          "text-input text-input--has-icon search-field__input",
          theme && `text-input--${theme}`
        )}
        placeholder={placeholder}
        type="text"
        value={searchQueryValue}
        onChange={handleChange}
      />
      <SkylarkIcon extraClassName="search-field__icon" iconName="search" />
    </label>
  ) : null;
};

SearchFilter.defaultProps = defaultProps;

export default SearchFilter;
