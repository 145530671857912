import { ENTITY_CONTENT_TYPES } from "skylarklib/constants/entity-content-types";
import { CORE_ENTITIES } from "skylarklib/constants/core-entities";

/**
 * @param {string} configName - it can be sets, assets, articles
 * @param {string} value - it can be home, playlists, main, etc.
 *
 * @return {string}
 */
export const getSlugTypeByEntity = (configName, value) => {
  switch (configName) {
    case "sets":
      return `set_type__slug=${value}`;
    case "assets":
      return `asset_type__slug=${value}`;
    case "articles":
      return `article_type__slug=${value}`;
    default:
      return "";
  }
};

/**
 * returns the entity slug property name, as this property is entity specific,
 * for example the
 * sets -> set_type_slug, and
 * assets -> asset_type_slug
 *
 * @returns {string}
 */
export const getEntityTypeSlugKey = (entityName) => {
  const entityContent = ENTITY_CONTENT_TYPES[entityName];

  if (entityContent) {
    return `${entityContent}_type_slug`;
  }
};

/**
 * get the filter key on the url to filter by its type
 * @param entityName
 * @return {string}
 */
export const getEntityTypeFilterKey = (entityName) => {
  const entityContent = ENTITY_CONTENT_TYPES[entityName];

  if (entityContent) {
    return `${entityContent}_type__slug`;
  }
};

export const getEntityTypeUrlKey = (entityName) => {
  const entityContent = ENTITY_CONTENT_TYPES[entityName];

  if (entityContent) {
    return `${entityContent}_type_url`;
  }
};

export const isCoreEntity = (entityType) =>
  !!CORE_ENTITIES.find((entity) => entity === entityType);

/**
 * @param {string} entity
 * @param {string} suffix
 *
 * @return {string}
 *
 */
export const buildApiUrl = (entity, suffix = "") => `/api/${entity}/${suffix}`;

export const buildURL = ({
  name,
  type,
  uid,
}: {
  name: string;
  type: string;
  uid: string;
}) => {
  const customString = isCoreEntity(name) ? "" : "custom/";
  const entityTypeString = type ? `/type-${type}` : "";
  const entityUid = uid ? `/${uid}/` : "";

  return `/${customString}${name}${entityTypeString}${entityUid}`;
};

/**
 *
 * Builds a search url based on a entity, entity type and core entity conditional
 */
export const buildEntitySearchURL = ({
  entityName,
  entityType,
}: {
  entityName: string;
  entityType?: string;
}): string => {
  const customString = isCoreEntity(entityName) ? "" : "custom/";
  const entityTypeString = entityType ? `/type-${entityType}` : "";

  return `/${customString}${entityName}${entityTypeString}/`;
};
