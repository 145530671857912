import { MODAL_TYPES } from "skylarklib/constants/modal.constants";

/**
 * @fileOverview
 * Notification Constants
 * Notification constants can be static or have placeholders.
 * Placeholders should use a named property which will then be replaced by the copy
 * provided in the notificationOptions object.
 */
export default {
  default: {
    title: "Are you sure you want to carry out this action?",
    body: [
      {
        text: "Are you sure you want to do this?",
        key: 1,
      },
    ],
    cta: {
      confirm: {
        text: "Continue",
      },
      cancel: {
        text: "Cancel",
        triggers: "",
      },
    },
  },
  [MODAL_TYPES.NEW_LANGUAGE_VERSION]: {
    title: "Warning",
    body: [
      {
        hasPlaceholders: true,
        text: "This content does not currently contain a language version for [newLanguage].",
        key: 1,
      },
      {
        hasPlaceholders: true,
        text: "You can create a language version or press cancel to remain in the current language ([currentLanguage]).",
        key: 2,
      },
    ],
    cta: {
      confirm: {
        text: "Add Language",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
  [MODAL_TYPES.NO_LANGUAGE_AVAILABLE]: {
    title: "Warning",
    body: [
      {
        hasPlaceholders: true,
        text: "This content does not currently contain a language version for [currentLanguage].",
        key: 1,
      },
      {
        hasPlaceholders: true,
        text: "You can create a language version for this content using [currentLanguage].",
        key: 2,
      },
    ],
    cta: {
      confirm: {
        text: "Got it!",
      },
    },
  },
  [MODAL_TYPES.DISCARD_CHANGES]: {
    title: "Warning",
    body: [
      {
        text: "You have unsaved changes. Are you sure you want to finish editing?",
        key: 1,
      },
    ],
    cta: {
      confirm: {
        text: "Save and Finish",
      },
      cancel: {
        text: "Cancel",
      },
      alternative: {
        text: "Discard Changes",
      },
    },
  },
  [MODAL_TYPES.HAS_UNSAVED_CHANGES]: {
    title: "Warning",
    body: [
      {
        text: "You have unsaved changes. Are you sure you want to Leave?",
        key: 1,
      },
    ],
    cta: {
      confirm: {
        text: "Discard & Leave",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
  [MODAL_TYPES.LOGOUT]: {
    title: "Warning",
    body: [
      {
        text: "You have unsaved changes. Are you sure you want to sign out?",
        key: 1,
      },
    ],
    cta: {
      confirm: {
        text: "Discard & sign out",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
  [MODAL_TYPES.BOOKMARK_LIMIT]: {
    title: "Too Many Bookmarks",
    body: [
      {
        text: "You must delete an existing bookmark before adding another.",
        key: 1,
      },
    ],
    cta: {
      confirm: {
        text: "Dismiss",
      },
    },
  },
};
