/**
 * Transforms an object into an Array
 * @param _
 * @return {Array}
 */
function toArray(_) {
  return (obj) => {
    if (!_.isPlainObject(obj)) {
      return obj;
    }

    return Object.values(obj);
  };
}

export default toArray;
