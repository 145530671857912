import template from "./delete-block.html";

class DeleteBlockCtrl {
  /**
   * @constructor
   * @param   {Object} $scope
   * @param   {Object} $location
   * @param   {Object} MessageService
   * @param   {Object} NotificationService
   * @param   {Object} RouteService
   * @param   {Object} EntityFactory
   */
  constructor(
    $scope,
    $location,
    MessageService,
    NotificationService,
    RouteService,
    EntityFactory
  ) {
    this.$scope = $scope;
    this.$location = $location;
    this.MessageService = MessageService;
    this.EntityFactory = EntityFactory;
    this.RouteService = RouteService;
    this.NotificationService = NotificationService;
  }

  /**
   * init
   * @returns {void}
   */
  $onInit() {
    this.entityId = this.entity.uid;
    this.entitySelf = this.entity.self;
    this.entityName = this.EntityFactory.getEntityName();
    this.entityType = this.EntityFactory.getEntityType();

    this.confirmDeleteChannel = `confirmDelete.${this.entityId}`;
    // this channel is necessary to keep the data in sync with parent view and modules
    this.dataChannel = `${this.entityId}.dataChannel`;

    this.redirectPath = this.RouteService.buildURL({
      name: this.entityName,
      type: this.entityType,
    });

    this._setSubscriptions();
    this._buildModalOptions();
  }

  /**
   * deleteSelf
   * @returns {void}
   */
  deleteSelf() {
    this.EntityFactory.delete(this.entityName, this.entityId)
      .then(() => {
        this.$location.url(this.redirectPath);
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      notificationType: "delete",
      channels: {
        confirm: `confirmDelete.${this.entityId}`,
      },
    };
  }

  /**
   * setSubscriptions
   * @returns {void}
   */
  _setSubscriptions() {
    this.MessageService.subscribe(this.confirmDeleteChannel, () => {
      this.MessageService.publish("Entity.Delete", this.entitySelf);
      this.deleteSelf();
    });

    this.MessageService.subscribe(this.dataChannel, (channel, data) => {
      if (data.editability) {
        this.entity.editability = data.editability;
      }
    });
  }

  /**
   * on destroy lifecycle,
   * unregister channels
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.confirmDeleteChannel);
    this.MessageService.unregisterChannel(this.dataChannel);
  }
}

const DeleteBlockComponent = {
  bindings: {
    entity: "=",
    name: "@",
    message: "@",
  },
  template,
  controller: DeleteBlockCtrl,
  controllerAs: "component",
};

export default DeleteBlockComponent;
