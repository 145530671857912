import NotificationMessage from "skylarklib/constants/notification-text";

function UpdateItemActions(
  NotificationService,
  BatchRequestFactory,
  SetContentsStore,
  SetContentsDispatcher,
  ItemDecorationActions,
  SortingActions,
  DisplayTitleActions,
  SET_CONTENTS_ACTIONS,
  MessageService,
  $q,
  _
) {
  const factory = {};

  /**
   * Take a single language key from a scheduledItem with multiple langauge versions
   * @param {Object} scheduleItemWithKeys
   * @return {Object}
   * @private
   */
  function _extractSingleLanguageVersion(scheduleItemWithKeys) {
    return scheduleItemWithKeys[Object.keys(scheduleItemWithKeys)[0]];
  }

  /**
   * @Todo: Rewrite this when we have more information about schedule status in the API.
   * Handle edge case for when an item has no schedules, and we add a schedule to it.
   * Does't interrogate fully if item is valid just assumes one has added a valid schedule.
   */
  function _updateExpiredStatus(updatedItem) {
    const updatedLanguageVersion = _extractSingleLanguageVersion(
      updatedItem.scheduledItem
    );
    const originalItem = _getFullItemFromStore(updatedLanguageVersion);
    const originalLanguageVersion = _extractSingleLanguageVersion(
      originalItem.scheduledItem
    );

    if (
      originalLanguageVersion.schedule_urls.length === 0 &&
      updatedLanguageVersion.schedule_urls.length > 0
    ) {
      Object.keys(updatedItem.scheduledItem).forEach((key) => {
        updatedItem.scheduledItem[key].expired = false;
      });
    }

    return updatedItem;
  }

  /**
   * Check to see if the displayed schedule has changed
   * @param {Object} updatedItem
   * @param {string} language
   * @return {boolean}
   * @private
   */
  function _hasScheduleChanged(updatedItem) {
    const updatedLanguageVersion = _extractSingleLanguageVersion(
      updatedItem.scheduledItem
    );
    const originalItem = _getFullItemFromStore(updatedLanguageVersion);
    const originalLanguageVersion = _extractSingleLanguageVersion(
      originalItem.scheduledItem
    );

    return !_.isEqual(
      originalLanguageVersion.schedule_urls,
      updatedLanguageVersion.schedule_urls
    );
  }

  /**
   * _filterNewSchedules
   * @param   {Object} updatedItem
   * @returns {Array}
   */
  function _filterNewSchedules(updatedItem) {
    const updatedLanguageVersion = _extractSingleLanguageVersion(
      updatedItem.scheduledItem
    );
    const originalItem = _getFullItemFromStore(updatedLanguageVersion);
    const originalLanguageVersion = _extractSingleLanguageVersion(
      originalItem.scheduledItem
    );

    return updatedLanguageVersion.schedule_urls.filter(
      (url) => originalLanguageVersion.schedule_urls.indexOf(url) === -1
    );
  }

  /**
   * return the matching full item from the store
   * @param {Object} scheduledItem
   * @returns {Object}
   * @private
   */
  function _getFullItemFromStore(scheduledItem) {
    return SetContentsStore.getState().items.find(
      (item) => item.uid === scheduledItem.uid
    );
  }

  /**
   * setImageToUpdate
   * @param {object} image
   */
  function _setImageToUpdate(image) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.setImageToUpdate,
      image,
    });
  }

  /**
   * updateImageObject
   * @param {object} image
   * @param {string} uid
   */
  function _updateImageObject(image, uid) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.updateImageObject,
      image,
      uid,
    });
  }

  /**
   * Add an image to store to be saved
   * @param {Object}
   */
  function _updateImageOnObject(updatedImage, parentData) {
    _updateImageObject(updatedImage, parentData.uid);

    if (updatedImage.self) {
      _setImageToUpdate(updatedImage);
    } else if (updatedImage.imagePreview) {
      _setImageToCreate(updatedImage);
    }
  }

  /**
   * setImageToCreate
   * @param {Object} image
   */
  function _setImageToCreate(image) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.setImageToCreate,
      image,
    });
  }

  /**
   * Update an item in store using a scheduled item
   * @param {Object} scheduledItem
   */
  factory.updateScheduledItem = (scheduledItem) => {
    const item = _getFullItemFromStore(scheduledItem);

    const updatedItem = {
      ...item,
      scheduledItem: {
        [scheduledItem.language]: scheduledItem,
      },
    };

    factory.updateItem(updatedItem);
  };

  /**
   * Update a scheduled item with multiple languages
   * @param {Object} scheduledItem
   */
  factory.updateScheduledItemWithLanguages = (scheduledItem) => {
    const item = _getFullItemFromStore(
      _extractSingleLanguageVersion(scheduledItem)
    );

    const updatedItem = { ...item, scheduledItem };

    factory.updateItem(updatedItem);
  };

  /**
   * Update an item with changes to both scheduleItem and contentItem
   * @param {Object} scheduledItem
   * @param {Object} contentItem
   */
  factory.updateContentAndScheduleItem = (scheduledItem, contentItem) => {
    const item = _getFullItemFromStore(
      _extractSingleLanguageVersion(scheduledItem)
    );
    const updatedContentItem = {};

    Object.keys(contentItem).forEach((language) => {
      updatedContentItem[language] = {
        ...item.contentItem[item.displayedLanguage],
        ...contentItem[language],
        language,
      };
    });

    const updatedItem = {
      ...item,
      scheduledItem,
      contentItem: updatedContentItem,
    };

    factory.updateItem(updatedItem);
  };

  /**
   * get new schedules
   * @param   {Object} updatedItem
   * @returns {Promise}
   */
  factory._getItemSchedules = (updatedItem) => {
    const deferred = $q.defer();
    if (_hasScheduleChanged(updatedItem)) {
      const schedulesToGet = _filterNewSchedules(updatedItem);
      BatchRequestFactory.createGetRequests(
        "updated-schedules",
        schedulesToGet
      );
      BatchRequestFactory.process().then((data) => {
        const schedules = data.map((response) => JSON.parse(response.body));
        deferred.resolve(schedules);
      });
    } else {
      deferred.resolve(updatedItem.schedules);
    }

    return deferred.promise;
  };

  /**
   * merge updated schedules with existing schedules that have not been removed
   * @param   {Object} updatedItem
   * @param   {Array} newSchedules
   * @returns {Array}
   */
  factory._mergeUpdatedSchedules = (updatedItem, newSchedules) => {
    const updatedLanguageVersion = _extractSingleLanguageVersion(
      updatedItem.scheduledItem
    );

    const existingSchedules = updatedItem.schedules.filter(
      (schedule) =>
        updatedLanguageVersion.schedule_urls.indexOf(schedule.self) > -1
    );

    return _.uniq([...existingSchedules, ...newSchedules]);
  };

  /**
   * Update an item in store using a full item
   * @param {Object} updatedItem
   */
  factory.updateItem = (updatedItem) => {
    updatedItem = _updateExpiredStatus(updatedItem);

    factory._getItemSchedules(updatedItem).then((data) => {
      updatedItem.schedules = factory._mergeUpdatedSchedules(updatedItem, data);

      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.updateItem,
        item: {
          ...updatedItem,
          hasScheduleChanged: _hasScheduleChanged(updatedItem),
        },
      });

      const scheduledItem =
        updatedItem.scheduledItem[updatedItem.displayedLanguage];
      const imageObject =
        scheduledItem.image_urls && scheduledItem.image_urls[0];

      if (imageObject) {
        _updateImageOnObject(
          imageObject,
          updatedItem.scheduledItem[updatedItem.displayedLanguage]
        );
      }

      DisplayTitleActions.generateDisplayTitle(
        updatedItem.uid,
        updatedItem.type
      ).then(() => {
        ItemDecorationActions.decorateItems();
        ItemDecorationActions.assignActiveStatus();
        SortingActions.sortItems();
      });

      const contentTypeString = NotificationService.createNotificationString(
        updatedItem.type
      );

      NotificationService.notifyInfo(
        NotificationMessage.updateContentInSetSuccess(contentTypeString)
      );
    });
  };

  return factory;
}

export default UpdateItemActions;
