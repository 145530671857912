/**
 * @classdesc Action creators for creating the data required for set contents
 * @param {Object} SET_CONTENTS_ACTIONS
 * @return {Object}
 * @constructor
 */
function EntityCreationActions(
  SetContentsStore,
  SetContentsDispatcher,
  AddRemoveItemsActions,
  ApiRequestConfigFactory,
  ApiService,
  ImagesFactory,
  DynamicObjectFactory,
  SetsFactory,
  SET_CONTENTS_ACTIONS
) {
  const factory = {};

  /**
   * Send the entity request to API
   * @param  {Object} data - request object
   * @return {Promise}
   */
  function _sendEntityRequest(data) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.sendEntityRequest,
    });

    switch (data.entityType) {
      case "images":
        return ImagesFactory.createImageWithAlwaysSchedule(
          data,
          data.imageFile
        );
      case "dynamic-object":
        return DynamicObjectFactory.create(data);

      default:
        return ApiService.post(
          `/api/${data.entityType}`,
          data,
          ApiRequestConfigFactory.createRequestConfig({
            useGlobalParams: true,
          })
        );
    }
  }

  /**
   * Receive entity request back from API
   */
  function _receiveEntity() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveEntity,
    });
  }

  /**
   * Action creator for saving data
   */
  factory.createEntity = (requestObject) => {
    _sendEntityRequest(requestObject).then((response) => {
      _receiveEntity();
      AddRemoveItemsActions.addItem(response, requestObject.entityType);
    });
  };

  return factory;
}

export default EntityCreationActions;
