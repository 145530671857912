/**
 * @fileOverview smart field handler.
 * @deprecated
 */

class SmartfieldCtrl {
  constructor($scope, $timeout) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.isOpen = false;
    this.openTranComplete = false;
  }

  toggle($event) {
    $event.stopPropagation();
    this.isOpen = !this.isOpen;
  }

  expand() {
    const height = this.$scope.smartfieldOptionsEl.children(":first").height();

    this.$scope.smartfieldOptionsEl.height(height);

    this.$timeout(() => {
      this.$scope.smartfieldOptionsEl.height("auto");
      this.$scope.trigger.openTranComplete = true;
    }, 500);
  }

  collapse() {
    const height = this.$scope.smartfieldOptionsEl.children(":first").height();

    this.$scope.smartfieldOptionsEl.height(height);
    this.$scope.trigger.openTranComplete = false;

    this.$timeout(() => {
      this.$scope.smartfieldOptionsEl.height(0);
    }, 500);
  }
}

function smartfield() {
  return {
    restrict: "EA",
    controller: SmartfieldCtrl,
    controllerAs: "smartfield",
    scope: {
      module: "=parentModule",
      form: "=parentForm",
      item: "@config",
    },
  };
}

function smartfieldTrigger() {
  return {
    restrict: "EA",
    controller: SmartfieldCtrl,
    controllerAs: "trigger",
  };
}

function smartfieldOptions() {
  return {
    restrict: "EA",
    controller: SmartfieldCtrl,
    controllerAs: "options",
    link: (scope, element, attrs) => {
      scope.smartfieldOptionsEl = element;

      if (!scope.field.hasTrigger) {
        scope.options.expand();
      } else {
        scope.$watch(attrs.smartfieldOptions, (shouldExpand) => {
          if (shouldExpand) {
            scope.options.expand();
          } else {
            scope.options.collapse();
          }
        });
      }
    },
  };
}

export { smartfield, smartfieldTrigger, smartfieldOptions };
