/**
 * @fileOverview Consume data from configurable endpoint.
 * This allows us to iterate over the results of an endpoint passed on from config and use them in configurable templates,
 * without it needing to live in the dynamic widget or in a specific directive that needs it before it compiles.
 *
 */
/**
 * finds if allowed item and select dropdown item match
 * @param {string} allowedItem - allowed item name
 * @param {string} selectItem - selected menu dropdown value
 * @returns {boolean} isAllowedItemKeyMatching
 */
function hasMatchingAllowedItem(allowedItem, selectItem) {
  return allowedItem.toLowerCase() === selectItem.toLowerCase();
}

/**
 * find all the items in dropdown that match allowed list
 * @param {array} allowedItems - items allowed in dropdown
 * @param {string} selectItem - current select item in loop
 * @returns {Array} array of allowed items
 */
function findAllAllowedFields(allowedItems, selectItem) {
  return allowedItems.some((allowedItem) =>
    hasMatchingAllowedItem(allowedItem, selectItem)
  );
}

class SelectRelationshipInputCtrl {
  /**
   * @constructor
   * @param   {Object} _
   * @param   {Object} $q
   * @param   {Object} $element
   * @param   {Object} $scope
   * @param   {Object} $compile
   * @param   {Object} ApiService
   * @param   {Object} ApiRequestConfigFactory
   * @param   {Object} ConfigurationFactory
   * @param   {Object} ModalFactory
   * @param   {Object} RouteService
   */
  constructor(
    _,
    $q,
    $element,
    $scope,
    $compile,
    ApiService,
    ApiRequestConfigFactory,
    ConfigurationFactory,
    ModalFactory,
    RouteService
  ) {
    this._ = _;
    this.$q = $q;
    this.$element = $element;
    this.$scope = $scope;
    this.$compile = $compile;
    this.ApiService = ApiService;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.ModalFactory = ModalFactory;
    this.RouteService = RouteService;
    this.config = undefined;
    this.endpoint = undefined;
    this.options = undefined;
    this.allowed = undefined;
    this.limit = 5;
    this.start = 0;
  }

  $onInit() {
    this.loadConfiguration(this.item.field);
    this.checkResultCount = this._.debounce(
      this.checkResultCount.bind(this),
      250
    );
  }

  /**
   * loadConfiguration
   * @param   {object} config
   * @returns {void}
   */
  loadConfiguration(config) {
    this.config = config;
    this.endpoint = config.endpoint;
    this.allowed = config.allowed;

    if (!this.endpoint) {
      return this.getStaticOptions(config);
    }

    this.relationshipEntityName = this.endpoint.split("/")[2];
    this.ConfigurationFactory.getBaseConfiguration(
      this.relationshipEntityName
    ).then((entityConfig) => {
      this.getOptionsAndCount(entityConfig);
    });
  }

  /**
   * Assigns options to be displayed in select element
   * @param {object} entityConfig - config for the relationshipEntity
   * @returns {void}
   */
  getOptionsAndCount(entityConfig) {
    if (entityConfig.countEndpoint) {
      this.ApiService.get(`${this.endpoint}count/?all=true`).then((data) => {
        this.optionsCount = data.count;
      });
    }

    this.getOptions().then((data) => {
      this.options = this.filterAllowed(data.objects);
      this.resultsCount = this.options.length;
      this.optionsCount = this.optionsCount || data.objects.length;

      this.setSelectedObject();
    });
  }

  /**
   * Gets the static options from the configuration
   * @param {object} config for the current field
   * @return {void}
   */
  getStaticOptions(config) {
    this.options = config.options;

    this.setSelectedObject();
  }

  /**
   * Makes an API request to retrieve the options available
   * @return {Promise}
   */
  getOptions() {
    return this.ApiService.get(
      this.endpoint,
      this.ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig: {
          params: {
            all: true,
            fields: `${this.config.name_as},uid,self`,
            limit: this.limit,
            start: this.start,
          },
        },
      })
    ).then((data) => {
      this.start += this.limit;

      return data;
    });
  }

  /**
   * Retrieves more results from
   * the B/E
   * @return {void}
   *
   */
  showMore() {
    if (this.options.length < this.optionsCount) {
      this.getOptions().then((data) => {
        this.options = this._.uniqBy(
          [...this.options, ...this.filterAllowed(data.objects)],
          "uid"
        );
      });
    }
  }

  /**
   * filterAllowed
   * @description - checks for allowed options in dropdowns
   * @param {array} arr - array of data objects
   * @returns {array} - array of allowed data objects - this is either all or a match against an array of options received from config
   */
  filterAllowed(arr) {
    let filteredList = [];
    if (!this.allowed) {
      filteredList = arr;
    } else {
      filteredList = arr.filter((obj) =>
        findAllAllowedFields(this.allowed, obj[this.config.name_as])
      );
    }

    return filteredList;
  }

  /**
   *
   * @return {boolean}
   */
  isSelectedDefined() {
    if (this._.isArray(this.selected)) {
      return this.selected.length > 0;
    }

    if (this._.isString(this.selected)) {
      return this.selected !== "";
    }

    return false;
  }

  /**
   * Retrieves the selected object from the options
   * by the `self` property
   *
   * @return {Object}
   */
  getCurrentSelectedObject() {
    const deferred = this.$q.defer();

    if (!this.selected) {
      return this.$q.resolve(undefined);
    }

    const selectedOption = this._.find(this.options, { self: this.selected });

    if (this._.isPlainObject(selectedOption)) {
      return this.$q.resolve(selectedOption);
    }

    this.ApiService.get(this.selected).then((data) => {
      const filteredData = this._.pick(data, [
        this.config.name_as,
        "uid",
        "self",
      ]);
      this.options = this._.uniqBy([...this.options, filteredData], "uid");

      deferred.resolve(filteredData);
    });

    return deferred.promise;
  }

  /**
   * Set object saved in entity relationship field
   *
   * @return {void}
   */
  setSelectedObject() {
    this.selected = null;

    const relationshipField = this.config.name;
    const savedSelectedItem = this.entityData[relationshipField];

    if (this._.isArray(savedSelectedItem)) {
      if (savedSelectedItem.length > 1) {
        this.dataIsCorrupted = true;
        return;
      }

      this.selected = savedSelectedItem[0];
    }

    if (this._.isString(savedSelectedItem)) {
      this.selected = savedSelectedItem;
    }

    this.getCurrentSelectedObject().then((selectedOption) => {
      this.getSelectedObjectName(selectedOption);
      this.createUrl(selectedOption);
    });
  }

  /**
   * Saved object in the field from the
   * object structure
   *
   * @return {void}
   */
  getSelectedObjectName(selectedOption) {
    this.selectedOptionName = selectedOption
      ? selectedOption[this.config.name_as]
      : "";
  }

  /**
   * updates the entity data model object
   * based on the item field name
   * this.selected will always be a string
   * so it needs to store it in the object
   * as an array if needed (M:M relationships)
   *
   * @return {void}
   */
  updateEntityDataModel() {
    const { name } = this.item.field;
    let { selected } = this;

    if (name.includes("urls")) {
      selected = selected ? [this.selected] : [];
    }

    this.entityData[name] = selected;

    this.setSelectedObject();
  }

  /**
   * Reset the model
   *
   * @return {void}
   */
  clear() {
    this.selected = null;
  }

  /**
   * Creates the URL for the selected object
   *
   * @return {String|void}
   */
  createUrl(selectedOption) {
    if (!this.selected) {
      return "";
    }

    const name = this.endpoint.split("/")[2];

    const { uid } = selectedOption;
    this.selectedObjectUrl = this.RouteService.buildURL({ name, uid });
  }

  /**
   * Extract the entity from the endpoint
   * eg: /api/submissions/ -> submissions
   * and creates /custom/submissions
   *
   * @return {string}
   */
  createRelationshipEntityUrl() {
    return this.RouteService.buildURL({ name: this.relationshipEntityName });
  }

  /**
   * Creates and append the show-more object
   *
   * @return {void}
   */
  appendShowMoreEl() {
    const html = this.$compile(`
      <div
        ng-if="(widget.options.length !== widget.optionsCount) && widget.resultsCount > 0"
        ng-click="widget.showMore()"
        class="relationship-dropdown__show-more">
        <span>Show More</span>
      </div>`)(this.$scope);

    this.$element.find(".chosen-drop").append(html);
    this.$element
      .find(".chosen-search-input")
      .on("keydown", this.checkResultCount);
  }

  /**
   * Checks the amount of active results after a keydown event.
   * It's debounced like this because this._.debounce is not triggering for some reason.
   *
   * @return {void}
   */
  checkResultCount() {
    const $chosenResults = this.$element.find(".chosen-results");
    this.resultsCount = $chosenResults.find(".active-result").length;

    this.$scope.$apply();
  }
}

const selectRelationshipInput = () => ({
  bindToController: {
    entityData: "<",
    item: "<",
  },
  restrict: "A",
  controller: SelectRelationshipInputCtrl,
  controllerAs: "widget",
});

export default selectRelationshipInput;
