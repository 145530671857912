import { merge } from "lodash";

import { API_FILTERING } from "./api-filtering-and-expanding.constants";

/**
 * _createFiltersFromConfig
 * @todo rectify hardcoded limit
 * @return {Object} params
 */
function _createFiltersFromConfig(config) {
  return Object.keys(API_FILTERING).reduce((acc, field) => {
    if (field in config) {
      return { ...acc, [API_FILTERING[field]]: config[field].join(",") };
    }

    return acc;
  }, {});
}

/**
 * create order param for API request
 * @param   {String} order
 * @returns {Object}
 */
function _createRequestOrder(order) {
  return { order };
}

/**
 * build - build filtering & expanding from config
 * @param  {Object} options
 * @returns {Object}
 */
function build(config = {}) {
  const {
    fields_to_filter: fieldsToFilter,
    fields_to_expand: fieldsToExpand,
    default_order: defaultOrder,
  } = config;

  return merge(
    {},
    fieldsToFilter || fieldsToExpand ? _createFiltersFromConfig(config) : {},
    defaultOrder ? _createRequestOrder(defaultOrder) : {}
  );
}

export { build };
