import template from "./time-indicator.html";

class TimeIndicatorCtrl {
  /**
   * @constructor
   * @param   {Object} $scope
   * @param   {Object} $timeout
   * @param   {Object} $interval
   * @param   {Object} momentJS
   * @param   {Object} CalendarGridService
   * @param   {Object} MessageService
   */
  constructor(
    $scope,
    $timeout,
    $interval,
    momentJS,
    CalendarGridService,
    MessageService
  ) {
    this.$scope = $scope;
    this.$timeout = $timeout;
    this.$interval = $interval;
    this.momentJS = momentJS;
    this.CalendarGridService = CalendarGridService;
    this.MessageService = MessageService;
    this._setupSubscriptions();
  }

  /**
   * init description
   * timeout ensures elements are only appended after DOM is rendered
   * @returns {void}
   */
  init() {
    this.minuteHeight = this.CalendarGridService.getMinuteHeight();
    this.elementHeightOffset = 10;
    this.currentTime = this.momentJS();
    this.currentTimeText = this.momentJS().format("HH:mm");
    this.nextMinute = this.momentJS().endOf("minute");
    this.elementPositionTop = this.CalendarGridService.calculatePosition(
      this.currentTime,
      this.elementHeightOffset
    );
    this._updateOnMinuteChangeWithOffset();

    this.$timeout(() => this._appendElementsToParents());
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.$scope.$on("$destroy", () => {
      this._destroyTimeouts();
    });

    this.MessageService.subscribe("EntityEPG.dateChange", () => {
      this._destroyTimeouts();
      this.init(this.element);
    });
  }

  /**
   * _destroyTimeouts
   * clear timeouts so we don't have these running when they shouldn't
   * @returns {void}
   */
  _destroyTimeouts() {
    this.$interval.cancel(this.minuteChangeInterval);
    this.$timeout.cancel(this.minuteChangeOffsetTimeout);
  }

  /**
   * _appendElementsToParents
   * @description  append the time line parts to their respective containers
   * @returns {void}
   */
  _appendElementsToParents() {
    const today = this.CalendarGridService.extractDay(this.momentJS().format());
    const columnElement = angular.element(`.js-day-${today}`);
    this.timeLabel = angular
      .element(".time-indicator__time-container")
      .detach();
    this.timeLine = angular.element(".time-indicator__line-container").detach();
    if (columnElement.length) {
      angular.element(".calendar-grid__side-header").append(this.timeLabel);
      columnElement.append(this.timeLine);
    }
  }

  /**
   * _updateOnMinuteChangeWithOffset
   * @returns {void}
   */
  _updateOnMinuteChangeWithOffset() {
    const differenceToNextMinute = this.nextMinute.diff(this.currentTime);
    this.minuteChangeOffsetTimeout = this.$timeout(() => {
      this._updateTimeValues();
      this._updateOnMinuteChange();
    }, differenceToNextMinute);
  }

  /**
   * _updateOnMinuteChange
   * @returns {void}
   */
  _updateOnMinuteChange() {
    this.minuteChangeInterval = this.$interval(() => {
      this._updateTimeValues();
    }, 60000);
  }

  /**
   * _updateTimeValues
   * @returns {void}
   */
  _updateTimeValues() {
    this.currentTime.add(1, "minutes");
    this.currentTimeText = this.currentTime.format("HH:mm");
    this.elementPositionTop = this.CalendarGridService.calculatePosition(
      this.currentTime,
      this.elementHeightOffset
    );
    this.MessageService.publish("CalendarGrid.Minute.Update");
  }
}
/**
 * timeIndicator
 * @returns {Object} Directive Definition Object
 */
function timeIndicator() {
  return {
    restrict: "E",
    scope: {},
    template,
    controller: TimeIndicatorCtrl,
    controllerAs: "component",
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default timeIndicator;
