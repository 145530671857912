import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./genres-module.html";

class GenresDirectiveCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $state
   * @param {Object} ApiService
   * @param {Object} MessageService
   * @param {Object} ModulePagingService,
   * @param {Object} NotificationService
   * @param {Object} SchedulesFactory
   * @param {Object} ApiRequestConfigFactory
   * @param {Object} GenresFactory
   * @param {Object} APP_SETTINGS
   * @param {Object} ModalTriggerService
   * @param {Object} ApiFilterFactory
   * @param {Object} $stateParams
   */
  constructor(
    _,
    $state,
    ApiService,
    MessageService,
    ModulePagingService,
    NotificationService,
    SchedulesFactory,
    ApiRequestConfigFactory,
    GenresFactory,
    APP_SETTINGS,
    ModalTriggerService,
    ApiFilterFactory,
    $stateParams
  ) {
    this._ = _;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ApiService = ApiService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.SchedulesFactory = SchedulesFactory;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.GenresFactory = GenresFactory;
    this.ModulePagingService = ModulePagingService;
    this.ModalTriggerService = ModalTriggerService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.APP_SETTINGS = APP_SETTINGS;

    this.type = "genres";
    this.types = undefined;

    this.setSubscriptions();

    this.paginationSettings = {
      start: 0,
      limit: this.config.limit || 10,
    };
  }

  /**
   * bindParams
   * @param {Object} config
   * @param {Object} data
   * @returns {void}
   */
  init() {
    this.existing = [...this.data.genre_urls];
    const genres = this.data.genre_urls.map((genre_url) => ({ genre_url }));
    this.genres = !this.isCreating ? genres : [];
    this.count = this.genres.length;

    this.parentType = this.$stateParams.entity
      ? this.$stateParams.entity
      : this.$state.current.title;

    this.self = this.data.self;
    this.entity = {
      parentId: this.data.uid,
      parentType: this.parentType,
      parentTitle: this.data.title,
    };

    this.genresToDisplay = [];

    this.changePage();
  }

  /**
   * Sets up all the pub sub channels
   * @returns {void}
   */
  setSubscriptions() {
    this.availableChannelNames = {
      addGenre: "Modal.Save.genres.base",
    };

    this.MessageService.registerChannel(this.availableChannelNames.addGenre);
    this.MessageService.on(
      this.availableChannelNames.addGenre,
      (channel, data) => this.addGenre(data)
    );
  }

  /**
   * Angular hook fired when component is destroyed
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.availableChannelNames.addGenre);
  }

  /**
   * Adds a genre
   * @param {Object} data - entity data
   * @returns {void}
   */
  addGenre(data) {
    this.SchedulesFactory.getAlwaysSchedule().then((response) => {
      data.forEach((obj) => {
        const genre = {
          genre_url: obj.self,
          schedule_urls: [response.self],
        };
        this.data.genre_urls.push(genre.genre_url);
        this.genres.push(genre);
        this.existing.push(genre.genre_url);
      });
      const message =
        data.length > 1
          ? "You have successfully added multiple genres."
          : `You have successfully added ${data[0].name}`;
      this.changePage();
      this.save(message);
    });

    this.MessageService.publish(`Pagination.${this.type}`, this.count);
  }

  /**
   *  updates a genre
   *  @param {object} genre in the context of its relationship
   *  @param {string} name for messaging
   *  @returns {void}
   */
  updateGenre(genre, name) {
    const genreInDataGenres = this._.findIndex(
      this.data.genre_urls,
      (url) => url === genre.genre_url
    );
    const genreInGenres = this._.findIndex(
      this.genres,
      (obj) => obj.genre_url === genre.genre_url
    );

    this.data.genre_urls[genreInDataGenres] = genre.genre_url;
    this.genres[genreInGenres] = genre;
    const message = `You have successfully updated ${name}`;
    this.MessageService.publish(
      `ScheduleWidget.Update.${genre.relationshipId}`,
      genre
    );
    this.save(message);
  }

  /**
   * Removes a genre
   * @param {Object} data - genre to remove - full genre object
   * @returns {void}
   */
  removeGenre(data) {
    this._.remove(this.data.genre_urls, (url) => url === data.self);

    this.ApiService.put(
      this.data.self,
      this.data,
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    )
      .then((response) => {
        this.data = angular.extend({}, response);
        this._.remove(this.genres, (obj) => obj.genre_url === data.self);
        this._.remove(this.existing, (url) => url === data.self);
        this.changePage();
        this.NotificationService.notifyInfo(
          NotificationMessage.removeSuccess(data.name)
        );
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.removeError(data.name)
        )
      );
  }

  /**
   * Save the changes
   * @param {string} msg
   * @returns {void}
   */
  save(msg) {
    this.ApiService.put(
      this.data.self,
      this.data,
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    )
      .then((response) => {
        this.data = angular.extend({}, response);
        this.NotificationService.notifyInfo(msg);
        this.changePage();
      })
      .catch(() =>
        this.NotificationService.notifyError(NotificationMessage.saveGenreError)
      );
  }

  /**
   * pagination change
   * @param {Number} start
   * @param {Number} limit
   */
  paginationChange(start, limit) {
    this.paginationSettings.start = start;
    this.paginationSettings.limit = limit;
    this.changePage();
  }

  /**
   * Changes the page in the modal
   * @returns {void}
   */
  changePage() {
    this.count = this.genres.length;
    this.MessageService.publish(`Pagination.${this.type}`, this.count);
    this.genresToDisplay.length = 0;
    this.genresToDisplay = this.genres.length
      ? this.ModulePagingService.urlsToGet(
          this.genres,
          this.paginationSettings.start,
          this.paginationSettings.limit
        )
      : [];
  }

  /**
   * trigger Modal for adding more items
   */
  triggerModal() {
    const options = {
      entity: this.type,
      channels: { add: "Modal.Save.genres.base" },
      parent: this.data,
    };

    this.ApiFilterFactory.getExcludeItemsFilter(
      this.data.self,
      this.existing,
      this.count
    ).then((filters) => {
      options.filters = filters;

      this.ModalTriggerService.triggerList(this.type, options);
    });
  }
}

/**
 * Genres Directive
 * @returns {Object}
 */
function genresDirective() {
  return {
    scope: {},
    bindToController: {
      config: "=",
      data: "<",
      isCreating: "=",
    },
    controller: GenresDirectiveCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default genresDirective;
