import angular from "angular";
import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./document-create.html";

class DocumentCreateController {
  /**
   * create a document
   * will render a form based on the passed in configuration
   * and submit the form to create a new document resource and upload a file to it
   * @param EntityFactory
   * @param MessageService
   * @param NotificationService
   * @param SchedulesFactory
   * @param DocumentFactory
   */
  constructor(
    EntityFactory,
    MessageService,
    NotificationService,
    SchedulesFactory,
    DocumentFactory
  ) {
    this.EntityFactory = EntityFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.SchedulesFactory = SchedulesFactory;
    this.DocumentFactory = DocumentFactory;

    this.data = {};
  }

  /**
   * submit form to create new document resource
   * after complete upload it is calling the onCreation function
   * @param form
   * @param formController
   */
  submit(form, formController) {
    if (!this.isFormValid(form)) {
      return formController.scrollToError(form);
    }

    this.isUploading = true;

    const position =
      this.DocumentFactory.filterByType(
        this.documents,
        this.data.document_type_url
      ).length + 1;
    const data = {
      ...this.data,
      content_url: this.entity,
      position,
    };
    const messageTitle = this.data.title || "an document";
    const errorMessage = NotificationMessage.addError(messageTitle);
    const successMessage = NotificationMessage.addSuccess(messageTitle);

    this.DocumentFactory.createDocumentWithAlwaysSchedule(data)
      .then((data) => {
        this.onCreation({ data });
        this.NotificationService.notifyInfo(successMessage);
      })
      .catch(() => {
        this.NotificationService.notifyError(errorMessage);
      })
      .finally(() => {
        this.clear(form);
      });
  }

  /**
   * Check if form element is valid
   * @param {Object} form
   * @returns {boolean}
   * @private
   */
  isFormValid(form) {
    form.$setSubmitted();

    return form.$valid;
  }

  /**
   * reset form
   * @param form
   */
  clear(form) {
    form.$setPristine();
    form.$setUntouched();
    this.data = {};
    this.isUploading = false;
  }

  onCancel(event, form) {
    event.preventDefault();
    this.clear(form);
  }
}

const documentCreateComponent = {
  bindings: {
    config: "<",
    entity: "<",
    documents: "<",
    onCreation: "&",
  },
  template,
  controller: DocumentCreateController,
  controllerAs: "module",
};

const DocumentCreate = angular
  .module("Skylark.Modules.DocumentCreate", [])
  .component("documentCreate", documentCreateComponent);

export default DocumentCreate;
