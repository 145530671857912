/**
 * @file Service to handle article creation.
 */
class MetadataCreateArticlesService {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} ArticleTypeFactory
   * @param {Object} ConfigurationFactory
   * @param {Object} EntityFactory
   */
  constructor($q, ArticleTypeFactory, ConfigurationFactory, EntityFactory) {
    this.$q = $q;
    this.ArticleTypeFactory = ArticleTypeFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.EntityFactory = EntityFactory;

    this.articleData = {};
  }

  /**
   * Get All Article Statuses
   * @private
   * @returns {Promise}
   */
  _getArticleStatuses() {
    return this.ArticleTypeFactory.getArticleStatus();
  }

  /**
   * Assigns an article publish status
   * @param {object} nullData
   * @private
   * @returns {void}
   */
  _assignStatusUrlField(statuses) {
    this.articleData.status_url = this._assignDraftStatus(statuses);
  }

  /**
   * Assigns the draft status always on creation.
   * @param {object} nullData
   * @private
   * @return {String}
   */
  _assignDraftStatus(statuses) {
    return statuses.find((status) => status.slug === "draft").self;
  }

  /**
   * Creates a new Article
   * @param {Object} data
   * @returns {Promise}
   */
  prepareDataForCreation(data) {
    this.data = { ...data, items: [] };

    return this._getArticleStatuses().then((statuses) => {
      this._assignStatusUrlField(statuses.objects);
      return { ...this.data, ...this.articleData };
    });
  }
}

export default MetadataCreateArticlesService;
