import _ from "lodash";

/**
 * Utilities
 *
 * @returns {Object}
 */
function UtilitiesService($q, $timeout) {
  const service = {};

  /**
   * @param {string} original
   * @returns {*}
   */
  service.capitalize = function (original) {
    return original.replace(/(?:^|\s)\S/g, (s) => s.toUpperCase());
  };

  /**
   * Takes a number and returns a boolean
   * @param {Number} int - Any positive integer
   * @returns {Boolean}
   */
  service.getBooleanfromInt = function (int) {
    return !!(int > 0);
  };

  /**
   * Takes and string or number and returns a boolean
   * @param {String | Number} value - A string value representing the boolean values true or false
   * or a number
   * @returns {Boolean}
   * @remarks returns false if anything other than 'true' is supplied
   */
  service.toBoolean = function (value) {
    if (_.isNumber(value)) {
      return service.getBooleanfromInt(value);
    }
    if (_.isString(value)) {
      if (isNaN(parseInt(value))) {
        // if we're passed 'true' as a string
        const v = value.toString().toLowerCase();
        value = v === "true";
      } else if (_.isNumber(parseInt(value))) {
        return service.getBooleanfromInt(parseInt(value));
      }
    }

    return value;
  };

  /**
   * @returns {void}
   * @param {string} attributePath
   * @param {string} rest
   * @param {string} separator
   * @private
   */
  service._setRestValue = (attributePath, rest, separator) => {
    if (separator === ".") {
      // remaining bit is after the dot
      rest = attributePath.substring(attributePath.indexOf(".") + 1);
    } else if (separator === "[") {
      // remaining bit includes the [
      rest = attributePath.substring(attributePath.indexOf("["));
    }
  };

  /**
   * given a string attribute patch such as 'images[2].image_url', return the
   * matching attribute from obj
   * @param {string} attributePath
   * @param {object} obj
   * @returns {*}
   */
  service.resolveAttribute = function (attributePath, obj) {
    const arrayIndexMatch = attributePath.match(/^\[(\d+)](.*)/);
    let rest = "";
    let resolved;

    if (arrayIndexMatch) {
      const index = parseInt(arrayIndexMatch[1], 10);
      rest = arrayIndexMatch[2];

      resolved = obj[index];
    } else {
      let attribute = attributePath;
      const attributeMatch = attributePath.match(/^(.*?)(\.|\[)/);

      if (attributeMatch) {
        attribute = attributeMatch[1];
        const separator = attributeMatch[2];
        service._setRestValue(attributePath, rest, separator);
      }

      resolved = obj[attribute];
    }

    if (rest) {
      // resolve the next segment
      return this.resolveAttribute(rest, resolved);
    }

    return resolved;
  };

  /**
   * delay with promise
   * waiting the duration and the resolves the promise
   * usage:
   *   UtilitiesService.delay(waitDelay).then(() => console.log('yeah it worked))
   *
   * @param {Number} time in ms
   * @return {Promise}
   */
  service.delayPromise = (time) => {
    const deferred = $q.defer();
    $timeout(deferred.resolve, time);
    return deferred.promise;
  };

  /**
   * checks if one item is in collection
   * @param {Array} superset
   * @param {*|Array} subset
   */
  service.includesOneOf = (superset, subset) => {
    if (!Array.isArray(superset)) {
      return false;
    }

    if (!Array.isArray(subset)) {
      return superset.includes(subset);
    }

    return subset.some((item) => superset.includes(item));
  };

  return service;
}

export default UtilitiesService;
