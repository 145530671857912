import { genKey, EditorState, RichUtils, Modifier } from "draft-js";

import { List, Repeat, Map } from "immutable";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

const types = [
  "header-two",
  "header-three",
  "header-four",
  "header-five",
  "header-six",
  "unstyled",
];
const nestableTypes = ["ordered-list-item", "unordered-list-item"];

/**
 * _setNextStyle
 * @param {Immutable.Record} state
 * @param {String} blockType
 */
function _setNextStyle(state, blockType) {
  if (nestableTypes.includes(blockType) && !DraftHelpers.isBlockEmpty(state)) {
    return nestableTypes[nestableTypes.indexOf(blockType)];
  }

  return "unstyled";
}

export default (editorState, cb, config, text = "") => {
  const currentBlockType = RichUtils.getCurrentBlockType(editorState);

  if (
    types.indexOf(currentBlockType) === -1 &&
    !nestableTypes.indexOf(currentBlockType) === -1 &&
    !DraftHelpers.isMediaBlock(editorState)
  ) {
    return "not-handled";
  }

  let contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const currentBlock = contentState.getBlockForKey(selection.getEndKey());
  const endOffset = selection.getEndOffset();
  const atEnd = endOffset === currentBlock.getLength();
  const atStart = endOffset === 0;

  if (
    (!selection.isCollapsed() || (!atEnd && !atStart)) &&
    !DraftHelpers.isMediaBlock(editorState)
  ) {
    contentState = DraftHelpers.removeRange(
      contentState,
      selection,
      "backward"
    );
    const selectionToSplit = !selection.isCollapsed() ? undefined : selection;
    contentState = DraftHelpers.splitBlock(contentState, selectionToSplit);

    const splitBlockSelection = contentState.getSelectionAfter();

    contentState = Modifier.mergeBlockData(
      contentState,
      splitBlockSelection,
      currentBlock.getData()
    );

    cb(EditorState.push(editorState, contentState, "split-block"));

    return "handled";
  }

  const nextStyle = _setNextStyle(
    editorState,
    RichUtils.getCurrentBlockType(editorState)
  );
  const emptyBlockKey = genKey();

  const newBlockType = nextStyle || "unstyled";
  const newBlockCharList = List(
    Repeat(DraftHelpers.createCharacterMetadata({}), text.length)
  );
  const newBlockData =
    nextStyle === "unstyled" && !text
      ? Map({ ...DraftHelpers.createBlockData(config, config.default_section) })
      : currentBlock.getData();

  const emptyBlock = DraftHelpers.buildContentBlock(
    newBlockType,
    text,
    newBlockData,
    newBlockCharList,
    emptyBlockKey
  );

  const blockMap = contentState.getBlockMap().toSeq();
  const blocksBefore = blockMap.takeUntil((block) => block === currentBlock);
  const blocksAfter = blockMap
    .skipUntil((block) => block === currentBlock)
    .rest();
  const currentBlockKey = currentBlock.getKey();

  const splitContentBlock =
    atEnd || DraftHelpers.isMediaBlock(editorState)
      ? [
          [currentBlockKey, currentBlock],
          [emptyBlockKey, emptyBlock],
        ]
      : [
          [emptyBlockKey, emptyBlock],
          [currentBlockKey, currentBlock],
        ];

  const focusKey =
    atEnd || DraftHelpers.isMediaBlock(editorState)
      ? emptyBlockKey
      : currentBlockKey;

  // Join back together with the current + new block
  const newBlocks = blocksBefore
    .concat(splitContentBlock, blocksAfter)
    .toOrderedMap();

  const newContentState = contentState.merge({
    blockMap: newBlocks,
    selectionBefore: selection,
    selectionAfter: selection.merge({
      anchorKey: focusKey,
      anchorOffset: text.length,
      focusKey,
      focusOffset: text.length,
      isBackward: false,
    }),
  });

  cb(EditorState.push(editorState, newContentState, "split-block"));

  return "handled";
};
