import _ from "lodash";
import TEXT_TRACK_UPLOAD from "./text-track-upload.constant";

const initialState = {};

const TextTrackUploadReducer = (state = initialState, action) => {
  switch (action.type) {
    case TEXT_TRACK_UPLOAD.UPLOAD_STARTED: {
      return {
        ...state,
        [action.data.id]: {
          ...action.data,
          status: action.type,
        },
      };
    }
    case TEXT_TRACK_UPLOAD.TEXT_TRACK_CREATED: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          textTrack: action.data.textTrack,
          // status doesn't need to change, just adding more meta data to the upload process
        },
      };
    }
    case TEXT_TRACK_UPLOAD.TEXT_TRACK_UPDATE: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          textTrack: {
            ...state[action.data.id].textTrack,
            ...action.data.textTrack,
          },
        },
      };
    }
    case TEXT_TRACK_UPLOAD.DOCUMENT_CREATED: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          document: action.data.document,
          status: action.type,
        },
      };
    }
    case TEXT_TRACK_UPLOAD.UPLOAD_PROGRESS: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          progress: action.data.progress,
          status: action.type,
        },
      };
    }
    case TEXT_TRACK_UPLOAD.INGEST_STARTED: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          progress: 1,
          status: action.type,
          hideFromModal: false,
        },
      };
    }
    case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE:
    case TEXT_TRACK_UPLOAD.UPLOAD_FAILED: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          status: action.type,
        },
      };
    }
    case TEXT_TRACK_UPLOAD.REMOVE_UPLOAD_FROM_STORE: {
      if (action.data && action.data.id) {
        return _.omit(state, [action.data.id]);
      }
      return state;
    }
    case TEXT_TRACK_UPLOAD.ADD_UPLOAD: {
      const currentState = state[action.data.id];

      if (!currentState) {
        return {
          ...state,
          [action.data.id]: {
            ...action.data,
            hideFromModal: true,
          },
        };
      }

      return {
        ...state,
        [action.data.id]: {
          ...currentState,
          id: action.data.id,
          entity: { ...currentState.entity, ...action.data.entity },
          textTrack: { ...currentState.textTrack, ...action.data.textTrack },
          document: { ...currentState.document, ...action.data.document },
        },
      };
    }
    case TEXT_TRACK_UPLOAD.HIDE_FROM_MODAL: {
      return {
        ...state,
        [action.data.id]: {
          ...state[action.data.id],
          hideFromModal: true,
        },
      };
    }
    default:
      return state;
  }
};

export default TextTrackUploadReducer;
