/**
 * used to generate headers for api requests
 * @param {object} GlobalParamsService
 * @returns {{}}
 * @constructor
 */
function ApiRequestConfigFactory(GlobalParamsService) {
  const factory = {};

  /**
   * if no language set use 'en' if is global language use that instead, but also check for
   * wild card settings
   * @param {boolean} isWild should use or not use wild card in language
   * @returns {string}
   */
  factory.getLanguage = function (isWild) {
    return isWild
      ? GlobalParamsService.getLanguageWithDefaults()
      : GlobalParamsService.getLanguage();
  };

  factory.createRequestConfig = (options = {}) => {
    const overrideLanguage = options.overrideGlobalLanguage
      ? {
          "Accept-Language": factory.getLanguage(options.useWildCard),
          "Cache-Control": "no-cache",
        }
      : {};
    // language fallback to english
    const userGlobalParams = options.useGlobalParams
      ? GlobalParamsService.getGlobalParams()
      : {};
    // global scheduling params for region, language, etc
    const userDefaultParams = options.useDefaultParams
      ? GlobalParamsService.getDefaultParams()
      : {};
    // all true or current, future, expired
    const userDefinedLanguage = options.language
      ? { "Accept-Language": options.language, "Cache-Control": "no-cache" }
      : {};
    // user defined language override
    const userScheduleParams = options.scheduleParams || {};
    // language, region, schedule filtering for overriding global params
    const userRequestConfig = options.requestConfig || {};
    // http config for angular $http

    const filtersPlusDefaults = angular.merge(
      {},
      {
        headers: overrideLanguage,
      },
      {
        headers: userDefinedLanguage,
        params: userGlobalParams,
      },
      {
        params: userDefaultParams,
      },
      userScheduleParams,
      userRequestConfig
    );

    return filtersPlusDefaults;
  };

  return factory;
}

export default ApiRequestConfigFactory;
