/**
 * @fileOverview Scopeless configurable field that renders a list of schedules,
 * typically inside a modal. Expects to have a parent scope namespaced as module.
 * needs to be called inside an ng-repeat
 */

class CollectionField {
  /**
   * @constructor
   * @param   {Object} $scope
   * @param   {Object} MessageService
   * @param   {Object} BatchRequestFactory
   * @param   {Object} SchedulesFactory
   * @param   {OBject} ConfigurationFactory
   * @param   {Object} _
   */
  constructor(
    $scope,
    MessageService,
    BatchRequestFactory,
    SchedulesFactory,
    ConfigurationFactory,
    _
  ) {
    this.$scope = $scope;
    this.MessageService = MessageService;
    this.BatchRequestFactory = BatchRequestFactory;
    this.SchedulesFactory = SchedulesFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this._ = _;
  }

  /**
   * init()
   * @param   {string} field - api field
   * @returns {void}
   */
  init(field) {
    this.currentField = field;
    this._setupSubscriptions();
    this._loadData(field);
    this._loadConfig();
    this._buildModalOptions();
  }

  /**
   * deleteItem
   * @param   {array} list
   * @param   {Object} item - the item being deleted
   * @returns {void}
   */
  deleteItem(list, item) {
    const selfUrl = item.self;
    const viewIndexToDelete = this._findIndexInList(list, selfUrl);

    this._.remove(this.$scope.component.existing, (url) => url === selfUrl);
    list.splice(viewIndexToDelete, 1);

    this.MessageService.unregisterChannel(
      `CollectionField.EditItem.${item.uid}`
    );

    if (this.$scope.module.data[this.currentField]) {
      this._.remove(
        this.$scope.module.data[this.currentField],
        (url) => url === selfUrl
      );
    }
  }

  /**
   * updateItem
   * @private
   * @param   {Object} data
   * @returns {void}
   */
  _updateItem(data) {
    let scheduleUrls;

    if (this._.isPlainObject(data[0])) {
      scheduleUrls = data.map((item) => item.self);
    } else {
      scheduleUrls = data;
    }

    this.SchedulesFactory.update(data.self, scheduleUrls).then((data) => {
      const index = this._findIndexInList(
        this.$scope.module[this.currentField],
        data.self
      );
      this.$scope.module[this.currentField][index] = data;
      this.MessageService.publish(`ScheduleWidget.Update.${data.uid}`, data);
    });
  }

  /**
   * _addItems
   * @private
   * @param {Array} itemList
   * @returns {void}
   */
  _addItems(itemList) {
    itemList.forEach((item) => {
      this.$scope.module[this.currentField].push(item);
      this.$scope.module.data[this.currentField].push(item.self);
      this.existing.push(item.self);
      this._createEditChannel(item.uid);
    });
  }

  /**
   * _createItem
   * @param   {Object} item
   * @returns {void}
   */
  _createItem(item) {
    this.SchedulesFactory.create(item).then((data) => {
      this._addItems([data]);
    });
  }

  /**
   * setupSubscriptions
   * @private
   * @returns {void}
   */
  _setupSubscriptions() {
    const scheduleChannelName = `Modal.Instance.${this.$scope.module.instanceUid}`;
    const entityId =
      this.$scope.module.data.relationshipId || this.$scope.module.data.uid;

    this.MessageService.subscribe(scheduleChannelName, (ch, data) => {
      this._addItems(data);
    });

    this.MessageService.subscribe(
      `EditableModal.Create.schedules.${entityId}`,
      (channel, data) => {
        this._createItem(data);
      }
    );

    this.$scope.$on("$destroy", () => {
      this.MessageService.unregisterChannel(scheduleChannelName);
      this.MessageService.unregisterChannel(
        `EditableModal.Create.schedules.${entityId}`
      );
      this._destroyEditChannels();
    });
  }

  /**
   * _createEditChannel
   * @private
   * @param   {string} itemUid - string
   * @returns {void}
   */
  _createEditChannel(itemUid) {
    const channelName = `CollectionField.EditItem.${itemUid}`;

    this.modalEditOptions[itemUid] = {
      channels: {
        save: channelName,
      },
      entity: "schedules",
    };

    this.MessageService.subscribe(channelName, (channel, data) => {
      this._updateItem(data);
    });
  }

  /**
   * _destroyEditChannels description
   * @private
   * @returns {void}
   */
  _destroyEditChannels() {
    this.$scope.module[this.currentField].forEach((item) => {
      this.MessageService.unregisterChannel(
        `CollectionField.EditItem.${item.uid}`
      );
    });
  }

  /**
   * loadData
   * @private
   * @param {string} field - the field name
   * @returns {void}
   */
  _loadData(field) {
    if (
      this._.isPlainObject(
        this.$scope.module.data[field] && this.$scope.module.data[field][0]
      )
    ) {
      this.$scope.module[field] = this.$scope.module.data[field];
      this.$scope.module.data[field] = this.$scope.module.data[field].map(
        (schedule) => schedule.self
      );
      this._buildModalEditOptions(this.$scope.module[field]);
    } else {
      this.BatchRequestFactory.createGetRequests(
        field,
        this.$scope.module.data[field]
      );
      this.BatchRequestFactory.process().then((data) => {
        this.$scope.module[field] = data.map((item) => JSON.parse(item.body));
        this._buildModalEditOptions(this.$scope.module[field]);
      });
    }
  }

  /**
   * _loadConfig
   * @private
   * @returns {void}
   */
  _loadConfig() {
    this.ConfigurationFactory.getEntityBaseConfiguration("schedules").then(
      (config) => {
        this.subModalConfig = config;
      }
    );
  }

  /**
   * _findIndexInList
   * @private
   * @param   {array} list
   * @param   {string} url
   * @returns {number} index
   */
  _findIndexInList(list, url) {
    return list.findIndex((item) => item.self === url);
  }

  /**
   * buildModalOptions
   * @private
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      filters: { rights: false },
      existing:
        this.existing.length && this.existing[0].self
          ? this.existing.map((item) => item.self)
          : this.existing,
      categories: null,
      field: this.currentField,
      parent: this.$scope.module.parentData,
    };
  }

  /**
   * _buildModalEditOptions description
   * @private
   * @param   {array} list - list of items to register
   * @returns {void}
   */
  _buildModalEditOptions(list) {
    this.modalEditOptions = {};
    list.forEach((item) => this._createEditChannel(item.uid));
  }
}

/**
 * collectionFieldDirective
 * @returns {Object} Directive Definition Object
 */
function collectionFieldDirective() {
  return {
    restrict: "A",
    controller: CollectionField,
    controllerAs: "component",
    link: (scope, element, attrs) => {
      scope.component.currentField = attrs.field || [];
      scope.component.existing = scope.module.data[attrs.field]
        ? [].concat(scope.module.data[attrs.field])
        : [];

      scope.component.init(attrs.field);
    },
  };
}

export default collectionFieldDirective;
