import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./version.html";

class VersionsDirectiveCtrl {
  /**
   * @constructor
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} VersionsFactory
   * @param {Object} _
   */
  constructor(MessageService, NotificationService, VersionsFactory, _) {
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.VersionsFactory = VersionsFactory;
    this._ = _;
  }

  /**
   * Initalises the functions
   * @returns {void}
   */
  init() {
    this.VersionsFactory.getAccountData(this.data.account_url).then(
      (accountData) => {
        this._accountKeys = Object.keys(accountData);
        this._extendModuleData(accountData);
      }
    );
  }

  /**
   * Sets up initial values which are used for replacing data on cancel.
   * @returns {void}
   * @private
   */
  _setInitialValues() {
    this._initialData = { ...this.data };
    this._initialEditableFields = this.editableFields.slice(0);
  }

  /**
   * Updates the data that is available for the view.
   * @param {Object} accountData
   * @returns {void}
   * @private
   */
  _updateViewData(accountData) {
    this.data = { ...this.parentData.ovps[this.index] };
    this.data = Object.assign(this.data, accountData);
  }

  /**
   * Sorts data for displaying in the view
   * @returns {void}
   * @private
   */
  _createViewData() {
    this.displayName = this.data.name;
    this.readOnlyFields = this.config.read_only_fields.filter(
      (field) => !!this.data[field.name]
    );
    // Hide fields that don't exist in the object (unless they have "always_show" as true in cms-config) - added for Atas Bitmovin/Brightcove account
    this.editableFields = this.config.editable_fields.filter(
      (field) =>
        !!this.data[field.name] ||
        (field.slug && field.slug === this.data.provider_slug) ||
        field.always_show
    );
  }

  /**
   * Extends data with additional properties
   * @param {Object} data - properties to add to the main data
   * @returns {void}
   * @private
   */
  _extendModuleData(data) {
    this.data = { ...this.data, ...data };
    this._createViewData();
    this._setInitialValues();
  }

  /**
   * Updates both parent data and the account details
   * @returns {void}
   * @private
   */
  _updateData() {
    this._updateParentData();

    // Default shouldUpdateAccount to true when "updateAccountOnSave" does not exist in CMS Config (backwards compatibility)
    // Added as ATAS Bitmovin/Brightcove account did not have a provider_id causing the account update to fail
    // Additionally, no one could think of a reason to update the account
    const shouldUpdateAccount = this._.has(this.config, "updateAccountOnSave")
      ? this.config.updateAccountOnSave
      : true;
    const accountFieldsToUpdate = shouldUpdateAccount
      ? this._accountFieldsToUpdate()
      : null;

    this.VersionsFactory.update(this.parentData, accountFieldsToUpdate)
      .then((response) => {
        this.parentData = response[0];
        this._updateViewData(response[1]);

        this.NotificationService.notifyInfo(
          NotificationMessage.updateSuccess(this.displayName)
        );
      })
      .catch(() => {
        this.NotificationService.notifyError(
          NotificationMessage.updateError(this.displayName)
        );
      });
  }

  /**
   * Updates the ovp fields on the parentEntity
   * @returns {void}
   * @private
   */
  _updateParentData() {
    this.parentData.ovps[this.index] = Object.assign(
      this.parentData.ovps[this.index],
      this._ovpsFieldsToUpdate()
    );
  }

  /**
   * Removes expanded fields from the data object so that only fields exisiting on the parent
   * data remain
   * @private
   * @returns {void}
   */
  _ovpsFieldsToUpdate() {
    return this._.omit(this.data, this._accountKeys);
  }

  /**
   * Picks the fields that are relating to account item and saves an updated version of
   * the account
   * @returns {void}
   * @private
   */
  _accountFieldsToUpdate() {
    return this._.pick(this.data, this._accountKeys);
  }

  /**
   * Public method called to reset the data
   * @returns {void}
   */
  cancel() {
    this.data = angular.copy(this._initialData);
    this.editableFields = angular.copy(this._initialEditableFields);

    this.MessageService.publish(`form.toggleEdit.Version${this.index}`);
  }

  /**
   * Public method called to save the data that had been changed in the view
   * @returns {void}
   */
  save() {
    this._setInitialValues();

    this._updateData();
    this.MessageService.publish(`form.toggleEdit.Version${this.index}`);
  }
}

/**
 * Versions directive
 * @returns {Object} directive
 */
function versionsDirective() {
  return {
    scope: {},
    bindToController: {
      config: "=",
      parentData: "=",
      data: "=",
      index: "=",
    },
    restrict: "E",
    controller: VersionsDirectiveCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default versionsDirective;
