import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./relationship-module.html";

class RelationshipDirectiveCtrl {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} $scope
   * @param {Object} ConfigurationFactory
   * @param {Object} LoadingService
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} EntityVersionsService
   * @param {Object} EntityFactory
   * @param {Object} RelationshipFactory
   * @param {Object} OVP_ASSET_UPLOAD
   * @param {Object} ModalTriggerService
   * @param {Object} ApiFilterFactory
   * @param {Object} _
   */
  constructor(
    $q,
    $scope,
    ConfigurationFactory,
    LoadingService,
    MessageService,
    NotificationService,
    EntityVersionsService,
    EntityFactory,
    RelationshipFactory,
    OVP_ASSET_UPLOAD,
    ModalTriggerService,
    ApiFilterFactory,
    _
  ) {
    this.$q = $q;
    this.$scope = $scope;
    this.ConfigurationFactory = ConfigurationFactory;
    this.EntityFactory = EntityFactory;
    this.LoadingService = LoadingService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.EntityVersionsService = EntityVersionsService;
    this.RelationshipFactory = RelationshipFactory;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;
    this.ModalTriggerService = ModalTriggerService;
    this.ApiFilterFactory = ApiFilterFactory;
    this._ = _;

    this.init();
  }

  /**
   * Initialise values
   * @access private
   * @returns {void}
   */
  init() {
    this.relationshipName = this.config.type
      ? this.config.type
      : this.config.name;
    this.itemRelationshipField = this.config.relationship_field;
    this.relatedEntities = [];
    this.limit = this.config.limit || 10;
    this.currentPaginationSettings = {
      start: 0,
      limit: this.limit,
    };
    this.ovpAssetType = "";

    this.bindParentData();
    this.updateRelatedEntities();
    this.setSubscriptions();
    this._loadAssetTypeConfig();
  }

  /**
   * Binds parent data for use in the view. Used for modal button.
   * @access private
   * @returns {void}
   */
  bindParentData() {
    this.parentType = this.EntityFactory.getEntityName();

    this.entity = {
      parentId: this.data.uid,
      parentType: this.parentType,
      parentTitle: this.data.title,
    };
  }

  /**
   * Add pub/sub subscriptions
   * @access private
   * @returns {void}
   */
  setSubscriptions() {
    const availableChannelNames = {
      remove: `delete.${this.type}.relationship`,
      add: `Modal.Save.${this.type}.base`,
    };

    this.MessageService.subscribe(
      availableChannelNames.add,
      (channel, data) => {
        this.addRelationships(data);
        this._setUploadIngestStatus(false);
      }
    );

    this.MessageService.subscribe(
      availableChannelNames.remove,
      (channel, data) => {
        this.removeRelationship(data);
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      (channel, { entityId }) => {
        if (this._isMessageForThisEntity(entityId)) {
          this._setUploadIngestStatus(true);
        }
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
      (channel, { entityId }) => {
        if (this._isMessageForThisEntity(entityId)) {
          this._setUploadIngestStatus(true);
        }
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
      (channel, { entityId }) => {
        if (this._isMessageForThisEntity(entityId)) {
          this._setUploadIngestStatus(false);
        }
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
      (channel, { entityId }) => {
        if (this._isMessageForThisEntity(entityId)) {
          this._deleteAssetOnCancelOrFail();
        }
      }
    );

    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
      (channel, { entityId }) => {
        if (this._isMessageForThisEntity(entityId)) {
          this._deleteAssetOnCancelOrFail();
        }
      }
    );

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions(availableChannelNames);
    });
  }

  /**
   * Remove pub/sub subscriptions on destroy
   * @access private
   * @param {object} availableChannelNames
   * @returns {void}
   */
  deregisterSubscriptions(availableChannelNames) {
    this.MessageService.unregisterChannel(availableChannelNames.add);
    this.MessageService.unregisterChannel(availableChannelNames.remove);
  }

  /**
   * on pagination change
   * @param {Number} start
   * @param {Number} limit
   */
  onPaginationChange(start, limit) {
    this.currentPaginationSettings = { start, limit };
    this.updateRelatedEntities();
  }

  /**
   * Creates link between both entities if it does ot already exist. Handles for both arrays
   * and strings depending on the field type
   * @access private
   * @param {object} relatedEntity - child entity (the entity that isn't the current page)
   * @returns {void}
   */
  linkEntities(relatedEntity) {
    const existingRelationships = this.data[this.itemRelationshipField];

    if (this.config.content_tab) {
      relatedEntity.parent_url = this.data.self;
    }

    if (this.hasMultipleRelationships(existingRelationships)) {
      if (existingRelationships.indexOf(relatedEntity.self) === -1) {
        existingRelationships.push(relatedEntity.self);
      }
    } else {
      this.data[this.itemRelationshipField] = relatedEntity.self;
    }
  }

  /**
   * Checks if a relationship can have multiple entities attached to it. API accepts string for
   * singular relationships and array for multiple
   * @access private
   * @param {string | array} relatedEntities
   * @returns {boolean}
   */
  hasMultipleRelationships(relatedEntities) {
    return this._.isArray(relatedEntities);
  }

  /**
   * Add a relationship to the parent entity
   * @access private
   * @param {Array} relatedEntities - the entities to add as a relationship
   * @returns {void}
   */
  addRelationships(relatedEntities) {
    relatedEntities.forEach((relatedEntity) => {
      this.linkEntities(relatedEntity);
    });

    const message =
      relatedEntities.length > 1
        ? `You have successfully added multiple ${this.type} relationships.`
        : `You have successfully added a ${this.type} relationship.`;

    this.saveChanges(message, relatedEntities);
  }

  /**
   * Fetch the ovp_asset_type from the config
   * for the current asset type
   *
   * @returns {void}
   *
   */
  _loadAssetTypeConfig() {
    if (this.config.name === "assets") {
      this.ConfigurationFactory.getBaseConfiguration(
        this.config.name,
        this.config.type
      ).then((config) => {
        this.ovpAssetType =
          config.ovp_asset_type || this.OVP_ASSET_UPLOAD.DEFAULT_OVP_ASSET_TYPE;
      });
    }
  }

  /**
   * The upload begins only after the asset has been
   * created. So we check if the asset exists before
   * actually disable the dropdown
   *
   * @param {boolean} status
   * @returns {void}
   */
  _setUploadIngestStatus(status) {
    this.isUploadingOrIngesting = status;
  }

  /**
   * We need to delete the created asset
   * if the upload is cancelled or fails
   * @returns {void}
   */
  _deleteAssetOnCancelOrFail() {
    if (!this.isDeletingAsset) {
      this._setUploadIngestStatus(false);

      this.EntityFactory.delete("assets", this.createdAsset.uid).finally(() => {
        this.isDeletingAsset = false;
      });

      this.isDeletingAsset = true;
    }
  }

  /**
   * We need to make sure that the message
   * returned from the publish belongs to
   * this specific component and asset
   * otherwise it will trigger for every
   * component that subscribes to the event
   * @returns {Boolean}
   */
  _isMessageForThisEntity(entityId) {
    return this.createdAsset && this.createdAsset.uid === entityId;
  }

  /**
   * Check to see if the item to be removed
   * was the last item on the page.
   * Item must be last on page and there
   * must be more than 1 page
   * @access private
   * @returns {boolean}
   */
  _itemWasLastOnPage(numberOfPages) {
    return numberOfPages > 1 && !this.relatedEntities.length;
  }

  /**
   * Return the current number of pages
   * @access private
   * @return {integer}
   */
  _getTotalNumberOfPages() {
    return (
      this.data[this.itemRelationshipField] &&
      Math.ceil(this.count / this.currentPaginationSettings.limit)
    );
  }

  /**
   * Decrement start value by limit value
   * and move back to previous page
   * @access private
   * @return {void}
   */
  _moveBackPage() {
    this.currentPaginationSettings.start -=
      this.currentPaginationSettings.limit;
  }

  /**
   * Remove deleted item from data
   * @param {Object} item
   * @access private
   */
  _removeItemFromData(item) {
    if (this.hasMultipleRelationships(this.data[this.itemRelationshipField])) {
      this._.remove(
        this.data[this.itemRelationshipField],
        (url) => url === item.self
      );
    } else {
      this.data[this.itemRelationshipField] = null;
    }

    this._.remove(this.relatedEntities, (entity) => entity.self === item.self);
  }

  /**
   * Removes a relationship from the page
   * @access private
   * @param {Object} item - the item to be removed
   * @returns {void}
   */
  removeRelationship(item) {
    const numberOfPages = this._getTotalNumberOfPages();

    if (this.config.content_tab) {
      item.parent_url = null;
    }

    this._removeItemFromData(item);

    if (this._itemWasLastOnPage(numberOfPages)) {
      this._moveBackPage();
    }

    this.saveChanges(`You have successfully removed ${item.displayName}`, [
      item,
    ]);
  }

  /**
   * Saves changes to the base entity. Api links the child entities to this one
   * For the Relationship tab we send just the `self` and the relationshipField
   * to actually patch
   * @access private
   * @param {string} successMessage - the successMessage to display on completion
   * @param {array} items
   */
  saveChanges(successMessage, items) {
    let saveRelationshipRequest;

    if (this.config.content_tab) {
      saveRelationshipRequest = this.RelationshipFactory.saveItems(items);
    } else {
      const item = {
        self: this.data.self,
        [this.itemRelationshipField]: this.data[this.itemRelationshipField],
      };
      saveRelationshipRequest = this.RelationshipFactory.save(item);
    }

    saveRelationshipRequest
      .then((response) => {
        if (!Array.isArray(response)) {
          this.updateParentData(response);
        }
        this.updateRelatedEntities();
        this.NotificationService.notifyInfo(successMessage);
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.addError("items")
        )
      );
  }

  /**
   * Update parent data.
   * @param {object} response - updated data response from the api
   * @returns {void}
   */
  updateParentData(response) {
    this.data = { ...response };
  }

  /**
   * The amount of items that are already in the field
   * @access private
   * @returns {Number}
   */
  numberOfAvailableItems() {
    if (this.hasMultipleRelationships(this.data[this.itemRelationshipField])) {
      return this.data[this.itemRelationshipField].length;
    }

    return this.data[this.itemRelationshipField] ? 1 : 0;
  }

  /**
   * Update page data
   * Keeps the page and modules in sync required for page ui
   * @access private
   * @returns {void}
   */
  updatePage() {
    if (!this.config.content_tab) {
      this.MessageService.publish("relationshipPage.update", {
        relationshipName: this.type,
        isRequired: this.config.required,
        numberOfAvailableItems: this.numberOfAvailableItems(),
      });
    }
  }

  /**
   * Handles the response from getRelatedEntities. Parses the new entities for display and
   * updates
   * the ui
   *
   * Reset the relatedEntities again so that if multiple requests resolve we only display the
   * last one (the page the user is then on)
   *
   * @access private
   * @param {Array} relatedEntities
   * @returns {void}
   */
  handleRelatedEntities(relatedEntities) {
    this.relatedEntities.length = 0;
    this.relatedEntities = this.relatedEntities.concat(relatedEntities);
    this.LoadingService.moduleLoading(false);

    if (this.config.content_tab) {
      this.MessageService.publish("ContentTab.update", {
        field: this.itemRelationshipField,
        items: this.relatedEntities,
      });
    }
  }

  /**
   * Updates the related entities array based on changes in data.
   *
   * @access private
   * @returns {void}
   */
  updateRelatedEntities() {
    const entityUrls = this.data[this.itemRelationshipField];

    this.updatePage();
    this.relatedEntities.length = 0;

    if (entityUrls && entityUrls.length) {
      this.LoadingService.moduleLoading(true);

      const entityUrlsToGet = this.hasMultipleRelationships(entityUrls)
        ? entityUrls
        : [entityUrls];

      this.setCount(entityUrlsToGet);
    } else {
      this.count = 0;
    }
  }

  /**
   * When we are in the content tab and the current asset has type (eg. main
   * assets) then we have to make a call in order to get the total count and
   * then we update the related entities.
   * @returns {void}
   */
  setCount(entityUrlsToGet) {
    if (this.config.content_tab) {
      this.RelationshipFactory.getCountOfRelatedEntitiesByParent(
        this.config.name,
        this.data.self,
        this.config
      ).then((response) => {
        this.count = response.count;
        this.updatePaginationAndEntities(entityUrlsToGet);
      });
    } else {
      this.count = this._filterUrlsByType(entityUrlsToGet).length;
      this.updatePaginationAndEntities(entityUrlsToGet);
    }
  }

  /**
   * @param {array} entityUrlsToGet
   * @returns {void}
   */
  updatePaginationAndEntities(entityUrlsToGet) {
    this.MessageService.publish(`Pagination.${this.type}.module`, this.count);

    if (entityUrlsToGet.length) {
      this.getEntities(entityUrlsToGet);
    }
  }

  /**
   * Makes a request to get entities related to this module
   * @param {array} entityUrlsToGet
   * @returns {void}
   */
  getEntities(entityUrlsToGet) {
    let getEntitiesRequest;
    const itemName = this.config.name || "items";

    if (this.config.content_tab) {
      getEntitiesRequest = this.RelationshipFactory.getRelatedEntitiesByParent(
        this.config.name,
        this.data.self,
        this.currentPaginationSettings,
        this.config
      ).then((relatedEntities) =>
        this.handleRelatedEntities(relatedEntities.objects)
      );
    } else {
      getEntitiesRequest = this.RelationshipFactory.getRelatedEntities(
        this._filterUrlsByType(entityUrlsToGet),
        this.currentPaginationSettings
      ).then((relatedEntities) => this.handleRelatedEntities(relatedEntities));
    }

    getEntitiesRequest.catch(() =>
      this.NotificationService.notifyError(
        NotificationMessage.addError(itemName)
      )
    );
  }

  /**
   * Set the visibility of the upload box
   *
   * @param {Boolean} isVisible
   * @returns {void}
   */
  setVisibilityUploadBox(isVisible) {
    this.isUploadBoxVisible = isVisible;
  }

  /**
   * Function to be ran before the upload
   * it takes the filename as param that'll
   * be used as title for the asset to be created
   * It also sends parentUrl in order to link
   * the new asset to the episode
   *
   * The promise returns an object to be used
   * in the upload function
   *
   * @params {String} filename
   * @returns {Promise}
   */
  onBeforeUpload(filename) {
    if (this.assetTypes && !this.assetTypeUrl) {
      this._setAssetType();
    }

    const data = {
      title: filename,
      asset_type_url: this.assetTypeUrl?.self,
      parent_url: this.data.self,
    };

    return this.EntityFactory.create("assets", data).then((data) => {
      this.createdAsset = data;

      return {
        entityId: data.uid,
      };
    });
  }

  /**
   *
   * Adds the asset as relationship
   * and sets the visibility
   * of the upload box to false
   *
   * @returns {Promise}
   */
  onUploadComplete() {
    return this.EntityVersionsService.refreshOvpsAndImages(
      "assets",
      this.createdAsset.uid
    ).then((entity) => {
      this.createdAsset.ovps = entity.ovps;
      this.createdAsset.image_urls = entity.image_urls;

      this.setVisibilityUploadBox(false);
      this.addRelationships([this.createdAsset]);

      this.createdAsset = null;
    });
  }

  /**
   * deletes the version from the asset
   * and updates the entity
   * @param {object} item - asset data
   * @param {object} version - one object of ovps
   * @return {Promise}
   */
  onDeleteVersion(item, version) {
    return this.EntityVersionsService.deleteVersion(
      item.uid,
      item.ovps,
      version
    )
      .then(() => {
        this.updateRelatedEntities();
        this.NotificationService.notifyInfo(
          NotificationMessage.removeFileSuccess
        );

        return this.$q.resolve();
      })
      .catch((error) => {
        this.NotificationService.notifyError(
          NotificationMessage.removeFileError
        );

        return this.$q.reject(error);
      });
  }

  /**
   *
   * @return {Object|undefined} version
   *
   */
  getVersionByAccount(ovps, account) {
    return this.EntityVersionsService.getVersionByAccount({ ovps }, account);
  }

  /**
   * Checks if the item has an asset that was
   * uploaded by an account with direct upload
   * in order to enable the video preview
   *
   * @param {object} item
   * @returns {boolean}
   *
   */
  itemHasPreview(item) {
    const accountsWithUpload = this._.get(this.versions, "accountsWithUpload");

    if (!accountsWithUpload || !item.ovps) {
      return false;
    }

    return accountsWithUpload.some((account) =>
      this.EntityVersionsService.getVersionByAccount(
        { ovps: item.ovps },
        account
      )
    );
  }

  /**
   * Finds the asset type config
   * from the B/E of the current asset
   * @returns {void}
   */
  _setAssetType() {
    this.assetTypeUrl = this.assetTypes.find(
      (assetType) => assetType.slug === this.config.type
    );
  }

  /**
   * _filterEntitiesByType
   * @param   {Array} urls
   * @returns {Array} filtered list of urls
   */
  _filterUrlsByType(urls) {
    if (this.config.restrict_to) {
      return urls.filter((url) => url.indexOf(this.config.restrict_to) > -1);
    }

    return urls;
  }

  /**
   * trigger the modal to add more items
   * before it is opened some additional filters are added
   * @returns {void}
   */
  triggerModal() {
    const entityType = this.relationshipName;
    const options = {
      entity: this.type,
      channels: {
        add: `Modal.Save.${this.type}.base`,
      },
      parent: {},
    };

    this._buildModalFilters().then((filters) => {
      options.filters = filters;

      this.ModalTriggerService.triggerList(entityType, options);
    });
  }

  /**
   * build modal filters if we need to restrict the listing
   * based on an entity type (like set type)
   */
  _buildModalFilters() {
    const asyncFilters = [];
    const reverseRelationshipName = this.config.content_tab
      ? "parent_url"
      : null;
    const tabsParam =
      this.config.filters &&
      this.config.filters.tabs &&
      this.config.filters.tabs.param;

    if (tabsParam) {
      asyncFilters.push(
        this.ApiFilterFactory.getRestrictionTabsParamFilter(
          this.config.type,
          tabsParam
        )
      );
    } else {
      asyncFilters.push(
        this.ApiFilterFactory.getRestrictionFilter(
          this.config.restrict_to,
          this.config.restrict_to_type
        )
      );
    }

    asyncFilters.push(
      this.ApiFilterFactory.getExcludeItemsFilter(
        this.data.self,
        this.relatedEntities,
        this.count,
        reverseRelationshipName
      )
    );

    return this.$q
      .all(asyncFilters)
      .then((filters) => Object.assign({}, ...filters));
  }

  /**
   * Returns the account options from the config given the accountName
   * @param {*} accountName
   * @returns object
   */
  getAccountConfigOptions(accountName) {
    if (
      this.versions &&
      this.versions.accountsWithDirectUploadOptions &&
      this.versions.accountsWithDirectUploadOptions.length > 0
    ) {
      const options = this.versions.accountsWithDirectUploadOptions;
      const found = options.find((opt) => accountName === opt.account);
      return found;
    }
  }

  /**
   * Returns whether the account supports upload via URL, defaults to true if a setting isn't found
   * @param {*} accountName string
   * @returns boolean
   */
  hasUploadViaUrl(accountName) {
    const options = this.getAccountConfigOptions(accountName);
    return options &&
      Object.prototype.hasOwnProperty.call(options, "uploadViaUrl")
      ? options.uploadViaUrl
      : true;
  }
}

/**
 * Relationship directive
 * @returns {Object} - the directive
 */
function relationshipDirective() {
  return {
    scope: {},
    bindToController: {
      config: "=",
      data: "=",
      type: "@",
      title: "@",
      versions: "<",
      assetTypes: "<",
    },
    restrict: "A",
    controller: RelationshipDirectiveCtrl,
    controllerAs: "module",
    template,
  };
}

export default relationshipDirective;
