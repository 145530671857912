import NotificationMessage from "skylarklib/constants/notification-text";
import * as EntityAction from "store/entity/entity.action";
import template from "./ingest-id-banner.html";

/**
 * Created by danielbanting on 19/06/2016.
 * @fileOverview ingestIdBanner renders language_ingest_date only
 */
class IngestIdBannerController {
  /**
   * @constructor
   * @param {Object} EntityFactory
   * @param {Object} MessageService
   * @param {Object} NotificationService
   */
  constructor(
    EntityFactory,
    MessageService,
    NotificationService,
    ReduxConnector
  ) {
    this.EntityFactory = EntityFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ReduxConnector = ReduxConnector;

    this.connectToStore();
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this._buildModalConfig();
    this._buildModalOptions();
    this._setupSubscriptions();
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this.modalOptions.channels.save,
      (channel, data) => {
        this._saveDataSource(data);
      }
    );
  }

  /**
   * _buildModalConfig
   * @returns {void}
   */
  _buildModalConfig() {
    this.INGEST_OPTIONS = [
      {
        name: "data_source_id",
        display_name: "Data source id",
        widget: "textfield",
      },
    ];
  }

  /**
   * _buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      title: "Data source options",
      channels: {
        save: `Modal.DataSource.${this.data.uid}.save`,
      },
    };
  }

  /**
   * _savePermissions - updates data source id in the API on publish from modal
   * when removing data source id then the fields need to be set to null
   * @param {Object} data edited in modal
   * @returns {void}
   */
  _saveDataSource(data) {
    this.data.data_source_id = data.data_source_id;

    const updatedData = {
      self: this.data.self,
      data_source_id: this.data.data_source_id,
    };

    if (!updatedData.data_source_id) {
      updatedData.data_source_fields = null;
    }

    this.store.updateEntity(
      this.data.self,
      updatedData,
      null,
      NotificationMessage.updateDataSourceIdSuccess,
      NotificationMessage.updateDataSourceIdError
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };

    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  $onDestroy() {
    this.disconnect();
  }
}

/**
 * ingestIdBannerDirective
 * @returns {Object} Directive Definition Object
 * /**
 */
function ingestIdBannerDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      data: "=",
      entityName: "=",
    },
    controller: IngestIdBannerController,
    controllerAs: "component",
    template,
    link: (scope) => scope.component.init(),
  };
}

export default ingestIdBannerDirective;
