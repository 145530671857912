const namespace = "config/";

export const NONE_TYPE = "noneType";
export const NAMESPACE = namespace;
export const RESET_ENTITIES_CONFIG = "RESET_ENTITIES_CONFIG";
export const RESET_ENTITY_CONFIG = `${namespace}RESET_ENTITY_CONFIG`;
export const FETCH_ENTITY_CONFIG_BASE = `${namespace}FETCH_ENTITY_CONFIG_BASE`;
export const FETCH_ENTITY_CONFIG_INFORMATION = `${namespace}FETCH_ENTITY_CONFIG_INFORMATION`;
export const FETCH_ENTITY_CONFIG_VERSION = `${namespace}FETCH_ENTITY_CONFIG_VERSION`;
export const FETCH_ENTITY_CONFIG_RELATIONSHIP = `${namespace}FETCH_ENTITY_CONFIG_RELATIONSHIP`;
export const FETCH_ENTITY_CONFIG_EDITOR = `${namespace}FETCH_ENTITY_CONFIG_EDITOR`;
export const IGNORE_THIS_TYPE = "@@INIT";
