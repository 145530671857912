import { applyMiddleware, createStore } from "redux";
import ReduxThunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import ApiMiddleware from "./api/api.middleware";
import NotificationMiddleware from "./notification/notification.middleware";
import RoutingMiddleware from "./routing/routing.middleware";

import RootReducer from "./root-reducer";

const middleware = [
  ReduxThunk,
  ApiMiddleware,
  NotificationMiddleware,
  RoutingMiddleware,
];

const composeEnhancers = composeWithDevTools({ trace: true });

const Store = createStore(
  RootReducer,
  undefined,
  composeEnhancers(applyMiddleware(...middleware))
);

export default Store;
