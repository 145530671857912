function SetTypesFactory($q, ApiService) {
  const factory = {};

  /**
   * getSetTypes - Fetches set types from Skylark API
   *
   * @return {Promise} - deferred promise
   *
   */
  factory.getSetTypes = () => ApiService.get("/api/set-types/");

  return factory;
}

export default SetTypesFactory;
