import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./list-headings.scss";

/**
 * ListHeadings
 * @param {Object} props
 */
export default function ListHeadings(props) {
  return (
    <div
      className={classnames(
        styles["list-headings"],
        styles[`list-headings--${props.context}`],
        props.classes
      )}
    >
      <div className={styles["list-headings__inner"]}>
        {props.columns.map((column, index) => (
          <div
            key={index}
            className={classnames(
              styles["list-headings__column"],
              styles[`grid-column-${column.size}`]
            )}
          >
            <div className={styles["list-headings__name"]}>
              <div className={styles["list-headings__name-inner"]}>
                {column.display_name}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

ListHeadings.propTypes = {
  columns: PropTypes.arrayOf(Object).isRequired,
  context: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

ListHeadings.defaultProps = {
  classes: undefined,
};
