import React, { Component } from "react";
import PropTypes from "prop-types";
import { RichUtils, EditorState } from "draft-js";
import classnames from "classnames";
import { find } from "lodash";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";
import * as TextPlaceholders from "components/_react/editor/helpers/text-placeholders/text-placeholders";
import * as LinkHelpers from "components/_react/editor/helpers/link-helpers/link-helpers";

import {
  DropdownWrapper,
  DropdownEditorControls,
  DropdownArticleObjects,
} from "components/_react/fields/dropdowns/dropdowns";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import editorControlsMap from "./editor-controls.constants";

import styles from "./editor-controls.scss";

const DropdownBlockStyles = DropdownWrapper(DropdownEditorControls);

/**
 * Editor Controls
 * @param {Object} props
 * @returns {JSX}
 */
export default class EditorControls extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    handleChange: PropTypes.func.isRequired,
    editor: PropTypes.shape({
      editorState: PropTypes.instanceOf(EditorState),
    }).isRequired,
    isFixed: PropTypes.bool.isRequired,
    renderMediaBlock: PropTypes.func.isRequired,
    promptForLink: PropTypes.func.isRequired,
    removeLink: PropTypes.func.isRequired,
    configuration: PropTypes.shape({
      controls: PropTypes.shape({
        disallowed: PropTypes.array,
      }),
    }).isRequired,
    onModalSave: PropTypes.func,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    currentBlockKey: "",
    currentType: "",
    currentStyle: null,
    blockType: null,
  };

  /**
   * @constuctor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.selectBlockStyle = this.selectBlockStyle.bind(this);

    this.state = {
      configuredOptions: [],
    };

    this.isLinkPromptVisible = false;
    this.allowedControls = this._filterDisallowedControls();
  }

  /**
   * componentWillMount lifecycle hook
   */
  componentWillMount() {
    this.props.handleChange(this.props.editor.editorState);
    this._configureDropdownOptions(this.props);
    this.disableBlockStyles(this.props);
  }

  /**
   * componentWillReceiveProps lifecycle hook
   */
  componentWillReceiveProps(nextProps) {
    this._setCurrents(nextProps.editor.editorState);

    if (DraftHelpers.getCurrentBlock(nextProps.editor.editorState)) {
      this._removePlaceholderStyles(nextProps);
      this._configureDropdownOptions(nextProps);
      this.disableBlockStyles(nextProps);
    }
  }

  /**
   * prevent focus state on buttons
   * @param   {Object} event
   */
  onMouseDown(event) {
    event.preventDefault();
  }

  /**
   * onClick
   * @param   {Object} button
   * @returns {Function}
   */
  onClick(button) {
    switch (button.target) {
      case "selection":
        return this._toggleInlineStyle(button);
      case "block":
        return this._toggleBlockType(button);
      case "link":
        return this._toggleLink(button);
      default:
        return false;
    }
  }

  /**
   * _setCurrents
   * @param {Immutable.Record} state
   */
  _setCurrents(state) {
    this._setCurrentBlockKey(state);

    if (DraftHelpers.getCurrentBlock(state)) {
      this._setCurrentBlock(state);
      this._setCurrentType();
      this._setControls(state);
    }
  }

  /**
   * _setCurrentBlockKey
   * @param {Immutable.Record} state - <EditorState>
   */
  _setCurrentBlockKey(state) {
    const selection = state.getSelection();

    this.currentBlockKey = selection.getFocusKey();
  }

  /**
   * _setCurrentBlock
   * @param {Immutable.Record} state - <EditorState>
   */
  _setCurrentBlock(state) {
    this.currentBlock = state
      .getCurrentContent()
      .getBlockForKey(this.currentBlockKey);
  }

  /**
   * _setCurrentType
   */
  _setCurrentType() {
    this.currentType = this.currentBlock.getType();
  }

  /**
   * _setControls
   * @param {Immutable.Record} state
   */
  _setControls(state) {
    this.blockType = state
      .getCurrentContent()
      .getBlockForKey(this.currentBlockKey)
      .getData()
      .getIn(["config", "field_name"]);

    this.currentStyle = state.getCurrentInlineStyle();
  }

  /**
   * configure the buttons in the dropdown
   * @param {Object} props
   */
  _configureDropdownOptions(props) {
    const configuredOptions = props.configuration.data.blocks.map((button) => ({
      ...button,
      isDisabled: this.isDisabled(button),
      isSelected: props.currentType === props.value,
    }));

    this.setState({ configuredOptions });
  }

  /**
   * configure the selected dropdown option
   * @return {string|null} current dropdown style based on cursor selection
   * @todo remove default section ternary when we change paragraph from unstyled to
   * paragraph in draft
   */
  configureSelectedDropdown() {
    const { blocks } = this.props.configuration.data;
    let { default_section: defaultSection } = this.props.configuration.data;
    defaultSection =
      defaultSection === "paragraph" ? "unstyled" : defaultSection;
    const selectedDropdown = blocks.find(
      (option) => option.value === this.currentType
    );
    const defaultBlock = blocks.find((block) => block.value === defaultSection);

    return selectedDropdown
      ? selectedDropdown.display_name
      : defaultBlock.display_name;
  }

  /**
   * selects the block style from the dropdown
   */
  selectBlockStyle(option) {
    this._toggleBlockType(option);
  }

  /**
   * disableBlockStypes
   * passes a disabled boolean flag to the dropdown component
   * @param  {Object} props
   * @return {Boolean}
   */
  disableBlockStyles(props) {
    const { sections } = this.props.configuration.data;
    const blockTypeConfig = find(
      sections,
      (section) => section.field_name === this.blockType
    );
    if (blockTypeConfig) {
      this.setState({
        dropdownDisabled: DraftHelpers.hasDisabled(props.editor.editorState),
      });
    }
  }

  /**
   * removes inline editor styles for placeholder blocks
   * @param  {Object} nextProps
   * @return {void}
   */
  _removePlaceholderStyles(nextProps) {
    if (
      DraftHelpers.isPlaceholder(nextProps.editor.editorState) &&
      this._isNewBlock(nextProps) &&
      nextProps.editor.editorState.getSelection() !==
        this.props.editor.editorState.getSelection()
    ) {
      const inlineStyles = nextProps.editor.editorState
        .getCurrentInlineStyle()
        .toJS();

      inlineStyles.forEach((style) => {
        this.props.handleChange(
          RichUtils.toggleInlineStyle(nextProps.editor.editorState, style)
        );
      });
    }
  }

  /**
   * _filterDisallowedControls
   * @returns {Array}
   */
  _filterDisallowedControls() {
    return editorControlsMap.buttons.map((section) =>
      section.filter(
        (option) =>
          !this.props.configuration.data.controls.disallowed.includes(
            option.value
          )
      )
    );
  }

  /**
   * is selected block different to previous
   * @return {Boolean}
   */
  _isNewBlock(nextProps) {
    return this.currentBlockKey !== nextProps.currentBlockKey;
  }

  /**
   * a button is active when the given style exists in the selection
   * @param   {Object} button
   * @returns {Boolean}
   */
  isActive(button) {
    return (
      (this.currentStyle && this.currentStyle.has(button.value)) ||
      (this.currentType && button.value === this.currentType) ||
      (DraftHelpers.getCurrentBlock(this.props.editor.editorState) &&
        this._isLink(button))
    );
  }

  /**
   * _isLink
   * @returns {Boolean}
   */
  _isLink(button) {
    return (
      button.target === "link" &&
      LinkHelpers.isLink(this.props.editor.editorState)
    );
  }

  /**
   * a button is disabled when :
   * the selection exists and its a block action
   * The button type is not allowed for this given action
   * @param   {Object} button
   * @returns {Boolean}
   */
  isDisabled(button) {
    const { editorState } = this.props.editor;

    return (
      (button.target === "block" && DraftHelpers.hasDisabled(editorState)) ||
      (button.target === "link" &&
        (this.props.editor.editorState.getSelection().isCollapsed() ||
          DraftHelpers.hasDisabled(editorState)))
    );
  }

  /**
   * toggle Block Type via RichUtils and update state
   * @param {Object} button
   */
  _toggleBlockType(button) {
    const newState = RichUtils.toggleBlockType(
      this.props.editor.editorState,
      button.value
    );
    if (
      button.isEmptyInsertion &&
      DraftHelpers.isPlaceholder(this.props.editor.editorState)
    ) {
      TextPlaceholders.toggleOff("", newState, this.props.handleChange);
    } else {
      this.props.handleChange(newState);
    }
  }

  /**
   * toggle Inline Style via Rich Utils and update state
   * @param {Object} button
   */
  _toggleInlineStyle(button) {
    const newState = RichUtils.toggleInlineStyle(
      this.props.editor.editorState,
      button.value
    );
    this.props.handleChange(newState);
  }

  /**
   * _isMediaAllowed
   * @return {Boolean}
   */
  _isMediaAllowed() {
    return !this.props.configuration.data.controls.disallowed.some(
      (control) => control.toLowerCase() === "media"
    );
  }

  /**
   * toggleLink
   * @returns {void}
   */
  _toggleLink() {
    if (LinkHelpers.isLink(this.props.editor.editorState)) {
      return this.props.removeLink();
    }

    return this.props.promptForLink();
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <div
        className={classnames(styles["editor-controls"], {
          [styles["editor-controls--fixed"]]: this.props.isFixed,
        })}
      >
        <div className={styles["editor-controls__container"]}>
          <DropdownBlockStyles
            options={this.state.configuredOptions}
            onSelect={this.selectBlockStyle}
            optionSelected={this.configureSelectedDropdown()}
            placeholder="Paragraph"
            isDisabled={this.state.dropdownDisabled}
          />
          {this.allowedControls.map((section, index) => (
            <div className={styles["editor-controls__section"]} key={index}>
              {section.map((button) => (
                <button
                  key={button.value}
                  id={button.value}
                  data-test={button.value}
                  className={classnames(styles["editor-controls__button"], {
                    [styles["editor-controls__button--active"]]:
                      this.isActive(button),
                    [styles["editor-controls__button--disabled"]]:
                      this.isDisabled(button),
                  })}
                  onClick={() =>
                    !this.isDisabled(button) && this.onClick(button)
                  }
                  onMouseDown={this.onMouseDown}
                  disabled={this.isDisabled(button)}
                >
                  <SkylarkIcon
                    iconName={button.icon}
                    extraClassName={styles["editor-controls__icon"]}
                  />
                </button>
              ))}
            </div>
          ))}
          {this._isMediaAllowed() && (
            <div className={styles["editor-controls__dropdown"]}>
              <DropdownArticleObjects
                onModalSave={this.props.onModalSave}
                renderMedia={this.props.renderMediaBlock}
                isDisabled={this.state.dropdownDisabled}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
