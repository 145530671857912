/**
 * Factory for CRUD functionally of a talent
 * @param ApiService
 * @returns {{}}
 * @constructor
 */
function TalentFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  /**
   * request all talent entities
   */
  factory.getTalent = () =>
    ApiService.get(
      "/api/talent/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get a single talent or any entity by url
   * @param url
   */
  factory.getByUrl = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * put or post a data object to talent endpoint
   * @param data
   */
  factory.save = (data) =>
    data.uid
      ? ApiService.put(
          data.self,
          data,
          ApiRequestConfigFactory.createRequestConfig({
            overrideGlobalLanguage: true,
          })
        )
      : ApiService.post(
          "/api/talent/",
          data,
          ApiRequestConfigFactory.createRequestConfig({
            overrideGlobalLanguage: true,
          })
        );

  /**
   * get a array of roles the talent entity can have from api
   */
  factory.getRoles = () =>
    ApiService.get(
      "/api/roles/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get role details from a role url
   * @param url
   */
  factory.getRole = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  return factory;
}

export default TalentFactory;
