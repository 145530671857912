/**
 * MultipleCheckboxCtrl
 * @fileOverview Attribute Directive which handles checkbox values in dynamic field loops.
 */
class MultipleCheckboxCtrl {
  /**
   * @constructor
   * @param   {Object} DimensionsService
   * @param   {Object} OptionsFactory
   * @param   {Object} _ lodash
   */
  constructor(DimensionsService, OptionsFactory, MessageService, _) {
    this.DimensionsService = DimensionsService;
    this.OptionsFactory = OptionsFactory;
    this.MessageService = MessageService;
    this._ = _;
  }

  /**
   * init
   * @public
   * @param   {Object} field - field config
   * @param   {Object} data - the object's data
   * @param   {Object} counts - a counts object, with a property matching each dimension
   * @param   {Number} counts.`${dimensionName}`  - the amount selected for a given dimension
   * @returns {void}
   */
  init(field, data, counts = {}, context) {
    this.data = data;
    this.field = field;
    this.counts = counts;
    this.context = context;
    this.data.amountSelected = data.amountSelected;

    if (this.field.slug) {
      this._getDimensionOptions();
    } else {
      this._getOptions();
    }
  }

  /**
   * Gets the available options for these checkboxes if the config doesn't use the dimensions endpoint
   * @private
   * @returns {void}
   */
  _getOptions() {
    this.OptionsFactory.getFieldOptions(this.field.url).then((options) => {
      this._setupCheckboxesWithData(options.objects);
    });
  }

  /**
   * Gets the available options for these checkboxes if we are using the dimensions endpoint
   * @private
   * @returns {void}
   */
  _getDimensionOptions() {
    this.DimensionsService.getDimension(
      this.field.slug,
      this.field.allowed_options
    ).then((data) => {
      this._setupCheckboxesWithData(data);
    });
  }

  /**
   * Sets up checkboxes with the loaded data
   * @private
   * @param {array} optionData
   * @returns {void}
   */
  _setupCheckboxesWithData(optionData) {
    this._initialiseWithDefault();
    this[this.field.name] = this._setExistingOptions(
      optionData,
      this.data[this.field.name]
    );

    this.optionsPreview = this._buildOptionsPreview();
    this._toggleAllCheckbox();

    this.data.isModified = false;

    if (this.context && !this.data.amountSelected) {
      this[`all${this.field.name}`] = true;
      this.toggleAll();
      this.data.isModified = false;
    }
  }

  /**
   * _initialiseDefault
   * @returns {void}
   */
  _initialiseWithDefault() {
    if (!this.data[this.field.name]) {
      this.data[this.field.name] = [];
    }
    if (!this.counts[this.field.name]) {
      this.counts[this.field.name] = this.data[this.field.name].length;
    }
  }

  /**
   * _setExistingOptions
   * @private
   * @param {Array} data - the list of dimensions coming from the API
   * @param {Array} existingUrls - the list of existing Objects or URLs in the data object being edited
   * @returns {Array}
   */
  _setExistingOptions(data, existingUrls) {
    data.forEach((option) => {
      option.isSelected = existingUrls.length
        ? !!existingUrls.find((value) => value === option[this.field.name_as])
        : false;
    });

    return data;
  }

  /**
   * _buildOptionsPreview
   * @returns {String}
   */
  _buildOptionsPreview() {
    return this[this.field.name]
      .filter((item) => item.isSelected)
      .map((item) => item.name)
      .join(", ");
  }

  /**
   * toggleAll
   * @public
   * @callback {ng-change}
   * @returns {void}
   */
  toggleAll() {
    this.data[this.field.name].length = 0;
    this.counts[this.field.name] = 0;
    this.data.isModified = true;
    if (this[`all${this.field.name}`]) {
      this._addAllOptions();
    } else {
      this._removeAllOptions();
    }
  }

  /**
   * _isAllSelected - checks the length of a single instance of a multiple checkbox on a page
   * @private
   * @returns {Boolean}
   */
  _isAllSelected() {
    return (
      this.data[this.field.name].length &&
      this.data[this.field.name].length === this[this.field.name].length
    );
  }

  /**
   * _toggleAllCheckbox
   * @private
   * @returns {void}
   */
  _toggleAllCheckbox() {
    if (this._isAllSelected()) {
      this[`all${this.field.name}`] = true;
    } else if (this[`all${this.field.name}`]) {
      this[`all${this.field.name}`] = false;
    }
    this.data.isModified = true;
  }

  /**
   * toggleCheckbox
   * @public
   * @callback {ng-change}
   * @param   {Object} option
   * @param   {String} name
   * @returns {void}
   */
  toggleCheckbox(option) {
    this.data.isModified = true;
    if (option.isSelected) {
      this._addOption(option);
    } else {
      this._removeOption(option);
    }

    this._toggleAllCheckbox();
  }

  /**
   * _addAllOptions
   * @private
   * @returns {void}
   */
  _addAllOptions() {
    this[this.field.name].forEach((option) => {
      option.isSelected = true;
      this._addOption(option);
    });
  }

  /**
   * _removeAllOptions
   * @private
   * @returns {void}
   */
  _removeAllOptions() {
    this[this.field.name].forEach((option) => {
      option.isSelected = false;
    });
    this.counts[this.field.name] = 0;
  }

  /**
   * addOption
   * @private
   * @param {object} item
   * @returns {void}
   */
  _addOption(item) {
    this.data[this.field.name].push(item[this.field.name_as]);
    this.counts[this.field.name]++;
    this.data.isModified = true;
  }

  /**
   * removeOption
   * @private
   * @param {object} item
   * @returns {void}
   */
  _removeOption(item) {
    this._.remove(
      this.data[this.field.name],
      (value) => value === item[this.field.name_as]
    );
    this.counts[this.field.name]--;
    this.data.isModified = true;
  }
}

/**
 * MultipleCheckboxDirective
 * @returns {Object} Directive Definition Object
 */
function multipleCheckboxDirective() {
  return {
    restrict: "A",
    scope: false,
    controller: MultipleCheckboxCtrl,
    controllerAs: "multipleCheckbox",
    link: (scope) => {
      scope.multipleCheckbox.init(
        scope.field,
        scope.module.data,
        scope.module.counts,
        scope.module.context
      );
    },
  };
}

export default multipleCheckboxDirective;
