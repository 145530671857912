import React, { Component } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import Toast from "./toast/toast";

import styles from "./toaster.scss";

export default class ToasterContainer extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    toaster: PropTypes.shape({
      newToast: PropTypes.shape(),
    }),
    toggleToasterState: PropTypes.func.isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    toaster: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.state = { toasts: [] };

    this.closeToast = this.closeToast.bind(this);
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    this.getRootElement();
    this.addClassToContainer();
  }

  /**
   * componentWillReceiveProps lifecycle hook
   * @param  {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this._handleNewToast(nextProps.toaster);
  }

  /**
   * getRootElement
   */
  getRootElement() {
    this.toasterRoot = document.getElementById("toaster-root");
  }

  /**
   * addClassToContainer
   */
  addClassToContainer() {
    this.toasterRoot.classList.add(styles["toaster-container"]);
  }

  /**
   * add new toast if UID from store has changed
   * @param  {Object} toaster
   * @return {void}
   */
  _handleNewToast(toaster) {
    if (this.shouldCreateToast(toaster.newToast)) {
      this.popToast(toaster.newToast);
    }
  }

  /**
   * popToast
   * @param  {Object} newToastData
   * @return {void}
   */
  popToast(newToastData) {
    const toastPortal = createPortal(
      <Toast
        closeToast={this.closeToast}
        toastCount={this.state.toasts.length + 1}
        data={newToastData}
      />,
      this.toasterRoot
    );

    this.setState({
      toasts: [...this.state.toasts, toastPortal],
    });
  }

  /**
   * closeToast
   * @param {number} toastId
   * @return {void}
   */
  closeToast(toastId) {
    const { toasts } = this.state;
    const toastIndex = this.findToastToClose(toasts, toastId);

    toasts.splice(toastIndex, 1);
    this.setState({ toasts });

    this.resetToaster();
  }

  /**
   * resetToaster
   */
  resetToaster() {
    if (!this.state.toasts.length) {
      this.props.toggleToasterState();
    }
  }

  /**
   * findToastToClose
   * @param  {Array} toasts
   * @param  {String} toastId
   * @return {Number}
   */
  findToastToClose(toasts, toastId) {
    return toasts.findIndex((toast) => toast.id === toastId);
  }

  /**
   * shouldCreateToast
   * @param  {Array} newToastId
   * @return {Boolean}
   */
  shouldCreateToast(newToast) {
    return newToast.id && newToast.id !== this.props.toaster.newToast.id;
  }

  /**
   * render and create portal
   * @returns {element}
   */
  render() {
    return [this.state.toasts];
  }
}
