import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Map } from "immutable";

import BlockIndicator from "../block-indicator/block-indicator";

import styles from "./editor-list-block.scss";

/**
 * Custom editor list wrapper for editor
 * @param {Object} props
 */
export default function EditorListBlock(props) {
  const ListType = props["list-type"] === "unordered-list-item" ? "ol" : "ul";
  const blockConfig = props.children[0].props.children.props.block.getIn([
    "data",
    "config",
  ]);
  const defaultConfig = Map({ is_restricted: false, indicator: "li" });
  const config = blockConfig || defaultConfig;

  return (
    <div
      key={`customBlock-${props.children[0].key}`}
      className={classnames(styles["editor-block"], {
        [styles["editor-block__template"]]: config.get("is_restricted"),
      })}
    >
      <BlockIndicator
        indicator={
          config.get("is_restricted")
            ? config.get("indicator")
            : props.indicator
        }
      />
      <ListType
        className={classnames(
          styles["editor-block__list"],
          styles[`editor-block__${ListType}`]
        )}
      >
        {props.children}
      </ListType>
    </div>
  );
}

EditorListBlock.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  "list-type": PropTypes.string,
  indicator: PropTypes.string,
};

EditorListBlock.defaultProps = {
  children: undefined,
  "list-type": undefined,
  indicator: undefined,
};
