import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import { createApiAction } from "store/api/api.actions";
import SearchConstant from "./search.constant";

/**
 * @param {object} selectedFilter - an object shaped like a flatten navigation object
 * @return {object}
 *
 */
export const setSelectedFilter = (selectedFilter) => ({
  type: SearchConstant.SET_SELECTED_FILTER,
  selectedFilter,
});

/**
 *
 * @param {boolean} resultsVisible: false | true
 * @return {object}
 *
 */
export const setResultsVisibility = (resultsVisible) => ({
  type: SearchConstant.SET_RESULTS_VISIBILITY,
  resultsVisible,
});

/**
 * Set the search query on the reducer
 *
 * @param {string} searchQuery
 * @return {object}
 *
 */
export const setSearchQuery = (searchQuery) => ({
  type: SearchConstant.SET_SEARCH_QUERY,
  searchQuery,
});

/**
 * Generates parameters for the search request
 *
 * @param {string} field
 * @param {string} searchQuery
 * @param {number} start
 * @param {string} slugType - in the form of eg: set_type_slug=sets
 *
 */
const generateParameters = ({ field, searchQuery, start, slugType }) => {
  const ApiRequestConfigFactory = Injector.get("ApiRequestConfigFactory");

  const params = {
    fields: `${field},self,uid`,
    all: true,
    q: searchQuery,
    limit: 30,
    order: field,
    start,
  };

  if (slugType) {
    const split = slugType.split("=");
    params[split[0]] = split[1];
  }

  return ApiRequestConfigFactory.createRequestConfig({
    requestConfig: {
      params,
    },
  });
};

/**
 * Makes an api request to the /api/{entity}/?q={*term*} endpoint with the generated params
 *
 * @param {string} searchConfig.field
 * @param {string} searchConfig.searchQuery
 * @param {string} searchConfig.resources - it's in the form of /api/brands/ or /api/sets/ etc..
 * @param {number} searchConfig.start
 * @param {number} searchConfig.slug_type
 */
const fetchSearch = (searchConfig) => {
  const { resources } = searchConfig;
  const ApiService = Injector.get("ApiService");
  const requestConfig = generateParameters(searchConfig);
  return createApiAction(
    SearchConstant.FETCH_SEARCH,
    ApiService.get(resources, requestConfig)
  );
};

export { fetchSearch };
export { generateParameters };
