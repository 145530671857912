import template from "./date-input.html";

class DateInputCtrl {
  /**
   * @constructor
   * @param {Object} DateInputService
   * @param {Object} MessageService
   * @param {Object} momentJS
   */
  constructor(DateInputService, MessageService, momentJS) {
    this.DateInputService = DateInputService;
    this.MessageService = MessageService;
    this.momentJS = momentJS;

    this._onDateUpdate = this._onDateUpdate.bind(this);
    this._onUpdateFields = this._onUpdateFields.bind(this);

    this.setupSubscriptions();
  }

  /**
   * Set up pub/sub subscriptions
   * @returns {void}
   */
  setupSubscriptions() {
    this.MessageService.subscribe(
      `DateInput.update.${this.item.uuid}`,
      this._onDateUpdate
    );
    this.MessageService.subscribe("UpdateFields", this._onUpdateFields);
  }

  removeSubscriptions() {
    this.MessageService.off(
      `DateInput.update.${this.item.uuid}`,
      this._onDateUpdate
    );
    this.MessageService.off("UpdateFields", this._onUpdateFields);
  }

  /**
   * Initialise the component
   * @returns {void}
   */
  $onInit() {
    this._initialiseValues();
  }

  /**
   * $onDestroy - native angular
   * @returns {void}
   */
  $onDestroy() {
    this.removeSubscriptions();
    this._resetData({});
  }

  /**
   * on date update
   * @private
   */
  _onDateUpdate() {
    this.data[this.config.name] = this.DateInputService.getFormattedValueByUUID(
      this.item.uuid
    );
  }

  /**
   * on update Fields
   * @param ch
   * @param data
   * @private
   */
  _onUpdateFields(ch, data) {
    this._resetData(data);
  }

  /**
   * Sets the date and formats
   * @returns {void}
   */
  setDate() {
    const date = this.DateInputService.getValueByUUID(this.item.uuid);
    this.DateInputService.updateDate(this.item.uuid, date);
  }

  /**
   * Returns the year range
   * @returns [1900, string]
   */
  getYearRange() {
    const currentYear = new Date().getFullYear();
    return [1900, currentYear];
  }

  /**
   * Reset the field
   * @param {Object} data
   * @returns {void}
   * @private
   */
  _resetData(data) {
    this.data = {
      [this.config.name]: data[this.config.name],
      data_source_id: data.data_source_id,
      uid: data.uid,
    };
    this._initialiseValues();
  }

  /**
   * _initialiseValues
   * @returns {void}
   */
  _initialiseValues() {
    this.DateInputService.initialiseValues(
      this.data[this.config.name],
      this.item.uuid
    );
  }
}

/**
 * Date and time directive
 * @returns {Object}
 */
function dateFieldDirective() {
  return {
    restrict: "E",
    controller: DateInputCtrl,
    controllerAs: "component",
    template,
    scope: {},
    bindToController: {
      data: "=",
      config: "<",
      form: "=",
      item: "=",
      module: "=",
    },
  };
}

export default dateFieldDirective;
