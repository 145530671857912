import angular from "angular";
import TEXT_TRACK_UPLOAD from "store/text-track-upload/text-track-upload.constant";
import * as TextTrackActions from "store/text-track-upload/text-track-upload.action";
import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./text-track.html";

class TextTrackController {
  /**
   * Text Track initialisation and connecting to store
   * @param $q
   * @param TextTrackUploadService
   * @param MessageService
   * @param NotificationService
   * @param ModalTriggerService
   * @param EntityFactory
   * @param ReduxConnector
   */
  constructor(
    $q,
    TextTrackUploadService,
    MessageService,
    NotificationService,
    ModalTriggerService,
    EntityFactory,
    ReduxConnector
  ) {
    this.$q = $q;
    this.TextTrackUploadService = TextTrackUploadService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ModalTriggerService = ModalTriggerService;
    this.EntityFactory = EntityFactory;
    this.ReduxConnector = ReduxConnector;

    this.connectToStore();
  }

  /**
   * init
   */
  $onInit() {
    this.setSubscription();
    this.setData();
  }

  /**
   * on changes update the state
   */
  $onChanges() {
    this.setStatusText();
    this.hasError();
    this.hasCompleted();
    this.setTextTrackType();
    this.isSynchronising();
    this.isSynchronisingDisabled();
    this.disableLanguages();
    this.disableDisplayLabels();
  }

  /**
   * set data for the form fields
   */
  setData() {
    if (!this.textTrack || typeof this.textTrack.textTrack !== "object") {
      this.data = {};
    }
    this.data = { ...this.textTrack.textTrack };
  }

  /**
   * add disable options to the select for language codes
   */
  disableLanguages() {
    const disabledOptions = this.usedLanguageCodes.filter(
      (languageCode) => languageCode !== this.textTrack.textTrack.language_code
    );
    this.fields = Object.values(this.fields).map((_field) => {
      const field = { ..._field };
      if (field.name === "language_code") {
        field.disabled = disabledOptions;
      }

      return field;
    });
  }

  /**
   * add disable options to the select for display labels
   */
  disableDisplayLabels() {
    const disabledOptions = this.usedDisplayLabels.filter(
      (displayLabel) => displayLabel !== this.textTrack.textTrack.display_label
    );
    this.fields = Object.values(this.fields).map((_field) => {
      const field = { ..._field };
      if (field.name === "display_label") {
        field.disabled = disabledOptions;
      }

      return field;
    });
  }

  /**
   * subscribe to delete of text track
   */
  setSubscription() {
    const uid =
      this.textTrack &&
      this.textTrack.textTrack &&
      this.textTrack.textTrack.uid;
    this.MessageService.subscribe(`delete.${uid}`, () => this.delete());
  }

  /**
   * error state
   */
  hasError() {
    this.error = this.textTrack.status === TEXT_TRACK_UPLOAD.UPLOAD_FAILED;
  }

  /**
   * complete state
   */
  hasCompleted() {
    this.done =
      this.textTrack.status === TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE ||
      this.textTrack.status === TEXT_TRACK_UPLOAD.DOCUMENT_CREATED;
  }

  /**
   * is synchronising state
   */
  isSynchronising() {
    this.synchronising =
      this.textTrack.status === TEXT_TRACK_UPLOAD.INGEST_STARTED ||
      this.isSaving;
  }

  /**
   * disable the button for synchronising manually
   */
  isSynchronisingDisabled() {
    this.synchronisedDisabled = this.synchronising || this.isSaving;
  }

  /**
   * status text state
   */
  setStatusText() {
    switch (this.textTrack.status) {
      case TEXT_TRACK_UPLOAD.UPLOAD_STARTED:
      case TEXT_TRACK_UPLOAD.TEXT_TRACK_CREATED:
      case TEXT_TRACK_UPLOAD.UPLOAD_PROGRESS: {
        this.statusText = "Uploading text track";
        break;
      }
      case TEXT_TRACK_UPLOAD.DOCUMENT_CREATED: {
        this.statusText = "Upload finished";
        break;
      }
      case TEXT_TRACK_UPLOAD.DELETE_ASSET: {
        this.statusText = "Deleting text track";
        break;
      }
      case TEXT_TRACK_UPLOAD.INGEST_STARTED: {
        this.statusText = "Synchronising";
        break;
      }
      case TEXT_TRACK_UPLOAD.UPLOAD_FAILED: {
        this.statusText = "Failed";
        break;
      }
      case TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE: {
        this.statusText = "Done";
        break;
      }
      default:
        this.statusText = "---";
    }
  }

  /**
   * text track type status
   */
  setTextTrackType() {
    if (!this.textTrack || !this.textTrack.textTrack) {
      return;
    }
    this.textTrackType = this.textTrack.textTrack.is_closed_captions
      ? "CC"
      : "Subtitle";
  }

  /**
   * set saving state
   * @param {Boolean} boolean
   */
  setSaving(boolean) {
    this.isSaving = boolean;
    this.isSynchronising();
    this.isSynchronisingDisabled();
  }

  /**
   * submit event when auto save is happening
   * @param {Object} values
   * @return {promise}
   */
  onSubmit(values) {
    const filter = { fields: Object.keys(values).join(",") };
    const isDuplicatedLabel = this.isPropertyWithValueAlreadyUsed(
      values,
      "display_label",
      this.usedDisplayLabels
    );
    const isDuplicatedLanguage = this.isPropertyWithValueAlreadyUsed(
      values,
      "display_label",
      this.usedLanguageCodes
    );

    if (isDuplicatedLabel) {
      this.NotificationService.notifyError("Display Label already used");
      return this.$q.reject();
    }

    if (isDuplicatedLanguage) {
      this.NotificationService.notifyError("Language Code already used");
      return this.$q.reject();
    }

    this.setSaving(true);

    return this.EntityFactory.patchByUrl(
      this.textTrack.textTrack.self,
      values,
      filter
    )
      .then((data) => {
        this.setSaving(false);

        this.store.updateTextTrack({
          id: this.textTrack.textTrack.uid,
          textTrack: data,
        });

        if (
          this.textTrack.document.url &&
          (this.textTrack.textTrack.language_code || data.language_code)
        ) {
          return this.synchronise();
        }

        this.NotificationService.notifyInfo(
          "Saved Successfully, but language code required to synchronise with OVP"
        );
      })
      .catch((error) => {
        this.setSaving(false);

        this.NotificationService.notifyError(
          NotificationMessage.updateError(this.textTrack.document.file_name)
        );
        return this.$q.reject(error);
      });
  }

  /**
   * when same value for the same property is used by another text track then return false
   * @param {Object} values
   * @param {String} property
   * @param {Array<String>} list
   * @return {boolean}
   */
  isPropertyWithValueAlreadyUsed(values, property, list) {
    if (!values[property]) {
      return false;
    }

    if (
      this.textTrack &&
      this.textTrack.textTrack &&
      this.textTrack.textTrack[property] === values[property]
    ) {
      return false;
    }

    return list.includes(values[property]);
  }

  /**
   * synchronise with the ovp
   * @return {promise}
   */
  synchronise() {
    return this.TextTrackUploadService.synchroniseTextTrack(
      this.textTrack,
      this.assetHasOvp
    ).then(() => this.MessageService.publish("refreshTextTracks"));
  }

  /**
   * modal to let user confirm of deleting asset
   * @returns {void}
   */
  triggerDeleteAssetWarning() {
    const uid =
      this.textTrack &&
      this.textTrack.textTrack &&
      this.textTrack.textTrack.uid;
    this.ModalTriggerService.triggerNotification({
      notificationType: "delete",
      channels: {
        confirm: `delete.${uid}`,
      },
    });
  }

  /**
   * removing text track
   */
  delete() {
    this.isRemoving = true;

    this.TextTrackUploadService.deleteUpload(this.textTrack)
      .then(() => this.MessageService.publish("refreshTextTracks"))
      .catch((error) => this.NotificationService.notifyError(error))
      .finally(() => (this.isRemoving = false));
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...TextTrackActions,
    };
    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  /**
   * removing store and subscription
   */
  $onDestroy() {
    const uid =
      this.textTrack &&
      this.textTrack.textTrack &&
      this.textTrack.textTrack.uid;

    this.disconnect();
    this.MessageService.off(`delete.${uid}`);
  }
}

const TextTrackComponent = angular
  .module("Skylark.Components.TextTrack", [])
  .component("textTrack", {
    bindings: {
      textTrack: "<",
      fields: "<",
      usedLanguageCodes: "<",
      usedDisplayLabels: "<",
      autoSave: "<?",
      assetHasOvp: "<",
    },
    template,
    controller: TextTrackController,
    controllerAs: "module",
  });

export default TextTrackComponent;
