class UsersService {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} ApiService
   * @param {Object} ApiRequestConfigFactory
   * @param {Object} UsersFactory
   * @param {Object} StoreFactory
   * @param {Object} _
   * @param {Object} SkylarkApiInfoService
   */
  constructor(
    $q,
    ApiService,
    ApiRequestConfigFactory,
    UsersFactory,
    EntityFactory,
    StoreFactory,
    _,
    SkylarkApiInfoService
  ) {
    this.$q = $q;
    this.ApiService = ApiService;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.EntityFactory = EntityFactory;
    this.UsersFactory = UsersFactory;
    this.StoreFactory = StoreFactory;
    this._ = _;
    this.SkylarkApiInfoService = SkylarkApiInfoService;

    this.userRoleStore = this.StoreFactory.createInstance(
      this.UsersFactory.getRolesList
    );
  }

  /**
   * @set
   * @param {*} value
   */
  set userRoleStore(value) {
    this._userRoleStore = value;
  }

  /**
   * @get
   * @returns {*}
   */
  get userRoleStore() {
    return this._userRoleStore;
  }

  /**
   * @param {string} email
   * @returns {Promise}
   * @public
   */
  getUserName(email) {
    const deferred = this.$q.defer();

    this.EntityFactory.getAll("customers", { q: `email:'${email}'` })
      .then((customers) => {
        if (customers) {
          deferred.resolve(this._.head(customers.objects));
        } else {
          deferred.resolve({});
        }
      })
      .catch(() => deferred.reject());

    return deferred.promise;
  }

  /**
   * Handles the reset of a user's password
   * @param {string} type
   * @param {object} user
   * @returns {Promise}
   */
  passwordReset(type, user) {
    return this.SkylarkApiInfoService.hasCognitoAuth().then(
      (cognitoEnabled) => {
        if (cognitoEnabled) {
          return this.ApiService.post(
            `api/users/${user.email}/password/reset/`
          );
        }

        if (type === "cms-users") {
          return this.ApiService.post("/api/cms-users/reset-password/", {
            cms_user_url: user.self,
          });
        }
        return this.ApiService.post("/api/authenticate/forgotten_password/", {
          email: user.email,
        });
      }
    );
  }

  /**
   * _checkCurrentUserCache
   * @description Caches the promise
   * @returns {promise}
   */
  _checkCurrentUserCache() {
    if (!this.currentUserPromise) {
      this.currentUserPromise = this.UsersFactory.getCurrentUser();
    }

    return this.currentUserPromise;
  }

  /**
   * getCurrentUser
   * @description Returns the current user. Uses cached version if API call has been made before.
   * @returns {promise}
   */
  getCurrentUser() {
    const deferred = this.$q.defer();

    if (!this.currentUser) {
      this._checkCurrentUserCache(deferred)
        .then((data) => {
          this.currentUser = data;
          deferred.resolve(data);
        })
        .catch(() => deferred.reject());
    } else {
      deferred.resolve(this.currentUser);
    }

    return deferred.promise;
  }
}

export default UsersService;
