import angular from "angular";
import template from "./file-picker.html";

class FilePickerCtrl {
  /**
   * Constructor for the class
   * @param {Object} $element
   */
  constructor($element) {
    this.$element = $element;
  }

  /**
   * a button click forwarding to file input, to browse from file
   */
  onFakeBrowseFileClick() {
    this.$element.find(".js-file-input").click();
  }

  updateFileName() {
    const file = this.data[this.field.name];
    if (file && !this.data[this.field.name_as]) {
      this.data[this.field.name_as] = file.name;
    }
  }
}

const FilePicker = angular
  .module("Skylark.Components.Fields.FilePicker", [])
  .component("filePicker", {
    bindings: {
      config: "<",
      data: "<",
      field: "<",
      validatedForm: "<",
    },
    controller: FilePickerCtrl,
    controllerAs: "component",
    template,
  });

export default FilePicker;
