import template from "./create-entity-button.html";

/**
 * Directive definition for listing item blocks
 * @returns {Object}
 * @constructor
 */
function CreateEntityButtonDirective() {
  return {
    restrict: "E",
    replace: true,
    template,
  };
}

export default CreateEntityButtonDirective;
