const CONTENT_SETTINGS = {
  MODAL: {
    SAVE_DIMENSIONS: "Modal.DimensionSettings.Saved",
    TOGGLE_DIMENSIONS: "Modal.DimensionSettings.Toggled",
    SAVE_TRANSLATIONS: "Modal.TranslationSettings.Saved",
    TOGGLE_TRANSLATIONS: "Modal.TranslationSettings.Toggled",
  },
  LANGUAGE_SET: "ContentSettings.GlobalLanguage.Set",
  GLOBAL_LANGUAGE_CHANGED: "GlobalLanguage.changed",
  CONTENT_SETTINGS_CREATE_NEW_LANGUAGE: "ContentSettings.createNewLanguage",
  ENTITY_LANGUAGES_AVAILABLE: "Entity.LanguagesAvailable",
  LOCAL_NAV_DIMENSIONS_REVERT: "LocalNav.Dimensions.revert",
};

export default CONTENT_SETTINGS;
