import template from "./navigation-filter-button.html";

/**
 * @NavigationFilterButton Component
 */
const navigationFilterButtonComponent = {
  bindings: {
    iconName: "@",
    title: "@",
    copy: "@",
    isActive: "<",
    isDisabled: "<",
  },
  template,
  controllerAs: "component",
};

export default navigationFilterButtonComponent;
