/**
 * Images Factory
 *
 *
 *
 * @description Contains factory methods for working with
 * the images endpoint
 */

/**
 * Angular factory for Images
 * @param {object} _
 * @param {object} $q
 * @param {object} ApiService
 * @param {object} ApiRequestConfigFactory
 * @param {object} EntityFactory
 * @param {object} UtilitiesService
 * @returns {object}
 */
function MediaFactory(
  _,
  $q,
  ApiService,
  ApiRequestConfigFactory,
  EntityFactory,
  UtilitiesService
) {
  const factory = {};

  /**
   * Handles the updating of any image
   * clones the data to avoid changing the original object
   * and makes sure that the schedule_urls are unexpanded
   * @private
   * @param {Object} url
   * @param {Object} data
   * @returns {Promise}
   */
  factory._updateEntityData = (url, data) => {
    const updatedDate = _.cloneDeep(data);

    _.each(updatedDate.schedule_urls, (schedule, i) => {
      if (_.isPlainObject(schedule)) {
        updatedDate.schedule_urls[i] = schedule.self;
      }
    });

    return factory.updateByUrl(url, updatedDate);
  };

  /**
   * update
   * @param {string} url - url to send data to
   * @param {object} data - data to update
   * @param {string} fileUrl - url to file
   * @param {object} file - the file to update with
   * @returns {Promise} deferred promise
   */
  factory.update = (url, data, fileUrl, file) => {
    if (fileUrl && file) {
      return factory
        .upload(fileUrl, file)
        .then(() => factory._updateEntityData(url, data));
    }

    return factory._updateEntityData(url, data);
  };

  /**
   * some functions which are taken from the EntityFactory but slightly modified. To the needs of the Media Items,
   * specially the ?all=true flag so that parent entities are shown even when parent is not scheduled,
   * it would be a good idea doing this as a default behaviour but it will need some effort to update the tests
   */

  /**
   * create entity
   * @param entity
   * @param data
   * @return {Promise}
   */
  factory.create = (entity, data) =>
    ApiService.post(
      `/api/${entity}/`,
      data,
      ApiRequestConfigFactory.createRequestConfig({
        useDefaultParams: true,
        useGlobalParams: true,
        overrideGlobalLanguage: true,
      })
    );

  /**
   * get By url
   * @param {string} url
   * @param {object} filters
   * @returns {Promise}
   */
  factory.get = (url, filters = {}) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useDefaultParams: true,
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig: {
          params: filters,
        },
      })
    );

  /**
   *  Update an entity with the url
   *  creates new version or language version of given episode
   *  @param {string} url
   *  @param {object} data
   *  @returns {Promise}
   */
  factory.updateByUrl = (url, data) =>
    ApiService.put(
      url,
      data,
      ApiRequestConfigFactory.createRequestConfig({
        useDefaultParams: true,
        useGlobalParams: true,
        overrideGlobalLanguage: true,
      })
    );

  /**
   * Uploading a media from an url is asynchronous, backend needs to download the file, after this was successful, the `url` is set
   * so we need to check if the `url` is set, or we will try again after a defined duration.
   * This function will fail after the `url` was not set after 10 tries
   * @param data
   * @param tries
   * @return {Promise<Object>}
   */
  factory.waitUntilUploadFinished = (data, tries = 0) => {
    const maxTries = 10;
    const waitDelay = 1000;
    if (data.url) {
      return $q.resolve(data);
    }

    if (tries > maxTries) {
      return $q.reject("Upload Failed");
    }

    tries++;

    return UtilitiesService.delayPromise(waitDelay)
      .then(() => EntityFactory.getByUrl(data.self))
      .then((data) => factory.waitUntilUploadFinished(data, tries));
  };

  factory.upload = EntityFactory.uploadFileByUrl;

  factory.delete = EntityFactory.deleteByUrl;

  return factory;
}

export default MediaFactory;
