import axios from "axios";
import { merge } from "lodash";

import CookieService from "skylarklib/helpers/cookies/cookies";
import { GLOBAL_LANGUAGE } from "skylarklib/constants/cookie";
import { getToken as getCognitoToken } from "skylarklib/angularjs/services/aws-amplify/amplify.service";
import backendServices from "skylarklib/constants/backend-services";

const addPrefixToUrl = (url, prefix) => {
  // If url has a leading slash, remove it
  const parsedUrl = url.startsWith("/") ? url.substring(1) : url;
  // If API prefix has a trailing slash, remove it
  const parsedPrefixPath = prefix.endsWith("/") ? prefix.slice(0, -1) : prefix;
  // Join the path with a single slash
  const prefixedUrl = new URL(`${parsedPrefixPath}/${parsedUrl}`, prefix);
  return prefixedUrl.toString();
};

export const constructRequestUrl = (url) => {
  if (
    __API_URL_PREFIX__ &&
    url &&
    (url.startsWith("/api") ||
      url.startsWith("api") ||
      url.startsWith(backendServices.pelicanPath) ||
      url.startsWith(backendServices.ravenPath))
  ) {
    if (__PELICAN_URL_PREFIX__ && url.startsWith(backendServices.pelicanPath)) {
      const prefixedUrl = addPrefixToUrl(url, __PELICAN_URL_PREFIX__);
      return prefixedUrl;
    }

    if (__RAVEN_URL_PREFIX__ && url.startsWith(backendServices.ravenPath)) {
      const prefixedUrl = addPrefixToUrl(url, __RAVEN_URL_PREFIX__);

      return prefixedUrl;
    }

    const prefixedUrl = addPrefixToUrl(url, __API_URL_PREFIX__);
    return prefixedUrl;
  }

  if (
    __CMS_CONFIG_PATH_PREFIX__ &&
    url &&
    (url.startsWith("/cms-config") || url.startsWith("cms-config"))
  ) {
    const prefixedUrl = url.replace(
      "cms-config",
      `${__CMS_CONFIG_PATH_PREFIX__}/cms-config`
    );
    return prefixedUrl;
  }

  return url;
};

/**
 * get authorization token from cookie
 * @returns {string || undefined}
 */
async function _getAuthorizationToken() {
  // V8 and above use aws-amplify
  if (__V8_ENVIRONMENT__) {
    try {
      const token = await getCognitoToken();
      return token;
    } catch {
      return;
    }
  }

  // V7 and below use auth cookie
  const auth = CookieService.get("token");
  if (auth) {
    return auth.token;
  }
}

/**
 * _buildHeaders
 */
async function _buildHeaders(requestConfig, addAuthHeader) {
  const token = await _getAuthorizationToken();
  const language = CookieService.get(GLOBAL_LANGUAGE);
  const defaultHeaders = {
    headers: {},
  };

  if (token && addAuthHeader) {
    defaultHeaders.headers.Authorization = `${
      __AUTH_TOKEN_PREFIX__ || "Bearer"
    } ${token}`;
  }

  if (language) {
    defaultHeaders.headers["Accept-Language"] = requestConfig.useWildCard
      ? `${language}, *`
      : language;
  }
  // add Cache-Control header
  defaultHeaders.headers["Cache-Control"] = "no-cache";

  return merge({}, defaultHeaders, requestConfig);
}

export default class Api {
  /**
   * get
   * @param   {String} url
   * @returns {Promise}
   */
  static async get(url, requestConfig = {}, addAuthHeader = true) {
    const requestUrl = constructRequestUrl(url);
    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.get(requestUrl, headers);
  }

  /**
   * put
   * @param   {String} url
   * @param   {Object} data
   * @returns {Promise}
   */
  static async put(url, data, requestConfig = {}, addAuthHeader = true) {
    const requestUrl = constructRequestUrl(url);
    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.put(requestUrl, data, headers);
  }

  /**
   * patch
   * @param   {String} url
   * @param   {Object} data
   * @returns {Promise}
   */
  static async patch(url, data, requestConfig = {}, addAuthHeader = true) {
    const requestUrl = constructRequestUrl(url);
    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.patch(requestUrl, data, headers);
  }

  /**
   * post
   * @param   {String} url
   * @param   {Object} data
   * @returns {Promise}
   */
  static async post(url, data, requestConfig = {}, addAuthHeader = true) {
    const requestUrl = constructRequestUrl(url);
    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.post(requestUrl, data, headers);
  }

  /**
   * postMultiPart
   * @param  {String} url
   * @param  {Object} file
   * @param  {Object} requestConfig
   * @return {Promise}
   */
  static async postMultipart(
    url,
    file,
    requestConfig = {},
    addAuthHeader = true
  ) {
    const requestUrl = constructRequestUrl(url);
    const formData = new FormData();
    formData.append("file", file);

    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.post(requestUrl, formData, headers);
  }

  /**
   * delete
   * @param   {String} url
   * @returns {Promise}
   */
  static async delete(url, requestConfig = {}, addAuthHeader = true) {
    const requestUrl = constructRequestUrl(url);
    const headers = await _buildHeaders(requestConfig, addAuthHeader);
    return axios.delete(requestUrl, headers);
  }
}
