import React from "react";
import PropTypes from "prop-types";

import Checkbox from "components/_react/fields/checkbox/checkbox";
import Radio from "components/_react/fields/radio/radio";

/**
 * IsSelectedColumn
 * @param {Object} props
 */
export default function IsSelectedColumn(props) {
  const FieldToRender = props.canSelectMultiple ? Checkbox : Radio;

  return <FieldToRender isChecked={props.isChecked} id={props.id} />;
}

IsSelectedColumn.propTypes = {
  canSelectMultiple: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

IsSelectedColumn.defaultProps = {
  isChecked: false,
};
