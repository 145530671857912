import { createSelector } from "reselect";

const getUploadByEntityIdAndAccountUrl = createSelector(
  [
    (state) => state.ovpAssets,
    (state, entityId) => entityId,
    (state, entityId, accountUrl) => accountUrl,
  ],
  (uploads, entityId, accountUrl) => {
    if (!entityId) {
      return {};
    }
    return (
      Object.values(uploads).find(
        (upload) =>
          upload.entityId === entityId && upload.accountUrl === accountUrl
      ) || {}
    );
  }
);

export { getUploadByEntityIdAndAccountUrl };
