import * as EntityAction from "store/entity/entity.action";
import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./content-settings.html";
/**
 * @classdesc handles the logic for changing our content settings, for dimensions
 * and languages
 */
class ContentSettingsController {
  /**
   * @constructor
   * @param   {Object} $state
   * @param   {Object} $rootScope
   * @param   {Object} $q
   * @param   {Object} $document
   * @param   {Object} ReduxConnector
   * @param   {Object} ConfigurationFactory
   * @param   {Object} ContentSettingsService
   * @param   {Object} DefaultLanguageService
   * @param   {Object} DimensionsFactory
   * @param   {Object} EntityDimensionsService
   * @param   {Object} GlobalParamsService
   * @param   {Object} MessageService
   * @param   {Object} NotificationService
   * @param   {Object} PermissionsService
   * @param   {Object} ModalTriggerService
   * @param   {Object} UsersFactory
   * @param   {Object} _
   * @param   {Object} CONTENT_SETTINGS
   * @returns {void}
   */
  constructor(
    $state,
    $rootScope,
    $q,
    $document,
    ReduxConnector,
    ConfigurationFactory,
    ContentSettingsService,
    DefaultLanguageService,
    DimensionsFactory,
    EntityDimensionsService,
    GlobalParamsService,
    MessageService,
    NotificationService,
    PermissionsService,
    ModalTriggerService,
    UsersFactory,
    _,
    CONTENT_SETTINGS
  ) {
    this.$state = $state;
    this.$rootScope = $rootScope;
    this.$q = $q;
    this.$document = $document;
    this.ReduxConnector = ReduxConnector;
    this.ConfigurationFactory = ConfigurationFactory;
    this.ContentSettingsService = ContentSettingsService;
    this.DefaultLanguageService = DefaultLanguageService;
    this.DimensionsFactory = DimensionsFactory;
    this.EntityDimensionsService = EntityDimensionsService;
    this.GlobalParamsService = GlobalParamsService;
    this.NotificationService = NotificationService;
    this.MessageService = MessageService;
    this.PermissionsService = PermissionsService;
    this.ModalTriggerService = ModalTriggerService;
    this.UsersFactory = UsersFactory;
    this._ = _;
    this.CONTENT_SETTINGS = CONTENT_SETTINGS;

    this.dimensions = {};
    this.dimensionState = {};
    this.languages = [];
    this.totalDimensions = 0;
    this.dimensionsSelected = 0;
    this.isEditingDimensions = false;
    this.isEditingTranslations = false;

    this.ContentSettingsService.init();
    this.hasDefaultNotificationTriggered = false;

    this.connectToStore();
  }

  /**
   * $onInit
   * @returns  {void}
   */
  $onInit() {
    this._getDimensionsConfig();
    this._setSubscriptions();
  }

  /**
   * $onDestroy
   * @returns {void}
   */
  $onDestroy() {
    this._destroySubscriptions();
    this.disconnect();
  }

  /**
   * _destroySubscriptions
   * @returns {void}
   */
  _destroySubscriptions() {
    // Schedules subscriptions
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.MODAL.SAVE_DIMENSIONS
    );
    this.MessageService.unregisterChannel(
      this.CONTENT_SETTINGS.MODAL.SAVE_DIMENSIONS
    );
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_DIMENSIONS
    );
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.LOCAL_NAV_DIMENSIONS_REVERT
    );

    // Translations subscriptions
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE
    );
    this.MessageService.unregisterChannel(
      this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE
    );
    this.MessageService.unlockChannel(this.CONTENT_SETTINGS.LANGUAGE_SET);
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_TRANSLATIONS
    );
    this.MessageService.unlockChannel(
      this.CONTENT_SETTINGS.MODAL.SAVE_TRANSLATIONS
    );
  }

  /**
   * _setSubscriptions
   * @returns  {void}
   */
  _setSubscriptions() {
    // Schedules subscriptions
    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.MODAL.SAVE_DIMENSIONS,
      (channel, data) =>
        this._applyDimensionSettings(this._buildGlobalParams(data))
    );
    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.MODAL.SAVE_DIMENSIONS
    );

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_DIMENSIONS,
      () => (this.isEditingDimensions = !this.isEditingDimensions)
    );
    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_DIMENSIONS
    );

    this.MessageService.subscribe("skylark.destroyModal", () => {
      this.isEditingDimensions = false;
      this.isEditingTranslations = false;
    });

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.LOCAL_NAV_DIMENSIONS_REVERT,
      () =>
        this._applyDimensionSettings(
          this.GlobalParamsService.getPreviousGlobalParams()
        )
    );

    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.LOCAL_NAV_DIMENSIONS_REVERT
    );

    // Translations subscriptions
    this.MessageService.subscribe(this.CONTENT_SETTINGS.LANGUAGE_SET, () =>
      this._setInitialLanguage()
    );
    this.MessageService.lockChannel(this.CONTENT_SETTINGS.LANGUAGE_SET);

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_TRANSLATIONS,
      () => (this.isEditingTranslations = !this.isEditingTranslations)
    );
    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.MODAL.TOGGLE_TRANSLATIONS
    );

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.MODAL.SAVE_TRANSLATIONS,
      (channel, data) => this._handleLanguageChange(data.iso_code)
    );
    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.MODAL.SAVE_TRANSLATIONS
    );

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.GLOBAL_LANGUAGE_CHANGED,
      (channel, isoCode) => this._handleLanguageChange(isoCode)
    );

    this.MessageService.registerChannel(
      this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE,
      1
    );
    this.MessageService.lockChannel(
      this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE
    );
    this.MessageService.on(
      this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE,
      (channel, isoCode) => this._handleLanguageChange(isoCode)
    );

    this.MessageService.subscribe(
      this.CONTENT_SETTINGS.ENTITY_LANGUAGES_AVAILABLE,
      () => this._triggerUnavailableLanguageNotification(),
      1
    );
  }

  /**
   * Gets the config object for dimensions and checks config permissions
   * @returns  {void}
   */
  _getDimensionsConfig() {
    this.ConfigurationFactory.getEntityBaseConfiguration("dimensions")
      .then((config) => this._handleDimensionsConfig(config))
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * set local nav config to state
   * @param    {Object} config
   * @returns  {void}
   */
  _handleDimensionsConfig(config) {
    this.dimensionsConfig = config;

    this.UsersFactory.getCurrentUser()
      .then((user) => {
        const allowedDimensions = this._filterDimensionsByPermission(
          user,
          config.dimensions
        );
        this.$q
          .all(
            allowedDimensions.map((dimension) =>
              this.DimensionsFactory.getDimensionByType(dimension.endpoint)
            )
          )
          .then((dimensions) =>
            this._handleDimensionsResponse(dimensions, allowedDimensions)
          );
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * handle data returned from dimension request
   * @param    {Array} dimensionTypes
   * @param    {Array} allowedDimensions
   * @returns  {void}
   */
  _handleDimensionsResponse(dimensionTypes, allowedDimensions) {
    dimensionTypes.forEach((dimension, index) => {
      const dimensionName = allowedDimensions[index].name;
      this.dimensions[dimensionName] = {
        data: dimension.objects,
        total: dimension.objects.length,
      };
      this.totalDimensions += dimension.objects.length;
    });

    this._getCurrentDimensionSettings();
    this.dimensionsModalOptions = this._buildDimensionsModal();
  }

  /**
   * _filterDimensionsByPermission
   * @param    {Object} user
   * @param    {Array} dimensions
   * @returns  {Array}
   */
  _filterDimensionsByPermission(user, dimensions) {
    return dimensions.filter((dimension) =>
      this.PermissionsService.hasAccess(user, dimension.customer_field)
    );
  }

  /**
   * gets all allowed dimension types for interaction
   * @returns  {Array} array of dimension type names as strings
   */
  _getDimensionTypes() {
    return Object.keys(this.dimensions);
  }

  /**
   * _getCurrentDimensionSettings
   */
  _getCurrentDimensionSettings() {
    this.dimensionsSelected = 0;

    const globalParams = this.GlobalParamsService.getGlobalParams();

    this._getDimensionTypes().forEach((name) => {
      this.dimensionState[name] = globalParams[name]
        ? globalParams[name].split(",")
        : [];
      this.dimensionsSelected += this.dimensionState[name].length;

      this.dimensions[name] = this._.assign(this.dimensions[name], {
        selected: this.dimensionState[name]
          ? this.dimensionState[name].length
          : 0,
        dimensionNames: globalParams[name]
          ? this._getDimensionNames(name)
          : null,
      });
    });

    this.dimensionsCopy = this._buildDimensionsCopy();
  }

  /**
   * _areAllDimensionsSelected
   * @returns  {Boolean}
   */
  _areAllDimensionsSelected() {
    return (
      !this.dimensionsSelected ||
      this.totalDimensions === this.dimensionsSelected
    );
  }

  /**
   * _buildDimensionsCopy
   * @returns  {string} - string with filters copy
   */
  _buildDimensionsCopy() {
    return !this._areAllDimensionsSelected()
      ? this._extractFiltersText()
      : "All dimensions set";
  }

  /**
   * _getDimensionNames
   * @param   {String} name - dimensionName
   * @returns {String} dimension names
   */
  _getDimensionNames(name) {
    return this.dimensions[name].data
      .filter((dimensionItem) =>
        this.dimensionState[name].find((text) => dimensionItem.slug === text)
      )
      .map((item) => item.name)
      .join(", ");
  }

  /**
   * _setInitialLanguage
   * @returns  {void}
   */
  _setInitialLanguage() {
    const language = this.ContentSettingsService.getCurrentLanguage();

    if (!language) {
      return;
    }

    this._setLanguageParams(language);
    this.languages = this.ContentSettingsService.getLanguages();
    this.translationModalOptions = this._buildTranslationsModal();
    this._triggerUnavailableLanguageNotification();
  }

  /**
   * set language params for component
   * @param    {Object} language
   */
  _setLanguageParams(language) {
    this.currentLanguage = language;
    const isDefault = this.DefaultLanguageService.isDefault(
      this.currentLanguage.iso_code
    );
    this.languageString = `${this.currentLanguage.name}${
      isDefault ? " (default)" : ""
    }`;
  }

  /**
   * event handler for pub/sub, checks language from iso code
   * @param {string} isoCode
   * @private
   * @returns {void}
   */
  _handleLanguageChange(isoCode = this.GlobalParamsService.getLanguage()) {
    const language = this.languages.find(
      (language) => language.iso_code === isoCode
    );

    if (language) {
      this._setLanguageParams(language);
      this.GlobalParamsService.setLanguage(this.currentLanguage.iso_code);
      this._updateCreatingStatus(language);
    }
  }

  /**
   * is translation modal disabled
   * @return {Boolean}
   */
  isTranslationModalDisabled() {
    return (
      this.$rootScope.isEditMode ||
      this.isEditingTranslations ||
      this.languages.length <= 1
    );
  }

  /**
   * @returns {boolean}
   * @private
   */
  _isInfoPage() {
    const currentState = this.$state.current.name;

    return currentState.split(".")[1] === "information";
  }

  /**
   * Redirects the user to the info panel
   * @returns {*}
   * @private
   */
  _redirectToInfoState() {
    const currentState = this.$state.current.name;
    const infoState = `${currentState.split(".")[0]}.information`;

    this.$state.go(infoState);
  }

  /**
   * _updateCreatingStatus
   * @param   {object} language
   * @returns {void}
   */
  _updateCreatingStatus(language) {
    const isCreating =
      language &&
      !this.EntityDimensionsService.isLanguageAvailable(language.iso_code);

    if (isCreating) {
      this.store.createTranslation();
    } else {
      this.store.cancelCreation();
    }

    if (!this._isInfoPage() && isCreating) {
      this._redirectToInfoState();
    } else {
      this.$state.reload();
    }
  }

  /**
   * build modal options for dimensions modal
   * @returns  {void}
   */
  _buildDimensionsModal() {
    return {
      dimensions: this.dimensions,
      channels: {
        save: this.CONTENT_SETTINGS.MODAL.SAVE_DIMENSIONS,
        toggle: this.CONTENT_SETTINGS.MODAL.TOGGLE_DIMENSIONS,
      },
    };
  }

  /**
   * build modal options for dimensions modal
   * @returns  {void}
   */
  _buildTranslationsModal() {
    return {
      channels: {
        save: this.CONTENT_SETTINGS.MODAL.SAVE_TRANSLATIONS,
        toggle: this.CONTENT_SETTINGS.MODAL.TOGGLE_TRANSLATIONS,
      },
    };
  }

  /**
   * _isDefaultNotificationRequired
   * @returns {Boolean}
   */
  _isDefaultNotificationRequired() {
    return (
      this.currentLanguage &&
      !this.hasDefaultNotificationTriggered &&
      !this.EntityDimensionsService.isLanguageAvailable(
        this.currentLanguage.iso_code
      )
    );
  }

  /**
   * _triggerUnavailableLanguageNotification
   * @returns {void}
   */
  _triggerUnavailableLanguageNotification() {
    if (this._isDefaultNotificationRequired()) {
      this.hasDefaultNotificationTriggered = true;
      this.ModalTriggerService.triggerNotification({
        placeholders: {
          currentLanguage: this.currentLanguage.name,
        },
        notificationType: "noLanguageAvailable",
        channels: {
          confirm: this.CONTENT_SETTINGS.CONTENT_SETTINGS_CREATE_NEW_LANGUAGE,
        },
        redirect: true,
      });
    }
  }

  /**
   * extractFiltersText
   * @returns  {String} - string with names
   */
  _extractFiltersText() {
    return this._getDimensionTypes()
      .reduce((array, name) => {
        const allSelected =
          this.dimensions[name].total === this.dimensions[name].selected;
        const dimensionName = allSelected
          ? `All ${this._findField(name).display_name}`
          : this.dimensions[name].dimensionNames;

        return array.concat(dimensionName);
      }, [])
      .join(", ");
  }

  /**
   * findField
   * @param    {String} name
   * @returns  {Object|undefined}
   */
  _findField(name) {
    return this.dimensionsConfig.fields.find((field) => field.name === name);
  }

  /**
   * save dimension settings to global params and notify user
   */
  _applyDimensionSettings(globalParams) {
    this.GlobalParamsService.setGlobalParams(globalParams);
    this._getCurrentDimensionSettings();
    this.NotificationService.notifyInfo(
      NotificationMessage.scheduleUpdateSuccess
    );

    this.$state.reload();
  }

  /**
   * check if dimensions button should be disabled
   * @return {boolean}
   */
  isDimensionsModalDisabled() {
    return this.isEditingDimensions || this.$rootScope.isEditMode;
  }

  /**
   * build selected dimensions object to set to global params
   * @returns  {Object}
   */
  _buildGlobalParams(selectedDimensions) {
    if (this.totalDimensions !== selectedDimensions.amountSelected) {
      return this.dimensionsConfig.dimensions.reduce((acc, dimension) => {
        if (selectedDimensions[dimension.name].length) {
          return Object.assign(acc, {
            [dimension.name]: selectedDimensions[dimension.name].join(","),
          });
        }

        return acc;
      }, {});
    }

    return {};
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };
    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }
}

const ContentSettingsComponent = {
  restrict: "E",
  bindings: {
    hasTranslationSettings: "<",
    hasScheduleSettings: "<",
  },
  template,
  controller: ContentSettingsController,
  controllerAs: "component",
};

export default ContentSettingsComponent;
