import * as EntityAction from "store/entity/entity.action";
import * as EntityConfigAction from "store/entities-config/entities-config.action";
import * as AccountAction from "store/accounts/accounts.action";
import NotificationMessage from "skylarklib/constants/notification-text";
import * as EntitiesConfigSelector from "store/entities-config/entities-config.selector";
import { filterIngestedVersions } from "store/selectors/filter-ingested-versions";
import { filterAccountsWithUpload } from "store/selectors/filter-accounts-with-upload";
import template from "./entity-versions.html";

/**
 * The versions tab for entities
 * @class
 */
class EntityVersionsPageCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $q
   * @param {Object} ReduxConnector
   * @param {Object} EntityFactory
   * @param {Object} GlobalParamsService
   * @param {Object} EntityVersionsService
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} _
   */
  constructor(
    $scope,
    $q,
    ReduxConnector,
    EntityFactory,
    GlobalParamsService,
    EntityVersionsService,
    MessageService,
    NotificationService,
    _,
    OVP_ASSET_UPLOAD
  ) {
    this.$scope = $scope;
    this.$q = $q;
    this.ReduxConnector = ReduxConnector;
    this.EntityFactory = EntityFactory;
    this.GlobalParamsService = GlobalParamsService;
    this.EntityVersionsService = EntityVersionsService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this._ = _;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;

    this.onRefreshSchedule = this.onRefreshSchedule.bind(this);
    this.connectToStore();
    this._init();
  }

  /**
   * Init's the page
   * @returns {void}
   * @private
   */
  _init() {
    this.entityName = this.EntityFactory.getEntityName();
    this.entityId = this.EntityFactory.getEntityId();
    this.entityType = this.EntityFactory.getEntityType();

    this._getAccounts();
    this._loadConfig();
    this._loadEntityData();
    this._setSubscriptions();
  }

  /**
   * Set subscriptions to channels
   *
   * @returns {void}
   *
   */
  _setSubscriptions() {
    this.MessageService.subscribe(
      `Schedules.${this.entityId}.update`,
      this.onRefreshSchedule
    );
  }

  /**
   * remove subscriptions from channels
   * @returns {void}
   */
  _removeSubscriptions() {
    this.MessageService.off(
      `Schedules.${this.entityId}.update`,
      this.onRefreshSchedule
    );
  }

  /**
   * Loads the view's config
   */
  _loadConfig() {
    this.store.fetchVersionConfig(this.entityName);
  }

  /**
   * Loads the entity data for use by the view
   */
  _loadEntityData() {
    const filters = {
      ...this.GlobalParamsService.getDefaultParams(),
      fields_to_expand: "schedule_urls,asset_type_url,image_urls",
    };

    this.store.fetchAndAttachEntity(this.entityName, this.entityId, filters);
  }

  /**
   * loads all accounts
   */
  _getAccounts() {
    this.store.fetchAccounts();
  }

  /**
   * get a version from the ovps by passing account in
   * @param {Object} account
   * @param {Object} account.self
   * @return {Object|undefined} version
   */
  getVersionByAccount(account) {
    return this.EntityVersionsService.getVersionByAccount(this.data, account);
  }

  /**
   * reloading the ovps from entity, used as an callback when upload is complete
   */
  refreshOvpsAndImages() {
    const filters = {
      ...this.GlobalParamsService.getDefaultParams(),
      fields: "ovps,image_urls",
      fields_to_expand: "image_urls",
    };
    this.store.fetchAndAttachEntity(this.entityName, this.entityId, filters);
  }

  /**
   * refreshSchedule
   * @param {string} ch
   * @param {object} expandedScheduleUrls
   */
  onRefreshSchedule(ch, expandedScheduleUrls) {
    if (this._.isObject(this.data)) {
      this.data.schedule_urls = expandedScheduleUrls;
    }
  }

  /**
   * deletes the version from the asset
   * and updates the entity
   * @param {object} version - one object of ovps
   */
  onDeleteVersion(version) {
    return this.EntityVersionsService.deleteVersion(
      this.data.uid,
      this.data.ovps,
      version
    )
      .then((data) => {
        this.store.updateEntityWithData(data);
        this.NotificationService.notifyInfo(
          NotificationMessage.removeFileSuccess
        );
      })
      .catch((error) => {
        this.NotificationService.notifyError(
          NotificationMessage.removeFileError
        );

        return this.$q.reject(error);
      });
  }

  /**
   * Necessary solution to address
   * the &? problem which in this version
   * of angularjs it'll always return a fn
   * even if undefined. Gets fixed in 1.7.8
   * https://stackoverflow.com/a/32094934/1848833
   * @returns {Promise}
   */
  onBeforeUpload() {
    return this.$q.resolve();
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToStore = {
      ...EntityAction,
      ...AccountAction,
      ...EntityConfigAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToStore
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    const base = EntitiesConfigSelector.getBaseConfig(
      state,
      this.entityName,
      this.entityType
    );
    const version = EntitiesConfigSelector.getVersionConfig(
      state,
      this.entityName,
      this.entityType
    );

    return {
      data: state.entity.data,
      accounts: state.accounts.data,
      config: version.data,
      ovpAssetType:
        (base.data && base.data.ovp_asset_type) ||
        this.OVP_ASSET_UPLOAD.DEFAULT_OVP_ASSET_TYPE,
      accountsWithUpload: filterAccountsWithUpload(
        state,
        this.entityName,
        this.entityType
      ),
      ingestedVersions: filterIngestedVersions(
        state,
        this.entityName,
        this.entityType
      ),
    };
  }

  /**
   * destroy event of controller,
   * time to clean up listeners
   */
  $onDestroy() {
    this.disconnect();
    this._removeSubscriptions();
  }

  /**
   * Returns the account options from the config given the accountName
   * @param {*} accountName
   * @returns object
   */
  getAccountConfigOptions(accountName) {
    if (
      this.config &&
      this.config.accountsWithDirectUploadOptions &&
      this.config.accountsWithDirectUploadOptions.length > 0
    ) {
      const options = this.config.accountsWithDirectUploadOptions;
      const found = options.find((opt) => accountName === opt.account);
      return found;
    }
  }

  /**
   * Returns whether the account supports upload via URL, defaults to true if a setting isn't found
   * @param {*} accountName string
   * @returns boolean
   */
  hasUploadViaUrl(accountName) {
    const options = this.getAccountConfigOptions(accountName);
    return options &&
      Object.prototype.hasOwnProperty.call(options, "uploadViaUrl")
      ? options.uploadViaUrl
      : true;
  }
}

const entityVersionsPageComponent = {
  controller: EntityVersionsPageCtrl,
  controllerAs: "versions",
  template,
};

export default entityVersionsPageComponent;
