import angular from "angular";
import uiRouter from "@uirouter/angularjs";
import toaster from "angularjs-toaster";
import ngCookie from "angular-cookies";
import ngAnimate from "angular-animate";
import ngMessages from "angular-messages";
import $ngRedux from "ng-redux";

import ErrorInterceptorService from "./skylarklib/angularjs/services/error-interceptor/error-interceptor.service";
import LoadingService from "./skylarklib/angularjs/services/loading/loading.service";
import Lib from "./skylarklib/moduleconfig";
import Components from "./components/components.module";
import Pages from "./pages/moduleconfig";
import Router from "./router";
import Modules from "./modules/moduleconfig";
import Ui from "./ui/moduleconfig";
import Metadata from "./metadata";
import ReduxStore from "./store";
import * as Monitoring from "./skylarklib/helpers/monitoring";

Monitoring.init();

angular
  .module("Skylark", [
    uiRouter,
    toaster,
    $ngRedux,

    ngCookie,
    ngAnimate,
    ngMessages,

    Lib.name,
    Components.name,
    Pages.name,
    Router.name,
    Modules.name,
    Ui.name,
    Metadata.name,
  ])
  .run(run)
  .config(($animateProvider, $httpProvider, $ngReduxProvider) => {
    $httpProvider.interceptors.push(ErrorInterceptorService);
    $httpProvider.interceptors.push(LoadingService);
    $animateProvider.classNameFilter(/^(?:(?!ng-animate-disabled).)*$/);

    $ngReduxProvider.provideStore(ReduxStore);
  });

/**
 * Runs on skylark initialization
 * @param {object} AuthenticationService - Skylark.lib authentication service.
 * @param {object} DefaultLanguageService
 * @param {object} HistoryService - Skylark.lib's history service. Stores information about user journey
 * @param {object} NetworkService - Skylark.lib's network service.
 * @param {object} FeatureToggleService
 * @param {object} BookmarkService
 * @param {object} AnalyticsService
 * @param {object} InjectorFactory
 * @param {object} TransitionService
 * @returns {void}
 */
function run(
  AuthenticationService,
  DefaultLanguageService,
  HistoryService,
  NetworkService,
  FeatureToggleService,
  BookmarkService,
  AnalyticsService,
  InjectorFactory,
  TransitionService
) {
  TransitionService.init();
  InjectorFactory.init();
  BookmarkService.init();
  AuthenticationService.init();
  DefaultLanguageService.init();
  HistoryService.init();
  NetworkService.init();
  FeatureToggleService.init();
  AnalyticsService.startTrackNavigation();
}
