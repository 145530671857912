/**
 * @fileOverview  Constant for defining permission values.
 */
const PERMISSIONS_OPTIONS = [
  {
    name: "editability",
    display_name: "Editability",
    widget: "radio",
    options: [
      {
        id: "normal",
        value: "normal",
        display_name: "Normal",
        subtext: "Editable and deletable",
      },
      {
        id: "undeletable",
        value: "undeletable",
        display_name: "undeletable",
        subtext: "Editable and non-deletable",
      },
      {
        id: "read-only",
        value: "read-only",
        display_name: "Read-only",
        subtext: "Non-editable and non-deletable",
      },
    ],
  },
];

export default PERMISSIONS_OPTIONS;
