import _ from "lodash";

/**
 * SetsFactory
 *
 *
 */

/**
 * @classdesc Factory containing classes for working with set's
 * @param {Object} $q
 * @param {Object} $stateParams
 * @param {Object} ApiService
 * @param {Object} ApiRequestConfigFactory
 * @param {Object} BatchRequestFactory
 * @param {Object} APP_SETTINGS
 * @return {Object}
 * @constructor
 */
function SetsFactory(
  $q,
  $stateParams,
  ApiService,
  ApiRequestConfigFactory,
  BatchRequestFactory,
  ImagesFactory,
  APP_SETTINGS,
  FIELDS_TO_REQUEST
) {
  const factory = {};

  /**
   * Save scheduled items
   * @param {Object} item
   * @param {string} language
   * @private
   */
  function _saveScheduledItem(item) {
    Object.keys(item.scheduledItem).forEach((languageKey) => {
      BatchRequestFactory.createPutRequest(
        item.uid,
        item.self,
        item.scheduledItem[languageKey],
        ApiRequestConfigFactory.createRequestConfig({
          language: languageKey,
        })
      );
    });
  }

  /**
   * Save the content item if the item is only available in this set
   * @param item
   * @private
   */
  function _saveContentItem(item) {
    Object.keys(item.contentItem).forEach((languageKey) => {
      BatchRequestFactory.createPutRequest(
        item.contentItem[languageKey].uid,
        item.contentItem[languageKey].self,
        item.contentItem[languageKey],
        ApiRequestConfigFactory.createRequestConfig({
          language: languageKey,
        })
      );
    });
  }

  /**
   * getSetById - Get set by id
   * @param  {string} id Set id
   * @param  {filters} filters to be appended to call
   * @returns {Promise} deferred promise object
   */
  factory.getSetById = (id, filters) =>
    ApiService.get(
      `/api/cms-sets/${id}/`,
      ApiRequestConfigFactory.createRequestConfig({
        requestConfig: {
          params: filters,
        },
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get and return promise with set type data
   * @param {string} url
   * @returns {void}
   */
  factory.getSetByUrl = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get front-end preview of sets
   * @param   {String} id
   * @param   {Object} filters
   * @returns {Promise}
   */
  factory.getSetsPreview = () =>
    ApiService.get(
      `/api/sets/${$stateParams.id}/items/`,
      ApiRequestConfigFactory.createRequestConfig({
        requestConfig: {
          params: {
            fields_to_expand: "content_url",
            fields: FIELDS_TO_REQUEST.requiredFieldsForPreview.join(","),
          },
        },
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * add get items to batch
   * @param   {Array} scheduledItems
   */

  factory.addGETItemLanguagesToBatch = (items, fieldsRequired) => {
    const multilingualItems = items.filter(
      (item) => item.all_languages && item.all_languages.length > 1
    );

    const coreFields = FIELDS_TO_REQUEST.requiredFieldsForContent.join(",");
    const configFields = _.uniq(fieldsRequired.map((field) => field.name)).join(
      ","
    );

    multilingualItems.forEach((item) => {
      item.all_languages.forEach((language) => {
        if (item.language !== language) {
          BatchRequestFactory.createGetRequests(
            "languageVersion",
            [item.self],
            `?fields=${coreFields},${configFields}`,
            ApiRequestConfigFactory.createRequestConfig({
              language,
            })
          );
        }
      });
    });
  };

  /**
   * addGETSchedulesToBatch
   * Creates request to every schedule need in the set.
   * @param {Array} scheduledItems
   */
  factory.addGETSchedulesToBatch = (scheduledItems) => {
    const scheduleUrls = scheduledItems
      .map((item) => item.schedule_urls)
      .reduce(
        (allSchedules, itemSchedules) => allSchedules.concat(itemSchedules),
        []
      );
    BatchRequestFactory.createGetRequests("schedules", _.uniq(scheduleUrls));
    BatchRequestFactory.createGetRequests("alwaysSchedule", [
      APP_SETTINGS.ALWAYS_SCHEDULE_SLUG,
    ]);
  };

  /**
   * Get set type
   */
  factory.getSetType = () =>
    factory.getSetById($stateParams.id, {
      fields: ["set_type_slug"],
    });

  /**
   * Gets the set for the current page
   * @returns {void}
   */
  factory.getCurrentSet = (fieldsRequired) => {
    const coreFields = FIELDS_TO_REQUEST.requiredFieldsForSet.join(",");
    const configFields = fieldsRequired
      .map((field) => {
        if (field.widget === "imagewithmetadata") {
          const imageFieldFilter = field.fields.map(
            (imageField) => `items__image_urls__${imageField.name}`
          );

          return imageFieldFilter.join(",");
        }
        if (!field.field_location || field.field_location === "scheduledItem") {
          return `items__${field.name}`;
        }
        if (field.name) {
          return `items__content_url__${field.name}`;
        }
      })
      .join(",");

    const fieldsToRequest = `${coreFields},${configFields}`;

    return factory.getSetById($stateParams.id, {
      all: true,
      fields: fieldsToRequest,
      fields_to_expand: "items__content_url,items__image_urls",
    });
  };

  /**
   * Flatten the configs
   * @param {Object|Array} config
   * @return {Array}
   */
  factory.flattenConfig = (config) => {
    const flatConfig = [];
    Object.keys(config).forEach((category) =>
      flatConfig.push(config[category])
    );

    return flatConfig.reduce((a, b) => a.concat(b), []);
  };

  /**
   * Add post requests to the batch
   * @param {Object} items
   * @param {Object} endpoint
   */
  factory.addPOSTScheduledItemsToBatch = (items, endpoint) => {
    items.forEach((item) => {
      BatchRequestFactory.createPostRequest(
        item.uid,
        endpoint,
        item.scheduledItem[item.displayedLanguage],
        ApiRequestConfigFactory.createRequestConfig({
          language: item.displayedLanguage,
        })
      );

      if (item.config.unique) {
        _saveContentItem(item);
      }
    });
  };

  /**
   * Add put requests to the batch
   * @param {Object} items
   */
  factory.addPUTScheduledItemsToBatch = (items) => {
    items.forEach((item) => {
      _saveScheduledItem(item);
      if (item.config.unique) {
        _saveContentItem(item);
      }
    });
  };

  factory.addDELETEScheduledItemsToBatch = (itemUrls) => {
    itemUrls.forEach((url) => {
      BatchRequestFactory.createDeleteRequest(url);
    });
  };

  return factory;
}

export default SetsFactory;
