import TEXT_TRACK_UPLOAD from "./text-track-upload.constant";

/**
 * generic start of an upload, and puts in all data gathered so far
 * @param data
 * @return {{data: *, type: string}}
 */
const startUpload = (data) => ({
  type: TEXT_TRACK_UPLOAD.UPLOAD_STARTED,
  data,
});

/**
 * text track is created successfully
 * @param data
 * @return {{data: *, type: string}}
 */
const textTrackCreated = (data) => ({
  type: TEXT_TRACK_UPLOAD.TEXT_TRACK_CREATED,
  data,
});

/**
 * update text track
 * @param data
 * @return {{data: *, type: string}}
 */
const updateTextTrack = (data) => ({
  type: TEXT_TRACK_UPLOAD.TEXT_TRACK_UPDATE,
  data,
});

/**
 * document is created successfully
 * @param data
 * @return {{data: *, type: string}}
 */
const documentCreated = (data) => ({
  type: TEXT_TRACK_UPLOAD.DOCUMENT_CREATED,
  data,
});

/**
 * update progress of document upload
 * @param id
 * @param progress
 * @return {{data: {progress: *, id: *}, type: string}}
 */
const updateProgress = (id, progress) => ({
  type: TEXT_TRACK_UPLOAD.UPLOAD_PROGRESS,
  data: {
    id,
    progress,
  },
});

/**
 * started ingest to pelican
 * @param id
 * @return {{data: {id: *}, type: string}}
 */
const startIngest = (id) => ({
  type: TEXT_TRACK_UPLOAD.INGEST_STARTED,
  data: {
    id,
  },
});

/**
 * ingest finished successfully
 * @param id
 * @return {{data: {id: *}, type: string}}
 */
const completeUpload = (id) => ({
  type: TEXT_TRACK_UPLOAD.UPLOAD_COMPLETE,
  data: {
    id,
  },
});

/**
 * error during any step of the upload process
 * @param id
 * @return {{data: {id: *}, type: string}}
 */
const failedUpload = (id) => ({
  type: TEXT_TRACK_UPLOAD.UPLOAD_FAILED,
  data: {
    id,
  },
});

/**
 * remove from store by using the id
 * @param id
 * @return {{data: {id: *}, type: string}}
 */
const clearUpload = (id) => ({
  type: TEXT_TRACK_UPLOAD.REMOVE_UPLOAD_FROM_STORE,
  data: {
    id,
  },
});

/**
 * add upload for example when recovering the upload process
 * @param data
 * @return {{data: *, type: string}}
 */
const addUpload = (data) => ({
  type: TEXT_TRACK_UPLOAD.ADD_UPLOAD,
  data,
});

/**
 * no data update only the status changed
 * @param data
 * @return {{data: {id: *}, type: *}}
 */
const updateStatus = (data) => {
  if (
    !data ||
    !data.pelican ||
    !data.pelican.details ||
    !data.pelican.details.status
  ) {
    return;
  }
  return {
    type: data.pelican.details.status,
    data: {
      id: data.id,
    },
  };
};

/**
 * hide upload from modal
 * @param data
 * @return {{data: {id: *}, type: string}}
 */
const hideFromModal = (data) => ({
  type: TEXT_TRACK_UPLOAD.HIDE_FROM_MODAL,
  data: {
    id: data.id,
  },
});

export {
  startUpload,
  textTrackCreated,
  updateTextTrack,
  documentCreated,
  updateProgress,
  startIngest,
  completeUpload,
  failedUpload,
  clearUpload,
  addUpload,
  updateStatus,
  hideFromModal,
};
