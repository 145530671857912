import React from "react";
import {
  chakra,
  Box,
  Button as ChakraButton,
  ButtonProps,
  keyframes,
} from "@chakra-ui/react";
import { createIcon, Icon } from "@chakra-ui/icons";

const SpinnerIcon = createIcon({
  displayName: "SpinnerIcon",
  viewBox: "25 25 50 50",
  path: (
    <g fill="none">
      <circle
        className="path another"
        cx="50"
        cy="50"
        fill="none"
        r="20"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
      <circle
        className="path"
        cx="50"
        cy="50"
        fill="none"
        r="20"
        strokeMiterlimit="10"
        strokeWidth="5"
      />
    </g>
  ),
});

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -45px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

const shadowDash = keyframes`
  0% {
    stroke-dasharray: 1, 250;
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dasharray: 80, 200;
    stroke-dashoffset: -20px;
  }

  100% {
    stroke-dasharray: 80, 200;
    stroke-dashoffset: -124px;
  }
`;

const dashAnimation = "1.4s ease-in-out infinite";

interface ISpinnerProps {
  color?: string;
  shadowColor?: string;
}

const styles = {
  width: 4,
  cx: 50,
  cy: 50,
  r: 20,
  fill: "none",
  dasharray: "1, 200",
  dashoffset: 0,
  linecap: "round" as "round" | "inherit" | "butt" | "square",
  miterlimit: 10,
};

export const Spinner: React.FC<ISpinnerProps> = ({
  color = "white",
  shadowColor = "rgba(0,0,0,0.4)",
}) => (
  <Box
    _before={{ content: '""', display: "block", paddingTop: "100%" }}
    m="0 auto"
    pos="relative"
    w="20"
  >
    <Icon
      animation={`${rotate} 1.5s linear infinite`}
      bottom={0}
      data-testid="spinner-icon"
      h="100%"
      left={0}
      m="auto"
      pos="absolute"
      right={0}
      top={0}
      transformOrigin="center center"
      viewBox="25 25 50 50"
      w="100%"
    >
      <g fill="none">
        <chakra.circle
          animation={`${shadowDash} ${dashAnimation}`}
          cx={styles.cx}
          cy={styles.cy}
          fill={styles.fill}
          r={styles.r}
          stroke={shadowColor}
          strokeDasharray={styles.dasharray}
          strokeDashoffset={styles.dashoffset}
          strokeLinecap={styles.linecap}
          strokeMiterlimit={styles.miterlimit}
          strokeWidth={styles.width}
        />
        <chakra.circle
          animation={`${dash} ${dashAnimation}`}
          cx={styles.cx}
          cy={styles.cy}
          fill={styles.fill}
          r={styles.r}
          stroke={color}
          strokeDasharray={styles.dasharray}
          strokeDashoffset={styles.dashoffset}
          strokeLinecap={styles.linecap}
          strokeMiterlimit={styles.miterlimit}
          strokeWidth={styles.width}
        />
      </g>
    </Icon>
  </Box>
);

export default Spinner;
