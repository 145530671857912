const initialState = {
  isActionTriggered: false,
  actions: {
    confirm: {
      location: "",
    },
    alternative: {
      location: "",
    },
  },
};

/**
 * Modal notification reducer
 * @param   {Object} state - modals
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case "CONFIRM_MODAL_NOTIFICATION":
      return {
        ...state,
        isActionTriggered: true,
        actions: {
          ...state.actions,
          confirm: {
            location: action.modalType,
          },
        },
      };
    case "ALTERNATIVE_MODAL_NOTIFICATION":
      return {
        ...state,
        isActionTriggered: true,
        actions: {
          ...state.actions,
          alternative: {
            location: action.modalType,
          },
        },
      };
    case "RESET_MODAL_NOTIFICATION":
      return { ...state, ...initialState };
    default:
      return state;
  }
}
