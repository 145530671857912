import template from "./changelog.html";

/**
 * @classdesc changelog component
 * @memberof Components.Changelog
 * Renders the changelog trigger.
 */
class ChangelogCtrl {
  /**
   * @constructor
   */
  constructor(ConfigurationFactory) {
    this.ConfigurationFactory = ConfigurationFactory;
  }

  /**
   * $onInit hook
   */
  $onInit() {
    if (this.isSupported) {
      this._getConfig();
      this._getInfoConfiguration();
    } else {
      this.modalOptions = {};
    }
  }

  /**
   * build a modal options object
   */
  _buildModalOptions() {
    this.modalOptions = {
      defaultQueryParams: { object: `${this.data.self}` },
      endpoint: "/api/datalog/",
      type: "datalog",
      infoConfig: this.infoConfig,
      entityName: this.entityDisplayName,
    };
  }

  /**
   * _getConfig
   */
  _getConfig() {
    this.ConfigurationFactory.getEntityBaseConfiguration("datalog").then(
      (config) => {
        this.config = config;
      }
    );
  }

  /**
   * _getInfoConfiguration
   * @todo  make this a $q.all
   */
  _getInfoConfiguration() {
    if (this.entityName === "sets") {
      this.ConfigurationFactory.getInformationConfiguration(
        this.entityName,
        this.data.set_type_slug
      ).then((infoConfig) => {
        this._getSetContentsConfiguration(this.data.set_type_slug).then(
          (contentsConfig) => {
            this._assignInfoConfig(
              this._prepareSetConfig(contentsConfig, infoConfig)
            );
          }
        );
      });
    } else if (
      this.entityName === "articles" ||
      this.entityName === "cms-articles"
    ) {
      this.ConfigurationFactory.getInformationConfiguration(
        this.entityName,
        this.data.article_type_slug
      ).then((infoConfig) => {
        this._assignInfoConfig(infoConfig);
      });
    } else {
      this.ConfigurationFactory.getEntityConfiguration(this.entityName).then(
        (infoConfig) => {
          this._assignInfoConfig(infoConfig);
        }
      );
    }
  }

  /**
   * get any other configuration required to generate this changelog
   * @param   {string} setType
   * @returns {Promise}
   */
  _getSetContentsConfiguration(setType) {
    return this.ConfigurationFactory.getSetContentsConfigurationByType(setType);
  }

  /**
   * _prepareSetConfig
   * @param   {Object} contentsConfig
   * @param   {Object} infoConfig
   * @returns {Object}
   */
  _prepareSetConfig(contentsConfig, infoConfig) {
    infoConfig.scheduled_item_entities = [
      ...contentsConfig.content_types,
      ...contentsConfig.dynamic_object_types,
      ...contentsConfig.set_types,
    ].reduce((accumulator, current) => {
      accumulator[current.name] = current;

      return accumulator;
    }, {});

    return infoConfig;
  }

  /**
   * _assignInfoConfig
   * @param   {Object} infoConfig
   */
  _assignInfoConfig(infoConfig) {
    this.infoConfig = angular.copy(infoConfig);
    this._buildModalOptions();
  }
}

const ChangelogComponent = {
  bindings: {
    data: "=",
    entityName: "=",
    entityDisplayName: "=",
    isSupported: "=",
  },
  controller: ChangelogCtrl,
  controllerAs: "component",
  template,
};

export default ChangelogComponent;
