import template from "./time-slot.html";

/**
 * @fileOverview
 * TIme slot component.
 * Renders each time slot within a calendar view.
 */
class TimeSlotCtrl {
  /**
   * @constructor
   * @param {Object} momentJS
   * @param {Object} MessageService
   * @param {Object} CalendarGridService
   */
  constructor(momentJS, MessageService, CalendarGridService) {
    this.momentJS = momentJS;
    this.MessageService = MessageService;
    this.CalendarGridService = CalendarGridService;
  }

  /**
   * initialise
   */
  init() {
    this.isExpandable = undefined;
    this.isExpanded = false;
    this.runningTime = this._buildTimeStrings(this.data.viewData.duration);
    this.overlappingTime = this._buildTimeStrings(
      this.data.viewData.overlapDuration
    );
    this.overlappingMessage = this._buildTooltipMessages(
      this.data.viewData.isOverlapping
    );
    this._setViewStyles();
    this.isLive = this._isLive();
    this.isOverlapping = this._isOverlapping();
    this._setupSubscriptions();
  }

  /**
   * _setupSubscriptions
   * @private
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe("CalendarGrid.Minute.Update", () => {
      this.isLive = this._isLive();
    });
  }

  /**
   * _calculateHeight
   * @private
   * @param   {Object} timeslot
   * @returns {Number} height of timeslot container
   */
  _calculateHeight(timeslot) {
    const duration =
      parseInt(timeslot.viewData.duration) -
      this._calculateDurationTrimming(timeslot);

    return duration * this.CalendarGridService.getMinuteHeight();
  }

  /**
   * _calculateDurationTrimming
   * @private
   * @param   {object} timeslot
   * @returns {number} the offset for truncating the height
   */
  _calculateDurationTrimming(timeslot) {
    let offset = 0;
    if (timeslot.isFromDayBefore) {
      const differenceDay = this._calculateDifference(
        timeslot.announced_start,
        timeslot.announced_start
      );
      offset -= differenceDay + 1;
    }
    if (timeslot.isFromDayAfter) {
      const differenceDay = this._calculateDifference(
        timeslot.announced_start,
        timeslot.announced_end
      );
      offset -= differenceDay + 1;
    }

    return Math.abs(offset);
  }

  /**
   * _calculateDifference
   * @private
   * @param   {string} start - iso string
   * @param   {string} end - iso string
   * @returns {Number} minutes between the two dates
   */
  _calculateDifference(start, end) {
    return this.momentJS(start)
      .endOf("day")
      .diff(this.momentJS(end).endOf("minute"), "minutes");
  }

  /**
   * _calculateTopPosition
   * @private
   * @param   {string} slotStart in isoString format
   * @param   {boolean} isFromPreviousDay hether this slot start falls on a previous day
   * @returns {function}
   */
  _calculateTopPosition(slotStart, isFromPreviousDay) {
    return isFromPreviousDay
      ? 0
      : this.CalendarGridService.calculatePosition(this.momentJS(slotStart));
  }

  /**
   * _buildTimeStrings
   * @private
   * @param {Number} timeValue - a number representing a number of minutes
   * @returns {String} - display string for running time
   */
  _buildTimeStrings(timeValue) {
    const duration = this.momentJS.duration(timeValue, "minutes");

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    const daysString = days ? this._getDisplayString(days, "day") : "";
    const hoursString = hours ? this._getDisplayString(hours, "hour") : "";
    const minutesString = minutes
      ? this._getDisplayString(minutes, "minute")
      : "";

    return `${daysString} ${hoursString} ${minutesString}`.trim();
  }

  /**
   * _getDisplayString
   * @private
   * @param   {Number} value - timeValue
   * @param   {String} unitName time unit name (minutes or hours)
   * @returns {String} string in the required format, i.e. "10 minutes" or "1 hour"
   */
  _getDisplayString(value, unitName) {
    return value > 1 ? `${value} ${unitName}s` : `${value} ${unitName}`;
  }

  /**
   * _buildTooltipMessages
   * @private
   * @param   {object} overlaps
   * @returns {string}
   */
  _buildTooltipMessages(overlaps) {
    const overlapPreviousString = overlaps.overlapPrevious
      ? this._buildTooltipMessageString(overlaps.overlapPrevious, "previous")
      : "";
    const overlapNextString = overlaps.overlapNext
      ? this._buildTooltipMessageString(overlaps.overlapNext, "following")
      : "";

    return `${overlapPreviousString} ${overlapNextString}`.trim();
  }

  /**
   * _buildTooltipMessageString
   * @private
   * @param   {Number} overlapDuration
   * @param   {String} separator - previous or next
   * @returns {String} interpolated string
   */
  _buildTooltipMessageString(overlapDuration, separator) {
    const duration =
      overlapDuration > this.data.viewData.duration
        ? this.data.viewData.duration
        : overlapDuration;

    return `This slot clashes with the ${separator} programming by ${this._buildTimeStrings(
      duration
    )}.`;
  }

  /**
   * _setViewStyles
   * @private
   * @returns {void}
   */
  _setViewStyles() {
    this.elementHeight = this._calculateHeight(this.data);
    this.elementPositionTop = this._calculateTopPosition(
      this.data.announced_start,
      this.data.isFromDayBefore
    );
    this.data.viewData.duration =
      parseInt(this.data.viewData.duration) -
      this._calculateDurationTrimming(this.data);
    this.isShortDuration = this.data.viewData.duration < 5;
    this.isMediumDuration = this.data.viewData.duration < 10;
    this.isExpandable = this._isExpandable();
  }

  /**
   * _isExpandable
   * @returns {Boolean}
   */
  _isExpandable() {
    if (this._isOverlapping()) {
      return this.data.viewData.duration < 40;
    }

    return this.data.viewData.duration < 20;
  }

  /**
   * _isLive
   * @private
   * @returns {Boolean}
   */
  _isLive() {
    const currentDate = this.momentJS();
    const startDate = this.momentJS(this.data.announced_start);
    const endDate = this.momentJS(this.data.announced_end);

    return currentDate.isAfter(startDate) && currentDate.isBefore(endDate);
  }

  /**
   * _isOverlapping
   * @description  helper to avoid massive boolean checks in the DOM
   * @private
   * @returns {Boolean}
   */
  _isOverlapping() {
    return (
      this.data.viewData.isOverlapping.overlapPrevious ||
      this.data.viewData.isOverlapping.overlapNext
    );
  }

  /**
   * toggleExpand
   * @public
   * @callback {ng-mouseenter|ng-mouseleave}
   * @returns  {void}
   */
  toggleExpand() {
    this.isExpanded = !this.isExpanded;
  }

  /**
   * Used to call the props onClick and pass through this
   * @public
   * @returns {void}
   */
  slotClick() {
    this.onClick(this);
  }
}

/**
 * timeSlotDirective
 * @returns {Object} DDO
 */
function timeSlotDirective() {
  return {
    restrict: "E",
    scope: {},
    replace: true,
    bindToController: {
      data: "=",
      onClick: "&",
    },
    controller: TimeSlotCtrl,
    controllerAs: "component",
    template,
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default timeSlotDirective;
