/**
 * @fileOverfiew EntityDimensions
 * Keeps track of the available dimensions and languages for a given entity.
 */

class EntityDimensionsService {
  /**
   * @constructor
   * @property {Array} _availableLanguages
   */
  constructor() {
    this._availableLanguages = [];
  }

  /**
   * setAvailableLanguages
   * @param {Array} languages
   * @returns {void}
   */
  setAvailableLanguages(languages = []) {
    this._availableLanguages = languages;
  }

  /**
   * getAvailableLanguages
   * @returns {Array} availableLanguages
   */
  getAvailableLanguages() {
    return this._availableLanguages;
  }

  /**
   * isLanguageAvailable
   * @param {string} selectedLanguage - the currently selected language
   * @returns {Boolean} whether the current entity has languages
   */
  isLanguageAvailable(selectedLanguage) {
    if (!this._availableLanguages.length) {
      return true;
    }

    return !!this._availableLanguages.find(
      (language) => language === selectedLanguage
    );
  }

  /**
   * areDimensionsInvalid
   * @param {Array} currentScheduleStatuses - list of schedules with their validity status
   * @returns {Boolean}
   */
  areDimensionsInvalid(currentScheduleStatuses = []) {
    if (!currentScheduleStatuses.length) {
      return false;
    }

    return currentScheduleStatuses.every(
      (schedule) => schedule.status === "no_match"
    );
  }

  /**
   * reset - this is called on route change
   * @returns {void}
   */
  reset() {
    this._availableLanguages.length = 0;
  }
}

export default EntityDimensionsService;
