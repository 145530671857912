/**
 *  ModalFactory
 */

/**
 * @param {Object} _
 * @constructor
 */
function ModalFactory(_) {
  const factory = {};

  /**
   * toggleModalOverflow
   * @description - Adds overflow visible property to the modal body when the
   * modal it hasn't reached the maximum height (max-height: calc(100vh - 228px)).
   * @returns {void}
   */
  factory.toggleModalOverflow = () => {
    const windowHeight = window.innerHeight;
    const modalBlockBodySection = $(".modal-block__body-section");
    const maxModalBodyHeight = windowHeight - 228;
    const offsetHeight = _.get(modalBlockBodySection, "[0].offsetHeight");
    const className = "modal-block__body-section--overflow-visible";

    if (offsetHeight && maxModalBodyHeight > offsetHeight) {
      modalBlockBodySection.addClass(className);
    } else {
      modalBlockBodySection.removeClass(className);
    }
  };

  return factory;
}

export default ModalFactory;
