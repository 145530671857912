/**
 * @fileOverview Dynamic Object Contents Factory
 * Stateful factory for dynamic object contents
 *
 */

/**
 * DynamicObjectContentsFactory
 * @param {Object} ApiService
 * @param {Object} BatchRequestFactory
 * @param {Object} EntityTypeHelpersService
 * @param {Object} ApiRequestConfigFactory
 * @param {Object} $q
 */
function DynamicObjectContentsFactory(
  ApiService,
  BatchRequestFactory,
  EntityTypeHelpersService,
  ApiRequestConfigFactory,
  $q
) {
  const factory = {};

  /**
   * set items here is the full list of set items
   * including contents of dynamic objects, as single entities
   * @memberOf  DynamicObjectFactory
   * @type {Array}
   */
  factory.setItems = [];

  /**
   * populateData - populate data store
   * @access public
   * @param   {string} setUid - the uid of the set in the view
   * @returns {Promise}
   */
  factory.populateData = (setUid) =>
    factory._getSetItems(setUid).then((items) =>
      factory._getContentEntityData(items.objects).then((data) => {
        factory.setItems = data;
      })
    );

  /**
   * getContents
   * @access public
   * @param   {string} setUid
   * @param   {string} dynamicObjectUid
   * @returns {Promise}
   */
  factory.getContents = (dynamicObjectUid) =>
    factory._filterByParent(dynamicObjectUid);

  /**
   * get set items from front-end endpoint
   * @param   {string} setUid
   * @returns {Promise}
   */
  factory._getSetItems = (setUid) =>
    ApiService.get(
      `/api/sets/${setUid}/items/`,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * get filtered contents for a dynamic object, based on its uid
   * @access private
   * @param {String} dynamicObjectUid - the uid for the dynamic object that we want to generate
   * @param {Array} data - array of scheduled item objects
   * @returns {Array}
   */
  factory._filterByParent = (dynamicObjectUid) =>
    factory.setItems.filter((content) => content.uid === dynamicObjectUid);

  /**
   * getContentEntityData
   * @access private
   * @param {Array} items
   * @returns {Promise}
   */
  factory._getContentEntityData = (items) => {
    const deferred = $q.defer();
    factory._buildRequests(items);

    BatchRequestFactory.process().then((data) => {
      items.forEach((item, index) => {
        const currentData = JSON.parse(data[index].body);
        item.viewData = factory._buildViewData(currentData, index);
      });
      deferred.resolve(items);
    });

    return deferred.promise;
  };

  /**
   * create requests for BatchRequestFactory
   * @access private
   * @param {array} items - list of items
   * @returns {void}
   */
  factory._buildRequests = (items) => {
    const urls = items.map((item) => item.content_url);
    BatchRequestFactory.createGetRequests(
      "set-items",
      urls,
      "?fields=self,title,uid"
    );
  };

  /**
   * build data to use in the UI
   * @param   {Object} dynamic object content item
   * @returns {Object} object with view properties
   */
  factory._buildViewData = (item) => {
    const viewData = {
      title: item.title,
      type: EntityTypeHelpersService.getSingularType(item.self),
      uid: item.uid,
    };

    return viewData;
  };

  /**
   * reset factory data
   * @access public
   * @returns {void}
   */
  factory.reset = () => {
    factory.setItems.length = 0;
  };

  return factory;
}

export default DynamicObjectContentsFactory;
