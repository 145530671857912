import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SkylarkApiIcon = createIcon({
  displayName: "SkylarkApiIcon",
  viewBox: "0 0 34 44",
  path: (
    <g fill="none">
      <path
        clipRule="evenodd"
        d="M23 2H3C2.44772 2 2 2.44772 2 3V41C2 41.5523 2.44772 42 3 42H31C31.5523 42 32 41.5523 32 41V11H26C24.3431 11 23 9.65685 23 8V2ZM3 0H24.5L34 9.5V41C34 42.6569 32.6569 44 31 44H3C1.34315 44 0 42.6569 0 41V3C0 1.34315 1.34315 0 3 0ZM11.3713 16.5731C11.9144 13.962 14.2284 12 17.0007 12C19.7731 12 22.0872 13.9621 22.6302 16.5733C25.111 16.9918 27.0007 19.1502 27.0007 21.75C27.0007 24.6495 24.6502 27 21.7507 27H19.5007V25H21.7507C23.5456 25 25.0007 23.5449 25.0007 21.75C25.0007 19.9551 23.5456 18.5 21.7507 18.5H20.7507V17.75C20.7507 15.6789 19.0718 14 17.0007 14C15.319 14 13.8959 15.107 13.4202 16.632C14.5621 16.8923 15.6243 17.5344 16.3982 18.532L14.818 19.7579C14.1821 18.9382 13.2316 18.5062 12.2688 18.5H12.2507C10.8423 18.5 9.64311 19.3959 9.19198 20.6489C9.12849 20.8258 9.08056 21.0075 9.04848 21.1918C9.01709 21.3731 9.00072 21.5597 9.00072 21.75C9.00072 23.5449 10.4558 25 12.2507 25H16H16.0007C17.105 25.0004 18 25.8957 18 27V30H20.5V32H13.5V30H16V27H12.2507C10.5639 27 9.06284 26.2044 8.10234 24.9681L8.10216 24.9682C7.22002 23.8312 6.87827 22.4461 7.03791 21.1219C7.09602 20.6345 7.22091 20.1676 7.40317 19.7306C7.74073 18.9183 8.28735 18.1798 9.03206 17.6021C9.03757 17.5978 9.04308 17.5935 9.0486 17.5893C9.29187 17.4018 9.55204 17.2351 9.82643 17.092C10.3193 16.8348 10.8408 16.6627 11.3713 16.5731ZM27 30H22.5V32H25C26.1046 32 27 31.1046 27 30ZM7 32C7 30.8954 7.89543 30 9 30H11.5V32H7Z"
        fill="#0E1825"
        fillRule="evenodd"
      />
    </g>
  ),
});
