/**
 * actions constants
 */
export default {
  TOGGLE_EDITING: "TOGGLE_EDITING",
  TOGGLE_HAS_CHANGED: "TOGGLE_HAS_CHANGED",
  RESET_HAS_CHANGED: "RESET_HAS_CHANGED",
  TRIGGER_UNSAVED_CHANGES: "TRIGGER_UNSAVED_CHANGES",
  RESET_UNSAVED_CHANGES: "RESET_UNSAVED_CHANGES",
};
