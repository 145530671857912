import template from "./picture-view.html";

/**
 *	@fileOverview - picture directive for images in the CMS
 *	@description - renders images with the image resizer.
 */
class PictureViewCtrl {
  /**
   * PictureViewCtrl
   * @param {object} ImagesFactory
   * @param {object} NotificationService
   */
  constructor(ImagesFactory, NotificationService) {
    this.ImagesFactory = ImagesFactory;
    this.NotificationService = NotificationService;
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this.imageSize = this.imageSize ? this.imageSize : "300x300";
    if (this.url && this.isAPIUrl(this.url)) {
      this._getResource();
    } else if (this.url) {
      this.imageUrl = this._generateUrl(this.url, this.imageSize);
    }
  }

  /**
   * _getResource
   * gets the image
   * @returns {void}
   */
  _getResource() {
    this.ImagesFactory.get(this.url)
      .then((data) => {
        if (data && data.url) {
          this.imageUrl = this._generateUrl(data.url, this.imageSize);
        }
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * _generateUrl
   * @param {string} url - image url string
   * @param {string} size - image size string
   * @returns {string} image url with size
   */
  _generateUrl(url, size) {
    const lastIndex = url.lastIndexOf(".");
    const extension = url.substring(lastIndex);
    const filePath = url.substring(0, lastIndex);

    return `${filePath}-${size}${extension}`;
  }

  /**
   * returns whether this is an API URL or a media URL
   * @param   {string} url
   * @returns {Boolean} whether this is an API URL or a media URL
   */
  isAPIUrl(url) {
    const imageRegex = new RegExp(/\/api\/images\//);

    return imageRegex.test(url);
  }
}

/**
 * pictureDirective
 * @returns {Object} Directive Definition Object
 */
function pictureDirective() {
  return {
    restrict: "A",
    bindToController: {
      url: "=",
      imageSize: "@",
      enablePlayback: "<",
    },
    scope: {},
    template,
    controller: PictureViewCtrl,
    controllerAs: "component",
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default pictureDirective;
