/**
 *  Schedules Factory
 *
 *
 * @description  Contains factory methods to work with the schedules endpoints
 *
 */

/**
 *  SchedulesFactory
 *  @param {Object} $q - angular $q service
 *  @param {Object} ApiService - lower-level service to handle API calls using $http
 *  @param {Object} ApiRequestConfigFactory
 *  @param {Object} APP_SETTINGS
 *  @returns {object} - factory object
 *
 */
function SchedulesFactory(
  $q,
  ApiService,
  ApiRequestConfigFactory,
  APP_SETTINGS
) {
  const factory = {};

  /**
   * @return {promise} -
   */
  factory.getAlwaysSchedule = () =>
    ApiService.get(APP_SETTINGS.ALWAYS_SCHEDULE_SLUG);

  /**
   *
   * getSchedulesByFilterParams
   * @param {string} filters - string for querying the API
   *
   * @return {Promise} - deferred promise
   *
   */
  factory.getSchedulesByFilterParams = (filters) =>
    ApiService.get(
      "/api/schedules/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig: { params: filters },
      })
    );

  /**
   * getSchedule
   * @param {string} url - single schedule url
   * @return {Promise} - deferred promise
   *
   * @todo assess if we need this general getSchedule...
   *
   */
  factory.getSchedule = (url) => ApiService.get(url);

  /**
   * getScheduleById - Get schedule by id
   *
   * @param  {String} id - Schedule id
   * @return {Promise} - deferred promise
   *
   */
  factory.getScheduleById = (id) =>
    ApiService.get(
      `/api/schedules/${id}/`,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * getSchedulesByParent
   *
   * @param {array} array of schedule urls owned by a parent entity
   * @returns {promise} - deferred promise
   *
   */
  factory.getSchedules = (urls) => {
    if (!Array.isArray(urls)) {
      return $q.reject();
    }

    const requests = urls.map((url) => {
      if (typeof url === "string") {
        return ApiService.get(
          url,
          ApiRequestConfigFactory.createRequestConfig({
            useGlobalParams: true,
            overrideGlobalLanguage: true,
            useWildCard: true,
          })
        );
      }

      if (typeof url === "object") {
        return url;
      }
    });

    return $q.all(requests);
  };

  /**
   * update
   * @param {string} url - url of schedule
   * @param {object} object with data to replace
   *
   * @returns {Promise} - deferred promise
   */
  factory.create = (data) =>
    ApiService.post(
      "/api/schedules/",
      data,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    );

  /**
   *
   * @param url
   * @param data
   */
  factory.update = (url, data) =>
    ApiService.put(
      url,
      data,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    );

  /**
   *
   * delete schedule
   * @param { string } url - api endpoint for delete
   * @return { Promise } - deferred promise
   *
   */
  factory.delete = (url) => ApiService.delete(url);

  /**
   * filtering schedule by rights flag,
   * use when require only licences or schedules
   * @param {Array} scheduleUrls
   * @param {Boolean} isRights
   * @return {Array|Null}
   */
  factory.filterScheduleUrlsByRights = (scheduleUrls, isRights) => {
    if (Array.isArray(scheduleUrls)) {
      return scheduleUrls.filter(
        (schedule) =>
          schedule.rights === isRights || typeof schedule === "string"
      );
    }
  };

  /**
   * will return if schedule_urls are expanded or not
   * @param {Array} scheduleUrls
   * @return {Boolean|Null}
   */
  factory.isScheduleUrlsExpanded = (scheduleUrls) => {
    if (Array.isArray(scheduleUrls)) {
      return scheduleUrls.every((schedule) => typeof schedule !== "string");
    }
  };

  return factory;
}

export default SchedulesFactory;
