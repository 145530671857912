import { createListingReducer } from "./entity-listing/entity-listing.reducer";
import {
  ENTITY_LISTING_NAMESPACE,
  NONE_TYPE,
  RESET_SEARCH_FILTERS,
} from "./entity-lisitng-page.constant";
import { EntityListingInterface } from "./entity-listing-page.types";

export const EntityListingSubReducer = createListingReducer(
  ENTITY_LISTING_NAMESPACE
);

/**
 * the state will hold multiple listings, each listing is separated by a key which is made up out of the entity name
 */
const EntityListingReducer = (state = {}, action): EntityListingInterface => {
  const { type, entityName, entityType = NONE_TYPE } = action;

  if (type.startsWith(ENTITY_LISTING_NAMESPACE) && entityName) {
    const currentConfig = state[entityName] && state[entityName][entityType];

    return {
      ...state,
      [entityName]: {
        ...state[entityName],
        [entityType]: EntityListingSubReducer(currentConfig, action),
      },
    };
  }

  if (type === RESET_SEARCH_FILTERS) {
    return {};
  }

  return state;
};

export default EntityListingReducer;
