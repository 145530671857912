import ReduceReducers from "reduce-reducers";
import SearchConstant from "./search.constant";
import { createApiReducer } from "../api/api.reducer";
import ApiConstant from "../api/api.constant";

/**
 * @type {object} state
 *
 */
const initialState = {
  searchQuery: "",
  data: {
    results: [],
    matches: null,
  },
  /**
   * @typedef {object} selectedFilter
   * @property {string|null} label - the label used by the dropdown to show an entry.
   * @property {string} value - the value that needs to be used
   * @property {string} configName - the field used to retrieve the actual configuration of the object
   *
   * examples:
   * {
   *  label: 'Seasons',
   *  configName: 'seasons',
   *  value: 'seasons',
   * }
   *{
   *  label: 'Playlists',
   *  configName: 'sets',
   *  value: 'playlists',
   * }
   */
  selectedFilter: {},
  /** @property {boolean} resultsVisible - whether the search results container is visible or not */
  resultsVisible: false,
};

const fetch = createApiReducer(initialState, SearchConstant.FETCH_SEARCH);

/**
 * @param state
 * @param {string} type - action type
 *
 * @typedef {object} payload
 * @property {object} selectedFilter
 * @property {object} activeFiltersParams
 * @property {string} activeFiltersParams.entityName
 * @property {string} activeFiltersParams.entityType
 * @property {string} activeFiltersParams.searchQuery
 * @param {payload} payload - action payload
 */
const enhanceReducer = (
  state = initialState,
  { response, selectedFilter, resultsVisible, searchQuery, type }
) => {
  switch (type) {
    case SearchConstant.SET_SEARCH_QUERY: {
      if (searchQuery === state.searchQuery) {
        return state;
      }

      return {
        ...state,
        searchQuery,
        resultsVisible: !!searchQuery,
        data: { ...initialState.data },
      };
    }
    case SearchConstant.SET_SELECTED_FILTER:
      return {
        ...state,
        selectedFilter,
        resultsVisible: !!state.searchQuery,
        data: { ...initialState.data },
      };
    case SearchConstant.SET_RESULTS_VISIBILITY:
      return {
        ...state,
        resultsVisible,
      };
    case ApiConstant.getSuccessType(SearchConstant.FETCH_SEARCH): {
      const { objects } = response;

      return {
        ...state,
        data: { results: objects, matches: objects.length },
      };
    }
    default:
      return state;
  }
};

export default ReduceReducers(initialState, fetch, enhanceReducer);
