import template from "./modal-performance-details.html";

class ModalPerformanceDetailsCtrl {
  constructor(SetContentsStore, SetContentsDispatcher, SET_CONTENTS_ACTIONS) {
    this.SetContentsStore = SetContentsStore;
    this.SetContentsDispatcher = SetContentsDispatcher;
    this.SET_CONTENTS_ACTIONS = SET_CONTENTS_ACTIONS;
  }

  $onInit() {
    this.selected = "components";
    this.items = this.data.items;

    this.getPlacedItems();

    this.SetContentsDispatcher.register((payload) => {
      switch (payload.actionType) {
        case this.SET_CONTENTS_ACTIONS.unsetSavingState:
          this.getPlacedItems();
          break;

        default:
          break;
      }
    });
  }

  getPlacedItems() {
    const { placedItems } = this.SetContentsStore.getState();
    this.placedItems = placedItems;
  }

  switchTab(which) {
    this.selected = which;
  }

  closeModal() {
    this.destroyModal();
  }
}

const modalPerformanceDetailsDirective = {
  controller: ModalPerformanceDetailsCtrl,
  bindings: {
    data: "<",
    options: "<",
    instanceUid: "@",
    destroyModal: "&",
  },
  controllerAs: "modal",
  template,
};

export default modalPerformanceDetailsDirective;
