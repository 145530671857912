import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

import Image from "components/_react/image/image";

import styles from "./image-column.scss";

/**
 * ImageColumn - Main column heading / title / name
 * @param {Object} props
 */
export default class ImageColumn extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    imageUrls: PropTypes.arrayOf(Object),
    size: PropTypes.string,
    isFaded: PropTypes.bool.isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    imageUrls: undefined,
    size: "300x300",
  };

  /**
   * buildUrl
   * @return {String} image url with defined.
   */
  buildUrl() {
    const { url } = this.props.imageUrls[0];

    const lastIndex = url.lastIndexOf(".");
    const extension = url.substring(lastIndex);
    const filePath = url.substring(0, lastIndex);

    return `${filePath}-${this.props.size}${extension}`;
  }

  /**
   * render
   * @return {JSX}
   */
  render() {
    if (this.props.imageUrls.length && this.props.imageUrls[0].url) {
      return (
        <div className={styles["image-column"]}>
          <Image
            src={this.buildUrl()}
            classes={styles["image-column__image"]}
          />
        </div>
      );
    }

    return (
      <div
        className={classnames(
          styles["image-column"],
          styles["image-column--no-image"],
          {
            [styles["image-column--is-faded"]]: this.props.isFaded,
          }
        )}
      />
    );
  }
}
