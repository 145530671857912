import React from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";

import styles from "./modal-overlay.scss";

/**
 * ModalOverlay
 * @param {Object} props
 */
export default function ModalOverlay(props) {
  return (
    <CSSTransition
      timeout={200}
      in={props.shouldDisplay}
      unmountOnExit
      classNames={{
        enter: styles["modal-overlay__enter"],
        enterActive: styles["modal-overlay__enter-active"],
        enterDone: styles["modal-overlay__entered"],
        exit: styles["modal-overlay__exit"],
        exitActive: styles["modal-overlay__exit-active"],
      }}
    >
      <div className={styles["modal-overlay"]} />
    </CSSTransition>
  );
}

/**
 * propTypes
 * @type {Object}
 */
ModalOverlay.propTypes = {
  shouldDisplay: PropTypes.bool.isRequired,
};
