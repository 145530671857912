import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./article-publish.html";

/**
 * @classdesc
 * Toggle published status of an article.
 * As this is a button, only allows for switching between draft and publish.
 * If a status is blank, the preceding status **should** be publish.
 */
class ArticlePublishController {
  /**
   * @constructor
   */
  constructor(ArticleStatusService, MessageService, NotificationService) {
    this.ArticleStatusService = ArticleStatusService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
  }

  /**
   * $onInit ng lifecycle hook
   */
  $onInit() {
    this._getArticleStatus();
  }

  /**
   * @callback {ng-click}
   */
  saveStatus() {
    const newStatus = this.isPublished ? "Draft" : "Published";
    this.isSaving = true;
    this.ArticleStatusService.updateStatus(newStatus, this.articleId).then(
      (data) => {
        this.statusUrl = data.status_url;
        this._getArticleStatus();
        this._showNotification();
        this._notifyDependents();
      }
    );
  }

  /**
   * _getArticleStatus
   * @returns {void}
   */
  _getArticleStatus() {
    this.ArticleStatusService.getStatusName(this.statusUrl).then((name) => {
      this.currentStatus = name;
      this._setPublishedStatus(name);
      this.isSaving = false;
    });
  }

  /**
   * set is published to a boolean
   */
  _setPublishedStatus(name) {
    this.isPublished = name === "Published";
  }

  /**
   * throw toaster notification
   */
  _showNotification() {
    const action = this.isPublished ? "unpublished" : "published";
    this.NotificationService.notifyInfo(
      NotificationMessage.saveArticleSuccess(action)
    );
  }

  /**
   * notifyDependents
   * @returns {void}
   */
  _notifyDependents() {
    this.MessageService.publish(`${this.articleId}.dataChannel`, {
      status_url: this.statusUrl,
    });
  }
}

const ArticlePublishComponent = {
  bindings: {
    statusUrl: "=",
    articleId: "<",
    isDisabled: "<",
  },
  controller: ArticlePublishController,
  controllerAs: "component",
  template,
};

export default ArticlePublishComponent;
