import angular from "angular";
import tagUpdateDirective from "./tag-update.directive";
import tagsDirective from "./tags-module.directive";

const Tags = angular
  .module("Skylark.Modules.Tags", [])
  .directive("tagUpdate", tagUpdateDirective)
  .directive("tags", tagsDirective);

export default Tags;
