import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { getVisibleSelectionRect, RichUtils } from "draft-js";

import * as LinkHelpers from "components/_react/editor/helpers/link-helpers/link-helpers";
import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

import LinkInput from "components/_react/editor/links/link-input/link-input";
import LinkPreview from "components/_react/editor/links/link-preview/link-preview";

import TooltipComposer from "components/_react/tooltip/tooltip";

const LinkInputPrompt = TooltipComposer(LinkInput);
const LinkPreviewPrompt = TooltipComposer(LinkPreview);

export default class LinkTooltipWrapper extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    isPrompting: PropTypes.bool.isRequired,
    handleEditorChange: PropTypes.func.isRequired,
    parentElement: PropTypes.shape({
      getBoundingClientRect: PropTypes.func,
    }).isRequired,
    editor: PropTypes.shape({
      editorState: PropTypes.object,
    }).isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.toggleLinkInput = this.toggleLinkInput.bind(this);
    this.hideLinkPreview = this.hideLinkPreview.bind(this);
    this.editLink = this.editLink.bind(this);
    this.removeLink = this.removeLink.bind(this);
    this.createLink = this.createLink.bind(this);
    this.updateLink = this.updateLink.bind(this);

    this.state = {
      isInputVisible: false,
      isPreviewVisible: false,
    };
  }

  /**
   * componentWillReceiveProps
   * @param   {Object} props
   */
  componentWillReceiveProps(nextProps) {
    const oldState = this.props.editor.editorState;
    const newState = nextProps.editor.editorState;

    if (
      newState !== oldState &&
      !this.state.isInputVisible &&
      DraftHelpers.hasSelectionChanged(oldState, newState)
    ) {
      this.toggleLinkPreview(newState);
    }

    if (nextProps.isPrompting !== this.props.isPrompting) {
      this.toggleLinkInput();
    }
  }

  /**
   * toggleLinkPreview
   */
  toggleLinkPreview(editorState) {
    this.currentLinkValue = LinkHelpers.getLinkValue(editorState);

    if (this.currentLinkValue) {
      setTimeout(() => {
        this.showLinkPreview();
      }, 0);
    } else if (!this.currentLinkValue) {
      this.hideLinkPreview();
    }
  }

  /**
   * toggleLinkPrompt description
   * @param   {Object} position
   */
  toggleLinkInput(position) {
    if (!this.state.isInputVisible) {
      const selectionPosition = this._getSelectionPosition();
      this.inputPosition = position || this._getPosition(selectionPosition);
      this.setState({ isInputVisible: true });

      if (!this.currentLinkValue) {
        this.props.handleEditorChange(
          RichUtils.toggleInlineStyle(this.props.editor.editorState, "SELECTED")
        );
        this._toggleFakeSelection(true);
      }
    } else if (this.state.isInputVisible) {
      this._toggleFakeSelection(false);

      this.setState({ isInputVisible: false });

      setTimeout(() => {
        const inlineStyles = this.props.editor.editorState
          .getCurrentInlineStyle()
          .toJS();
        if (inlineStyles.includes("SELECTED")) {
          this.props.handleEditorChange(
            RichUtils.toggleInlineStyle(
              this.props.editor.editorState,
              "SELECTED"
            )
          );
        }
      }, 0);
    }
  }

  /**
   * showLinkPreview
   */
  showLinkPreview() {
    const selectionPosition = this._getSelectionPosition();
    const inputPosition = this._getPosition(selectionPosition);
    if (inputPosition && inputPosition.top > 0) {
      this.inputPosition = inputPosition;
      this.setState({ isPreviewVisible: true });
    }
  }

  /**
   * hideLinkPreview
   */
  hideLinkPreview() {
    this.setState({ isPreviewVisible: false });
  }

  /**
   * editLink
   */
  editLink() {
    this.hideLinkPreview();
    this.toggleLinkInput(this.inputPosition);
  }

  /**
   * updateLink
   * @param   {String} value
   */
  updateLink(value) {
    this.props.handleEditorChange(
      LinkHelpers.updateLinkEntity(this.props.editor.editorState, value)
    );
  }

  /**
   * removeLink
   */
  removeLink() {
    this.props.handleEditorChange(
      LinkHelpers.removeLinkEntity(this.props.editor.editorState)
    );
  }

  /**
   * createLink
   * @param   {String} value
   */
  createLink(value) {
    this.props.handleEditorChange(
      LinkHelpers.createLinkEntity(this.props.editor.editorState, value)
    );
  }

  /**
   * getPosition
   * @returns {Object|Null}
   */
  _getPosition() {
    const selectionPosition = this._getSelectionPosition();

    if (selectionPosition) {
      const editorPosition = this.props.parentElement.getBoundingClientRect();
      const selectionOffset = selectionPosition.left - editorPosition.left;
      const editorCentre = editorPosition.width / 2;

      return {
        top: selectionPosition.top - editorPosition.top,
        left: selectionOffset <= editorCentre ? 32 : "auto",
        right: selectionOffset > editorCentre ? 32 : "auto",
      };
    }

    return null;
  }

  /**
   * _getSelectionPosition
   * @returns {Object|Null}
   */
  _getSelectionPosition() {
    return getVisibleSelectionRect(window);
  }

  /**
   * _toggleFakeSelection
   * @param   {Array} blocks
   * @param   {Boolean} isAdding
   */
  _toggleFakeSelection(isAdding) {
    const blocks = Array.from(
      document.getElementsByClassName("public-DraftStyleDefault-block")
    );

    blocks.forEach((block) => {
      block.classList.toggle(
        "public-DraftStyleDefault-block--no-selection",
        isAdding
      );
    });
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <Fragment>
        {this.state.isInputVisible && (
          <LinkInputPrompt
            toggleTooltip={this.toggleLinkInput}
            position={this.inputPosition}
            url={this.currentLinkValue}
            createLink={this.createLink}
            updateLink={this.updateLink}
          />
        )}
        {this.state.isPreviewVisible && this.inputPosition && (
          <LinkPreviewPrompt
            toggleTooltip={this.hideLinkPreview}
            position={this.inputPosition}
            url={this.currentLinkValue}
            editLink={this.editLink}
            removeLink={this.removeLink}
          />
        )}
      </Fragment>
    );
  }
}
