class RavenApiService {
  /**
   * RavenApiService
   * @param {Object} ApiService
   * @returns {Object} service
   */
  constructor(_, ApiService) {
    this._ = _;
    this.ApiService = ApiService;
  }

  /**
   * get interceptor for raven calls
   * @param {String} url
   * @param {Object} requestConfigOverrides
   * @returns {promise}
   */
  get(url, requestConfigOverrides) {
    return this.ApiService.get(
      url,
      this.attachRavenSpecificConfiguration(requestConfigOverrides)
    );
  }

  /**
   * add raven specific header configuration
   * @param {object} requestConfigOverrides
   * @returns {Object}
   */
  attachRavenSpecificConfiguration(requestConfigOverrides) {
    const extendedHeaders = {
      params: {
        uncached: true,
      },
    };

    return this._.merge({}, requestConfigOverrides, extendedHeaders);
  }
}

export default RavenApiService;
