import * as entityActions from "store/entity/entity.action";
import * as editorActions from "store/editor/editor.actions";
import * as editingStateActions from "store/editing-state/editing-state.actions";
import * as modalActions from "store/modal/modal.actions";
import { popInfoToast } from "store/toaster/toaster.actions";

import { react2angular } from "react2angular";
import withStoreProvider from "hocs/with-store-provider";
import { getConfigByEntity } from "store/entities-config/entities-config.selector";
import { fetchEditorConfig } from "store/entities-config/entities-config.action";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Editor from "./editor";

const connectToStore = (component) => connect(mapProps, mapDispatch)(component);

const mapProps = (state) => ({
  entity: state.entity,
  configuration: getConfigByEntity(
    state,
    state.entity.entityName,
    state.entity.data.article_type_slug
  ).editor,
  editingState: state.editingState,
  editor: state.editor,
  modal: state.modal,
  toaster: state.toaster,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      ...editingStateActions,
      ...entityActions,
      ...editorActions,
      ...modalActions,
      ...{ popInfoToast, fetchEditorConfig },
    },
    dispatch
  );

const reactEditor = angular
  .module("Skylark.Components.React.Editor", [])
  .component(
    "editor",
    react2angular(withStoreProvider(connectToStore(Editor)))
  );

export default connectToStore(Editor);

export { reactEditor };
