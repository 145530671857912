/**
 * @classdesc Contains all the actions needed for positioning content. When positioning items the
 *   direction the item is moving in becomes important. If an item is yet to be placed or is
 *   moving down the order, then it should be placed *behind* of the item that currently holds
 *   the position. Otherwise if it is moving up the order then it should be placed *ahead* of the
 *   item that currently holds that position moving items that we're previously ahead of it,
 *   down. It is important to not that position and index are not the same, as the array can
 *   contain any number of unplaced or expired items. The approach taken here is to position
 *   the item ahead or behind the item that currently holds the correct position and let
 *   SortingActions sort the status and position accordingly.
 * @param {Object} SetContentsDispatcher
 * @param {Object} SetContentsStore
 * @param {Object} SortingActions
 * @param {Object} SET_CONTENTS_ACTIONS
 * @return {Object}
 * @constructor
 */
function PositioningActions(
  SetContentsDispatcher,
  SetContentsStore,
  SortingActions,
  SET_CONTENTS_ACTIONS
) {
  const factory = {};

  /**
   * Returns the items without the item that is about to be repositioned in it
   * @param {Array.<Object>} items
   * @param {Object} itemToRemove
   * @private
   */
  function _getItemsWithoutRepositionedItem(items, itemToRemove) {
    return items.filter((item) => item.uid !== itemToRemove.uid);
  }

  /**
   * Zeros the position of any item passed to it in every language
   * @param {Object} item
   * @return {Object}
   * @private
   */
  function _itemWithPositionZero(item) {
    const itemToReposition = { ...item };

    for (const languageVersion in itemToReposition.scheduledItem) {
      if (
        Object.prototype.hasOwnProperty.call(
          itemToReposition.scheduledItem,
          languageVersion
        )
      ) {
        itemToReposition.scheduledItem[languageVersion].position = 0;
      }
    }

    return itemToReposition;
  }

  /**
   * Moves item to the first position in the item array and zeros the langauge version
   * @param {Object} itemToPlace
   * @param {Array} itemsWithoutRepositionedItem
   * @return {Array.<Object>}
   * @private
   */
  function _arrayWithUnplacedItem(itemToPlace, itemsWithoutRepositionedItem) {
    return [
      _itemWithPositionZero(itemToPlace),
      ...itemsWithoutRepositionedItem,
    ];
  }

  /**
   * Moves the item to the past position in the items array
   * @param {Object} itemToPlace
   * @param {Array} itemsWithoutRepositionedItem
   * @return {Array.<Object>}
   * @private
   */
  function _arrayWithItemPlacedAtEnd(
    itemToPlace,
    itemsWithoutRepositionedItem
  ) {
    return [...itemsWithoutRepositionedItem, itemToPlace];
  }

  /**
   * Adds an item into a specific point of the items array assuming it is moving up the array
   * @param {Object} itemToPlace
   * @param {Array} itemsWithoutRepositionedItem
   * @param {Number} index
   * @return {Array.<Object>}
   * @private
   */
  function _arrayWithItemPlacedMovedUp(
    itemToPlace,
    itemsWithoutRepositionedItem,
    index
  ) {
    return [
      ...itemsWithoutRepositionedItem.slice(0, index),
      itemToPlace,
      ...itemsWithoutRepositionedItem.slice(index),
    ];
  }

  /**
   * Adds an item into a specific point of the items array assuming it is moving down the array
   * @param {Object} itemToPlace
   * @param {Array} itemsWithoutRepositionedItem
   * @param {Number} index
   * @return {Array.<Object>}
   * @private
   */
  function _arrayWithItemPlacedMovedDown(
    itemToPlace,
    itemsWithoutRepositionedItem,
    index
  ) {
    return [
      ...itemsWithoutRepositionedItem.slice(0, index + 1),
      itemToPlace,
      ...itemsWithoutRepositionedItem.slice(index + 1),
    ];
  }

  /**
   * Adds item to the start of an array
   * @param {Object} itemToPlace
   * @param {Array} itemsWithoutRepositionedItem
   * @return {Array.<Object>}
   * @private
   */
  function _arrayWithItemPlacedAtStart(
    itemToPlace,
    itemsWithoutRepositionedItem
  ) {
    return [itemToPlace, ...itemsWithoutRepositionedItem];
  }

  /**
   * Decides if an item is moving form unplaced to last
   * @param {number} initialPosition
   * @return {boolean}
   * @private
   */
  function _isMovingFromUnplaced(initialPosition) {
    return initialPosition === 0;
  }

  /**
   * Place item in the array in the correct position. Uses position to see if the item is being
   * unplaced or if the item is being placed at the end of the array. Otherwise works out the
   * index at where item needs to be inserted and uses that to decide where to put it
   * @param {Object} item
   * @param {Array.<Object>} itemsWithoutRepositionedItem
   * @param {Number} position
   * @param {Number} initialIndex
   * @return {Array.<Object>}
   * @private
   */
  function _insertItem(
    itemToPlace,
    itemsWithoutRepositionedItem,
    position,
    initialPosition
  ) {
    const indexToInsert = itemsWithoutRepositionedItem.findIndex(
      (item) => item.scheduledItem[item.displayedLanguage].position === position
    );
    const amountOfPlacedItems = itemsWithoutRepositionedItem.filter(
      (item) => item.scheduledItem[item.displayedLanguage].position > 0
    ).length;
    const isMovingUp = position < initialPosition;

    if (position === 0) {
      return _arrayWithUnplacedItem(itemToPlace, itemsWithoutRepositionedItem);
    }
    if (position > amountOfPlacedItems) {
      return _arrayWithItemPlacedAtEnd(
        itemToPlace,
        itemsWithoutRepositionedItem
      );
    }
    if (
      (indexToInsert > 0 && isMovingUp) ||
      _isMovingFromUnplaced(initialPosition)
    ) {
      return _arrayWithItemPlacedMovedUp(
        itemToPlace,
        itemsWithoutRepositionedItem,
        indexToInsert
      );
    }
    if (!isMovingUp) {
      return _arrayWithItemPlacedMovedDown(
        itemToPlace,
        itemsWithoutRepositionedItem,
        indexToInsert
      );
    }

    return _arrayWithItemPlacedAtStart(
      itemToPlace,
      itemsWithoutRepositionedItem
    );
  }

  /**
   * Positions an item in the item array, each items position property will be updated when items
   * are resorted in sortedActions.This is purely about updating them in the store
   * @param {Object} item
   * @param {number} position
   */
  factory.positionItem = (item, position, initialPosition) => {
    const { items } = SetContentsStore.getState();
    const itemsWithoutRepositionedItem = _getItemsWithoutRepositionedItem(
      items,
      item
    );
    const itemToPlace = { ...item, hasBeenUpdated: true };
    const repositionedItems = _insertItem(
      itemToPlace,
      itemsWithoutRepositionedItem,
      position,
      initialPosition
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.positionItem,
      repositionedItems,
    });

    SortingActions.sortItems();
  };

  return factory;
}

export default PositioningActions;
