import angular from "angular";

const LogoutRoute = angular.module("LogoutRoute", []).config(router);

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("Logout", {
    url: "/logout",
    views: {
      main: {
        controller: "LogoutPageCtrl as page",
      },
    },
  });
}

export default LogoutRoute;
