/**
 * @classdesc Handles the sorting of scheduled items in set contents based on there
 *   position/status
 * @param {Object} SetContentsDispatcher
 * @param {Object} SetContentsStore
 * @param {Object} SET_CONTENTS_ACTIONS
 * @return {Object}
 * @constructor
 * @TODO: Most of the functionality in sorting methods prob's needs to be in store so these
 *   actions can be reused.
 */
function SortingActions(
  SetContentsDispatcher,
  SetContentsStore,
  SET_CONTENTS_ACTIONS
) {
  const factory = {};

  /**
   * Returns if an item is positioned
   * @param {object} item
   * @return {boolean}
   * @private
   */
  function _isPositioned(item) {
    return item.scheduledItem[item.displayedLanguage].position > 0;
  }

  /**
   * Returns if an item has expired
   * @param {object} item
   * @return {boolean}
   * @private
   */
  function _hasExpired(item) {
    const scheduledItem = item.scheduledItem[item.displayedLanguage];
    const contentItem = item.contentItem[item.displayedLanguage];

    return _isItemExpired(scheduledItem) || _isItemExpired(contentItem);
  }

  /**
   * _isItemExpired
   * @param  {Object} item
   * @return {Boolean}
   */
  function _isItemExpired(item) {
    return (
      (item.expired && !item.future) ||
      (item.schedule_urls && item.schedule_urls.length === 0)
    );
  }

  /**
   *
   * @param {number} position
   * @param {Object} item
   * @returns {Object}
   * @private
   */
  function _setItemPosition(item, position) {
    const scheduledItem = { ...item.scheduledItem };

    for (const languageVersion in scheduledItem) {
      if (
        Object.prototype.hasOwnProperty.call(scheduledItem, languageVersion)
      ) {
        scheduledItem[languageVersion].position = position;
      }
    }

    return { ...item, scheduledItem };
  }

  /**
   * _sortExpired
   * @private
   */
  function _sortExpired() {
    const expiredItems = SetContentsStore.getState()
      .items.filter((item) => _hasExpired(item))
      .map((item) => _setItemPosition(item, 0));

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.sortExpired,
      expiredItems,
    });
  }

  /**
   * _sortUnplaced
   * @private
   */
  function _sortUnplaced() {
    const unplacedItems = SetContentsStore.getState().items.filter(
      (item) => !_isPositioned(item) && !_hasExpired(item)
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.sortUnplaced,
      unplacedItems,
    });
  }

  /**
   * _sortPlaced
   * @private
   */
  function _sortPlaced() {
    const placedItems = SetContentsStore.getState().items.filter(
      (item) => _isPositioned(item) && !_hasExpired(item)
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.sortPlaced,
      placedItems,
    });
  }

  /**
   * Correct the position of placed items taking into account any items that may of expired
   * @private
   */
  function _correctPositions() {
    const placedItems = SetContentsStore.getState().items.filter(
      (item) => _isPositioned(item) && !_hasExpired(item)
    );

    const updatedPlacedItems = placedItems.map((item, index) =>
      _setItemPosition(item, index + 1)
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.correctPositions,
      positionedItems: updatedPlacedItems,
    });
  }

  factory.sortItems = () => {
    _sortExpired();
    _sortUnplaced();
    _sortPlaced();
    _correctPositions();
  };

  return factory;
}

export default SortingActions;
