import TextPlaceholderBlock from "components/_react/editor/text-placeholder-block/text-placeholder-block";
import MediaBlock from "components/_react/editor/media-block/media-block";

/**
 *
 * @param   {Immutable.Map} contentBlock
 * @returns {Object}
 */
export default function (contentBlock, rendererConfig) {
  const type = contentBlock.getType();

  switch (type) {
    case "placeholder":
      return {
        component: TextPlaceholderBlock,
        editable: true,
        props: {
          config: contentBlock.getData().get("config"),
        },
      };
    case "image":
      return {
        component: MediaBlock,
        editable: true,
        props: {
          renderMedia: rendererConfig.renderMedia,
          replaceMedia: rendererConfig.replaceMedia,
          onModalSave: rendererConfig.onModalSave,
        },
      };
    case "media-placeholder":
      return {
        component: MediaBlock,
        editable: true,
        props: {
          replaceMedia: rendererConfig.replaceMedia,
          onModalSave: rendererConfig.onModalSave,
        },
      };
    case "video":
      return {
        component: MediaBlock,
        editable: true,
        props: {
          replaceMedia: rendererConfig.replaceMedia,
          onModalSave: rendererConfig.onModalSave,
        },
      };
    default:
      return contentBlock;
  }
}
