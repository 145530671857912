import { UID_PATTERN } from "../../../../metadata";

/**
 * Stateful service which maintains a history of where a user has been
 * Contains all required methods to manipulate the history as the user navigates through
 */
class HistoryService {
  /**
   * @constructor
   * @param {Object} $transitions
   * @param {Object} $location
   */
  constructor($transitions, $location, $state) {
    this.$transitions = $transitions;
    this.$location = $location;
    this.$state = $state;
  }

  /**
   * @set
   * @param {Array.<Object>} value
   */
  set history(value) {
    this._history = value;
  }

  /**
   * @get
   * @returns {Array.<Object>}
   */
  get history() {
    return this._history;
  }

  /**
   * Safely initialises the history object and registers events. Should only be called during bootstrapping
   * @returns {void}
   */
  init() {
    this.history = this.history || [];

    this._registerNavigationEvents();
  }

  /**
   * Registers Listeners on an app level. Must be part of the run function when bootstrapping to ensure all
   * route changes are registered. Sets up listeners so the history can be updated when page data is loaded.
   * @private
   * @returns {void}
   */
  _registerNavigationEvents() {
    this.$transitions.onSuccess({}, (transition) =>
      this._handleViewChange(transition.to())
    );
  }

  /**
   * Works out the direction of navigation (forward or back) and call the method directly
   * @param {Object} state - information about the state we are navigating to.
   */
  _handleViewChange() {
    if (this._isBackAction()) {
      this.backPage();
    }
  }

  /**
   * Helper function to check if the user has navigated back
   * @private
   * @returns {Boolean}
   */
  _isBackAction() {
    const previousPage = this.previousPage();

    return (
      previousPage &&
      previousPage.path === this.pathWithoutTab(this.$location.path())
    );
  }

  /**
   * _isSameEntity
   * @private
   * @returns {Boolean} - whether we are in the same path
   */
  _isSameEntity() {
    return (
      this.previousPage() &&
      this.previousPage().path === this.currentPage().path
    );
  }

  /**
   * _isSameTab
   * @private
   * @returns {Boolean} whether we are in the same tab
   */
  _isSameTab() {
    const previousPageTitleArray = this.previousPage().pageTitle.split(":");
    const currentPageTitleArray = this.currentPage().pageTitle.split(":");

    return (
      previousPageTitleArray[1] &&
      previousPageTitleArray[1] === currentPageTitleArray[1]
    );
  }

  /**
   * Helper function used to discern if the navigation was a tab change or trip to another entity
   * @returns {boolean}
   * @private
   */
  _isTabChange() {
    let isTabChange = false;
    let isSameEntity = false;

    if (this.previousPage()) {
      isSameEntity = this._isSameEntity();
      isTabChange = !this._isSameTab();
    }

    return isSameEntity && isTabChange;
  }

  /**
   * _isReload
   * @private
   * @returns {Boolean}
   */
  _isReload() {
    let isSameEntity = false;
    let isReload = false;

    if (this.previousPage()) {
      isSameEntity = this._isSameEntity();
      isReload = this._isSameTab();
    }

    return isSameEntity && isReload;
  }

  /**
   * Returns the current path without the current tab added to the url
   * @param {string} path
   * @returns {string}
   */
  pathWithoutTab(path) {
    const pathArray = path.split("/");
    const uiPatternRegex = new RegExp(UID_PATTERN);
    const isDetail = pathArray.findIndex((val) => val.match(uiPatternRegex));

    return isDetail > -1 ? pathArray.splice(0, isDetail + 1).join("/") : path;
  }

  /**
   * Adds data once entity has loaded to the latest history element. Called from child controllers
   * @param {string} title - title to update page title
   */
  addToHistory(title) {
    const state = this.$state.current;
    const newPage = {
      pageTitle: state.pageTitle,
      stateTitle: state.title,
      stateName: state.name,
      path: this.pathWithoutTab(this.$location.path()),
      position: this.history.length,
      title,
    };

    this.forwardPage(newPage);

    if (this._isSameEntity()) {
      this.history.pop();
    }
  }

  /**
   * Move forward in the history
   * @param {Object} page
   * @returns {void}
   */
  forwardPage(page) {
    this.history.push(page);
  }

  /**
   * Move back through history
   * @returns {void}
   */
  backPage() {
    this.history.pop();
  }

  /**
   * Get the current page
   * @returns {Object} the last entity in history
   */
  currentPage() {
    return this.history[this.history.length - 1];
  }

  /**
   * Returns the previous page
   * @returns {Object} the previous page
   */
  previousPage() {
    return this.history[this.history.length - 2];
  }

  /**
   * Returns the last N pages§
   * @param {number} amount
   * @returns {Array} the last N pages
   */
  lastPages(amount) {
    return this.history.length > amount - 1
      ? this.history.slice(this.history.length - amount)
      : this.history;
  }

  /**
   * Reset the stored history to only include the current page
   * @returns {void}
   */
  reset() {
    if (this.history.length > 0) {
      this.history.splice(0, this.history.length - 1);
      this.history[0].position = 0;
    }
  }
}

export default HistoryService;
