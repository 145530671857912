import React, { Component } from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";

import ModalNotification from "components/_react/modal/modal-notification/modal-notification-container";
import ModalList from "components/_react/modal/modal-list/modal-list-container";

import styles from "./modal-wrapper.scss";

export default class ModalWrapper extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    destroyModal: PropTypes.func,
    options: PropTypes.shape().isRequired,
    modalIndex: PropTypes.number.isRequired,
    modal: PropTypes.shape({
      modalInstances: PropTypes.arrayOf(PropTypes.object),
    }).isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    destroyModal: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.initialiseDestruction = this.initialiseDestruction.bind(this);

    this.modalComponents = {
      notification: ModalNotification,
      list: ModalList,
    };
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    this.setInitialState();
  }

  /**
   * componentDidMount lifecycle hook
   */
  componentDidMount() {
    this._displayModal();
  }

  /**
   * setInitialState
   */
  setInitialState() {
    this.setState({
      isVisible: false,
    });
  }

  /**
   * _displayModal
   * @return {void}
   */
  _displayModal() {
    this.setState({
      isVisible: true,
    });
  }

  /**
   * initialiseDestruction
   * @return {[type]} [description]
   */
  initialiseDestruction() {
    this.setState({
      isVisible: false,
    });
  }

  /**
   * _isActiveModal
   * @return {boolean}
   */
  _isActiveModal() {
    return this.props.modalIndex + 1 === this.props.modal.modalInstances.length;
  }

  /**
   * _hideModal
   * @return {Object}
   */
  _hideModal() {
    return !this._isActiveModal() ? { display: "none" } : {};
  }

  /**
   * render and create portal
   * @returns {element}
   */
  render() {
    const ModalToRender =
      this.modalComponents[this.props.options.metadata.name];

    return (
      <CSSTransition
        timeout={300}
        in={this.state.isVisible}
        onExited={() => this.props.destroyModal()}
        classNames={{
          enter: styles["modal-wrapper__enter"],
          enterActive: styles["modal-wrapper__enter-active"],
          enterDone: styles["modal-wrapper__entered"],
          exit: styles["modal-wrapper__exit"],
          exitActive: styles["modal-wrapper__exit-active"],
        }}
      >
        <div style={this._hideModal()} className={styles["modal-wrapper"]}>
          <ModalToRender
            options={this.props.options}
            destroyModal={this.initialiseDestruction}
          />
        </div>
      </CSSTransition>
    );
  }
}
