/*
 * @fileOverview - wrapper for chosen jQuery plugin
 * @description We will need to cover more use cases at some point
 */
class ChosenRelationshipDirectiveCtrl {
  /**
   * @param {object} $scope
   * @param {object} $element
   * @param {object} $timeout
   * @constructor
   */
  constructor($scope, $element, $timeout) {
    this.$scope = $scope;
    this.$element = $element;
    this.$timeout = $timeout;
    this.settings = undefined;

    this.defaults = {
      width: "100%",
      placeholder_text_single: "Select Relationship",
      search_contains: true,
    };

    this.ngModel = this.$element.controller("ngModel");
  }

  $onInit() {
    this.$scope.chosen.bindParams(this.$scope.settings);

    this.$element.on("chosen:ready", () => {
      this.$scope.appendShowMoreEl();
      this.addPlaceholderToSearchInput();
    });

    this.$element.on("chosen:updated", () => {
      const $chosenResults = this.$element.parent().find(".chosen-results");

      setTimeout(() => {
        $chosenResults[0].scrollTop = $chosenResults[0].scrollHeight;
      }, 1);
    });

    this.$element.chosen(this.$scope.chosen.settings);

    this.$scope.$watch("ngModel", (newValue, oldValue) => {
      // when ngModel has programmatically changed we want to update the form state
      if (newValue !== oldValue) {
        this.ngModel.$setDirty();
        this.ngModel.$validate();
      }
      this.$timeout(() => {
        this.$scope.updateEntityDataModel();
        this.$element.trigger("chosen:updated");
      }, 100);
    });

    this.$scope.$watch("options", () => {
      this.$timeout(() => {
        this.$element.trigger("chosen:updated");
      }, 100);
    });
  }

  /**
   * bindParams
   * @param   {Object} settings - chosen directive settings
   * @returns {void}
   */
  bindParams(settings) {
    this.settings = { ...this.defaults, ...settings };
  }

  /**
   * Adds the placeholder to the search input
   * into the chosen-js plugin
   *
   * @return {void}
   *
   */
  addPlaceholderToSearchInput() {
    this.$element
      .parent()
      .find(".chosen-search-input")
      .attr("placeholder", "Search an item");
  }
}

/**
 * chosenRelationshipDirective
 *
 *
 * watches the ngModel and the options
 * to refresh the (plugin) list accordingly to
 * changes
 *
 * @returns {Object} Directive Definition Object
 */
function chosenRelationshipDirective() {
  return {
    restrict: "A",
    scope: {
      ngModel: "=",
      options: "=",
      ngDisabled: "=",
      settings: "=",
      appendShowMoreEl: "&",
      updateEntityDataModel: "&",
    },
    controller: ChosenRelationshipDirectiveCtrl,
    controllerAs: "chosen",
  };
}

export default chosenRelationshipDirective;
