import * as ToasterAction from "store/notification/notification.action";
import template from "./toaster.html";

class ToasterController {
  /**
   * @param $scope
   * @param toaster
   * @param ReduxConnector
   * @param NotificationService
   */
  constructor($scope, toaster, ReduxConnector, NotificationService) {
    this.$scope = $scope;
    this.toaster = toaster;
    this.ReduxConnector = ReduxConnector;
    this.NotificationService = NotificationService;

    this.connectToStore();
  }

  $onInit() {
    this.$scope.$watch("component.toasterMessages", (newValue) => {
      if (Array.isArray(newValue) && newValue[0]) {
        const toast = newValue[0];
        this.NotificationService.notify(toast.type, toast.message);

        this.store.removeToast();
      }
    });

    this.config = {
      "close-button": true,
      "position-class": "toast-bottom-right",
      "time-out": 3000,
    };
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...ToasterAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis,
      mapDispatchToThis
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    return {
      toasterMessages: state.notifications,
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

const ToasterComponent = {
  template,
  controller: ToasterController,
  controllerAs: "component",
};

const Toaster = angular
  .module("Skylark.Components.Toaster", [])
  .component("toaster", ToasterComponent);

export default Toaster;
