import template from "./modal-edit.html";

/**
 * @file ModalEdit
 * A self-contained editable module to be used in modals.
 */

/**
 * @memberof  Components.Modal
 * @classdesc Allows for the creation and editing of generic entities which
 * conform to a configurable interface with tabs.
 * Supports data source fields and image fields.
 * Note: This component needs to be namespaced as module to conform with
 * the fields interface.
 * Expects an options object with the following format:
 * { channels: { save: string OR create: string, }, parent: object, entity: string }
 */
class ModalEditCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $q
   * @param {Object} _
   * @param {Object} MessageService
   * @param {Object} ModalService
   * @param {Object} ModalMultiLanguageService
   * @param {Object} ModalMultiSourceService
   * @param {Object} LanguagesService
   * @param {Object} MODEL_DEFAULTS
   * @param {Object} DataSourceFactory
   * @param {Object} SkylarkApiInfoService
   */
  constructor(
    $scope,
    $q,
    _,
    MessageService,
    ModalService,
    ModalMultiLanguageService,
    ModalMultiSourceService,
    LanguagesService,
    MODEL_DEFAULTS,
    DataSourceFactory,
    SkylarkApiInfoService
  ) {
    this.$scope = $scope;
    this._ = _;
    this.$q = $q;
    this.MessageService = MessageService;
    this.ModalService = ModalService;
    this.ModalMultiLanguageService = ModalMultiLanguageService;
    this.ModalMultiSourceService = ModalMultiSourceService;
    this.LanguagesService = LanguagesService;
    this.MODEL_DEFAULTS = MODEL_DEFAULTS;
    this.DataSourceFactory = DataSourceFactory;
    this.SkylarkApiInfoService = SkylarkApiInfoService;

    // react functions caveat, it needs a bind to this otherwise the passed town function looses the context
    this.toggleDataSource = this.toggleDataSource.bind(this);
  }

  /**
   * @returns {void}
   */
  init() {
    this.isModal = true;
    this.validatedForm = {};

    this._setupData();
    this._assignDefaults();
    this._setupSubscriptions();

    this.hideDataSource = this.options.entity === "schedules";

    const requiredField = this._getRequiredField();
    if (requiredField) {
      this.requiredField = requiredField.name;
      this.requiredTab = requiredField.tab;
    }

    // default cognitoAuthEnabled to true to stop div hiding flash
    this.cognitoAuthEnabled = true;
    // check if using cognito auth but don't block component init
    this.setCognitoAuth();
  }

  /**
   * Setup the modals initial data
   * @private
   */
  _setupData() {
    this.parentData = this.options.parent;

    if (this.options.isUniqueToSet) {
      const fieldsToCopy = this._getContentItemFieldNames();
      this.data = this.ModalMultiSourceService.combineData(
        this.data,
        this.options.contentItem,
        fieldsToCopy
      );

      const firstLanguageVersion = this.data[Object.keys(this.data)[0]];
      this.imageFile = firstLanguageVersion.imageFile;
      this.imagePreview = firstLanguageVersion.imagePreview;
    }

    if (this.options.hasTranslations || this.options.isUniqueToSet) {
      this.ModalMultiLanguageService.multilingualData = this._.cloneDeep(
        this.data
      );
      this.ModalMultiLanguageService.translatableFields =
        this._getMetadataFieldNames();
      this._extendAvailableLanguages();

      const initialLanguageData =
        this.ModalMultiLanguageService.getInitialLanguageVersion(
          this.options.parent.parentLanguage
        );

      this.data = initialLanguageData;
    } else {
      this.data = this._.cloneDeep(this.data);
    }

    this.initialData = angular.copy(this.data, {});
    this.isNewEntity = this._.isEmpty(this.data);
  }

  /**
   * Get the name of the fields that can be translated
   * @private
   */
  _getMetadataFieldNames() {
    return this.config
      .filter((field) => field.tab === "Metadata")
      .map((field) => field.name);
  }

  /**
   * Get the name of the fields that can be translated
   * @private
   */
  _getContentItemFieldNames() {
    return this.config
      .filter((field) => field.field_location === "contentItem")
      .map((field) => field.name);
  }

  /**
   * Gets any required fields for this modal
   * @returns {Object}
   */
  _getRequiredField() {
    return this.config.find(
      (field) => field.validation && field.validation.required
    );
  }

  /**
   * assign default model values
   * @returns {void}
   */
  _assignDefaults() {
    if (this.isNewEntity && this.options.entity) {
      Object.assign(this.data, this.MODEL_DEFAULTS[this.options.entity]);
    }
  }

  /**
   * @returns {void}
   * @deprecated only to be used in articles
   */
  _createEntity() {
    if (this.imageFile) {
      this._prepareImage();
    }

    const dataToSend = { ...this.data, entityType: this.entityType };

    this.MessageService.publish(this.options.channels.create, dataToSend);
    Object.keys(this.data).forEach((key) => delete this.data[key]);

    this._closeModal();
  }

  /**
   * Updates the image entity attached to this item block
   * @deprecated only to be used in articles
   * @returns {void}
   */
  _imageUpdate() {
    this._prepareImage();

    const dataToSend = { ...this.data, entityType: "image" };

    this.MessageService.publish(this.options.channels.save, dataToSend);
  }

  /**
   * Handles functionality when images have been uploaded and added to metadata
   * @returns {void}
   * @todo: looking into this as part of images in set contents. Is it required by articles or
   *   images tab
   */
  _prepareImage() {
    if (this.hasImageBeenRemoved) {
      this.hasImageBeenRemoved = false;
      this.data.imageFile = undefined;

      this.MessageService.publish(
        `itemblock:${this.uid}-haschanged.removeImage`,
        this.data
      );
      this.data.image_urls.length = 0;
    } else {
      this.data.imageFile = this.imageFile;
      this.data.imagePreview = this.imagePreview;
    }
  }

  /**
   * Assigns the data to be saved based on if it matches the parent's language or not
   * @returns {void}
   */
  _assignData() {
    this.data = angular.copy(this.initialData);
  }

  /**
   * Handler for assigning data when after saving a scheduled item
   * @returns {void}
   */
  _updateItemData() {
    if (
      this.data.content_type === "Image" ||
      "image_type_url" in this.data ||
      this.imageFile
    ) {
      this._prepareImage();
    }

    this._updateAndCloseModal();
  }

  /**
   * @returns {void}
   */
  _updateAndCloseModal() {
    if (this.options.hasTranslations) {
      this.ModalMultiLanguageService.prepareMultilingualDataToSave();
      this.data = this.ModalMultiLanguageService.multilingualData;
    }

    if (this.options.isUniqueToSet) {
      this.data = this.ModalMultiSourceService.splitData(
        this.ModalMultiLanguageService.multilingualData
      );
    }

    this.MessageService.publish(this.options.channels.save, { ...this.data });

    this._closeModal();
  }

  /**
   * Extend the passed version availableLanguage for use in this modal
   * @returns {void}
   * @private
   */
  _extendAvailableLanguages() {
    this.availableLanguages = this.options.availableLanguages.map(
      (language) => ({
        ...language,
        isUsed:
          !!this.ModalMultiLanguageService.multilingualData[language.iso_code],
      })
    );
  }

  /**
   * save the scheduledItem data and close modal
   * @returns {void}
   */
  save() {
    if (this.isNewEntity) {
      this._createEntity();
    } else {
      this._updateItemData();
    }
  }

  /**
   * delete the scheduledItem data and close modal
   * @description pass the data in the publish as it is required to deal
   * with some more complex deleting workflows
   * @returns {void}
   */
  remove() {
    const uid = this.data.uid || this.data.relationshipId;
    const removeChannel = this.options.channels.delete || `deleteSelf.${uid}`;
    this.MessageService.publish(removeChannel, this.data);
    this.destroyModal();
  }

  /**
   * @returns {void}
   */
  revert() {
    angular.copy(this.initialData, this.data);
  }

  /**
   * @returns {void}
   */
  cancel() {
    this.revert();
    this.data = angular.extend({}, this.initialData);
    this.destroyModal();
  }

  /**
   * toggleDataSource - unlink or relink an ingested attribute
   * from/to it's data source
   * @param {Object} field name - the field to toggle
   * @param {Boolean} isEditing - if the form is currently being edited
   * @returns {void}
   */
  toggleDataSource(field, isEditing) {
    if (isEditing) {
      this.data.data_source_fields = this.DataSourceFactory.toggleDataSource(
        this.data.data_source_fields,
        field
      );
    }
  }

  /**
   * Switches the language
   */
  switchLanguageVersion(languageKey) {
    this.data =
      this.ModalMultiLanguageService.switchLanguageVersion(languageKey);
    this._extendAvailableLanguages();
  }

  /**
   * Get the full name of the language
   */
  getCurrentLanguageName() {
    return this.ModalMultiLanguageService.getCurrentLanguageName(
      this.options.availableLanguages
    );
  }

  /**
   * Set up the pub/sub subscriptions for this directive
   * @returns {void}
   */
  _setupSubscriptions() {
    const availableChannels = {
      imageUpdate: this.data.uid
        ? `ImageUploader.${this.data.uid}`
        : "ImageUploader",
      imageRemove: this.data.uid
        ? `ImageUploader.Remove.${this.data.uid}`
        : "ImageUploader.Remove",
      revert: `${this.data.uid}.Metadata.CancelEdit`,
      dataChannel: `${this.data.uid}.dataChannel`,
    };

    this.MessageService.registerChannel(availableChannels.imageUpdate);
    this.MessageService.registerChannel(availableChannels.imageRemove);

    this.MessageService.on(availableChannels.imageUpdate, (channel, image) => {
      this.imageFile = image.file;
      this.imagePreview = image.filePreview;
      this.hasImageBeenRemoved = false;
    });

    this.MessageService.on(availableChannels.imageRemove, () => {
      this.imageFile = undefined;
      this.imagePreview = undefined;
      this.hasImageBeenRemoved = true;
    });

    this.MessageService.subscribe(
      `${this.data.uid}.Metadata.CancelEdit`,
      () => {
        this.revert();
      }
    );

    this.MessageService.subscribe(
      availableChannels.dataChannel,
      (channel, data) => {
        this.data = angular.merge(this.data, data);
        this.initialData = angular.merge(this.initialData, data);
      }
    );

    this.$scope.$on("$destroy", () => {
      this._unregisterSubscriptions(availableChannels);
    });
  }

  /**
   * Remove subscriptions
   * @param {Object} availableChannels
   * @returns {void}
   */
  _unregisterSubscriptions(availableChannels) {
    this.MessageService.unregisterChannel(availableChannels.imageUpdate);
    this.MessageService.unregisterChannel(availableChannels.imageRemove);
    this.MessageService.unregisterChannel(availableChannels.revert);
    this.MessageService.unregisterChannel(availableChannels.dataChannel);
  }

  /**
   * @returns {void}
   */
  _closeModal() {
    // see if we need more stuff in here
    this.destroyModal();
  }

  /**
   * @returns {Promise<void>}
   */
  async setCognitoAuth() {
    try {
      this.cognitoAuthEnabled =
        await this.SkylarkApiInfoService.hasCognitoAuth();
    } catch (err) {
      this.cognitoAuthEnabled = false;
    }
  }
}

/**
 * modalEditDirective
 * @returns {Object} Directive Definition Object
 */
function modalEditDirective() {
  return {
    restrict: "E",
    controller: ModalEditCtrl,
    scope: {},
    bindToController: {
      data: "=",
      config: "=",
      options: "=",
      entityType: "<",
      instanceUid: "@",
      destroyModal: "&",
    },
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default modalEditDirective;
