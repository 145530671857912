import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { DropdownArticleObjects } from "components/_react/fields/dropdowns/dropdowns";

import styles from "./block-header.scss";

/**
 * BlockHeader - renders headers with dropdowns in media blocks
 * @param {Object} props
 */
export default function BlockHeader(props) {
  const icon = props.type === "video" ? "episode" : props.type;

  return (
    <div
      className={classnames(styles["block-header"], {
        [styles["block-header--preview"]]: !props.isEditing,
      })}
      suppressContentEditableWarning="true"
      contentEditable="false"
      readOnly="readonly"
    >
      <div className={styles["block-header__title"]}>
        <div className={styles["block-header__icon-wrap"]}>
          <SkylarkIcon
            iconName={icon}
            extraClassName={styles["block-header__icon"]}
          />
        </div>
        <p className={styles["block-header__text"]} data-test="block-header">
          {" "}
          {props.headerText}
        </p>
      </div>
      {props.isEditing && (
        <DropdownArticleObjects
          renderMedia={props.renderMediaBlock}
          isDisabled={false}
          copy="Replace"
          styling="light"
          block={props.block}
          onModalSave={props.onModalSave}
        />
      )}
    </div>
  );
}

BlockHeader.propTypes = {
  headerText: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderMediaBlock: PropTypes.func,
  block: PropTypes.shape(),
  isEditing: PropTypes.bool,
  onModalSave: PropTypes.func,
};

BlockHeader.defaultProps = {
  block: undefined,
  renderMediaBlock: undefined,
  isEditing: true,
};
