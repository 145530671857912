import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./navigation-bookmarks.html";

class NavigationBookmarksCtrl {
  /**
   * Creates an instance of NavigationBookmarksCtrl.
   * @param {Object} BookmarkService
   * @param {Object} MessageService
   * @memberof NavigationBookmarksCtrl
   */
  constructor(
    $scope,
    BookmarkService,
    MessageService,
    NotificationService,
    $rootScope,
    _
  ) {
    this.$scope = $scope;
    this.BookmarkService = BookmarkService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.$rootScope = $rootScope;
    this._ = _;
  }

  /**
   * lifecycle hook
   * @memberof NavigationBookmarksCtrl
   */
  $onInit() {
    this.data = {
      display_name: "Bookmarks",
      icon: "favourite",
      items: [],
    };

    this._setSubscriptions();
  }

  /**
   * @memberof NavigationBookmarksCtrl
   */
  _setSubscriptions() {
    this.$scope.$watch("$root.hasBookmarks", (hasBookmarks) => {
      if (hasBookmarks) {
        this._updateList();
      }
    });

    this.MessageService.subscribe("Bookmark.Add", () => {
      this._updateActiveNavItem();
      this._updateList();
    });

    this.MessageService.subscribe("Bookmark.Remove", () => {
      this._updateList();
    });

    this.MessageService.subscribe("Entity.Delete", (channel, itemUrl) => {
      this.removeItem(itemUrl);
    });

    this.MessageService.subscribe("Modal.TranslationSettings.Saved", () => {
      this._updateList([]);
      this.BookmarkService.fetchItems().then(() => {
        this._updateList();
      });
    });

    this.MessageService.subscribe("GlobalLanguage.changed", () => {
      // Only fetch bookmarks if the service is ready
      if (this.$rootScope.hasBookmarks) {
        this._updateList([]);
        this.BookmarkService.fetchItems().then(() => {
          this._updateList();
        });
      }
    });

    this.MessageService.lockChannel("Entity.Delete");
    this.MessageService.lockChannel("Bookmark.Add");
    this.MessageService.lockChannel("Bookmark.Remove");
  }

  /**
   * @memberof NavigationBookmarksCtrl
   */
  _updateActiveNavItem() {
    const { items } = this.BookmarkService.buildBookmarkList();

    this.$rootScope.activeNavItem = items[items.length - 1].uid;
    this.shouldSectionExpand = true;
  }

  /**
   * @param {String} itemUrl
   * @memberof NavigationBookmarksCtrl
   */
  removeItem(itemUrl) {
    const items = [...this.data.items];
    const removedBookmark = this._.remove(
      this.data.items,
      (item) => item.self === itemUrl
    );

    if (removedBookmark.length) {
      this.BookmarkService.removeItem(itemUrl).catch(() => {
        this.NotificationService.notifyError(
          NotificationMessage.bookmarkError("remove")
        );
        this._updateList(items);
      });
    }
  }

  /**
   * @param {Array} items
   * @memberof NavigationBookmarksCtrl
   */
  _updateList(items = this.BookmarkService.buildBookmarkList().items) {
    const uniqueItems = items.length > 0 ? this._.uniqBy(items, "uid") : items;
    this.data = {
      ...this.data,
      display_name: uniqueItems.length ? "Bookmarks" : "No Bookmarks",
      items: uniqueItems,
    };
  }
}

const navigationBookmarksComponent = {
  controller: NavigationBookmarksCtrl,
  controllerAs: "component",
  template,
};

export default navigationBookmarksComponent;
