/**
 * @fileOverview Dynamic Object Factory
 * CRUD methods for dynamic objects
 */

function DynamicObjectFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  /**
   * create
   * @access public
   * @param   {Object} data - data for the dynamic object we are creating
   * @returns {Promise}
   */
  factory.create = (data) =>
    ApiService.post(
      "/api/computed-scheduled-items/",
      data,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    );

  /**
   * get
   * @access public
   * @param   {String} uid - of dynamic object
   * @returns {Promise}
   */
  factory.get = (uid) =>
    ApiService.get(
      `/api/computed-scheduled-items/${uid}/`,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  /**
   * save
   * @access public
   * @param   {String} uid - of dynamic object
   * @param   {Object} data - new data for the dynamic object we are editing
   * @returns {Promise}
   */
  factory.save = (uid, data) => {
    const config = ApiRequestConfigFactory.createRequestConfig({
      overrideGlobalLanguage: true,
    });

    return ApiService.put(
      `/api/computed-scheduled-items/${uid}/`,
      data,
      config
    );
  };

  /**
   * remove
   * @access public
   * @param   {String} uid - of dynamic object to remove
   * @returns {Promise}
   */
  factory.remove = (uid) =>
    ApiService.put(`/api/computed-scheduled-items/${uid}/`);

  return factory;
}

export default DynamicObjectFactory;
