/**
 * @fileOverview Modal Service
 * Stateful service for modals.
 * Keeps track of how many modals are open at a given time,
 * along with preparing the data that was passed into them via directive initialisation.
 */

const modalInstances = [];
let modalConfigs = {};

class ModalService {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} ConfigurationFactory
   * @param {Object} ModalNotificationService
   */
  constructor($q, ConfigurationFactory, ModalNotificationService) {
    this.$q = $q;
    this.ConfigurationFactory = ConfigurationFactory;
    this.ModalNotificationService = ModalNotificationService;
  }

  /**
   * initNewModal
   * @access public
   * @param {object} data - data
   * @param {object} config - config
   * @param {string} type - the type of modal
   * @param {string} entityType - the entityType
   * @param {object} options - other required options
   * @returns {Promise} - promise which resolves to the index of the modal instance
   */
  initNewModal(data, config, type, entityType, options) {
    const deferred = this.$q.defer();
    const isDisabled = this._isDisabled(type, options);

    if (isDisabled) {
      deferred.reject();
    } else {
      this._resolveEnabled(data, config, type, entityType, options, deferred);
    }

    return deferred.promise;
  }

  /**
   * _createInstance
   * @param   {Array} data
   * @param   {Object} modalConfig
   * @param   {string} type
   * @param   {string} entityType - the entityType
   * @param   {object} options
   * @returns {Number} index of the modal instance
   */
  _createInstance(data, modalConfig, type, entityType, options) {
    modalInstances.push({
      data,
      modalConfig,
      type,
      entityType,
      options,
    });

    return modalInstances.length - 1;
  }

  /**
   * _resolveDisabled
   * Checks if current notification is disabled and triggers any default actions.
   * If so, reject the promise to prevent creating a modal instance.
   * @param {string} type - modal type
   * @param {object} options - notification options
   * @param {promise} modalInitPromise - the modal create promise
   * @returns {void}
   */
  _isDisabled(type, options) {
    const isDisabled =
      type === "notification"
        ? this.ModalNotificationService.triggerDefaultAction(options)
        : false;

    return isDisabled;
  }

  /**
   * _resolveEnabled
   * @param {object} data - data
   * @param {object} config - config
   * @param {string} type - the type of modal
   * @param {string} entityType - the entityType
   * @param {object} options - other required options
   * @param   {promise} deferred
   * @returns {number} index of the modal instance
   */
  _resolveEnabled(data, config, type, entityType, options, deferred) {
    const hasConfig = !!config;
    const genericConfig = modalConfigs[type];

    if (!hasConfig && !genericConfig) {
      this._getConfig(type).then((response) => {
        modalConfigs = response;
        const filteredConfig = this._getEntitySpecificConfig(
          response,
          type,
          entityType
        );
        const instance = this._createInstance(
          data,
          filteredConfig,
          type,
          entityType,
          options
        );
        deferred.resolve(instance);
      });
    } else {
      const filteredConfig =
        !hasConfig && genericConfig
          ? this._getEntitySpecificConfig(genericConfig, type, entityType)
          : config;
      const instance = this._createInstance(
        data,
        filteredConfig,
        type,
        entityType,
        options
      );
      deferred.resolve(instance);
    }
  }

  /**
   * getInstance
   * @param   {Number} uid
   * @returns {object} modalInstances[uid] - the instance of the modal
   */
  getInstance(uid) {
    return modalInstances[uid];
  }

  /**
   * getData
   * @param   {Number} uid
   * @returns {object} data - modal data
   */
  getData(uid) {
    return modalInstances[uid].data;
  }

  /**
   * getConfig
   * @param   {number} uid
   * @returns {object} config
   */
  getConfig(uid) {
    return modalInstances[uid].modalConfig;
  }

  /**
   * destroyModal - destroy current modal
   * @returns {void}
   */
  destroyModal() {
    modalInstances.pop();
  }

  /**
   * destroyAllModals
   * @returns {void}
   */
  destroyAllModals() {
    modalInstances.length = 0;
  }

  /**
   * isLayered
   * @returns {Boolean}
   */
  isLayered() {
    return modalInstances.length > 1;
  }

  /**
   * updateData
   * @param {string} uid - modal uid
   * @param {any} data - modal instance data
   * @returns {void}
   */
  updateData(uid, data) {
    modalInstances[uid].data = data;
  }

  /**
   * updateField
   * @param   {string} uid
   * @param   {any} data
   * @param   {string} field - field in data to update
   * @returns {void}
   */
  updateField(uid, data, field) {
    modalInstances[uid].data[field] = data;
  }

  /**
   * getConfig for modals that need it
   * @param {string} type
   * @returns {void}
   */
  _getConfig(type) {
    return this.ConfigurationFactory.getModalConfigurationByType(type);
  }

  /**
   * _getEntitySpecificConfig
   * @param   {array} config
   * @param   {string} type - modal type
   * @param   {string} entityType - entity type
   * @returns {object|array} config
   */
  _getEntitySpecificConfig(config, type, entityType) {
    if (type === "list") {
      return config.content_types.find(
        (contentType) => contentType.name === entityType
      );
    }
    return config;
  }
}

export default ModalService;
