import _ from "lodash";
import { createSelector } from "reselect";
import { getInformationConfig } from "store/entities-config/entities-config.selector";
import { getEntity } from "store/entity/entity.selector";

/**
 * matching entity data and information config, if fields contain data source,
 * fields which have a data source from the backend are flagged as true
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @returns {Object} config
 */
export const enhanceConfigWithDataSource = createSelector(
  [getEntity, getInformationConfig],
  (entityData, { data: config }) => {
    if (_.isEmpty(config)) {
      return;
    }

    if (
      !entityData ||
      !Array.isArray(entityData.data_source_fields) ||
      !Array.isArray(config.modules)
    ) {
      return config;
    }

    const newConfig = { ...config };
    newConfig.modules.forEach((module) => {
      if (module.fields) {
        module.fields.forEach((field) => {
          const names = field.names || [field.name];

          field.dataSource = names.some((name) =>
            entityData.data_source_fields.includes(name)
          );
        });
      }
    });

    return newConfig;
  }
);
