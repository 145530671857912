class LogoutPageCtrl {
  /**
   * @constructor
   * @param {Object} AuthenticationService
   */
  constructor(AuthenticationService) {
    AuthenticationService.logout();
  }
}

export default LogoutPageCtrl;
