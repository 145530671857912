import template from "./credits-update.html";

/**
 * @fileOverview creditsupdate.js
 * This class manages credits in the context of their relationship with their parent.
 * We do not edit the credits entity itself here.
 */

/**
 * @class CreditsUpdateCtrl
 */
class CreditsUpdateCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $scope
   * @param {Object} MessageService
   * @param {Object} CreditsFactory
   * @param {Object} NotificationService
   */
  constructor(_, $scope, MessageService, CreditsFactory, NotificationService) {
    this._ = _;
    this.$scope = $scope;
    this.MessageService = MessageService;
    this.CreditsFactory = CreditsFactory;
    this.NotificationService = NotificationService;
  }

  /**
   * @param {object} creditsEntity
   * @param {object} parentEntity
   * @param {object} config
   * @param {object} context
   * @returns {void}
   */
  bindParams(creditsEntity, parentEntity, config, context) {
    this.parentEntity = parentEntity;
    this.config = config;
    this.creditsEntity = creditsEntity;
    this.context = context;
    this.data = {};
    this.uid = this.creditsEntity.uid;
    this.creditsEntity.type = this.getCreditsRoleTitle();
    this.getCredits();
    this.setSubscriptions();
    this._buildModalOptions();
  }

  /**
   * set pub sub subscriptions
   * @returns {void}
   */
  setSubscriptions() {
    this.creditsUpdateChannelName = `itemblock:${this.uid}-haschanged`;

    this.MessageService.registerChannel(this.creditsUpdateChannelName);
    this.MessageService.on(this.creditsUpdateChannelName, (ch, data) =>
      this.updateCredit(data)
    );

    this.creditsDeleteChannelName = `deleteSelf.${this.uid}`;

    this.MessageService.registerChannel(this.creditsDeleteChannelName);
    this.MessageService.on(this.creditsDeleteChannelName, (ch, data) =>
      this.removeCredit(data)
    );

    this.creditsUpdateRoleChannelName = "updateRoles";

    this.MessageService.registerChannel(this.creditsUpdateRoleChannelName);
    this.MessageService.on(this.creditsUpdateRoleChannelName, () =>
      this.updateRoles()
    );

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions();
    });
  }

  /**
   * De-register pub/sub subscriptions
   * @returns {void}
   */
  deregisterSubscriptions() {
    this.MessageService.unregisterChannel(this.creditsUpdateChannelName);
    this.MessageService.unregisterChannel(this.creditsDeleteChannelName);
    this.MessageService.unregisterChannel(this.creditsUpdateRoleChannelName);
  }

  /**
   * get a match from parent this.types (array of role objects)
   * and return title for displaying in dom
   * @returns {string}
   */
  getCreditsRoleTitle() {
    if (this.creditsEntity.role_url && this.context.types) {
      const currentRole = this._.find(
        this.context.types,
        (type) => type.self === this.creditsEntity.role_url
      );

      if (currentRole && currentRole.title) {
        return currentRole.title;
      }
    }

    return "";
  }

  /**
   * getCredits
   * gets Credits metadata and assigns its id to the relationship data.
   * This allows us to have both the credits entity and its relationship context in scope.
   * sets the title of role to type
   * @returns {void}
   */
  getCredits() {
    this.CreditsFactory.getByUrl(this.creditsEntity.people_url)
      .then((data) => {
        this.data = angular.extend({}, data);
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * update credits on controller
   * @param  {object} credits in the context of its relationship with the parent entity
   * @returns {void}
   */
  updateCredit(credits) {
    this.creditsEntity = credits;
    this.context.updateCredit(credits);
  }

  /**
   * update roles on message from module parent
   * @returns {void}
   */
  updateRoles() {
    this.creditsEntity.type = this.getCreditsRoleTitle();
  }

  /**
   * call controller to remove from api
   * @returns {void}
   */
  removeCredit() {
    this.context.removeCredit(this.creditsEntity);
  }

  /**
   * generateModalOptions
   * @return {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: { save: `itemblock:${this.uid}-haschanged` },
      isRemoval: true,
      entity: "credits",
    };
  }
}

/**
 * creditsUpdateDirective
 * @returns {object} directive definition object
 */
function creditsUpdateDirective() {
  return {
    restrict: "EA",
    replace: true,
    scope: {
      creditsEntity: "=",
      config: "=",
      entity: "=",
      context: "=",
    },
    controller: CreditsUpdateCtrl,
    controllerAs: "module",
    template,
    link(scope) {
      if (scope.creditsEntity) {
        scope.module.bindParams(
          scope.creditsEntity,
          scope.entity,
          scope.config,
          scope.context
        );
      }
    },
  };
}

export default creditsUpdateDirective;
