import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./tags-module.html";

class TagsDirectiveCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $state
   * @param {Object} ApiService
   * @param {Object} MessageService
   * @param {Object} ModulePagingService,
   * @param {Object} NotificationService
   * @param {Object} SchedulesFactory
   * @param {Object} ApiRequestConfigFactory
   * @param {Object} TagsFactory
   * @param {Object} APP_SETTINGS
   * @param {Object} ModalTriggerService
   * @param {Object} ApiFilterFactory
   * @param {Object} $stateParams
   */
  constructor(
    _,
    $state,
    ApiService,
    MessageService,
    ModulePagingService,
    NotificationService,
    SchedulesFactory,
    ApiRequestConfigFactory,
    TagsFactory,
    APP_SETTINGS,
    ModalTriggerService,
    ApiFilterFactory,
    $stateParams
  ) {
    this._ = _;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ApiService = ApiService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.SchedulesFactory = SchedulesFactory;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.TagsFactory = TagsFactory;
    this.ModulePagingService = ModulePagingService;
    this.ModalTriggerService = ModalTriggerService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.APP_SETTINGS = APP_SETTINGS;

    this.type = "tags";
    this.types = undefined;

    this.setSubscriptions();

    this.paginationSettings = {
      start: 0,
      limit: this.config.limit || 10,
    };
  }

  /**
   * bindParams
   * @param {Object} config
   * @param {Object} data
   * @returns {void}
   */
  init() {
    this.existing = this._.map(this.data.tags, "tag_url");
    this.tags = !this.isCreating ? [...this.data.tags] : [];
    this.count = this.tags.length;

    this.parentType = this.$stateParams.entity
      ? this.$stateParams.entity
      : this.$state.current.title;

    this.self = this.data.self;
    this.entity = {
      parentId: this.data.uid,
      parentType: this.parentType,
      parentTitle: this.data.title,
    };

    this.TagsFactory.getCategories().then((data) => {
      this.types = data.objects;
    });

    this.tagsToDisplay = [];

    this.changePage();
  }

  /**
   * Sets up all the pub sub channels
   * @returns {void}
   */
  setSubscriptions() {
    this.availableChannelNames = {
      addTag: "Modal.Save.tags.base",
    };

    this.MessageService.registerChannel(this.availableChannelNames.addTag);
    this.MessageService.on(this.availableChannelNames.addTag, (channel, data) =>
      this.addTag(data)
    );
  }

  /**
   * Angular hook fired when component is destroyed
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.availableChannelNames.addTag);
  }

  /**
   * Adds a tag
   * @param {Object} data - entity data
   * @returns {void}
   */
  addTag(data) {
    this.SchedulesFactory.getAlwaysSchedule().then((response) => {
      data.forEach((obj) => {
        const tag = {
          tag_url: obj.self,
          schedule_urls: [response.self],
        };
        this.data.tags.push(tag);
        this.tags.push(tag);
        this.existing.push(tag.tag_url);
      });
      const message =
        data.length > 1
          ? "You have successfully added multiple tags."
          : `You have successfully added ${data[0].name}`;
      this.changePage();
      this.save(message);
    });

    this.MessageService.publish(`Pagination.${this.type}`, this.count);
  }

  /**
   *  updates a tag
   *  @param {object} tag in the context of its relationship
   *  @param {string} name for messaging
   *  @returns {void}
   */
  updateTag(tag, name) {
    const tagInDataTags = this._.findIndex(
      this.data.tags,
      (obj) => obj.tag_url === tag.tag_url
    );
    const tagInTags = this._.findIndex(
      this.tags,
      (obj) => obj.tag_url === tag.tag_url
    );

    this.data.tags[tagInDataTags] = tag;
    this.tags[tagInTags] = tag;
    const message = `You have successfully updated ${name}`;
    this.MessageService.publish(
      `ScheduleWidget.Update.${tag.relationshipId}`,
      tag
    );
    this.save(message);
  }

  /**
   * Removes a tag
   * @param {Object} data - tag to remove - full tag object
   * @returns {void}
   */
  removeTag(data) {
    this._.remove(this.data.tags, (tag) => tag.tag_url === data.self);

    this.ApiService.put(
      this.data.self,
      this.data,
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    )
      .then((response) => {
        this.data = angular.extend({}, response);
        this._.remove(this.tags, (obj) => obj.tag_url === data.self);
        this._.remove(this.existing, (url) => url === data.self);
        this.changePage();
        this.NotificationService.notifyInfo(
          NotificationMessage.removeSuccess(data.name)
        );
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.removeError(data.name)
        )
      );
  }

  /**
   * Save the changes
   * @param {string} msg
   * @returns {void}
   */
  save(msg) {
    this.ApiService.put(
      this.data.self,
      this.data,
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    )
      .then((response) => {
        this.data = angular.extend({}, response);
        this.NotificationService.notifyInfo(msg);
        this.changePage();
      })
      .catch(() =>
        this.NotificationService.notifyError(NotificationMessage.saveTagError)
      );
  }

  /**
   * pagination change
   * @param {Number} start
   * @param {Number} limit
   */
  paginationChange(start, limit) {
    this.paginationSettings.start = start;
    this.paginationSettings.limit = limit;
    this.changePage();
  }

  /**
   * Changes the page in the modal
   * @returns {void}
   */
  changePage() {
    this.count = this.tags.length;
    this.MessageService.publish(`Pagination.${this.type}`, this.count);
    this.tagsToDisplay.length = 0;
    this.tagsToDisplay = this.tags.length
      ? this.ModulePagingService.urlsToGet(
          this.tags,
          this.paginationSettings.start,
          this.paginationSettings.limit
        )
      : [];
  }

  /**
   * trigger Modal for adding more items
   */
  triggerModal() {
    const options = {
      categories: this.types,
      entity: this.type,
      channels: { add: "Modal.Save.tags.base" },
      parent: this.data,
    };

    this.ApiFilterFactory.getExcludeItemsFilter(
      this.data.self,
      this.existing,
      this.count
    ).then((filters) => {
      options.filters = filters;

      this.ModalTriggerService.triggerList(this.type, options);
    });
  }
}

/**
 * Tags Directive
 * @returns {Object}
 */
function tagsDirective() {
  return {
    scope: {},
    bindToController: {
      config: "=",
      data: "<",
      isCreating: "=",
    },
    controller: TagsDirectiveCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default tagsDirective;
