import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { Map } from "immutable";

import BlockIndicator from "../block-indicator/block-indicator";

import styles from "./editor-block.scss";

/**
 * Custom editor list wrapper for any block
 * @param {Object} props
 */
export default function EditorBlock(props) {
  const defaultConfig = Map({ is_restricted: false, indicator: "p" });

  return props.children.map((child) => {
    const blockConfig = child.props.children.props.block.getIn([
      "data",
      "config",
    ]);
    const config = blockConfig || defaultConfig;

    return (
      <div
        key={`customBlock-${child.key}`}
        className={classnames(styles["editor-block"], {
          [styles["editor-block__template"]]: config.get("is_restricted"),
        })}
      >
        {props.hasIndicator && (
          <BlockIndicator
            indicator={
              config.get("is_restricted")
                ? config.get("indicator")
                : props.indicator
            }
          />
        )}
        <div className={styles["editor-block__text-container"]}>{child}</div>
      </div>
    );
  });
}

EditorBlock.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
  indicator: PropTypes.string,
};

EditorBlock.defaultProps = {
  children: undefined,
  hasIndicator: true,
};
