import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

import MediaPreviewBlock from "components/_react/editor/media-preview-block/media-preview-block";

import BlockHeader from "../block-header/block-header";
import BlockFocus from "../block-focus/block-focus";

import styles from "./video-block.scss";

/**
 * Video Block Component for rendering videos in the editor
 * @param {Object} props
 */
export default class VideoBlock extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    data: PropTypes.shape({
      media: PropTypes.shape({
        title: PropTypes.string,
        image_urls: PropTypes.arrayOf(
          PropTypes.shape({
            url: PropTypes.string,
          })
        ),
      }),
    }).isRequired,
    blockProps: PropTypes.shape({
      replaceMedia: PropTypes.func,
      onModalSave: PropTypes.func,
    }).isRequired,
    block: PropTypes.shape({
      getKey: PropTypes.func,
      getLength: PropTypes.func,
    }).isRequired,
    selection: PropTypes.shape({
      getAnchorKey: PropTypes.func,
    }).isRequired,
  };

  /**
   * constructor
   * @param  {Object} props
   */
  constructor(props) {
    super(props);

    this.replaceVideo = this.replaceVideo.bind(this);
  }

  /**
   * replaceVideo - replace with another media block e.g. image or media-placeholder
   * @param   {String} type
   * @param   {Object} data
   */
  replaceVideo(type, data) {
    const { block } = this.props;
    const selectionState = DraftHelpers.createBlockSelection(
      block.getKey(),
      0,
      block.getLength()
    );

    this.props.blockProps.replaceMedia(selectionState, block, data, type);
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <BlockFocus
        block={this.props.block}
        selection={this.props.selection}
        render={(state) => (
          <Fragment>
            <div
              className={classnames(styles["video-block"], {
                [styles["video-block--has-focus"]]: state.hasFocus,
              })}
            >
              <BlockHeader
                type="video"
                headerText={`Episode: ${this.props.data.media.title}`}
                renderMediaBlock={this.replaceVideo}
                block={this.props.block}
                onModalSave={this.props.blockProps.replaceMedia}
              />
              <MediaPreviewBlock data={this.props.data.media} />
            </div>
          </Fragment>
        )}
      />
    );
  }
}
