import template from "./dynamic-object-item-block.html";

class DynamicObjectItemBlockCtrl {
  /**
   * @constructor
   * @param   {object} $scope
   * @param   {object} SetContentsActionCreators
   * @param   {object} SetContentsStore
   * @param   {object} BreadcrumbService
   * @param   {object} RouteService
   * @param   {object} _
   */
  constructor(
    $scope,
    SetContentsActionCreators,
    SetContentsStore,
    BreadcrumbService,
    RouteService,
    _
  ) {
    this.$scope = $scope;
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.SetContentsStore = SetContentsStore;
    this.BreadcrumbService = BreadcrumbService;
    this.RouteService = RouteService;
    this._ = _;
    this.contents = [];
  }

  /**
   * init
   * @returns {void}
   */
  $onInit() {
    this.url = this.data.contentItem[this.data.displayedLanguage].url;
    this.config = this.data.config;
    this.initialPosition = {
      ...this.data.scheduledItem[this.data.displayedLanguage],
    }.position;

    this.isContentInvalid = !this.isEditing && this._isContentInvalid();
    this._buildModalOptions();
    this.messageData = { message: this.data.displayTitle };
    this._setupListeners();
    this._setHasActions();
    this._hasActiveStatus();
    this.objectTypeSingular = this?.data?.objectType?.endsWith("s")
      ? this.data.objectType.slice(0, -1)
      : this.data.objectType;
  }

  /**
   * _setupListeners
   */
  _setupListeners() {
    this.SetContentsStore.registerChangeListener(() => {
      this.initialPosition = {
        ...this.data.scheduledItem[this.data.displayedLanguage],
      }.position;
    });
  }

  /**
   * _buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    const parentEntity = {
      parentId: this.data.scheduledItem[this.data.displayedLanguage].uid,
      parentType: "scheduledItem",
    };

    this.parentData = {};
    this.parentData.parent = parentEntity;

    this.modalOptions = {
      channels: { save: `itemblock:${this.data.uid}-haschanged` },
      parent: this.parentData.parent,
    };
  }

  /**
   * hasActions
   * @return {boolean}
   */
  _setHasActions() {
    this.hasActions = !!this.data.config.columns.find(
      (column) => column.widget === "itemActions"
    );
  }

  /**
   * content is invalid when the contents of a computed scheduled item
   * are the computed scheduled item itself
   * @returns {Boolean}
   */
  _isContentInvalid() {
    return this.data.previewItems.some(
      (item) =>
        item.content_url.self ===
        this.data.contentItem[this.data.displayedLanguage].self
    );
  }

  /**
   * Creates a double check to see if active status property is visible on the
   * computed scheduled item.
   */
  _hasActiveStatus() {
    this.hasActiveStatus = this._.has(this.data, "isActive");
  }

  /**
   * Builds an item url based on its type
   * @param {String} name
   * @param {String} uid
   * @returns {String}
   *
   */
  buildItemUrl(name, uid) {
    return this.RouteService.buildURL({ name, uid });
  }

  /**
   * reposition data and save when enter key is pressed
   * @returns {void}
   *
   * Reposition an item
   * @callback ngKeyup
   */
  repositionItem() {
    this.data.scheduledItem[this.data.displayedLanguage].position =
      this.data.scheduledItem[this.data.displayedLanguage].position || 0;
    const position = parseInt(
      this.data.scheduledItem[this.data.displayedLanguage].position
    );

    if (
      !isNaN(position) &&
      position >= 0 &&
      position !== this.initialPosition
    ) {
      this.SetContentsActionCreators.positionItem(
        this.data,
        position,
        this.initialPosition
      );
    }
  }

  /**
   * Remove an item from the set
   * @callback ngclick
   */
  removeItem() {
    this.SetContentsActionCreators.removeScheduledItem(
      this.data.scheduledItem[this.data.displayedLanguage]
    );
  }

  /**
   * Resets the breadcrumb when navigating away from a set
   */
  setNavigationHandler() {
    this.BreadcrumbService.setNavigationHandler();
  }

  /**
   * Returns a title or name for a content URL if they exist
   * @param {*} contentUrl an object
   * @returns string
   */
  getContentTitleOrName(contentUrl) {
    if (contentUrl && (contentUrl.title || contentUrl.name)) {
      return contentUrl.title || contentUrl.name;
    }
    return "";
  }
}

const DynamicObjectItemBlockComponent = {
  bindings: {
    data: "<",
    isEditing: "<",
  },
  controller: DynamicObjectItemBlockCtrl,
  controllerAs: "component",
  template,
};

export default DynamicObjectItemBlockComponent;
