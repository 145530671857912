/**
 * @constructor
 * @param {Object} ApiService
 * @returns {Object}
 */
function UserlistsFactory(
  ApiService,
  ApiRequestConfigFactory,
  SkylarkApiInfoService,
  GlobalParamsService
) {
  const factory = {};

  factory.get = (filters) => {
    const config = ApiRequestConfigFactory.createRequestConfig({
      useDefaultParams: true,
      requestConfig: {
        params: filters,
      },
    });
    return ApiService.get("/api/userlists/", config);
  };

  /**
   * creates a userlist, requires a language in order to set it correctly
   * @param data
   * @return {Promise<unknown>}
   */
  factory.create = (data) =>
    SkylarkApiInfoService.getDefaultLanguage().then((language) => {
      const requestConfig = {
        headers: {
          "Accept-Language": GlobalParamsService.getLanguage() || language,
          "Cache-Control": "no-cache",
        },
      };

      return ApiService.post("/api/userlists/", data, requestConfig);
    });

  factory.getItems = (uid, filters) => {
    const config = ApiRequestConfigFactory.createRequestConfig({
      useDefaultParams: true,
      overrideGlobalLanguage: true,
      useWildCard: true,
      requestConfig: {
        params: filters,
      },
    });
    return ApiService.get(`/api/userlists/${uid}/items/`, config);
  };

  factory.addItem = (uid, item, filters) => {
    const config = ApiRequestConfigFactory.createRequestConfig({
      useDefaultParams: true,
      requestConfig: {
        params: filters,
      },
    });

    return ApiService.post(`/api/userlists/${uid}/items/`, item, config);
  };

  factory.removeItem = (uid, itemUid) =>
    ApiService.delete(`/api/userlists/${uid}/items/${itemUid}/`);

  return factory;
}

export default UserlistsFactory;
