import React from "react";
import { react2angular } from "react2angular";
import { Version } from "@skylark/react";
import withChakraUiProvider from "hocs/with-chakra-ui-provider";

const buildTimestamp = __BUILD_TIMESTAMP__;
const nginxOrStandalone = __IN_NGINX_CONTAINER__ ? "NGINX" : "STND";
const skylarkRelease =
  __SKYLARK_RELEASE__ || (__V8_ENVIRONMENT__ ? "8.X" : nginxOrStandalone);

/**
 * Version component wrapper
 */
const VersionWrapper = () => (
  <Version skylarkRelease={skylarkRelease} buildTimestamp={buildTimestamp} />
);

const reactVersion = angular
  .module("Skylark.Components.React.Version", [])
  .component("version", react2angular(withChakraUiProvider(VersionWrapper)));

export default VersionWrapper;

export { reactVersion };
