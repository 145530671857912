/**
 * languages reducer
 * @param   {Object} state - languages
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = {}, action) {
  switch (action.type) {
    case "REQUEST_LANGUAGES":
      return {
        ...state,
        isFetching: true,
      };
    case "RECEIVE_LANGUAGES":
      return {
        ...state,
        isFetching: true,
        languages: action.languages,
      };
    case "REQUEST_GLOBAL_LANGUAGE":
      return {
        ...state,
        isFetching: true,
        globalLanguage: "",
      };
    case "RECEIVE_GLOBAL_LANGUAGE":
      return {
        ...state,
        isFetching: false,
        globalLanguage: action.globalLanguage,
      };
    case "SET_NEW_ENTITY_LANGUAGE_VERSION":
      return {
        ...state,
        newEntityLanguageVersion: action.isoCode,
      };
    default:
      return state;
  }
}
