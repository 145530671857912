import template from "./calendar-grid.html";

/**
 * @fileOverview
 * Calendar component for rendering EPG views.
 * Handles calculating the positioning each slot relative to the calendar grid.
 */
class CalendarGridCtrl {
  /**
   * @constructor
   * @param {Object} _ lodash
   * @param {Object} momentJS
   * @param {Object} CalendarGridService
   * @param {Object} MessageService
   */
  constructor(_, momentJS, CalendarGridService, MessageService) {
    this._ = _;
    this.momentJS = momentJS;
    this.CalendarGridService = CalendarGridService;
    this.MessageService = MessageService;
  }

  /**
   * init
   * @param {element} element
   * @returns {void}
   */
  init() {
    this.hours = this._buildHourRange();
    this.days = this._buildDayRange(this.dateInterval);
    this.containsToday = this.CalendarGridService.containsToday(this.days);

    this._prepareViewData();
    this._setupSubscriptions();
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe("EntityEPG.dateChange", (channel, data) => {
      this.data = data;
      this.days = this._buildDayRange(this.dateInterval);
      this.containsToday = this.CalendarGridService.containsToday(this.days);
      this.todayInISODate = this._extractDay(this.momentJS().format());
      this._prepareViewData();
    });
  }

  /**
   * _buildHourRange
   * @returns {array} array of hour strings in hh:mm format
   */
  _buildHourRange() {
    return this._.range(0, 24).map(
      (hour) => `${this._.padStart(hour.toString(), 2, "0")}:00`
    );
  }

  /**
   * _buildDayRange
   * @param   {Object} dateInterval - object with start and end properties
   * @returns {array} -  array of days in the interval, with dates in ISO format
   */
  _buildDayRange(dateInterval) {
    const diff = dateInterval.end.diff(dateInterval.start, "days");
    const numberOfDays = this._.range(0, diff + 1);

    return numberOfDays.map((day, index) => {
      const currentDayDate = dateInterval.start
        .clone()
        .add(index, "day")
        .format();

      return this._extractDay(currentDayDate);
    });
  }

  /**
   * _groupByDay
   * @param {Array} slotList - list of slots for a given date range from the API
   * @returns {object} slotsGroupedBy - slots grouped by day in the range
   */
  _groupByDay(slotList) {
    const slotsGroupedBy = {};

    this.days.forEach((day) => {
      slotsGroupedBy[day] = slotList.filter((slot) => {
        const startDay = this._extractDay(
          this.momentJS(slot.announced_start).format()
        );
        const endDay = this._extractDay(
          this.momentJS(slot.announced_end).format()
        );

        return startDay === day || endDay === day;
      });
    });

    return slotsGroupedBy;
  }

  /**
   * _prepareViewData
   * @returns {void}
   */
  _prepareViewData() {
    this.data.forEach((timeslot, index) => {
      timeslot.viewData = this._buildViewData(timeslot);
      timeslot.viewData.hasAdjacent = this._hasAdjacent(
        timeslot,
        this.data[index - 1],
        this.data[index + 1]
      );
      timeslot.viewData.isOverlapping = this._isOverlapping(
        timeslot,
        this.data,
        index
      );
    });

    this.viewData = this._assignDayOverlaps(this._groupByDay(this.data));
  }

  /**
   * _buildViewData
   * @param   {object} timeslot
   * @returns {object} viewData for the timeslot
   */
  _buildViewData(timeslot) {
    return {
      duration: this._calculateDuration(
        timeslot.announced_start,
        timeslot.announced_end
      ),
      startTime: this._getLocalisedTime(timeslot.announced_start),
      endTime: this._getLocalisedTime(timeslot.announced_end),
    };
  }

  /**
   * _calculateAdjacent
   * @param   {object} currentSlot
   * @param   {object} previousSlot
   * @param   {object} nextSlot
   * @returns {object} containing booleans - whether the current slot has an adjacent one before or after it
   */
  _hasAdjacent(currentSlot, previousSlot, nextSlot) {
    const slotStart = this._getLocalisedTime(currentSlot.announced_start);
    const slotEnd = this._getLocalisedTime(currentSlot.announced_end);
    let topAdjacent = false;
    let bottomAdjacent = false;
    if (nextSlot) {
      const nextSlotStart = this._getLocalisedTime(nextSlot.announced_start);
      bottomAdjacent = slotEnd === nextSlotStart;
    }
    if (previousSlot) {
      const previousSlotEnd = this._getLocalisedTime(
        previousSlot.announced_end
      );
      topAdjacent = slotStart === previousSlotEnd;
    }

    return { bottom: bottomAdjacent, top: topAdjacent };
  }

  /**
   * _isOverlapping
   * @param   {object} currentSlot
   * @param   {Array} data
   * @param   {index} index
   * @returns {Object} - object with properties defining the overlapping time
   */
  _isOverlapping(currentSlot, data, index) {
    const slotsBefore = index > 0 ? data.slice(0, index) : [];
    const slotsAfter =
      index + 1 !== data.length ? data.slice(index + 1, data.length) : [];

    let overlapPrevious = 0;
    let overlapNext = 0;

    if (slotsBefore.length) {
      overlapPrevious = this._findOverlapDuration(
        slotsBefore,
        currentSlot.announced_start,
        "announced_end"
      );
    }

    if (slotsAfter.length) {
      overlapNext = this._findOverlapDuration(
        slotsAfter,
        currentSlot.announced_end,
        "announced_start"
      );
    }

    return {
      overlapPrevious,
      overlapNext,
    };
  }

  /**
   * _findOverlapDuration
   * @param   {Array} slotList - list of slots to itate
   * @param   {String} date - current date to compare
   * @param   {String} fieldToCompare - announced_start || announced_end
   * @returns {Number} the duration of the overlap
   */
  _findOverlapDuration(slotList, date, fieldToCompare) {
    const overlapSlot = slotList.find((current) => {
      const hasOverlap =
        fieldToCompare === "announced_start"
          ? this._hasOverlap(date, current[fieldToCompare])
          : this._hasOverlap(current[fieldToCompare], date);

      return hasOverlap;
    });

    if (overlapSlot) {
      return Math.abs(
        this._calculateDuration(overlapSlot[fieldToCompare], date)
      );
    }

    return 0;
  }

  /**
   * _hasOverlap description
   * @param   {string} endDate (ISO)
   * @param   {string} startDate (ISO)
   * @returns {Boolean} whether these dates overlap
   */
  _hasOverlap(endDate, startDate) {
    const momentStartDate = this.momentJS(startDate).startOf("minute");
    const momentEndDate = this.momentJS(endDate).startOf("minute");

    return (
      this.momentJS(momentStartDate).isBefore(this.momentJS(momentEndDate)) ||
      this.momentJS(momentEndDate).isAfter(momentStartDate)
    );
  }

  /**
   * _calculateDuration
   * @param   {string} startDate (ISO)
   * @param   {string} endDate (ISO)
   * @returns {Number} difference between the two provided dates, ignoring seconds
   */
  _calculateDuration(startDate, endDate) {
    const momentStartDate = this.momentJS(startDate).startOf("minute");
    const momentEndDate = this.momentJS(endDate).startOf("minute");

    return momentEndDate.diff(momentStartDate, "minutes", false);
  }

  /**
   * _assignDayOverlaps
   * @param   {object} slotList - grouped slots list
   * @returns {object} a new copy of the slot list with day overlaps
   */
  _assignDayOverlaps(slotList) {
    const slotListCopy = {};
    for (const day in slotList) {
      const date = day;
      slotListCopy[day] = slotList[day].map((timeslot) => {
        const newTimeslot = this._.cloneDeep(timeslot);
        newTimeslot.isFromDayBefore = this._isOverlappingDay(
          this.momentJS(timeslot.announced_start).format(),
          date
        );
        newTimeslot.isFromDayAfter = this._isOverlappingDay(
          this.momentJS(timeslot.announced_end).format(),
          date
        );

        return newTimeslot;
      });
    }

    return slotListCopy;
  }

  /**
   * _isOverlappingDay
   * @param   {string} isoString - date to compare
   * @param   {string} day - a given day
   * @returns {Boolean} whether the iso string day is the same day
   */
  _isOverlappingDay(isoString, day) {
    return this._extractDay(this.momentJS(isoString).format()) !== day;
  }

  /**
   * _extractDay
   * @param   {string} isoString
   * @returns {string} substring with date only
   */
  _extractDay(isoString) {
    const endIndex = isoString.indexOf("T");

    return isoString.substring(0, endIndex);
  }

  /**
   * _getLocalisedTime
   * @param   {string} date (ISO)
   * @returns {string} formatted localised time, ignoring seconds
   */
  _getLocalisedTime(date) {
    return this.momentJS(date).startOf("minute").format("HH:mm");
  }
}

/**
 * calendarGridDirective
 * @returns {Object} DDO
 */
function calendarGridDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      data: "=",
      dateInterval: "=",
      onClick: "&",
    },
    template,
    controller: CalendarGridCtrl,
    controllerAs: "component",
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default calendarGridDirective;
