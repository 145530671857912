import angular from "angular";
import calendarGridDirective from "./calendar-grid.directive";
import timeSlotDirective from "./time-slot/time-slot.directive";
import timeIndicator from "./time-indicator/time-indicator.directive";
import CalendarGridService from "./shared/calendar-grid.service";

const CalendarGrid = angular
  .module("Skylark.Components.CalendarGrid", [])
  .service("CalendarGridService", CalendarGridService)
  .directive("calendarGrid", calendarGridDirective)
  .directive("timeSlot", timeSlotDirective)
  .directive("timeIndicator", timeIndicator);

export default CalendarGrid;
