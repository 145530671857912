class SchedulingService {
  /**
   * @constructor
   * @param {Object} momentJS
   * @param {Object} _
   */
  constructor(momentJS, _) {
    this.momentJS = momentJS;
    this._ = _;

    this.today = this.momentJS();
  }

  /**
   * Checks if the schedule has availability now
   * @param {Object} schedule
   * @return {boolean}
   * @private
   */
  _hasAvailability(schedule) {
    const startDate = schedule.starts;
    const endDate = this._validateEnds(schedule);

    return (
      this.today.diff(startDate) > 0 &&
      (this.today.diff(endDate) < 0 || this._.isNull(endDate))
    );
  }

  /**
   * Checks if the schedule will become active within 30 days
   * @param {Object} schedule
   * @return {boolean}
   * @private
   */
  _hasAvailabilitySoon(schedule) {
    return this.today.diff(schedule.starts, "days") > -30;
  }

  /**
   * Checks if schedule will expire within 30 days
   * @param {Object} schedule
   * @return {boolean}
   * @private
   */
  _isExpiringSoon(schedule) {
    const endDate = this._validateEnds(schedule);
    return this.today.diff(endDate, "days") > -30;
  }

  /**
   * isAvailable
   * @param {object} schedule
   * @return {boolean}
   */
  isAvailable(schedule) {
    return this._hasAvailability(schedule);
  }

  /**
   * isExpired
   * @param {object} schedule
   * @return {boolean}
   */
  isExpired(schedule) {
    const endDate = this._validateEnds(schedule);
    return this.today.diff(endDate) > 0;
  }

  /**
   * isFuture
   * @param {object} schedule
   * @return {boolean}
   */
  isFuture(schedule) {
    const startDate = schedule.starts;

    return this.today.diff(startDate) < 0;
  }

  /**
   * isExpiring
   * @param {Object} schedule
   * @return {boolean}
   */
  isExpiring(schedule) {
    return (
      this._isExpiringSoon(schedule) &&
      !this.isExpired(schedule) &&
      !this.isBecomingActive(schedule)
    );
  }

  /**
   * isBecomingActive
   * @param {Object} schedule
   * @return {boolean}
   */
  isBecomingActive(schedule) {
    return (
      this._hasAvailabilitySoon(schedule) &&
      !this.isExpired(schedule) &&
      !this._hasAvailability(schedule)
    );
  }

  /**
   * buildTimeTillExpired
   * @param {Object} schedule
   * @returns {string}
   */
  buildTimeRemaining(date) {
    const deltaTime = Math.abs(this.today.diff(date));

    return this._buildString(deltaTime);
  }

  /**
   * Build the string based on duration
   * @param {number} deltaTime
   * @return {string}
   * @private
   */
  _buildString(deltaTime) {
    const duration = this.momentJS.duration(deltaTime, "ms");

    const oneHour = this.today.diff(this.momentJS().subtract(1, "hour"));
    const oneDay = this.today.diff(this.momentJS().subtract(1, "day"));

    if (deltaTime < oneHour) {
      const minutes = Math.floor(duration.asMinutes());

      return `${minutes} ${minutes < 2 ? "minute" : "minutes"}`;
    }
    if (deltaTime < oneDay) {
      const hours = Math.floor(duration.asHours());
      const minutes = Math.floor(duration.asMinutes()) - 60 * hours;
      let minuteSting = "";

      if (minutes === 1) {
        minuteSting = ` ${minutes} minute`;
      } else if (minutes > 1) {
        minuteSting = ` ${minutes} minutes`;
      }

      return `${hours} ${hours < 2 ? "hour" : "hours"}${minuteSting}`;
    }

    const days = Math.floor(this.momentJS.duration(deltaTime, "ms").asDays());
    const hours =
      Math.floor(this.momentJS.duration(deltaTime, "ms").asHours()) - 24 * days;
    let hourString = "";

    if (hours === 1) {
      hourString = ` ${hours} hour`;
    } else if (hours > 1) {
      hourString = ` ${hours} hours`;
    }

    return `${days} ${days < 2 ? "day" : "days"}${hourString}`;
  }

  /**
   * Check if the array contains any object about to go live
   * @param {Array} schedules
   * @return {boolean}
   */
  hasSchedulesBecomingActive(schedules) {
    return schedules.some((schedule) => this.isBecomingActive(schedule));
  }

  /**
   * Check if the array contains any schedules about to expire
   * @param {Array} schedules
   * @return {boolean}
   */
  hasExpiringSchedules(schedules) {
    return schedules.some((schedule) => this.isExpiring(schedule));
  }

  /**
   * Returns a schedule's end timestamp in a valid format
   * @param {Object} schedule
   * @return {string}
   * @private
   */
  _validateEnds(schedule) {
    return schedule.ends !== undefined ? schedule.ends : null;
  }
}

export default SchedulingService;
