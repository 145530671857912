import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "./dropdown-wrapper.scss";

/**
 * DropdownWrapper
 */
export default function DropdownWrapper(ComposedDropdown) {
  return class Dropdown extends Component {
    /**
     * propTypes
     * @type {Object}
     */
    static propTypes = {
      onSelect: PropTypes.func.isRequired,
    };

    /**
     * @constructor
     * @param   {Object} props
     */
    constructor(props) {
      super(props);

      this._openDropdown = this._openDropdown.bind(this);
      this._closeDropdown = this._closeDropdown.bind(this);

      this.state = {
        isOpen: false,
      };
    }

    /**
     * onSelect
     * @param  {Object} element
     */
    onSelect = (element) => {
      this.props.onSelect(element);
      this._closeDropdown();
    };

    /**
     * Closes the dropdown
     */
    _openDropdown() {
      this.setState({ isOpen: true });
    }

    /**
     * Closes the dropdown
     */
    _closeDropdown() {
      this.setState({ isOpen: false });
    }

    /**
     * stopClickPropagation
     * @param  {Object} event
     */
    stopClickPropagation(event) {
      event.stopPropagation();
    }

    /**
     * render
     * @returns {Node}
     */
    render() {
      return (
        <div
          onClick={(event) => this.stopClickPropagation(event)}
          className={styles["dropdown-wrapper"]}
          onMouseEnter={this._openDropdown}
          onMouseLeave={this._closeDropdown}
          aria-hidden={!this.state.isOpen}
          role="listbox"
          tabIndex="0"
        >
          <ComposedDropdown
            {...this.props}
            isOpen={this.state.isOpen}
            onSelect={this.onSelect}
          />
        </div>
      );
    }
  };
}
