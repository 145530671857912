import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const ArrowLeftIcon = createIcon({
  displayName: "ArrowLeftIcon",
  path: (
    <path
      clipRule="evenodd"
      d="M3.15135 5.25007L12.9975 5.25007L12.9975 6.75007L3.15135 6.75007L6.52787 10.1266L5.46721 11.1873L1.03003 6.75007H0.997544V6.71759L0.280029 6.00007L0.997544 5.28256V5.25007H1.03003L5.46721 0.812887L6.52787 1.87355L3.15135 5.25007Z"
      fill="#226DFF"
      fillRule="evenodd"
    />
  ),
});
