import * as EntityConfigAction from "store/entities-config/entities-config.action";
import { getRelationshipConfig } from "store/entities-config/entities-config.selector";
import { getEntity } from "store/entity/entity.selector";
import template from "./entity-relationships.html";

/**
 * The relationship tab for entities
 * @TODO there is an issue that this page is manipulating directly the parents data,
 * the relationship module is taking a data property in two way data binding,
 * this should be replaced by redux store when possible
 *
 * @class
 */
class EntityRelationshipsPageCtrl {
  /**
   * Class constructor
   * @param {Object} $scope
   * @param {Object} _
   * @param {Object} ReduxConnector
   * @param {Object} EntityFactory
   * @param {Object} EntityRelationshipFactory
   * @param {Object} HistoryService
   * @param {Object} MessageService
   * @constructor
   */
  constructor(
    $scope,
    _,
    ReduxConnector,
    EntityFactory,
    EntityRelationshipFactory,
    HistoryService,
    MessageService
  ) {
    this.$scope = $scope;
    this._ = _;
    this.ReduxConnector = ReduxConnector;
    this.EntityFactory = EntityFactory;
    this.EntityRelationshipFactory = EntityRelationshipFactory;
    this.HistoryService = HistoryService;
    this.MessageService = MessageService;

    this.relationshipChannelName = "relationshipPage.update";
    this.entityName = this.EntityFactory.getEntityName();

    this.connectToStore();
    this.init();
  }

  /**
   * Initialise controller values
   * Reset EntityRelationshipFactory to create a new instance on this page keeping state only
   * for this entity
   * @access private
   * @returns {void}
   */
  init() {
    this.EntityRelationshipFactory.reset();

    this.getConfiguration();
    this.setupSubscriptions();
  }

  /**
   * Set up Pub/Sub subscriptions
   * @access private
   * @returns {void}
   */
  setupSubscriptions() {
    this.MessageService.subscribe(
      this.relationshipChannelName,
      (channel, data) => {
        this.updatePage(data);
      }
    );
  }

  /**
   * Get the relationship configuration
   * @access private
   * @returns {void}
   */
  getConfiguration() {
    this.store.fetchRelationshipConfig(this.entityName);
  }

  /**
   * Update the UI dependencies
   * @access private
   * @returns {void}
   */
  updateProgressIndicators() {
    if (this.relationshipData.optionalProgressData) {
      this.MessageService.publish(
        this.relationshipData.optionalProgressData.channelName,
        this.relationshipData.optionalProgressData
      );
    }

    if (this.relationshipData.requiredProgressData) {
      this.MessageService.publish(
        this.relationshipData.requiredProgressData.channelName,
        this.relationshipData.requiredProgressData
      );

      this.MessageService.publish(
        "relationshipsRequiredIndicator.update",
        this.relationshipData.tabIndicator
      );
    }
  }

  /**
   * Handles the updating of the page ui
   * @access public
   * @param {object} relationshipData - relationship metadata
   * @returns {void}
   */
  updatePage(relationshipData) {
    if (relationshipData.isRequired) {
      this.relationshipData = this.EntityRelationshipFactory.updateRequired(
        relationshipData,
        this.config
      );
    } else {
      this.relationshipData = this.EntityRelationshipFactory.updateOptional(
        relationshipData,
        this.config
      );
    }

    this.updateProgressIndicators();
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToStore = {
      ...EntityConfigAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToStore
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    return {
      data: getEntity(state).data,
      config: getRelationshipConfig(state, this.entityName).data,
    };
  }

  /**
   * on component removed
   * @returns {void}
   */
  $onDestroy() {
    this.disconnect();
    this.MessageService.unregisterChannel(this.relationshipChannelName);
  }
}

const entityRelationshipsPageComponent = {
  controller: EntityRelationshipsPageCtrl,
  controllerAs: "relationships",
  template,
};

export default entityRelationshipsPageComponent;
