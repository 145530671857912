class AsyncSetActionsService {
  /**
   * @constructor
   * @param {Object} _
   */
  constructor(_, EntityTypeHelpersService) {
    this._ = _;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
  }

  /**
   * Build the list of fields to use for the API call
   * @param  {Array} flattenedConfig
   * @return {Array}
   */
  buildFieldsToInclude(flattenedConfig) {
    const displayTitleFields = this._getContentDisplayFields(flattenedConfig);
    const fieldsToInclude = [];

    flattenedConfig.forEach((configItem) => {
      configItem.fields.forEach((field) => {
        fieldsToInclude[fieldsToInclude.length] = field;
      });
    });

    return this._.uniq([...fieldsToInclude, ...displayTitleFields]);
  }

  /**
   * If an additional display property that is not a field is required,
   * create a fake object so it gets added to the dynamic fields to filter list.
   * @param   {Array} flattenedConfig
   * @returns {Object} a fake object representing a field
   * @private
   */
  _getContentDisplayFields(flattenedConfig) {
    return flattenedConfig
      .filter(
        (configItem) =>
          configItem.display_as &&
          !this._hasDisplayContentTitle(
            configItem.fields,
            configItem.display_as
          )
      )
      .map((configItem) => ({
        field_location: "contentItem",
        name: configItem.display_as,
      }));
  }

  /**
   * _hasDisplayContentTitle
   * @returns {Boolean}
   * @private
   */
  _hasDisplayContentTitle(fields, fieldName) {
    return !!fields.find((field) => field.name === fieldName);
  }

  /**
   * buildItemsFromSetResponse
   * @param  {Object} response
   * @return {Object}
   */
  buildItemsFromSetResponse(response) {
    return response.items.map((item) => {
      const typeFromUrl = this.EntityTypeHelpersService.getRawType(
        item.content_url.self
      );

      return {
        uid: item.uid,
        contentUrl: item.content_url.self,
        displayedLanguage: item.language,
        scheduledItem: {
          [item.language]: { ...item, content_url: item.content_url.self },
        },
        self: item.self,
        contentItem: {
          [item.content_url.language || item.language]: item.content_url,
        },
        type: item.content_url.set_type_slug || typeFromUrl,
      };
    });
  }

  /**
   * filterContentItemsThatSupportLanguages
   * @param  {Array} items
   * @return {Array}
   */
  filterContentItemsThatSupportLanguages(items) {
    return items
      .filter((item) => item.config.unique)
      .map((item) => item.contentItem[Object.keys(item.contentItem)[0]]);
  }

  /**
   * assignSchedulesToItems
   * @param  {Array} schedules
   * @param  {Array} items
   * @return {Array} items with schedules
   */
  assignSchedulesToItems(schedulesToAssign, items) {
    return items.map((item) => {
      const schedules = item.scheduledItem[
        item.displayedLanguage
      ].schedule_urls.map((url) =>
        schedulesToAssign.find((schedule) => url === schedule.self)
      );

      return { ...item, schedules };
    });
  }

  /**
   * assignPreviewItemsToCSI
   * @param  {Array} items
   * @return {Array}
   */
  assignPreviewItemsToCSI(items, previewData) {
    return items.map((item) => {
      if (item.type === "computed-scheduled-items") {
        const previewItems = previewData.filter(
          (content) => content.uid === item.uid
        );

        return { ...item, previewItems };
      }

      return item;
    });
  }
}

export default AsyncSetActionsService;
