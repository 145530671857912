/**
 * FeatureToggleDependencyFactory
 * @fileOverfiew A utility factory to toggle CMS-side injectable configuration.
 * Only supports alpha at the moment and will globally turn on
 * all the alpha configuration available for a given controller.
 * This prompts a rethink on how we define non-deployment specific
 * configuration for components.
 * A caveat: for constants only for now (hence uppercase)
 */

/**
 * FeatureToggleDependencyFactory
 * @param {Object} $injector
 * @param {Object} $state
 * @param {Object} FeatureToggleService
 * @returns {Object} factory
 */
function FeatureToggleDependencyFactory(
  $injector,
  $state,
  FeatureToggleService
) {
  const factory = {};

  /**
   * getDependency - get a single dependency
   * @param   {string} dependencyName
   * @returns {Object} an angular provider instance
   */
  factory.getDependency = (dependencyName) => {
    if (
      FeatureToggleService.isAlpha() &&
      $injector.has(`${dependencyName}_ALPHA`)
    ) {
      return $injector.get(`${dependencyName}_ALPHA`);
    }

    return $injector.get(dependencyName);
  };

  /**
   * getDependencies
   * @description  assigns to class passed through - this is potentially dangerous?
   * @param   {Array} dependencyList - a list of dependencies
   * @param  {Object} controller - usually a class passed on from a directive as `this`
   * @returns {void}
   */
  factory.getDependencies = (dependencyList, controller) => {
    dependencyList.forEach((dependency) => {
      controller[dependency] = factory.getDependency(dependency);
    });
  };

  /**
   * getRouterAlphaControllerName
   * @param   {string} controllerNameAs
   * @returns {function|string} a state redirect or the controller to inject
   */
  factory.disableRouteOnAlpha = (controllerNameAs) => {
    if (FeatureToggleService.isAlpha()) {
      return $state.go("Error");
    }
    return controllerNameAs;
  };

  return factory;
}

export default FeatureToggleDependencyFactory;
