import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  CompositeDecorator,
  Editor,
  EditorState,
  RichUtils,
  getDefaultKeyBinding,
  KeyBindingUtil,
} from "draft-js";

import { FeatureToggle } from "skylarklib/helpers";

import { KEYCODES } from "skylarklib/constants/keycodes.constants";

import EditorControls from "components/_react/editor/editor-controls/editor-controls-container";
import LinkTooltip from "components/_react/editor/links/link-tooltip/link-tooltip-container";

import DraftBlockRenderer from "components/_react/editor/helpers/draft-block-renderer/draft-block-renderer";
import InlineStyleMap from "components/_react/editor/helpers/draft-inline-style-map/draft-inline-style-map";
import RenderMap from "components/_react/editor/helpers/draft-render-map/draft-render-map";
import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";
import * as LinkHelpers from "components/_react/editor/helpers/link-helpers/link-helpers";
import * as MediaHelpers from "components/_react/editor/helpers/media-helpers/media-helpers";
import * as PasteHelpers from "components/_react/editor/helpers/paste-helpers/paste-helpers";
import * as TextPlaceholders from "components/_react/editor/helpers/text-placeholders/text-placeholders";
import HTMLHelpers from "components/_react/editor/helpers/html-helpers/html-helpers";
import splitBlock from "components/_react/editor/helpers/split-block-helpers/split-block-helpers";

import styles from "./text-editor.scss";

export default class TextEditor extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    save: PropTypes.func.isRequired,
    setEditorState: PropTypes.func.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        content: PropTypes.string,
        content_type: PropTypes.string,
        triggers: PropTypes.arrayOf(PropTypes.object),
      })
    ).isRequired,
    editor: PropTypes.shape({
      editorState: PropTypes.object,
    }),
    editorMediaMetadata: PropTypes.shape({
      isDisplayed: PropTypes.bool,
    }).isRequired,
    configuration: PropTypes.shape({
      data: PropTypes.object,
    }).isRequired,
    controlsFixed: PropTypes.bool.isRequired,
    resetModalList: PropTypes.func.isRequired,
  };

  /**
   * propTypes
   * @type {Object}
   */
  static defaultProps = {
    editor: {},
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.blockRendererFn = this.blockRendererFn.bind(this);
    this.blockStyling = this.blockStyling.bind(this);
    this._handleTripleClick = this._handleTripleClick.bind(this);
    this._isTripleClick = this._isTripleClick.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
    this.handleBeforeInput = this.handleBeforeInput.bind(this);
    this.keyBindingFn = this.keyBindingFn.bind(this);
    this.renderMediaBlock = this.renderMediaBlock.bind(this);
    this.replaceMediaBlock = this.replaceMediaBlock.bind(this);
    this.promptForLink = this.promptForLink.bind(this);
    this.removeLink = this.removeLink.bind(this);
    this.onModalSave = this.onModalSave.bind(this);

    this.focus = this.focus.bind(this);
    this.blur = this.blur.bind(this);
    this.handlePastedText = this.handlePastedText.bind(this);

    this.blockRenderMap = RenderMap;
    this.setDomEditorRef = (ref) => (this.domEditor = ref);

    this.state = {
      isPrompting: false,
    };

    this.decorators = new CompositeDecorator([
      LinkHelpers.buildLinkDecorator(),
    ]);

    this.isAlpha = FeatureToggle.isAlpha();
  }

  /**
   * componentWillMount
   */
  componentWillMount() {
    window.addEventListener("click", this._handleTripleClick);
  }

  /**
   * component Did Mount lifecycle hook
   */
  componentDidMount() {
    const contentState = HTMLHelpers.convertFromHTML(
      this.props.items,
      this.props.configuration.data
    );
    const state = EditorState.createWithContent(contentState, this.decorators);

    this.props.setEditorState(state);
  }

  /**
   * componentWillUnmount lifecycle hook
   */
  componentWillUnmount() {
    window.removeEventListener("click", this._handleTripleClick);
  }

  /**
   * setModalSubscriptions
   */
  onModalSave(modal) {
    this.renderBlock(modal);
  }

  /**
   * handleEditorChange
   * @param   {Immutable.Record} newState
   */
  handleEditorChange(newState) {
    this.props.setEditorState(
      this._forceSelectionState(newState, newState.getSelection()),
      this._contentHasChanged(newState)
    );

    this._shouldPastePlainText(newState);
  }

  /**
   * focus
   */
  focus() {
    this.isFocussed = true;
    this.domEditor && this.domEditor.focus();
  }

  /**
   * blur
   */
  blur() {
    this.isFocussed = false;
    this.domEditor && this.domEditor.blur();
  }

  /**
   * select entire block on click event, preventing next block being selected
   * @param  {MouseEvent} event
   */
  _handleTripleClick(event) {
    const { editorState } = this.props.editor;

    if (this._isTripleClick(event)) {
      this.handleEditorChange(
        DraftHelpers.selectEntireBlock(
          editorState,
          editorState.getSelection().getAnchorKey()
        )
      );
    }
  }

  /**
   * check for triple click in a browser MouseEvent
   * @param  {MouseEvent}  event
   * @return {Boolean}
   */
  _isTripleClick(event) {
    return event.detail === 3 || event.detail === 4;
  }

  /**
   * _pastePlainText
   * @param  {Immutable.Record} state - <EditorState>
   * @return {Boolean}
   */
  _shouldPastePlainText(newState) {
    this.setState({
      shouldPastePlainText:
        DraftHelpers.hasDisabled(newState) || this.state.restrictedClipboard,
    });
  }

  /**
   * _forceSelectionState
   * @param  {Immutable.Record} state - <EditorState>
   * @return {Immutable.Record} - newState
   */
  _forceSelectionState(state, selection) {
    if (!selection.getHasFocus()) {
      selection.set("hasFocus", true);
    }

    return EditorState.set(state, {
      selection,
      forceSelection: true,
      nativelyRenderedContent: null,
    });
  }

  /**
   * keyBindingFn
   * @param   {Event} event
   * @returns {String}
   */
  keyBindingFn(event) {
    if (
      this.isKeyCode(KEYCODES.LETTER_S, event) &&
      KeyBindingUtil.hasCommandModifier(event)
    ) {
      return "editor-save";
    }

    if (
      this.isKeyCode(KEYCODES.LETTER_A, event) &&
      KeyBindingUtil.hasCommandModifier(event)
    ) {
      this.handleEditorChange(
        DraftHelpers.selectEntireBlock(this.props.editor.editorState)
      );

      return "handled";
    }

    if (
      this.isKeyCode(KEYCODES.LETTER_C, event) &&
      KeyBindingUtil.hasCommandModifier(event)
    ) {
      this.setState({
        restrictedClipboard: DraftHelpers.hasDisabled(
          this.props.editor.editorState
        )
          ? DraftHelpers.getCurrentBlocks(this.props.editor.editorState)
          : null,
      });
    }

    if (
      this.isKeyCode(KEYCODES.DELETE, event) &&
      !DraftHelpers.isSelection(this.props.editor.editorState) &&
      DraftHelpers.isSelectionAtEnd(this.props.editor.editorState)
    ) {
      return "handled";
    }

    if (
      this.isKeyCode(KEYCODES.DELETE, event) &&
      ((DraftHelpers.isUnremovableBlock(this.props.editor.editorState) &&
        !DraftHelpers.getCurrentBlock(this.props.editor.editorState).getText()
          .length) ||
        DraftHelpers.isTogglingPlaceholderOn(this.props.editor.editorState) ||
        DraftHelpers.isMediaBlock(this.props.editor.editorState))
    ) {
      return this._handleBackspace();
    }

    return getDefaultKeyBinding(event);
  }

  /**
   * isKeyCode
   * @param   {Number} keyCode
   * @param   {Event} event
   * @returns {Boolean}
   */
  isKeyCode(keyCode, event) {
    return keyCode === event.keyCode;
  }

  /**
   * blockRendererFn
   * @param   {Immutable.Record} contentBlock
   * @returns {Immutable.Map|Object}
   */
  blockRendererFn(contentBlock) {
    const blockRendererConfig = {
      renderMedia: this.renderMediaBlock,
      replaceMedia: this.replaceMediaBlock,
      onModalSave: this.onModalSave,
    };

    return DraftBlockRenderer(contentBlock, blockRendererConfig);
  }

  /**
   * handleBeforeInput
   * @todo  missing a check to decide
   * @param   {String} text
   * @param   {Immutable.Record} editorState
   * @returns {String} - Whether the input was handled or not
   */
  handleBeforeInput(text, editorState) {
    if (DraftHelpers.isPlaceholder(editorState)) {
      return this._textPlaceholderOff(text, editorState);
    }
    if (
      text &&
      !DraftHelpers.isPlaceholder(this.props.editor.editorState) &&
      DraftHelpers.isLastOfType(this.props.editor.editorState) &&
      DraftHelpers.isSelection(this.props.editor.editorState) &&
      DraftHelpers.isMultipleBlocks(this.props.editor.editorState)
    ) {
      return this._textPlaceholderOn(editorState, text);
    }
    if (DraftHelpers.isMediaBlock(editorState)) {
      return this._splitBlock(editorState, text);
    }

    return "not-handled";
  }

  /**
   * handleKeyCommand
   * @param   {String} command
   * @returns {String}
   */
  handleKeyCommand(command) {
    const newState = RichUtils.handleKeyCommand(
      this.props.editor.editorState,
      command
    );

    if (command === "editor-save") {
      this.props.save();

      return "handled";
    }

    if (
      newState &&
      !DraftHelpers.isBackspace(command) &&
      DraftHelpers.isPlaceholder(newState)
    ) {
      this.handleEditorChange(newState);

      return "handled";
    }

    if (newState && this._isStyling(command)) {
      this.handleEditorChange(newState);

      return "handled";
    }

    if (DraftHelpers.isBackspace(command)) {
      return this._handleBackspace();
    }

    return "not-handled";
  }

  /**
   * _handleBackspace
   * @returns {Function|String} - constrained to handled/not-handled
   */
  _handleBackspace() {
    const state = this.props.editor.editorState;

    if (DraftHelpers.isTogglingPlaceholderOn(state)) {
      return this._textPlaceholderOn(state);
    }

    if (MediaHelpers.isRemovingMedia(state)) {
      this.handleEditorChange(
        MediaHelpers.handleMediaDelete(state, DraftHelpers.isMediaBlock(state))
      );

      return "handled";
    }

    if (DraftHelpers.isUnremovableBlock(state)) {
      return "handled";
    }

    return "not-handled";
  }

  /**
   * handle key bindings for styling
   * @param   {String} command
   * @returns {Boolean}
   */
  _isStyling(command) {
    return (
      command === "bold" || command === "italic" || command === "underline"
    );
  }

  /**
   * handlePastedText
   * @returns {Boolean}
   */
  handlePastedText(pastedText, pastedHTML, editorState) {
    if (DraftHelpers.isPlaceholder(editorState)) {
      this._textPlaceholderOff(pastedText, editorState, pastedText);

      return true;
    }
    if (DraftHelpers.isMediaBlock(editorState)) {
      this._splitBlock(editorState, pastedText);

      return true;
    }
    if (this.state.shouldPastePlainText) {
      this._pastePlainText(editorState, pastedText);

      return true;
    }

    return false;
  }

  /**
   * contentHasChanged
   * @param   {Object} newState
   * @returns {Boolean}
   */
  _contentHasChanged(newState) {
    return (
      newState.getCurrentContent() !==
      this.props.editor.editorState.getCurrentContent()
    );
  }

  /**
   * _textPlaceholderOn
   * @param {Immutable.Record}
   * @returns {String}
   */
  _textPlaceholderOn(editorState, text) {
    return TextPlaceholders.toggleOn(
      editorState,
      this.handleEditorChange,
      text
    );
  }

  /**
   * _textplaceholderOff
   * @param   {String} text
   * @param   {Immutable.Record} editorState
   * @returns {String}
   */
  _textPlaceholderOff(text, editorState) {
    return TextPlaceholders.toggleOff(
      text,
      editorState,
      this.handleEditorChange
    );
  }

  /**
   * _pastePlainText
   * @param  {Immutable.Record} state
   * @param  {String} text
   * @return {Function} handleEditorState callback
   */
  _pastePlainText(state, text) {
    return PasteHelpers.pastePlainText(state, text, this.handleEditorChange);
  }

  /**
   * handleReturn
   * @param   {Object} event
   * @returns {String} handled/not-handled
   */
  handleReturn(event, editorState) {
    if (event.shiftKey) {
      if (!DraftHelpers.isMediaBlock(editorState)) {
        this.handleEditorChange(
          RichUtils.insertSoftNewline(this.props.editor.editorState)
        );
      }

      return "handled";
    }

    if (
      DraftHelpers.isPlaceholder(editorState) ||
      DraftHelpers.isRestricted(editorState)
    ) {
      return "handled";
    }

    return this._splitBlock(editorState);
  }

  /**
   * _unstyleBlock
   * @returns {String}
   */
  _splitBlock(editorState, text) {
    return splitBlock(
      editorState,
      this.handleEditorChange,
      this.props.configuration.data,
      text
    );
  }

  /**
   * blockStyling
   * @todo  add proper styling to placeholder
   * @param   {Object} block
   * @returns {String}
   */
  blockStyling(contentBlock) {
    const type = contentBlock.getType();
    if (type === "unstyled" || type === "placeholder") {
      return "paragraph";
    }

    return type;
  }

  /**
   * promptForLink
   */
  promptForLink() {
    this.setState({ isPrompting: !this.state.isPrompting });
  }

  /**
   * removeLink
   */
  removeLink() {
    this.handleEditorChange(
      LinkHelpers.removeLinkEntity(this.props.editor.editorState)
    );
  }

  /**
   * removeMediaBlock
   */
  replaceMediaBlock(selection, currentBlock, media, type) {
    const newState = MediaHelpers.replaceMediaBlock(
      this.props.editor.editorState,
      currentBlock,
      selection,
      media,
      type
    );

    this.handleEditorChange(newState);
  }

  /**
   * renderMediaBlock
   * @param  {Object} data
   * @param  {String} type
   */
  renderMediaBlock(
    type,
    media = {},
    selection = this.props.editor.editorState.getSelection()
  ) {
    const mediaBlockState = MediaHelpers.createMediaBlock(
      this.props.editor.editorState,
      type,
      this.props.configuration.data,
      media,
      selection
    );

    this.handleEditorChange(mediaBlockState);
  }

  /**
   * renderBlock
   * @param   {Object} modal
   * @returns {*}
   */
  renderBlock(modal) {
    if (modal.location) {
      const selection = DraftHelpers.createBlockSelection(
        modal.location.getKey(),
        0,
        modal.location.getLength()
      );

      return this.replaceMediaBlock(
        selection,
        modal.location,
        modal.selectedItem[0],
        "video"
      );
    }

    return this.renderMediaBlock("video", modal.selectedItem[0]);
  }

  /**
   * render
   * @returns {JSX|Null}
   */
  render() {
    if (this.props.editor.editorState) {
      return (
        <div className={styles["text-editor"]}>
          <div className={styles["text-editor__container"]}>
            {this.domEditor && (
              <LinkTooltip
                parentElement={this.domEditor.editor}
                handleEditorChange={this.handleEditorChange}
                isPrompting={this.state.isPrompting}
              />
            )}
            <EditorControls
              handleChange={this.handleEditorChange}
              editorFocus={this.focus}
              isFixed={this.props.controlsFixed}
              renderMediaBlock={this.renderMediaBlock}
              promptForLink={this.promptForLink}
              removeLink={this.removeLink}
              onModalSave={this.onModalSave}
            />
            <div className={styles["text-editor__content"]}>
              <Editor
                customStyleMap={InlineStyleMap}
                editorState={this.props.editor.editorState}
                onChange={this.handleEditorChange}
                blockRendererFn={this.blockRendererFn}
                blockRenderMap={this.blockRenderMap}
                blockStyleFn={this.blockStyling}
                handleKeyCommand={this.handleKeyCommand}
                handleReturn={this.handleReturn}
                handleBeforeInput={this.handleBeforeInput}
                handlePastedText={this.handlePastedText}
                keyBindingFn={this.keyBindingFn}
                ref={this.setDomEditorRef}
                onFocus={this.focus}
                onBlur={this.blur}
              />
            </div>
          </div>
        </div>
      );
    }

    return null;
  }
}
