import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./bookmark.html";

class BookmarkController {
  /**
   * Creates an instance of BookmarkController.
   * @param {Object} BookmarkService
   * @param {Object} NotificationService
   * @memberof BookmarkController
   */
  constructor(
    BookmarkService,
    NotificationService,
    ModalTriggerService,
    MessageService
  ) {
    this.BookmarkService = BookmarkService;
    this.NotificationService = NotificationService;
    this.ModalTriggerService = ModalTriggerService;
    this.MessageService = MessageService;

    this.isSaving = false;
  }

  /**
   *  lifecycle hook
   * @memberof BookmarkController
   */
  $onInit() {
    this.isBookmarked = !!this.BookmarkService.findItem(this.detailUrl);
    this._setSubscriptions();
    this._buildNotificationOptions();
  }

  /**
   * setup MessageService subscriptions
   * @memberof BookmarkController
   */
  _setSubscriptions() {
    this.MessageService.subscribe("Bookmark.Remove", (channel, data) => {
      this.isBookmarked = !!data.find(
        (item) => item.content_url.self === this.detailUrl
      );
    });
  }

  /**
   * callback from ng-click
   * @memberof BookmarkController
   */
  toggle() {
    if (!this.BookmarkService.isEnabled() && !this.isBookmarked) {
      return this.ModalTriggerService.triggerNotification(
        this.notificationOptions
      );
    }

    if (!this.isSaving) {
      this.isBookmarked = !this.isBookmarked;

      this._save()
        .then(() => (this.isSaving = false))
        .catch(() => this._throwError());
    }
  }

  /**
   * make API call to save current state
   * @returns {Promise}
   * @memberof BookmarkController
   */
  _save() {
    this.isSaving = true;

    return this.isBookmarked
      ? this.BookmarkService.addItem(this.detailUrl)
      : this.BookmarkService.removeItem(this.detailUrl);
  }

  /**
   * throw error and restore bookmark state
   * @memberof BookmarkController
   */
  _throwError() {
    const action = this.isBookmarked ? "add" : "remove";

    this.isBookmarked = !this.isBookmarked;
    this.isSaving = false;
    this.NotificationService.notifyError(
      NotificationMessage.bookmarkError(action)
    );
  }

  /**
   * _buildNotificationOptions
   * @returns {void}
   */
  _buildNotificationOptions() {
    this.notificationOptions = {
      notificationType: "bookmarkLimit",
      channels: {
        confirm: "bookmark.Confirm",
      },
    };
  }
}

const BookmarkComponent = {
  bindings: {
    detailUrl: "<",
  },
  template,
  controller: BookmarkController,
  controllerAs: "component",
};

export default BookmarkComponent;
