/**
 * reverse an array
 * @param _
 * @return {Array}
 */
function reverse(_) {
  return (array) => {
    if (!_.isArray(array)) {
      return array;
    }

    return array.reverse();
  };
}

export default reverse;
