import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import withStoreProvider from "hocs/with-store-provider";
import { react2angular } from "react2angular";
import { fetchArticleStatuses } from "store/article-status/article-status.actions";
import { updateEntity } from "store/entity/entity.action";
import ArticleStatus from "./article-status";

const connectToStore = (component) => connect(mapProps, mapDispatch)(component);

const mapProps = (state) => ({
  entity: state.entity,
  articleStatus: state.articleStatus,
});

const mapDispatch = (dispatch) =>
  bindActionCreators(
    {
      fetchArticleStatuses,
      updateEntity,
    },
    dispatch
  );

const reactArticleStatus = angular
  .module("Skylark.Components.React.ArticleStatus", [])
  .component(
    "reactArticleStatus",
    react2angular(withStoreProvider(connectToStore(ArticleStatus)))
  );

export { reactArticleStatus };

export default connectToStore(ArticleStatus);
