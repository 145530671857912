import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SkylarkLogoIconBlue = createIcon({
  displayName: "SkylarkLogoIconBlue",
  viewBox: "0 0 28 24",
  path: (
    <g fill="none">
      <rect fill="#0E1825" height="8" width="7.92857" x="11.8928" y="8" />
      <path
        d="M3.96436 8C3.96436 5.87827 4.79969 3.84344 6.28658 2.34315C7.77348 0.842855 9.79014 0 11.8929 0H27.7501C27.7501 2.12173 26.9147 4.15656 25.4278 5.65685C23.941 7.15715 21.9243 8 19.8215 8H11.8929C13.9957 8 16.0124 8.84286 17.4993 10.3431C18.9862 11.8434 19.8215 13.8783 19.8215 16H11.8929C9.79014 16 7.77348 15.1571 6.28658 13.6569C4.79969 12.1566 3.96436 10.1217 3.96436 8Z"
        fill="#0E1825"
      />
      <path
        d="M19.8214 24H0C0 21.8783 0.835329 19.8434 2.32222 18.3431C3.80912 16.8429 5.82578 16 7.92857 16H23.125L19.8214 24Z"
        fill="#0D5AF1"
      />
      <path
        d="M19.8214 24C21.9242 24 23.9408 23.1571 25.4277 21.6569C26.9146 20.1566 27.75 18.1217 27.75 16C27.75 13.8783 26.9146 11.8434 25.4277 10.3431C23.9408 8.84285 21.9242 8 19.8214 8H11.8928C13.9956 8 16.0123 8.84285 17.4992 10.3431C18.9861 11.8434 19.8214 13.8783 19.8214 16V24Z"
        fill="url(#paint0_linear)"
      />
      <path d="M19.8214 16H11.8928L19.8214 24V16Z" fill="url(#paint1_linear)" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear"
          x1="19.8214"
          x2="19.8214"
          y1="8"
          y2="19.5"
        >
          <stop offset="0.347826" stopColor="#226DFF" />
          <stop offset="1" stopColor="#0D5AF1" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear"
          x1="19.8214"
          x2="15.8216"
          y1="16"
          y2="19.9641"
        >
          <stop stopOpacity="0.35" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </g>
  ),
});

export const SkylarkLogoIconWhite = createIcon({
  displayName: "SkylarkLogoIconWhite",
  viewBox: "0 0 28 24",
  path: (
    <g fill="none">
      <rect fill="#0E1825" height="8" width="7.92857" x="11.8928" y="8" />
      <path
        d="M3.96436 8C3.96436 5.87827 4.79969 3.84344 6.28658 2.34315C7.77348 0.842855 9.79014 0 11.8929 0H27.7501C27.7501 2.12173 26.9147 4.15656 25.4278 5.65685C23.941 7.15715 21.9243 8 19.8215 8H11.8929C13.9957 8 16.0124 8.84286 17.4993 10.3431C18.9862 11.8434 19.8215 13.8783 19.8215 16H11.8929C9.79014 16 7.77348 15.1571 6.28658 13.6569C4.79969 12.1566 3.96436 10.1217 3.96436 8Z"
        fill="#0E1825"
      />
      <path
        d="M19.8214 24H0C0 21.8783 0.835329 19.8434 2.32222 18.3431C3.80912 16.8429 5.82578 16 7.92857 16H23.125L19.8214 24Z"
        fill="#FFF"
      />
      <path
        d="M19.8214 24C21.9242 24 23.9408 23.1571 25.4277 21.6569C26.9146 20.1566 27.75 18.1217 27.75 16C27.75 13.8783 26.9146 11.8434 25.4277 10.3431C23.9408 8.84285 21.9242 8 19.8214 8H11.8928C13.9956 8 16.0123 8.84285 17.4992 10.3431C18.9861 11.8434 19.8214 13.8783 19.8214 16V24Z"
        fill="#FFF"
      />
      <path d="M19.8214 16H11.8928L19.8214 24V16Z" fill="url(#paint2_linear)" />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint2_linear"
          x1="19.8214"
          x2="15.8216"
          y1="16"
          y2="19.9641"
        >
          <stop stopOpacity="0.35" />
          <stop offset="1" stopOpacity="0" />
        </linearGradient>
      </defs>
    </g>
  ),
});
