/**
 * @fileOverview Helpers to deal with query strings from URLs.
 */

class QueryStringHelpersService {
  /**
   * @constructor
   * @param   {Object} $q
   * @param   {Object} $httpParamSerializer
   * @param   {Object} BatchRequestFactory
   * @param   {Object} EntityTypeHelpersService
   * @param   {Object} _
   */
  constructor(
    $q,
    $httpParamSerializer,
    BatchRequestFactory,
    EntityTypeHelpersService,
    _
  ) {
    this.$q = $q;
    this.$httpParamSerializer = $httpParamSerializer;
    this.BatchRequestFactory = BatchRequestFactory;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
    this._ = _;
  }

  /**
   * transform a url with a query string into an object
   * angular does not seem to have a string-to-object method
   * bundled up with httpParamSerializer,
   * so we need to do this manually
   * @param   {string} url
   * @returns {object}
   */
  queryStringToObject(url) {
    return url
      .slice(url.indexOf("?") + 1)
      .split("&")
      .map((param) => param.split("="))
      .reduce((prev, curr) => {
        prev[curr[0]] = decodeURIComponent(curr[1]);

        return prev;
      }, {});
  }

  /**
   * buildUrl - generate a url param with filters as a query string
   * @access private
   * @param   {string} objectType - entity name for endpoint
   * @param   {object} params - filter params to be stringified
   * @returns {string} encoded url string
   */
  buildUrl(objectType, params) {
    const validParams = { ...params };
    validParams.entity = null;
    if (validParams.q) {
      validParams.q = this._enforceExclusionOperator(validParams.q);
    }

    const serialisedParams = this.$httpParamSerializer(validParams).replace(
      /[+]+/g,
      " "
    );

    return `/api/${objectType}/?${serialisedParams}`;
  }

  /**
   * _enforceExclusionOperator
   * @todo  this logic should eventually be moved into the search factory
   * @param   {string} queryString - query string
   * @returns {string}
   */
  _enforceExclusionOperator(queryString) {
    if (this._hasNotOperator(queryString)) {
      return this._replaceExclusionOperator(queryString);
    }

    return queryString;
  }

  /**
   * _hasNotOperator
   * @param   {string} str - a lucene query string
   * @returns {Boolean} does this string contain a NOT operator?
   */
  _hasNotOperator(str) {
    return str.match(new RegExp("NOT"));
  }

  /**
   * _replaceExclusionOperator
   * @todo  this is a temporary fix to avoid disturbing existing code -
   * this logic should eventually be moved into the search factory
   * @param   {string} queryString
   * @returns {string} sanitized string
   */
  _replaceExclusionOperator(queryString) {
    return queryString
      .split(" AND ")
      .map((curr) => curr.replace(/[()]+/g, "").split(" OR "))
      .map((group) => this._buildExclusionString(group))
      .map((queryStr) => `(${queryStr.trim()})`)
      .join(" AND ");
  }

  /**
   * _buildExclusionString - build the string with exclusion
   * @param   {array} group of terms
   * @returns {string} reduced group to a string
   */
  _buildExclusionString(group) {
    return group.reduce((finalString, queryPair, currentIndex) => {
      if (this._hasNotOperator(queryPair)) {
        const newQueryPair = queryPair.replace(/NOT /g, "");

        return (finalString += ` NOT ${newQueryPair}`);
      }
      const operator = currentIndex > 0 ? " OR " : "";

      return (finalString += `${operator}${queryPair}`);
    }, "");
  }

  /**
   * luceneStringToArray
   * @param {string} queryString
   * @access public
   * @returns {array}
   */
  luceneStringToArray(queryString) {
    return this._spliceInvalidChars(queryString)
      .split(" AND ")
      .map((curr) => curr.split(" OR ").map(this._generateQueryObject))
      .reduce(this._mergeArrays, []);
  }

  /**
   * luceneParamsByType - convert string with a q param of type
   * into an array of objects
   * for multisearch
   * @param   {string} queryString
   * @returns {array} array for search
   */
  luceneParamsByType(queryString) {
    return this._spliceInvalidChars(queryString)
      .split(" OR ")
      .map(this._generateQueryObject);
  }

  /**
   * sanitizeQueryString
   * @param   {string} queryString
   * @returns {string}
   */
  sanitiseQueryString(queryString) {
    if (queryString.includes("NOT")) {
      return queryString
        .split(" AND ")
        .map((cur) => this._convertNotOperators(cur))
        .join(" AND ");
    }

    return queryString;
  }

  /**
   * _moveNotOperator - moves not operator from the field part
   * to the term part of the string
   * @todo  this is a temporary fix to avoid disturbing existing code -
   * @param   {string} str
   * @returns {string} multisearch ready string
   */
  _moveNotOperator(str) {
    const strPair = str.split(":");
    strPair[1] = `NOT ${strPair[1]}`;

    return strPair.join(":");
  }

  /**
   * _isFirstParamNot
   * @todo  this is a temporary fix to avoid disturbing existing code -
   * @param   {string} queryString
   * @returns {Boolean} whether the first term is an exclusion term
   */
  _isFirstParamNot(queryString) {
    const testStrings = queryString.split("OR");
    const hasMultipleParams = testStrings.length > 1;
    if (hasMultipleParams) {
      return testStrings[0].includes("NOT");
    }
    return testStrings[0].indexOf("NOT") === 1;
  }

  /**
   * _convertNotOperators
   * @todo  this is a hack - this should be superceded by a UI based solution
   * @param   {string} queryString
   * @returns {string} original query string or modified query string for DOM
   */
  _convertNotOperators(queryString) {
    if (queryString.includes("NOT")) {
      const notIndexes = this._findOccurrences(
        queryString,
        new RegExp("NOT", "g")
      );
      const colonIndexes = this._findOccurrences(
        queryString,
        new RegExp(":", "g")
      );

      const assignColonIndex = (op) => {
        const colonIndex = colonIndexes.find((ind) => ind > op);
        colonIndexes.splice(colonIndexes.indexOf(colonIndex), 1);

        return colonIndex;
      };

      queryString = queryString.replace(new RegExp("NOT", "g"), "OR");

      const list = notIndexes.map((op) => ({
        notIndex: op,
        colonIndex: assignColonIndex(op),
      }));

      let strCopy = "";

      list.forEach((pair, index) => {
        const sum = 2 * index;
        strCopy = `${queryString.substring(
          0,
          pair.colonIndex + sum + index
        )}NOT ${queryString.substring(pair.colonIndex + sum + index)}`;
        queryString = strCopy;
      });

      if (strCopy.indexOf("OR") === 1) {
        return strCopy.replace(new RegExp("OR "), "");
      }

      return strCopy;
    }

    return queryString;
  }

  /**
   * _findOccurrences
   * @param   {string} queryString
   * @param   {regex} regex
   * @returns {array} list of indexes of regex occurrences in string
   */
  _findOccurrences(queryString, regex) {
    const results = [];

    queryString.replace(regex, (a, index) => {
      results.push(index);
    });

    return results;
  }

  /**
   * ancestorsByName
   * @access public
   * @param   {object} filters with ancestors param
   * @returns {promise} promise
   */
  ancestorsByName(filters) {
    return this._extractAncestorParams(filters);
  }

  /**
   * buildViewString
   * @param   {string} url
   * @param   {object} config
   * @returns {Promise}
   */
  buildViewString(url, config) {
    const deferred = this.$q.defer();
    const filters = this.queryStringToObject(url);
    const filtersObject = this._buildViewObject(filters, url, config);
    this._extractAncestorParams(filters).then((ancestors) => {
      filtersObject.ancestors = this._buildAncestorsString(ancestors);
      deferred.resolve(filtersObject);
    });

    return deferred.promise;
  }

  /**
   * build an object for view (i.e. dynamic object tooltips)
   * @param   {object} filters
   * @param   {string} url
   * @param   {object} config
   * @returns {object} view object data
   */
  _buildViewObject(filters, url, config) {
    return {
      objectType: this.EntityTypeHelpersService.getRawType(url) || "",
      limit: filters.limit,
      objectRanking: this._extractRanking(filters.order, config),
      relationships: this._extractLuceneQueryParams(filters) || "",
    };
  }

  /**
   * extractLuceneQueryParams
   * @param   {string} queryString
   * @returns {string} string
   */
  _extractLuceneQueryParams(queryString) {
    if (queryString.q) {
      return this.sanitiseQueryString(queryString.q)
        .split(" AND ")
        .map((type) =>
          type.replace(/[()]+/g, "").replace(/[+]+/g, " ").replace(/%22+/g, '"')
        )
        .map((param) =>
          param
            .split(new RegExp(" OR ", "g"))
            .map((cur) => cur.substring(cur.indexOf(":") + 1))
            .join(" OR ")
        )
        .join(" AND ");
    }
  }

  /**
   * extractAncestorParams description
   * @param   {Object} filters - filters object
   * @returns {Promise}
   */
  _extractAncestorParams(filters) {
    const deferred = this.$q.defer();
    if (filters.ancestors) {
      const urls = decodeURIComponent(filters.ancestors).split(",");
      this.BatchRequestFactory.createGetRequests(
        "ancestors-titles",
        urls,
        "?fields=title,self"
      );
      this.BatchRequestFactory.process().then((data) => {
        deferred.resolve(this._buildAncestorsList(data));
      });
    } else {
      deferred.resolve([]);
    }

    return deferred.promise;
  }

  /**
   * extractRanking
   * @param   {string} objectRanking - the object ranking from string
   * @param   {object} config
   * @returns {string} the copy for the selected ranking
   */
  _extractRanking(objectRanking, config) {
    const rankingConfig = config.object_ranking;
    const option = rankingConfig.options.find(
      (cur) => cur.self === objectRanking
    ) || {
      name: "",
    };

    return option.name;
  }

  /**
   * build an ancestors string
   * @param   {Array} ancestorsList - list of ancestors with title and type
   * @returns {String}
   */
  _buildAncestorsString(ancestorsList) {
    return ancestorsList
      .map((item) => `${item.type}: ${item.title}`)
      .join(", ");
  }

  /**
   * build a list of ancestors
   * @param   {Array} items ancestor items from API calls
   * @returns {Array} items ancestors objects to help generate copy
   */
  _buildAncestorsList(items) {
    return items.map((item) => {
      const itemData = JSON.parse(item.body);
      const itemType = this.EntityTypeHelpersService.getRawType(itemData.self);

      return {
        type: this._.capitalize(itemType),
        title: itemData.title,
        ancestors: itemData.self,
      };
    });
  }

  /**
   * spliceInvalidChars
   * @param   {string} str - param
   * @returns {string} param after going through the regex
   */
  _spliceInvalidChars(str) {
    return str
      .replace(/[+]+/g, " ")
      .replace(/%2B+/g, " ")
      .replace(/[()]+/g, "")
      .replace(/%22+/g, '"');
  }

  /**
   * _generateQueryObject
   * @param   {string} param
   * @returns {object}
   * @callback luceneParams#map
   */
  _generateQueryObject(param) {
    const newParam = param.split(":");

    return {
      entity: newParam[0],
      q: newParam[1],
    };
  }

  /**
   * concat arrays
   * @callback reduce
   * @param   {array} finalArr
   * @param   {object} curr current item being iterated
   * @returns {void}
   */
  _mergeArrays(finalArr, curr) {
    return finalArr.concat(curr);
  }
}

export default QueryStringHelpersService;
