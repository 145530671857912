import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import DropdownList from "../dropdown-list/dropdown-list";

import styles from "./dropdown-editor-controls.scss";

/**
 * DropdownEditorControls
 * @param {Object} props
 */
export default function DropdownEditorControls(props) {
  return (
    <div
      disabled={props.isDisabled}
      className={classnames(styles["dropdown-editor-controls"], {
        [styles["dropdown-editor-controls--disabled"]]: props.isDisabled,
      })}
    >
      <div className={styles["dropdown-editor-controls__dropdown"]}>
        <div>
          <span className={styles["dropdown-editor-controls__text"]}>
            {props.optionSelected || props.placeholder}
          </span>
          <SkylarkIcon
            iconName="down"
            extraClassName={styles["dropdown-editor-controls__icon"]}
          />
          {!props.isDisabled && (
            <DropdownList
              extraClassName={styles["dropdown-editor-controls__options"]}
              options={props.options}
              current={props.optionSelected || null}
              onSelect={props.onSelect}
              isCollapsed={props.isOpen}
              type="editor-controls"
              displayField="display_name"
            />
          )}
        </div>
      </div>
    </div>
  );
}

DropdownEditorControls.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  optionSelected: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
};

DropdownEditorControls.defaultProps = {
  optionSelected: "",
  isDisabled: false,
};
