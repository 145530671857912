import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./talent-module.html";

class TalentDirectiveCtrl {
  /**
   * @constructor
   * @param   {Object} _
   * @param   {Object} $state
   * @param   {Object} MessageService
   * @param   {Object} ModulePagingService
   * @param   {Object} NotificationService
   * @param   {Object} SchedulesFactory
   * @param   {Object} TalentFactory
   * @param   {Object} EntityFactory
   * @param   {Object} $stateParams
   * @param   {Object} ModalTriggerService
   * @param   {Object} ApiFilterFactory
   */
  constructor(
    _,
    $state,
    MessageService,
    ModulePagingService,
    NotificationService,
    SchedulesFactory,
    TalentFactory,
    EntityFactory,
    $stateParams,
    ModalTriggerService,
    ApiFilterFactory
  ) {
    this._ = _;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.MessageService = MessageService;
    this.ModulePagingService = ModulePagingService;
    this.NotificationService = NotificationService;
    this.SchedulesFactory = SchedulesFactory;
    this.EntityFactory = EntityFactory;
    this.TalentFactory = TalentFactory;
    this.ModalTriggerService = ModalTriggerService;
    this.ApiFilterFactory = ApiFilterFactory;

    this.type = "talent";
    this.types = undefined;

    this.availableChannelNames = {
      add: "Modal.Save.talent.base",
    };

    this.paginationSettings = {
      start: 0,
      limit: this.config.limit || 10,
    };

    this.setSubscriptions();
  }

  /**
   * @param {Object} config
   * @param {Object} data
   * @returns {void}
   */
  init() {
    this.talent = [].concat(this.data.talent);
    this.existing = this._.map(this.data.talent, "talent_url");
    this.parentType = this.$stateParams.entity
      ? this.$stateParams.entity
      : this.$state.current.title;

    this.self = this.data.self;
    this.entity = {
      parentId: this.data.uid,
      parentType: this.parentType,
      parentTitle: this.data.title,
    };

    this.TalentFactory.getRoles().then((data) => {
      this.types = data.objects;
    });

    this.talentToDisplay = [];

    this.changePage();
  }

  /**
   * listen for events from other directives and controllers
   * @returns {void}
   */
  setSubscriptions() {
    this.MessageService.subscribe(
      this.availableChannelNames.add,
      (channel, data) => this.addTalent(data)
    );
  }

  /**
   * removing subscriptions
   * @returns {void}
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.availableChannelNames.add);
  }

  /**
   * add talent to dom
   * @param {object} data
   * @returns {void}
   */
  addTalent(data) {
    data.forEach((obj) => {
      const talent = {
        talent_url: obj.self,
        roles: [],
        character: "",
      };
      this.data.talent.push(talent);
      this.talent.push(talent);
      this.existing.push(talent.talent_url);
    });

    let message = "You have successfully added multiple talent.";

    if (data.length === 1) {
      const name = `${data[0].first_name} ${data[0].last_name}`;
      message = `You have successfully added ${name}`;
    }

    this.saveTalent(message);

    this.MessageService.publish(`Pagination.${this.type}`, this.count);
  }

  /**
   * Update dom with changes to talent data
   * @param {object} talent
   * @param {string} name
   * @returns {void}
   */
  updateTalent(talent, name) {
    const indexOfTalent = this._.findIndex(
      this.data.talent,
      (obj) => obj.talent_url === talent.talent_url
    );

    this.talent[indexOfTalent] = talent;
    this.data.talent[indexOfTalent] = talent;

    const message = `You have successfully updated ${name}`;
    this.saveTalent(message);
  }

  /**
   * remove talent from dom and update api
   * @param {object} data
   * @returns {void}
   */
  removeTalent(data) {
    const name = `${data.first_name} ${data.last_name}`;

    this._.remove(
      this.data.talent,
      (talent) => talent.talent_url === data.self
    );

    this.EntityFactory.updateByUrl(this.data.self, this.data)
      .then((response) => {
        this.data = angular.extend({}, response);
        this._.remove(this.talent, (obj) => obj.talent_url === data.self);
        this._.remove(this.existing, (url) => url === data.self);
        this.changePage();

        this.NotificationService.notifyInfo(
          NotificationMessage.removeSuccess(name)
        );
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.removeError(name)
        )
      );
  }

  /**
   * send message to update roles in child isolated scopes
   * @returns {void}
   */
  updateTalentTypes() {
    this.MessageService.publish("updateRoles", {});
  }

  /**
   * push talent data to api
   * @param {sting} message
   * @returns {void}
   */
  saveTalent(message) {
    this.EntityFactory.updateByUrl(this.data.self, this.data)
      .then((response) => {
        this.data = angular.extend({}, response);
        this.updateTalentTypes();
        this.NotificationService.notifyInfo(message);
        this.changePage();
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.saveTalentError
        )
      );
  }

  /**
   * pagination change
   * @param {Number} start
   * @param {Number} limit
   */
  paginationChange(start, limit) {
    this.paginationSettings.start = start;
    this.paginationSettings.limit = limit;
    this.changePage();
  }

  /**
   * Changes the page in the modal
   * @returns {void}
   */
  changePage() {
    this.count = this.talent.length;
    this.MessageService.publish(`Pagination.${this.type}`, this.count);
    this.talentToDisplay.length = 0;
    this.talentToDisplay = this.ModulePagingService.urlsToGet(
      this.talent,
      this.paginationSettings.start,
      this.paginationSettings.limit
    );
  }

  /**
   * trigger Modal for adding more items
   */
  triggerModal() {
    const options = {
      entity: this.type,
      channels: { add: "Modal.Save.talent.base" },
    };

    this.ApiFilterFactory.getExcludeItemsFilter(
      this.data.self,
      this.existing,
      this.count
    ).then((filters) => {
      options.filters = filters;

      this.ModalTriggerService.triggerList(this.type, options);
    });
  }
}

/**
 * Talent directive
 * @returns {Object} directive
 */
function talentDirective() {
  return {
    scope: {},
    bindToController: {
      data: "<",
      config: "=",
      isCreating: "=",
    },
    controller: TalentDirectiveCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default talentDirective;
