/**
 * @file FIELDS_TO_REQUEST
 * Constant for request filters
 */

const FIELDS_TO_REQUEST = {
  requiredFieldsForSet: [
    "self",
    "uid",
    "title",
    "items",
    "items__uid",
    "items__self",
    "items__expired",
    "items__future",
    "items__image_urls",
    "items__content_url",
    "items__language",
    "items__position",
    "items__all_languages",
    "items__content_url",
    "items__content_url__uid",
    "items__content_url__self",
    "items__content_url__expired",
    "items__content_url__future",
    "items__content_url__schedule_urls",
    "items__content_url__all_languages",
    "items__content_url__set_type_slug",
    "items__content_url__title",
    "items__content_url__name",
    "items__content_url__upload_image_url",
    "items__content_url__url",
    "items__image_urls__uid",
    "items__image_urls__self",
    "items__image_urls__schedule_urls",
    "items__image_urls__content_url",
    "items__image_urls__upload_image_url",
    "items__image_urls__url",
    "items__scheduling_status",
  ],
  requiredFieldsForContent: ["uid", "self", "content_url", "language"],
  requiredFieldsForPreview: [
    "content_url",
    "uid",
    "self",
    "content_url__title",
    "content_url__uid",
    "content_url__self",
    "content_url__name",
  ],
};

export default FIELDS_TO_REQUEST;
