import angular from "angular";
import genreUpdateDirective from "./genre-update.directive";
import genresDirective from "./genres-module.directive";

const Genres = angular
  .module("Skylark.Modules.Genres", [])
  .directive("genreUpdate", genreUpdateDirective)
  .directive("genres", genresDirective);

export default Genres;
