import backendServices from "skylarklib/constants/backend-services";

class ViewingService {
  /**
   * @param $q
   * @param ApiService
   * @param SkylarkApiInfoService
   * @param RavenApiService
   * @param NotificationService
   */
  constructor(
    $q,
    ApiService,
    SkylarkApiInfoService,
    RavenApiService,
    NotificationService
  ) {
    this.$q = $q;
    this.ApiService = ApiService;
    this.RavenApiService = RavenApiService;
    this.NotificationService = NotificationService;
    this.SkylarkApiInfoService = SkylarkApiInfoService;
  }

  /**
   * Gets a viewing object
   * @param {string} entityId
   * @param {string} provider
   * @returns {promise}
   */
  getViewing(entityId, provider) {
    return this.SkylarkApiInfoService.hasRaven().then((hasRaven) => {
      if (hasRaven) {
        return this.getViewingFromRaven(entityId);
      }

      return this.getViewingFromSkylark(entityId, provider);
    });
  }

  /**
   * get streaming url from Skylark
   * @param {string} entityId
   * @param {string} provider
   * @returns {promise<string>}
   */
  getViewingFromSkylark(entityId, provider) {
    return this.ApiService.post(`${backendServices.skylarkPath}/viewings/`, {
      asset_url: `${backendServices.skylarkPath}/assets/${entityId}/`,
    }).then((data) => {
      const { objects } = data;

      if (objects.length) {
        // map to get all objects that contain a valid object for the given provider, filter out ones that don't
        const [streamObject] = objects
          .map((object) => {
            let streamObject = object[provider];

            const workflowServicePrefix = "workflow-service-";
            if (Object.prototype.hasOwnProperty.call(object, "generic")) {
              streamObject = object.generic;
            } else if (
              !streamObject &&
              provider &&
              provider.startsWith(workflowServicePrefix)
            ) {
              const workflowProvider = provider.replace(
                workflowServicePrefix,
                ""
              );
              streamObject = object[workflowProvider];
            }

            return streamObject;
          })
          .filter((object) => !!object);

        if (!streamObject) {
          return "";
        }

        let viewingUrl;

        switch (provider) {
          case "mux":
          case "workflow-service-mux":
            viewingUrl = streamObject.tokenised_url;
            break;
          case "m2a":
          case "bitmovin":
          case "verizon":
          case "workflow-service-m2a":
            viewingUrl = streamObject.hls_url;
            break;
          default:
            viewingUrl = "";
        }

        return viewingUrl;
      }

      return "";
    });
  }

  /**
   * get streaming url from Raven
   * currently we can't get a stream for a specific provider in case project has multiple providers
   * @param entityId
   * @returns {promise}
   */
  getViewingFromRaven(entityId) {
    const options = {
      params: {
        manifest_type: "HLS",
      },
    };

    return this.RavenApiService.get(
      `${
        __V8_ENVIRONMENT__ ? "" : backendServices.ravenPath
      }/api/playback/${entityId}`,
      options
    ).then((data) => data.playback_url);
  }
}

export default ViewingService;
