import template from "./modal-dynamic-object.html";

/**
 * @file DynamicObjectModalCtl
 * Renders a modal view for creating and editing dynamic objects.
 */

/**
 * @memberOf  Components.Modal
 * @classdesc Renders dynamic object modal.
 */
class DynamicObjectModalCtrl {
  /**
   * @constructor
   * @param   {object} QueryStringHelpersService
   * @param   {object} EntityTypeHelpersService
   * @param   {object} MessageService
   * @param   {object} DynamicObjectFactory
   * @param   {object} DYNAMIC_OBJECT
   * @param   {object} _
   */
  constructor(
    QueryStringHelpersService,
    EntityTypeHelpersService,
    MessageService,
    DynamicObjectFactory,
    ModalMultiSourceService,
    DYNAMIC_OBJECT,
    _
  ) {
    this.QueryStringHelpersService = QueryStringHelpersService;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
    this.MessageService = MessageService;
    this.DynamicObjectFactory = DynamicObjectFactory;
    this.DYNAMIC_OBJECT = DYNAMIC_OBJECT;
    this.ModalMultiSourceService = ModalMultiSourceService;
    this._ = _;
  }

  /**
   * init
   * @access public
   * @param {object} data
   * @returns {void}
   */
  init(data) {
    this.isModal = true;
    this.data = data;
    this.initialData = angular.copy(this.data);

    this.entityList = {};

    this.isCreating = this._.isEmpty(data);

    if (!this.isCreating) {
      this.parentData = this.options.parent;
      this._setupData();
    } else {
      this.viewData = { amount: "", filters: {} };
    }

    this.entityList.operator = this.config.filters.multisearch.operator;

    this._setupSubscriptions();
  }

  /**
   * Setup the modals initial data
   * @private
   */
  _setupData() {
    if (!this.isCreating) {
      const fieldsToCopy = ["name", "url"];
      this.displayedLanguage = this.data.displayedLanguage;
      this.data = this.ModalMultiSourceService.combineData(
        this.data.scheduledItem,
        this.data.contentItem,
        fieldsToCopy
      )[this.displayedLanguage];

      this._assignViewData();
    }
  }

  /**
   * set current multisearch entity list based on selected entity - called on ng-change
   * @access public
   * @callback {ng-change}
   * @returns {void}
   */
  setMultisearchEntityList() {
    const { entity } = this.viewData;
    this.entityList.entities = this.config.filters.multisearch[entity].entities;
    this.MessageService.publish("defaultEntityChange", entity);
  }

  /**
   * suffixValue - adds 'items' to the end of the model
   * @callback {ng-blur}
   * @returns {void}
   */
  suffixValue() {
    if (this.viewData.amount) {
      const index = this.viewData.amount.indexOf(" ");

      this.viewData.amount =
        this.viewData.amount && index < 0
          ? `${this.viewData.amount} Items`
          : this.viewData.amount;
    }
  }

  /**
   * unsuffixValue - strips out 'items' off the model
   * @callback {ng-focus}
   * @returns {void}
   */
  unsuffixValue() {
    if (this.viewData.amount) {
      const index = this.viewData.amount.indexOf(" ");

      this.viewData.amount = this.viewData.amount.substring(0, index);
    }
  }

  /**
   * destroyScope
   * @returns {void}
   */
  destroyScope() {
    this.data = {};
    this.coreEntityData = null;
    this.MessageService.unregisterChannel("MultiSearch.Repeatable");
  }

  /**
   * isSaveButtonEnabled
   * @returns {void}
   */
  isSaveButtonEnabled() {
    return "order" in this.viewData.filters && this.viewData.entity;
  }

  /**
   * save
   * @returns {void}
   */
  save() {
    if (this.isCreating) {
      this._create();
    } else {
      this._update();
    }
  }

  /**
   * cancel
   * @returns {void}
   */
  cancel() {
    angular.copy(this.initialData, this.data);
    this.close();
  }

  /**
   * remove
   * @returns {void}
   */
  remove() {
    this.MessageService.publish("Modal.Edit.Delete.ScheduledItem", this.data);
    this.close();
  }

  /**
   * close modal
   * @access public
   * @returns {void}
   */
  close() {
    this.destroyModal();
    this.destroyScope();
  }

  /**
   * setupSubscriptions - MessageService listeners
   * @returns {void}
   */
  _setupSubscriptions() {
    const multiSearchChannelName = "MultiSearch.Repeatable";

    this.MessageService.registerChannel(multiSearchChannelName);
    this.MessageService.on(multiSearchChannelName, (ch, queryObj) => {
      this._multisearchParamsHandler(queryObj);
    });
  }

  /**
   * _create
   * @returns {void}
   */
  _create() {
    const dynamicObject = this._buildDynamicObject();
    dynamicObject.entityType = "dynamic-object";
    this.MessageService.publish("Modal.Create.setContent.base", dynamicObject);

    this.close();
  }

  /**
   * _update
   * @returns {void}
   */
  _update() {
    const dataToSend = { ...this.data, ...this._buildDynamicObject() };
    this.MessageService.publish(
      "Modal.Edit.Save.UniqueItem",
      this.ModalMultiSourceService.splitData({
        [`${this.displayedLanguage}`]: dataToSend,
      })
    );
    this.close();
  }

  /**
   * _assignViewData
   * @returns {void}
   */
  _assignViewData() {
    if (!this.isCreating) {
      const { url } = this.data;
      const urlFilters =
        this.QueryStringHelpersService.queryStringToObject(url);

      this.viewData = {
        name: this.data.name,
        entity: this.EntityTypeHelpersService.getRawType(url),
        filters: urlFilters,
        amount: urlFilters.limit,
      };

      this.viewData.filters.q = this.viewData.filters.q
        ? this.QueryStringHelpersService.sanitiseQueryString(
            this.viewData.filters.q
          )
        : null;

      this.schedule_urls = this.data.schedule_urls;

      this.suffixValue();
      this._assignMultisearchData();
    }
  }

  /**
   * assignMultisearchData
   * @returns {void}
   */
  _assignMultisearchData() {
    this.existingParams = {};
    this.existingParams.q = this.viewData.filters.q
      ? this.viewData.filters.q
      : null;
    this.existingParams.ancestors = this.viewData.filters.ancestors
      ? this.viewData.filters.ancestors
      : null;

    this.setMultisearchEntityList();
  }

  /**
   * multisearchParamsHandler
   * @param   {object} queryObj - multisearch query object
   * @returns {void}
   */
  _multisearchParamsHandler(queryObj) {
    this.viewData.filters = this.viewData.filters || {};
    this.viewData.filters.q = queryObj.q || null;
    this.viewData.filters.ancestors = queryObj.ancestors || null;
  }

  /**
   * buildDynamicObject
   * @access private
   * @returns {object} Api ready dynamic object
   */
  _buildDynamicObject() {
    this._generateLimitParam();

    return {
      url: this.QueryStringHelpersService.buildUrl(
        this.viewData.entity,
        this.viewData.filters
      ),
      name: this.viewData.name,
    };
  }

  /**
   * generate true limit param from amount model
   * @returns {void}
   */
  _generateLimitParam() {
    this.viewData.filters.limit = this.viewData.amount.substring(
      0,
      this.viewData.amount.indexOf(" ")
    );
    this._enforceDefaultLimit();
  }

  /**
   * enforceDefaultLimit - force a default limit if amount field is empty
   * @param   {object} filters - filters object
   * @returns {object} filters with a default limit applied
   */
  _enforceDefaultLimit() {
    this.viewData.filters.limit = this.viewData.filters.limit
      ? this.viewData.filters.limit
      : 1;
  }
}

/**
 * modalCreateDirective - creates directive instances
 * @returns {Object} Directive Definition Object
 */
function modalDynamicObjectDirective() {
  return {
    restrict: "E",
    controller: DynamicObjectModalCtrl,
    scope: {
      data: "=",
    },
    template,
    bindToController: {
      config: "=",
      options: "=",
      instanceUid: "@",
      destroyModal: "&",
    },
    controllerAs: "module",
    link: (scope) => {
      scope.module.init(scope.data);

      scope.$on("$destroy", () => {
        scope.module.destroyScope();
      });
    },
  };
}

export default modalDynamicObjectDirective;
