class OvpAssetDownloadService {
  /**
   *
   * @param $q
   * @param ApiService
   */
  constructor($q, ApiService) {
    this.$q = $q;
    this.ApiService = ApiService;
  }

  /**
   * API request to the download endpoint
   *
   * @return {promise}
   */
  downloadAssetFromStorage(entityId) {
    if (!entityId) {
      return this.$q.reject("no entityId provided");
    }

    return this.ApiService.get(
      `/api/assets/${entityId}/download/s3_backup_path/`,
      {}
    );
  }
}

export default OvpAssetDownloadService;
