import template from "./collapsible-panel.html";

/**
 * @file panels with collapsible content area, states can be locked, closed, open
 */
class CollapsiblePanelController {
  /**
   * @constructor
   * @param {object} $element
   * @param {object} $timeout
   * @param {object} MessageService
   */
  constructor($element, $timeout, MessageService) {
    this.$element = $element;
    this.$timeout = $timeout;
    this.MessageService = MessageService;
    this.statesAllowed = ["locked", "open", "closed"];
    this.isAnimating = false;
    this.animationTimeMS = 600;
  }

  /**
   * @returns {void}
   */
  $onInit() {
    this.isOpen = false;
    this.isLocked = false;
    this.state = this.state || "closed";
    if (this.isCreating) {
      this.state = this.index === 0 ? "open" : "locked";
    }
    this.$content = this.$element.find(".collapsible-panel__content");
    this.updateState();
    this.setupSubscription();
  }

  /**
   * clean up after state destoryed
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(
      `collapsible-panel.changeState.${this.name}`
    );
  }

  /**
   * @returns {void}
   */
  setupSubscription() {
    this.MessageService.subscribe(
      `collapsible-panel.changeState.${this.name}`,
      (state) => {
        this.updateStateOnMessage(state);
      },
      1
    );

    this.MessageService.subscribe(
      "collapsible-panel.changeState.closed",
      (channel, name) => {
        if (name !== this.name) {
          this.updateStateOnMessage("closed");
        }
      }
    );

    this.MessageService.subscribe(
      "collapsible-panel.changeState.closeOne",
      (channel, name) => {
        if (name === this.name && this.state !== "closed") {
          this.updateStateOnMessage("closed");
        }
      }
    );

    this.MessageService.subscribe(
      "collapsible-panel.changeState.closeAll",
      () => {
        this.updateStateOnMessage("closed");
      }
    );
  }

  /**
   * @param {string} state
   * @returns {void}
   */
  updateStateOnMessage(state) {
    if (this.statesAllowed.includes(state) && !this.isAnimating) {
      this.state = state;
      this.updateState();
      this.notifyStateChange();
    }
  }

  /**
   * close all the open panels apart from the name passed
   */
  closeOtherPanels() {
    this.MessageService.publish(
      "collapsible-panel.changeState.closed",
      this.name
    );
  }

  /**
   * @public
   * @returns {void}
   */
  open() {
    if (this.isArticleGroup) {
      this.closeOtherPanels();
    }

    this.state = "open";
    this.isAnimating = true;
    this.$content.css("max-height", this.$content[0].scrollHeight);
    this.$timeout(() => {
      this.$content.css("max-height", "none");
      this.isAnimating = false;
    }, this.animationTimeMS);
  }

  /**
   * @public
   * @returns {void}
   */
  close() {
    if (!this.isLocked) {
      this.state = "closed";
    }
    this.isAnimating = true;
    this.$content.css("max-height", this.$content[0].scrollHeight);
    this.$timeout(() => this.$content.css("max-height", 0));
    this.$timeout(() => {
      this.isAnimating = false;
    }, this.animationTimeMS);
  }

  /**
   * @returns {void}
   */
  notifyStateChange() {
    this.MessageService.publish(
      `collapsible-panel.stateChanged.${this.name}`,
      this.state
    );
  }

  /**
   * takes state and updates the scope booleans to open/closed or locked
   * @returns {void}
   */
  updateState() {
    switch (this.state) {
      case "open": {
        this.isOpen = true;
        this.open();
        break;
      }
      case "closed": {
        this.isOpen = false;
        this.close();
        break;
      }
      case "locked": {
        this.isOpen = false;
        this.isLocked = true;
        this.close();
        break;
      }
      default: {
        this.isOpen = false;
        this.close();
        this.isLocked = false;
      }
    }
  }

  /**
   * @returns {void}
   */
  handleClick() {
    if (this.state !== "locked" && !this.isAnimating) {
      this.isOpen = !this.isOpen;
      this.isOpen ? this.open() : this.close();
      this.notifyStateChange();
    }
  }
}

const CollapsiblePanelComponent = {
  controller: CollapsiblePanelController,
  controllerAs: "component",
  transclude: {
    collapsiblePanelBody: "?collapsiblePanelBody",
    collapsiblePanelHeader: "?collapsiblePanelHeader",
  },
  bindings: {
    title: "<",
    count: "<",
    index: "<",
    isCreating: "<",
    name: "@",
    state: "@",
    isCountHighlighted: "@",
  },
  template,
};

export default CollapsiblePanelComponent;
