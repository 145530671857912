/**
 * @param {Object} _
 * @param {Object} $q
 * @param {Object} EntityFactory
 * @returns {Object}
 * @constructor
 */
function MetadataFactory(_, $q, EntityFactory) {
  const factory = {};

  /**
   * Store of all versions
   * @type {{}}
   * @private
   */
  const _versionData = {};

  /**
   * Returns the version data of this metadata type
   * @param {string} type
   * @returns {Object}
   */
  factory._getCurrentVersion = (type) => {
    if (_.isEmpty(_versionData)) {
      return _versionData;
    }

    return _versionData[type][_versionData[type].length - 1];
  };

  /**
   * Resets all version data stored
   * @param {string} type
   * @returns {Object}
   * @private
   */
  factory._removeVersionData = (type) => {
    _versionData[type].length = 0;
  };

  /**
   * See if you created a new language
   * @param {object} data
   * @param {string} type
   * @returns {Object}
   * @private
   */
  factory._isNewLanguage = (data, type) =>
    data.language_version_number === 0 &&
    factory._getCurrentVersion(type).all_languages.length <
      data.all_languages.length;

  /**
   * Add a new version
   * @param {object} data
   * @param {string} type
   * @returns {Object}
   * @private
   */
  factory._addNewVersion = (data, type) => {
    _versionData[type].push(data);
  };

  /**
   * factory._mergeDataSourceVersions
   * @param   {Object} data
   */
  factory._mergeDataSourceVersions = (data) => {
    Object.keys(_versionData).forEach((type) => {
      _versionData[type][
        _versionData[type].length - 1
      ].data_source_fields.length = 0;
      Array.prototype.push.apply(
        _versionData[type][_versionData[type].length - 1],
        data.data_source_fields
      );
    });
  };

  /**
   * factory._isNewVersion
   * @param   {object} data
   * @param   {string} type
   * @returns {Boolean}
   */
  factory._isNewVersion = (data, type) => {
    if (type === "language-versions") {
      return (
        data.language_version_number >
        factory._getCurrentVersion(type).language_version_number
      );
    }

    return (
      data.version_number > factory._getCurrentVersion(type).version_number
    );
  };

  /**
   * factory._isDataSourceChange
   * @param   {Object} data
   * @param   {String} type
   * @returns {Boolean}
   */
  factory._isDataSourceChange = (data, type) => {
    const currentVersion = factory._getCurrentVersion(type);

    return (
      !factory._isNewVersion(data, type) &&
      !factory._isNewLanguage(data, type) &&
      (_.difference(data.data_source_fields, currentVersion.data_source_fields)
        .length ||
        _.difference(currentVersion.data_source_fields, data.data_source_fields)
          .length)
    );
  };

  /**
   * Response of update
   * @private
   * @param {object} data - response data
   * @param {object} type
   * @returns {object}
   */
  factory._updateResponseHandler = (data, type) => {
    const isNewVersion = factory._isNewVersion(data, type);
    const isNewLanguage = factory._isNewLanguage(data, type);
    const isNewDataSource = factory._isDataSourceChange(data, type);

    if (isNewLanguage) {
      factory._removeVersionData(type);
      factory._addNewVersion(data, type);
    }
    if (isNewVersion) {
      factory._addNewVersion(data, type);
    }

    if (isNewDataSource) {
      factory._mergeDataSourceVersions(data);
    }
  };

  /**
   * Ensures that the entity supports versions
   * @param {Object} data
   * @returns {boolean}
   */
  factory.hasVersionSupport = (data) =>
    Number.isInteger(data.language_version_number) ||
    Number.isInteger(data.version_number);

  /**
   * Returns the version data of this metadata type
   * @param {string} type
   * @returns {Object}
   */
  factory.getCurrentVersion = (type) => factory._getCurrentVersion(type);

  /**
   * Returns all available versions
   * @param {object} data
   * @param {string} type
   * @returns {Promise}
   */
  factory.getAllVersions = (data, type) => {
    if (factory.hasVersionSupport(data)) {
      return EntityFactory.getVersions(
        EntityFactory.getEntityName(),
        data.uid,
        type
      ).then((versionData) => {
        _versionData[type] = versionData;
        return _versionData[type];
      });
    }

    _versionData[type] = [data];
    return $q.resolve(_versionData[type]);
  };

  /**
   * Gets a specific version of the data
   * @param {string} uid
   * @param {string} type
   * @param {number} itemIndex
   * @returns {Object}
   */
  factory.getDataVersion = (uid, type, itemIndex) => {
    if (_versionData[type]) {
      return $q.resolve(_versionData[type][itemIndex]);
    }

    return EntityFactory.getVersions(
      EntityFactory.getEntityName(),
      uid,
      type
    ).then((versionData) => {
      _versionData[type] = versionData;
      return _versionData[type][itemIndex];
    });
  };

  /**
   * Update the entity with updated data
   * @param {Object} data
   * @param {Object} type
   * @returns {Promise}
   */
  factory.update = (data, type) =>
    EntityFactory.update(EntityFactory.getEntityName(), data).then(
      (responseData) => factory._updateResponseHandler(responseData, type)
    );

  /**
   * Creates entity with the given data
   * @param {String} entityName
   * @param {Object} data
   * @param {String} fieldDisplayName
   * @returns {Promise}
   */
  factory.createEntity = (entityName, data, fieldDisplayName) =>
    EntityFactory.create(entityName, data).then((response) => ({
      successMessage: `You have successfully updated ${fieldDisplayName}`,
      data: response,
    }));

  /**
   * Updates entity with the given data
   * @param {String} entityName
   * @param {Object} data
   * @param {String} fieldDisplayName
   * @returns {Promise}
   */
  factory.updateEntity = (entityName, data, fieldDisplayName) =>
    EntityFactory.update(entityName, data).then((response) => ({
      successMessage: `You have successfully updated ${fieldDisplayName}`,
      data: response,
    }));

  /**
   * delete all properties of the versionData object
   * @returns {void}
   */
  factory.reset = () => {
    for (const type in _versionData) {
      if (Object.prototype.hasOwnProperty.call(_versionData, type)) {
        delete _versionData[type];
      }
    }
  };

  /**
   * Builds a success message for updating an item
   * @param {object} data
   * @param {string} type
   * @returns {string}
   */
  factory.buildSuccessMessage = (data, type) => {
    if (type === "language-versions" && factory._isNewVersion(data, type)) {
      return "You have successfully updated Language Metadata";
    }
    if (factory._isNewVersion(data, type)) {
      return "You have successfully updated Additional Metadata";
    }
    if (factory._isNewLanguage(data, type)) {
      return "You have successfully created a new Language Version.";
    }
    if (factory._isDataSourceChange(data, type)) {
      return "You have successfully updated Data Source fields.";
    }

    return "Successfully updated";
  };

  return factory;
}

export default MetadataFactory;
