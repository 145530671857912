import angular from "angular";
import DateInputService from "./date-input.service";
import dateInputDirective from "./date-input.directive";

const DateInput = angular
  .module("Skylark.Components.Fields.DateInput", [])
  .service("DateInputService", DateInputService)
  .directive("dateInput", dateInputDirective);

export default DateInput;
