import "chosen-js";

class ChosenDirectiveCtrl {
  /**
   * @constructor
   */
  constructor() {
    this.settings = undefined;

    this.defaults = {
      width: "100%",
      disable_search_threshold: 5,
      placeholder_text_single: undefined,
      maxSelection: undefined,
      searchThreshold: undefined,
    };
  }

  /**
   * bindParams
   * @param   {Object} settings - chosen directive settings
   * @returns {void}
   */
  bindParams(settings) {
    this.settings = { ...this.defaults, ...settings };
  }
}

/**
 * chosenDirective
 * @param   {Object} $timeout
 * @returns {Object} Directive Definition Object
 */
function chosenDirective($timeout) {
  return {
    restrict: "A",
    scope: {
      ngModel: "=",
      ngDisabled: "=",
      settings: "=",
      disabledOptions: "<",
      unfilteredOptions: "<",
    },
    controller: ChosenDirectiveCtrl,
    controllerAs: "chosen",
    link(scope, element) {
      scope.chosen.bindParams(scope.settings);
      element.chosen(scope.chosen.settings);

      scope.$watch("ngModel", () => {
        $timeout(() => {
          element.trigger("chosen:updated");
        }, 100);
      });

      scope.$watch("unfilteredOptions", () => {
        $timeout(() => {
          element.trigger("chosen:updated");
        }, 100);
      });

      scope.$watch("disabledOptions", () => {
        $timeout(() => {
          element.trigger("chosen:updated");
        }, 100);
      });
    },
  };
}

export default chosenDirective;
