import { extendTheme, theme as chakraTheme } from "@chakra-ui/react";
import Link from "./components/link";
import Modal from "./components/modal";
import Text from "./components/text";
import "@fontsource/work-sans/700.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";

const skylarkColors = {
  blue: "#226DFF",
  red: "#F43636",
  green: "#33BD6E",
};

const overrides = {
  colors: {
    skylarkGrey: "#B8BCC6",
    skylarkNeutral: "#F6F8FB",
    skylarkBlack: "#0E1825",
    skylark1337: "#E5E9EF",
    skylarkBlue: skylarkColors.blue,
    blue: {
      50: "#d2e1ff",
      100: "#a6c4ff",
      200: "#7aa7ff",
      300: "#4e8aff",
      400: "#387bff",
      500: skylarkColors.blue,
      600: "#1e62e5",
      700: "#1b57cc",
      800: "#174cb2",
      900: "#144199",
    },
    skylarkRed: skylarkColors.red,
    red: {
      50: "#fcd9d6",
      100: "#fab3ae",
      200: "#f88e86",
      300: "#f6685e",
      400: "#f5554a",
      500: skylarkColors.red,
      600: "#db3c30",
      700: "#c3352b",
      800: "#aa2e25",
      900: "#922820",
    },
    skylarkGreen: skylarkColors.green,
    green: {
      50: "#d6f1e2",
      100: "#ade4c5",
      200: "#84d7a8",
      300: "#5bca8b",
      400: "#47c37c",
      500: skylarkColors.green,
      600: "#2daa63",
      700: "#289758",
      800: "#23844d",
      900: "#1e7142",
    },
  },
  fontSizes: {
    xs: "0.675rem",
    sm: "0.75rem",
    md: "0.875rem",
    lg: "1rem",
    xl: "1.125rem",
    "2xl": "1.25rem",
    "3xl": "1.5rem",
    "4xl": "1.75rem",
    "5xl": "2.0rem",
    "6xl": "2.25rem",
    "7xl": "2.5rem",
    "8xl": "2.75rem",
    "9xl": "3.0rem",
  },
  fonts: {
    heading: "Work Sans",
    body: "Inter",
  },
  components: {
    Link,
    Modal,
    Text,
  },
  sizes: {
    ...chakraTheme.space,
    "launcher-h-header": "32px",
    "launcher-h-full-minus-header": "calc(100vh - 32px)",
  },
};

const theme = extendTheme(overrides);

export { theme };

export default theme;
