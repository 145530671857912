import React, { Component } from "react";
import PropTypes from "prop-types";

import { MODAL_TYPES } from "skylarklib/constants/modal.constants";

import * as MediaHelpers from "components/_react/editor/helpers/media-helpers/media-helpers";

import DropdownWrapper from "components/_react/fields/dropdowns/dropdown-wrapper";

import DropdownArticleObjects from "./dropdown-article-objects";

const Dropdown = DropdownWrapper(DropdownArticleObjects);

/**
 * DropdownArticleObjectWrapper
 */
export default class DropdownArticleObjectWrapper extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    popErrorToast: PropTypes.func.isRequired,
    renderMedia: PropTypes.func,
    triggerModal: PropTypes.func.isRequired,
    configuration: PropTypes.shape({
      tab: PropTypes.shape({
        supported_objects: PropTypes.arrayOf(Object),
      }),
    }).isRequired,
    block: PropTypes.shape({
      getKey: PropTypes.func,
    }),
    onModalSave: PropTypes.func,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    block: undefined,
    renderMedia: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.optionSelect = this.optionSelect.bind(this);
    this.createFileInput();
  }

  /**
   * componentWillUnmount
   */
  componentWillUnmount() {
    this.fileInput.removeEventListener("change", this.onChange);
  }

  /**
   * onChange
   * @param   {Event} event
   * @returns {Function}
   */
  onChange = (event) => {
    this.file = event.target.files[0];
    this.filePreview = window.URL.createObjectURL(this.file);

    this.fileInput.value = "";

    if (!MediaHelpers.isFileSupported(this.file)) {
      return this.props.popErrorToast(
        `image - filetype '${this.file.type}' is unsupported`,
        "create"
      );
    }

    return this.props.renderMedia("image", {
      file: this.file,
      filePreview: this.filePreview,
    });
  };

  /**
   * createFileInput
   */
  createFileInput() {
    this.fileInput = document.createElement("input");
    this.fileInput.setAttribute("type", "file");
    this.fileInput.setAttribute("multiple", true);
    this.fileInput.addEventListener("change", this.onChange);
  }

  /**
   * optionSelect
   */
  optionSelect(option) {
    switch (option.value) {
      case "image":
        this.fileInput.click();
        break;
      case "media-placeholder":
        this.props.renderMedia("media-placeholder");
        break;
      case "entity":
        this.triggerListModal(option);
        break;
      default:
        this.props.popErrorToast("article media", "create");
        break;
    }
  }

  /**
   * triggerListModal
   */
  triggerListModal(option) {
    this.props.triggerModal({
      metadata: {
        name: "list",
        entityType: option.name,
        type: MODAL_TYPES.ARTICLE_ADD_EPISODE,
        location: this.props.block,
      },
      onSave: this.props.onModalSave,
    });
  }

  /**
   * render
   * @returns {element}
   */
  render() {
    return (
      <div>
        <Dropdown
          {...this.props}
          options={this.props.configuration.data.supported_objects}
          onSelect={this.optionSelect}
          isBlock={!!this.props.block}
        />
      </div>
    );
  }
}
