class DimensionsService {
  /**
   * @constructor
   * @param   {Object} $q
   * @param   {Object} StoreFactory
   * @param   {Object} DimensionsFactory
   * @param   {Object} CustomersFactory
   */
  constructor($q, StoreFactory, DimensionsFactory, CustomersFactory) {
    this.$q = $q;
    this.StoreFactory = StoreFactory;
    this.DimensionsFactory = DimensionsFactory;
    this.CustomersFactory = CustomersFactory;

    this.customerStore = this._createCustomerStore();
  }

  /**
   * getDimension
   * @param   {string} dimension slug
   * @param   {string | undefined} allowedOptionsField
   * @returns {Promise}
   */
  getDimension(dimension, allowedOptionsField) {
    const deferred = this.$q.defer();

    if (!this[`${dimension}Store`]) {
      this[`${dimension}Store`] = this._createDimensionStore();
    }

    this[`${dimension}Store`].getData(dimension).then((data) => {
      if (!allowedOptionsField) {
        deferred.resolve(data);
      } else {
        this._getFilteredDimensions(deferred, data, allowedOptionsField);
      }
    });

    return deferred.promise;
  }

  /**
   * _createStore
   * @returns {function} StoreFactory#CreateInstance
   */
  _createDimensionStore() {
    return this.StoreFactory.createInstance(
      this.DimensionsFactory.getDimensionByType
    );
  }

  /**
   * _createCustomerStore
   * @returns {function} StoreFactory#CreateInstance
   */
  _createCustomerStore() {
    return this.StoreFactory.createInstance(
      this.CustomersFactory.getCurrentCustomer
    );
  }

  /**
   * _getFilteredDimensions
   * @param   {Promise} deferred
   * @param   {Array} dimensionData
   * @param   {String} allowedOptionsField
   * @returns {void}
   */
  _getFilteredDimensions(deferred, dimensionData, allowedOptionsField) {
    this.customerStore.getData().then((customerData) => {
      deferred.resolve(
        this._filterAllowed(dimensionData, customerData[allowedOptionsField])
      );
    });
  }

  /**
   * _filterAllowed
   * @param   {Array} dimensionData
   * @param   {Array} allowedOptions
   * @returns {Array} the list of dimensions
   */
  _filterAllowed(dimensionData, allowedOptions) {
    if (!allowedOptions.length) {
      return dimensionData;
    }
    return dimensionData.filter((dimension) =>
      allowedOptions.some((url) => dimension.self === url)
    );
  }
}

export default DimensionsService;
