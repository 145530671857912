import template from "./collapsible-preview.html";

/**
 * @fileOverview  make a single element collapsible if height is more than one line
 */
class CollapsiblePreviewCtrl {
  /**
   * @constructor
   * @param   {Object} $element
   * @param {Object} $timeout
   */
  constructor($element, $timeout) {
    this.$element = $element;
    this.$timeout = $timeout;
  }

  /**
   * $onInit
   * @returns {void}
   */
  $onInit() {
    this.isClosed = false;
    this._applyStyles();
  }

  /**
   * apply styles to elements - uses timeout to ensure DOM has rendered
   * @returns {void}
   */
  _applyStyles() {
    this.$timeout(() => {
      this.isCollapsible = this._isCollapsible();
      this.isLoaded = true;
      if (this.isCollapsible) {
        this.toggle();
      }
    });
  }

  /**
   * _isCollapsible
   * @returns {Boolean}
   */
  _isCollapsible() {
    return (
      this.$element
        .find(".js-collapsible-preview-transcluded")
        .children()
        .height() > 20
    );
  }

  /**
   * toggle
   * @returns {void}
   */
  toggle() {
    this.isClosed = !this.isClosed;
    this.$element
      .find(".js-collapsible-preview-transcluded")
      .children()
      .toggleClass("collapsed");
  }
}

const CollapsiblePreviewComponent = {
  controller: CollapsiblePreviewCtrl,
  controllerAs: "component",
  transclude: true,
  template,
};

export default CollapsiblePreviewComponent;
