import { combineReducers } from "redux";
import accounts from "./accounts/accounts.reducer";
import entity from "./entity/entity.reducer";
import notifications from "./notification/notification.reducer";
import ovpAssets from "./ovp-asset-upload/ovp-asset-upload.reducer";
import textTracks from "./text-track-upload/text-track-upload.reducer";
import navigation from "./navigation/navigation.reducer";
import entitiesConfig from "./entities-config/entities-config.reducer";
import search from "./search/search.reducer";
import globalParams from "./global-params/global-params.reducer";
import dimensions from "./dimensions/dimensions.reducer";
import entityListing from "./listing/entity-listing-page.reducer";
import tagCategories from "./tag-categories/tag-categories.reducer";
import modalListing from "./listing/modal-listing.reducer";

import editingState from "./editing-state/editing-state.reducer";
import editor from "./editor/editor.reducer";
import editorMediaMetadata from "./editor-media-metadata/editor-media-metadata.reducer";

import modal from "./modal/modal.reducer";
import modalNotification from "./modal-notification/modal-notification.reducer";
import toaster from "./toaster/toaster.reducer";
import language from "./languages/languages.reducer";
import articleStatus from "./article-status/article-status.reducer";

const RootReducer = combineReducers({
  accounts,
  dimensions,
  entity,
  entitiesConfig,
  entityListing,
  notifications,
  ovpAssets,
  textTracks,
  search,
  modalListing,
  navigation,
  globalParams,
  tagCategories,
  editingState,
  editor,
  editorMediaMetadata,
  modal,
  modalNotification,
  toaster,
  language,
  articleStatus,
});

export default RootReducer;
