/**
 * @fileOverfiew
 * Angular Filter that takes a search string, an array of objects and a field name
 * and returns only objects with a field with words STARTING with the given string.
 */

/**
 * filterByStart
 * @returns {function} filter function
 */
function startsWith() {
  return (list, searchString, fieldName) => {
    if (searchString) {
      const searchStringLength = searchString.length;
      const searchStringLowercase = searchString.toLowerCase();

      return list.filter((item) => {
        const name = item[fieldName].toLowerCase().split(" ");

        const severalWords = name.some(
          (subname) =>
            subname.substring(0, searchStringLength) === searchStringLowercase
        );
        const fullString =
          item[fieldName].toLowerCase().substring(0, searchStringLength) ===
          searchStringLowercase;

        return severalWords || fullString;
      });
    }

    return list;
  };
}

export default startsWith;
