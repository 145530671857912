import * as EntityAction from "store/entity/entity.action";
import scheduleBlockTemplate from "./schedule-block/schedule-block.html";
import defaultTemplate from "./scheduling-module.html";

/**
 *  @file  Configurable module for schedules in entities
 * @TODO deprecate all the cookie functionality
 */

/**
 * @class SchedulingDirectiveController
 */
class SchedulingDirectiveController {
  /**
   * @constructor
   * @param   {Object} $state
   * @param   {Object} $cookies
   * @param   {Object} $scope
   * @param   {Object} ReduxConnector
   * @param   {Object} SchedulesFactory
   * @param   {Object} ApiRequestConfigFactory
   * @param   {Object} ApiService
   * @param   {Object} EntityFactory
   * @param   {Object} MessageService
   * @param   {Object} NotificationService
   * @param   {Object} ConfigurationFactory
   * @param   {Object} _
   */
  constructor(
    $state,
    $cookies,
    $scope,
    ReduxConnector,
    SchedulesFactory,
    ApiRequestConfigFactory,
    ApiService,
    EntityFactory,
    MessageService,
    NotificationService,
    ConfigurationFactory,
    _
  ) {
    this.$state = $state;
    this.$cookies = $cookies;
    this.$scope = $scope;
    this.ReduxConnector = ReduxConnector;
    this.SchedulesFactory = SchedulesFactory;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ApiService = ApiService;
    this.EntityFactory = EntityFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this._ = _;

    this.connectToStore();
  }

  /**
   * $onInit
   * @returns {void}
   */
  $onInit() {
    this.isRights = this.type === "rights";
    this.filter = {
      fields_to_expand: "schedule_urls",
      fields: "schedule_urls,uid,self",
      rights: this.isRights,
    };
    this.parentId = this.data.uid;
    this.entity = {
      parentId: this.data.uid,
      parentType: this.$state.current.title,
      parentTitle: this.data.title,
    };

    this.applyGlobalScheduleConfig();

    if (this.data.schedule_urls) {
      this.getSchedules();
    }

    this.setSchedules();
    this._setupSubscriptions();
  }

  /**
   * on scope changes
   */
  $onChanges() {
    this.setSchedules();
  }

  /**
   * _setSubscriptions
   * @listens MessageService#Modal.Save - from Modal
   * @listens MessageService#Entity.Schedules.Update - From parent
   * @listens MessageService#Schedule.Remove - From scheduleUpdate.js
   */
  _setupSubscriptions() {
    this.dataChannel = `${this.data.uid}.dataChannel`;
    this.scheduleRemove = "Schedule.Remove";
    this.scheduleAdd = `Modal.Save.schedules.base.${this.parentId}`;

    this.MessageService.subscribe(
      `EditableModal.Create.schedules.${this.parentId}`,
      (channel, data) => {
        this._createSchedule(data);
      }
    );

    this.MessageService.subscribe(this.scheduleAdd, (channel, data) => {
      this.addSchedules(data);
    });

    this.MessageService.subscribe(this.scheduleRemove, (channel, data) => {
      this.removeSchedules(data);
    });
  }

  /**
   * unregisterChannel
   */
  unregisterChannel() {
    this.MessageService.unregisterChannel(this.scheduleRemove);
    this.MessageService.unregisterChannel(this.scheduleAdd);
    this.MessageService.unregisterChannel(
      `EditableModal.Create.schedules.${this.parentId}`
    );
  }

  /**
   *  hasChildEntity
   *  If a child entity exists, it adds itself to the parent and removes the cookie.
   */
  hasChildEntity() {
    const cookieName = `${this.entity.parentType}childEntity`;
    const childEntity = this.$cookies.getObject(cookieName) || null;
    this.$cookies.remove(cookieName);

    if (childEntity && childEntity.parent.parentId === this.parentId) {
      const childEntityArray = [];
      childEntityArray.push(childEntity.child);
      this.addSchedules(childEntityArray);
    }
  }

  /**
   * fetching the config from the licensing or editorial schedule for editing the schedule
   * the global config is not taken when config fields are defined in case some entities are having specific fields
   */
  applyGlobalScheduleConfig() {
    if (this.config && this.config.fields) {
      this.modalConfig = { ...this.config };
      return;
    }

    const scheduleEntity = this.isRights ? "licensing" : "editorial-schedules";
    this.ConfigurationFactory.getEntityBaseConfiguration(scheduleEntity).then(
      (config) => {
        this.modalConfig = {
          ...this.config,
          fields: config.fields,
        };
      }
    );
  }

  /**
   * getSchedules loads list of schedules of parent entity
   */
  getSchedules() {
    if (!this.isScheduleUrlsExpanded) {
      this.store.fetchAndAttachEntity(
        this.EntityFactory.getEntityName(),
        this.EntityFactory.getEntityId(),
        this.filter
      );
    }
  }

  /**
   * setting the correct schedules for the component, respecting rights property
   */
  setSchedules() {
    this.schedules = this.SchedulesFactory.filterScheduleUrlsByRights(
      this.data.schedule_urls,
      this.isRights
    );
    this.isScheduleUrlsExpanded = this.SchedulesFactory.isScheduleUrlsExpanded(
      this.data.schedule_urls
    );
  }

  /**
   * removeSchedules
   * @description  remove schedule from array and notify parent
   * @param  {object} scheduleToRemove - schedule object to be deleted
   */
  removeSchedules(scheduleToRemove) {
    const patchedData = {
      schedule_urls: this.schedules.filter(
        (schedule) => schedule.self !== scheduleToRemove.self
      ),
    };

    const successMessage = `You have successfully removed ${scheduleToRemove.title}`;
    const errorMessage = `Something went wrong when removing ${scheduleToRemove.title}`;

    this.store.updateEntity(
      this.data.self,
      patchedData,
      this.filter,
      successMessage,
      errorMessage
    );
  }

  /**
   * addSchedules
   * @param {array} data - array of new schedule objects to be added to entity
   * @returns {void}
   */
  addSchedules(data) {
    const patchedData = {
      schedule_urls: [...this.schedules, ...data],
    };
    const title = data.length > 1 ? "multiple schedules" : data[0].title;
    const successMessage = `You have successfully added ${title}`;
    const errorMessage = `Something went wrong when adding ${title}`;

    this.store.updateEntity(
      this.data.self,
      patchedData,
      this.filter,
      successMessage,
      errorMessage
    );
  }

  /**
   * _createSchedule
   * @param   {Object} data
   */
  _createSchedule(data) {
    const scheduleToAdd = this.isRights
      ? {
          ...data,
          rights: true,
        }
      : data;
    this.SchedulesFactory.create(scheduleToAdd).then((responseData) => {
      this.addSchedules([responseData]);
    });
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };
    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  /**
   * on destroy
   */
  $onDestroy() {
    this.disconnect();
    this.unregisterChannel();
  }
}

/**
 *  @name  schedulesDirective
 *  @returns {object} - Directive Definition Object
 */
function schedulingDirective() {
  return {
    bindToController: {
      data: "<",
      config: "<",
      isCreating: "<",
      type: "@",
      isScheduleBlock: "<?",
    },
    scope: {},
    controller: SchedulingDirectiveController,
    controllerAs: "module",
    template(elem, attr) {
      if (attr.isScheduleBlock) {
        return scheduleBlockTemplate;
      }

      return defaultTemplate;
    },
  };
}

export default schedulingDirective;
