import angular from "angular";
import Shared from "./shared/shared.module";
import EntityContent from "./entity-content/entity-content.module";
import EntityDetail from "./entity-detail/entity-detail.module";
import EntityRelationships from "./entity-relationships/entity-relationships.module";
import EntityListing from "./entity-listing/entity-listing.module";
import EntityVersions from "./entity-versions/entity-versions.module";
import EntityImagery from "./entity-imagery/entity-imagery.module";
import EntityEPG from "./entity-epg/entity-epg.module";
import EntityInformation from "./entity-information/entity-information.module";
import EntityDocuments from "./entity-documents/entity-documents.component";

const Entity = angular.module("Skylark.Pages.Entity", [
  Shared.name,
  EntityContent.name,
  EntityDetail.name,
  EntityRelationships.name,
  EntityListing.name,
  EntityListing.name,
  EntityVersions.name,
  EntityImagery.name,
  EntityEPG.name,
  EntityInformation.name,
  EntityDocuments.name,
]);

export default Entity;
