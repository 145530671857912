import template from "./modal-container.html";

/**
 * @file ModalContainer
 * handles the number of modal instances open.
 *
 */
/**
 * @memberOf  Components.Modal
 * @classdesc the modal container listens for initialisation triggers of each modal,
 * keeping track of how many are currently initialised in the screen.
 * When an event occurs, it gets the data independently from the ModalService, using the uid
 * that has been passed through.
 * When a modal is destroyed, it pops it off the array, thus removing it from the DOM and cleaning
 * scopes cleanly.
 */
class ModalContainerCtrl {
  /**
   * @constructor
   * @param   {Object} $document
   * @param   {Object} $rootScope
   * @param   {Object} $scope
   * @param   {Object} ModalService
   * @param   {Object} MessageService
   */
  constructor($document, $rootScope, $scope, ModalService, MessageService) {
    this.$document = $document;
    this.$rootScope = $rootScope;
    this.$scope = $scope;

    this.ModalService = ModalService;
    this.MessageService = MessageService;
    this.modalInstances = [];
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this._setupSubscriptions();
  }

  /**
   * getNewInstance
   * get instance of the modal
   * @param {number} uid
   * @returns {void}
   */
  getNewInstance(uid) {
    this.modalInstances.push(this.ModalService.getInstance(uid));
    this.$rootScope.isLocked = true;
    this._bindKeyEvents();
  }

  /**
   * destroyModal
   * @param   {string} modal - 'current' or 'all'
   * @returns {void}
   */
  destroyModal(modal = "current") {
    if (modal === "all") {
      this._destroyAllInstances();
    } else {
      this._destroyInstance();
    }
  }

  /**
   * destroyInstance
   * @returns {void}
   */
  _destroyInstance() {
    this.modalInstances.pop();
    this.ModalService.destroyModal();
    this.MessageService.publish("skylark.destroyModal");
    this._restoreDOM();
  }

  /**
   * _destroyAllInstances
   * @returns {void}
   */
  _destroyAllInstances() {
    this.modalInstances.length = 0;
    this.ModalService.destroyAllModals();
    this.MessageService.publish("skylark.destroyModal");
    this._restoreDOM();
  }

  /**
   * _restoreDOM  - destroys key events and unlocks body
   * @returns {void}
   */
  _restoreDOM() {
    this._unbindKeyEvents();
    this._unlockBody();
  }

  /**
   * setupSubscriptions
   * @description channels are locked here to ensure they do not get unregistered, as the
   * modal container is never removed from the DOM.
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.registerChannel("skylark.initModal");
    this.MessageService.lockChannel("skylark.initModal");
    this.MessageService.registerChannel("skylark.destroyModal");
    this.MessageService.lockChannel("skylark.destroyModal");

    this.MessageService.on("skylark.initModal", (channel, uid) =>
      this.getNewInstance(uid)
    );
  }

  /**
   * setupKeyEvents
   * @returns {void}
   */
  _bindKeyEvents() {
    this.$document.on("keyup", (event) => {
      if (event.keyCode === 27) {
        this._destroyInstance();
        this.$scope.$apply();
      }
    });
  }

  /**
   * destroyKeyEvents
   * @returns {void}
   */
  _unbindKeyEvents() {
    if (!this.modalInstances.length) {
      this.$document.off("keyup");
    }
  }

  /**
   * _unlockBody
   * @returns {void}
   */
  _unlockBody() {
    if (this.$rootScope.isLocked && !this.modalInstances.length) {
      this.$rootScope.isLocked = false;
    }
  }
}

/**
 * modalContainer
 * @returns {Object} directive definition object
 */
function modalContainer() {
  return {
    restrict: "E",
    controller: ModalContainerCtrl,
    controllerAs: "component",
    scope: {},
    template,
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default modalContainer;
