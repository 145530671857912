import angular from "angular";

const SchedulesRoute = angular.module("SchedulesRoute", []).config(router);

/**
 * Clears all messaging channels
 * @param {object} MessageService - the messaging service
 * @returns {void}
 */
function clearChannels(MessageService) {
  MessageService.clearChannels();
}

/**
 * resetLanguages
 * @param   {Object} EntityDimensionsService
 * @returns {void}
 */
function resetLanguages(EntityDimensionsService) {
  EntityDimensionsService.reset();
}

/**
 * resetEditingState
 * @param   {Object} EditingService
 * @returns {void}
 */
function resetEditingState(EditingService) {
  EditingService.disableEditing();
}

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);

  const resolvers = {
    clearChannels,
    resetLanguages,
    resetEditingState,
  };

  const views = angular.extend({
    main: {
      component: "entityListingPage",
    },
  });

  $stateProvider
    .state("editorial-schedules", {
      resolve: resolvers,
      url: "/editorial-schedules",
      title: "editorial-schedules",
      pageTitle: "editorial-schedules:listing",
      views,
    })
    .state("licensing", {
      resolve: resolvers,
      url: "/licensing",
      title: "licensing",
      pageTitle: "licensing:listing",
      views,
    });
}

export default SchedulesRoute;
