const initialState = {
  modalInstances: [],
};

/**
 * Modal reducer
 * @param   {Object} state - modals
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case "CREATE_MODAL":
      return {
        ...state,
        modalInstances: [...state.modalInstances, { ...action.options }],
      };
    case "DESTROY_MODAL":
      return {
        ...state,
        modalInstances: [...action.instances],
      };
    default:
      return state;
  }
}
