/**
 * @fileOverview
 * Notification Constants
 * Notification constants can be static or have placeholders.
 * Placeholders should use a named property which will then be replaced by the copy
 * provided in the notificationOptions object.
 */

/**
 * @name   ModalNotificationConstants
 * @memberof Components.Modal
 */
const MODAL_NOTIFICATION_CONSTANTS = {
  default: {
    title: "Are you sure you want to carry out this action?",
    body: [
      {
        text: "Are you sure you want to do this?",
      },
    ],
    disable: {
      visible: false,
      text: "",
      value: false,
    },
    cta: {
      confirm: {
        text: "continue",
      },
      cancel: {
        text: "cancel",
        triggers: "",
      },
    },
  },
  delete: {
    title: "Warning",
    body: [
      {
        text: "Are you sure you want to delete this item?",
      },
      {
        text: "This action is irreversible.",
      },
    ],
    disable: {
      visible: false,
      text: "",
      value: false,
    },
    cta: {
      confirm: {
        text: "delete",
      },
      cancel: {
        text: "cancel",
      },
    },
  },
  discardChanges: {
    title: "Warning",
    body: [
      {
        text: "You have unsaved changes. Are you sure you want to finish editing?",
      },
    ],
    disable: {
      visible: true,
      text: "Don't show this warning again",
      value: false,
    },
    cta: {
      confirm: {
        text: "Save and Finish",
      },
      cancel: {
        text: "Cancel",
        triggers: "",
      },
      alternative: {
        text: "Discard Changes",
      },
    },
  },
  discardAndNavigate: {
    title: "Warning",
    body: [
      {
        text: "You have unsaved changes. Are you sure you want to leave this page?",
      },
    ],
    cta: {
      confirm: {
        text: "Discard and Leave",
      },
      cancel: {
        text: "Cancel",
        triggers: "",
      },
    },
  },
  removeItem: {
    title: "Warning",
    body: [
      {
        text: "Are you sure you wish to remove this item?",
      },
    ],
    disable: {
      visible: true,
      text: "Don't show this warning again",
      value: false,
    },
    cta: {
      confirm: {
        text: "Remove",
      },
      cancel: {
        text: "Cancel",
        triggers: "",
      },
    },
  },
  removeLastSchedule: {
    title: "Warning",
    body: [
      {
        text: "Are you sure you wish to remove this schedule?",
      },
      {
        text: "If you are removing an active schedule then this item will no longer be visible on the customer-facing product.",
      },
    ],
    cta: {
      confirm: {
        text: "Remove",
      },
      cancel: {
        text: "Cancel",
        triggers: "",
      },
    },
  },
  createNewLanguageVersion: {
    title: "Warning",
    body: [
      {
        hasPlaceholders: true,
        text: "This content does not currently contain a language version for [${newLanguage}].",
      },
      {
        hasPlaceholders: true,
        text: "You can create a language version or press cancel to remain in the current language ([${currentLanguage}]).",
      },
    ],
    cta: {
      confirm: {
        text: "Add Language",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
  noLanguageAvailable: {
    title: "Warning",
    body: [
      {
        hasPlaceholders: true,
        // eslint-disable-next-line no-template-curly-in-string
        text: "This content does not currently contain a language version for [${currentLanguage}].",
      },
      {
        hasPlaceholders: true,
        // eslint-disable-next-line no-template-curly-in-string
        text: "You can create a language version for this content using [${currentLanguage}].",
      },
    ],
    cta: {
      confirm: {
        text: "Got it!",
      },
    },
  },
  noDimensionsMatch: {
    title: "Warning",
    body: [
      {
        text: "This content does not currently contain schedules matching your filter selections.",
      },
      {
        text: "You can add a schedule for this content or press cancel to revert back to the previous filter sections.",
      },
    ],
    cta: {
      confirm: {
        text: "Add Schedule",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
  bookmarkLimit: {
    title: "Too Many Bookmarks",
    body: [
      {
        text: "You must delete an existing bookmark before adding another.",
      },
    ],
    cta: {
      confirm: {
        text: "Dismiss",
      },
    },
  },
  passwordReset: {
    title: "Reset Password",
    body: [
      {
        text: "Are you sure you want to send a password reset email?",
      },
    ],
    cta: {
      confirm: {
        text: "Reset password",
      },
      cancel: {
        text: "Cancel",
      },
    },
  },
};
export default MODAL_NOTIFICATION_CONSTANTS;
