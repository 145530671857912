import _ from "lodash";
import { createSelector } from "reselect";
import { NONE_TYPE } from "./entities-config.constant";
import { defaultState } from "./entities-config.reducer";
import { SelectorInterface } from "./entities-config.types";

/**
 * get the configuration for entity and optional entityType
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @return {Object}
 */
export const getConfigByEntity = (state, entity, entityType = NONE_TYPE) => {
  if (!entity || !state.entitiesConfig) {
    return defaultState;
  }

  if (
    entity === "assets" &&
    state.entitiesConfig[entity] &&
    state.entitiesConfig[entity][NONE_TYPE] &&
    state.entitiesConfig[entity][entityType]
  ) {
    return _.merge(
      state.entitiesConfig[entity][NONE_TYPE],
      state.entitiesConfig[entity][entityType]
    );
  }

  if (
    state.entitiesConfig[entity] &&
    state.entitiesConfig[entity][entityType]
  ) {
    return state.entitiesConfig[entity][entityType];
  }

  return defaultState;
};

/**
 * get base configuration
 * @param {Object} state
 * @param {String} entityName
 * @param {String} [entityType]
 * @return {Object}
 */
export const getBaseConfig: SelectorInterface = (
  state,
  entityName,
  entityType
) => getConfigByEntity(state, entityName, entityType).base;

/**
 * get information configuration
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @return {Object}
 */
export const getInformationConfig: SelectorInterface = (
  state,
  entity,
  entityType
) => getConfigByEntity(state, entity, entityType).information;

/**
 * get version configuration
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @return {Object}
 */
export const getVersionConfig: SelectorInterface = (
  state,
  entity,
  entityType
) => getConfigByEntity(state, entity, entityType).version;

/**
 * get relationship configuration
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @return {Object}
 */
export const getRelationshipConfig: SelectorInterface = (
  state,
  entity,
  entityType
) => getConfigByEntity(state, entity, entityType).relationship;

/**
 * Handles the visibility of the breadcrumb based on the config and breadcrumb service.
 * hasBreadcrumb initially checks for the configuration property and defaults to true is the
 * property is not defined. It also requires that breadcrumb is not in a workflow before hiding
 * it)
 * @param {Object} state
 * @param {String} entity
 * @param {String} entityType
 * @param {String} locked
 * @returns {Boolean}
 */
export const hasBreadcrumbs = createSelector(
  [getBaseConfig, (state, entity, entityType, locked) => locked],
  ({ data: config }, isContentBreadcrumbLocked: boolean): boolean => {
    if (_.isEmpty(config)) {
      return false;
    }

    if (_.isUndefined(config.has_breadcrumbs)) {
      return true;
    }

    return Boolean(config.has_breadcrumbs || isContentBreadcrumbLocked);
  }
);
/**
 * gets up the delete message
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @returns {string}
 */
export const getDeleteMessage = createSelector(
  [getBaseConfig],
  ({ data: config }) => {
    const singularName = config && config.singular_name;
    if (singularName) {
      return `Deleting this ${singularName.toLowerCase()} will no longer show it in placed sets.`;
    }
  }
);
