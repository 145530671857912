class StoreBuilder {
  /**
   * @constructor
   * @param {function} cb
   * @param {object} $q
   */
  constructor(cb, $q) {
    this.cb = cb;
    this.$q = $q;
    this.deferred = this.$q.defer();
    this._isProcessing = false;
  }

  /**
   * @returns {Promise}
   */
  getData(config = {}) {
    this.config = config;

    if (this.data) {
      this._returnData();
    } else {
      this._processPromise();
    }

    return this.deferred.promise;
  }

  /**
   * @private
   * @returns {void}
   */
  _checkForData() {
    setTimeout(() => {
      if (this.data) {
        this.deferred.resolve(this.data);
      } else {
        this._checkForData();
      }
    }, 50);
  }

  /**
   * @private
   * @returns {void}
   */
  _returnData() {
    this.deferred.resolve(this.data);
  }

  /**
   * @private
   * @returns {void}
   */
  _runApiCall() {
    this.cb(this.config)
      .then((data) => {
        this.data = data.objects ? data.objects : data;
        this._returnData();
      })
      .catch((err) => {
        this.deferred.reject(err);
      });
  }

  /**
   * @private
   * @returns {void}
   */
  _processPromise() {
    if (this._isCached) {
      this._checkForData();
    } else {
      this._isCached = true;
      this._runApiCall();
    }
  }
}

/**
 *
 * @param {object} $q
 * @returns {{}}
 * @constructor
 */
function StoreFactory($q) {
  const factory = {};

  /**
   * creates a new instance of class
   * @param {function} cb
   * @returns {instance}
   */
  factory.createInstance = (cb) => new StoreBuilder(cb, $q);

  /**
   * return copy of it's class
   * @returns {Class}
   */
  factory.getClass = () => StoreBuilder;

  return factory;
}

export default StoreFactory;
