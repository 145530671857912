import uuidv4 from "uuid/v4";

class DynamicField {
  /**
   * @constructor
   * @param   {Object} $scope
   * @param   {Object} $element
   * @param   {Object} TemplateFactory
   * @param   {Object} NotificationService
   * @param   {Object} DYNAMIC_FIELD_TYPES
   * @param   {Object} DYNAMIC_FIELD_VALIDATION_REG_EXP
   * @param   {Object} _ lodash
   */
  constructor(
    $scope,
    $element,
    TemplateFactory,
    NotificationService,
    DYNAMIC_FIELD_TYPES,
    DYNAMIC_FIELD_VALIDATION_REG_EXP,
    _
  ) {
    this.$scope = $scope;
    this.TemplateFactory = TemplateFactory;
    this.NotificationService = NotificationService;
    this.DYNAMIC_FIELD_TYPES = DYNAMIC_FIELD_TYPES;
    this.DYNAMIC_FIELD_VALIDATION_REG_EXP = DYNAMIC_FIELD_VALIDATION_REG_EXP;
    this._ = _;

    this.element = $element;

    this.fieldTypes = this.DYNAMIC_FIELD_TYPES;

    this.uuid = uuidv4();
  }

  $onInit() {
    this.getValidationRegExp(this.field);
    this.fetch();
  }

  /**
   * @description creates the reg expression needed for creation the validation
   * of the form.
   * @param {object} field
   * @returns {void}
   */
  getValidationRegExp(field) {
    const type = this._.get(field, "validation.pattern.type");

    if (type) {
      const { regExp } = this.DYNAMIC_FIELD_VALIDATION_REG_EXP[type];
      this.regexp = new RegExp(regExp);
    }
  }

  /**
   * fetch
   * @returns {void}
   */
  fetch() {
    this._.forEach(this.fieldTypes, (field) => {
      if (this.field.widget === field.type) {
        this.render(field.template);
      }
    });
  }

  /**
   * render template
   * @param {string} template - from template factory
   * @returns {void} [description]
   */
  render(template) {
    const compiledTemplate = this.TemplateFactory.compileTemplate(template);

    this.element.append(compiledTemplate.el);
    compiledTemplate.compiled(this.$scope);
  }
}

/**
 * dynamicFieldDirective
 * @returns {Object} directive definition object
 */
function dynamicFieldDirective() {
  return {
    restrict: "EA",
    replace: true,
    controller: DynamicField,
    controllerAs: "item",
    bindToController: {
      hasAutoSave: "<?",
      index: "@",
      uid: "@",
      field: "<",
      hideDataSource: "<",
    },
  };
}

export default dynamicFieldDirective;
