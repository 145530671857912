import angular from "angular";
import Entity from "./entity/entity.module";
import Error from "./error/error.module";
import Sets from "./sets/sets.module";
import User from "./user/user.module";

const Pages = angular.module("Skylark.Pages", [
  Entity.name,
  Error.name,
  Sets.name,
  User.name,
]);

export default Pages;
