import template from "./navigation.html";

/**
 * Navigation template directive
 */
class NavigationCtrl {
  /**
   * @constructor
   * @param   {Object} $element
   * @param   {Object} $rootScope
   * @param   {Object} $state
   * @param   {Object} $timeout
   * @param   {Object} EntityFactory
   * @param   {Object} NavigationService
   * @param   {Object} NotificationService
   * @param   {Object} PermissionsService
   * @param   {Object} ConfigurationFactory
   * @param   {Object} CustomersFactory
   * @param   {Object} _
   * @returns {void}
   */
  constructor(
    $element,
    $rootScope,
    $scope,
    $state,
    $timeout,
    EntityFactory,
    MessageService,
    NavigationService,
    NotificationService,
    PermissionsService,
    ConfigurationFactory,
    CustomersFactory,
    _
  ) {
    this.$element = $element;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$timeout = $timeout;
    this.EntityFactory = EntityFactory;
    this.MessageService = MessageService;
    this.NavigationService = NavigationService;
    this.NotificationService = NotificationService;
    this.PermissionsService = PermissionsService;
    this.ConfigurationFactory = ConfigurationFactory;
    this.CustomersFactory = CustomersFactory;
    this._ = _;

    this._onScroll = this._onScroll.bind(this);

    this.title = this._.startCase(this.$state.current.title);
    this.$rootScope.$title = this.title;
    this.$rootScope.navIsOpen = false;
    this.isOpen = false;
    this.permissions = {};
  }

  /**
   * $onInit
   */
  $onInit() {
    this._getUserDetails();
    this._getConfig();
    this._setCurrent();
  }

  $postLink() {
    this._bindScrollEvents();
  }

  /**
   * $onDestroy
   */
  $onDestroy() {
    this._unbindEvents();
  }

  /**
   * bind scroll events for when navigation scrolls
   */
  _bindScrollEvents() {
    this.$timeout(() => {
      this.$element
        .find(".navigation__container")
        .bind("scroll", this._.debounce(this._onScroll, 50));
    }, 3000);
  }

  /**
   * unbind scroll event to component
   */
  _unbindEvents() {
    angular.element(this.navContainer).unbind("scroll", this._onScroll);
  }

  /**
   * sets navigation scroll position
   * @param  {UIEvent} event
   */
  _onScroll(event) {
    this.navScroll = event.currentTarget.scrollTop;
    this.$scope.$apply();
  }

  /**
   * Get the user details
   * @returns {void}
   */
  _getUserDetails() {
    this.CustomersFactory.getCurrentCustomer()
      .then((data) => {
        this.username = `${data.first_name} ${data.last_name}`;
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * Returns a list of permissions for this entity
   * @returns  {Array}
   */
  _getPermissionsToCheck() {
    return this.NavigationService.flattenItems(this.data.items)
      .filter((item) => item.permission)
      .map((item) => item.permission);
  }

  /**
   * Gets the config object and checks config permissions
   * @returns  {void}
   */
  _getConfig() {
    this.ConfigurationFactory.getEntityBaseConfiguration("navigation")
      .then((data) => {
        this.data = data;
        this._checkNavPermissions();
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * Sets current nav item on initialisation
   * @returns  {void}
   */
  _setCurrent() {
    this.$rootScope.activeNavItem =
      this.EntityFactory.getEntityType() || this.EntityFactory.getEntityName();
  }

  /**
   * Check and set permissions for navigation
   * @returns  {void}
   */
  _checkNavPermissions() {
    this._getPermissionsToCheck().forEach((permission) => {
      this.PermissionsService.checkAccess(permission)
        .then((userAccess) => {
          this.permissions[permission] = userAccess.hasAccess;
        })
        .catch(() => this.NotificationService.notifyRefresh());
    });
  }

  /**
   * Checks if nav section / link has user permissions
   * @param    {Object} section
   * @returns  {Boolean}
   */
  hasPermissions(section) {
    return this.NavigationService.hasPermissions(section, this.permissions);
  }

  /**
   * Toggle display for mobile
   * @param {Object} $event click event
   * @returns {void}
   */
  toggleNav() {
    this.isOpen = !this.isOpen;
  }

  inV8Environment() {
    return __V8_ENVIRONMENT__;
  }

  /**
   * Returns the path for home e.g. "/"
   * @returns string
   */
  getHomeHref() {
    return __BASE_PATH__;
  }

  /**
   * Returns the launcher URL
   * @returns string
   */
  getLauncherHref() {
    return `${__LAUNCHER_URL__}?preferred_app=false`;
  }

  /**
   * Returns the path for logout
   * @returns string
   */
  getLogoutHref() {
    if (this.inV8Environment()) {
      return `${__LAUNCHER_URL__}/logout`;
    }
    return "/logout";
  }
}

const navigationComponent = {
  restrict: "E",
  controller: NavigationCtrl,
  controllerAs: "component",
  template,
};

export default navigationComponent;
