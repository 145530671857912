/**
 *  Loading Service
 *  @fileOverview  handle all kinds of loading states
 *  @description
 *  Loading is a complex affair in this app. It can either be triggered by specific router events,
 *  which are listened to in our higher-level loading.js directive, or manually inside any other
 *  directive or controller when appropriate (i.e. lazy loading). At a service level we keep track
 *  of how many requests have been made and resolved since the loading process was initiated.
 *  Setting the current loading status to a rootScope property allows to drive the toggling of the
 *  loading state element through state and display the correct DOM element.
 *
 * For dev purposes, loading can be disabled by commenting out $rootScope.loading = true
 * in service#loadingToggle
 *
 */

/**
 * list of urls which do not toggle loading states by default
 * @type {Array}
 *
 */
const exclusionList = ["/logout", "/login"];

/**
 * currentView - keep track of where we are and how many of our requests have
 * finished.
 * @access private
 * @type {Object}
 * @property {string} - view viewname
 * @property {int} - counter
 * @property {boolean} - current loading state
 */
const currentView = {
  view: null,
  counter: 0,
  loading: false,
};

/**
 * The main loading service
 * @param {object} $q - Angular promise service
 * @param {object} $rootScope - Angular rootScope service
 * @param {object} $timeout - Angular timeout service
 * @returns {object} - service
 */
function LoadingService($q, $rootScope, $timeout) {
  const service = {};

  /**
   * Add one to private property currentView.counter
   * @access private
   * @returns {void}
   */
  service.increaseCurrentViewCounter = () => {
    currentView.counter++;
  };

  /**
   * Subtract one to private property currentView.counter
   * @access private
   * @returns {void}
   */
  service.decreaseCurrentViewCounter = () => {
    currentView.counter--;
  };

  /**
   * Returns the currentViews counter
   * @access private
   * @returns {number} - current number of the counter
   */
  service.getCurrentViewCounter = () => currentView.counter;

  service.resetCounter = () => (currentView.counter = 0);
  /**
   * Sets private property currentView.view
   * @access private
   * @param {string} view - viewname
   * @returns {void}
   */
  service.setCurrentView = (view) => {
    currentView.view = view;
  };

  /**
   * Sets private property currentView.loading
   * @access private
   * @param {boolean} loading - is the view loading
   * @returns {void}
   */
  service.setCurrentViewLoading = (loading) => {
    currentView.loading = loading;
  };

  /**
   * Return the if the current view is loading
   * @access private
   * @returns {boolean} - current view's loading state
   */
  service.getCurrentViewLoading = () => currentView.loading;

  /**
   * request - native interceptor method
   * @access public
   * @param  {object} req - api request
   * @returns {object} req - post transformation
   */
  service.request = (req) => {
    const { method } = req;

    if (method !== "GET") {
      return req;
    }

    service.setCurrentViewLoading(true);
    service.increaseCurrentViewCounter();

    return req;
  };

  /**
   * response - native interceptor method
   * @access public
   * @param {object} res - response of the resolved promise
   * @returns {object} modified response
   */
  service.response = (res) => {
    const { config } = res;
    const { method } = config;

    if (method !== "GET") {
      return res;
    }

    service.decreaseCurrentViewCounter();
    service.loadingEnd();

    return res;
  };

  /**
   * @access public
   * @todo: we are defending against 406s from broken data - this is very bad practice
   * @param {object} err - error object
   * @returns {object} - rejected promise
   */
  service.responseError = (err) => {
    if (err.status !== 406) {
      $rootScope.loadingError = true;
    }

    service.decreaseCurrentViewCounter();
    service.loadingEnd();

    return $q.reject(err);
  };

  /**
   * isViewLoading
   * Avoids a proliferation of $rootScope in directives to verify a value.
   * @access public
   * @returns {boolean} - whether we are currently loading views.
   *
   */
  service.isViewLoading = () => $rootScope.loading;

  /**
   * inlineLoader
   * @param {boolean} bool - whether to turn the indicator true or false
   * @returns {void}
   */
  service.inlineLoader = (bool) => {
    $rootScope.isPaginationLoading = bool;
  };

  /**
   * inlineLoader
   * @param {boolean} bool - whether to turn the indicator true or false
   * @returns {void}
   */
  service.moduleLoading = (bool) => {
    $rootScope.isModuleLoading = bool;
  };

  /**
   * loadingStart
   * @access public
   * @param  {object} view - our current view - not used for anything at the moment
   * @param {string} type  - the type of loading
   * @returns {void}
   */
  service.loadingStart = (view, type) => {
    const excludedRoutes = exclusionList.filter((cur) => cur === view.url);

    if (!excludedRoutes.length) {
      service.setCurrentViewLoading(true);

      $rootScope.loadingType = type;
      $rootScope.loading = true;
      $rootScope.loadingError = false;

      /*
       * we started loading without knowing if there will be any request,
       * as the loadingEnd is only triggered on a response,
       * it would be good to check after a short time if any request got triggered, otherwise finish loading
       */
      $timeout(() => service.loadingEnd(), 1000);
    }
  };

  /**
   * loadingEnd
   * @access public
   * @description - checks if our counter is back to 0
   * @todo $timeout might be better as an animation-delay
   */

  service.loadingEnd = () => {
    if (service.getCurrentViewCounter() <= 0) {
      service.setCurrentViewLoading(false);

      /*
       * this looks like a dirty hack, but:
       * $timeout returns a promise, and sticks our callback in the end of the current digest
       * cycle.This ensures that there are no unresolved promises that the view is waiting for
       * before toggling $rootScope. If currentView is toggled to true because another request
       * has been initiated the conditional will not run loadingEnd() will run again when new
       * requests have been successfully resolved.
       */
      $timeout(() => {
        if (!service.getCurrentViewLoading()) {
          $rootScope.loading = false;
          $rootScope.loadingType = null;

          // set initial page load complete the first time loading is set to false
          if (!$rootScope.initialPageLoadComplete) {
            $rootScope.initialPageLoadComplete = true;
          }
        }
      }, 0);
    }
  };

  return service;
}

export default LoadingService;
