import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./upload-area-url.html";

class UrlController {
  /**
   * @param NotificationService
   */
  constructor(NotificationService) {
    this.NotificationService = NotificationService;
    this.data = {
      url: "",
    };
  }

  /**
   * Takes a URL and returns a title
   * 1. Splits the URL on '/' in order to take only the name itself of the file
   * 2. Checks if there's any query string, truncating everything after '?'
   * 3. Checks if there's any extension and eventually removes everything after it including the extension
   * 4. Checks the length, if it's longer than 140, it gets truncated
   *
   * 140 is completely arbitrary
   * I just picked a number as it's a tweet long and loads of thing fit in a tweet
   * @params {string}
   * @return {string}
   */
  trimUrl(url) {
    const splitUrl = url.split("/");
    let filename = splitUrl.pop();

    if (filename.includes("?")) {
      filename = filename.split("?")[0];
    }

    filename = filename.slice(0, 140);

    return filename;
  }

  /**
   * Executes onUpload in case there's one
   * and then uploads with url.
   *
   * onUpload returns a promise in any case
   * @returns {void}
   *
   */
  uploadFromUrl() {
    if (!this.data.url) {
      return;
    }

    const filename = this.trimUrl(this.data.url);

    this.onUpload({ url: this.data.url, filename }).catch(() =>
      this.onUploadError()
    );
  }

  /**
   * Error handling in case the upload is failing
   * @returns {void}
   */
  onUploadError() {
    this.NotificationService.notifyError(NotificationMessage.urlError);
  }
}

const uploadAreaUrlComponent = {
  bindings: {
    onUpload: "&?",
    placeholderText: "@",
  },
  template,
  controller: UrlController,
  controllerAs: "component",
};

export default uploadAreaUrlComponent;
