import angular from "angular";
import creditsDirective from "./credits-module.directive";
import creditsUpdateDirective from "./credits-update.directive";

const Credits = angular
  .module("Skylark.Modules.Credit", [])
  .directive("credits", creditsDirective)
  .directive("creditsUpdate", creditsUpdateDirective);

export default Credits;
