import React from "react";
import PropTypes from "prop-types";
import moment from "moment-timezone";

import styles from "./date-column.scss";

/**
 * @class DateColumn
 */
export default function DateColumn(props) {
  return (
    <div className={styles["date-column"]}>
      {moment(props.date).format("DD/MM/YYYY")}
    </div>
  );
}

DateColumn.propTypes = {
  date: PropTypes.string.isRequired,
};
