/**
 * FeatureToggleService
 * @fileOverfiew
 * Handles interactions with the skylark release cookie
 * for release versions and allows other parts of the CMS
 * to know whether the feature can be toggled on or off.
 * Exposes a couple of public methods that can be called from the console.
 *
 */

import Cookie from "../cookies/cookies";

import FEATURE_TOGGLE_CONSTANTS from "./feature-toggle.constants";

class FeatureToggle {
  /**
   * init
   */
  init() {
    this.flag = this._getFlag();
    this._logCurrentFlag();
  }

  /**
   * isAlpha
   * @returns {Boolean}
   */
  isAlpha() {
    return this.flag === "alpha";
  }

  /**
   * getFlag
   */
  _getFlag() {
    return Cookie.get(FEATURE_TOGGLE_CONSTANTS.cookieName);
  }

  /**
   * _isValid description
   * @returns {Boolean}
   */
  _isValidFlag(flag) {
    return FEATURE_TOGGLE_CONSTANTS.allowedValues.includes(flag);
  }

  /**
   * _logCurrentFlag
   */
  _logCurrentFlag() {
    /* eslint-disable no-console */
    if (this._isValidFlag(this.flag)) {
      console.warn(`Skylark is now in ${this.flag} mode.`);
    } else if (this.flag) {
      console.error(`${this.flag} is not a valid release flag.`);
    }
    /* eslint-enable no-console */
  }
}

export default new FeatureToggle();
