/**
 * @description batch request factory
 * @param {object} $log
 * @param {object} $q
 * @param {object} BatchRequestService
 * @returns {{}}
 * @constructor
 */
function BatchRequestFactory($log, $q, BatchRequestService) {
  const factory = {};
  const requests = [];

  /**
   * add a request to be send as part of batch request
   * @param {object} request
   * @returns {void}
   */
  factory.addRequest = (request) => {
    requests.push(request);
  };

  /**
   * create a get request
   * @param {string} id
   * @param {string} url
   * @param {string} config
   * @returns {{id: *, url: *, method: string}}
   */
  factory.createGetRequest = (id, url, config = {}) => {
    const getRequest = {
      id,
      url,
      method: "GET",
      headers: config.headers || undefined,
    };

    return getRequest;
  };

  /**
   * create a batch of get requests from an array of URLs
   * @access public
   * @param {string} id - id of requests
   * @param {array} urls - Array of URLs to get
   * @param {string} filters - to combine with expanding and filtering when required
   * @param {string} config - including headers to be pass with request
   * @returns {void}
   */
  factory.createGetRequests = (id, urls = [], filters = "", config) => {
    urls.forEach((url) => {
      const currentUrl = `${url}${filters}`;
      const request = factory.createGetRequest(id, currentUrl, config);
      factory.addRequest(request);
    });
  };

  /**
   * create a post request
   * @param {string} id
   * @param {string} url
   * @param {any} data
   */
  factory.createPostRequest = (id, url, data, config = {}) => {
    const postRequest = {
      id,
      url,
      method: "POST",
      data: JSON.stringify(data),
      headers: config.headers || undefined,
    };

    factory.addRequest(postRequest);
  };

  /**
   * create a post request
   * @param {string} id
   * @param {string} url
   * @param {any} data
   */
  factory.createPutRequest = (id, url, data, config = {}) => {
    const putRequest = {
      id,
      url,
      method: "PUT",
      data: JSON.stringify(data),
      headers: config.headers || undefined,
    };

    factory.addRequest(putRequest);
  };

  /**
   * create a delete request
   * @param {string} url
   */
  factory.createDeleteRequest = (url) => {
    const deleteRequest = {
      id: url,
      url,
      method: "DELETE",
      data: "",
    };

    factory.addRequest(deleteRequest);
  };

  /**
   * process batch requests currently
   * being held in the BatchRequestService
   * @param {Object} config
   * @returns {Promise}
   */
  factory.process = (config = {}) => {
    let deferred = $q.defer();

    if (requests.length) {
      deferred = BatchRequestService.sendRequests(requests, config);
      requests.length = 0;

      return deferred;
    }
    deferred.resolve([]);

    return deferred.promise;
  };

  /**
   * Helper method to handle response of a process batch, filters response by id
   * @param {Array} batchResponse
   * @param {string} id
   * @returns {Array}
   */
  factory.parseResponse = (batchResponse, id) =>
    batchResponse
      .filter((response) => response.id === id)
      .map((response) => {
        try {
          return JSON.parse(response.body) || "";
        } catch (e) {
          // @todo: Look at better ways to handle this, for now this will do
          $log.error(`Batch request failed with id ${response.id}`);
        }
      });

  return factory;
}

export default BatchRequestFactory;
