import angular from "angular";

const ResetPasswordRoute = angular
  .module("ResetPasswordRoute", [])
  .config(router);

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("ResetPassword", {
    url: "/reset-password",
    resolve: {},
    title: "reset-password",
    pageTitle: "Reset:password",
    views: angular.extend({
      main: {
        controller: (AuthenticationService) => {
          AuthenticationService.redirectToAuthenticationApp();
        },
      },
    }),
  });
}

export default ResetPasswordRoute;
