/**
 * Handling customized Window actions
 *
 * for example ask the user to reload the page when an upload is in progress
 */
class WindowService {
  constructor($window) {
    this.$window = $window;

    // array for tracking instances uploading
    this.beforeUnloadWarningAttached = [];
  }

  /**
   * the beforeunload should have ever only one event listener for showing a warning,
   * otherwise the warning window would be triggered multiple time when multiple uploads are in process
   * this function will only attach an event if none is attached.
   *
   * @param {Number|String} id
   * @returns {void}
   */
  attachOnBeforeUnloadWarning(id) {
    if (this.beforeUnloadWarningAttached.length <= 0) {
      this.$window.addEventListener("beforeunload", this.onBeforeUnloadWarning);
    }

    if (!this.beforeUnloadWarningAttached.includes(id)) {
      this.beforeUnloadWarningAttached.push(id);
    }
  }

  /**
   * removes the id from the array, if array is empty no upload service is running,
   * and therefore no warning needs to be shown
   *
   * @param {Number|String} id
   */
  detachOnBeforeUnloadWarning(id) {
    const index = this.beforeUnloadWarningAttached.indexOf(id);

    if (index >= 0) {
      this.beforeUnloadWarningAttached.splice(index, 1);
    }

    if (index === 0) {
      this.$window.removeEventListener(
        "beforeunload",
        this.onBeforeUnloadWarning
      );
    }
  }

  /**
   * show warning onunload window
   * @param event
   * @returns {void}
   */
  onBeforeUnloadWarning(event) {
    event.preventDefault();
    event.returnValue = "";
  }
}

export default WindowService;
