import React, { useMemo } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Dropdown from "components/_react/dropdown/dropdown.component";

/**
 *
 * @param {object} filter
 * @param {function} updateFilter
 * @param {array<object>} ordering
 *
 */
const OrderingFilter = ({ filter, updateFilter, ordering }) => {
  const options = useMemo(
    () =>
      ordering.map(({ name, slug }) => ({
        value: slug,
        label: name,
      })),
    [ordering]
  );

  const selected = useMemo(() => {
    if (filter.value && filter.label) {
      return filter;
    }

    return _.find(options, { value: filter.value });
  }, [filter, options]);

  const onChangeAction = (option) => {
    updateFilter([
      {
        name: "order",
        payload: option,
        bucket: "pagination",
      },
    ]);
  };

  return (
    <Dropdown
      options={options}
      value={selected}
      onChangeAction={onChangeAction}
      ariaLabel="ordering filter"
    />
  );
};

OrderingFilter.propTypes = {
  updateFilter: PropTypes.func,
  filter: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
  ordering: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

OrderingFilter.defaultProps = {
  filter: {},
};

export default OrderingFilter;
