export default {
  globalParamsAll: {
    all: "true",
  },
  globalParamsSet: {
    current: true,
    future: true,
    expired: true,
  },
  globalParamsTypes: ["current", "future", "expired", "unscheduled"],
};
