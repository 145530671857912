import {
  setDimension,
  setLanguage,
} from "store/global-params/global-params.action";

class GlobalParamsService {
  /**
   * GlobalParamsService
   * @description Service to handle global params from localisation nav
   * @param {object} _ lodash
   * @param {object} $cookies
   * @param {object} MessageService
   * @param {object} GLOBAL_PARAM_CONSTANTS
   * @param {object} $ngRedux
   *
   * @returns {object} service - GlobalParamService
   */
  constructor(_, $cookies, MessageService, GLOBAL_PARAM_CONSTANTS, $ngRedux) {
    this._ = _;
    this.$cookies = $cookies;
    this.$ngRedux = $ngRedux;
    this.MessageService = MessageService;

    this.DEFAULT_PARAMS = GLOBAL_PARAM_CONSTANTS.defaultParams;
    this.CHANNEL_NAMES = GLOBAL_PARAM_CONSTANTS.channelNames;
    this.COOKIE_NAMES = GLOBAL_PARAM_CONSTANTS.cookieNames;

    this.globalLanguage = null;

    this.globalParams = {};
    this.previousGlobalParamsCopy = null;
  }

  /**
   * init - called from run block on app bootstrap
   * @param {string} language
   * @returns {void}
   */
  init(language) {
    this.globalLanguage = language;
    this.MessageService.registerChannel(
      this.CHANNEL_NAMES.globalLanguageChanged
    );
    this.MessageService.lockChannel(this.CHANNEL_NAMES.globalLanguageChanged);
    this.setGlobalParams(
      this.$cookies.getObject(this.COOKIE_NAMES.globalFilters)
    );
    this.setLanguageWithPublish(language);
  }

  /**
   * setGlobalParams
   * @memberOf GlobalParamsService
   * @param {Object} filters - from local nav
   * @returns {void}
   */
  setGlobalParams(filters) {
    this._previousGlobalParamsCopy();
    this.globalParams = { ...filters };
    this.$cookies.putObject(this.COOKIE_NAMES.globalFilters, filters);
    this.$ngRedux.dispatch(setDimension(filters));
  }

  /**
   * getGlobalParams
   * @returns {Object} GlobalParams (empty if unset)
   */
  getGlobalParams() {
    return this.globalParams;
  }

  /**
   * @description Stores a copy of the global params in case the schedule is not
   * available for the current entity, and the user wants to reverse them.
   * @returns {void}
   */
  _previousGlobalParamsCopy() {
    this.previousGlobalParamsCopy = this.getGlobalParams();
  }

  /**
   * Gets the previous global params
   * @returns {Object} GlobalParams (empty if unset)
   */
  getPreviousGlobalParams() {
    return this.previousGlobalParamsCopy;
  }

  /**
   * isGlobalParamsSet - wrapper for lodash method
   * @access public
   * @returns {boolean} -  whether local nav params have been set
   */
  isGlobalParamsSet() {
    return !this._.isEmpty(this.getGlobalParams());
  }

  /**
   * getDefaultParams
   * @returns {Object} - defaultParams.globalParamsUnset or
   * DefaultParams.globalParamsSet depending on globalParams status
   */
  getDefaultParams() {
    if (this.isGlobalParamsSet()) {
      return this.DEFAULT_PARAMS.globalParamsSet;
    }
    return this.DEFAULT_PARAMS.globalParamsUnset;
  }

  /**
   * set current language with publishing message
   * @param {string} isoCode - isoCode string for this language
   * @returns {void}
   */
  setLanguageWithPublish(isoCode) {
    this.setLanguage(isoCode);
    this.MessageService.publish(
      this.CHANNEL_NAMES.globalLanguageChanged,
      isoCode
    );
  }

  /**
   * set current language without publishing message
   * @param {string} isoCode - isoCode string for this language
   * @returns {void}
   */
  setLanguage(isoCode) {
    this.globalLanguage = isoCode;
    this.$cookies.put(this.COOKIE_NAMES.globalLanguage, isoCode);
    this.$ngRedux.dispatch(setLanguage(isoCode));
  }

  /**
   * getLanguageWithDefaults
   * @returns {string} service.globalLanguage - the language that is currently set with wildcard, for get requests
   */
  getLanguageWithDefaults() {
    return this.globalLanguage ? `${this.globalLanguage},*` : "*";
  }

  /**
   * getLanguage
   * @returns {string} service.globalLanguage - the language that is currently set
   */
  getLanguage() {
    return this.globalLanguage;
  }

  /**
   * overrideSchedulingParams
   * @description  Avoid overwriting schedule filtering params
   * when we have a way of setting them from the dom (dropdowns).
   * @param {object} queryObj - query object which might contain one of the specified params
   * @returns {object} defaultParams or empty object
   */
  overrideSchedulingParams(queryObj = {}) {
    let params;
    if (
      queryObj.unscheduled ||
      queryObj.expired ||
      queryObj.current ||
      queryObj.future
    ) {
      params = {};
    } else {
      queryObj.all = this.isGlobalParamsSet() ? null : queryObj.all;
      params = this.getDefaultParams();
    }

    return params;
  }
}

export default GlobalParamsService;
