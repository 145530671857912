import template from "./upload-area.html";

const uploadAreaComponent = {
  bindings: {
    onUpload: "&",
    canBeClosed: "<",
    onClose: "&",
    acceptTypes: "<",
    dragDropText: "@",
    urlPlaceholderText: "@",
    hasUrlUpload: "<?",
  },
  template,
  controllerAs: "component",
};

export default uploadAreaComponent;
