import template from "views/widgets/edit.html";

class EditCtrl {
  constructor($scope) {
    this.item = $scope.item;
  }
}

function editDirective() {
  return {
    restrict: "E",
    scope: {
      item: "=",
    },
    template,
    controller: EditCtrl,
    controllerAs: "edit",
  };
}

export default editDirective;
