import angular from "angular";
import SchedulingItem from "./scheduling-item/scheduling-item.module";
import AddScheduleButton from "./add-schedule-button/add-schedule-button.module";
import schedulingDirective from "./scheduling-module.directive";

const Scheduling = angular
  .module("Skylark.Modules.Scheduling", [
    SchedulingItem.name,
    AddScheduleButton.name,
  ])
  .directive("scheduling", schedulingDirective);

export default Scheduling;
