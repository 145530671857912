import * as Sentry from "@sentry/browser";
import { Angular as AngularIntegration } from "@sentry/integrations/dist/angular";
/**
 * we put here information which would be better set by environment variables,
 * however the production build comes from an archive created during develop build
 * no env vars are able to be used during a build
 * Once the deployment of the cms is part of the product deployment for a client
 * DNS, ENVIRONMENT (replace allowUrls) should move to env vars.
 */
export const init = () => {
  Sentry.init({
    dsn: "https://16270aa91786491cb79cba1a53786372@o732961.ingest.sentry.io/5790364",
    integrations: [new AngularIntegration()],
    environment: window.location.hostname.split(".")[0], // `rakuten-feature` for a url like https://rakuten-feature.skylark.ostmodern.co.uk
    allowUrls: [/https:\/\/(\w*[-\w*]*).skylark.ostmodern.co.uk/],
  });
};
