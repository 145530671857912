import template from "./add-schedule-button.html";

/**
 *  @fileOverview Directive for adding a new schedule to an entity.
 *  Renders an add entity list modal.
 *
 */

/**
 * @class AddScheduleButton
 */
class AddScheduleButton {
  /**
   * @constructor
   * @param {Object} MessageService
   * @param {Object} ModalTriggerService
   */
  constructor(MessageService, ModalTriggerService, ApiFilterFactory) {
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.entityType = "schedules";
  }

  /**
   * init
   * @returns {void}
   */
  $onInit() {
    this.label = this.type === "rights" ? "Licence" : "Schedule";
    this.className = this.getClassName(this.size);

    this._setupDimensionsSubscriptions();
  }

  /**
   * Returns class-name based on size
   *
   * @returns {string}
   *
   */
  getClassName(size) {
    if (size === "small") {
      return "button-small__icon";
    }

    return "button-standard__icon";
  }

  /**
   * trigger modal to add a schedule
   */
  triggerModal() {
    const options = {
      parent: this.entity,
      channels: { add: `Modal.Save.schedules.base.${this.entity.parentId}` },
      filters: {
        rights: this.type === "rights",
      },
    };

    this.ApiFilterFactory.getExcludeItemsFilter(
      this.entity.self,
      this.existing
    ).then((filters) => {
      options.filters = {
        ...options.filters,
        ...filters,
      };

      this.ModalTriggerService.triggerList(this.entityType, options);
    });
  }

  /**
   * _setupDimensionsSubscriptions
   * @returns {void}
   */
  _setupDimensionsSubscriptions() {
    this.MessageService.subscribe("LocalNav.Dimensions.addSchedule", () => {
      this.triggerModal();
    });
  }

  $onDestroy() {
    this.MessageService.unregisterChannel("LocalNav.Dimensions.addSchedule");
  }
}

const addScheduleButtonComponent = {
  bindings: {
    entity: "=",
    existing: "=",
    type: "=",
    size: "<?",
  },
  controller: AddScheduleButton,
  controllerAs: "component",
  template,
};

export default addScheduleButtonComponent;
