/**
 * @classdesc
 * A simple adapter to handle urls that need to be prefixed so
 * we hit the right endpoints in the API.
 * This is a workaround as the property `self` for entities will not return
 * the cms- prefixed URL which can cause all sorts of issues with scheduling,
 * fields that the CMS requires which are not returned for frontends, etc.
 *
 * No longer used for articles v2. Keeping this around for CMS users or sets if needed.
 * To support articles v1, use '\/api\/articles\/' in entityRegexes
 */
class ApiUrlAdapterService {
  /**
   * @constructor
   */
  constructor() {
    this._entityRegexes = [];
  }

  /**
   * prefixUrl
   * @returns {string} url prefixed with cms
   */
  replaceUrl(url) {
    if (this._isMatch(url)) {
      return `/api/cms-${url.substring(5)}`;
    }

    return url;
  }

  /**
   * matches regexes
   * @param   {string} url
   * @returns {Boolean}
   */
  _isMatch(url) {
    return this._entityRegexes.some((regex) => url.match(regex));
  }
}

export default ApiUrlAdapterService;
