import template from "./error-404.html";

class Error404 {
  /**
   * @constructor
   * @param {Object} $rootScope
   * @param {Object} $transitions
   */
  constructor($rootScope, $transitions) {
    this.$rootScope = $rootScope;
    this.$transitions = $transitions;

    this.$rootScope.isFooterHidden = true;

    this.setupSubscriptions();
  }

  /**
   * Registers listeners
   * @returns {void}
   */
  setupSubscriptions() {
    this.footerListener = this.$transitions.onStart({}, () =>
      this.showFooter()
    );
  }

  /**
   * Changes the rootScope property isFooterHidden on $transitions.onStart
   * @returns {void}
   */
  showFooter() {
    this.$rootScope.isFooterHidden = false;

    // Deregister this event listener
    this.footerListener();
  }
}

const error404Component = {
  controller: Error404,
  controllerAs: "controller",
  template,
};

export default error404Component;
