import ReduceReducers from "reduce-reducers";
import { createAttachDataReducer } from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import AccountConstant from "./accounts.constant";

const initialState = {};

const fetch = createAttachDataReducer(
  initialState,
  AccountConstant.FETCH_ACCOUNTS
);

const fetchSuccess = (state = initialState, action) => {
  switch (action.type) {
    case ApiConstant.getSuccessType(AccountConstant.FETCH_ACCOUNTS):
      return {
        ...state,
        data: state.data.objects,
      };
    default:
      return state;
  }
};

const AccountReducer = ReduceReducers(initialState, fetch, fetchSuccess);

export default AccountReducer;
