import moment from "moment-timezone";
import "moment/locale/en-gb";

function momentWrapper() {
  moment.locale("en-gb");

  return moment;
}

export default momentWrapper;
