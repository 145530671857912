import angular from "angular";

const UsersRoute = angular.module("UsersRoute", []).config(router);

/**
 * Clears all messaging channels
 * @param {object} MessageService - the messaging service
 * @returns {void}
 */
function clearChannels(MessageService) {
  MessageService.clearChannels();
}

/**
 * Check to redirect user if they do not have the correct view permissions for this route
 * @param {Object} $location
 * @param {Object} PermissionsService
 * @returns {void}
 */
function checkRoutePermission(
  $location,
  PermissionsService,
  NotificationService
) {
  // @TODO: update with view permission when available
  PermissionsService.checkAccess("change_userlistmember").then((userAccess) => {
    if (!userAccess.hasAccess) {
      $location.path("/sets");
      NotificationService.notify(
        "error",
        "Sorry, you do not have permission to view that page"
      );
    }
  });
}

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("Users", {
    url: "/cms-users",
    resolve: {
      clearChannels,
      checkRoutePermissions: checkRoutePermission,
    },
    title: "cms-users",
    pageTitle: "Users:listing",
    reloadOnSearch: false,
    views: angular.extend({
      main: {
        component: "entityListingPage",
      },
    }),
  });
}

export default UsersRoute;
