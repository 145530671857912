import React from "react";
import PropTypes from "prop-types";

import Schedules from "components/_react/schedules/schedules";
import SchedulesSingle from "components/_react/schedules/schedules-single/schedules-single";
import SchedulesMultiple from "components/_react/schedules/schedules-multiple/schedules-multiple";

import { UNSCHEDULED_STATUS } from "components/_react/schedules/schedules.constants";

import styles from "./schedule-column.scss";

/**
 * Renders the correct scheduling information for a given entity.
 * @export
 * @class ScheduleColumn
 */
export default function ScheduleColumn(props) {
  return (
    <div className={styles["schedule-column"]}>
      {(() => {
        switch (props.schedules.length) {
          case 0:
            return (
              <Schedules
                text={UNSCHEDULED_STATUS.copy}
                styling={UNSCHEDULED_STATUS.styling}
              />
            );
          case 1:
            return <SchedulesSingle schedule={props.schedules[0]} />;
          default:
            return <SchedulesMultiple schedules={props.schedules} />;
        }
      })()}
    </div>
  );
}

ScheduleColumn.propTypes = {
  schedules: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.oneOf(["expired", "current", "future"]),
      starts: PropTypes.string,
      ends: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
    })
  ).isRequired,
};
