import * as EntityAction from "store/entity/entity.action";
import * as EntitySelector from "store/entity/entity.selector";
import * as EntityConfigAction from "store/entities-config/entities-config.action";
import * as EntitiesConfigSelector from "store/entities-config/entities-config.selector";
import { getDeleteMessage } from "store/entities-config/entities-config.selector";
import { enhanceConfigWithDataSource } from "store/selectors/enhance-config-with-data-source";
import template from "./entity-information.html";

class EntityInfoPageCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $scope
   * @param {Object} ReduxConnector
   * @param {Object} BreadcrumbService
   * @param {Object} HistoryService
   * @param {Object} EntityFactory
   */
  constructor(
    _,
    $scope,
    ReduxConnector,
    BreadcrumbService,
    HistoryService,
    EntityFactory
  ) {
    this._ = _;
    this.$scope = $scope;
    this.ReduxConnector = ReduxConnector;
    this.BreadcrumbService = BreadcrumbService;
    this.HistoryService = HistoryService;
    this.EntityFactory = EntityFactory;

    this.connectToStore();
    this.init();
  }

  init() {
    this.entity = this.EntityFactory.getEntityName();
    this.entityType = this.EntityFactory.getEntityType();
    this.entityId = this.EntityFactory.getEntityId();

    this.loadData();
    this.channel = `${this.entity}.${this.entityId}`;
    this.setWatchUpdates();
  }

  /**
   * loading the necessary data for this view
   */
  loadData() {
    this.EntityFactory.fetchType()
      .then((type) => {
        this.entityType = type;
      })
      .finally(() => {
        this.store.fetchInformationConfig(this.entity, this.entityType);
      });
  }

  /**
   * watching the scope
   */
  setWatchUpdates() {
    const unwatch = this.$scope.$watch(
      () => this.data.title,
      (newData) => {
        if (newData) {
          if (this.entity !== "sets") {
            this.BreadcrumbService.lockHistoryBreadcrumb();
          }
          unwatch();
        }
      },
      true
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityConfigAction,
      ...EntityAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    const { loading, data, creating } = EntitySelector.getEntity(state);
    const informationConfig = enhanceConfigWithDataSource(
      state,
      this.entity,
      this.entityType
    );
    return {
      loading,
      data,
      baseConfig: EntitiesConfigSelector.getBaseConfig(
        state,
        this.entity,
        this.entityType
      ).data,
      informationConfig,
      isCreating: creating,
      deleteMessage: getDeleteMessage(state, this.entity, this.entityType),
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

const entityInfoPageComponent = {
  controller: EntityInfoPageCtrl,
  controllerAs: "info",
  template,
};

export default entityInfoPageComponent;
