/**
 * editor reducer
 * @param   {Object} state - editor
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = {}, action) {
  switch (action.type) {
    case "SET_EDITOR_STATE":
      return {
        ...state,
        initialData: !state.initialData ? action.data : state.initialData,
        editorState: action.data,
        hasChanged: state.hasChanged || action.hasChanged,
      };

    case "RESET_EDITOR_STATE":
      return { ...state, hasChanged: false, initialData: state.editorState };

    case "DISCARD_EDITOR_CHANGES":
      return { ...state, editorState: state.initialData, hasChanged: false };

    default:
      return state;
  }
}
