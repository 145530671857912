function NetworkService($rootScope, $jQuery, NotificationService) {
  let initialised = false;

  const service = {};

  /**
   *
   * Initialise service
   *
   */
  service.init = () => {
    if (initialised) {
      return true;
    }
    $jQuery(window).on("offline", service.offline);
    $jQuery(window).on("online", service.online);
    initialised = true;
  };

  /**
   *
   * Offline evt callback
   * @returns {void}
   *
   */
  service.offline = () => {
    NotificationService.notifyStickyError("No internet connection");
    $rootScope.$apply();
  };

  /**
   *
   * Online evt callback
   * @returns {void}
   */
  service.online = () => {
    NotificationService.clearStickyErrors();
    $rootScope.$apply();
  };

  return service;
}

export default NetworkService;
