import template from "./entity-list.html";

/**
 * @entityListing Directive
 * Directive to manage list output in listings pages.
 *
 */
class EntityListCtrl {
  /**
   * Checks if 'image' column exists, adds class for tall table row if it exists
   * @returns {boolean}
   */
  isTall() {
    return !!this.config.columns.find((col) => col.name === "image");
  }

  /**
   * sets the list in interactive mode, when one of the columns requires an interactive behaviour
   * @return {boolean}
   */
  isInteractive() {
    return Boolean(this.config.columns.find((col) => col.name === "position"));
  }

  /**
   * Helper function to hide items when Skylark is using AWS Cognito (regardless of cms-config settings)
   * @param {string} columnName
   * @param {string} self
   * @returns {boolean} true if should be hidden
   */
  hideForCognito(columnName, self) {
    const shouldHide =
      columnName === "role_urls" && self && self.startsWith("/api/users/");
    return shouldHide;
  }
}

/**
 * Entity List directive
 * @returns {Object}
 */
function EntityListDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      data: "<",
      config: "=",
      context: "=",
      isEditable: "=",
      isReadOnly: "=",
      onPositionChange: "&?",
    },
    template,
    controller: EntityListCtrl,
    controllerAs: "component",
  };
}

export default EntityListDirective;
