/**
 * @file A service to handle the transformation of data from a changelog
 * into a human readable formar.
 */

/**
 * @memberof Skylark.Components
 * @classdesc The changelog service only has one public method and
 * which will always return a new collection with human readable
 * properties ready to be be used in the DOM.
 * This list and the changes that are allowed in the log are configurable per deployment.
 * `ChangelogService` has one single entry point at `getLog`.
 * It takes the baseConfig of the core entity being logged and a core changelog config defining valid modular and non modular fields.
 * get the changelog raw data from `ChangelogFactory`
 * get core entity data from relationship urls contained in the changelog raw data,
 * excluding fields which are not in config, using a `Batch` call;
 * prepare a list of entity names and of metadata config fields;
 * start building a readable list item per valid diff;
 * for each diff, assign author, actions, change_type, formatted date and an `actionCopy` object
 * when building an action, find out the action type.
 * If there are no valid configs (i.e no relationship fields) the diff should be treated as a core entity change.
 * We find which field has been changed, which is either a metadata type or a configured non-module field (i.e. permissions).
 * If there are configs, iterate over the configs to build objects for each of these. pluralise as needed.
 * This takes in account that two relationship fields could, in the future, be sent as a single request.
 * If actionCopy is an array of actions, use the first one or the single object.
 * Return the full list.
 */
class ChangelogService {
  /**
   * @constructor
   */
  constructor(
    $q,
    ChangelogFactory,
    BatchRequestFactory,
    ApiRequestConfigFactory,
    EntityTypeHelpersService,
    CHANGELOG_CONSTANTS,
    LanguagesService,
    momentJS,
    _
  ) {
    this.$q = $q;
    this.ChangelogFactory = ChangelogFactory;
    this.BatchRequestFactory = BatchRequestFactory;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
    this.ChangelogConstants = CHANGELOG_CONSTANTS;
    this.LanguagesService = LanguagesService;
    this.momentJS = momentJS;
    this._ = _;
  }

  /**
   * getLog - get the changelog and build a list for rendering the table
   * @param   {string} entitySelf - the self url for an entity
   * @param {object} config - datalog config
   * @param {object} entityConfig - entity information config
   * @param {string} entityName - a display name
   * @returns {Promise}
   */
  getLog(entitySelf, config, entityConfig, entityName) {
    const deferred = this.$q.defer();
    this._entitySelf = entitySelf;
    this._entityConfig = entityConfig;
    this._entityName = entityName;
    this._config = config;

    this.ChangelogFactory.getChangelog(entitySelf)
      .then((response) => {
        this._getEntityData(response.objects, config).then((data) => {
          this.LanguagesService.getLanguages().then((languages) => {
            this._entities = this._prepareEntityData(data, languages);
            this._metadataFields = this._prepareConfig(entityConfig);

            deferred.resolve(this._buildList(response.objects, this._config));
          });
        });
      })
      .catch((err) => deferred.reject(err));

    return deferred.promise;
  }

  /**
   * prepare entity data so we can find entity names
   * @param   {array} data
   * @param   {array} languages
   * @returns {array} array of data containing information about entities
   * that can be modified in relation to the changelog target
   */
  _prepareEntityData(data, languages) {
    return data
      .filter((res) => res.code === 200)
      .map((res) => this._parseJSON(res.body))
      .concat(languages);
  }

  /**
   * takes fields from metadata or additional metadata config
   * @param   {object} entityConfig
   * @returns {array} array of metadata field names
   */
  _prepareConfig(entityConfig) {
    return entityConfig.modules
      .filter(
        (module) =>
          module.name === "metadata" || module.name === "additional-metadata"
      )
      .map((module) => module.fields.map((field) => field.name))
      .reduce((acc, curr) => acc.concat(curr), []);
  }

  /**
   * build the actual list
   * @todo  get rid of change type direct filter here and of actionCopy.actionTarget
   * @returns {array}
   *
   */
  _buildList(response, config) {
    return response
      .filter((diff) => this._isChangeValid(diff))
      .map((diff) => this._buildReadableDiff(diff, config))
      .filter((diff) => diff.actionCopy.actionTarget);
  }

  /**
   * is this change valid?
   * @param   {object} diff - a diff object from the API
   * @returns {boolean} whether any valid change has been made
   */
  _isChangeValid(diff) {
    return (
      diff.changes ||
      diff.action === "POST" ||
      this._isScheduledItem(diff) ||
      this._isMetadata(diff.changes, this._metadataFields)
    );
  }

  /**
   * filter allowed fields based on config
   * @returns {Object} a new changes object with only the allowed fields in
   */
  _filterFields(changeList, config) {
    const allowedFieldsList = {};

    for (const field in changeList) {
      if (this._isValidField(field, config, changeList)) {
        allowedFieldsList[field] = changeList[field];
      }
    }

    return allowedFieldsList;
  }

  /**
   * build url list
   * @param   {Array} data
   * @param   {Object} config
   * @returns {array}
   */
  _buildUrlList(data, config) {
    const requests = data
      .map((diff) => {
        const filteredFields = this._filterFields(
          this._parseJSON(diff.changes),
          config
        );
        const fields = config.fields.direct;
        if (this._isScheduledItem(diff)) {
          return [
            this._parseJSON(diff.response_body).content_url,
            ...this._getUrlsFromDiff(filteredFields, fields),
          ];
        }

        if (this._isTVContent(diff, this._parseJSON(diff.changes))) {
          return [diff.changed_object_url];
        }

        return this._getUrlsFromDiff(filteredFields, fields);
      })
      .reduce((requests, currentList) => requests.concat(currentList), []);

    return this._buildEntityRequests(requests, config);
  }

  /**
   * get urls from diff
   * @param   {Object} filteredFields
   * @param   {Object} config
   * @returns {Array}
   */
  _getUrlsFromDiff(filteredFields, config) {
    return Object.keys(filteredFields)
      .map((prop) => {
        if (config[prop] && this._isCompositeType(filteredFields[prop])) {
          return [
            ...this._flatten(filteredFields[prop].added, config[prop].urls),
            ...this._flatten(filteredFields[prop].removed, config[prop].urls),
          ];
        }
        if (config[prop] && this._isPrimitiveType(filteredFields[prop])) {
          return [filteredFields[prop].old, filteredFields[prop].new];
        }
      })
      .reduce((prev, curr) => prev.concat(curr), []);
  }

  /**
   * build readable diff
   * decide what type of diff we are dealing with here and branch off
   * @todo  add a merge here for several actionCopies in one as neeeded
   * @returns {object} - a diff object ready to be used in the DOM
   */
  _buildReadableDiff(diff, config) {
    this._parsedCurrentChanges = this._parseJSON(diff.changes);

    if (diff.change_type === "indirect") {
      return this._buildIndirectDiff(diff, config);
    }

    return this._buildDirectDiff(diff, config);
  }

  /**
   * build diff for direct changes
   * @param   {Object} diff
   * @param   {Object} config
   * @returns {Object}
   */
  _buildDirectDiff(diff, config) {
    const filteredFields = this._filterFields(
      this._parsedCurrentChanges,
      config
    );
    const configs = this._findConfigs(filteredFields, config);
    const actionCopy = this._buildAction(filteredFields, diff, configs, config);

    return {
      user: diff.created_by,
      date: this._formatDate(diff.created),
      changeType: diff.change_type,
      actionCopy: actionCopy[0] || actionCopy,
      tooltipMessage: this._buildTooltipMessage(diff, actionCopy),
    };
  }

  /**
   * build diff for indirect changes
   * @param   {Object} diff
   * @param   {Object} config
   * @param   {String} entityName
   * @returns {Object}
   */
  _buildIndirectDiff(diff, config) {
    const configs = this._isScheduledItem(diff)
      ? [config.fields.indirect.scheduled_items]
      : [config.fields.indirect.parent_url];

    const actionCopy = this._buildAction(
      this._parsedCurrentChanges,
      diff,
      configs,
      config
    );

    return {
      user: diff.created_by,
      date: this._formatDate(diff.created),
      changeType: diff.change_type,
      actionCopy: actionCopy || "",
      tooltipMessage: this._buildTooltipMessage(diff, actionCopy),
    };
  }

  /**
   * findNameFromConfig
   * @returns {Array} config names
   */
  _findConfigs(filteredChanges, config) {
    const configs = [];
    for (const field in filteredChanges) {
      configs.push(config.fields.direct[field]);
    }

    return configs.filter((config) => config);
  }

  /**
   * get entity data
   * @param   {Array} data
   * @param   {Object} config
   * @returns {Promise}
   */
  _getEntityData(data, config) {
    this._buildUrlList(data, config);

    return this.BatchRequestFactory.process();
  }

  /**
   * build action
   * @param   {object} filteredChanges
   * @param   {object} diff
   * @param   {Array} configs
   * @param   {object} config - main config
   * @returns {Object} the full action copy
   */
  _buildAction(filteredChanges, diff, configs, config) {
    if (!configs.length) {
      return this._buildSelfAction(filteredChanges, diff, config);
    }
    if (
      this._isScheduledItem(diff) ||
      this._isTVContent(diff, this._parsedCurrentChanges)
    ) {
      return this._buildItemAction(diff, filteredChanges, config);
    }
    return configs.map((config) =>
      this._buildRelationshipAction(config, filteredChanges[config.field])
    );
  }

  /**
   * build Self Action
   * @param   {Object} filteredChanges
   * @param   {Object} diff
   * @param   {Object} config
   * @returns {Object}
   */
  _buildSelfAction(filteredChanges, diff, config) {
    const isCreation = this._isCreation(diff);
    const actionName = isCreation
      ? this.ChangelogConstants.actionTypeCopy.created
      : this.ChangelogConstants.actionTypeCopy.edited;

    const actionTarget = isCreation
      ? this._entityName
      : this._findMetadataDisplayName(this._metadataFields, filteredChanges) ||
        this._findFieldName(this._parsedCurrentChanges, config);

    const requestBody = this._parseJSON(diff.request_body);
    const actionEntityName = requestBody.title || requestBody.name;

    return this._buildActionCopy(actionName, actionTarget, actionEntityName);
  }

  /**
   * build relationship action for configured fields
   * @param   {Object} config
   * @param   {Object} changes
   * @param   {Array} entities
   * @returns {Object}
   */
  _buildRelationshipAction(config, changes) {
    const name = this._buildActionName(changes);
    const actionTarget = this._pluralise(config, changes);
    const actionEntityName = this._findEntityNames(changes, config);

    return this._buildActionCopy(name, actionTarget, actionEntityName, changes);
  }

  /**
   * build item action
   * @param   {Object} diff
   * @returns {Object}
   */
  _buildItemAction(diff, filteredChanges) {
    const fields = this._config.fields.indirect;
    let name;
    let entityName;
    let title;

    if (this._isScheduledItem(diff)) {
      name = this._buildScheduledItemActionName(diff.action);

      const entityType = this._getScheduledItemEntityName(diff);
      const entityField =
        this._config.scheduled_item_entities[entityType].entity_title;

      title = this._findEntityName(
        this._parseJSON(diff.request_body).content_url,
        entityField,
        "self"
      );
      title =
        title ||
        this._entityConfig.scheduled_item_entities[entityType].display_name;
      entityName = fields.scheduled_items.singular_name;
    } else {
      name = this._buildTVStructureActionName(diff);
      title = this._findEntityName(diff.changed_object_url, "title", "self");
      entityName = fields.parent_url.singular_name;
    }

    return this._buildActionCopy(name, entityName, title, filteredChanges);
  }

  /**
   * build final action copy object
   * @param   {String} actionName
   * @param   {String} actionTarget
   * @param   {String} actionEntityName
   * @param   {Object|String} actionField
   * @returns {Object}
   */
  _buildActionCopy(
    actionName,
    actionTarget,
    actionEntityName,
    actionField = ""
  ) {
    return {
      actionField,
      actionName,
      actionTarget,
      actionEntityName,
    };
  }

  /**
   * _buildActionName
   * @param {Object} field
   * @returns {String}
   */
  _buildActionName(field) {
    if (this._.isEmpty(field)) {
      return this.ChangelogConstants.actionTypeCopy.created;
    }
    if (
      (field.added && this._isEdition(field.added, field.removed)) ||
      (field.new && field.old)
    ) {
      return this.ChangelogConstants.actionTypeCopy.edited;
    }
    if (this._isModified(field.added) || field.new) {
      return this.ChangelogConstants.actionTypeCopy.added;
    }
    if (this._isModified(field.removed) || field.old) {
      return this.ChangelogConstants.actionTypeCopy.removed;
    }
  }

  /**
   * build scheduled item action name
   * @param   {string} action
   * @returns {string}
   */
  _buildScheduledItemActionName(action) {
    switch (action) {
      case "POST":
        return this.ChangelogConstants.actionTypeCopy.added;
      case "PUT":
        return this._buildScheduledItemCopy(this._parsedCurrentChanges);
      case "DELETE":
        return this.ChangelogConstants.actionTypeCopy.deleted;
    }
  }

  /**
   * _buildTVStructureActionName
   * @returns {String} the copy
   */
  _buildTVStructureActionName() {
    const changes = this._parsedCurrentChanges.parent_url;
    if (!changes.new || changes.new !== this._entitySelf) {
      return this.ChangelogConstants.actionTypeCopy.removed;
    }

    return this.ChangelogConstants.actionTypeCopy.added;
  }

  /**
   * build scheduled item copy strings
   * @param   {string} changes in JSON format
   * @returns {String} strings representing the copy change
   */
  _buildScheduledItemCopy(parsedChanges) {
    const copyArr = [];
    if (parsedChanges.position) {
      copyArr.push(this._getPlacementCopy(parsedChanges));
    }

    if (
      (parsedChanges.position && Object.keys.length > 1) ||
      !parsedChanges.position
    ) {
      copyArr.push(this.ChangelogConstants.actionTypeCopy.edited);
    }

    return copyArr.join(" and ");
  }

  /**
   * _getScheduledItemEntityName
   * @param   {object} changes
   * @returns {string}
   */
  _getScheduledItemEntityName(diff) {
    const contentUrl = this._parseJSON(diff.request_body).content_url;

    return this.EntityTypeHelpersService.getRawType(contentUrl);
  }

  /**
   * _isMetadata
   * @param   {Object} changes
   * @param   {Array} metadataFields
   * @returns {Boolean}
   */
  _isMetadata(changes, metadataFields = []) {
    // eslint-disable-next-line no-unreachable-loop
    for (const prop in changes) {
      return metadataFields.some((field) => field === prop);
    }
  }

  /**
   * _findMetadataDisplayName
   * @param   {Array} metadataFields
   * @param   {Object} entityConfig
   * @param   {Object} filteredChanges
   * @returns {string|null}
   */
  _findMetadataDisplayName(metadataFields, filteredChanges) {
    const keys = Object.keys(filteredChanges).filter((key) =>
      metadataFields.some((field) => key === field)
    );

    if (keys.length) {
      return this._entityConfig.modules.find((module) =>
        module.fields.some((field) => field.name === keys[0])
      ).display_name;
    }
  }

  /**
   * whether this field's value is a composite data type;
   * @param   {Object} field
   * @returns {Boolean}
   */
  _isCompositeType(field) {
    return field.added || field.removed;
  }

  /**
   * whether this field's value is a primitive data type
   * @param   {Object} field
   * @returns {Boolean} [description]
   */
  _isPrimitiveType(field) {
    return field.old || field.new;
  }

  /**
   * flatten a collection if its an array of objects
   * @param   {Array} collection - array of items
   * @param   {string} urlProp
   * @returns {Array} a flat array made of urls representing entities
   */
  _flatten(collection, selfField) {
    return collection
      .map((item) => {
        if (this._.isString(item)) {
          return item;
        }

        return item[selfField];
      })
      .reduce((acc, curr) => acc.concat(curr), []);
  }

  /**
   * _findFieldName
   * @param   {object} diff
   * @param   {object} config
   * @returns {string}
   */
  _findFieldName(diff, config) {
    for (const prop in diff) {
      if (config.non_module_fields[prop]) {
        return config.non_module_fields[prop];
      }
    }
  }

  /**
   * _isCreation
   * @param   {object} diff
   * @returns {Boolean}
   */
  _isCreation(diff) {
    return diff.action === "POST";
  }

  /**
   * _isEdition
   * @param   {array} field1
   * @param   {array} field2
   * @returns {Boolean}
   */
  _isEdition(field1, field2) {
    return field1.length && field2.length;
  }

  /**
   * is Modified
   * @param   {string} field
   * @returns {Boolean} the field length, which can be coerced
   */
  _isModified(field) {
    return field && field.length;
  }

  /**
   * _pluralise
   * @param   {object} config
   * @param   {object} diff
   */
  _pluralise(config, diff) {
    return this._isMultipleEntity(diff.added, diff.removed)
      ? config.display_name
      : config.singular_name;
  }

  /**
   * _isValidField
   * @param   {string} field
   * @param   {object} config
   * @param   {Array} changeList
   * @param   {Array} metadataFields
   * @returns {Boolean}
   */
  _isValidField(field, config, changeList) {
    return (
      Object.prototype.hasOwnProperty.call(changeList, field) &&
      (config.fields.direct[field] ||
        this._isMetadata(changeList, this._metadataFields))
    );
  }

  /**
   * build entity batch requests
   * @returns {Promise}
   */
  _buildEntityRequests(urls) {
    const validUrls = this._.uniq(urls.filter((url) => this._isAPIUrl(url)));
    const requestConfig = this.ApiRequestConfigFactory.createRequestConfig({
      overrideGlobalLanguage: true,
      useWildCard: true,
    });

    return this.BatchRequestFactory.createGetRequests(
      "changelogitems",
      validUrls,
      "?fields=self,title,uid,name,first_name,last_name,set_type_slug",
      requestConfig
    );
  }

  /**
   * _findEntityNames
   * @param   {object} diff
   * @param   {object} config
   * @returns {string} name or names of entity
   */
  _findEntityNames(diff, config) {
    const names = this._extractUrls(diff, config).map((url) =>
      this._findEntityName(url, config.entity_title, config.identifier)
    );

    if (
      this._isCompositeType(diff) &&
      this._isEdition(diff.added, diff.removed)
    ) {
      return this._.uniq(names).join(", ");
    }

    return names.join(", ");
  }

  /**
   * extract urls from fields
   * @param   {Object} diff
   * @param   {Object} config
   * @returns {Array} array of urls
   */
  _extractUrls(diff, config) {
    return this._isCompositeType(diff)
      ? this._flatten(diff.added.concat(diff.removed), config.urls)
      : [diff.old, diff.new].filter((url) => url);
  }

  /**
   * _findEntityNames
   * @param   {string} url
   * @param {string} comparisonField
   * @returns {string}
   */
  _findEntityName(url, nameAs, comparisonField) {
    const entity =
      this._entities.find((entity) => entity[comparisonField] === url) || {};
    entity[nameAs] = entity.self
      ? entity[nameAs]
      : this.ChangelogConstants.deletedEntity;

    if (this._.isArray(nameAs)) {
      return nameAs.map((name) => entity[name]).join(" ");
    }

    return entity[nameAs];
  }

  /**
   * parse payloads into JS objects for comparison
   * @returns {Object}
   */
  _parseJSON(payload) {
    if (!payload) {
      return payload;
    }

    return JSON.parse(payload);
  }

  /**
   * format date
   * @param   {string} date - date in iso string
   * @returns {string} formatted date from a momentJS string
   */
  _formatDate(date) {
    return this._isToday(date)
      ? `Today - ${this.momentJS(date).format("HH:mm:ss")}`
      : this.momentJS(date).format("L HH:mm:ss");
  }

  /**
   * isToday
   * @param   {string} date - date
   * @returns {Boolean} if this date is for today
   */
  _isToday(date) {
    return this.momentJS(date).isSame(this.momentJS(), "day");
  }

  /**
   * returns whether this is an API URL
   * @param   {string} url
   * @returns {Boolean} whether this is an API URL
   */
  _isAPIUrl(url) {
    return new RegExp(/\/api\//).test(url);
  }

  /**
   * is a scheduled item
   * @param   {Object} diff
   * @returns {Boolean} whether the indirect change was made to a scheduled item
   */
  _isScheduledItem(diff) {
    return (
      diff.change_type === "indirect" &&
      new RegExp(/sets.*items/).test(diff.changed_object_url)
    );
  }

  /**
   * is tv content
   * @param   {object} diff
   * @returns {Boolean} whether an indirect change was made to a tv content item
   */
  _isTVContent(diff, parsedChanges) {
    return diff.change_type === "indirect" && !!parsedChanges.parent_url;
  }

  /**
   * get placement copy
   * @param   {Object} changes
   * @returns {String}
   */
  _getPlacementCopy(changes) {
    if (this._isPlaced(changes)) {
      return this.ChangelogConstants.actionTypeCopy.placed;
    }

    if (this._isUnplaced(changes)) {
      return this.ChangelogConstants.actionTypeCopy.unplaced;
    }

    return this.ChangelogConstants.actionTypeCopy.positioned;
  }

  /**
   * _isPlaced
   * @param   {Object} changes
   * @returns {Boolean} - whether this item has been placed
   */
  _isPlaced(changes) {
    return !changes.position.old && changes.position.new > 0;
  }

  /**
   * _isUnplaced
   * @param   {Object} changes
   * @returns {Boolean} - whether this item has been unplaced
   */
  _isUnplaced(changes) {
    return !changes.position.new && changes.position.old > 0;
  }

  /**
   * build tooltip message
   * @param   {Object} diff
   * @param   {Object} actionCopy
   * @returns {Object}
   */
  _buildTooltipMessage(diff, actionCopy) {
    const action = this._.isArray(actionCopy) ? actionCopy[0] : actionCopy;

    return {
      message: this._buildDiffTooltip().concat(
        this._buildMultipleEntityTooltip(diff, action),
        this._buildScheduledItemTooltip(diff)
      ),
    };
  }

  /**
   * build message
   * @param   {Array} fields - the list of fields available for this diff
   * @returns {String} the list of messages for the tooltip
   */
  _buildMessage(fields) {
    return Object.keys(this._parsedCurrentChanges)
      .map((key) => {
        if (fields.find((field) => field.name === key)) {
          const name = fields.find((field) => field.name === key);

          return this._buildMessageString(
            name,
            key,
            this._parsedCurrentChanges
          );
        }
      })
      .filter((message) => !!message)
      .join(", ");
  }

  /**
   * _buildMessageString
   * @param   {Object} name
   * @param   {String} key
   * @param   {Object} changes
   * @returns {String} the string to send to the DOM
   */
  _buildMessageString(name, key, changes) {
    if (this._isPrimitiveType(changes[key])) {
      return (
        `${name.display_name}: ${changes[key].old || "empty"}` +
        ` to ${changes[key].new || "empty"}`
      );
    }
    if (this._isCompositeType(changes[key])) {
      const entityNames = this._findEntityNames(
        changes[key],
        this._config.fields.direct[key]
      );
      const actionName = this._buildActionName(changes[key]);

      return `${actionName} ${this._pluralise(
        this._config.fields.direct[key],
        changes
      )}: ${entityNames}`;
    }
  }

  _findSetType(url) {
    return this._entities.find((entity) => entity.self === url).set_type_slug;
  }

  /**
   * _buildDiffTooltip
   * @param   {Object} diff
   * @returns {String}
   */
  _buildDiffTooltip() {
    const fields = [
      ...this._findMetadataModule("additional-metadata").fields,
      ...this._findMetadataModule("metadata").fields,
    ];

    return this._buildMessage(fields);
  }

  /**
   * _buildScheduledItemTooltip
   * @param   {Object} diff
   * @returns {String}
   */
  _buildScheduledItemTooltip(diff) {
    if (this._isScheduledItem(diff)) {
      const entityType = this._getScheduledItemEntityName(diff);
      const entityTypeName =
        entityType === "sets"
          ? this._findSetType(this._parseJSON(diff.request_body).content_url)
          : entityType;
      const { fields } =
        this._entityConfig.scheduled_item_entities[entityTypeName];

      return this._buildMessage(fields);
    }

    return "";
  }

  /**
   * _buildMultipleEntityTooltip
   * @param   {Object} diff
   * @param   {Object} actionCopy
   * @returns {String}
   */
  _buildMultipleEntityTooltip(diff, actionCopy) {
    if (
      this._isMultipleEntity(
        actionCopy.actionField.added,
        actionCopy.actionField.removed
      )
    ) {
      const entities = actionCopy.actionEntityName;
      actionCopy.actionEntityName = `${
        actionCopy.actionField.added.length +
        actionCopy.actionField.removed.length
      } items`;

      return entities;
    }

    return "";
  }

  /**
   * has multiple entities
   * @param   {Array} field1
   * @param   {Array} field2
   * @returns {Boolean}
   */
  _isMultipleEntity(field1, field2) {
    return (field1 && field1.length > 1) || (field2 && field2.length > 1);
  }

  /**
   * _findMetadataModule
   * @param   {String} moduleName
   * @returns {Object} the config module
   */
  _findMetadataModule(moduleName) {
    return this._entityConfig.modules.find(
      (module) => module.name === moduleName
    );
  }
}

export default ChangelogService;
