import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";

/**
 * ApiResponseMiddleware is not making any requests,
 * for now all API request are made through different service,
 * but to map the response in a same manner to the store
 * a good approach is that middleware.
 *
 * @returns {function(*): function(*=): function(...[*]=)}
 * @constructor
 */
const ApiMiddleware =
  () =>
  (next) =>
  /**
   * @param {Object} action - contain promise of api response and types for request, success and failure.
   * {
   *   api: {
   *     promise: EntityFactory.get(entity, uid, filters),
   *     failureType: 'FETCH_ENTITY_FAILURE',
   *     successType: 'FETCH_ENTITY_SUCCESS',
   *   }
   *   type: 'FETCH_ENTITY_REQUEST'
   * }
   * @returns {*}
   */
  (action) => {
    const { api } = action;

    if (!api) {
      return next(action);
    }

    const { promise, successType, failureType } = api;

    delete action.api;

    next(action);

    if (promise && typeof promise.then === "function") {
      return promise
        .then((response) => {
          /*
           * fallback for components which are not fully supported with redux,
           * for example updating related entities which have child components which are need the related entities data
           * when adding a video to and episode in the contents tab, this data comes from the related entity not the episode itself,
           * to let that component know that the asset got updated we send a message
           */
          if (response && response.uid) {
            const MessageService = Injector.get("MessageService");

            MessageService.publish(`${response.uid}.dataChannel`, response);
          }
          return {
            ...action,
            type: successType,
            response,
          };
        })
        .catch((error) =>
          // provides for the standard http error response and the error response from ApiService
          ({
            ...action,
            type: failureType,
            error: error.data || error.statusText || error,
            errorStatus: error.status,
          })
        )
        .then((action) => next(action));
    }

    return next(action);
  };
export default ApiMiddleware;
