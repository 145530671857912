import React, { useMemo } from "react";
import { react2angular } from "react2angular";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import withStoreProvider from "hocs/with-store-provider";
import { RootState } from "store/store.types";
import { getActiveFiltersByEntity } from "store/listing/entity-listing/entity-listing.selector";
import { setActiveFilters } from "store/listing/entity-listing/entity-listing.actions";
import {
  DynamicProperties,
  GenericActionParams,
} from "store/listing/entity-listing/entity-listing.types";
import SearchFilter from "./search-filter/search-filter.component";
import StatusFilter from "./status-filter/status-filter.component";
import OrderingFilter from "./ordering-filter/ordering-filter.component";

import CategoryFilter from "./category-filter/category-filter.component";

export interface SearchAndFilterProps extends GenericActionParams {
  config: {
    countEndpoint: boolean;
    display_name: string;
    filters: {
      search?: {
        param: string;
      };
      ordering?: Array<{ slug: string; name: string }>;
      status?: boolean;
      category?: {
        param: string;
      };
    };
  };
}

const defaultProps = {
  config: {
    countEndpoint: false,
    display_name: "",
    filters: {},
  },
};

const SearchAndFilter = ({
  config,
  entityName,
  entityType,
  namespace,
}: SearchAndFilterProps) => {
  const dispatch = useDispatch();
  const activeFilter = useSelector((state: RootState) =>
    getActiveFiltersByEntity(state, entityName, entityType, namespace)
  );

  const configFilters = useMemo(() => config && config.filters, [config]);

  const updateFilter = (dynamicProperties: DynamicProperties): void => {
    dispatch(
      setActiveFilters({
        entityName,
        entityType,
        dynamicProperties,
        namespace,
      })
    );
  };

  if (!configFilters || _.isEmpty(configFilters)) {
    return null;
  }

  return (
    <div className="search-and-filter">
      <div className="search-and-filter__container">
        <SearchFilter
          filter={activeFilter?.search}
          placeholder={`Search all ${config.display_name || ""}`}
          updateFilter={updateFilter}
        />
        {configFilters.status && (
          <StatusFilter
            filter={activeFilter?.schedule?.status}
            updateFilter={updateFilter}
          />
        )}
        {configFilters.ordering && (
          <OrderingFilter
            filter={activeFilter?.pagination?.order}
            ordering={configFilters.ordering}
            updateFilter={updateFilter}
          />
        )}
        {configFilters.category?.param && (
          <CategoryFilter
            category={configFilters.category}
            filter={activeFilter?.property?.category}
            updateFilter={updateFilter}
          />
        )}
      </div>
    </div>
  );
};

SearchAndFilter.defaultProps = defaultProps;

// @ts-ignore
const reactSearchAndFilter = angular
  .module("Skylark.Components.React.SearchAndFilter", [])
  .component(
    "searchAndFilter",
    react2angular(withStoreProvider(SearchAndFilter), [
      "config",
      "entityName",
      "entityType",
      "namespace",
    ])
  );

export default SearchAndFilter;

export { reactSearchAndFilter };
