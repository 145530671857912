/**
 * Datepicker
 *
 * @author Lewis Nixon <lewis.nixon@ostmodern.co.uk>
 * @copyright 2015 OstModern
 *
 * @description Render datepicker Widget
 */

/**
 * Datepicker directive
 * @param {Object} $timeout
 * @param {Object} Pikaday
 * @param {Object} momentJS
 * @param {Object} _
 * @param {Object} PIKADAY_CONFIG
 * @returns {Object}
 */
function datepickerDirective($timeout, Pikaday, momentJS, _, PIKADAY_CONFIG) {
  return {
    require: "ngModel",
    scope: {
      pikaday: "=",
      onSelect: "&",
      onOpen: "&",
      onClose: "&",
      onDraw: "&",
      disableDayFn: "&",
    },
    link: (scope, element, attrs, ctrl) => {
      // Init config Object
      const config = {
        field: element[0],
        format: "l",
        onSelect() {
          $timeout(() => {
            scope.$apply();
          });
        },
      };

      // Decorate/Overide config with inline attributes
      _.forEach(PIKADAY_CONFIG, (value, key) => {
        config[key] = value;
      });

      _.forEach(attrs.$attr, (dashAttr) => {
        // normalize = ToCamelCase()
        const attr = attrs.$normalize(dashAttr);
        applyConfig(attr, attrs[attr]);
      });

      // instantiate pikaday with config, bind to scope, add destroy event callback
      const picker = new Pikaday(config);
      scope.pikaday = picker;

      scope.$on("$destroy", () => {
        picker.destroy();
      });

      // format as ISO on the way out of the view
      ctrl.$parsers.unshift((viewValue) => {
        // if it the value is empty or null the it will parse to null
        if (!viewValue) {
          return null;
        }

        const date = momentJS(viewValue, config.format);

        // if it is not a valid date, the date will not be parsed, parse error is happening and form will not submit
        if (!date.isValid()) {
          return undefined;
        }

        return date.toISOString();
      });

      // format presentably on the way in to the view
      ctrl.$formatters.unshift((viewValue) => {
        if (!viewValue || viewValue === "true") {
          /*
           * $setViewValue required to trigger
           * $parsers run
           */
          ctrl.$setViewValue(null);

          return "Set a Date";
        }

        return momentJS(viewValue).format("l");
      });

      /**
       * applyConfig
       * @param {*} attr
       * @param {*} value
       * @returns {void}
       */
      function applyConfig(attr, value) {
        switch (attr) {
          case "setDefaultDate":
          case "bound":
          case "reposition":
          case "disableWeekends":
          case "showWeekNumber":
          case "isRTL":
          case "showMonthAfterYear":
          case "firstDay":
          case "yearRange":
          case "numberOfMonths":
          case "mainCalendar":
            config[attr] = scope.$eval(value);
            break;
          case "onSelect":
          case "onOpen":
          case "onClose":
          case "onDraw":
          case "disableDayFn":
            config[attr] = function (date) {
              $timeout(() => {
                scope.$apply();
              });

              return scope[attr]({
                pikaday: this,
                date,
                name: ctrl.$name,
              });
            };
            break;
          case "format":
          case "position":
          case "theme":
          case "yearSuffix":
            config[attr] = value;
            break;
          case "minDate":
          case "maxDate":
          case "defaultDate":
            config[attr] = new Date(value);
            break;
          case "trigger":
          case "container":
            config[attr] = document.getElementById(value);
            break;
        }
      }
    },
  };
}

export default datepickerDirective;
