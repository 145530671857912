import React from "react";
import PropTypes from "prop-types";

import styles from "./radio.scss";

/**
 * Radio
 * @param {Object} props
 * @returns {JSX}
 */
export default function Radio(props) {
  return (
    <div className={styles.radio}>
      <input
        name={`radio--${props.id}`}
        type="radio"
        className={styles.radio__input}
        checked={props.isChecked}
      />
      <label htmlFor={`radio--${props.id}`} className={styles.radio__label} />
    </div>
  );
}

Radio.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
};

Radio.defaultProps = {
  isChecked: false,
};
