import template from "./multisearch.html";

/**
 * @fileOverview MultiSearch
 * Renders a multisearch interface which allows
 * for relationship/hierarchical based search.
 */
class MultiSearchCtrl {
  /**
   * @constructor
   * @param {Object} $document
   * @param {Object} $timeout
   * @param {Object} SearchFactory
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} GlobalParamsService
   * @param {Object} _ lodash
   */
  constructor(
    $document,
    $timeout,
    SearchFactory,
    MessageService,
    NotificationService,
    GlobalParamsService,
    _
  ) {
    this.$document = $document;
    this.$timeout = $timeout;
    this.SearchFactory = SearchFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.GlobalParamsService = GlobalParamsService;
    this._ = _;

    this.queryObj = {};
    this.currentSearchParam = {};
    this.suggestions = [];

    this.isLocked = false;
    this.isToggled = false;

    this.debouncedToggleSuggestions = this._.debounce(
      this.toggleSuggestions,
      500
    );
    this.previousModelLength = 0;
  }

  /**
   * init
   * @param {element} element - directive element
   * @returns {void}
   */
  init(element) {
    this.element = element;
    this.operator = this.config.operator || "AND";
    this.channelNameIndex = this.instanceIndex >= 0 ? this.instanceIndex : "";

    this.setupSubscriptions();
    this.addExistingSearchParams();
    this.bindEvents();
  }

  /**
   * Binds ui events to the controller
   * @returns {void}
   */
  bindEvents() {
    this.element.on("click", () => {
      this.element.find("input").focus();

      // Avoid conflict where this property doesn't update the dom correctly
      this.$timeout(() => {
        this.isToggled = true;
      });
    });
  }

  /**
   * addExistingSearchParams
   * adds inherited search params
   * @returns {void}
   */
  addExistingSearchParams() {
    const searchParamsData = this.existingSearchParams || [];
    this.searchParams = searchParamsData.map((param) =>
      Object.assign(param, this.getEntityConfig(param.entity))
    );

    this.toggleDropdownLock();
  }

  /**
   * setupSubscriptions - setup MessageService channels
   * @returns {void}
   */
  setupSubscriptions() {
    this.MessageService.registerChannel("defaultEntityChange");
    this.MessageService.on(
      "defaultEntityChange",
      (channel, newDefaultEntity) => {
        if (newDefaultEntity !== this.defaultEntity) {
          this.entity = newDefaultEntity;
          this.defaultEntity = newDefaultEntity;
          this.removeAllSearchParams();
        }
      }
    );
  }

  /**
   * setSearchParam
   * @description  set the current search param, default to the preset entity as needed
   * This is to avoid overwriting entity in other scopes when locking
   * @returns {void}
   */
  setSearchParam() {
    if (!this.currentSearchParam.entity) {
      const presetEntity = this.entity || this.defaultEntity;
      Object.assign(
        this.currentSearchParam,
        this.getEntityConfig(presetEntity)
      );
    }
    this.addSearchParam();
  }

  /**
   * setEntityParam
   * @param {string} entity - entity in the context of relationships
   * @returns {void}
   */
  setEntityParam(entity) {
    Object.assign(this.currentSearchParam, entity);
    this.addSearchParam();
  }

  /**
   * get entity config
   * @param   {string} entityName
   * @returns {void}
   */
  getEntityConfig(entityName) {
    return this.config.entities.find((entity) => entity.entity === entityName);
  }

  /**
   * addSearchParam
   * @todo  fix to title
   * @returns {void}
   */
  addSearchParam() {
    if (this.currentSearchParam.q && this.currentSearchParam.entity) {
      this.searchParams.push(this.currentSearchParam);
      this.currentSearchParam = {};
      this.buildQuery();
    }
  }

  /**
   * removeSearchParam
   * @param {Number} index - index of element in the context of the ng-repeat loop
   * @returns {void}
   */
  removeSearchParam(index) {
    if (this.searchParams.length && !this.previousModelLength) {
      this.searchParams.splice(index, 1);
      this.resetSuggestions();
      this.buildQuery();
    }
  }

  /**
   * resetAllSearches
   * remove all search params
   * @returns {void}
   */
  removeAllSearchParams() {
    this.searchParams.length = 0;
    this.buildQuery();
  }

  /**
   * save the model in a variable so we can use the backspace correctly
   * @returns {void}
   */
  _savePreviousModelLength() {
    const query =
      this.currentSearchParam && this.currentSearchParam.q
        ? this.currentSearchParam.q
        : "";
    this.previousModelLength =
      !query && this.previousModelLength <= 1 ? 0 : query.length + 1;
  }

  /**
   * _saveCurrentModelLength
   * @returns {void}
   */
  _saveCurrentModelLength() {
    if (this.currentSearchParam && this.currentSearchParam.q) {
      this.previousModelLength = this.currentSearchParam.q.length + 1;
    }
  }

  /**
   * handleFormInputs - calls relevant function based on input
   * @param {Object} $event - DOM $event
   * @returns {void}
   */
  handleFormInput($event) {
    if ($event.keyCode === 13) {
      this.previousModelLength = 0;
      this.setSearchParam();
    } else if ($event.keyCode === 8) {
      this._savePreviousModelLength();
      this.removeSearchParam(this.searchParams.length - 1);
      this.debouncedToggleSuggestions();
    } else if ($event.keyCode === 40) {
      angular
        .element(".multisearch__suggestion:first-of-type")
        .trigger("focus");
    } else {
      this._saveCurrentModelLength();
      this.debouncedToggleSuggestions();
    }
  }

  /**
   * sortSearchParams
   * sort searchParams to match specified structure
   * note: this is slowish, but we never have that many objects so it should do for now
   * @param {array} searchParams - array of objects
   * @param {array} hierarchyList - the configurable structured list for entities
   * @returns {array} a new sorted based on searchParams
   */
  sortSearchParams(searchParams, hierarchyList) {
    return this._.sortBy(searchParams, (param) =>
      hierarchyList.findIndex((entity) => entity.entity === param.entity)
    );
  }

  /**
   * buildQuery
   * @description gets a valid hierarchical query object and sends relevant data to parent view
   * @returns {void}
   */
  buildQuery() {
    this.toggleDropdownLock();
    if (this.searchParams.length) {
      const sortedList = this.sortSearchParams(
        this.searchParams,
        this.config.entities
      );
      const entity = this.defaultEntity ? this.defaultEntity : this.entity;
      const selfConfig = this.getEntityConfig(entity);
      const field = selfConfig ? selfConfig.display_as : undefined;
      this.resetSuggestions();

      this.SearchFactory.getQueryObject(
        sortedList,
        entity,
        this.operator,
        field
      )
        .then((queryObj) => {
          this.setQueryObjectHandler(queryObj);
        })
        .catch(() => this.NotificationService.notifyRefresh());
    } else {
      this.setQueryObjectHandler({});
    }
  }

  /**
   * reset factory and notify parent
   * @access private
   * @param   {Array<Object>|Object} queryObj - queryObject from API Call
   * @returns {void}
   */
  setQueryObjectHandler(queryObj) {
    this.SearchFactory.resetState();

    this.MessageService.publish(
      `MultiSearch.chosenParams${this.channelNameIndex}`,
      queryObj
    );
  }

  /**
   * isInvalidQuery
   * @description check if our query object is valid
   * @param {object} queryObj - object from searchfactory
   * @returns {boolean} - whether the queryObject is valid or not
   *
   */
  isInvalidQuery(queryObj) {
    return (
      this.hasEmptyAncestors(queryObj) &&
      !this._.isArray(queryObj) &&
      !this.hasRelationships()
    );
  }

  /**
   * toggleDropdownLock on input when the widget is part of a list and has a index
   * @returns {void}
   */
  toggleDropdownLock() {
    const searchParamsSize = this.searchParams.length;
    const isRepeatable = this.instanceIndex >= 0;
    if (isRepeatable && searchParamsSize) {
      this.isLocked = true;
      this.defaultEntity = this.defaultEntity
        ? this.defaultEntity
        : this.entity;
      this.entity = this.searchParams[searchParamsSize - 1].entity;
    } else if (isRepeatable) {
      this.isLocked = false;
      this.entity = this.defaultEntity;
    }
  }

  /**
   * hasEmptyAncestors
   * check if there are actual ancestor parents
   *
   * @param {object} query - query object
   * @returns {boolean} whether element has empty ancestors
   */
  hasEmptyAncestors(query) {
    const hasSelf = this.searchParams.find(
      (param) => param.entity === this.entity
    );
    return !hasSelf && (!query.ancestors || !query.ancestors.length);
  }

  /**
   * hasRelationships
   * @returns {boolean} whether we have any relationship param
   */
  hasRelationships() {
    return !!this.searchParams.find((param) => param.type === "relationship");
  }

  /**
   * toggleClass
   * @param {$event} $event object
   * @returns {void}
   */
  toggleInputStyles($event) {
    this.isToggled = !this.isToggled;
    if ($event.type === "click") {
      const searchInput = this.element.find(".multisearch__input");
      searchInput.trigger("focus");
    }
  }

  /**
   * bindDocumentEvents
   * @description  binds click event to destroy suggestions
   * @returns {void}
   */
  bindDocumentEvents() {
    this.$document.on("click", ($event) => {
      const isCurrentElement = $event.target === this.element;

      if (!isCurrentElement) {
        this.resetSuggestions();
        this.unbindDocumentEvents();
      }
    });
  }

  /**
   * unbindDocumentEvents
   * @returns {void}
   */
  unbindDocumentEvents() {
    this.$document.off("click");
  }

  /**
   * toggleSuggestions
   * @callback {ng-change}
   * @returns {void}
   */
  toggleSuggestions() {
    if (this.currentSearchParam.q && this.currentSearchParam.q.length >= 3) {
      this.getSuggestions();
    } else {
      this.resetSuggestions();
    }
  }

  /**
   * selectSuggestion
   * @callback {ng-click}
   * @param {Number} $index - $index from DOM ng-repeat
   * @returns {void}
   */
  selectSuggestion($index) {
    this.currentSearchParam.q = this.suggestions[$index];
    this.setSearchParam();

    const searchInput = this.element.find(".multisearch__input");
    searchInput.focus();
    this.isToggled = true;
  }

  /**
   * getSuggestions
   * @description generate fuzzy query object and get suggestions from the API
   * defaults to current entity in view if one hasn't been provided
   * @returns {void}
   */
  getSuggestions() {
    const { q } = this.currentSearchParam;
    const entity = this.currentSearchParam.entity || this.entity;
    const nameField = entity
      ? this.getEntityConfig(entity).suggestions_field
      : "";
    const queryParam = nameField !== "" ? `${nameField}:${q}` : q;

    const queryObj = {
      q: this._removeNotOperator(queryParam),
      limit: 5,
      all: "true",
    };
    if (this.currentSearchParam.entity || this.isLocked) {
      this.SearchFactory.getData(entity, queryObj).then((data) => {
        const hasSameParams = q === this.currentSearchParam.q;
        if (hasSameParams) {
          this.bindDocumentEvents();
          this.suggestions = this.getSuggestionNames(
            data.objects,
            nameField,
            q.includes("NOT")
          );
        }
      });
    }
  }

  /**
   * _removeNotOperator
   * @param   {string} q
   * @returns {string} q without NOT
   */
  _removeNotOperator(q) {
    if (q.includes("NOT")) {
      return q.replace(new RegExp("NOT "), "");
    }

    return q;
  }

  /**
   * contains fallback to deal with first name and last name
   * @param   {Array} suggestions
   * @param   {string} nameField
   * @param   {boolean} hasNOTOperator
   * @returns {array} list of suggestion names
   */
  getSuggestionNames(suggestions, nameField, hasNOTOperator) {
    return suggestions.map((item) => {
      let suggestionName;

      if (nameField) {
        const name = `"${item[nameField]}"`;
        suggestionName = hasNOTOperator ? `NOT ${name}` : name;
      } else {
        const fullName = `"${item.first_name} ${item.last_name}"`;
        suggestionName = hasNOTOperator ? `NOT ${fullName}` : fullName;
      }

      return suggestionName;
    });
  }

  /**
   * resetSuggestions
   * @description empty out suggestions array
   * @returns {void}
   */
  resetSuggestions() {
    this.suggestions.length = 0;
  }

  /**
   * destroy channels
   * @returns {void}
   */
  destroyChannels() {
    this.MessageService.unregisterChannel("defaultEntityChange");
  }
}

/**
 * multiSearchDirective
 * @returns {Object} directive definition object
 */
function multiSearchDirective() {
  return {
    bindToController: {
      config: "=",
      entity: "=",
      existingSearchParams: "=",
      instanceIndex: "@",
    },
    scope: {},
    restrict: "E",
    template,
    controller: MultiSearchCtrl,
    controllerAs: "block",
    link: (scope, element) => {
      scope.block.init(element);
      element.on("$destroy", () => {
        scope.block.destroyChannels();
      });
    },
  };
}

export default multiSearchDirective;
