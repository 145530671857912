/* eslint-disable max-classes-per-file */
/**
 * @file set content store functionality
 */

/**
 * @classdesc Base class for all store functionality.
 * Contains methods for managing events, resetting the store and
 * updating children. Abstracted to show clearly what methods are
 * specific to the SetContentsStore instance. State is shaped as
 * below:
 */
class Store {
  /**
   * @constructor
   */
  constructor() {
    /**
     * Stores internal state
     * @type Object
     */
    this.state = {};
    /**
     * Stores callbacks that look for updates
     * @type {Array}
     */
    this.changeListeners = [];

    this.defaultState = {
      set: {},
      items: [],
      removedItemUrls: [],
      placedItems: [],
      unplacedItems: [],
      imagesToUpdate: [],
      imagesToCreate: [],
      previewItems: [],
    };
  }

  /**
   * Method for resetting a store, should be made exposed publicly in the factory
   */
  resetStore() {
    this.state = this._.cloneDeep(this.defaultState);
    this.changeListeners = [];
  }

  /**
   * Method for clearing the stores state
   */
  resetState() {
    this.state = this._.cloneDeep(this.defaultState);
  }

  /**
   * Update all registered components
   */
  emitChange() {
    this.changeListeners.forEach((callback) => {
      callback();
    });
  }

  /**
   * Register a callback for execution when data changes
   * @param {Function} callback
   */
  registerChangeListener(callback) {
    this.changeListeners.push(callback);
  }
}

/**
 * @classdesc Store specific for set contents should contain
 * all methods to change state. Should not contain logic.
 * States shape is below
 * {
 *   isFetchingSet: <boolean>,
 *   isFetchingConfig: <boolean>,
 *   isFetchingSupportingData: <boolean>,
 *   isFetchingPreview: <boolean>
 *   setLastRetrieved: <number>,
 *   configRetrieved: <number>,
 *   supportingDataLastRetrieved: <number>,
 *   isEditing: <boolean>,
 *   set: <Object>,
 *   hasBeenAdded: <boolean>,
 *   hasBeenChanged : <boolean>
 *   items: [
 *     {
 *       // The uid of the scheduledItem
 *       uid: <string>
 *       // The url of the content item (i.e. brand's api endpoint)
 *       contentUrl: <string>
 *       // The scheduled item from the set and the languages available
 *       scheduledItem: {
 *         [languageKey]: <Object>
 *       },
 *       // The data from the item's end point (i.e. a brand or season)
 *       contentItem: <Object>,
 *       // The first schedule attached to the scheduled item
 *       schedule: <Object>,
 *       // The language displayed in the set contents view
 *       displayedLanguage: <string>,
 *       // Does the item have an error with config
 *       hasConfigError: <string>
 *     }
 *   ],
 *   // Placed set of items
 *   placedItems: <Array.items>,
 *   // Unplaced set of items
 *   unplacedItems: <Array.items>
 * }
 */
class SetContentsStore extends Store {
  /**
   * @constructor
   * @param {Object} _ lodash
   */
  constructor(_) {
    super();
    this._ = _;
    this.state = this._.cloneDeep(this.defaultState);
  }

  /**
   * Used for resetting core data with changing view properties
   */
  resetData() {
    const { isEditing } = this.state;
    const { isLoading } = this.state;
    const { isSaving } = this.state;

    this.resetState();
    this.state = { ...this.state, isEditing, isLoading, isSaving };
  }

  /**
   * requestSet
   */
  requestSet() {
    this.state = { ...this.state, isFetchingSet: true };
    this.emitChange();
  }

  /**
   * receiveSet
   * @param {Object} set
   * @param {Array} items
   */
  receiveSet(set, items) {
    this.state = {
      ...this.state,
      isFetchingSet: false,
      setLastRetrieved: new Date().getTime(),
      set,
      items,
    };
  }

  /**
   * requestConfig
   */
  requestConfig() {
    this.state = { ...this.state, isFetchingConfig: true };
  }

  /**
   * receiveConfig
   * @param {object} config
   * @param {Array} flattenedConfig
   * @param {Array} fieldsRequired
   */
  receiveConfig(config, flattenedConfig, fieldsRequired) {
    this.state = {
      ...this.state,
      isFetchingConfig: false,
      configLastRetrieved: new Date().getTime(),
      config,
      flattenedConfig,
      fieldsRequired,
    };
  }

  /**
   * requestSupportingData
   */
  requestSupportingData() {
    this.state = { ...this.state, isFetchingSupportingData: true };
  }

  /**
   * receiveSchedules
   * @param {Array} items
   * @param {Object} alwaysSchedule
   */
  receiveSchedules(items, alwaysSchedule) {
    this.state = {
      ...this.state,
      isFetchingSupportingData: false,
      items,
      alwaysSchedule,
    };
  }

  /**
   * requestAvailableLanguages
   */
  requestAvailableLanguages() {
    this.state = { ...this.state, isFetchingAvailableLanguages: true };
  }

  /**
   * receiveAvailableLanguages
   * @param {Array} availableLanguages
   */
  receiveAvailableLanguages(availableLanguages) {
    this.state = {
      ...this.state,
      isFetchingAvailableLanguages: false,
      availableLanguages,
    };
  }

  /**
   * requestCSIPreviewData
   */
  requestCSIPreviewData() {
    this.state = { ...this.state, isFetchingPreview: true };

    this.emitChange();
  }

  /**
   * receivePreviewData description
   * @param   {Array} items
   */
  receiveCSIPreviewData(items) {
    this.state = { ...this.state, isFetchingPreview: false, items };

    this.emitChange();
  }

  /**
   * assignConfigToItems
   * @param {Array} items
   */
  assignConfigToItems(items) {
    this.state = { ...this.state, items };

    this.emitChange();
  }

  /**
   * togglePreviewMode
   * changes isEditing
   */
  togglePreviewMode() {
    this.state = { ...this.state, isEditing: false };

    this.emitChange();
  }

  /**
   * toggleEditMode
   * changes isEditing
   */
  toggleEditMode() {
    this.state = { ...this.state, isEditing: true };

    this.emitChange();
  }

  /**
   * Sort expired
   * @param {Array.<Object>} expiredItems
   */
  sortExpired(expiredItems) {
    this.state = { ...this.state, expiredItems };
  }

  /**
   * sortPlaced
   * @param {Array.<Object>} placedItems
   */
  sortPlaced(placedItems) {
    this.state = { ...this.state, placedItems };
  }

  /**
   * sortUnplaced
   * @param {Array.<Object>} unplacedItems
   */
  sortUnplaced(unplacedItems) {
    this.state = { ...this.state, unplacedItems };

    this.emitChange();
  }

  /**
   * sortUnplaced
   * @param {Array.<Object>} positionedItems
   */
  correctPositions(positionedItems) {
    this.state = { ...this.state, positionedItems };

    this.emitChange();
  }

  /**
   * sendEntityRequest
   * changes isPostingEntity
   */
  sendEntityRequest() {
    this.state = { ...this.state, isPostingEntity: true };

    this.emitChange();
  }

  /**
   * receiveEntity
   * changes isPostingEntity
   */
  receiveEntity() {
    this.state = { ...this.state, isPostingEntity: false };

    this.emitChange();
  }

  /**
   * addItem
   * @param {Object} contentItem
   * @param {string} displayedLanguage
   * @param {string} type
   */
  addItem(contentItem, displayedLanguage, type) {
    this.state = {
      ...this.state,
      items: [].concat(this.state.items, {
        uid: `new.${contentItem.uid}`,
        contentUrl: contentItem.self,
        hasBeenAdded: true,
        scheduledItem: {
          [displayedLanguage]: {
            hasBeenAdded: true,
            schedule_urls: [this.state.alwaysSchedule.self],
            position: 0,
            language: displayedLanguage,
            expired: contentItem.expired,
            content_url: contentItem.self,
            uid: `new.${contentItem.uid}`,
          },
        },
        type,
        contentItem: {
          [displayedLanguage]: contentItem,
        },
        displayedLanguage,
        schedules: [this.state.alwaysSchedule],
      }),
    };
  }

  /**
   * addLanguage
   * @param {Object} item
   */
  addLanguage(item, type) {
    const itemToUpdateIndex = this.state.items.findIndex(
      (fullItem) =>
        fullItem.uid === item.uid ||
        fullItem.contentItem[Object.keys(fullItem.contentItem)[0]].uid ===
          item.uid
    );

    const updatedItem = this._.merge(
      this.state.items[itemToUpdateIndex][type],
      {
        [item.language]: item,
      }
    );

    const updatedFullItem = {
      ...this.state.items[itemToUpdateIndex],
      [type]: updatedItem,
    };

    this.state = {
      ...this.state,
      items: [].concat(
        this.state.items.slice(0, itemToUpdateIndex),
        updatedFullItem,
        this.state.items.slice(itemToUpdateIndex + 1)
      ),
    };
  }

  /**
   * updateItem
   * @param {Object} scheduledItem
   */
  updateItem(item) {
    const itemToUpdateIndex = this.state.items.findIndex(
      (stateItem) => stateItem.uid === item.uid
    );
    const updatedItem = { ...item, hasBeenUpdated: true };

    this.state = {
      ...this.state,
      items: [].concat(
        this.state.items.slice(0, itemToUpdateIndex),
        updatedItem,
        this.state.items.slice(itemToUpdateIndex + 1)
      ),
    };
  }

  /**
   * removeScheduledItem
   * @param {Object} scheduledItemLanguageVersion
   * @returns {void}
   */
  removeScheduledItem(scheduledItemLanguageVersion) {
    const itemToRemoveIndex = this.state.items.findIndex(
      (item) => item.uid === scheduledItemLanguageVersion.uid
    );

    this.state = {
      ...this.state,
      items: [].concat(
        this.state.items.slice(0, itemToRemoveIndex),
        this.state.items.slice(itemToRemoveIndex + 1)
      ),
      removedItemUrls: [].concat(
        this.state.removedItemUrls,
        scheduledItemLanguageVersion.hasBeenAdded
          ? []
          : [scheduledItemLanguageVersion.self]
      ),
    };
  }

  /**
   * removeMultipleScheduledItems
   * @param {Array.<Object>} scheduledItemsLanguageVersion
   */
  removeMultipleScheduledItems(scheduledItemsLanguageVersion) {
    scheduledItemsLanguageVersion.forEach((item) => {
      this.removeScheduledItem(item);
    });
  }

  /**
   * Position an item
   * @param items
   */
  positionItem(items) {
    this.state = { ...this.state, items };
  }

  /**
   * Reset the store data without removing editing state
   */
  discardChanges() {
    const { isEditing } = this.state;
    this.resetState();
    this.state = { ...this.state, isEditing };
  }

  /**
   * Set the image to create on save
   * @param {Object} image
   */
  setImageToCreate(image) {
    const indexInCurrentArray = this.state.imagesToCreate.findIndex(
      (item) => item.uid === image.uid
    );

    const imagesToCreate =
      indexInCurrentArray !== -1
        ? [
            ...this.state.imagesToCreate.splice(0, indexInCurrentArray),
            image,
            ...this.state.imagesToCreate.splice(indexInCurrentArray + 1),
          ]
        : [...this.state.imagesToCreate, image];

    this.state = { ...this.state, imagesToCreate };
  }

  /**
   * setImageToUpdate
   * @param {Object} image
   */
  setImageToUpdate(image) {
    const indexInCurrentArray = this.state.imagesToUpdate.findIndex(
      (item) => item.uid === image.uid
    );

    const imagesToUpdate =
      indexInCurrentArray !== -1
        ? [
            ...this.state.imagesToUpdate.splice(0, indexInCurrentArray),
            image,
            ...this.state.imagesToUpdate.splice(indexInCurrentArray + 1),
          ]
        : [...this.state.imagesToUpdate, image];

    this.state = { ...this.state, imagesToUpdate };
  }

  /**
   * updateImageObject
   * @param {Object} image
   * @param {string} uid
   */
  updateImageObject(image, uid) {
    const indexToUpdate = this.state.items.findIndex(
      (item) => item.uid === uid
    );
    const updatedScheduledItem = {
      ...this.state.items[indexToUpdate].scheduledItem,
    };

    Object.keys(updatedScheduledItem).forEach((language) => {
      updatedScheduledItem[language].image_urls = [image];
    });

    const updatedItem = {
      ...this.state.items[indexToUpdate],
      scheduledItem: updatedScheduledItem,
    };

    this.state = {
      ...this.state,
      items: [
        ...this.state.items.slice(0, indexToUpdate),
        updatedItem,
        ...this.state.items.slice(indexToUpdate + 1),
      ],
    };
  }

  /**
   * generate display positions for preview
   * @param   {Array} items
   */
  generateDisplayPositions(items) {
    this.state = { ...this.state, items };

    this.emitChange();
  }

  /**
   * requestPreviewItems
   */
  requestPreviewItems() {
    this.state = { ...this.state, isRequestingPreviewItems: true };
  }

  /**
   * receivePreviewItems
   * @param  {Object} previewItems - list of items to display
   */
  receivePreviewItems(previewItems) {
    this.state = {
      ...this.state,
      isRequestingPreviewItems: false,
      previewItems,
    };

    this.emitChange();
  }

  /**
   * generate display positions for preview
   * @param   {Array} items
   */
  generateDisplayTitles(items) {
    this.state = { ...this.state, items };

    this.emitChange();
  }

  /**
   * generateDisplayTitle
   * @param   {Object} item
   */
  generateDisplayTitle(item) {
    const itemToUpdateIndex = this.state.items.findIndex(
      (stateItem) => stateItem.uid === item.uid
    );

    const updatedItem = { ...item };

    this.state = {
      ...this.state,
      items: [].concat(
        this.state.items.slice(0, itemToUpdateIndex),
        updatedItem,
        this.state.items.slice(itemToUpdateIndex + 1)
      ),
    };

    this.emitChange();
  }

  /**
   * updates items
   * @param {Array.<Object>} items
   */
  updateItems(items) {
    this.state = { ...this.state, items };
  }

  /**
   * setSavingState
   */
  setSavingState() {
    this.state = { ...this.state, isSaving: true };

    this.emitChange();
  }

  /**
   * unsetSavingState
   */
  unsetSavingState() {
    this.state = { ...this.state, isSaving: false };

    this.emitChange();
  }

  /**
   * setLoadingState
   */
  setLoadingState() {
    this.state = { ...this.state, isLoading: true };

    this.emitChange();
  }

  /**
   * unsetLoadingState
   */
  unsetLoadingState() {
    this.state = { ...this.state, isLoading: false };

    this.emitChange();
  }
}

/**
 * SetContentsStore
 * @memberOf Skylark.Pages.Sets.Shared
 * @param {Object} SetContentsDispatcher
 * @param {Object} SET_CONTENTS_ACTIONS
 * @param {Object} _
 * @returns {{state: Object, registerChangeListener: Store.registerChangeListener}}
 */
function setContentsStore(SetContentsDispatcher, SET_CONTENTS_ACTIONS, _) {
  const store = new SetContentsStore(_);

  SetContentsDispatcher.register((payload) => {
    switch (payload.actionType) {
      case SET_CONTENTS_ACTIONS.requestSet:
        store.requestSet();
        break;
      case SET_CONTENTS_ACTIONS.receiveSet:
        store.receiveSet(payload.set, payload.items);
        break;
      case SET_CONTENTS_ACTIONS.requestConfig:
        store.requestConfig();
        break;
      case SET_CONTENTS_ACTIONS.receiveConfig:
        store.receiveConfig(
          payload.config,
          payload.flattenedConfig,
          payload.fieldsRequired
        );
        break;
      case SET_CONTENTS_ACTIONS.requestSupportingData:
        store.requestSupportingData();
        break;
      case SET_CONTENTS_ACTIONS.receiveSchedules:
        store.receiveSchedules(payload.items, payload.alwaysSchedule);
        break;
      case SET_CONTENTS_ACTIONS.requestAvailableLanguages:
        store.requestAvailableLanguages();
        break;
      case SET_CONTENTS_ACTIONS.receiveAvailableLanguages:
        store.receiveAvailableLanguages(payload.availableLanguages);
        break;
      case SET_CONTENTS_ACTIONS.assignConfigToItems:
        store.assignConfigToItems(payload.items);
        break;
      case SET_CONTENTS_ACTIONS.togglePreviewMode:
        store.togglePreviewMode();
        break;
      case SET_CONTENTS_ACTIONS.toggleEditMode:
        store.toggleEditMode();
        break;
      case SET_CONTENTS_ACTIONS.sortExpired:
        store.sortExpired(payload.expiredItems);
        break;
      case SET_CONTENTS_ACTIONS.sortPlaced:
        store.sortPlaced(payload.placedItems);
        break;
      case SET_CONTENTS_ACTIONS.sortUnplaced:
        store.sortUnplaced(payload.unplacedItems);
        break;
      case SET_CONTENTS_ACTIONS.correctPositions:
        store.correctPositions(payload.positionedItems);
        break;
      case SET_CONTENTS_ACTIONS.addItem:
        store.addItem(
          payload.contentItem,
          payload.currentLanguage,
          payload.type
        );
        break;
      case SET_CONTENTS_ACTIONS.addLanguage:
        store.addLanguage(payload.item, payload.type);
        break;
      case SET_CONTENTS_ACTIONS.updateItem:
        store.updateItem(payload.item);
        break;
      case SET_CONTENTS_ACTIONS.removeScheduledItem:
        store.removeScheduledItem(payload.scheduledItem, payload.language);
        break;
      case SET_CONTENTS_ACTIONS.removeMultipleScheduledItems:
        store.removeMultipleScheduledItems(
          payload.scheduledItems,
          payload.language
        );
        break;
      case SET_CONTENTS_ACTIONS.positionItem:
        store.positionItem(payload.repositionedItems);
        break;
      case SET_CONTENTS_ACTIONS.discardChanges:
        store.discardChanges();
        break;
      case SET_CONTENTS_ACTIONS.sendEntityRequest:
        store.sendEntityRequest();
        break;
      case SET_CONTENTS_ACTIONS.receiveEntity:
        store.receiveEntity();
        break;
      case SET_CONTENTS_ACTIONS.updateImageObject:
        store.updateImageObject(payload.image, payload.uid);
        break;
      case SET_CONTENTS_ACTIONS.setImageToUpdate:
        store.setImageToUpdate(payload.image);
        break;
      case SET_CONTENTS_ACTIONS.setImageToCreate:
        store.setImageToCreate(payload.image);
        break;
      case SET_CONTENTS_ACTIONS.requestCSIPreviewData:
        store.requestCSIPreviewData(payload.items);
        break;
      case SET_CONTENTS_ACTIONS.receiveCSIPreviewData:
        store.receiveCSIPreviewData(payload.items);
        break;
      case SET_CONTENTS_ACTIONS.generateDisplayPositions:
        store.generateDisplayPositions(payload.itemsWithDisplayPositions);
        break;
      case SET_CONTENTS_ACTIONS.requestPreviewItems:
        store.requestPreviewItems();
        break;
      case SET_CONTENTS_ACTIONS.receivePreviewItems:
        store.receivePreviewItems(payload.previewItems);
        break;
      case SET_CONTENTS_ACTIONS.generateDisplayTitles:
        store.generateDisplayTitles(payload.itemsWithDisplayTitles);
        break;
      case SET_CONTENTS_ACTIONS.generateDisplayTitle:
        store.generateDisplayTitle(payload.itemWithDisplayTitle);
        break;
      case SET_CONTENTS_ACTIONS.updateItems:
        store.updateItems(payload.items);
        break;
      case SET_CONTENTS_ACTIONS.setSavingState:
        store.setSavingState();
        break;
      case SET_CONTENTS_ACTIONS.unsetSavingState:
        store.unsetSavingState();
        break;
      case SET_CONTENTS_ACTIONS.setLoadingState:
        store.setLoadingState();
        break;
      case SET_CONTENTS_ACTIONS.unsetLoadingState:
        store.unsetLoadingState();
        break;
    }
  });

  return {
    getState: () => Object.assign(store.state),
    resetStore: () => {
      store.resetStore();
    },
    resetState: () => {
      store.resetState();
    },
    registerChangeListener: (callback) => {
      store.registerChangeListener(callback);
    },
    resetData: () => {
      store.resetData();
    },
  };
}

export default setContentsStore;
