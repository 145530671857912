import angular from "angular";
import {
  accordionDirective,
  accordionGroupDirective,
} from "./accordion.directive";

const Accordion = angular
  .module("Skylark.Components.Accordion", [])
  .directive("accordion", accordionDirective)
  .directive("accordionGroup", accordionGroupDirective);

export default Accordion;
