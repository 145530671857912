import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const UserManagementIcon = createIcon({
  displayName: "UserManagementIcon",
  viewBox: "0 0 56 56",
  path: (
    <g fill="none">
      <path
        clipRule="evenodd"
        d="M28 22C31.5899 22 34.5 19.0899 34.5 15.5C34.5 11.9101 31.5899 9 28 9C24.4101 9 21.5 11.9101 21.5 15.5C21.5 19.0899 24.4101 22 28 22ZM28 24C32.6944 24 36.5 20.1944 36.5 15.5C36.5 10.8056 32.6944 7 28 7C23.3056 7 19.5 10.8056 19.5 15.5C19.5 20.1944 23.3056 24 28 24ZM35.2978 29.9515L27 38.2499V43H16C15.4477 43 15 42.5523 15 42V38.9381C18.9463 38.446 22 35.0796 22 31C22 30.4918 21.9526 29.9947 21.862 29.5128C23.7873 28.8561 25.8518 28.5 28 28.5C30.5862 28.5 33.051 29.0161 35.2978 29.9515ZM13 38.9381C9.05369 38.446 6 35.0796 6 31C6 26.5817 9.58172 23 14 23C17.2061 23 19.9717 24.886 21.2479 27.6091C23.3669 26.89 25.6379 26.5 28 26.5C31.1482 26.5 34.1345 27.1927 36.815 28.4341L43.8787 21.3699C45.0503 20.1983 46.9497 20.1983 48.1213 21.3699L50.63 23.8786C51.8016 25.0502 51.8016 26.9497 50.63 28.1212L43 35.7506V43V45H41V43V37.7505L33.75 44.9999H30V45H16C14.3431 45 13 43.6569 13 42V38.9381ZM15 26.5H13V30H9.5V32H13V35.5H15V32H18.5V30H15V26.5ZM29 42.9999V39.0783L45.293 22.784C45.6835 22.3936 46.3166 22.3936 46.7071 22.7841L49.2158 25.2928C49.6063 25.6833 49.6063 26.3165 49.2158 26.707L32.9216 42.9999H29Z"
        fill="#0E1825"
        fillRule="evenodd"
      />
    </g>
  ),
});
