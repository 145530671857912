import _ from "lodash";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { react2angular } from "react2angular";

import { RootState } from "store/store.types";
import {
  getActiveFiltersByEntity,
  getApiQuerySelector,
  getCountApiQuerySelector,
  getEntityListingData,
} from "store/listing/entity-listing/entity-listing.selector";
import {
  fetchEntityListingCount,
  setActiveFilters,
  setCountQuery,
} from "store/listing/entity-listing/entity-listing.actions";
import withStoreProvider from "hocs/with-store-provider";
import Pagination from "./pagination.component";
import { SearchAndFilterProps } from "../search-and-filter/search-and-filter.component";

const PaginationWithFilter = ({
  config,
  entityName,
  entityType,
  namespace,
}: SearchAndFilterProps) => {
  const dispatch = useDispatch();

  const apiQuery = useSelector((state: RootState) =>
    getApiQuerySelector(state, entityName, entityType, namespace)
  );
  const countQuery = useSelector((state: RootState) =>
    getCountApiQuerySelector(state, entityName, entityType, namespace)
  );
  const count = useSelector(
    (state: RootState) =>
      getEntityListingData(state, entityName, entityType, namespace).count
  );
  const range = useSelector(
    (state: RootState) =>
      getActiveFiltersByEntity(state, entityName, entityType, namespace)
        .pagination
  );

  // be sure of updating the countQuery just after the apiQuery gets updated
  useEffect(() => {
    if (apiQuery) {
      dispatch(setCountQuery({ entityName, entityType, namespace }));
    }
  }, [apiQuery, entityType, entityName, namespace, dispatch]);

  // retrieve the entity count on behalf on the children components
  useEffect(() => {
    if (!_.isEmpty(countQuery) && config.countEndpoint) {
      dispatch(
        fetchEntityListingCount({
          entityName,
          entityType,
          countQuery,
          namespace,
        })
      );
    }
  }, [countQuery, dispatch, entityName, entityType, namespace, config]);

  const updateFilter = (range): void => {
    dispatch(
      setActiveFilters({
        entityName,
        entityType,
        dynamicProperties: [
          {
            bucket: "pagination",
            name: "start",
            payload: {
              value: range.start,
            },
          },
        ],

        namespace,
      })
    );
  };

  if (!_.isNumber(count) || !range) {
    return null;
  }

  return (
    <Pagination
      count={count}
      limit={range.limit.value}
      start={range.start.value}
      updateRange={updateFilter}
    />
  );
};

// @ts-ignore
const reactPaginationWithFilter = angular
  .module("Skylark.Components.React.PaginationWithFilter", [])
  .component(
    "paginationWithFilter",
    react2angular(withStoreProvider(PaginationWithFilter), [
      "config",
      "entityName",
      "entityType",
      "namespace",
    ])
  );

export default PaginationWithFilter;
export { reactPaginationWithFilter };
