import * as editingStateActions from "store/editing-state/editing-state.actions";

import actionNames from "./modal-notification.actions.constants";

/**
 * confirmModalNotification
 * @private
 * @returns {Object}
 */
function confirmModalNotification(modalType) {
  return {
    type: actionNames.CONFIRM_MODAL_NOTIFICATION,
    modalType,
  };
}

/**
 * alternativeModalNotification
 * @private
 * @returns {Object}
 */
function alternativeModalNotification(modalType) {
  return {
    type: actionNames.ALTERNATIVE_MODAL_NOTIFICATION,
    modalType,
  };
}

/**
 * _resetModalNotification
 * @private
 * @returns {Object}
 */
function _resetModalNotification() {
  return {
    type: actionNames.RESET_MODAL_NOTIFICATION,
  };
}

/**
 * Reset Modal Notification
 * @return {function} dispatch
 */
function resetModalNotification() {
  return (dispatch) => {
    dispatch(_resetModalNotification());
    dispatch(editingStateActions.resetUnsavedChanges());
  };
}

export {
  confirmModalNotification,
  alternativeModalNotification,
  resetModalNotification,
};
