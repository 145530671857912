import template from "./set-contents.html";

/**
 * @file SetContentsController
 * Renders the set content tab within a set entity
 */

/**
 * @memberof Skylark.Pages.Sets
 * @classdesc Controller for set contents view. Handles little functionality, mostly message
 * service callbacks for modals
 */
class SetContentsController {
  /**
   * @constructor
   * @param {Object} $location
   * @param {Object} $scope
   * @param {Object} $rootScope
   * @param {Object} SetContentsActionCreators
   * @param {Object} SetContentsStore
   * @param {Object} HistoryService
   * @param {Object} MessageService
   * @param {Object} ModalTriggerService
   */
  constructor(
    $location,
    $scope,
    $rootScope,
    SetContentsActionCreators,
    SetContentsStore,
    HistoryService,
    MessageService,
    ModalTriggerService
  ) {
    this.$location = $location;
    this.$scope = $scope;
    this.$rootScope = $rootScope;
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.SetContentsStore = SetContentsStore;
    this.HistoryService = HistoryService;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;

    this._setupListeners();
    this._setupSubscriptions();
    this.SetContentsActionCreators.togglePreviewMode();
    this.SetContentsActionCreators.fetchSetData();
  }

  /**
   * Registers store Listeners
   * @private
   */
  _setupListeners() {
    this.SetContentsStore.registerChangeListener(() => {
      this._updateData();
    });

    this.urlChangeListener = this.$scope.$on(
      "$locationChangeStart",
      (event, next) => {
        if (
          this.SetContentsStore.getState().isEditing &&
          !this.isNavigatingAway
        ) {
          this.nextUrl = new URL(next);
          event.preventDefault();
          this._displayLeavingMessage();
        }

        this.isNavigatingAway = false;
      }
    );
  }

  /**
   * Display a 'Are you sure' message
   * @private
   */
  _displayLeavingMessage() {
    this.ModalTriggerService.triggerNotification({
      notificationType: "discardAndNavigate",
      channels: {
        confirm: "SetContents.NavigateAway",
      },
    });
  }

  /**
   * Registers pub sub. Needed for shared elements outside the scope of flux. (i.e. modals)
   * @private
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      "Modal.Save.setContent.base",
      (channel, contentItems) => {
        contentItems.forEach((contentItem) =>
          this.SetContentsActionCreators.addItem(contentItem)
        );
        this.SetContentsActionCreators.assignAppearanceStatus();
      }
    );

    this.MessageService.subscribe(
      "Modal.Create.setContent.base",
      (channel, item) => {
        this.SetContentsActionCreators.createEntity(item);
      }
    );

    this.MessageService.subscribe(
      "Modal.Edit.Save.ScheduledItem",
      (channel, scheduledItem) => {
        this.SetContentsActionCreators.updateScheduledItem(scheduledItem);
        this.SetContentsActionCreators.assignAppearanceStatus();
      }
    );

    this.MessageService.subscribe(
      "Modal.Edit.Save.UniqueItem",
      (channel, data) => {
        const { contentItem, scheduledItem } = data;
        this.SetContentsActionCreators.updateContentAndScheduleItem(
          scheduledItem,
          contentItem
        );
        this.SetContentsActionCreators.assignAppearanceStatus();
      }
    );

    this.MessageService.subscribe(
      "Modal.Edit.Save.MultipleLanguagesScheduledItem",
      (channel, scheduledItem) => {
        this.SetContentsActionCreators.updateScheduledItemWithLanguages(
          scheduledItem
        );
      }
    );

    this.MessageService.subscribe(
      "Modal.Edit.Delete.ScheduledItem",
      (channel, scheduledItem) => {
        this.SetContentsActionCreators.removeScheduledItem(scheduledItem);
      }
    );

    this.MessageService.subscribe("SetContents.NavigateAway", () => {
      const nextPath = this.nextUrl.pathname;
      this.isNavigatingAway = true;
      this.$rootScope.isEditMode = false;
      this.SetContentsActionCreators.togglePreviewMode();
      this.$location.path(nextPath);
    });
  }

  /**
   * removing route information and messages
   * @private
   */
  _cleanupOnRouteChange() {
    this.urlChangeListener();

    this.MessageService.unregisterChannel("Modal.Save.setContent.base");
    this.MessageService.unregisterChannel("Modal.Create.setContent.base");
    this.MessageService.unregisterChannel("Modal.Edit.Save.ScheduledItem");
    this.MessageService.unregisterChannel("Modal.Edit.Save.UniqueItem");
    this.MessageService.unregisterChannel(
      "Modal.Edit.Save.MultipleLanguagesScheduledItem"
    );
    this.MessageService.unregisterChannel("Modal.Edit.Delete.ScheduledItem");
  }

  /**
   * Update the page data forcing an update to the dom.
   * @private
   */
  _updateData() {
    this.$scope.$applyAsync(() => {
      this.state = this.SetContentsStore.getState();

      this.isLoading =
        this.state.isFetchingSet ||
        this.state.isFetchingConfig ||
        this.state.isFetchingSupportingData ||
        this.state.isSaving;
    });
  }

  /**
   * ngClick method for button
   */
  startEditing() {
    this.$rootScope.isEditMode = true;
    this.SetContentsActionCreators.toggleEditMode();
  }

  $onDestroy() {
    this._cleanupOnRouteChange();
  }
}

const setContentsPageComponent = {
  controller: SetContentsController,
  controllerAs: "module",
  template,
};

export default setContentsPageComponent;
