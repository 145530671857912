import template from "./modal-dimensions-settings.html";

/**
 * ModalDimensionsSettings
 * @file Renders dimensions settings modal.
 */
/**
 * @memberof  Components.Modal
 * @classdesc Renders dimensions settings modal.
 */
class ModalDimensionsSettingsCtrl {
  /**
   * @constructor
   * @param   {Object} ConfigurationFactory
   * @param   {Object} MessageService
   * @param   {Object} _ lodash
   */
  constructor($timeout, MessageService, NotificationService, _) {
    this.$timeout = $timeout;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this._ = _;

    this.context = "dimensions-settings";
    this.data.amountSelected = 0;
    this.totalDimensions = 0;
    this.counts = {};
  }

  /**
   * $onInit
   * @memberof ModalDimensionsSettings
   */
  $onInit() {
    this.dimensions = this.options.dimensions;

    this.setCurrentTab();
    this._setSubscriptions();
    this._setInitialData();
    this._countTotalDimensions();
    this._countSelectedDimensions();
  }

  /**
   * set initial subscriptions
   * @memberof ModalDimensionsSettings
   */
  _setSubscriptions() {
    this.MessageService.publish(this.options.channels.toggle);
  }

  /**
   * sets initial data for reverting on cancel
   * @memberof ModalDimensionsSettings
   */
  _setInitialData() {
    this.initialDimensions = angular.copy(this.options.dimensions, {});
    this.initialData = angular.copy(this.data, {});
  }

  /**
   * setCurrentTab
   * @param    {Event} $event
   * @param    {String} tabName
   * @returns  {void}
   * @memberof ModalDimensionsSettings
   */
  setCurrentTab($event, tabName) {
    this.$timeout(() => {
      const firstElement = document.querySelector(
        `#filter-nav--${this.config.dimensions[0].name}`
      );
      const element = $event ? $event.currentTarget : firstElement;

      this.currentTab = {
        width: element.clientWidth,
        position: element.offsetLeft,
        name: tabName || this.config.dimensions[0].name,
      };
    });
  }

  /**
   * _countSelectedDimensions - sets correct initial data when it doesn't alreay exist.
   * @param   {Object} data - an object with initial filter parameters
   * @returns {void}
   */
  _countSelectedDimensions() {
    this.data.amountSelected = Object.keys(this.dimensions).reduce(
      (totalDimensions, name) =>
        totalDimensions + (this.data[name] ? this.data[name].length : 0),
      0
    );
  }

  /**
   * _countTotalDimensions
   * @returns {void}
   */
  _countTotalDimensions() {
    this.totalDimensions = this._.sum(
      this._.map(this.dimensions, (dimension) => dimension.total)
    );
  }

  /**
   * save
   * @returns  {void}
   * @memberof ModalDimensionsSettings
   */
  save() {
    this._countSelectedDimensions();
    this.MessageService.publish(this.options.channels.save, this.data);
    this.destroyModal();
  }

  /**
   * _calculateTotalAmountSelected
   * @returns  {Number} the total amount of selected checkboxes for comparison in checkbox
   * @memberof ModalDimensionsSettings
   */
  _calculateAmountSelected() {
    return this._.sum(this._.values(this.counts));
  }

  /**
   * _resetFilters
   * @returns  {void}
   * @memberof ModalDimensionsSettings
   */
  _resetFilters() {
    this.data = angular.copy(this.initialData, this.data);
    this.dimensions = angular.copy(this.initialDimensions);
  }

  /**
   * cancel
   * @returns  {void}
   * @memberof ModalDimensionsSettings
   */
  cancel() {
    this._resetFilters();
    this.destroyModal();
  }
}

const ModalDimensionsSettingsComponent = {
  restrict: "E",
  bindings: {
    data: "=",
    config: "<",
    options: "<",
    destroyModal: "&",
  },
  controller: ModalDimensionsSettingsCtrl,
  controllerAs: "module",
  template,
};

export default ModalDimensionsSettingsComponent;
