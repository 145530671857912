import _ from "lodash";
import NotificationMessage from "skylarklib/constants/notification-text";

function NotificationService(toaster) {
  const service = {};

  /**
   *
   * notify - publish a toaster notification of type
   *
   * @param  {String} type - info or error
   * @param  {String} message - Message to display
   * @returns {void}
   */
  service.notify = (type, message) => {
    if (type === "info") {
      service.notifyInfo(message);
    }
    if (type === "error") {
      service.notifyError(message);
    }
  };

  /**
   * notifyInfo - publish an information notification
   *
   * @param  {String} msg - Message to display
   * @returns {Object}
   */
  service.notifyInfo = (msg) => toaster.pop("info", msg);

  /**
   *
   * notifyError - publish an error notification
   * as an error message can very from short to very long and explicit the duration of the toaster varies
   * @param  {String|Error} error - Message to display
   */
  service.notifyError = (error) => {
    const message = service.getErrorMessage(error);
    const minDuration = 10000;
    const duration = Math.max(
      minDuration,
      service.calculateReadingDuration(message)
    );
    toaster.pop("error", message, "", duration);
  };

  /**
   * get error message from a generic error parameter
   * @param {Error|String|Object} error - the error could be a string, skylark error, or js Error
   * @return {string}
   */
  service.getErrorMessage = (error) => {
    if (typeof error === "string") {
      return error;
    }

    // skylark error or http error
    if (error && error.error) {
      return error.error;
    }

    // instance of Error
    if (error && error.message) {
      return error.message;
    }

    return "";
  };

  /**
   * calculate duration of reading based on text length
   * on average a person can read 860 characters per minute (https://en.wikipedia.org/wiki/Words_per_minute#Reading_and_comprehension)
   * this will be used to gage the duration needed for reading
   * @param message
   * @return {number}
   */
  service.calculateReadingDuration = (message) => {
    const msPerCharacter = (60 * 1000) / 860;
    return Math.round(message.replace(/ /g, "").length * msPerCharacter);
  };

  /**
   *
   * notifyRefresh - notify
   * @returns {function}
   */
  service.notifyRefresh = () =>
    service.notifyError(NotificationMessage.refreshError);

  /**
   *
   * notifyStickyError - publish an error that sticks around
   *
   * @param  {String} msg - Message to display
   * @returns {function}
   */
  service.notifyStickyError = (msg) =>
    toaster.pop("error", service.getErrorMessage(msg), "", 0);

  /**
   *
   * clearStickyError - clear sticky errors
   * @returns {function}
   */
  service.clearStickyErrors = () => toaster.clear();

  /**
   *
   * createNotificationString- clear sticky errors
   * @returns {String}
   */
  service.createNotificationString = (contentItemType) => {
    const contentType = contentItemType.endsWith("s")
      ? contentItemType.slice(0, -1)
      : contentItemType;
    const contentTypeStartsWithVowel = /[aeiou]/.test(
      contentType.substring(0, 1).toLowerCase()
    );
    let contentTypeString;

    if (contentTypeStartsWithVowel) {
      contentTypeString = `an ${_.startCase(contentType)}`;
    } else if (
      contentItemType === "computed-scheduled-items" ||
      contentType === "dynamic-object"
    ) {
      contentTypeString = "a Dynamic Object";
    } else {
      contentTypeString = `a ${contentType}`;
    }

    return contentTypeString.toLowerCase();
  };

  return service;
}

export default NotificationService;
