/**
 * ConfigurationFactory
 *
 *
 *
 * @description Template factory
 */

function TemplateFactory($q, $timeout, $compile, $templateCache, ApiService) {
  const factory = {};

  /**
   * getTemplate - gets template from template
   * cache or loads it. Returns a promise for.
   *
   * @param  {String} url - template url
   * @return {Promise} deffered promise
   *
   */
  factory.getTemplate = (url) => {
    const deferred = $q.defer();
    const template = $templateCache.get(url);

    if (template) {
      /*
       * Defer execution to next tick of
       * the event loop so promise can resolve
       */
      $timeout(() => deferred.resolve(template));
    } else {
      ApiService.get(url)
        .then((data) => deferred.resolve(data))
        .catch((err, status) => deferred.reject(err, status));
    }

    return deferred.promise;
  };

  /**
   *
   * @param template
   * @returns {{el: (*|any), compiled: *}}
   */
  factory.compileTemplate = (template) => {
    const el = angular.element(template);
    const compiled = $compile(el);

    return {
      el,
      compiled,
    };
  };

  return factory;
}

export default TemplateFactory;
