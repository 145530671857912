import React, { Fragment } from "react";
import PropTypes from "prop-types";

import * as ScheduleHelpers from "../helpers/schedule-helpers";
import Schedules from "../schedules";
import SchedulesDate from "../schedules-date/schedules-date";
import { STATUSES } from "../schedules.constants";

/**
 * Renders a label for single schedules with date.
 * @export
 * @param {Object} props
 * @returns {JSX}
 */
export default function SchedulesSingle(props) {
  const scheduleType = ScheduleHelpers.getScheduleType(props.schedule);
  const scheduleStatus = ScheduleHelpers.getScheduleStatus(props.schedule);
  const { copy, styling } = STATUSES[scheduleType][scheduleStatus];

  return (
    <Fragment>
      <Schedules text={copy} styling={styling} />
      <Fragment>
        <SchedulesDate
          schedule={props.schedule}
          scheduleStatus={scheduleStatus}
        />
      </Fragment>
    </Fragment>
  );
}

SchedulesSingle.propTypes = {
  schedule: PropTypes.shape({
    status: PropTypes.oneOf(["expired", "current", "future"]),
    starts: PropTypes.string,
    ends: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  }).isRequired,
};
