/**
 * getSubType - for entities that have subtypes (i.e. sets)
 * @param   {Object} item data
 * @param   {string} mainType the entity type as a singular
 * @returns {string} subType
 */
function _getSubType(item, mainType) {
  let subType = "";

  if (mainType === "set") {
    subType = item.set_type_slug;
  }

  subType = _pluralToSingular(subType);

  return subType;
}

/**
 * getDisplayName
 * @param   {object} item data
 * @param   {string} rawType - raw type from URL
 * @param   {object} config data
 * @returns {string} display name for view
 */
function _getDisplayName(item, rawType, config) {
  const contentConfig = config.content_types.filter(
    (type) => type.name === rawType
  );
  const displayName = contentConfig.length ? contentConfig[0].display_name : "";

  return displayName;
}

/**
 * getMainType (singular)
 * @param   {string} rawType
 * @returns {string} raw type in singular form
 */
function _getMainType(rawType) {
  return _pluralToSingular(rawType);
}

/**
 * pluralToSingular - convert plural strings to singular
 * @param   {string} typeString
 * @returns {string} parameter string or modified string
 */
function _pluralToSingular(typeString) {
  if (/[s]$/i.test(typeString)) {
    return typeString.substring(0, typeString.length - 1);
  }

  return typeString;
}

class EntityTypeHelpers {
  /**
   * get information about an item
   * @param   {object} item - the item data
   * @param  {Array} config - array of configs for all possible entities
   * @returns {Object} object with all possible names
   */
  getItemType(item, config) {
    const selfUrl = item.self;
    const rawType = this.getRawType(selfUrl);
    const displayName = _getDisplayName(item, rawType, config);
    const mainType = _getMainType(rawType);
    const subType = _getSubType(item, mainType);

    return {
      pluralised: rawType,
      singular: subType || mainType,
      content_type: mainType,
      display_name: displayName,
    };
  }

  /**
   * getRawType - get entity type from URL
   * @param   {string} selfUrl - field 'self'
   * @returns {string} the entity type from url
   */
  getRawType(selfUrl = "") {
    const firstIndex = selfUrl.indexOf("/", 1);
    const secondIndex = selfUrl.indexOf("/", firstIndex + 1);

    return selfUrl.substring(firstIndex + 1, secondIndex);
  }

  /**
   * getSingularType from url
   * @param   {String} selfUrl
   * @returns {String} the raw type in singular form
   */
  getSingularType(selfUrl = "") {
    return _pluralToSingular(this.getRawType(selfUrl));
  }
}

export default EntityTypeHelpers;
