import template from "./date-filter.html";

class DateFilterCtrl {
  /**
   * @constructor
   * @param   {Object} $timeout
   * @param   {Object} momentJS
   * @param   {Object} MessageService
   */
  constructor(
    _,
    $timeout,
    $document,
    $jQuery,
    momentJS,
    MessageService,
    SetContentsStore
  ) {
    this.$timeout = $timeout;
    this.$document = $document;
    this.$jQuery = $jQuery;
    this.momentJS = momentJS;
    this.MessageService = MessageService;
    this.SetContentsStore = SetContentsStore;
  }

  /**
   * init description
   * @param {element} element
   * @returns {void}
   */
  init() {
    this.isToday = true;
    this.isDatePickerVisible = false;
    this.isRangeEnabled = false;
    this.fieldToUse = "slots__between";
    this.currentDate = this.momentJS();

    this.startDate = this.momentJS().startOf("day").format();
    this.endDate = null;

    this.currentDateInterval = this._buildDateInterval(this.currentDate);
    this.viewDate = this._buildViewDate();

    this.bindDocumentEvents();
  }

  /**
   * setDay - set a single day
   * this method gets fired on init so its
   * necessary to check for preview items
   * before publishing the current date
   * @param {Object} pikaday
   * @param {Object} date object
   * @returns {void}
   */
  setDay(pikaday, date) {
    this.currentDate = this.momentJS(date);

    if (this.endDate) {
      this.start.setStartRange(date);
      this.end.setStartRange(date);
      this.end.setMinDate(date);
    }

    if (!this.isRangeEnabled && !this.end) {
      this.start.setStartRange(null);
      this._resetRanges();
    }

    this.MessageService.publish("DateAndTime.updateFilters", this.currentDate);

    this._changeDate();
    this.toggleContainer();
  }

  /**
   * setStartPickerDates - sets initial date for start picker
   * @callback pikaday#on-open
   * @param {object} pikaday
   * @returns {void}
   */
  setStartPickerDates(pikaday) {
    this.start = pikaday;
    if (!this.start.getDate()) {
      const date = this.currentDate
        ? this.currentDate.clone()
        : this.momentJS();
      this.start.setDate(date.toDate());
    }
  }

  /**
   * setEndPickerDates
   * @callback pikaday#on-open
   * @param {Object} pikaday
   * @returns {void}
   */
  setEndPickerDates(pikaday) {
    this.end = pikaday;
    this.end.setMinDate(this.currentDate.clone().toDate());
  }

  /**
   * setRange - set an end date to build a range of dates
   * @param {Object} pikaday
   * @param {Object} date - momentJS date
   * @returns {void}
   */
  setRange(pikaday, date) {
    if (this.startDate) {
      if (
        this.endDate &&
        this.endDate.clone().format() === this.momentJS(date).format()
      ) {
        this.endDate = null;
        this.end.setDate(null);
        this._setRangeValues(null);
        // this is a hack because we can't reset max date on this plugin
        this.start.setMaxDate(
          this.currentDate.clone().add(20, "years").toDate()
        );
        this._repaintPicker();
      } else {
        this.endDate = this.momentJS(date);
        this._setRangeValues(date);
        this.start.setMaxDate(date);
        this._repaintPicker();
      }

      this._changeDate();
      this.toggleContainer();
    }
  }

  /**
   * _setRangeValues
   * @param {string} date or null
   * @returns {void}
   */
  _setRangeValues(date) {
    const startDate = date ? this.currentDate.clone().toDate() : null;
    this.end.setStartRange(startDate);
    this.end.setEndRange(date);

    this.start.setStartRange(startDate);
    this.start.setEndRange(date);
  }

  /**
   * _repaintPicker
   * repaints picker instance so class changes take effect
   * @returns {void}
   */
  _repaintPicker() {
    this.start.hide();
    this.start.show();
  }

  /**
   * toggleContainer
   * @returns {void}
   * @todo  does this belong here
   */
  toggleContainer() {
    this.isDatePickerVisible = !this.isDatePickerVisible;
    this.bindDocumentEvents();
    this.isRangeEnabled = false;
    if (this.endDate && this.isDatePickerVisible) {
      this.$timeout(() => this.end.show());
    }
    this._repaintPicker();
  }

  /**
   * enableRange
   * @access public
   * @callback ng-click
   * @returns {void}
   */
  enableRange() {
    this.isRangeEnabled = !this.isRangeEnabled;
  }

  /**
   * goToNextDay
   * @access public
   * @callback ng-click
   * @returns {void}
   */
  goToNextDay() {
    this.currentDate.add(1, "days");
    this._setValuesOnButtonNavigation();
  }

  /**
   * goToPreviousDay
   * @access public
   * @callback ng-click
   * @returns {void}
   */
  goToPreviousDay() {
    this.currentDate.subtract(1, "days");
    this._setValuesOnButtonNavigation();
  }

  /**
   * goToToday
   * @access public
   * @callback ng-click
   * @returns {void}
   */
  goToToday() {
    this.currentDate = this.momentJS();
    this.endDate = null;
    this.isRangeEnabled = false;
    this._setValuesOnButtonNavigation();
  }

  /**
   * _setValuesOnButtonNavigation
   * called from go to next, go to previous and go to today
   * @returns {void}
   */
  _setValuesOnButtonNavigation() {
    this.start.setDate(this.currentDate.clone().toDate());
    this._resetRanges();
    this.isToday = this._isToday();
    this.isDatePickerVisible = false;
  }

  /**
   * _resetRanges
   * @returns {void}
   */
  _resetRanges() {
    this.start.setStartRange(null);
    this.start.setEndRange(null);
    this.start.setMaxDate(this.currentDate.clone().add(20, "years").toDate());
    if (this.end) {
      this.end.setMinDate(this.currentDate.clone().toDate());
      this.end.setStartRange(null);
      this.end.setEndRange(null);
      this.end.setDate(null);
    }
  }

  /**
   * _changeDate
   * @returns {void}
   */
  _changeDate() {
    this.currentDateInterval = this._buildDateInterval(
      this.currentDate,
      this.endDate
    );
    this.isToday = this._isToday();
    this.isRange = this._isRange(this.currentDate, this.endDate);
    this.viewDate = this._buildViewDate();
    this._notifyParent();
  }

  /**
   * _isToday
   * @returns {Boolean} whether the current date is equal to today
   */
  _isToday() {
    const today = this.momentJS();

    return this.currentDate.isSame(today, "day");
  }

  /**
   * _isRange
   * @param   {Object} dateStart
   * @param   {Object} dateEnd
   * @returns {Boolean}
   */
  _isRange(dateStart, dateEnd) {
    if (dateEnd) {
      return !dateStart.isSame(dateEnd, "day");
    }
  }

  /**
   * _buildDateInterval
   * @param   {Object} momentDateStart
   * @param  {Object} momentDateEnd
   * @returns {Object} range object containing a start and end date
   */
  _buildDateInterval(momentDateStart, momentDateEnd) {
    const dateStart = this.momentJS(momentDateStart).clone();
    const dateEnd = momentDateEnd
      ? this.momentJS(momentDateEnd).clone()
      : this.momentJS(momentDateStart).clone();

    return {
      start: dateStart.startOf("day"),
      end: dateEnd.endOf("day"),
    };
  }

  /**
   * _buildViewDate
   * @returns {string}
   */
  _buildViewDate() {
    if (this.isRange) {
      return `${this.currentDate.format("L")} ~ ${this.endDate.format("L")}`;
    }

    const dateToFormat = this.currentDate.clone();
    const dayText = this._buildDayText(dateToFormat);

    return `${dayText}${dateToFormat.format("L")}`;
  }

  /**
   * _buildDayText
   * @param   {object} momentDate
   * @returns {string} a string representing Today, the day of the week or nothing
   */
  _buildDayText(momentDate) {
    const today = this.momentJS();
    if (this._isToday()) {
      return "Today - ";
    }
    if (
      momentDate.isAfter(today) &&
      momentDate.isBefore(today.add(7, "days"))
    ) {
      return `${momentDate.format("dddd")} - `;
    }

    return "";
  }

  /**
   * notifyParent
   * @returns {void}
   */
  _notifyParent() {
    this.MessageService.publish(
      "DateRange.changeDate",
      this.currentDateInterval
    );
  }

  /**
   * bindDocumentEvents
   * @returns {void}
   */
  bindDocumentEvents() {
    if (this.isDatePickerVisible) {
      this.$document.on("click.toggleDateFilter", (event) => {
        const parentElement = this.$jQuery("#js-date-range-container")[0];
        if (
          !this.$jQuery.contains(parentElement, event.target) &&
          this.isDatePickerVisible
        ) {
          this.toggleContainer();
        }
      });
    } else {
      this.unbindDocumentEvents();
    }
  }

  /**
   * unbindDocumentEvents
   * @returns {void}
   */
  unbindDocumentEvents() {
    this.$document.off("click.toggleDateFilter");
  }
}

/**
 * dateRangeDirective
 * @returns {Object}
 */
function dateFilterDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      hasRangeFiltering: "<",
    },
    controller: DateFilterCtrl,
    controllerAs: "component",
    template,
    link: (scope) => {
      scope.component.init();
      scope.$on("destroy", () => scope.component.unbindDocumentEvents());
    },
  };
}

export default dateFilterDirective;
