import angular from "angular";

import TextTrack from "modules/text-tracks/text-track/text-track.component";
import Accordion from "./accordion/accordion.module";
import Article from "./+article/article.module";
import AddItemButton from "./add-item-button/add-item-button.module";
import Banner from "./banner/banner.module";
import Bookmark from "./bookmark/bookmark.module";
import Changelog from "./changelog/changelog.module";
import ContentBreadcrumb from "./content-breadcrumb/content-breadcrumb.module";
import ContentSettings from "./content-settings/content-settings.module";
import CalendarGrid from "./+calendar-grid/calendar-grid.module";
import CollapsiblePanel from "./collapsible-panel/collapsible-panel.module";
import ContentListingBlock from "./content-listing-block/content-listing-block.module";
import OvpAsset from "./+ovp-asset/ovp-asset.module";
import Tooltip from "./tooltip/tooltip.module";
import TextScroller from "./text-scroller/text-scroller.module";
import SetContentsBanner from "./set-contents-banner/set-contents-banner.module";
import SchedulePreview from "./scheduling-information/scheduling-information.module";
import RoleExpander from "./role-expander/role-expander.module";
import RequiredIndicator from "./required-indicator/required-indicator.module";
import LinearProgressIndicator from "./linear-progress-indicator/linear-progress-indicator.module";
import RadialProgressIndicator from "./radial-progress-indicator/radial-progress-indicator.module";
import PictureView from "./picture-view/picture-view.module";
import PermissionsManager from "./permissions-manager/permissions-manager.module";
import Pagination from "./pagination/pagination.module";
import PageTitle from "./page-title/page-title.module";
import Navigation from "./navigation/navigation.module";
import NavigatableList from "./navigatable-list/navigatable-list.module";
import Multisearch from "./multisearch/multisearch.module";
import Modal from "./+modal/modal.module";
import Maskable from "./maskable/maskable.module";
import Loading from "./loading/loading.module";
import ItemBlock from "./item-block/item-block.module";
import IngestIdBanner from "./ingest-id-banner/ingest-id-banner.module";
import EditableForm from "./editable-form/editable-form.module";
import DynamicObjectItemBlock from "./dynamic-object-item-block/dynamic-object-item-block.module";
import DeleteBlock from "./delete-block/delete-block.module";
import DateRangeFilter from "./date-range-filter/date-range-filter.module";
import DatePicker from "./date-picker/date-picker.module";
import DateFilter from "./date-filter/date-filter.module";
import CollapsiblePreview from "./collapsible-preview/collapsible-preview.module";
import EntityList from "./+entity-list/entity-list.module";
import Fields from "./+fields/fields.modules";
import ImagePlayback from "./image-playback/image-playback.module";
import HistoryBreadcrumb from "./history-breadcrumb/history-breadcrumb.module";
import RepeatableMultisearch from "./repeatable-multisearch/repeatable-multisearch.module";
import InputFile from "./input-file/input-file.directive";
import Toaster from "./toaster/toaster.component";
import UploadArea from "./+upload-area/upload-area.module";
import UploadProgressModal from "./upload-progress-modal/upload-progress-modal.module";
import PerformanceDetailRow from "./performance-detail-row/performance-detail-row.module";
import DataPredictionRow from "./data-prediction-row/data-prediction-row.module";
import { reactHomeSearch } from "./_react/home-search/home-search.component";
import { reactTopNavigationSearch } from "./_react/top-navigation-search/top-navigation-search.component";
import { reactIngestIndicator } from "./_react/ingest-indicator/ingest-indicator.component";
import { reactSearchAndFilter } from "./_react/search-and-filter/search-and-filter.component";
import { reactPaginationWithFilter } from "./_react/pagination/pagination-with.filter";
import { reactEditor } from "./_react/editor/editor-container";
import { reactSkylarkIcon } from "./_react/skylark-icon/skylark-icon.component";
import { reactToaster } from "./_react/toaster/toaster-container";
import { reactModal } from "./_react/modal/modal-container";
import { reactArticleStatus } from "./_react/article-status/article-status-container";
import { reactNavigationHeader } from "./_react/navigation-header/navigation-header.component";
import { reactLoadingScreen } from "./_react/loading-screen/loading-screen.component";
import { reactVersion } from "./_react/version/version.component";

const Components = angular.module("Skylark.Components", [
  Accordion.name,
  Article.name,
  AddItemButton.name,
  Banner.name,
  Bookmark.name,
  Changelog.name,
  ContentBreadcrumb.name,
  ContentSettings.name,
  CalendarGrid.name,
  CollapsiblePreview.name,
  CollapsiblePanel.name,
  ContentListingBlock.name,
  DateFilter.name,
  DatePicker.name,
  DateRangeFilter.name,
  DeleteBlock.name,
  DynamicObjectItemBlock.name,
  EditableForm.name,
  EntityList.name,
  Fields.name,
  ImagePlayback.name,
  HistoryBreadcrumb.name,
  IngestIdBanner.name,
  ItemBlock.name,
  Loading.name,
  Maskable.name,
  Modal.name,
  Multisearch.name,
  NavigatableList.name,
  Navigation.name,
  PageTitle.name,
  Pagination.name,
  PermissionsManager.name,
  PictureView.name,
  RadialProgressIndicator.name,
  LinearProgressIndicator.name,
  RepeatableMultisearch.name,
  RequiredIndicator.name,
  RoleExpander.name,
  SchedulePreview.name,
  SetContentsBanner.name,
  TextScroller.name,
  Tooltip.name,
  OvpAsset.name,
  Toaster.name,
  InputFile.name,
  UploadArea.name,
  TextTrack.name,
  UploadProgressModal.name,
  PerformanceDetailRow.name,
  DataPredictionRow.name,
  // React components
  reactSkylarkIcon.name,
  reactHomeSearch.name,
  reactIngestIndicator.name,
  reactTopNavigationSearch.name,
  reactSearchAndFilter.name,
  reactPaginationWithFilter.name,
  reactEditor.name,
  reactToaster.name,
  reactModal.name,
  reactArticleStatus.name,
  reactNavigationHeader.name,
  reactLoadingScreen.name,
  reactVersion.name,
]);

export default Components;
