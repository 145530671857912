import { createApiAction } from "store/api/api.actions";

import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import AccountConstant from "./accounts.constant";

/**
 * dispatch action with EntityFactory.get to fetch accounts
 */
const fetchAccounts = () => {
  const EntityFactory = Injector.get("EntityFactory");

  return createApiAction(
    AccountConstant.FETCH_ACCOUNTS,
    EntityFactory.getAll("accounts")
  );
};

export { fetchAccounts };
