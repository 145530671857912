import ReduceReducers from "reduce-reducers";
import { createApiReducer } from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import { GET_TAG_CATEGORIES } from "./tag-categories.constants";
import { TagApiAction, TagCategoriesInterface } from "./tag-categories.types";

const initialState: TagCategoriesInterface = {
  data: [],
};

const get = createApiReducer(initialState, GET_TAG_CATEGORIES);

const overrideDataSuccess = (state = initialState, action: TagApiAction) => {
  if (action.type === ApiConstant.getSuccessType(GET_TAG_CATEGORIES)) {
    const { response } = action;
    const { objects } = response;

    return {
      ...state,
      data: objects,
    };
  }

  return state;
};

const TagCategoriesReducer = ReduceReducers(
  initialState,
  get,
  overrideDataSuccess
);

export default TagCategoriesReducer;
