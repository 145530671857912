import React from "react";
import { DefaultDraftBlockRenderMap } from "draft-js";
import { Map } from "immutable";
import EditorBlock from "components/_react/editor/editor-block/editor-block";
import EditorListBlock from "components/_react/editor/editor-list-block/editor-list-block";

const customBlocks = Map({
  placeholder: {
    element: "div",
    wrapper: <EditorBlock indicator="p" />,
  },
  "header-two": {
    element: "h2",
    wrapper: <EditorBlock indicator="h2" />,
  },
  "header-three": {
    element: "h3",
    wrapper: <EditorBlock indicator="h3" />,
  },
  "header-four": {
    element: "h4",
    wrapper: <EditorBlock indicator="h4" />,
  },
  "header-five": {
    element: "h5",
    wrapper: <EditorBlock indicator="h5" />,
  },
  "header-six": {
    element: "h6",
    wrapper: <EditorBlock indicator="h6" />,
  },
  paragraph: {
    element: "div",
    wrapper: <EditorBlock indicator="p" />,
  },
  unstyled: {
    element: "div",
    wrapper: <EditorBlock indicator="p" />,
  },
  image: {
    element: "div",
    wrapper: <EditorBlock hasIndicator={false} />,
  },
  "media-placeholder": {
    element: "div",
    wrapper: <EditorBlock hasIndicator={false} />,
  },
  video: {
    element: "div",
    wrapper: <EditorBlock hasIndicator={false} />,
  },
  "unordered-list-item": {
    element: "li",
    wrapper: <EditorListBlock list-type="unordered-list-item" indicator="li" />,
  },
  "ordered-list-item": {
    element: "li",
    wrapper: <EditorListBlock list-type="ordered-list-item" indicator="li" />,
  },
});

export default DefaultDraftBlockRenderMap.merge(customBlocks);
