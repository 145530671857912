import { EditorState } from "draft-js";

import * as TextPlaceholders from "components/_react/editor/helpers/text-placeholders/text-placeholders";
import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

/**
 * Pastes plain text into
 * @param  {String} pastedText
 * @param  {String} pastedHTML
 * @param  {Immutable.Record} state
 * @return {Immutable.Record} - <EditorState>
 */
function pastePlainText(state, text, callback) {
  const selection = state.getSelection();

  if (DraftHelpers.isSelection(state) && DraftHelpers.isMultipleBlocks(state)) {
    TextPlaceholders.toggleOn(state, callback, text);
  } else {
    const contentState = DraftHelpers.replaceText(
      state.getCurrentContent(),
      selection,
      text
    );

    callback(EditorState.push(state, contentState, "insert-characters"));
  }
}

export { pastePlainText };
