/**
 * Feature Toggle Constants
 */
export default {
  cookieName: "skylark.releaseCookie",
  allowedValues: ["alpha"],
  alpha: [
    {
      name: "Old Routes",
      type: "feature",
      alphaFiles: [],
      filesToDeprecate: ["/v2/app/index.jsx"],
    },
    {
      name: "drawers",
      type: "feature",
      alphaFiles: ["/v2/app/features/root/root.jsx"],
    },
    {
      name: "multiplecheckbox",
      type: "field",
      alphaFiles: [
        "cms/app/js/components/+fields/dynamic-field/dynamic-field-types.constants.js",
        "cms/app/js/components/+fields/multiple-checkbox/multiple-checkbox-alpha.html",
        "cms-config/dimensions/",
      ],
      filesToDeprecate: [
        "cms-config/localnav/",
        "cms/app/js/components/+fields/multiple-checkbox/multiple-checkbox.html",
        "cms/app/js/components/+fields/multiple-checkbox/multiple-checkbox.scss",
      ],
    },
  ],
};
