import { triggerModal } from "store/modal/modal.actions";

import { MODAL_TYPES } from "skylarklib/constants/modal.constants";

import actionNames from "./editing-state.actions.constants";

/**
 * toggle editing action creator
 * @returns {Object}
 */
function _toggleEditing() {
  return {
    type: actionNames.TOGGLE_EDITING,
  };
}

/**
 * toggle global has changed action creator
 * @returns {Object}
 */
function _toggleHasChanged(hasChanged) {
  return {
    type: actionNames.TOGGLE_HAS_CHANGED,
    hasChanged,
  };
}

/**
 * _triggerUnsavedChanges
 * @param  {String} location
 * @return {Object}
 */
function _triggerUnsavedChanges(location) {
  return {
    type: actionNames.TRIGGER_UNSAVED_CHANGES,
    location,
  };
}

/**
 * resetUnsavedChanges
 * @param  {String} location
 * @return {Object}
 */
function _resetUnsavedChanges() {
  return {
    type: actionNames.RESET_UNSAVED_CHANGES,
  };
}

/**
 * _resetHasChanged
 * @return {Object}
 */
function _resetHasChanged() {
  return {
    type: actionNames.RESET_HAS_CHANGED,
  };
}

/**
 * toggleEditing
 * @returns {Function}
 */
function toggleEditing() {
  return (dispatch) => {
    dispatch(_toggleEditing());
  };
}

/**
 * toggleHasChanged
 * @param  {Boolean} hasChanged
 * @return {Function}
 */
function toggleHasChanged(hasChanged) {
  return (dispatch) => {
    dispatch(_toggleHasChanged(hasChanged));
  };
}

/**
 * resetHasChanged
 * @param  {Boolean} hasChanged
 * @return {Function}
 */
function resetHasChanged() {
  return (dispatch) => {
    dispatch(_resetHasChanged());
  };
}

/**
 * triggerUnsavedChanges
 * @param  {String} location
 * @return {Object}
 */
function triggerUnsavedChanges(location) {
  return (dispatch) => {
    dispatch(_triggerUnsavedChanges(location));
    dispatch(
      triggerModal({
        metadata: {
          name: "notification",
          type: location ? MODAL_TYPES.HAS_UNSAVED_CHANGES : MODAL_TYPES.LOGOUT,
        },
      })
    );
  };
}

/**
 * resetUnsavedChanges
 * @return {Object}
 */
function resetUnsavedChanges() {
  return (dispatch) => {
    dispatch(_resetUnsavedChanges());
  };
}

export {
  toggleEditing,
  toggleHasChanged,
  resetHasChanged,
  triggerUnsavedChanges,
  resetUnsavedChanges,
};
