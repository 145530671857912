export const CORE_ENTITIES = [
  "sets",
  "brands",
  "seasons",
  "episodes",
  "channels",
  "schedules",
  "editorial-schedules",
  "licensing",
  "tags",
  "talent",
  "assets",
  "articles",
  "cms-articles",
  "links",
  "credits",
];
