import LANGUAGES_LIST from "skylarklib/constants/language-codes";

/**
 * @fileOverview Consume data from configurable endpoint.
 * This allows us to iterate over the results of an endpoint passed on from config and use them in configurable templates,
 * without it needing to live in the dynamic widget or in a specific directive that needs it before it compiles.
 *
 */

/**
 * finds if allowed item and select dropdown item match
 * @param {string} allowedItem - allowed item name
 * @param {string} selectItem - selected menu dropdown value
 * @returns {boolean} isAllowedItemKeyMatching
 */
function hasMatchingAllowedItem(allowedItem, selectItem) {
  return allowedItem.toLowerCase() === selectItem.toLowerCase();
}

/**
 * find all the items in dropdown that match allowed list
 * @param {array} allowedItems - items allowed in dropdown
 * @param {string} selectItem - current select item in loop
 * @returns {Array} array of allowed items
 */
function findAllAllowedFields(allowedItems, selectItem) {
  return allowedItems.some((allowedItem) =>
    hasMatchingAllowedItem(allowedItem, selectItem)
  );
}

/**
 * getReadableValueFromOptions
 * @param {string} val - The dropdown value. Usually API URL (self) or the object slug
 * @param {array} options - The options
 */
function getReadableValueFromOptions(val, options) {
  if (val && options) {
    const obj = options.find(({ self, slug }) => self === val || slug === val);
    return obj?.name || obj?.title;
  }
}

class SelectInputCtrl {
  /**
   * @constructor
   * @param   {Object} ApiService
   * @param   {Object} ApiRequestConfigFactory
   */
  constructor(_, ApiService, ApiRequestConfigFactory, ModalFactory) {
    this._ = _;
    this.ApiService = ApiService;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.ModalFactory = ModalFactory;
    this.config = undefined;
    this.endpoint = undefined;
    this.options = undefined;
    this.allowed = undefined;
  }

  /**
   * loadConfiguration
   * @param   {object} config
   * @returns {void}
   */
  loadConfiguration(config) {
    this.config = config;
    this.endpoint = config.endpoint;
    this.allowed = config.allowed;

    this.getOptions(this.config);
  }

  /**
   * getOptions
   * @description assigns options to be displayed in select element
   * @param {object} item - config for field
   * @returns {void}
   */
  getOptions(item) {
    if (this.endpoint) {
      this.ApiService.get(
        this.endpoint,
        this.ApiRequestConfigFactory.createRequestConfig({
          useGlobalParams: true,
          overrideGlobalLanguage: true,
          useWildCard: true,
        })
      ).then((data) => {
        this.options = this.filterAllowed(data.objects);
      });

      return;
    }

    if (item.options === "LANGUAGE_CODES") {
      this.options = Object.values(LANGUAGES_LIST).map((language) => ({
        self: language.code,
        name: `${language.code.toUpperCase()} ${language.name}`,
      }));

      return;
    }

    this.options = item.options;
  }

  /**
   * filterAllowed
   * @description - checks for allowed options in dropdowns
   * @param {array} arr - array of data objects
   * @returns {array} - array of allowed data objects - this is either all or a match against an array of options received from config
   */
  filterAllowed(arr) {
    let filteredList = [];
    if (!this.allowed) {
      filteredList = arr;
    } else {
      filteredList = arr.filter((obj) =>
        findAllAllowedFields(this.allowed, obj[this.config.name_as])
      );
    }

    return filteredList;
  }

  /**
   * getReadableValue
   * @param {string} val - The value, could be an object or string
   *   if an object, attempt to use its properties or default to using its self url
   *   if a string, it will be the self url
   */
  getReadableValue(val) {
    const isObject =
      typeof val === "object" && !Array.isArray(val) && val !== null;
    if (isObject) {
      return (
        val?.name ||
        val?.title ||
        getReadableValueFromOptions(val.self, this.options)
      );
    }
    return getReadableValueFromOptions(val, this.options);
  }
}

/**
 * selectInputDirective
 * @returns {Object} Directive Definition Object
 */
function selectInputDirective() {
  return {
    restrict: "A",
    controller: SelectInputCtrl,
    controllerAs: "widget",
    link: (scope) => {
      scope.widget.loadConfiguration(scope.item.field);
    },
  };
}

export default selectInputDirective;
