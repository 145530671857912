import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import styles from "./checkbox.scss";

/**
 * Checkbox
 * @param {Object} props
 * @returns {JSX}
 */
export default function Checkbox(props) {
  return (
    <div className={styles.checkbox}>
      <input
        name={`checkbox--${props.id}`}
        type="checkbox"
        className={styles.checkbox__input}
        checked={props.isChecked}
        readOnly
      />
      <label
        htmlFor={`checkbox--${props.id}`}
        className={classnames(styles.checkbox__label, {
          [styles["checkbox--is-selectable"]]: props.isSelectable,
        })}
      >
        <SkylarkIcon
          iconName="tickbox"
          extraClassName={styles.checkbox__icon}
        />
      </label>
    </div>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  isSelectable: PropTypes.bool,
};

Checkbox.defaultProps = {
  isChecked: false,
  isSelectable: false,
};
