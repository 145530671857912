import { EditorState, RichUtils, SelectionState } from "draft-js";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

import Link from "components/_react/editor/links/link/link";

/**
 * _isLinkEntity
 * @param   {String} entityKey
 * @param   {Immutable.Record} contentState
 * @returns {Boolean}
 */
function _isLinkEntity(entityKey, contentState) {
  return (
    entityKey !== null && contentState.getEntity(entityKey).getType() === "LINK"
  );
}

/**
 * _findLinkEntities
 * @param   {Immutable.Map} contentBlock
 * @param   {Function} callback
 */
function _findLinkEntities(contentBlock, callback, contentState) {
  return contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();

    return _isLinkEntity(entityKey, contentState);
  }, callback);
}

/**
 * _createEntitySelection
 * @param   {Immutable.Record} editorState
 * @returns {Immutable.Map}
 */
function _createEntitySelection(editorState) {
  const contentState = editorState.getCurrentContent();
  const selection = editorState.getSelection();
  const key = selection.getEndKey();
  const currentSelection = selection.getAnchorOffset();
  const currentBlock = contentState.getBlockForKey(key);
  let selectionStart;
  let selectionEnd;

  const callback = (start, end) => {
    if (currentSelection >= start && currentSelection <= end) {
      selectionStart = start;
      selectionEnd = end;
    }
  };

  _findLinkEntities(currentBlock, callback, contentState);

  return SelectionState.createEmpty(key).merge({
    anchorKey: key,
    focusKey: key,
    anchorOffset: selectionStart,
    focusOffset: selectionEnd,
    hasFocus: true,
  });
}

/**
 * createLinkDecorator
 * @returns {Object}
 */
function buildLinkDecorator() {
  return {
    strategy: _findLinkEntities,
    component: Link,
  };
}

/**
 * createLink
 * @param   {Immutable.Record} editorState
 * @param   {String} urlValue
 * @returns {Immutable.Record} a new editor state with the newly created link
 */
function createLinkEntity(editorState, urlValue) {
  const contentState = editorState.getCurrentContent();

  const contentStateWithEntity = contentState.createEntity("LINK", "MUTABLE", {
    url: urlValue,
  });

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const newEditorState = EditorState.set(editorState, {
    currentContent: contentStateWithEntity,
  });

  return RichUtils.toggleLink(
    newEditorState,
    newEditorState.getSelection(),
    entityKey
  );
}

/**
 * editLinkEntity
 * @param   {Immutable.Record} editorState
 * @param   {String} urlValue
 * @returns {Immutable.Record} a newState
 */
function updateLinkEntity(editorState, urlValue) {
  const selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    return createLinkEntity(editorState, urlValue);
  }

  const newSelection = _createEntitySelection(editorState);
  const newState = EditorState.forceSelection(editorState, newSelection);

  return createLinkEntity(newState, urlValue);
}

/**
 * removeLinkEntity
 * @returns {Immutable.Record} a new editor state without the currently selected link
 * @todo  handle collapsed selections
 */
function removeLinkEntity(editorState) {
  let selection = editorState.getSelection();

  if (selection.isCollapsed()) {
    selection = _createEntitySelection(editorState);
  }

  return RichUtils.toggleLink(editorState, selection, null);
}

/**
 * isLink
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isLink(state) {
  if (DraftHelpers.isMultipleBlocks(state)) {
    return false;
  }

  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const endKey = selection.getEndKey();
  const selectionStart = selection.getStartOffset();
  const selectionEnd = selection.getEndOffset();

  const currentBlock = contentState.getBlockForKey(endKey);
  const containsLink = [];

  const callback = (start, end) => {
    containsLink.push(selectionEnd <= end && selectionStart >= start);
  };

  _findLinkEntities(currentBlock, callback, contentState);

  return containsLink.some((link) => link);
}

/**
 * getLinkEntity
 * @param   {Immutable.Record} editorState
 * @returns {String}
 */
function getLinkValue(state) {
  if (DraftHelpers.isMultipleBlocks(state)) {
    return "";
  }

  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const key = selection.getEndKey();
  const selectionStart = selection.getStartOffset();
  const selectionEnd = selection.getEndOffset();
  const currentBlock = contentState.getBlockForKey(key);

  let linkUrl = "";
  let entityKey;

  const callback = (start, end) => {
    if (selectionEnd <= end && selectionStart >= start) {
      linkUrl = contentState.getEntity(entityKey).getData().url;
    }
  };

  if (currentBlock) {
    currentBlock.findEntityRanges((character) => {
      entityKey = character.getEntity();

      return _isLinkEntity(entityKey, contentState);
    }, callback);
  }

  return linkUrl;
}

export {
  buildLinkDecorator,
  createLinkEntity,
  updateLinkEntity,
  removeLinkEntity,
  isLink,
  getLinkValue,
};
