export const ComponentStyle = {
  sizes: {
    sm: {
      fontSize: "13px",
      fontWeight: "600",
      lineHeight: "16px",
    },
  },
};

export default ComponentStyle;
