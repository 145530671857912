import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import * as modalNotificationActions from "store/modal-notification/modal-notification.actions";

import ModalNotification from "./modal-notification";

const mapProps = (state) => ({
  modalNotification: state.modalNotification,
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ ...modalNotificationActions }, dispatch);

export default connect(mapProps, mapDispatch)(ModalNotification);
