class ImagePlaybackCtrl {
  constructor($element, $compile, $scope, MessageService) {
    this.$element = $element;
    this.$compile = $compile;
    this.$scope = $scope;
    this.MessageService = MessageService;
  }

  /**
   * The image-uploader always uses the same image over and over again, without emptying the box.
   * This means that the canvas that gets created, sticks around even after the image upload is completed,
   * so when you open the box again, you're going to see a floating canvas. Even if the directive itself
   * should only care about creating the canvas and the overlay, the image uploader still creates this issue,
   * and solving the problem on the image-create directive level would mean changing the logic on how it works,
   * The flag just indicates that the state needs to be cleaned after the image gets uploaded.
   *
   */
  $onInit() {
    this.$element.on("load", (e) => this.onLoad(e));

    if (this.clean) {
      this.MessageService.on("Image.Create", () => {
        this.removeExistingNodes();
        this.MessageService.off("Image.Create");
      });
    }
  }

  /**
   *
   *
   * @param {object} event
   * @return {void}
   *
   */
  onLoad(event) {
    this.removeExistingNodes();

    const { target } = event;
    const { src } = target;

    const isBlob = src.includes("blob:");

    if (isBlob) {
      fetch(src)
        .then((response) => response.blob())
        .then((blob) => {
          if (!blob) {
            return;
          }

          const isGif = ["image/gif", "image/webp"].includes(blob.type);

          if (isGif) {
            this.generateFreezeframe(this.playback);
          }
        });

      return;
    }

    const isGif = src.includes(".gif") || src.includes(".webp");

    if (isGif) {
      this.generateFreezeframe(this.playback);
    }
  }

  /**
   * deletes existing nodes to avoid duplicates
   * @return {void}
   */
  removeExistingNodes() {
    const existingPlaybackOverlay = this.$element
      .parent()
      .find(".playback-overlay");
    const existingCanvas = this.$element.parent().find(".playback-canvas");

    if (existingPlaybackOverlay.length) {
      existingPlaybackOverlay.remove();
    }

    if (existingCanvas.length) {
      existingCanvas.remove();
    }
  }

  /**
   * Generates a canvas that is a clone of the animated image
   * if the flag is true, it also appends the play button
   * in order to playback the animated image.
   *
   * To avoid duplicates, it cleans the nodes at the beginning, if they exists.
   * @return {void}
   *
   */
  generateFreezeframe(enablePlayback) {
    this.removeExistingNodes();

    const $image = this.$element[0];

    this.$element.addClass("playback-image");

    const { clientWidth, clientHeight } = $image;
    const $canvas = angular.element("<canvas></canvas>");
    const canvas = $canvas[0];
    const ctx = canvas.getContext("2d");

    canvas.setAttribute("class", "playback-canvas");
    canvas.setAttribute("width", clientWidth);
    canvas.setAttribute("height", clientHeight);

    ctx.drawImage(this.$element[0], 0, 0, clientWidth, clientHeight);

    $canvas.insertAfter(this.$element);

    if (enablePlayback) {
      const $playButton = this.$compile(`
            <div class="playback-overlay">
                <skylark-icon icon-name="play"></skylark-icon>
            </div>`)(this.$scope);
      $playButton.insertBefore(this.$element);
    }
  }
}

function ImagePlaybackDirective() {
  return {
    restrict: "A",
    controller: ImagePlaybackCtrl,
    controllerAs: "playback",
    scope: {},
    bindToController: {
      playback: "<",
      clean: "<",
    },
  };
}

export default ImagePlaybackDirective;
