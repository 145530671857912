class PermissionsService {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} ApiService
   * @param {Object} NotificationService
   * @param {Object} UsersService
   */
  constructor($q, ApiService, NotificationService, UsersService) {
    this.$q = $q;
    this.ApiService = ApiService;
    this.NotificationService = NotificationService;
    this.UsersService = UsersService;
  }

  /**
   * Check to see if the user has the correct permission or if they are a superuser.
   * @param {string} permission
   * @returns {promise}
   */
  checkAccess(permission) {
    const deferred = this.$q.defer();

    this.UsersService.getCurrentUser()
      .then((user) =>
        deferred.resolve({
          hasAccess: this.hasAccess(user, permission),
        })
      )
      .catch(() => deferred.reject());

    return deferred.promise;
  }

  /**
   * hasAccess
   * @param    {Object} user
   * @param    {String} permission
   * @returns  {Boolean}
   * @memberof PermissionsService
   */
  hasAccess(user, permission) {
    return user.is_superuser || user.permissions.indexOf(permission) > -1;
  }
}

export default PermissionsService;
