import _ from "lodash";

import NotificationMessage from "skylarklib/constants/notification-text";

function AsyncSetActions(
  $q,
  EntityTypeHelpersService,
  NotificationService,
  BatchRequestFactory,
  ConfigurationFactory,
  ImagesFactory,
  SetsFactory,
  LanguagesService,
  AsyncSetActionsService,
  SetContentsDispatcher,
  SetContentsStore,
  ItemDecorationActions,
  SortingActions,
  PreviewActions,
  DisplayTitleActions,
  SET_CONTENTS_ACTIONS
) {
  const factory = {};

  let languageVersionsToCreate = [];

  /**
   * Returns the correct language version to display in the set. Either uses the same
   * as scheduled item or first language version available
   * @return {Object}
   * @private
   */
  function _getContentLanguage(item) {
    return (
      item.contentItem[item.displayedLanguage] ||
      item.contentItem[Object.keys(item.contentItem)[0]]
    );
  }

  /**
   * Receive available languages for this instance of Skylark
   * @param availableLangauges
   * @private
   */
  function _receiveAvailableLanguages(availableLanguages) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveAvailableLanguages,
      availableLanguages,
    });
  }

  /**
   * Request available languages for this instance of Skylark
   * @return {Promise}
   * @private
   */
  function _requestAvailableLanguages() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.requestAvailableLanguages,
    });

    return LanguagesService.getLanguages();
  }

  /**
   * Parse response from batch request
   * @param {Array} batchResponse
   * @private
   */
  function _receiveSchedules(batchResponse) {
    const schedules = BatchRequestFactory.parseResponse(
      batchResponse,
      "schedules"
    );
    const alwaysSchedule = BatchRequestFactory.parseResponse(
      batchResponse,
      "alwaysSchedule"
    )[0];
    const items = AsyncSetActionsService.assignSchedulesToItems(
      schedules,
      SetContentsStore.getState().items
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveSchedules,
      items,
      alwaysSchedule,
    });
  }

  /**
   * Handle the response of the batch request for adding languages
   * @param {Object} batchResponse
   * @private
   */
  function _receiveItemLanguageVersions(batchResponse) {
    const items = BatchRequestFactory.parseResponse(
      batchResponse,
      "languageVersion"
    );
    const contentItems = items.filter((item) => !item.content_url);
    const scheduledItems = items.filter((item) => !!item.content_url);

    scheduledItems.forEach((item) => {
      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.addLanguage,
        item,
        type: "scheduledItem",
      });
    });

    contentItems.forEach((item) => {
      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.addLanguage,
        item,
        type: "contentItem",
      });
    });
  }

  /**
   * Request additional scheduledItem data
   * @param {object} response
   * @returns {promise}
   * @private
   */
  function _requestSupportingData() {
    const state = SetContentsStore.getState();

    if (!state.isFetchingSupportingData) {
      const contentItems =
        AsyncSetActionsService.filterContentItemsThatSupportLanguages(
          state.items
        );

      SetsFactory.addGETSchedulesToBatch(state.set.items);
      SetsFactory.addGETItemLanguagesToBatch(
        state.set.items,
        state.fieldsRequired
      );

      if (contentItems.length) {
        SetsFactory.addGETItemLanguagesToBatch(
          contentItems,
          state.fieldsRequired
        );
      }

      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.requestSupportingData,
      });

      return BatchRequestFactory.process();
    }
  }

  /**
   * Request the cms-config. This can't be added to the batch
   * @param {Object} response
   * @returns {Promise}
   */
  function _requestConfig(type) {
    const state = SetContentsStore.getState();
    if (!state.isFetchingConfig) {
      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.requestConfig,
      });

      return ConfigurationFactory.getSetContentsConfigurationByType(type);
    }
  }

  /**
   * Receive the cms-config. This can't be added to the batch
   * @param {Object} config
   * @returns {void}
   */
  function _receiveConfig(config) {
    const flattenedConfig = SetsFactory.flattenConfig(config);

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveConfig,
      config,
      flattenedConfig,
      fieldsRequired:
        AsyncSetActionsService.buildFieldsToInclude(flattenedConfig),
    });
  }

  /**
   * requestSet
   * @param {Object} response
   * @returns {Promise}
   */
  function _requestSet() {
    const state = SetContentsStore.getState();
    const { fieldsRequired } = state;

    if (!state.isFetchingSet) {
      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.requestSet,
      });

      return SetsFactory.getCurrentSet(fieldsRequired);
    }
  }

  /**
   * _recieveSet
   * @param {Object} set
   * @private
   */
  function _receiveSet(set) {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveSet,
      set,
      items: AsyncSetActionsService.buildItemsFromSetResponse(set),
    });
  }

  /**
   * _requestPreviewData
   * @returns {Promise}
   */
  function _requestPreviewData() {
    if (!SetContentsStore.getState().isFetchingPreview) {
      SetContentsDispatcher.dispatch({
        actionType: SET_CONTENTS_ACTIONS.requestCSIPreviewData,
      });

      return SetsFactory.getSetsPreview();
    }
  }

  /**
   * receivePreviewData
   * @param   {Object} data
   */
  function _receivePreviewData(previewData) {
    const state = SetContentsStore.getState();
    const items = AsyncSetActionsService.assignPreviewItemsToCSI(
      state.items,
      previewData
    );

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.receiveCSIPreviewData,
      items,
    });
  }

  /**
   * Removes a uid from each lanaguge for an scheduled item
   * @param {Object} item
   * @return {Object}
   * @private
   */
  function _removeUidFromScheduledItem(item) {
    const updatedItem = { ...item };
    const scheduleItemLanguages = updatedItem.scheduledItem;

    Object.keys(scheduleItemLanguages).forEach((language) => {
      delete scheduleItemLanguages[language].uid;
    });

    return updatedItem;
  }

  /**
   * Updates images before we updated other entities
   * @private
   * @returns {Promise}
   */
  function _updateImages() {
    const deferred = $q.defer();
    const requests = [];

    const state = SetContentsStore.getState();
    const contentItemsWithImages = state.items
      .filter((item) => !!_getContentLanguage(item).imageFile)
      .map((item) => _getContentLanguage(item));

    const images = [...contentItemsWithImages, ...state.imagesToUpdate];

    images.map((item) => {
      requests.push(
        ImagesFactory.update(
          item.self,
          item,
          item.upload_image_url,
          item.imageFile
        )
      );
    });

    if (requests.length) {
      $q.all(requests).then(() => {
        deferred.resolve("");
      });
    } else {
      deferred.resolve("");
    }

    return deferred.promise;
  }

  /**
   *
   */
  function _updateDynamicObject() {
    const contentItems = SetContentsStore.getState()
      .items.filter((item) => item.type === "computed-scheduled-items")
      .map((item) => item.contentItem[item.displayedLanguage]);

    contentItems.forEach((item) =>
      BatchRequestFactory.createPutRequest("dynamic-object", item.self, item)
    );

    return BatchRequestFactory.process();
  }

  /**
   * Create image objects
   * @return {Promise}
   * @private
   */
  function _createImages() {
    const deferred = $q.defer();
    const state = SetContentsStore.getState();
    const requests = [];

    if (state.imagesToCreate) {
      state.imagesToCreate.map((image) => {
        requests.push(
          ImagesFactory.createImageWithAlwaysSchedule(
            _.omit(image, ["uid", "imageFile", "imagePreview"]),
            image.imageFile
          )
        );
      });
    }

    if (requests.length) {
      $q.all(requests).then(() => {
        deferred.resolve("");
      });
    } else {
      deferred.resolve("");
    }

    return deferred.promise;
  }

  /**
   * Save the scheduled items that have been modified
   * @return {Promise}
   * @private
   */
  function _saveScheduledItems() {
    const state = SetContentsStore.getState();
    const itemsToCreate = state.items
      .filter((item) => item.hasBeenAdded)
      .map(_removeUidFromScheduledItem);
    const itemsToUpdate = state.items.filter((item) => !item.hasBeenAdded);
    const endpointForCreation = `${state.set.self}items/`;

    if (state.removedItemUrls && state.removedItemUrls.length) {
      SetsFactory.addDELETEScheduledItemsToBatch(state.removedItemUrls);
    }

    if (itemsToCreate.length) {
      SetsFactory.addPOSTScheduledItemsToBatch(
        itemsToCreate,
        endpointForCreation
      );
      languageVersionsToCreate = itemsToCreate.filter(
        (item) => Object.keys(item.scheduledItem).length > 1
      );
    }

    SetsFactory.addPUTScheduledItemsToBatch(itemsToUpdate);

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.saveSetData,
    });

    return BatchRequestFactory.process();
  }

  /**
   * Create language versions for all newly created content
   * @param {Object} batchResponse
   * @return {Promise}
   * @private
   */
  function _createLanguageVersion(batchResponse) {
    const itemsToAddToBatch = [];
    languageVersionsToCreate.forEach((item) => {
      const createdItem = BatchRequestFactory.parseResponse(
        batchResponse,
        `new.${_getContentLanguage(item).uid}`
      )[0];

      itemsToAddToBatch.push({
        ...item,
        uid: createdItem.uid,
        self: createdItem.self,
      });
    });

    languageVersionsToCreate.length = 0;

    if (itemsToAddToBatch.length) {
      SetsFactory.addPUTScheduledItemsToBatch(itemsToAddToBatch);
    }

    return BatchRequestFactory.process();
  }

  /**
   * Request the set type so we can get config
   * @private
   */
  function _requestSetType() {
    return SetsFactory.getSetType();
  }

  /**
   * Set saving state
   * @private
   */
  function _setSavingState() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.setSavingState,
    });
  }

  /**
   * remove saving state
   * @private
   */
  function _unsetSavingState() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.unsetSavingState,
    });
  }

  /**
   * Set the loading state
   * @private
   */
  function _setLoadingState() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.setLoadingState,
    });
  }

  /**
   * Remove the loading state
   * @private
   */
  function _unsetLoadingState() {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.unsetLoadingState,
    });
  }

  /**
   * Action creator for fetching data and loading set contents.
   */
  factory.fetchSetData = () => {
    if (!SetContentsStore.getState().isLoading) {
      SetContentsStore.resetData();

      _setLoadingState();
      _requestSetType().then((set) => {
        _requestConfig(set.set_type_slug).then((config) => {
          _receiveConfig(config);
          _requestSet().then((setResponse) => {
            _receiveSet(setResponse);
            ItemDecorationActions.decorateItems();
            _requestSupportingData().then((batchResponse) => {
              _receiveItemLanguageVersions(batchResponse);
              _receiveSchedules(batchResponse);
              _requestAvailableLanguages().then((availableLanguages) => {
                _receiveAvailableLanguages(availableLanguages);
                _requestPreviewData().then((previewData) => {
                  _receivePreviewData(previewData.objects);
                  ItemDecorationActions.assignAppearanceStatus();
                  ItemDecorationActions.assignActiveStatus();
                  SortingActions.sortItems();
                  DisplayTitleActions.generateDisplayTitles();
                  PreviewActions.fetchPreviewItems();
                  _unsetLoadingState();
                  _unsetSavingState();
                });
              });
            });
          });
        });
      });
    }
  };

  /**
   * Action creator for saving data
   */
  factory.saveSetData = () => {
    if (!SetContentsStore.getState().isSaving) {
      _setSavingState();
      _updateImages().then(() => {
        _updateDynamicObject().then(() => {
          _saveScheduledItems().then((batchResponse) => {
            _createImages().then(() => {
              _createLanguageVersion(batchResponse)
                .then(() => {
                  const setTitle = SetContentsStore.getState().set.title;
                  NotificationService.notifyInfo(
                    NotificationMessage.saveSuccess(setTitle)
                  );
                  factory.fetchSetData();
                })
                .catch(() => {
                  NotificationService.notifyRefresh();
                });
            });
          });
        });
      });
    }
  };

  return factory;
}

export default AsyncSetActions;
