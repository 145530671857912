const SET_CONTENTS_ACTIONS = {
  requestSet: "requestSet",
  receiveSet: "receiveSet",
  requestConfig: "requestConfig",
  receiveConfig: "receiveConfig",
  sendEntityRequest: "sendEntityRequest",
  receiveEntity: "receiveEntity",
  requestSupportingData: "requestSupportingData",
  receiveSchedules: "receiveSchedules",
  requestAvailableLanguages: "requestAvailableLanguages",
  receiveAvailableLanguages: "receiveAvailableLanguages",
  assignConfigToItems: "assignConfigToItems",
  togglePreviewMode: "togglePreviewMode",
  toggleEditMode: "toggleEditMode",
  sortPlaced: "sortPlaced",
  sortUnplaced: "sortUnplaced",
  sortExpired: "sortExpired",
  correctPositions: "correctPositions",
  addItem: "addItem",
  updateItem: "updateItem",
  addLanguage: "addLanguage",
  removeScheduledItem: "removeScheduledItem",
  removeMultipleScheduledItems: "removeMultipleScheduledItems",
  positionItem: "positionItem",
  discardChanges: "discardChanges",
  updateImageObject: "updateImageObject",
  setImageToUpdate: "setImageToUpdate",
  setImageToCreate: "setImageToCreate",
  requestCSIPreviewData: "requestCSIPreviewData",
  receiveCSIPreviewData: "receiveCSIPreviewData",
  generateDisplayPositions: "generateDisplayPositions",
  requestPreviewItems: "requestPreviewItems",
  receivePreviewItems: "receivePreviewItems",
  requestDisplayTitles: "requestDisplayTitles",
  generateDisplayTitles: "generateDisplayTitles",
  requestDisplayTitle: "requestDisplayTitle",
  generateDisplayTitle: "generateDisplayTitle",
  updateItems: "updateItems",
  setSavingState: "setSavingState",
  unsetSavingState: "unsetSavingState",
  setLoadingState: "setLoadingState",
  unsetLoadingState: "unsetLoadingStatess",
};

export default SET_CONTENTS_ACTIONS;
