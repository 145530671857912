import ApiConstant from "./api.constant";

/**
 * creating a default action for api requests
 * @param {string} type
 * @param {Promise} promise
 * @returns {{api: {failureType: string, promise: *, successType: string}, type: string}}
 */
const createApiAction = (type, promise) => ({
  type: ApiConstant.getRequestType(type),
  api: {
    promise,
    failureType: ApiConstant.getFailureType(type),
    successType: ApiConstant.getSuccessType(type),
  },
});

export { createApiAction };
