import { createSelector } from "reselect";
import { getEntity } from "store/entity/entity.selector";
import { filterAccountsWithUpload } from "./filter-accounts-with-upload";

/**
 * filtering ovps which are ingested
 * @param {Object} state
 * @param {String} entity
 * @param {String} entityType
 * @returns {Array}
 */
export const filterIngestedVersions = createSelector(
  [filterAccountsWithUpload, getEntity],
  (accountsWithUpload, { data: { ovps } }) => {
    if (Array.isArray(ovps)) {
      return ovps.filter(
        (ovp) =>
          !accountsWithUpload.some(
            (account) => account.self === ovp.account_url
          )
      );
    }

    return [];
  }
);
