import template from "./loading.html";

/**
 * LoadingDirective
 * Manages the view for the loading state widget and communicates with the lower-level
 * loading service.
 * NOTE: keep in mind that tabbed views containers NEED a tab-container class and a
 * ng-hide="loading" directive for animations to work.
 */

class LoadingDirectiveCtrl {
  /**
   * @constructor
   * @param {Object} $transitions
   * @param {Object} LoadingService
   */
  constructor($transitions, LoadingService) {
    this.$transitions = $transitions;
    this.LoadingService = LoadingService;
    this.type = undefined;
  }

  /**
   * Toggles the loading state
   * @access public
   * @param {Object} toState
   * @param {Object} fromState
   * @returns {void}
   */
  toggleLoadingState(toState, fromState) {
    this.LoadingService.inlineLoader(false);
    this.type =
      toState.parent && toState.parent === fromState.parent
        ? "section"
        : "page";
    this.LoadingService.loadingStart(toState, this.type);
  }
}

/**
 *
 * @returns {{
 *  restrict: string,
 *  template,
 *  controllerAs: string,
 *  controller: Object,
 *  link: function
 * }}
 */
function loadingDirective() {
  return {
    restrict: "E",
    template,
    controllerAs: "loadingWidget",
    controller: LoadingDirectiveCtrl,
    link: (scope) => {
      scope.loadingWidget.$transitions.onStart({}, (transition) =>
        scope.loadingWidget.toggleLoadingState(
          transition.to(),
          transition.from()
        )
      );
    },
  };
}

export default loadingDirective;
