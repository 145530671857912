import angular from "angular";
import ContentRoute from "./router/content";
import ErrorRoute from "./router/error";
import HomeRoute from "./router/home";
import LogoutRoute from "./router/logout";
import SchedulesRoute from "./router/schedules";
import TagsRoute from "./router/tags";
import UsersRoute from "./router/users";
import ResetPasswordRoute from "./router/reset-password";
import CustomObjectsRoute from "./router/custom";

const Router = angular.module("Skylark.Router", [
  ContentRoute.name,
  ErrorRoute.name,
  HomeRoute.name,
  LogoutRoute.name,
  SchedulesRoute.name,
  TagsRoute.name,
  UsersRoute.name,
  ResetPasswordRoute.name,
  CustomObjectsRoute.name,
]);

export default Router;
