import uuidv4 from "uuid/v4";
import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./credits-module.html";

class CreditsDirectiveCtrl {
  /**
   * @constructor
   * @param   {Object} _
   * @param   {Object} $state
   * @param   {Object} MessageService
   * @param   {Object} ModulePagingService
   * @param   {Object} NotificationService
   * @param   {Object} CreditsFactory
   * @param   {Object} EntityFactory
   * @param   {Object} $stateParams
   * @param   {Object} ModalTriggerService
   */
  constructor(
    _,
    $state,
    MessageService,
    ModulePagingService,
    NotificationService,
    CreditsFactory,
    EntityFactory,
    $stateParams,
    ModalTriggerService
  ) {
    this._ = _;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.MessageService = MessageService;
    this.ModulePagingService = ModulePagingService;
    this.NotificationService = NotificationService;
    this.EntityFactory = EntityFactory;
    this.CreditsFactory = CreditsFactory;
    this.ModalTriggerService = ModalTriggerService;

    this.type = "people";
    this.types = undefined;

    this.availableChannelNames = {
      add: "Modal.Save.credits.base",
    };

    this.paginationSettings = {
      start: 0,
      limit: this.config.limit || 10,
    };

    this.setSubscriptions();
  }

  /**
   * @param {Object} config
   * @param {Object} data
   * @returns {void}
   */
  init() {
    this.credits = this._convertApiCreditsIntoCredits(this.data.credits);

    // Fetch latest credits while loading using the existing credits
    if (this.data?.self) {
      this.refreshCredits();
    }

    this.parentType = this.$stateParams.entity
      ? this.$stateParams.entity
      : this.$state.current.title;

    this.self = this.data.self;
    this.entity = {
      parentId: this.data.uid,
      parentType: this.parentType,
      parentTitle: this.data.title,
    };

    this.CreditsFactory.getRoles().then((data) => {
      this.types = data.objects;
      this.updateCreditsTypes();
    });

    this.creditsToDisplay = [];

    this.changePage();
  }

  /**
   * listen for events from other directives and controllers
   * @returns {void}
   */
  setSubscriptions() {
    this.MessageService.subscribe(
      this.availableChannelNames.add,
      (channel, data) => this.addCredits(data)
    );
  }

  /**
   * removing subscriptions
   * @returns {void}
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.availableChannelNames.add);
  }

  /**
   * add credits to dom
   * @param {object} data
   * @returns {void}
   */
  addCredits(data) {
    data.forEach((obj) => {
      const credits = {
        uid: uuidv4(),
        people_url: obj.self,
        role_url: obj.role || "",
        character: "",
        index: "",
      };
      this.data.credits.push(credits);
      this.credits.push(credits);
    });

    let message = "You have successfully added multiple credits.";

    if (data.length === 1) {
      message = "Successfully added credit.";
    }

    this.saveCredits(message);

    this.MessageService.publish(`Pagination.${this.type}`, this.count);
  }

  /**
   * Update dom with changes to credits data
   * @param {object} credits
   * @returns {void}
   */
  updateCredit(credits) {
    const indexOfCredits = this._.findIndex(
      this.credits,
      (obj) => obj.uid === credits.uid
    );

    this.credits[indexOfCredits] = credits;
    this.data.credits[indexOfCredits] = credits;

    this.saveCredits("Successfully updated credit.");
  }

  /**
   * remove a credit from dom and update api
   * @param {object} data
   * @returns {void}
   */
  removeCredit(data) {
    const filteredCredits = this.credits.filter(({ uid }) => data.uid !== uid);
    this.data.credits = this._convertCreditsIntoApiCredits(filteredCredits);

    this.EntityFactory.updateByUrl(this.data.self, this.data)
      .then((response) => {
        this.data = angular.extend({}, response);
        this.credits = filteredCredits;
        this.changePage();

        this.NotificationService.notifyInfo("Successfully removed credit.");
      })
      .catch(() =>
        this.NotificationService.notifyError("Error removing credit.")
      );
  }

  /**
   * send message to update roles in child isolated scopes
   * @returns {void}
   */
  updateCreditsTypes() {
    this.MessageService.publish("updateRoles", {});
  }

  /**
   * push credits data to api
   * @param {sting} message
   * @returns {void}
   */
  saveCredits(message) {
    this.data.credits = this._convertCreditsIntoApiCredits(this.credits);

    this.EntityFactory.updateByUrl(this.data.self, this.data)
      .then((response) => {
        this.data = angular.extend({}, response);
        this.updateCreditsTypes();
        this.credits = this._convertApiCreditsIntoCredits(this.data.credits);
        this.NotificationService.notifyInfo(message);
        this.changePage();
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.saveCreditsError
        )
      );
  }

  /**
   * Fetches the latest credits
   */
  refreshCredits() {
    this.EntityFactory.getByUrl(this.data.self)
      .then((response) => {
        this.data = angular.extend({}, response);
        this.updateCreditsTypes();
        this.credits = this._convertApiCreditsIntoCredits(this.data.credits);
        this.changePage();
      })
      .catch(() =>
        this.NotificationService.notifyError("Error refreshing credits.")
      );
  }

  /**
   * pagination change
   * @param {Number} start
   * @param {Number} limit
   */
  paginationChange(start, limit) {
    this.paginationSettings.start = start;
    this.paginationSettings.limit = limit;
    this.changePage();
  }

  /**
   * Changes the page in the modal
   * @returns {void}
   */
  changePage() {
    this.count = this.credits.length;
    this.MessageService.publish(`Pagination.${this.type}`, this.count);
    this.creditsToDisplay.length = 0;
    this.creditsToDisplay = this.ModulePagingService.urlsToGet(
      this.credits,
      this.paginationSettings.start,
      this.paginationSettings.limit
    );
  }

  /**
   * trigger Modal for adding more items
   */
  triggerModal() {
    const options = {
      entity: this.type,
      channels: { add: "Modal.Save.credits.base" },
    };

    this.ModalTriggerService.triggerList(this.type, options);
  }

  /**
   * Converts the Credits array received from the API
   * @param {*} apiCredits the credit object returned from the API
   * @returns the credits object parsed with a uid added to track the object between components
   */
  _convertApiCreditsIntoCredits(apiCredits) {
    if (!apiCredits) {
      return [];
    }

    return apiCredits.map((apiCredit) => ({
      ...apiCredit,
      uid: uuidv4(),
    }));
  }

  /**
   * returns an object containing only the parameters that the API cares about
   */
  _convertCreditsIntoApiCredits(credits) {
    if (!credits) {
      return [];
    }

    return credits.map(({ people_url, role_url, character, index }) => ({
      people_url,
      role_url,
      character,
      index,
    }));
  }
}

/**
 * Credits directive
 * @returns {Object} directive
 */
function creditsDirective() {
  return {
    scope: {},
    bindToController: {
      data: "<",
      config: "=",
      isCreating: "=",
    },
    controller: CreditsDirectiveCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.init();
    },
  };
}

export default creditsDirective;
