export default {
  buttons: [
    [
      {
        value: "BOLD",
        icon: "editor-bold",
        target: "selection",
      },
      {
        value: "ITALIC",
        icon: "editor-italic",
        target: "selection",
      },
      {
        value: "UNDERLINE",
        icon: "editor-underline",
        target: "selection",
      },
      {
        value: "LINK",
        icon: "link",
        target: "link",
      },
    ],
    [
      {
        value: "unordered-list-item",
        icon: "editor-ul",
        target: "block",
        isEmptyInsertion: true,
      },
      {
        value: "ordered-list-item",
        icon: "editor-ol",
        target: "block",
        isEmptyInsertion: true,
      },
    ],
  ],
};
