export const ALWAYS_SCHEDULE_SLUG = "/api/schedules/always/";

export const COOKIE_NAMES = {
  GLOBAL_LANGUAGE: "skylark.GlobalLanguage",
  GLOBAL_FILTERS: "GlobalFilters",
};

export const ELEMENT_METADATA = {
  LOCAL_NAV_HEIGHT: 48,
};

export const SUPPORTED_FILE_TYPES = new RegExp(
  /image\/(gif|jpg|jpeg|png|webp|jp2)$/i
);

export const CORE_ENTITIES = [
  "sets",
  "brands",
  "seasons",
  "episodes",
  "channels",
  "schedules",
  "tags",
  "talent",
  "assets",
  "articles",
  "cms-articles",
  "links",
  "licensing",
  "editorial-schedules",
  "credits",
];
