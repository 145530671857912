import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import styles from "./image-playback.scss";

const ImagePlayback = ({ src }) => {
  const imageRef = useRef(null);
  const canvasRef = useRef(null);
  const overlayRef = useRef(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const generateCanvas = () => {
    const { clientWidth, clientHeight } = imageRef.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.setAttribute("width", clientWidth);
    canvas.setAttribute("height", clientHeight);

    setWidth(clientWidth);
    setHeight(clientHeight);

    ctx.drawImage(imageRef.current, 0, 0);
  };

  return (
    <div className={styles["image-playback-container"]}>
      <div
        ref={overlayRef}
        style={{ width, height }}
        className={styles["image-playback-overlay"]}
      >
        <SkylarkIcon iconName="play" />
      </div>
      <img ref={imageRef} src={src} alt="" onLoad={generateCanvas} />
      <canvas ref={canvasRef} />
    </div>
  );
};

ImagePlayback.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImagePlayback;
