/**
 *  featureToggler
 *  @fileOverview renders components depending on
 *  CMS release status (only alpha or stable for now).
 *
 */

class FeatureTogglerCtrl {
  /**
   * @constructor
   * @param {Object} FeatureToggleService
   * @param {Object} FEATURE_TOGGLE_CONSTANTS
   * @property {string} feature  - feature name
   */
  constructor(FeatureToggleService, FEATURE_TOGGLE_CONSTANTS) {
    this.FeatureToggleService = FeatureToggleService;
    this.FEATURE_TOGGLE_CONSTANTS = FEATURE_TOGGLE_CONSTANTS;
  }

  /**
   * bindParams
   * @param {string} featureName - feature name
   * @returns {void}
   */
  bindParams(featureName) {
    this.featureName = featureName;

    this.isFeatureAvailable =
      this.FeatureToggleService.isFeatureEnabled(featureName);
  }
}

/**
 * featureTogglerDirective
 * @returns {Object} - directive definition object
 */
function featureTogglerDirective() {
  return {
    restrict: "A",
    scope: true,
    controller: FeatureTogglerCtrl,
    controllerAs: "featureToggle",
    link: (scope, element, attrs) => {
      scope.featureToggle.bindParams(attrs.feature);
    },
  };
}

export default featureTogglerDirective;
