import React from "react";
import PropTypes from "prop-types";

import {
  ImageBlock,
  MediaPlaceholderBlock,
  VideoBlock,
} from "./components/block-types";

/**
 * renderMediaBlock
 * @param  {String} type
 * @return {JSX}
 */
function renderMediaBlock(mediaType, data, props) {
  switch (mediaType.toLowerCase()) {
    case "image":
      return <ImageBlock {...props} data={data} />;

    case "media-placeholder":
      return <MediaPlaceholderBlock {...props} />;

    case "video":
      return <VideoBlock {...props} data={data} />;

    default:
      return null;
  }
}

/**
 * MediaBlock
 * @description in firefox, anything that has contentEditable set to true displays resizable
 * controls. Disabling object resizing fixes it.
 * @param {Object} props
 * @returns {JSX}
 */
export default function MediaBlock(props) {
  document.execCommand("enableObjectResizing", false, "false");

  const entity = props.contentState.getEntity(props.block.getEntityAt(0));

  return renderMediaBlock(
    entity.getData().contentType,
    entity.getData(),
    props
  );
}

MediaBlock.propTypes = {
  contentState: PropTypes.shape({
    getEntity: PropTypes.func,
  }).isRequired,
  block: PropTypes.shape({
    getEntityAt: PropTypes.func,
  }).isRequired,
};
