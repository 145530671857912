import template from "views/widgets/type.html";

class TypeCtrl {
  constructor($scope) {
    this.item = $scope.item;
  }
}

function typeDirective() {
  return {
    restrict: "E",
    scope: {
      item: "=",
    },
    template,
    controller: TypeCtrl,
    controllerAs: "type",
  };
}

export default typeDirective;
