import React from "react";
import PropTypes from "prop-types";

/**
 * Image
 * @param {Object} props
 */
export default function Image(props) {
  return <img className={props.classes} src={props.src} alt="" />;
}

Image.propTypes = {
  src: PropTypes.string.isRequired,
  classes: PropTypes.string,
};

Image.defaultProps = {
  classes: "",
};
