import { Api } from "skylarklib/helpers";
import * as toasterActions from "store/toaster/toaster.actions";
import actionNames from "./article-status.actions.constants";

/**
 * requestArticleStatuses
 * @returns {Object}
 */
function _requestArticleStatuses() {
  return {
    type: actionNames.REQUEST_ARTICLE_STATUSES,
  };
}

/**
 * receiveArticleStatuses
 * @param   {Object} data
 * @returns {Object}
 */
function _receiveArticleStatuses(data) {
  return {
    type: actionNames.RECEIVE_ARTICLE_STATUSES,
    data,
  };
}

/**
 * fetchArticleStatuses
 * @todo  implement window location pathname with routing
 * @returns {Function}
 */
function fetchArticleStatuses() {
  return (dispatch) => {
    dispatch(_requestArticleStatuses());

    return Api.get("/api/article-status/")
      .then((data) => dispatch(_receiveArticleStatuses(data.data)))
      .catch(() => dispatch(toasterActions.popCatastrophicErrorToast()));
  };
}

export { fetchArticleStatuses };
