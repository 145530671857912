export const ComponentStyle = {
  variants: {
    launcherPad: {
      dialogContainer: {
        justifyContent: "flex-start",
      },
    },
  },
};

export default ComponentStyle;
