/**
 * @class ImageUploaderCtrl
 * @description Main controller for the image upload directive
 */
class ImageUploaderCtrl {
  /**
   * Constructor for the class
   * @param {Object} $scope - angular scope service
   * @param {Object} MessageService - Skylark.lib MessageService for pub/sub
   * @returns {void}
   */
  constructor($scope, MessageService) {
    this.$scope = $scope;
    this.MessageService = MessageService;

    if (this._hasExistingPreview()) {
      this.filePreview = this.$scope.module.data.imagePreview;
    }
  }

  /**
   * Check if item already has a image file preview to display
   * @return {boolean}
   * @private
   */
  _hasExistingPreview() {
    return !!(
      this.$scope.module &&
      this.$scope.module.data &&
      this.$scope.module.data.imagePreview
    );
  }

  /**
   * Uses scope to get the uid of this item. As we have no isolated scope (and cant pass uid
   * attribute when it's rendered by the dynamicField directive) this has become the only way to
   * get it's context uid.
   * @returns {string}
   */
  getUidFromScope() {
    return this.$scope.$parent.item.uid && this.$scope.$parent.item.uid.length
      ? this.$scope.$parent.item.uid
      : this.$scope.$parent.module.uid;
  }

  /**
   * Sends file data to the parent. If item have a UID then it is in a modal
   * @returns {void}
   */
  updateParent() {
    const imageData = {
      file: this.file,
      filePreview: this.filePreview,
    };

    if (this.getUidFromScope()) {
      this.MessageService.publish(
        `ImageUploader.${this.getUidFromScope()}`,
        imageData
      );
    } else {
      this.MessageService.publish("ImageUploader", imageData);

      window.URL.revokeObjectURL(this.file);
      this.filePreview = undefined;
      this.file = undefined;
    }
  }

  /**
   * Checks if the file type is image.
   * @param {object} file - the file that was uploaded
   * @returns {boolean} - is it type of image?
   */
  isImageFile(file) {
    return file.type.match("image.*");
  }

  /**
   * Handler for the file upload field. Gets the files and sets it.
   * @access public
   * @param {object} elem - an file upload input element
   * @returns {void}
   */
  handleFileSelect(elem) {
    this.setFile(elem);
  }

  /**
   * Sets the file to object.
   * @access private
   * @param {object} file - the file that was uploaded through the file uploader
   * @returns {void}
   */
  setFile(element) {
    this.file = element.files[0];

    if (this.isImageFile(this.file)) {
      this.filePreview = window.URL.createObjectURL(this.file);
      this.updateParent();
      element.value = "";

      // we must force a digest cycle here for the image to update
      this.$scope.$apply();
    } else {
      window.alert("You must select an image to upload");
    }
  }

  /**
   * Remove the image
   * @returns {void}
   */
  remove() {
    const removeChannel = this.getUidFromScope()
      ? `ImageUploader.Remove.${this.getUidFromScope()}`
      : "ImageUploader.Remove";
    this.MessageService.publish(removeChannel);
    this.filePreview = undefined;
  }
}

/**
 * The directive configuration function
 * @returns {object} - the directive config
 */
function imageUploaderDirective() {
  return {
    restrict: "A",
    controller: ImageUploaderCtrl,
    controllerAs: "uploader",
    link: (scope, element) => {
      // use attrs for custom events
      element.on("dragenter", eventDefaultHelper);
      element.on("dragover", eventDefaultHelper);
      element.on("drop", drop);

      /**
       * Default event callback for all events. Stops all default behaviour and propagations
       * @param {object} event - the javascript event object
       * @returns {void}
       */
      function eventDefaultHelper(event) {
        event.stopPropagation();
        event.preventDefault();
      }

      /**
       * Callback for drop events
       * @param {object} event - the javascript event object
       * @returns {void}
       */
      function drop(event) {
        const { dataTransfer } = event.originalEvent;
        eventDefaultHelper(event);
        scope.uploader.setFile(dataTransfer);
      }
    },
  };
}

export default imageUploaderDirective;
