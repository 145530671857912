import NotificationConstant from "./notification.constant";

const showMessage = (message) => ({
  type: NotificationConstant.SHOW_INFO_TOAST,
  message,
});

const showError = (message) => ({
  type: NotificationConstant.SHOW_ERROR_TOAST,
  message,
});

const removeToast = () => ({
  type: NotificationConstant.REMOVE_TOAST,
});

export { showMessage, showError, removeToast };
