function ContentFilter(momentJS, DATE_FORMATTING) {
  const factory = {};
  /*
   * store moment formatting and, as we're adding an unconfigured, outside of a loop
   * item we just need a mechanism to cover us in case there happen to be two content filters added to a given page
   * therefore needing unique ID's to house the date picker calendars
   */
  const _format = DATE_FORMATTING.format;
  const uid = Math.floor(Math.random() * (100000000000 - 10000000)) + 1000000;

  factory.config = {
    index: 0,
    uid,
    field: {
      start: {
        name: "starts",
      },
      end: {
        name: "ends",
      },
    },
  };

  factory.rawFilters = [
    {
      date: momentJS(),
      name: "Today",
    },
    {
      date: momentJS().add(1, "day"),
      name: "Tomorrow",
    },
    {
      date: momentJS().add(1, "week"),
      name: "Next Week",
    },
    {
      date: momentJS().add(1, "month"),
      name: "Next Month",
    },
  ];

  factory.createFilter = (name, date) => {
    const filter = {};

    filter.display_name = name;
    filter.momentDate = date;
    filter.prettyDate = factory.formatDate(date);

    return filter;
  };

  factory.getQueryParam = (date) => {
    const query = `pub_date:[${date}]`;

    return query;
  };

  factory.formatDate = (date) => momentJS(date).format(_format);

  factory.setFilterUid = (uid) => (factory.config.uid = uid);

  return factory;
}

export default ContentFilter;
