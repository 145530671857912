/**
 * First character of string to upper case
 * example: this is a title -> This is a title
 * @param _
 * @returns {Function}
 */
function capitalise(_) {
  return (input) => {
    if (!input) {
      return "";
    }

    return _.upperFirst(input);
  };
}

export default capitalise;
