import angular from "angular";
import dynamicFieldDirective from "./dynamic-field.component";
import DYNAMIC_FIELD_TYPES from "./dynamic-field-types.constants";
import DYNAMIC_FIELD_VALIDATION_REG_EXP from "./dynamic-field-validation.constants";

const DynamicField = angular
  .module("Skylark.Components.Fields.DynamicField", [])
  .directive("dynamicField", dynamicFieldDirective)
  .constant("DYNAMIC_FIELD_TYPES", DYNAMIC_FIELD_TYPES)
  .constant(
    "DYNAMIC_FIELD_VALIDATION_REG_EXP",
    DYNAMIC_FIELD_VALIDATION_REG_EXP
  );

export default DynamicField;
