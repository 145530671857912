class LanguagesService {
  /**
   * @constructor
   * @param {Object} StoreFactory
   * @param {Object} DimensionsFactory
   * @param {Object} $q
   */
  constructor(StoreFactory, DimensionsFactory, $q) {
    this.StoreFactory = StoreFactory;
    this.DimensionsFactory = DimensionsFactory;
    this.$q = $q;
    this.isProcessing = false;

    this.languagesStore = this.StoreFactory.createInstance(
      this.DimensionsFactory.getDimensionByType
    );
  }

  /**
   * get the list of languages in system
   * @public
   * @returns {Promise}
   */
  getLanguages() {
    return this.languagesStore.getData("languages");
  }

  /**
   * getLanguage
   * @param   {string} isoCode - a language iso code
   * @returns {Promise}
   */
  getLanguage(isoCode) {
    return this.languagesStore
      .getData("languages")
      .then((languages) => this._getLanguageObject(languages, isoCode));
  }

  /**
   * _getLanguageObject
   * @param   {Array} languageList
   * @param   {String} languageISOCode
   * @returns {Object}
   */
  _getLanguageObject(languageList, languageISOCode) {
    return languageList.find(
      (language) => language.iso_code === languageISOCode
    );
  }
}

export default LanguagesService;
