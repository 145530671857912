import template from "./modal-display-list.html";

class ModalDisplayListCtrl {
  /**
   * @constructor
   */
  constructor(EntityFactory, ChangelogService, MessageService) {
    this.EntityFactory = EntityFactory;
    this.ChangelogService = ChangelogService;
    this.MessageService = MessageService;
  }

  /**
   * $onInit
   */
  $onInit() {
    this._getData();
    this.isReadOnly = true;
  }

  /**
   * getData
   */
  _getData() {
    this.ChangelogService.getLog(
      this.options.defaultQueryParams.object,
      this.config,
      this.options.infoConfig,
      this.options.entityName
    ).then((data) => {
      this.data = data;
    });
  }

  /**
   * destroyModal
   */
  close() {
    this.destroyModal();
  }
}

const modalDisplayListComponent = {
  controller: ModalDisplayListCtrl,
  bindings: {
    config: "<",
    options: "<",
    instanceUid: "@",
    destroyModal: "&",
  },
  controllerAs: "modal",
  template,
};

export default modalDisplayListComponent;
