/**
 * Accordion
 * @deprecated
 * @author Alexander Vince <alex.vince@ostmodern.co.uk>
 * @copyright 2015 OstModern
 *
 * @description Renders breadcrumbs
 */

/**
 * Component definition
 * @param {object} UtilitiesService
 * @param {object} $timeout
 * @returns {object} Directive config
 */
function accordionDirective(UtilitiesService, $timeout) {
  return {
    scope: true,
    link(scope, element, attrs) {
      attrs.$observe("isDisabled", (disabled) => {
        const disabledAsBoolean = !UtilitiesService.toBoolean(disabled);

        // if the last item is removed, thus disabling the accordion then the accordion should close
        if (disabledAsBoolean && scope.isOpen) {
          scope.toggle();
        }
        scope.isDisabled = disabledAsBoolean;
      });

      attrs.$observe("isLocked", (locked) => {
        const lockedAsBoolean = UtilitiesService.toBoolean(locked);

        // if the last item is removed, thus disabling the accordion then the accordion should close
        if (lockedAsBoolean && scope.isOpen) {
          scope.toggle();
        }
        scope.isLocked = lockedAsBoolean;
      });

      // this is so, so dirty, but avoids jank

      attrs.$observe("isOpenByDefault", (val) => {
        if (UtilitiesService.toBoolean(val)) {
          $timeout(() => {
            scope.isCollapsed = false;
          }, 800);
        }
      });
      scope.isCollapsed = attrs.isCollapsed;
    },
  };
}

/**
 * accordionGroupDirective
 * @param   {Object} $animate
 * @param   {Object} $timeout
 * @returns {object} Directive definition object
 */
function accordionGroupDirective($animate, $timeout) {
  return {
    link(scope, element, attrs) {
      scope.toggle = () => {
        // if currently animating
        if (scope.isAnimating || scope.isDisabled || scope.isLocked) {
          return;
        }

        // toggle
        scope.isCollapsed = !scope.isCollapsed;
      };

      /**
       * expand
       * @returns {void}
       */
      function expand() {
        scope.isAnimating = true;

        element.css({
          height: 0,
        });
        element.removeClass("collapsed").addClass("collapsing");
        const contentHeight = element[0].scrollHeight;

        element.css({
          height: `${contentHeight}px`,
        });

        $timeout(() => {
          expandDone();
        }, 750);
      }

      /**
       * expandDone
       * @returns {void}
       */
      function expandDone() {
        element.removeClass("collapsing");
        element.css({
          height: "auto",
        });
        scope.isAnimating = false;
        scope.isOpen = true;

        // TODO: publish event that open
      }

      /**
       * collapse
       * The height must be set before adding "collapsing" class.
       * Otherwise, the browser attempts to animate from height 0 (in
       * collapsing class) to the given height here.
       * initially all panel collapse have the collapse class, this removal
       * prevents the animation from jumping to collapsed state
       * @returns {void}
       */
      function collapse() {
        scope.isAnimating = true;

        element.height(element[0].scrollHeight).addClass("collapsing");

        element.height(0);
        $timeout(() => {
          collapseDone();
        }, 750);
      }

      /**
       * collapseDone
       * @returns {void}
       */
      function collapseDone() {
        // element.css({height: '0'}); // Required so that collapse works when animation is disabled
        element.removeClass("collapsing");
        element.addClass("collapsed");
        scope.isAnimating = false;
        scope.isOpen = false;
        // TODO: publish event that closed
      }

      scope.$watch(attrs.accordionGroup, (shouldCollapse) => {
        if (shouldCollapse) {
          collapse();
        } else {
          expand();
        }
      });
    },
  };
}

export { accordionDirective, accordionGroupDirective };
