import React from "react";
import { react2angular } from "react2angular";
import withStoreProvider from "hocs/with-store-provider";
import SelectFilter from "../search/select-filter/select-filter.component";
import SearchBar from "../search/search-bar/search-bar.component";
import SearchResults from "../search/search-results/search-results.component";

/**
 * Container component for the home page.
 * It renders the whole search bar structure.
 */
const HomeSearch = () => (
  <div className="home-container">
    <h1>Welcome back.</h1>
    <h2>What are you looking for?</h2>
    <div className="search-container">
      <div className="search-bar-container">
        <SelectFilter />
        <SearchBar />
      </div>
      <SearchResults />
    </div>
  </div>
);

const reactHomeSearch = angular
  .module("Skylark.Components.React.HomeSearch", [])
  .component("homeSearch", react2angular(withStoreProvider(HomeSearch)));

export default HomeSearch;

export { reactHomeSearch };
