import angular from "angular";
import chosenDirective from "./chosen.directive";
import selectInputDirective from "./select-input.directive";

const DropdownSelect = angular
  .module("Skylark.Components.Fields.DropdownSelect", [])
  .directive("chosen", chosenDirective)
  .directive("selectInput", selectInputDirective);

export default DropdownSelect;
