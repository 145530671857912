import angular from "angular";
import * as TextTrackUploadAction from "store/text-track-upload/text-track-upload.action";
import * as EntityAction from "store/entity/entity.action";
import * as TextTrackSelector from "store/text-track-upload/text-track-upload.selector";
import template from "./text-tracks.html";

class TextTracksController {
  /**
   *
   * @param _
   * @param $ngRedux
   * @param ReduxConnector
   * @param TextTrackUploadService
   */
  constructor(_, $ngRedux, ReduxConnector, TextTrackUploadService) {
    this._ = _;
    this.$ngRedux = $ngRedux;
    this.ReduxConnector = ReduxConnector;
    this.TextTrackUploadService = TextTrackUploadService;

    this.connectToStore();
  }

  /**
   * init life cycle
   */
  $onInit() {
    this.TextTrackUploadService.recoverTextTracks(this.data.uid);

    this.autoSave =
      this.config.autoSave === undefined ? true : this.config.autoSave;
  }

  /**
   * initiating upload
   * @param url
   * @param file
   * @param filename
   * @return {Promise}
   */
  upload({ url, file, filename }) {
    this.addNewTextTrack = false;
    return this.TextTrackUploadService.upload({
      url,
      file,
      filename,
      entity: this.data,
    });
  }

  /**
   * add new text track
   */
  addTextTrack() {
    this.addNewTextTrack = true;
  }

  /**
   * Close the text track uploader
   */
  closeTextTrackUploader() {
    this.addNewTextTrack = false;
  }

  assetHasOvp() {
    return (
      __V8_ENVIRONMENT__ &&
      this.data &&
      this.data.ovps &&
      this.data.ovps.length > 0
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToStore = {
      ...TextTrackUploadAction,
      ...EntityAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToStore
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    return {
      textTracks: TextTrackSelector.getUploadsByEntityId(state, this.data.uid),
      usedLanguageCodes: TextTrackSelector.getLanguageCodes(
        state,
        this.data.uid
      ),
      usedDisplayLabels: TextTrackSelector.getDisplayLabels(
        state,
        this.data.uid
      ),
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

const TextTracks = angular
  .module("Skylark.Modules.TextTracks", [])
  .component("textTracks", {
    bindings: {
      data: "<",
      config: "<",
      account: "<",
      disableAdd: "<",
    },
    template,
    controller: TextTracksController,
    controllerAs: "module",
  });

export default TextTracks;
