import React from "react";
import { Box, Flex, Heading, Text, BoxProps } from "@chakra-ui/react";
import {
  SkylarkLogoIconBlue,
  SkylarkApiIcon,
  SkylarkClassicIconDark,
  SkylarkExperienceIcon,
  UserManagementIcon,
} from "@skylark/icons";
import { Link } from "../link";

export interface LauncherItemProps extends BoxProps {
  description?: string;
  href?: string;
  icon: string;
  title: string;
  variant?: string;
  isExternal?: boolean;
}

export const LauncherItem: React.FC<LauncherItemProps> = ({
  description,
  href,
  icon,
  title,
  variant,
  isExternal = false,
  ...rest
}) => {
  const variantCustomization = {
    imageHeight: "56px",
    imageMarginRight: "24px",
    headingFontSize: "20px",
    headingMarginBottom: "8px",
    alignSelf: "",
  };
  switch (variant) {
    case "secondary":
      variantCustomization.imageHeight = "44px";
      variantCustomization.headingFontSize = "16px";
      variantCustomization.headingMarginBottom = "4px";
      break;
    case "launch-pad":
      variantCustomization.imageHeight = "36px";
      variantCustomization.imageMarginRight = "16px";
      variantCustomization.headingFontSize = "13px";
      variantCustomization.headingMarginBottom = "0";
      variantCustomization.alignSelf = "center";
      break;
    default:
      break;
  }

  let Icon;
  switch (icon) {
    case "skylarkApi":
      Icon = SkylarkApiIcon;
      break;
    case "skylarkClassic":
      Icon = SkylarkClassicIconDark;
      break;
    case "skylarkExperience":
      Icon = SkylarkExperienceIcon;
      break;
    case "userManagement":
      Icon = UserManagementIcon;
      break;
    default:
      Icon = SkylarkLogoIconBlue;
      break;
  }

  const card = (
    <Flex cursor="pointer">
      <Icon
        alt={title}
        h={variantCustomization.imageHeight}
        mr={variantCustomization.imageMarginRight}
        w={variantCustomization.imageHeight}
      />
      <Box alignSelf={variantCustomization.alignSelf}>
        <Heading
          _groupHover={{
            textDecoration: "underline",
            textDecorationColor: "skylarkBlack",
          }}
          color="skylarkBlack"
          fontFamily={variant === "launch-pad" ? "Inter" : undefined}
          fontSize={variantCustomization.headingFontSize}
          mb={variantCustomization.headingMarginBottom}
          textAlign="left"
        >
          {title}
        </Heading>
        {description && (
          <Text color="skylarkBlack" size="sm" textDecoration="none">
            {description}
          </Text>
        )}
      </Box>
    </Flex>
  );

  return (
    <Box role="group" {...rest}>
      {/* Only wrap with Link when a href is given */}
      {href ? (
        <Link
          _hover={{ textDecoration: "none" }}
          display="block"
          hasExternalIcon={false}
          href={href}
          isExternal={isExternal}
        >
          {card}
        </Link>
      ) : (
        card
      )}
    </Box>
  );
};

export default { LauncherItem };
