import React from "react";
import { react2angular } from "react2angular";
import { LoadingScreen } from "@skylark/react";
import withChakraUiProvider from "hocs/with-chakra-ui-provider";

/**
 * Loading screen wrapper
 */
const LoadingScreenWrapper = () => <LoadingScreen variant="classic" />;

const reactLoadingScreen = angular
  .module("Skylark.Components.React.LoadingScreen", [])
  .component(
    "loadingScreen",
    react2angular(withChakraUiProvider(LoadingScreenWrapper))
  );

export default LoadingScreenWrapper;

export { reactLoadingScreen };
