import angular from "angular";
import PropTypes from "prop-types";
import React from "react";
import { react2angular } from "react2angular";
import { SkylarkClassicIconDark } from "@skylark/icons";
import useAngularInjection from "hooks/use-angular-injection";

const SkylarkIcon = ({ extraClassName, iconName }) => {
  const rootScope = useAngularInjection("$rootScope");

  // Some icons have rebrand alternatives (rebrand happened in V8)
  return rootScope &&
    rootScope.rebrandStyling &&
    iconName === "skylark-outline" ? (
    <SkylarkClassicIconDark
      className={`skylark-icon${extraClassName ? ` ${extraClassName}` : ""}`}
    />
  ) : (
    <svg
      className={`skylark-icon${extraClassName ? ` ${extraClassName}` : ""}`}
      role="presentation"
    >
      <use href={`#icon-${iconName}`} xlinkHref={`#icon-${iconName}`} />
    </svg>
  );
};

SkylarkIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  extraClassName: PropTypes.string,
};

const reactSkylarkIcon = angular
  .module("Skylark.Components.React.SkylarkIcon", [])
  .component("skylarkIcon", react2angular(SkylarkIcon));

export { reactSkylarkIcon };

export default SkylarkIcon;
