/**
 * Flats a specific navigation collection, moving every `item` on the higher level
 * in order to have a list of all the objects available in the CMS
 *
 * @param {array} collection
 * @param {array} array
 *
 * @return {void}
 */
export const flatCollection = (collection, array) => {
  /**
   * We don't want these entities to be searchable therefore we remove it from the collection
   *
   */
  const filters = [
    "editorial-schedules",
    "licensing",
    "tags",
    "cms-users",
    "customers",
  ];

  collection.forEach((item) => {
    const { items } = item;

    /**
     * Skip the entity if it's in the list
     *
     */
    if (!filters.includes(item.name)) {
      items ? flatCollection(items, array) : array.push(item);
    }
  });
};

/**
 * Flats the navigation collection if any data available
 * and maps it to a shape that is immediately usable by the react-select library
 *
 * @param {object} state
 * @return {object} map
 *
 */
export const getNavigation = (state) => {
  const { navigation } = state;
  const { data } = navigation;

  if (!data || !data.length) {
    return [];
  }

  const flattenMap = [];

  /**
   * Have all the entities on the first level of the structure and not in nested structures
   *
   */
  flatCollection(data, flattenMap);

  /**
   * Map entities to a label, value, configName structure to use it with the react-select library
   *
   */
  return flattenMap.map(({ display_name, name, type }) => {
    const map = {
      label: display_name,
      configName: name,
      value: name,
    };

    if (type) {
      return { ...map, value: type };
    }

    return map;
  });
};
