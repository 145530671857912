import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "components/_react/button/button";

import NotificationMessage from "skylarklib/constants/notification-text";
import styles from "./article-status.scss";

export default class ArticleStatus extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    fetchArticleStatuses: PropTypes.func.isRequired,
    updateEntity: PropTypes.func.isRequired,
    articleStatus: PropTypes.shape({
      data: PropTypes.array,
    }),
    entity: PropTypes.shape({
      data: PropTypes.shape({
        title: PropTypes.string.isRequired,
        status_url: PropTypes.string.isRequired,
        self: PropTypes.string.isRequired,
        schedule_urls: PropTypes.array.isRequired,
      }).isRequired,
    }).isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    articleStatus: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.updateStatus = this.updateStatus.bind(this);

    props.fetchArticleStatuses();
  }

  /**
   * setCurrentStatus
   * @param {Array} list
   */
  setCurrentStatus(list) {
    if (list) {
      this.currentStatus = this._findStatus(
        list,
        this.props.entity.data.status_url
      );
      this.buttonCopy =
        this.currentStatus.slug === "published" ? "Unpublish" : "Publish";
    }
  }

  /**
   * get new status
   * @returns {String}
   */
  _getNewStatus() {
    return this.currentStatus.slug === "published"
      ? this._findStatusUrl(this.props.articleStatus.data, "draft")
      : this._findStatusUrl(this.props.articleStatus.data, "published");
  }

  /**
   * _updateStatus
   */
  updateStatus() {
    this.props.updateEntity(
      this.props.entity.data.self,
      {
        status_url: this._getNewStatus(),
      },
      undefined,
      this._getSuccessMessage()
    );

    this.setCurrentStatus(this.props.articleStatus.data);
  }

  /**
   * success message
   * @return {function(*): string}
   * @private
   */
  _getSuccessMessage() {
    if (this.currentStatus.slug === "published") {
      return (data) => NotificationMessage.unpublishSuccess(data.title);
    }
    return (data) => NotificationMessage.publishSuccess(data.title);
  }

  /**
   * _findStatus
   * @param   {Array} list
   * @param   {Array} url
   * @returns {Object}
   */
  _findStatus(list, url) {
    return list.find((status) => status.self === url);
  }

  /**
   * _findStatusUrl
   * @param   {Array} list
   * @param   {String} slug
   * @returns {String} the status url
   */
  _findStatusUrl(list, slug) {
    return list.find((status) => status.slug === slug).self;
  }

  /**
   * isDisabled
   * @returns {Boolean}
   */
  isDisabled() {
    return (
      this.currentStatus.slug !== "published" &&
      !this.props.entity.data.schedule_urls.length
    );
  }

  /**
   * render
   * @returns {element}
   */
  render() {
    this.setCurrentStatus(this.props.articleStatus.data);

    return (
      <div>
        {this.currentStatus && (
          <div className={styles["article-status"]}>
            <div
              className={styles["article-status__current"]}
              data-test="article-publish-status"
            >
              Current Status: {this.currentStatus.title}
            </div>
            <div className={styles["article-status__button"]}>
              <Button
                action={this.updateStatus}
                buttonType="standard"
                copy={this.buttonCopy}
                id="article-status"
                isDisabled={this.isDisabled()}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
