import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./dropdown-list.scss";

/**
 * DropdownList
 */
export default class DropdownList extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    onSelect: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(PropTypes.object).isRequired,
    displayField: PropTypes.string.isRequired,
    isCollapsed: PropTypes.bool.isRequired,
    styling: PropTypes.string,
    current: PropTypes.string,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    displayField: "name",
    styling: "",
    current: null,
  };

  /**
   * onClick handler
   * @param   {Object} option
   * @returns {Function}
   */
  onClick = (option) => () =>
    !this.isCurrent(option) && this.props.onSelect(option);

  /**
   * onMousedown Handler
   * @param   {Event} event
   * @returns {Function}
   */
  onMouseDown = (event) => event.preventDefault();

  /**
   * checks if dropdown option is current selected
   * @param  {Object} option
   * @param  {Object} props
   * @returns {Boolean}
   */
  isCurrent(option) {
    return this.props.current === option[this.props.displayField];
  }

  /**
   * render component
   */
  render() {
    return (
      <div
        className={classnames(
          this.props.styling,
          styles["dropdown-list__options"],
          {
            [styles["dropdown-list__options--visible"]]: this.props.isCollapsed,
          }
        )}
      >
        <ul>
          {this.props.options.map((option) => (
            <li key={option[this.props.displayField]}>
              <div
                onClick={this.onClick(option)}
                onMouseDown={this.onMouseDown}
                role="option"
                data-test="dropdown-list-item"
                tabIndex={this.isCurrent(option) ? "-1" : "0"}
                aria-selected={this.isCurrent(option)}
                className={classnames(styles["dropdown-list__options-item"], {
                  [styles["dropdown-list__options-item--inactive"]]:
                    this.isCurrent(option),
                })}
              >
                <span className={styles["dropdown-list__options-text"]}>
                  {option[this.props.displayField]}
                </span>
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}
