/**
 * FeatureToggleService
 * @fileOverfiew
 * Determines whether any component in the CMS should be toggling between
 * flows due to breaking changes between major API versions.
 *
 *
 */

class FeatureToggleVersionService {
  /**
   * @constructor
   * @param   {Object}  $q
   * @param   {Object} SkylarkApiInfoService
   * @param   {Object} VERSION_FEATURE_TOGGLE_CONSTANTS
   */
  constructor($q, SkylarkApiInfoService, VERSION_FEATURE_TOGGLE_CONSTANTS) {
    this.$q = $q;
    this.SkylarkApiInfoService = SkylarkApiInfoService;
    this.VERSION_FEATURE_TOGGLE_CONSTANTS = VERSION_FEATURE_TOGGLE_CONSTANTS;
  }

  /**
   * isToggling
   * @returns {Boolean}
   */
  isToggling(featureName) {
    const deferred = this.$q.defer();

    this.SkylarkApiInfoService.getVersion()
      .then((version) => {
        const firstDot = version.indexOf(".");
        const currentVersion = parseInt(version.slice(0, firstDot));

        deferred.resolve({
          isToggling:
            currentVersion >=
            this.VERSION_FEATURE_TOGGLE_CONSTANTS[featureName].version,
        });
      })
      .catch((err) => deferred.reject(err));

    return deferred.promise;
  }
}

export default FeatureToggleVersionService;
