import template from "./modal-create-button.html";

/**
 * @fileOverview Modal Create Button
 */
/**
 * @memberof  Components.Modal
 * @classdesc Renders a create button inside list modals, to trigger a create entity modal.
 * Note that saving from the edit modal will close the original list modal using the passed in close function,
 * which is equivalent to a cancel.
 */
class ModalCreateButtonCtrl {
  /**
   * @constructor
   * @param   {Object} ConfigurationFactory
   * @param   {Object} MessageService
   */
  constructor(ConfigurationFactory, MessageService) {
    this.ConfigurationFactory = ConfigurationFactory;
    this.MessageService = MessageService;
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this._loadConfig();
    this._buildModalOptions();
    this._setupSubscriptions();
  }

  /**
   * _loadConfig
   * @returns {void}
   */
  _loadConfig() {
    this.ConfigurationFactory.getEntityBaseConfiguration(
      this.config.entity
    ).then((config) => {
      this.modalConfig = config;
    });
  }

  /**
   * _buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: {
        create: `EditableModal.Create.${this.config.entity}.${this.parentId}.Layered`,
      },
      entity: this.config.entity,
    };
  }

  /**
   * _setupSubscriptions
   * Note the create subscription here, which closes the modal
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this.modalOptions.channels.create,
      (channel, data) => {
        this.MessageService.publish(
          `EditableModal.Create.${this.config.entity}.${this.parentId}`,
          data
        );
        this.close();
      }
    );
  }

  /**
   * $onDestroy
   * @returns {void}
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(this.modalOptions.channels.create);
  }
}

/**
 * modalCreateButton
 * @returns {Object} Directive Definition Object
 */
function modalCreateButton() {
  return {
    restrict: "E",
    controller: ModalCreateButtonCtrl,
    scope: {},
    bindToController: {
      config: "=",
      parentId: "=",
      close: "&",
    },
    controllerAs: "component",
    template,
    link: (scope) => scope.component.init(),
  };
}

export default modalCreateButton;
