import { has, isUndefined } from "lodash";
import template from "./item-block.html";

/**
 * @file ItemBlockController
 * Item block for use in set contents
 */
/**
 * @classdesc handles interactions within the item blocks. Item blocks
 * currently only used in set contents
 */
class ItemBlockController {
  /**
   * @constructor
   * @param {Object} $location
   * @param {Object} $scope
   * @param {Object} SetContentsActionCreators
   * @param {Object} SetContentsStore
   */
  constructor(
    $location,
    $scope,
    SetContentsActionCreators,
    SetContentsStore,
    BreadcrumbService,
    EntityFactory,
    QuixService
  ) {
    this.$location = $location;
    this.$scope = $scope;
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.SetContentsStore = SetContentsStore;
    this.BreadcrumbService = BreadcrumbService;
    this.EntityFactory = EntityFactory;
    this.QuixService = QuixService;

    this.initialPosition = {
      ...this.data.scheduledItem[this.data.displayedLanguage],
    }.position;

    this.modalOptions = this._buildModalOptions();
    this.displayNavIcon = !this.data.config.is_not_core_entity;
    this.contentScore = 0;
    this.setState = this.SetContentsStore.getState();

    this._setupListeners();
    this._setContentUrl();
    this._itemHasActions();
    this._hasActiveStatus();
    this._getContentScore();
  }

  /**
   * Creates a modal option object. Parent data allows for us to layer modals specifically
   * so we can create a schedule from within the schedule tab
   * @return {Object}
   * @private
   */
  _buildModalOptions() {
    const config = {
      channels: {
        save: "Modal.Edit.Save.ScheduledItem",
        delete: "Modal.Edit.Delete.ScheduledItem",
      },
      entity: "scheduledItem",
      parent: {
        parentId: this.data.uid,
        parentType: "scheduledItem",
        parentLanguage: this.data.displayedLanguage,
        childOf: this.$location.url(),
      },
      hasTranslations: this.data.config.hasTranslations,
      isUniqueToSet: this.data.config.unique,
    };

    if (this.data.config.hasTranslations) {
      config.availableLanguages =
        this.SetContentsStore.getState().availableLanguages;
      config.channels.save = "Modal.Edit.Save.MultipleLanguagesScheduledItem";
    }

    if (this.data.config.unique) {
      config.contentItem = this.data.contentItem;
      config.channels.save = "Modal.Edit.Save.UniqueItem";
    }

    return config;
  }

  /**
   * On data change lifecycle hook
   */
  $onChanges() {
    this.modalOptions = this._buildModalOptions();
    this.contentItem = this.data.contentItem[this.data.displayedLanguage];
  }

  /**
   * Because this item exists within a ng-repeat, the items are not rebuilt properly when data is
   * changed, this circumvents angular $onChange and $onInit lifecycle hooks event and provides
   * the similar functionality. This is not best practice and should be avoided
   * @private
   */
  _setupListeners() {
    this.SetContentsStore.registerChangeListener(() => {
      this.initialPosition = {
        ...this.data.scheduledItem[this.data.displayedLanguage],
      }.position;
    });
  }

  /**
   * Returns the correct language version to display in the set. Either uses the same
   * as scheduled item or first language version available
   * @return {Object}
   * @private
   */
  _getContentLanguageVersionToDisplay() {
    return (
      this.data.contentItem[this.data.displayedLanguage] ||
      this.data.contentItem[Object.keys(this.data.contentItem)[0]]
    );
  }

  /**
   * Gets content score from quix
   *
   */
  _getContentScore() {
    const isItemScore = this.data.config.columns.find(
      (column) => column.widget === "itemScore"
    );

    if (!isItemScore) {
      return;
    }

    const contentItem = this._getContentLanguageVersionToDisplay();
    const { uid } = contentItem;

    this.clickThroughLoading = true;

    if (this.data.type === "episodes") {
      return this.QuixService.getEngagement(uid)
        .then((data) => {
          const { numericValues } = data;
          const engagement = numericValues["mean(engagement)"][0];

          this.contentScore = isUndefined(engagement)
            ? "0%"
            : `${Math.floor(engagement * 100) / 100}%`;
        })
        .finally(() => {
          this.clickThroughLoading = false;
        });
    }

    this.QuixService.getClickThrough(uid)
      .then((data) => {
        const count = data.timestamps.length;

        this.contentScore = `${count} Clicks`;
      })
      .finally(() => {
        this.clickThroughLoading = false;
      });
  }

  /**
   * Set the content url property for linking in the dom
   * @private
   */
  _setContentUrl() {
    const urlWithoutApiPrefix =
      this._getContentLanguageVersionToDisplay().self.substring(4);
    const entityType =
      this.data.config.type === "set" ? "sets" : this.data.config.name;

    const prefix = this.EntityFactory.isCoreEntity(entityType) ? "" : "custom";
    this.contentUrl = `${prefix}${urlWithoutApiPrefix}information/`;
  }

  /**
   * hasActions
   * @return {boolean}
   */
  _itemHasActions() {
    this.hasActions = !!this.data.config.columns.find(
      (column) => column.widget === "itemActions"
    );
  }

  /**
   * Creates a double check to see if active status property is visible on the
   * computed scheduled item.
   */
  _hasActiveStatus() {
    this.hasActiveStatus = has(this.data, "isActive");
  }

  /**
   * Remove an item from the set
   * @callback ngclick
   */
  removeItem() {
    this.SetContentsActionCreators.removeScheduledItem(
      this.data.scheduledItem[this.data.displayedLanguage]
    );
  }

  /**
   * Reposition an item
   * @callback ngKeyup
   */
  repositionItem() {
    this.data.scheduledItem[this.data.displayedLanguage].position =
      this.data.scheduledItem[this.data.displayedLanguage].position || 0;
    const position = parseInt(
      this.data.scheduledItem[this.data.displayedLanguage].position
    );

    if (
      !isNaN(position) &&
      position >= 0 &&
      position !== this.initialPosition
    ) {
      this.SetContentsActionCreators.positionItem(
        this.data,
        position,
        this.initialPosition
      );
    }
  }

  /**
   * get Title
   * when getting content title, check for a display_as property or default to title.
   * This is not required for scheduled items title as we may not want to display
   * the title field if it exists.
   */
  getTitle() {
    const displayAs = this.data.config.display_as || "title";

    return (
      this.data.scheduledItem[this.data.displayedLanguage][
        this.data.config.display_as
      ] || this._getContentLanguageVersionToDisplay()[displayAs]
    );
  }

  /**
   * Resets the breadcrumb when navigating away from a set
   */
  setNavigationHandler() {
    this.BreadcrumbService.setNavigationHandler();
  }

  /**
   * navigateToItem - Navigates to scheduled item in set contents
   * @returns {void}
   */
  navigateToItem() {
    if (this.hasActions && this.displayNavIcon) {
      this.$location.path(this.contentUrl);
      this.setNavigationHandler();
    }
  }
}

const itemBlockComponent = {
  bindings: {
    data: "<",
    isEditing: "<",
  },
  template,
  controller: ItemBlockController,
  controllerAs: "component",
};

export default itemBlockComponent;
