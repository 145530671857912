import angular from "angular";

const TagsRoute = angular.module("TagsRoute", []).config(router);

/**
 * Clears all messaging channels
 * @param {object} MessageService - the messaging service
 * @returns {void}
 */
function clearChannels(MessageService) {
  MessageService.clearChannels();
}

/**
 * resetLanguages
 * @param   {Object} EntityDimensionsService
 * @returns {void}
 */
function resetLanguages(EntityDimensionsService) {
  EntityDimensionsService.reset();
}

/**
 * Defines routing config
 * @param  {object} $stateProvider - ui-router's stateProvider service
 * @param  {object} $locationProvider - angular router's locationProvider service
 * @param  {object} $urlMatcherFactoryProvider - ui-router's urlMatcherFactoryProvider service
 * @returns {void}
 */
function router($stateProvider, $locationProvider, $urlMatcherFactoryProvider) {
  $urlMatcherFactoryProvider.strictMode(false);
  $locationProvider.html5Mode(true);
  $stateProvider.state("Tags", {
    resolve: {
      clearChannels,
      resetLanguages,
    },
    url: "/tags",
    title: "tags",
    pageTitle: "tags:listing",
    reloadOnSearch: false,
    views: angular.extend({
      main: {
        component: "entityListingPage",
      },
    }),
  });
}

export default TagsRoute;
