import pikaday from "pikaday";

/**
 * pikaday
 * @param   {Object} $window
 * @returns {Object} instance of pikaday
 */
function pikadayWrapper() {
  return pikaday;
}

export default pikadayWrapper;
