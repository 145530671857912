import React from "react";
import PropTypes from "prop-types";

import DefaultColumn from "../list-columns/default-column/default-column";
import ImageColumn from "../list-columns/image-column/image-column";
import IsSelectedColumn from "../list-columns/is-selected-column/is-selected-column";
import ScheduleColumn from "../list-columns/schedule-column/schedule-column";
import DateColumn from "../list-columns/date-column/date-column";

/**
 *
 * @param  {Object} column
 * @param  {Object} props
 * @returns {Object}
 */
export default function EntityListColumnRenderer(column, props) {
  switch (column.name) {
    case "image":
      return (
        <ImageColumn
          imageUrls={props.entity.image_urls}
          size="100x56"
          isFaded={props.isFaded}
        />
      );

    case "isSelected":
      return (
        <IsSelectedColumn
          isChecked={props.isSelected}
          id={props.entity.uid}
          canSelectMultiple={props.canSelectMultiple}
        />
      );

    case "schedule":
      return (
        <ScheduleColumn
          schedules={props.entity.schedule_urls || [props.entity]}
        />
      );

    case "modified":
      return <DateColumn date={props.entity.modified} />;

    default:
      return (
        <DefaultColumn
          entity={props.entity}
          columnName={column.name}
          isFaded={props.isFaded}
          type={props.type}
        />
      );
  }
}

EntityListColumnRenderer.propTypes = {
  entity: PropTypes.shape({
    image_urls: PropTypes.arrayOf(PropTypes.string),
    schedule_urls: PropTypes.arrayOf(PropTypes.object),
    uid: PropTypes.string,
    modified: PropTypes.string,
  }).isRequired,
  isSelected: PropTypes.bool.isRequired,
  canSelectMultiple: PropTypes.bool.isRequired,
  isFaded: PropTypes.bool,
  type: PropTypes.string,
};

EntityListColumnRenderer.defaultProps = {
  isFaded: false,
  type: "",
};
