import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import withStoreProvider from "hocs/with-store-provider";
import { react2angular } from "react2angular";
import angular from "angular";

import * as toasterActions from "store/toaster/toaster.actions";

import Toaster from "./toaster";

const connectToStore = (component) => connect(mapProps, mapDispatch)(component);

const mapProps = (state) => ({
  toaster: state.toaster,
});

const mapDispatch = (dispatch) =>
  bindActionCreators({ ...toasterActions }, dispatch);

const reactToaster = angular
  .module("Skylark.Components.React.Toaster", [])
  .component(
    "reactToaster", // prefix with react as the angular toaster cannot be replaced yet
    react2angular(withStoreProvider(connectToStore(Toaster)))
  );

export default connectToStore(Toaster);

export { reactToaster };
