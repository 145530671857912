import angular from "angular";
import NavigationSection from "./navigation-section/navigation-section.module";
import NavigationBookmarks from "./navigation-bookmarks/navigation-bookmarks.module";
import NavigationFilterButton from "./navigation-filter-button/navigation-filter-button.module";
import navigationComponent from "./navigation.component";
import NavigationService from "./shared/navigation.service";

const Navigation = angular
  .module("Skylark.Components.Navigation", [
    NavigationSection.name,
    NavigationBookmarks.name,
    NavigationFilterButton.name,
  ])
  .component("navigation", navigationComponent)
  .service("NavigationService", NavigationService);

export default Navigation;
