const initialState = {
  isEditing: false,
  nextLocation: null,
};

/**
 * current user reducer
 * @param   {Object} state
 * @param   {Object} action
 * @returns {Object} state
 */
export default function (state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_EDITING":
      return { ...state, isEditing: !state.isEditing, hasChanged: false };

    case "TOGGLE_HAS_CHANGED":
      return { ...state, hasChanged: state.hasChanged || action.hasChanged };

    case "RESET_HAS_CHANGED":
      return { ...state, hasChanged: false };

    case "TRIGGER_UNSAVED_CHANGES":
      return { ...state, nextLocation: action.location };

    case "RESET_UNSAVED_CHANGES":
      return { ...state, nextLocation: null };

    default:
      return state;
  }
}
