import React from "react";
import PropTypes from "prop-types";

import ImagePreview from "./image-preview/image-preview";
import TextPreview from "./text-preview/text-preview";
import VideoPreview from "./video-preview/video-preview";

import styles from "./editor-preview.scss";

const previewBlockConfig = {
  paragraph: TextPreview,
  title: TextPreview,
  author: TextPreview,
  subtitle: TextPreview,
  video: VideoPreview,
  "media-placeholder": null,
  image: ImagePreview,
};

/**
 * Render an article preview
 * p.s. this is not dangerous - its being sanitised!
 * @param {Object} props
 */
export default function EditorPreview(props) {
  return (
    <div className={styles["editor-preview"]} dir="auto">
      {props.data.map((block, index) => {
        const PreviewBlock = previewBlockConfig[block.content_type];

        if (PreviewBlock) {
          return (
            <div
              className={styles["editor-preview__block"]}
              key={`${block.content_type}-${index}`}
            >
              <PreviewBlock data={block} />
            </div>
          );
        }

        return null;
      })}
    </div>
  );
}

EditorPreview.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      content: PropTypes.string,
      content_type: PropTypes.string,
      triggers: PropTypes.array,
    })
  ).isRequired,
};
