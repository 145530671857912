import _ from "lodash";

let channels = [];

/**
 * All purpose Messaging Service for communicating between directives and controllers.
 * @param {object} _ lodash
 * @returns {Object} service object
 */
function MessageService() {
  const service = {};

  /**
   * register channel
   * @memberOf  MessageService
   * @description : register a channel for a scope that needs to listen for it.
   *
   * @param  {string} name - Name of channel to be registered
   * @param {int} limit of subscribers - subscribers that depend on unique IDs should to be
   *     limited
   * @returns {void}
   */
  service.registerChannel = (name, limit) => {
    const exists = service.get(name);
    if (!exists) {
      channels.push({
        name,
        subscribers: [],
        lock: false,
        limit,
      });
    }
  };

  /**
   * get
   * @memberOf  MessageService
   * @description : retrieves channel by name and returns it as an object
   *
   * @param  {string} channel name
   * @returns {Object} - channel object
   */
  service.get = (channel) => {
    let retObj;
    channels.forEach((ch) => {
      if (ch.name === channel) {
        retObj = ch;
      }
    });

    return retObj;
  };

  /**
   * lockChannel
   *
   * @memberOf  MessageService
   * @description : sets the lock property in channel objects. This prevents the designed
   *     channels from being wiped out on clearChannels. use sparingly, for things like modals or
   *     other other events which are sufficiently close to the rootscope.
   * @param { string } channel - channel name
   * @returns {void}
   */
  service.lockChannel = (channel) => {
    channels.forEach((ch) => {
      if (ch.name === channel) {
        ch.lock = true;
      }
    });
  };

  /**
   * unlockChannel
   * @memberOf  MessageService
   *
   * @description : unsets the lock property in channel objects, allowing thenm to be wiped out
   *     on clearChannels.
   * @param {string} channel - channel name
   * @returns {void}
   */
  service.unlockChannel = (channel) => {
    channels.forEach((ch) => {
      if (ch.name === channel) {
        ch.lock = false;
      }
    });
  };

  /**
   * on
   * @memberOf  MessageService
   * @description: adds current scope to the subscribers of a specified channel
   *
   * @param  {string} channel channel name
   * @param  {function} cb function callback - will work with data passed on from publish method
   * @returns {void}
   */
  service.on = (channel, cb) => {
    channels.forEach((ch) => {
      if (ch.name === channel) {
        if (ch.limit === ch.subscribers.length) {
          return;
        }
        ch.subscribers.push(cb);
      }
    });
  };

  /**
   * remove callback from that channel
   * @param {string} ch
   * @param {function} callback
   */
  service.off = (ch, callback) => {
    channels.forEach((channel) => {
      if (channel.name === ch) {
        _.remove(
          channel.subscribers,
          (savedCallback) => savedCallback === callback
        );
      }
    });
  };

  /**
   * publish
   * @memberOf  MessageService
   * @description : register a new channel. When a new channel is published its subscribers are
   *     updated with a new callback.
   *
   * @param  {string} channel - channel to be published
   * @param  {any} data to be passed on through the channel
   * @returns {void}
   */
  service.publish = (channel, data) => {
    const ch = service.get(channel);
    if (ch) {
      ch.subscribers.forEach((sub) => {
        sub(ch, data);
      });
    }
  };

  /**
   * wrap both register and on together
   * @param {string} channelName
   * @param {function} cb
   * @param {number} limit
   * @returns {void}
   */
  service.subscribe = (channelName, cb, limit = 9999) => {
    service.registerChannel(channelName, limit);
    service.on(channelName, cb);
  };

  /**
   * clearChannels
   * @memberOf  MessageService
   * wipes out all the current channels.
   * this is dangerous so it should only be used in things like route change.
   * @returns {void}
   */
  service.clearChannels = () => {
    channels = _.filter(channels, (channel) => channel.lock);
  };

  /**
   *  Unregister Channel
   *  @memberOf  MessageService
   *  @description: Unregister channel when it no longer exists. useful when it's not convenient
   *     to wipe out the channels array.
   *  @param {object} channel - channel to be cleared
   *  @returns {void}
   */
  service.unregisterChannel = (channel) => {
    channels.forEach((ch, index) => {
      if (ch.name === channel) {
        channels.splice(index, 1);
      }
    });
  };

  /**
   * Used for debugging
   * @returns {void}
   */
  service.logChannels = () => {
    // eslint-disable-next-line no-console
    console.log("currently registered channels", channels);
  };

  return service;
}

export default MessageService;
