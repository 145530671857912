/**
 * @fileOverview
 * This is the HTML preparer helpers for articles, which converts
 * the API payload for article items into a draftjs compatible structure and
 * back to raw HTML contained in JSON when sending it back to the API.
 */

import { ContentState, EditorState, convertToRaw } from "draft-js";

import { convertFromHTML } from "draft-convert";
import { stateToHTML } from "draft-js-export-html";

const allowedBlocks = ["p", "ol", "ul", "h1", "h2", "h3", "h4", "h5", "h6"];
const mediaBlocks = ["image", "media-placeholder", "video"];
const apiEntities = ["image", "video"];

/**
 * @classdesc HTML Helpers for Features Editor
 */
class HTMLHelpers {
  /**
   * _htmlToEntity
   * @param   {string} nodeName
   * @param   {HTMLElement} node
   * @param   {function} createEntity
   * @returns {function}
   */
  static _htmlToEntity(nodeName, node, createEntity) {
    switch (nodeName) {
      case "a":
        return createEntity("LINK", "MUTABLE", { url: node.href });
      case "img":
        return createEntity("image", "IMMUTABLE", {
          contentType: "image",
          media: { url: node.src },
        });
      case "media-placeholder":
        return createEntity("media-placeholder", "IMMUTABLE", {
          contentType: "media-placeholder",
          media: {},
        });
      case "video":
        return createEntity("video", "IMMUTABLE", {
          contentType: "video",
          media: { title: node.innerHTML },
        });
      default:
        return null;
    }
  }

  /**
   * _htmlToBlock
   * @param   {string} nodeName
   * @param   {HTMLElement} node
   * @param   {function} createEntity
   * @returns {function}
   */
  static _htmlToBlock(nodeName, node) {
    switch (nodeName) {
      case "img":
        return HTMLHelpers._createMediaBlock("image", node.src);
      case "media-placeholder":
        return HTMLHelpers._createMediaBlock("media-placeholder");
      case "video":
        return HTMLHelpers._createMediaBlock("video");
      default:
        return null;
    }
  }

  /**
   * _createMediaBlock
   * @param  {String} url
   * @param  {String} mediaType
   * @return {Object}
   */
  static _createMediaBlock(mediaType, url = "") {
    return {
      type: "atomic",
      data: { mediaType, url },
    };
  }

  /**
   * convert from HTML
   * @returns {ContentState}
   */
  static convertFromHTML(items, config) {
    const newItems = HTMLHelpers._assignPlaceholders(items, config.sections);

    const blocks = items.length
      ? convertFromHTML({
          htmlToEntity: HTMLHelpers._htmlToEntity,
          htmlToBlock: HTMLHelpers._htmlToBlock,
        })(HTMLHelpers._extractHTML(newItems, config.sections))
      : convertFromHTML({
          htmlToEntity: HTMLHelpers._htmlToEntity,
          htmlToBlock: HTMLHelpers._htmlToBlock,
        })(HTMLHelpers._createPlaceholderHTML(items, config));

    return ContentState.createFromBlockArray(
      HTMLHelpers._annotateContentTypes(
        newItems,
        blocks.getBlocksAsArray(),
        config
      ),
      HTMLHelpers._buildEntityData(newItems, blocks)
    );
  }

  /**
   * convert To HTML
   * @returns {Array} an API friendly array of objects
   */
  static convertToHTML(currentContent) {
    const types = HTMLHelpers._getTypes(currentContent);
    const entities = HTMLHelpers._getEntities(currentContent);
    const blocksWithEntities = HTMLHelpers._mergeEntitytoBlock(currentContent);
    const newContent = ContentState.createFromBlockArray(blocksWithEntities);

    const html = stateToHTML(
      HTMLHelpers._removePlaceholders(newContent),
      HTMLHelpers._configureStateToHTML()
    )
      .replace(/<br>\n/g, "<br>")
      .replace(/<br>/g, "<br/>")
      .replace(/<ul>\n/g, "<ul>")
      .replace(/<ol>\n/g, "<ol>")
      .replace(/\s*<li>/g, "<li>")
      .replace(/<\/li>\n/g, "</li>")
      .split("\n")
      .map((content, index) => {
        const type = types[index];
        const media = entities[index];

        return {
          content: type && type.includes("media-placeholder") ? "" : content,
          content_type: type || "paragraph",
          triggers: [],
          media: HTMLHelpers._handleItemMedia(media.media, type),
        };
      });

    return HTMLHelpers._splitMultipleSpaceBlocks(html);
  }

  /**
   * _handleItemMedia
   * @param  {Object} data
   * @param  {String} type
   * @return {String|null}
   */
  static _handleItemMedia(data, type) {
    return apiEntities.includes(type) ? data.self : null;
  }

  /**
   * _configureStateToHTML
   * @return {Object} Options
   */
  static _configureStateToHTML() {
    return {
      blockRenderers: {
        "media-placeholder": () => "<div>Media Placeholder</div>",
        video: (block) =>
          `<div>${block.getData().getIn(["media", "title"])}</div>`,
        image: (block) =>
          `<img src="${block.getData().getIn(["media", "url"])}" />`,
      },
    };
  }

  /**
   * split multiple space blocks
   * @param   {Array} items
   * @returns {Array} array of items
   */
  static _splitMultipleSpaceBlocks(items) {
    return items
      .map((item) => {
        const match = item.content.match(/(<br\/>){2,}/g);
        if (match && item.content_type === "paragraph") {
          const tag = allowedBlocks.find((block) =>
            item.content.includes(`<${block}>`)
          );

          if (tag !== "ol" && tag !== "ul") {
            return item.content.split(match).map((fragment) => {
              const newFragment = fragment
                .split(`<${tag}>`)
                .join("")
                .split(`</${tag}>`)
                .join("");

              return {
                content_type: item.content_type,
                content: `<${tag}>${newFragment}</${tag}>`,
                triggers: [],
              };
            });
          }
        }

        return [item];
      })
      .reduce((acc, curr) => acc.concat(curr));
  }

  /**
   * _mergeEntitytoBlock
   * @param   {Immutable.Record} currentContent
   * @returns {Array}
   */
  static _mergeEntitytoBlock(currentContent) {
    const blocksAsArray = currentContent.getBlocksAsArray();
    const blocksWithEntities = [];
    const blockKeys = [];

    blocksAsArray.forEach((block, index) => {
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();

          if (
            entityKey === null ||
            (entityKey &&
              currentContent.getEntity(entityKey).getType() === "LINK")
          ) {
            if (!blockKeys.includes(index)) {
              blockKeys.push(index);
              blocksWithEntities.push(block);
            }

            return false;
          }

          return true;
        },
        (startKey) => {
          const entity = currentContent.getEntity(block.getEntityAt(startKey));

          blockKeys.push(index);
          blocksWithEntities.push(
            block.merge({
              data: {
                ...block.getData().toJS(),
                media: entity.data.media,
              },
            })
          );
        }
      );
    });

    return blocksWithEntities.filter(
      (block) => block.getType() !== "placeholder"
    );
  }

  /**
   * _getTypes
   * @param   {Immutable.Record} currentContent
   * @returns {Array}
   */
  static _getTypes(currentContent) {
    const rawContent = convertToRaw(currentContent);

    return HTMLHelpers._filterBlocks(
      rawContent,
      (block) => block.data.contentType
    );
  }

  /**
   * _getEntities
   * @param   {Immutable.Record} currentContent
   * @returns {Array}
   */
  static _getEntities(currentContent) {
    const rawContent = convertToRaw(currentContent);

    return HTMLHelpers._filterBlocks(rawContent, (block) => {
      if (block.entityRanges.length) {
        const entityKey = block.entityRanges[0].key;

        return rawContent.entityMap[entityKey].data;
      }

      return block.type;
    });
  }

  /**
   * _filterBlocks
   * @param  {Object} rawContent
   * @param  {Func} handleBlock
   * @return {Array} Blocks
   */
  static _filterBlocks(rawContent, handleBlock) {
    return rawContent.blocks
      .filter((block, index, curr) =>
        HTMLHelpers._filterList("unordered-list-item", block, curr[index + 1])
      )
      .filter((block, index, curr) =>
        HTMLHelpers._filterList("ordered-list-item", block, curr[index + 1])
      )
      .filter((block) => block.text.length)
      .map((block) => handleBlock(block))
      .filter((type) => type !== "placeholder");
  }

  /**
   * Filter out list blocks
   * @param  {String} listType
   * @param  {Object} block
   * @param  {Object} nextBlock [description]
   * @return {Object} Filtered List block
   */
  static _filterList(listType, currentBlock, nextBlock) {
    return !(
      currentBlock.type === listType &&
      nextBlock &&
      nextBlock.type === listType
    );
  }

  /**
   * filter placeholders so they are not sent back to the API
   * @param   {Immutable.Map} currentContent
   * @returns {Immutable.Map}
   */
  static _removePlaceholders(currentContent) {
    const blockMap = currentContent
      .getBlockMap()
      .toSeq()
      .filter((block) => block.getType() !== "placeholder")
      .toOrderedMap();

    const filteredContent = currentContent.merge({
      blockMap,
    });

    return EditorState.createWithContent(filteredContent).getCurrentContent();
  }

  /**
   * assign placeholders as needed
   * @todo  rewrite this whole thing
   * @param   {Array} items
   * @param   {Array} sections
   * @returns {Array} the HTML from the API plus any required placeholders
   */
  static _assignPlaceholders(items, sections) {
    const groupedSections = items.reduce((accumulator, item) => {
      const contentType = mediaBlocks.includes(item.content_type)
        ? "paragraph"
        : item.content_type;
      const config = sections.find(
        (section) => section.field_name === contentType
      );

      if (!accumulator[contentType]) {
        return { ...accumulator, [contentType]: [{ ...item, config }] };
      }

      return Object.assign(accumulator, {
        [contentType]: [...accumulator[contentType], { ...item, config }],
      });
    }, {});

    return sections
      .map((section) => {
        if (!groupedSections[section.field_name]) {
          return HTMLHelpers._buildPlaceholderSection(section);
        }

        return groupedSections[section.field_name];
      })
      .reduce((accumulator, section) => accumulator.concat(section))
      .map((item) => HTMLHelpers._createMediaPlaceholderHTML(item));
  }

  /**
   * _buildPlaceholderSection
   * @param  {Object} section
   * @return {Array}
   */
  static _buildPlaceholderSection(section) {
    return [
      {
        content: HTMLHelpers._buildPlaceholderMarkup(section),
        config: section,
      },
    ];
  }

  /**
   * _createPlaceholderHTML
   * @param   {Array} items
   * @param   {Object} config
   * @returns {String} stringified HTML
   */
  static _createPlaceholderHTML(items, config) {
    return config.sections
      .map((section) => HTMLHelpers._buildPlaceholderMarkup(section))
      .join("");
  }

  /**
   * _buildPlaceholderMarkup
   * @param   {object} section
   * @returns {string}
   */
  static _buildPlaceholderMarkup(section) {
    return `<${section.default_element}></${section.default_element}>`;
  }

  /**
   * _createMediaPlaceholderHTML
   * @param  {Object} item
   * @return {Object} item
   */
  static _createMediaPlaceholderHTML(item) {
    const title =
      item.content_type === "video" ? HTMLHelpers._getVideoTitle(item) : "";

    if (
      item.content_type === "media-placeholder" ||
      item.content_type === "video"
    ) {
      return {
        ...item,
        content: `<${item.content_type}>${title || ""}</${item.content_type}>`,
      };
    }

    return item;
  }

  /**
   * _getVideoTitle
   * @param  {Object} item
   * @return {String}
   */
  static _getVideoTitle(item) {
    return item.content.replace(/<[^>]*>/g, "");
  }

  /**
   * extract html from the items array
   * @param   {Array} items
   * @returns {String} the html for the items all joined up together
   */
  static _extractHTML(items) {
    return items.map((item) => item.content).join("");
  }

  /**
   * expand nested items (for lists)
   * @todo  merge with paragraph split
   * @param {Array} items from the API
   * @returns {Array}
   */
  static _expandNestedItems(items) {
    return items
      .map((item) => {
        if (
          item.content.startsWith("<ul>") ||
          item.content.startsWith("<ol>")
        ) {
          return item.content
            .split("<li>")
            .map((htmlString) =>
              htmlString.replace(/(<\/li>|<\/ul>|<\/ol>|<ol>|<ul>)/g, "")
            )
            .filter((text) => text)
            .map((text) => ({
              content_type: item.content_type,
              content: `<li>${text}</li>`,
              triggers: item.triggers,
              config: item.config,
            }));
        }

        return [item];
      })
      .reduce((acc, curr) => acc.concat(curr), []);
  }

  /**
   * _generateBlockType
   * @param  {Immutable.Record} block - Draft <ContentBlock>
   * @param  {String} contentType
   * @return {String} Block Type
   */
  static _generateBlockType(block, contentType) {
    if (contentType === "placeholder") {
      return "placeholder";
    }
    if (block.getData().get("mediaType")) {
      return block.getData().get("mediaType");
    }

    return block.type;
  }

  /**
   * _handleBlockText
   * @param  {Immutable.Record} block - Draft <ContentBlock>
   * @param  {String} contentType
   * @return {String} Block Text
   */
  static _handleBlockText(block, contentType) {
    if (contentType === "placeholder") {
      return "";
    }
    if (block.getData().get("mediaType")) {
      return " ";
    }

    return block.text;
  }

  /**
   * annotate content blocks with metadata
   * @param   {Array} items - array of items
   * @param   {Object} blocks
   * @returns {Array}
   */
  static _annotateContentTypes(items, blocks) {
    const newItems = HTMLHelpers._expandNestedItems(items);

    return blocks.map((block, index) => {
      const contentType = newItems[index].content_type
        ? newItems[index].content_type
        : "placeholder";

      return block.merge({
        data: HTMLHelpers._buildBlockMetadata(block, newItems[index]),
        type: HTMLHelpers._generateBlockType(block, contentType),
        text: HTMLHelpers._handleBlockText(block, contentType),
      });
    });
  }

  /**
   * _buildEntityData
   * @param  {Array} items
   * @param  {Object} blocks
   */
  static _buildEntityData(items, blocks) {
    let contentState = blocks;

    blocks
      .getBlocksAsArray()
      .filter((block, index, curr) =>
        HTMLHelpers._filterList("unordered-list-item", block, curr[index + 1])
      )
      .filter((block, index, curr) =>
        HTMLHelpers._filterList("ordered-list-item", block, curr[index + 1])
      )
      .forEach((block, key) => {
        contentState = HTMLHelpers._mergeEntityData(blocks, block, items[key]);
      });

    return contentState.entityMap;
  }

  /**
   * _mergeEntityData
   * @param  {Array} items - Array of item objects
   * @param  {Object} blocks - <ContentBlock>
   */
  static _mergeEntityData(blocks, block, item) {
    if (block.getData().get("mediaType")) {
      return blocks.mergeEntityData(block.getEntityAt(0), {
        media: item.media,
      });
    }

    return blocks;
  }

  /**
   * _buildBlockMetadata
   * @param   {Object} item
   * @param   {Object} block
   * @returns {Object}
   */
  static _buildBlockMetadata(block, item) {
    const contentType = item.content_type ? item.content_type : "placeholder";

    return {
      contentType,
      config: item.config,
      triggers: [],
      media: item.media,
    };
  }
}

export default HTMLHelpers;
