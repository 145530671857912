import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import styles from "./link-preview.scss";

/**
 * LinkPreview
 * @param {Object} props
 */
export default class LinkPreview extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    url: PropTypes.string.isRequired,
    removeLink: PropTypes.func.isRequired,
    editLink: PropTypes.func.isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.removeLink = this.removeLink.bind(this);
    this.editLink = this.editLink.bind(this);
  }

  /**
   * deleteLink
   */
  removeLink() {
    this.props.removeLink();
  }

  /**
   * editLink
   */
  editLink() {
    this.props.editLink();
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    return (
      <div className={classnames(styles["link-preview__container"])}>
        <div className={styles["link-preview__text"]}>{this.props.url}</div>
        <div className={styles["link-preview__controls"]}>
          <button
            id="editor-link-edit"
            className={classnames([
              styles["link-preview__button"],
              styles["link-preview__button--edit"],
            ])}
            onClick={this.editLink}
          >
            <SkylarkIcon
              iconName="pencil"
              extraClassName={styles["link-preview__icon"]}
            />
          </button>
          <button
            id="editor-link-remove"
            data-test="editor-link-remove"
            className={classnames([
              styles["link-preview__button"],
              styles["link-preview__button--remove"],
            ])}
            onClick={this.removeLink}
          >
            <SkylarkIcon
              iconName="bin"
              extraClassName={styles["link-preview__icon"]}
            />
          </button>
        </div>
      </div>
    );
  }
}
