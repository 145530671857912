import React from "react";
import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import NextLink, { LinkProps as NextLinkProps } from "next/link";
import { useRouter as useNextRouter } from "next/router";

export type LinkProps = NextLinkProps &
  ChakraLinkProps & { hasExternalIcon?: boolean };

export const Link: React.FC<LinkProps> = ({
  children,
  href,
  as,
  replace,
  scroll,
  shallow,
  prefetch,
  locale,
  isExternal,
  hasExternalIcon = isExternal,
  color = "skylarkBlue",
  size = "sm",
  ...anchorProps
}) => {
  const nextRouter = useNextRouter();

  // Only use NextLink if running in a Next.js app and the link is not external
  if (nextRouter && !isExternal) {
    return (
      <NextLink
        {...{ href, as, replace, scroll, shallow, prefetch, locale }}
        passHref
      >
        <ChakraLink color={color} size={size} {...anchorProps}>
          {children}
        </ChakraLink>
      </NextLink>
    );
  }

  return (
    <ChakraLink
      color={color}
      href={href}
      isExternal={isExternal}
      size={size}
      {...anchorProps}
    >
      {children}
      {hasExternalIcon && isExternal && <ExternalLinkIcon mx="2px" />}
    </ChakraLink>
  );
};

export default Link;
