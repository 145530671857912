import template from "./pagination.html";

class PaginationDirectiveCtrl {
  /**
   * @constructor PaginationDirectiveCtrl
   * @description  limit and start are named so because they
   * match the properties we actually need in the API.
   * @param {Object} $scope
   * @param {Object} GlobalParamsService
   * @param {Object} BatchAddingService
   * @param {Object} MessageService
   * @param {Object} EntityFactory
   * @param {Object} _ lodash
   */
  constructor(
    $scope,
    GlobalParamsService,
    MessageService,
    BatchAddingService,
    EntityFactory,
    _
  ) {
    this.$scope = $scope;
    this.GlobalParamsService = GlobalParamsService;
    this.MessageService = MessageService;
    this.EntityFactory = EntityFactory;
    this.BatchAddingService = BatchAddingService;
    this._ = _;

    this.start = parseInt(this.start) || 0;
    this.limit = (this.config && this.config.limit) || 10;
    this.end = this.limit;
    this.prevViewStart = 0;
    this.count = this.count || 0;
    this.totalAmount = this.count || 0;
    this.absoluteTotal = 0;
    this.selectedEntities = [];
    this.selectedItemsAmount = 0;

    /*
     * pagination is debouncing while a user makes quick changes to the page next or previous,
     * so that it doesn't get triggered on every change,
     * the first request is fired the following are debouncing.
     */
    const debounceTimer = 500;
    const debounceOption = {
      leading: true,
    };
    this.onNext = _.debounce(
      this.onNext.bind(this),
      debounceTimer,
      debounceOption
    );
    this.onPrevious = _.debounce(
      this.onPrevious.bind(this),
      debounceTimer,
      debounceOption
    );
  }

  /**
   * init
   */
  $onInit() {
    this.updateCounterPosition(this.start);
    this._setupSubscriptions();
  }

  /**
   * Builds the change channel
   * @private
   * @returns {string}
   */
  _buildChangeChannel() {
    if (this.isModule) {
      return `Pagination.${this.type}.module`;
    }
    return `Pagination.${this.type}`;
  }

  /**
   * setupSubscriptions
   * @description sets listeners
   * @access private
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      `Pagination.UpdatePosition.${this.config.name}`,
      (ch, start) => {
        this.updateCounterPosition(start);
      }
    );

    const changeTotalAmountChannel = this._buildChangeChannel();
    this.MessageService.subscribe(
      changeTotalAmountChannel,
      (ch, totalAmount) => {
        this.changeTotalAmount(totalAmount);
      }
    );

    if (this.isModal) {
      this.MessageService.subscribe(
        `List.selectedViewToggle.${this.type}.modal`,
        (ch, currentViewOptions) => {
          this.changeViewHandler(currentViewOptions);
        }
      );

      this.MessageService.subscribe(
        `List.selectedEntitiesChange.${this.type}.modal`,
        () => {
          this.changeSelectedEntitiesHandler();
        }
      );
    }
  }

  _unsubscribe() {
    this.MessageService.unregisterChannel(this._buildChangeChannel());
    this.MessageService.unregisterChannel(
      `List.selectedViewToggle.${this.type}.modal`
    );
    this.MessageService.unregisterChannel(
      `List.selectedEntitiesChange.${this.type}.modal`
    );
    this.MessageService.unregisterChannel(
      `Pagination.UpdatePosition.${this.config.name}`
    );
  }

  /**
   * resetValues
   * @access private
   * @returns {void}
   */
  _resetValues() {
    this.start = 0;
    this.end = this.limit;
  }

  /**
   * getCount
   * @access private
   * @description calls getCount to get API endpoint
   * or assigns it manually for endpoints that do not support count
   * @returns {void}
   */
  _getCount() {
    if (this.count > 0) {
      this.start = 0;
      this.end = this.limit;
    } else if (!this.config.countEndpoint) {
      this.totalAmount = this.data.length;
      this._compareEnd();
    } else if (this.isCurrentViewSelected) {
      this.totalAmount = this.selectedItemsAmount;
      this.start = 0;
      this.end = this.limit;
      this._compareEnd();
    } else {
      this._loadCount();
    }
  }

  /**
   * loadCount
   * @private
   * @description gets count from API endpoint
   * @returns {void}
   */
  _loadCount() {
    const entityType = this.config.entity || this.config.name;

    this.EntityFactory.getCount(
      entityType,
      this.currentQuery,
      this.isModal
    ).then((countData) => {
      this._handleCountResponse(countData);
    });
  }

  /**
   * Handles the counting of data
   * @param {object} countData
   * @returns {void}
   */
  _handleCountResponse(countData) {
    const selectedItemsAmount = this.BatchAddingService.getSelectedAmount();
    this.totalAmount = countData.count - selectedItemsAmount;
    this.absoluteTotal = countData.count;
    this._compareEnd();
  }

  /**
   * compareEnd
   * @access private
   * @description checks if we need to set end to totalAmount when we are in the last page
   * @returns {void}
   */
  _compareEnd() {
    let { end } = this;

    if (this.end > this.totalAmount) {
      end = this.totalAmount;
    }

    this.end = end;
  }

  _compareEndLimit() {
    if (this.totalAmount < this.limit) {
      this.end = this.totalAmount;
    }
  }

  /**
   * decrementEnd
   * @access private
   * @description decrements end according the number of entities we had in the previous page
   * @returns {void}
   */
  _decrementEnd() {
    if (this.end % this.limit !== 0) {
      this.end -= this.end % this.limit;
    } else {
      this.end -= this.limit;
    }
  }

  /**
   * take properties out from the query object which not matter for the count endpoint
   * @param {Object} query - { all: 'true', order: 'title', ... }
   * @return {Object}
   * @private
   */
  _sanitizeQuery(query) {
    if (!query) {
      return;
    }
    return this._.omit(query, ["order", "start", "end", "limit"]);
  }

  /**
   * getNext
   * @access  public
   * @description move to next page of results
   * @returns {void}
   */
  getNext() {
    this.start += this.limit;
    this.end += this.limit;
    this._compareEnd();

    this.onNext({
      start: this.start,
      limit: this.limit,
    });
  }

  /**
   * getPrevious
   * @access public
   * @description move to previous page of results
   * @returns {void}
   */
  getPrevious() {
    this.start -= this.limit;
    this._decrementEnd();

    this.onPrevious({
      start: this.start,
      limit: this.limit,
    });
  }

  /**
   * changeCurrentQueryHandler - handler for MessageService Channel
   * @param   {Object} updatedQuery - a query object, to be used in count api calls
   * @returns {void}
   */
  changeCurrentQueryHandler(updatedQuery) {
    const newUpdateQuery = this._sanitizeQuery(updatedQuery);
    const sameQuery = this._.isEqual(this.currentQuery, newUpdateQuery);

    this.currentQuery = newUpdateQuery;
    this.isInitialQuery = !this._.isEqual(
      this.currentQuery,
      this.GlobalParamsService.getDefaultParams()
    );

    if (!sameQuery) {
      this._resetValues();
      this._getCount();
    }
  }

  /**
   * changeViewHandler - handler for MessageService Channel
   * @param   {object} currentViewOptions - view options object from list
   * @returns {void}
   */
  changeViewHandler(currentViewOptions) {
    this.isInitialQuery = !this._.isEqual(
      this.currentQuery,
      this.GlobalParamsService.getDefaultParams()
    );
    this.prevViewStart = currentViewOptions.status
      ? this.start
      : this.prevViewStart;
    this.isCurrentViewSelected = currentViewOptions.status;
    this.start = currentViewOptions.status ? 0 : this.prevViewStart;
    this.end = this.start + this.limit;
    this._getCount();
  }

  /**
   * changeSelectedEntitiesHandler - handler for MessageService channel
   * @returns {void}
   */
  changeSelectedEntitiesHandler() {
    const previousSelectedItemsAmount = this.selectedItemsAmount;
    this.isInitialQuery = false;
    this.selectedEntities = this.BatchAddingService.getSelectedItems();
    this.selectedItemsAmount = this.BatchAddingService.getSelectedAmount();
    this.changeSelectedItemsAmounts(previousSelectedItemsAmount);
  }

  /**
   * changeSelectedItemsAmounts - change totalAmount and end
   *
   * If the total amount of items is lower than the limit, then
   * the end variable needs to be equal to the total amount.
   * The total amount contains also the already selected
   * items. This way if:
   * limit = 10, absolute total = 9, totalAmount = 8, selected = 1
   * end = 8 -> if the total amount was higher than 10,
   * end would've been 10, as it represents the number of items
   * that are being shown in the modal.
   * @param   {Number} previousSelectedItemsAmount - previous amount
   * @returns {void}
   */
  changeSelectedItemsAmounts(previousSelectedItemsAmount) {
    this.isInitialQuery = false;
    if (previousSelectedItemsAmount < this.selectedItemsAmount) {
      this.totalAmount = this.absoluteTotal - this.selectedItemsAmount;
    } else if (this.isCurrentViewSelected) {
      this.totalAmount -=
        previousSelectedItemsAmount - this.selectedItemsAmount;
      this._compareEnd();
    }

    this._compareEndLimit();
  }

  /**
   * change to the total items
   * @param {Number} totalAmount
   * @returns {void}
   */
  changeTotalAmount(totalAmount) {
    this.totalAmount = totalAmount;

    if (this.end > this.totalAmount) {
      this.end = this.totalAmount;
    }
  }

  /**
   * Update position.
   * @param {Number} start - the new starting position
   * @returns {void}
   */
  updateCounterPosition(start) {
    this.start = start;
    this.end = start + this.limit;
    this._getCount();
  }

  /**
   * destroy lifecycle
   */
  $onDestroy() {
    this._unsubscribe();
  }
}

const PaginationComponent = {
  bindings: {
    config: "<",
    isModule: "<?",
    isModal: "<?",
    data: "<?",
    start: "<?",
    count: "<?",
    isCurrentViewSelected: "<?",
    type: "<?", // entity type, set by relationship module, modal list, tag module, talent module
    onNext: "&?",
    onPrevious: "&?",
  },
  controller: PaginationDirectiveCtrl,
  controllerAs: "component",
  template,
};

export default PaginationComponent;
