import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import styles from "./block-focus.scss";

/**
 * @class  BlockFocus
 * Handles focus styles in media blocks.
 * A media block is focussed when the user clicks within the block,
 * causing the selection state to be at the start at the block.
 */
export default class BlockFocus extends Component {
  static propTypes = {
    render: PropTypes.func.isRequired,
  };

  /**
   * state
   * @type {Object}
   */
  state = {
    hasFocus: false,
  };

  /**
   * componentWillReceiveProps
   * @param  {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this._setFocus(nextProps);
  }

  /**
   * set focus value
   * @param {Object} props
   */
  _setFocus = (props) => this.setState({ hasFocus: this._isSelected(props) });

  /**
   * whether the block is selected
   * @param  {Object} props
   * @return {Boolean}
   */
  _isSelected = (props) =>
    props.block.getKey() === props.selection.getAnchorKey();

  /**
   * render
   * @description In firefox, anything that has contentEditable and is absolutely positioned becomes draggable.
   * Disabling contentEditable fixes it.
   * @returns {JSX}
   */
  render() {
    return (
      <Fragment>
        {this.props.render(this.state)}
        <p
          className={styles["block-focus__selectable"]}
          contentEditable="false"
        />
      </Fragment>
    );
  }
}
