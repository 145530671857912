import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./password-reset.html";

class PasswordResetController {
  /**
   * @constructor
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} UsersService
   */
  constructor(MessageService, NotificationService, UsersService) {
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.UsersService = UsersService;

    this._setupSubscriptions();
  }

  /**
   * Called by the link function, inits the app
   * @returns {void}
   */
  init() {
    this.notificationOptions = {
      notificationType: "passwordReset",
      channels: {
        confirm: `User.${this.user.self}.remove`,
      },
    };
  }

  /**
   * Sets up pub/subscriptions
   * @returns {void}
   * @private
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(`User.${this.user.self}.remove`, () => {
      this._passwordReset();
    });
  }

  /**
   * Resets the users password
   * @private
   * @returns {void}
   */
  _passwordReset() {
    this.UsersService.passwordReset(this.config.type, this.user)
      .then(() =>
        this.NotificationService.notifyInfo(
          NotificationMessage.passwordResetMailSuccess
        )
      )
      .catch(() =>
        this.NotificationService.notifyError(NotificationMessage.generalError)
      );
  }
}

/**
 * Directive config
 * @returns {Object}
 */
function passwordResetComponent() {
  return {
    type: "E",
    controller: PasswordResetController,
    controllerAs: "component",
    scope: {},
    bindToController: {
      config: "=",
      user: "=",
    },
    template,
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default passwordResetComponent;
