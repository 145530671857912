import NotificationMessage from "skylarklib/constants/notification-text";

function AddRemoveItemsActions(
  SetContentsDispatcher,
  EntityTypeHelpersService,
  GlobalParamsService,
  DisplayTitleActions,
  ItemDecorationActions,
  SortingActions,
  NotificationService,
  SET_CONTENTS_ACTIONS
) {
  const factory = {};

  /**
   * addItems
   * @param {Object} contentItem
   */
  factory.addItem = (contentItem, entityType) => {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.addItem,
      contentItem,
      type:
        contentItem.set_type_slug ||
        EntityTypeHelpersService.getRawType(contentItem.self),
      currentLanguage: GlobalParamsService.getLanguage(),
    });

    ItemDecorationActions.decorateItems();
    ItemDecorationActions.assignAppearanceStatus();

    DisplayTitleActions.generateDisplayTitles().then(() => {
      SortingActions.sortItems();
    });

    const contentTypeString =
      NotificationService.createNotificationString(entityType);

    NotificationService.notifyInfo(
      NotificationMessage.addToSetSuccess(contentTypeString)
    );
  };

  /**
   * removeScheduledItem
   * @param {Object} scheduledItem
   */
  factory.removeScheduledItem = (scheduledItem) => {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.removeScheduledItem,
      scheduledItem,
    });

    ItemDecorationActions.decorateItems();
    SortingActions.sortItems();
  };

  /**
   * removeScheduledItem
   * @param {Array} scheduledItems
   */
  factory.removeMultipleScheduledItems = (scheduledItems) => {
    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.removeMultipleScheduledItems,
      scheduledItems,
    });

    ItemDecorationActions.decorateItems();
    SortingActions.sortItems();
  };

  return factory;
}

export default AddRemoveItemsActions;
