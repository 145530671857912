import template from "./history-breadcrumb.html";

class HistoryBreadcrumbCtrl {
  /**
   * @constructor
   * @param {Object} BreadcrumbService
   * @param {Object} MessageService
   * @returns {void}
   */
  constructor(BreadcrumbService, MessageService) {
    this.BreadcrumbService = BreadcrumbService;
    this.MessageService = MessageService;

    this._setupSubscriptions();
  }

  /**
   * Handles the set up of the history items for the directive based on how we navigated to this page
   * @private
   * @returns {void}
   */
  _setupHistoryItems() {
    if (this.BreadcrumbService.isBackLoop()) {
      this.BreadcrumbService.updateHistory(
        this.BreadcrumbService.previousBreadcrumb.items
      );
    }

    this.historyItems = this.BreadcrumbService.historyItems.slice(0);
  }

  /**
   * Sets up the directives pub/sub subscriptions
   * @private
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe("HistoryBreadcrumb.Update", () => {
      if (this.dropdownItems) {
        this.historyItems = [].concat(this.dropdownItems, this.historyItems);
      }

      this._chopdownBreadcrumb();
    });
  }

  /**
   * Handles the cut down of the breadcrumb. Splits the combined historyItems into a historyItems
   * array and an dropdownItems array based on the class property maximumBreadcrumbItemsToDisplay
   * @private
   * @returns {void}
   */
  _chopdownBreadcrumb() {
    if (this._isExceedingChopdownLength()) {
      const itemsToChop =
        this.BreadcrumbService.totalBreadcrumbItems() -
        this.maximumBreadcrumbItemsToDisplay -
        1;
      this.dropdownItems = this.historyItems.splice(0, itemsToChop);
      this.dropdownItems.reverse();
    }
  }

  /**
   * Checks to see if history items needs to be chopped down or not
   * @private
   * @returns {boolean}
   */
  _isExceedingChopdownLength() {
    return (
      this.BreadcrumbService.totalBreadcrumbItems() >
      this.maximumBreadcrumbItemsToDisplay + 1
    );
  }

  /**
   * Initialise values need by the class.
   * @returns {void}
   */
  init() {
    this._setupHistoryItems();
    this.maximumBreadcrumbItemsToDisplay = 5;

    this.BreadcrumbService.updateVisibility();

    this._chopdownBreadcrumb();
  }

  /**
   * Toggles the dropdown of additional items
   * @returns {void}
   */
  toggleDropdown() {
    this.isVisible = !this.isVisible;
  }

  /**
   * Called when the user uses the breadcrumb links to navigate through skylark
   * @param {object} item - the breadcrumb item clicked on
   * @returns {void}
   */
  breadcrumbNavigateTo(item) {
    this.BreadcrumbService.breadcrumbNavigateTo(item);
  }
}

/**
 * Angular directive definition
 * @returns {Object}
 * @constructor
 */
function HistoryBreadcrumbDirective() {
  return {
    scope: {},
    restrict: "E",
    replace: true,
    template,
    controller: HistoryBreadcrumbCtrl,
    controllerAs: "component",
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default HistoryBreadcrumbDirective;
