import angular from "angular";
import editDirective from "./directives/edit";
import {
  smartfield,
  smartfieldOptions,
  smartfieldTrigger,
} from "./directives/smartfield";
import typeDirective from "./directives/type";
import ContentFilter from "./factory/contentfilterfactory";

const Ui = angular
  .module("Skylark.Ui", [])
  .directive("edit", editDirective)
  .directive("smartfield", smartfield)
  .directive("smartfieldTrigger", smartfieldTrigger)
  .directive("smartfieldOptions", smartfieldOptions)
  .directive("type", typeDirective)
  .factory("ContentFilterFactory", ContentFilter);

export default Ui;
