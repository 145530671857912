class ModulePagingService {
  /**
   * Takes an array of url and returns a sub set to be fetched by the module
   * @param {Array} urls
   * @param {Number} start
   * @param {Number} amountToGet
   * @returns {Array}
   */
  urlsToGet(urls, startIndex = 0, amountToGet) {
    const endIndex = startIndex + amountToGet;
    const updatedUrls = urls.slice(startIndex, endIndex);

    return updatedUrls;
  }
}

export default ModulePagingService;
