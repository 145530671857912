/**
 * @classdesc
 * Handle article status and status changes.
 * Status are API provided objects, so the article status is determined by the reference
 * to the desired article status object via its self url
 * (set in the article's publish_status field).
 */
class ArticleStatusService {
  /**
   * @constructor
   * @param   {Object} ApiService
   */
  constructor(EntityFactory, ApiService, $q) {
    this.EntityFactory = EntityFactory;
    this.ApiService = ApiService;
    this.$q = $q;
    // this should be undefined on initialisation
    this._statuses = [];
  }

  /**
   * getStatusName
   * @param   {string} url
   * @returns {object}
   */
  getStatusName(url) {
    const deferred = this.$q.defer();
    if (this._statuses.length) {
      deferred.resolve(this._findStatus(url).title);
    } else {
      this._getStatuses().then((data) => {
        this._statuses = data.objects;
        deferred.resolve(this._findStatus(url).title);
      });
    }

    return deferred.promise;
  }

  /**
   * updateStatus
   * @param   {string} name
   * @param   {string} articleUid
   * @returns {Promise}
   */
  updateStatus(name, uid) {
    const data = {
      status_url: this._findStatusByName(name).self,
      uid,
    };

    return this.EntityFactory.patch("cms-articles", data);
  }

  /**
   * getStatuses
   * @returns {void}
   */
  _getStatuses() {
    return this.ApiService.get("/api/article-status/");
  }

  /**
   * _findStatusByName
   * @param   {string} name
   * @returns {Object}
   */
  _findStatusByName(name) {
    return this._statuses.find((status) => status.title === name);
  }

  /**
   * find a status object by url
   * @param   {string} url
   * @returns {Object}
   */
  _findStatus(url) {
    return this._statuses.find((status) => status.self === url);
  }
}

export default ArticleStatusService;
