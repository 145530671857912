import React from "react";
import PropTypes from "prop-types";

import styles from "./modal-header.scss";

/**
 * ModalHeader
 * @param {Object} props
 */
export default function ModalHeader(props) {
  return (
    <div className={styles["modal-header"]}>
      <h2 className={styles["modal-header__title"]}>{props.title}</h2>
    </div>
  );
}

ModalHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
