/**
 * fills the gap in angularJs not supported file type
 * the ngModel will hold the file as type File
 * this gives the flexibility to let other components do operations with that file
 * @param scope
 * @param element
 * @param attrs
 * @param ngModel
 */
function link(scope, element, attrs, ngModel) {
  element.on("change", (changeEvent) => {
    ngModel.$setViewValue(changeEvent.target.files[0]);
  });

  // empty input field when model is cleared
  ngModel.$render = () => {
    if (ngModel.$viewValue === undefined) {
      element.val("");
    }
  };
}

const inputDirective = () => ({
  restrict: "A",
  require: "ngModel",
  link,
});

const InputFile = angular
  .module("Skylark.Components.InputFile", [])
  .directive("inputFile", inputDirective);

export default InputFile;
