import { isUndefined } from "lodash";
import template from "./performance-detail-row.html";

class PerformanceDetailRowComponentCtrl {
  constructor(QuixService) {
    this.QuixService = QuixService;
  }

  $onInit() {
    const { displayedLanguage } = this.item;

    this.contentItem = this.item.contentItem[displayedLanguage];

    this.clickThroughCount = "—";
    this.playRateCount = "—";
    this.viewCountCount = "—";
    this.engagementCount = "—";
    this._getMetrics();
  }

  /**
   * Gets the metrics for the current object
   *
   * @return {void}
   */
  _getMetrics() {
    const { uid } = this.contentItem;
    const { type } = this.item;

    if (type !== "episodes") {
      this.QuixService.getClickThrough(uid).then((data) => {
        const count = data.timestamps.length;

        this.clickThroughCount = count;
      });

      return;
    }

    this.QuixService.getAllStringMetrics(uid).then((data) => {
      const { stringValues } = data;
      const { eventType } = stringValues;

      this.clickThroughCount = eventType.filter(
        (event) => event === "click through"
      ).length;
      this.playRateCount = eventType.filter(
        (event) => event === "play rate"
      ).length;
      this.viewCountCount = eventType.filter(
        (event) => event === "view count"
      ).length;
    });

    this.QuixService.getEngagement(uid).then((data) => {
      const { numericValues } = data;
      const engagement = numericValues["mean(engagement)"][0];
      this.engagementCount = isUndefined(engagement)
        ? "0%"
        : `${Math.floor(engagement * 100) / 100}%`;
    });
  }
}

const performanceDetailRowComponent = {
  bindings: {
    item: "<",
  },
  template,
  controller: PerformanceDetailRowComponentCtrl,
  controllerAs: "component",
};

export default performanceDetailRowComponent;
