import textInputTemplate from "components/+fields/text-input/text-input.html";
import longTextInputTemplate from "components/+fields/long-text-input/long-text-input.html";
import textareaTemplate from "components/+fields/textarea-input/textarea-input.html";
import dropdownTemplate from "components/+fields/dropdown-select/dropdown-select.html";
import relationshipDropdownTemplate from "components/+fields/relationship-dropdown/relationship-select.html";
import checkboxTemplate from "components/+fields/checkbox-input/checkbox-input.html";
import checkboxCustomTemplate from "components/+fields/checkbox-custom/checkbox-custom.html";
import scheduleTemplate from "views/widgets/fields/schedule-button.html";
import radioTemplate from "components/+fields/radio-input/radio-input.html";
import datefieldTemplate from "components/+fields/+date-input/date-input-field.html";
import dateandtimefieldTemplate from "components/+fields/+date-time/data-time-field.html";
import smartfieldTemplate from "views/widgets/fields/smartfield.html";
import multiplecheckboxTemplate from "components/+fields/multiple-checkbox/multiple-checkbox.html";
import multiplecheckboxalphaTemplate from "components/+fields/multiple-checkbox/multiple-checkbox-alpha.html";
import collectionfieldTemplate from "components/+fields/collection-field/collection-field.html";
import imagehandlerTemplate from "components/+fields/image-uploader/image-uploader.html";
import filepickerTemplate from "components/+fields/file-picker/file-picker-field.html";
import placeholderTemplate from "components/+fields/placeholder/placeholder.html";
import commaseparatedtextTemplate from "components/+fields/textarea-input/textarea-input-comma-separated.html";
import imagewithmetadataTemplate from "components/+fields/image-with-metadata/image-with-metadata-field.html";
import passwordResetTemplate from "components/+fields/password-reset/password-reset-field.html";

const DYNAMIC_FIELD_TYPES = {
  textfield: {
    type: "textfield",
    template: textInputTemplate,
  },
  longtextfield: {
    type: "longtextfield",
    template: longTextInputTemplate,
  },
  textarea: {
    type: "textarea",
    template: textareaTemplate,
  },
  dropdown: {
    type: "dropdown",
    template: dropdownTemplate,
  },
  relationshipDropdown: {
    type: "relationshipDropdown",
    template: relationshipDropdownTemplate,
  },
  checkbox: {
    type: "checkbox",
    template: checkboxTemplate,
  },
  checkboxCustom: {
    type: "checkboxCustom",
    template: checkboxCustomTemplate,
  },
  schedule: {
    type: "scheduleButton",
    template: scheduleTemplate,
  },
  radio: {
    type: "radio",
    template: radioTemplate,
  },
  datefield: {
    type: "datefield",
    template: datefieldTemplate,
  },
  dateandtimefield: {
    type: "dateandtimefield",
    template: dateandtimefieldTemplate,
  },
  smartfield: {
    type: "smartfield",
    template: smartfieldTemplate,
  },
  multiplecheckbox: {
    type: "multiplecheckbox",
    template: multiplecheckboxTemplate,
  },
  multiplecheckboxalpha: {
    type: "multiplecheckboxalpha",
    template: multiplecheckboxalphaTemplate,
  },
  collectionfield: {
    type: "collectionfield",
    template: collectionfieldTemplate,
  },
  imagehandler: {
    type: "imagehandler",
    template: imagehandlerTemplate,
  },
  filepicker: {
    type: "filepicker",
    template: filepickerTemplate,
  },
  placeholder: {
    type: "placeholder",
    template: placeholderTemplate,
  },
  commaseparatedtext: {
    type: "commaseparatedtextfield",
    template: commaseparatedtextTemplate,
  },
  imagewithmetadata: {
    type: "imagewithmetadata",
    template: imagewithmetadataTemplate,
  },
  passwordreset: {
    type: "passwordreset",
    template: passwordResetTemplate,
  },
};

export default DYNAMIC_FIELD_TYPES;
