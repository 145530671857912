import React from "react";
import PropTypes from "prop-types";
import styles from "./block-indicator.scss";

/**
 * BlockIndicator
 * @param {Object} props
 */
export default function BlockIndicator(props) {
  return (
    <span
      className={styles["block-indicator"]}
      suppressContentEditableWarning="true"
      contentEditable="false"
      readOnly="readonly"
      data-indicator={props.indicator.toUpperCase()}
    />
  );
}

BlockIndicator.propTypes = {
  indicator: PropTypes.string.isRequired,
};
