import * as EntityAction from "store/entity/entity.action";
import NotificationMessage from "skylarklib/constants/notification-text";
import * as EntitiesConfigSelector from "store/entities-config/entities-config.selector";
import * as EntitySelector from "store/entity/entity.selector";
import template from "./metadata-module.html";

/**
 * @class MetadataController
 */
class MetadataController {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $location
   * @param {Object} $scope
   * @param {Object} $state
   * @param {Object} $element
   * @param {Object} $q
   * @param {Object} ReduxConnector
   * @param {Object} GlobalParamsService
   * @param {Object} MessageService
   * @param {Object} MetadataService
   * @param {Object} NotificationService
   * @param {Object} EntityFactory
   * @param {Object} MetadataFactory
   * @param {Object} MetadataCreateArticlesService
   * @param {Object} RouteService
   * @param {Object} DataSourceFactory
   * @param {Object} UtilitiesService
   */
  constructor(
    _,
    $location,
    $scope,
    $state,
    $element,
    $q,
    ReduxConnector,
    GlobalParamsService,
    MessageService,
    MetadataService,
    NotificationService,
    EntityFactory,
    MetadataFactory,
    MetadataCreateArticlesService,
    RouteService,
    DataSourceFactory,
    UtilitiesService,
    EntityVersionsService
  ) {
    this._ = _;
    this.$location = $location;
    this.$scope = $scope;
    this.$state = $state;
    this.$element = $element;
    this.$q = $q;
    this.ReduxConnector = ReduxConnector;
    this.GlobalParamsService = GlobalParamsService;
    this.MessageService = MessageService;
    this.MetadataService = MetadataService;
    this.NotificationService = NotificationService;
    this.EntityFactory = EntityFactory;
    this.MetadataFactory = MetadataFactory;
    this.MetadataCreateArticlesService = MetadataCreateArticlesService;
    this.RouteService = RouteService;
    this.DataSourceFactory = DataSourceFactory;
    this.UtilitiesService = UtilitiesService;
    this.EntityVersionsService = EntityVersionsService;

    // react functions caveat, it needs a bind to this otherwise the passed town function looses the context
    this.toggleDataSource = this.toggleDataSource.bind(this);
    this.isNewInstance = !this.EntityFactory.getEntityId();
    this.entityName = this.EntityFactory.getEntityName();
    this.entityType = this.EntityFactory.getEntityType();
    this.initialConfig = this._.clone(this.config);
    this.selectedVersion = {};

    this.connectToStore();
  }

  /**
   * Called by directive link function
   * @returns {void}
   */
  $onInit() {
    this._copyInitialStates();

    if (this.isNewInstance) {
      this._fillSlug();
    }

    if (!this.isNewInstance) {
      this._getData().then(() => this._buildDisplayTitle());
    } else {
      this._buildDisplayTitle();
    }

    // Create a dummy ovps so that something displays on the versions tab (Added for ATAS Brightcove)
    this.autoCreateDummyOvpForAsset =
      this.entityName === "assets" &&
      this._.has(this.baseConfig, "auto_create_dummy_ovp") &&
      this.baseConfig.auto_create_dummy_ovp;
    if (this.autoCreateDummyOvpForAsset) {
      this._getAccounts();
    }

    this._setupSubscriptions();
  }

  /**
   * Setup the pub/sub subscriptions
   * @returns {void}
   * @private
   */
  _setupSubscriptions() {
    const cancelEditStateChannel = `${this.entityData.uid}.Metadata.CancelEdit`;

    this.MessageService.subscribe(cancelEditStateChannel, () => {
      this.cancel();
    });

    this.watchData = this.$scope.$watch(
      () => this.entityData,
      () => {
        this._copyInitialStates();
      }
    );
  }

  /**
   * loads all accounts
   *
   * @return {Promise}
   */
  _getAccounts() {
    return this.EntityVersionsService.getAccounts().then((data) => {
      this.accounts = data.objects;
      return data;
    });
  }

  /**
   * @returns {void}
   */
  _buildDisplayTitle() {
    this.MetadataService.buildTitleSuffix(
      this.entityData.language,
      this.config,
      this.isNewInstance,
      this.isCreating
    ).then((titleSuffix) => {
      this.displayTitle = `${this.config.display_name} ${titleSuffix}`;
    });
  }

  /**
   * Save button callback
   * @param {Object} form
   * @param {Object} formController
   * @returns {void|promise}
   */
  save(form, formController) {
    if (!this._isFormValid(form)) {
      return formController.scrollToError(form);
    }

    if (this.isNewInstance) {
      return this._create();
    }

    const dataChanged = !this._.isEqual(
      this._filterDataByConfigFields(this.entityData),
      this.data
    );
    const differentLanguage =
      this.GlobalParamsService.getLanguage() !== this.entityData.language;

    if (dataChanged || differentLanguage) {
      return this._update();
    }

    return this.cancel();
  }

  /**
   * Create a new entity
   * @private
   */
  _create() {
    this.prepareDataForCreation().then((data) => {
      this.store.saveCreatedEntity(
        data,
        this.entityName,
        this._buildErrorMessage.bind(this)
      );
    });
  }

  /**
   * attaching extra data to form data
   * like entity type or article status
   *
   * an object is returned which is merged together from the form data, and async or resolvers
   * current resolvers are:
   *    entity type data
   *    article status
   * @return {Promise<Object>}
   */
  prepareDataForCreation() {
    const requests = [this.data];

    const addTypeRequest = this.EntityFactory.getType(
      this.entityName,
      this.entityType
    ).then((type) => {
      if (!type) {
        return;
      }

      const data = {};
      const typeUrlKey = this.EntityFactory.getEntityTypeUrlKey(
        this.entityName
      );

      if (this.autoCreateDummyOvpForAsset) {
        data.ovps = [{ account_url: this.accounts[0].self }];
      }

      data[typeUrlKey] = type.self;

      return data;
    });
    requests.push(addTypeRequest);

    if (this.entityName === "cms-articles" || this.entityName === "articles") {
      const articleData =
        this.MetadataCreateArticlesService.prepareDataForCreation(this.data);
      requests.push(articleData);
    }

    return this.$q.all(requests).then((data) => this._.merge(...data));
  }

  /**
   * Update entity
   * @private
   * @returns {void}
   */
  _update() {
    const unwatchSave = this.$scope.$watch(
      () => this.isSaving,
      (newData, oldData) => {
        if (!newData && oldData) {
          this._copyInitialStates();
          this._getData().then(() => this._buildDisplayTitle());
          this._notifyDependents();
          unwatchSave();
        }
      }
    );

    const unwatchCreated = this.$scope.$watch(
      () => this.isCreating,
      (newData, oldData) => {
        if (!newData && oldData) {
          this.$state.reload();
          unwatchCreated();
        }
      },
      true
    );

    const successMessage = this._buildSuccessMessage.bind(this);
    const errorMessage = this._buildErrorMessage.bind(this);

    if (this.GlobalParamsService.getLanguage() !== this.entityData.language) {
      // merge entityData with formData so that additional metadata doesn't get overwritten
      const newLanguageData = this._.merge({}, this.entityData, this.data);
      this.store.addLanguageVersion(
        this.entityName,
        this.entityData.uid,
        newLanguageData,
        successMessage,
        errorMessage
      );
      return;
    }

    this.store.updateEntity(
      this.entityData.self,
      this._getChangedData(),
      null,
      successMessage,
      errorMessage
    );
  }

  /**
   * Called by cancel button
   * @access public
   * @returns {void}
   */
  cancel() {
    if (this.isCreating) {
      this.store.cancelCreation();
      const url = this.RouteService.buildURL({
        name: this.entityName,
        type: this.entityType,
      });
      this.$location.path(url);
    } else {
      this._reset();
      this.MessageService.publish(`form.toggleEdit.${this.type}`);
    }
  }

  /**
   * generate slug from heading_field or `title` when config has no heading_field
   */
  _fillSlug() {
    let titleField = "title";

    if (this.baseConfig && this.baseConfig.heading_field) {
      titleField = this.baseConfig.heading_field;
    }

    if (this.type !== "language-versions") {
      return;
    }

    this.$scope.$watch(`module.data.${titleField}`, (title) => {
      this.generateSlug(title);
    });

    this.$element.on("blur", `[name='${titleField}']`, () => {
      if (this.data.slug.length > 0) {
        this.isSlugLocked = true;
      }
    });
  }

  /**
   * Updated slug based on title
   * @returns {void}
   */
  generateSlug(title = "") {
    if (this.isNewInstance && !this.isSlugLocked) {
      const spaces = /\s+/g;
      const invalidChars = /[^a-zA-Z0-9_-]/g;
      this.data.slug = title
        .replace(spaces, "-")
        .replace(invalidChars, "")
        .toLowerCase();
    }
  }

  /**
   * Loads a specific data version into the module
   * @param {number} itemIndex
   * @returns {void}
   */
  loadDataVersion(itemIndex) {
    this.MetadataFactory.getDataVersion(
      this.entityData.uid,
      this.type,
      itemIndex
    ).then((data) => {
      this._setSelectedVersion(data);
      this._setData(data);
    });
  }

  /**
   * Takes a copy restore on cancel
   * @private
   * @returns {void}
   */
  _copyInitialStates() {
    this._setData(this.entityData);
    this.config = this._.cloneDeep(this.initialConfig);
  }

  /**
   * set data for the form,
   * which will be filtered down to the items defined in the config
   * @param {Object} data
   * @private
   */
  _setData(data) {
    this.data = this._filterDataByConfigFields(data);
  }

  /**
   * Gets the latest version of data available
   * @private
   * @returns {Promise}
   */
  _getData() {
    return this.MetadataFactory.getAllVersions(this.entityData, this.type)
      .then((availableVersions) => {
        this.availableVersions = angular.copy(availableVersions).reverse();
        this._setSelectedVersion(
          this.MetadataFactory.getCurrentVersion(this.type)
        );
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * filter items out which have changed
   * when data source fields have changed,
   * or a field part of the data source fields,
   * then all fields in data_source_fields need to be included, and the data_source_id
   * @return {Object}
   */
  _getChangedData() {
    const filteredData = this._filterDataByConfigFields(this.entityData);
    const changedData = this._.pickBy(
      this.data,
      (value, key) => value !== filteredData[key]
    );
    const hasDataSourceFieldToggled =
      Array.isArray(changedData.data_source_fields) &&
      changedData.data_source_fields.length > 0;
    const hasDataSourceFieldChanged = this.UtilitiesService.includesOneOf(
      filteredData.data_source_fields,
      Object.keys(changedData)
    );

    if (hasDataSourceFieldToggled || hasDataSourceFieldChanged) {
      changedData.data_source_id = this.entityData.data_source_id;
      changedData.data_source_fields =
        changedData.data_source_fields || this.entityData.data_source_fields;
      changedData.data_source_fields.forEach((field) => {
        changedData[field] = this.data[field] || this.entityData[field];
      });
    }
    return changedData;
  }

  /**
   * set selected version object for the dropwdown, to pick from different versions
   * this object only needs a number and modified time
   * @param data
   * @private
   */
  _setSelectedVersion(data) {
    let pick = [];
    if (this.type === "language-versions") {
      pick = ["language_version_number", "language_modified"];
    }
    if (this.type === "versions") {
      pick = ["version_number", "metadata_modified"];
    }

    this.selectedVersion = this._.pick(data, pick);
  }

  /**
   * Reset to initial data
   * @returns {void}
   * @private
   */
  _reset() {
    this._setSelectedVersion(this.MetadataFactory.getCurrentVersion(this.type));
    this._copyInitialStates();
  }

  /**
   * Check if form element is valid
   * @param {Object} form
   * @returns {boolean}
   * @private
   */
  _isFormValid(form) {
    form.$setSubmitted();

    return form.$valid;
  }

  /**
   * build success message
   * @param data
   * @private
   */
  _buildSuccessMessage(data) {
    return this.MetadataFactory.buildSuccessMessage(data, this.type);
  }

  /**
   * build error message
   * @private
   * @param {object} response
   * @returns {string}
   */
  _buildErrorMessage(response) {
    try {
      const invalidSlug = this._.get(JSON.parse(response.error), "slug");

      if (invalidSlug) {
        return NotificationMessage.invalidSlugError;
      }
    } catch (e) {
      return NotificationMessage.generalError;
    }

    return NotificationMessage.generalError;
  }

  /**
   * Notify dependents of changes
   * @private
   * @returns {void}
   */
  _notifyDependents() {
    this.MessageService.publish(this.updateDataChannel, this.entityData);
    this.MessageService.publish("UpdateFields", this.entityData);
    this.MessageService.publish(
      `${this.entityData.uid}.saveMetadata`,
      this.entityData
    );
    this.MessageService.publish(`form.toggleEdit.${this.type}`);
  }

  /**
   * filter input data by configuration fields
   * will return only the data properties defined in the config
   * @param {Object} data
   * @return {Object}
   */
  _filterDataByConfigFields(data) {
    if (!this.config || !this.config.fields) {
      return {};
    }

    const configFieldNames = this._.flatten(
      this.config.fields.map((field) => {
        if (field.name) {
          return field.name;
        }

        // date and time component
        if (field.names) {
          return field.names;
        }
      })
    );

    const additionalFields = ["data_source_fields", "data_source_id"];

    return this._.pick(data, [...configFieldNames, ...additionalFields]);
  }

  /**
   * toggle data source fields with the passed in fields
   * only when form is in edit mode
   * @param updateField
   * @param isEditing
   */
  toggleDataSource(updateField, isEditing) {
    if (isEditing) {
      this.data.data_source_fields = this.DataSourceFactory.toggleDataSource(
        this.data.data_source_fields,
        updateField
      );
    }
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };

    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  /**
   * maps state of store to this
   * @param state
   */
  mapStateToThis(state) {
    const { loading, data, creating } = EntitySelector.getEntity(state);
    return {
      isSaving: loading,
      entityData: data,
      isCreating: creating,
      baseConfig: EntitiesConfigSelector.getBaseConfig(
        state,
        this.entityName,
        this.entityType
      ).data,
    };
  }

  $onDestroy() {
    this.disconnect();
    this.watchData();
  }
}

/**
 * Directive config
 * @returns {object} - metadata directive config
 */
const metadataComponent = {
  bindings: {
    config: "<",
    type: "@",
    title: "@",
    index: "<",
  },
  controller: MetadataController,
  controllerAs: "module",
  template,
};

export default metadataComponent;
