/**
 * buildCountSearchParam
 */
function buildCountSearchParam(options) {
  const { q } = options.filters;

  return q ? { filters: { q } } : {};
}

export { buildCountSearchParam };
