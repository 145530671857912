import Api from "skylarklib/helpers/api/api";

/**
 * getAlwaysSchedule
 * @return {promise} Api Service Get
 */
function getAlwaysSchedule() {
  return Api.get("/api/schedules/always/");
}

export { getAlwaysSchedule };
