import angular from "angular";
import changelogConstants from "./changelog.constants";
import ChangelogService from "./changelog.service";
import ChangelogComponent from "./changelog.component";

const Changelog = angular
  .module("Skylark.Components.Changelog", [])
  .constant("CHANGELOG_CONSTANTS", changelogConstants)
  .service("ChangelogService", ChangelogService)
  .component("changelog", ChangelogComponent);

export default Changelog;
