import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./listing-item-block.html";

class ListingItemBlockCtrl {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} $location
   * @param {Object} $scope
   * @param {Object} EntityFactory
   * @param {Object} MessageService
   * @param {Object} NotificationService
   */
  constructor(
    _,
    $location,
    $scope,
    EntityFactory,
    MessageService,
    NotificationService,
    SchedulingService,
    CHANGELOG_CONSTANTS
  ) {
    this._ = _;
    this.$location = $location;
    this.$scope = $scope;
    this.EntityFactory = EntityFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.SchedulingService = SchedulingService;
    this.ChangelogConstants = CHANGELOG_CONSTANTS;
  }

  /**
   * Bind params
   * @returns {void}
   */
  $onInit() {
    this.currentUrl = this.$location.path();

    this._setupSubscriptions();
    this._buildModalOptions();
    this._setScheduleStatus();
    if (this.data.all_languages) {
      this._buildLanguageIndicator();
    }
  }

  /**
   * Sets the schedule status
   * @TODO: make this work with multiple schedules
   * @private
   */
  _setScheduleStatus() {
    if (this.data.schedule_urls && this.data.schedule_urls.length) {
      this.hasAvailableSchedule = this.data.schedule_urls.some((schedule) =>
        this.SchedulingService.isAvailable(schedule)
      );
      this.hasExpiredSchedule = this.data.schedule_urls.some((schedule) =>
        this.SchedulingService.isExpired(schedule)
      );
    } else {
      this.hasExpiredSchedule = true;
    }
  }

  /**
   * sets a string with all the languages versions available on this item
   * @private
   * @returns {void}
   */
  _buildLanguageIndicator() {
    const languages = this.data.all_languages;
    const hasMoreThanThreeLanguages = languages.length > 3;
    const languageSelection = hasMoreThanThreeLanguages
      ? languages.slice(0, 3)
      : languages;
    const languageString = languageSelection.join(", ").toUpperCase();

    this.languageIndicator = hasMoreThanThreeLanguages
      ? `${languageString}...`
      : languageString;
  }

  /**
   * set which channels to listen on and what methods to call on events
   * @returns {void}
   * @private
   */
  _setupSubscriptions() {
    const listUpdateChannelName = `itemblock:${this.data.uid}-haschanged`;
    const listDeleteChannelName = `deleteSelf.${this.data.uid}`;

    this.MessageService.subscribe(listUpdateChannelName, (channel, data) => {
      this._updateEntity(data);
    });

    this.MessageService.subscribe(listDeleteChannelName, (channel, data) => {
      this._deleteEntity(data);
    });

    this.$scope.$on("$destroy", () => {
      this._unregisterSubscriptions();
    });
  }

  /**
   * Removes subscription channels
   * @private
   * @returns {void}
   */
  _unregisterSubscriptions() {
    const listUpdateChannelName = `itemblock:${this.data.uid}-haschanged`;
    const listDeleteChannelName = `deleteSelf.${this.data.uid}`;

    this.MessageService.unregisterChannel(listUpdateChannelName);
    this.MessageService.unregisterChannel(listDeleteChannelName);
  }

  /**
   * Builds the options for the modal
   * @private
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: {
        save: `itemblock:${this.data.uid}-haschanged`,
      },
      parent: {
        parentId: this.data.uid,
        parentTitle: this.data.title,
      },
      entity: this.config.name,
      has_datasource: true,
    };
  }

  /**
   * Builds the link url
   * @returns {String}
   */
  buildUrl() {
    return this.context ? "#" : `${this.currentUrl}${this.data.uid}/`;
  }

  /**
   * deletes entity
   * @param {Object} data
   * @returns {void}
   */
  _deleteEntity(data) {
    const name = this._getEntityName(data);

    this.EntityFactory.deleteByUrl(data.self)
      .then(() => {
        this.NotificationService.notifyInfo(
          NotificationMessage.deleteSuccess(name)
        );
        this.MessageService.publish("EntityListing.Delete", data);
      })
      .catch(() => {
        this.NotificationService.notifyError(
          NotificationMessage.deleteError(data.name)
        );
      });
  }

  /**
   * updates and entity
   * @param {Object} data
   * @returns {void}
   */
  _updateEntity(data) {
    const name = this._getEntityName(data);
    this.data = { ...this.data, ...data };

    this.EntityFactory.updateByUrl(data.self, this.data)
      .then(() => {
        this.NotificationService.notifyInfo(
          NotificationMessage.updateSuccess(name)
        );
        this.initialData = this.data;

        this.MessageService.publish("EntityListing.Update", data);
        this.MessageService.publish(`RoleExpander.Update.${this.data.uid}`);
        this.MessageService.publish(
          `ScheduleWidget.Update.${this.data.uid}`,
          this.data
        );
      })
      .catch(() => {
        this.NotificationService.notifyError(
          NotificationMessage.updateError(data.name)
        );
      });
  }

  /**
   * _getEntityName
   * @private
   * @param   {Object} data - entity data
   * @returns {string} the name to use in notifications
   */
  _getEntityName(data) {
    return data.name || data.title || this.displayFullName(data);
  }

  /**
   * build the display of a full name
   * @param data
   * @return {string}
   */
  displayFullName(data) {
    return (
      data.full_name || `${data.first_name || "---"} ${data.last_name || "---"}`
    );
  }

  /**
   * Takes in input a field name from the config
   * that can me "title" or a nested field like "award_category_url__award_name"
   *
   * @return {string}
   */
  getFieldValue(name) {
    return this._.get(this.data, name, "---");
  }

  onPositionKeyUp($event) {
    if ($event.keyCode === 13) {
      const newPosition = Number($event.target.value);
      this.onPositionChange({ newPosition });
    }
  }
}

const ListingItemBlockComponent = {
  bindings: {
    data: "=",
    config: "=",
    context: "=",
    isEditable: "=",
    isReadOnly: "=",
    isInteractive: "<",
    onPositionChange: "&?",
    hideForCognito: "=",
  },
  template,
  controller: ListingItemBlockCtrl,
  controllerAs: "component",
};

export default ListingItemBlockComponent;
