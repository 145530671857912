/**
 * @fileOverview Modal Trigger Service
 * The ModalTriggerService can be injected in any controller when a modal needs
 * to be triggered programatically, as opposed to a typical DOM trigger approach.
 * It provides a public interface to trigger specific  modal instances
 * in order to deal with the large number of arguments required for triggering modals.
 */

class ModalTriggerService {
  /**
   * @constructor
   * @param   {Object} ModalService
   * @param   {Object} MessageService
   */
  constructor(ModalService, MessageService) {
    this.ModalService = ModalService;
    this.MessageService = MessageService;
  }

  /**
   * triggerModal
   * @description  a wrapper for init modal to trigger modals
   * programatically from controllers, when a promise is not needed.
   * @access public
   * @param {object} data - data
   * @param {object} config - config
   * @param {string} type - the type of modal
   * @param {string} entityType - the entityType
   * @param {object} options - other required options
   * @returns {void}
   */
  triggerModal(data, config, type, entityType, options) {
    this.ModalService.initNewModal(
      data,
      config,
      type,
      entityType,
      options
    ).then((modalInstance) => {
      this.MessageService.publish("skylark.initModal", modalInstance);
    });
  }

  /**
   * triggerNotification
   * @access public
   * @param {Object} options - a modal options object
   * @returns {function} triggerModal
   */
  triggerNotification(options) {
    return this.triggerModal(null, {}, "notification", null, options);
  }

  /**
   * triggerList
   * @param   {string} entityType - the type of entity for the list
   * @param   {object} options - modal options
   * @returns {function}
   */
  triggerList(entityType, options) {
    return this.triggerModal(null, null, "list", entityType, options);
  }
}

export default ModalTriggerService;
