import { TOASTER_TYPES } from "skylarklib/constants/toaster.constants";

/**
 * Builds the text to display in the error toast
 * @param {String} entityTitle
 * @param {String} messageType
 * @param {Boolean} multiple
 * @return {String} message to display in toast
 */
function _buildInfo(entityTitle, messageType, multiple) {
  const { successAction, prefix } = TOASTER_TYPES[messageType];
  const copyVerb = multiple || prefix.length ? "have" : "has";
  const title = multiple ? "Multiple items" : entityTitle;

  return `${prefix}${title} ${copyVerb} been successfully ${successAction}`;
}

/**
 * Builds the text to display in the error toast
 * @param {String} entityTitle
 * @param {String} messageType
 * @param {Boolean} multiple
 * @return {String} message to display in toast
 */
function _buildError(entityTitle, messageType, multiple) {
  const title = multiple ? "multiple items" : entityTitle;
  const { errorAction } = TOASTER_TYPES[messageType];

  return `Something went wrong while ${errorAction} ${title}`;
}

/**
 * Builds the text to display in the toast
 * @param {String} entityTitle
 * @param {String} messageType
 * @param {String} toasterType
 * @param {Boolean} multiple
 * @return {String} message to display in toast
 */
function build(entityTitle, messageType, toasterType, multiple) {
  return toasterType === "info"
    ? _buildInfo(entityTitle, messageType, multiple)
    : _buildError(entityTitle, messageType, multiple);
}

export { build };
