import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./tooltip.scss";

/**
 * TooltipWrapper
 * @param {Class} ComposedTooltip
 * @returns {Class}
 */
export default function TooltipWrapper(ComposedTooltip) {
  return class Tooltip extends Component {
    /**
     * propTypes
     * @type {Object}
     */
    static propTypes = {
      toggleTooltip: PropTypes.func.isRequired,
      position: PropTypes.shape({
        top: PropTypes.number,
      }),
    };

    /**
     * defaultProps
     * @type {Object}
     */
    static defaultProps = {
      position: undefined,
    };

    /**
     * @constructor
     * @param   {Object} props
     */
    constructor(props) {
      super(props);

      this.setWrapperRef = this.setWrapperRef.bind(this);
      this.handleOutsideClick = this.handleOutsideClick.bind(this);
      this.handleEscapeKey = this.handleEscapeKey.bind(this);
    }

    /**
     * componentDidMount
     */
    componentDidMount() {
      document.addEventListener("mousedown", this.handleOutsideClick);
      document.addEventListener("keyup", this.handleEscapeKey);
    }

    /**
     * componentWillUnmount
     */
    componentWillUnmount() {
      document.removeEventListener("mousedown", this.handleOutsideClick);
      document.removeEventListener("keyup", this.handleEscapeKey);
    }

    /**
     * setWrapperRef
     * @param {DOMElement} node
     */
    setWrapperRef(node) {
      this.wrapperRef = node;
    }

    /**
     * handleOutsideClick
     * @callback  mousedown
     * @param   {Event} event
     */
    handleOutsideClick(event) {
      if (
        this.wrapperRef &&
        !this.wrapperRef.contains(event.target) &&
        !this._isButtonClick(event)
      ) {
        this.props.toggleTooltip();
      }
    }

    /**
     * _isButtonClick
     * @param   {Event} event
     * @todo    this needs to be less coupled to links so that it's more reusable
     * @returns {Boolean}
     */
    _isButtonClick(event) {
      const linkButton = document.getElementById("LINK");

      return (
        linkButton &&
        (event.target === linkButton ||
          event.target === linkButton.getElementsByTagName("svg")[0])
      );
    }

    /**
     * handleEscapeKey
     * @param   {Object} event
     */
    handleEscapeKey(event) {
      if (event.keyCode === 27) {
        this.props.toggleTooltip();
      }
    }

    /**
     * render
     * @returns {JSX}
     */
    render() {
      return (
        <div
          className={classnames(styles.tooltip)}
          style={this.props.position}
          ref={this.setWrapperRef}
        >
          <ComposedTooltip
            {...this.props}
            toggleTooltip={this.props.toggleTooltip}
          />
        </div>
      );
    }
  };
}
