import template from "./data-prediction-row.html";

class DataPredictionRowComponentCtrl {
  constructor(
    SetContentsActionCreators,
    ApiRequestConfigFactory,
    SetContentsStore,
    MessageService,
    QuixService,
    ApiService
  ) {
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.SetContentsStore = SetContentsStore;
    this.MessageService = MessageService;
    this.QuixService = QuixService;
    this.ApiService = ApiService;
  }

  $onInit() {
    const { displayedLanguage } = this.item;

    this.loading = false;
    this.contentItem = this.item.contentItem[displayedLanguage];

    this._getComparedScore();
  }

  /**
   *
   *
   */
  async _getComparedScore() {
    this.loading = true;
    if (this.item.type === "episodes") {
      try {
        const { parent_url: season } = await this.ApiService.get(
          `${this.item.contentUrl}`,
          this.ApiRequestConfigFactory.createRequestConfig({
            requestConfig: {
              params: {
                fields:
                  "parent_url,parent_url__season_number,parent_url__parent_url,parent_url__parent_url__slug",
                fields_to_expand: "parent_url,parent_url__parent_url",
              },
            },
          })
        );

        const { season_number: seasonNumber, parent_url: brand } = season;
        const { slug } = brand;

        const location = `brands/${slug}/seasons/${seasonNumber}/episodes`;
        const streams = await this.QuixService.getStreamsByLocation(location);
        const streamsIds = streams.map(({ streamId }) => streamId);

        const engagementsData = await this.QuixService.getEngagement(
          null,
          streamsIds
        );
        const { tagValues, numericValues } = engagementsData;
        const { uid } = this.contentItem;
        const engagements = {};

        let max = 0;
        let maxId = uid;

        tagValues.objectId.forEach((objectId, index) => {
          const engagement = numericValues["mean(engagement)"][index];
          engagements[objectId] = engagement;

          if (engagement > max) {
            max = engagement;
            maxId = objectId;
          }
        });

        this.currentItemEngagement = this._intToEngagement(engagements[uid]);

        if (maxId !== uid) {
          const comparedItem = await this.ApiService.get(
            `/api/episodes/${maxId}/`,
            this.ApiRequestConfigFactory.createRequestConfig({
              requestConfig: {
                params: {
                  fields: "uid,title,self,expired,future,schedule_urls",
                  fields_to_expand: "schedule_urls",
                },
              },
            })
          );

          this.comparedItem = {
            ...comparedItem,
            engagement: this._intToEngagement(max),
          };

          this.engagementDelta = this._intToEngagement(max - engagements[uid]);
        }
      } catch (e) {
        console.error(e);
      }
    }
    this.loading = false;
  }

  _intToEngagement(engagement) {
    return `${Math.floor(engagement * 100) / 100}%`;
  }

  switchItem() {
    this.loading = true;

    const { position } = this.item.scheduledItem[this.item.displayedLanguage];

    const comparedItem = {
      ...this.comparedItem,
      isSelected: false,
    };

    this.SetContentsActionCreators.toggleEditMode();

    this.MessageService.publish("Modal.Save.setContent.base", [comparedItem]);

    const { unplacedItems } = this.SetContentsStore.getState();
    const unplacedComparedItem = unplacedItems.find(
      (item) => item.uid === `new.${this.comparedItem.uid}`
    );

    unplacedComparedItem.scheduledItem[this.item.displayedLanguage].position =
      position;

    this.SetContentsActionCreators.positionItem(
      unplacedComparedItem,
      position,
      0
    );

    this.SetContentsActionCreators.removeScheduledItem(this.item);

    this.SetContentsActionCreators.saveSetData();

    this.loading = false;
  }
}

const dataPredictionRowComponent = {
  bindings: {
    item: "<",
  },
  template,
  controller: DataPredictionRowComponentCtrl,
  controllerAs: "component",
};

export default dataPredictionRowComponent;
