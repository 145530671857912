/**
 * @constructor
 * @param {Object} SetContentsStore
 * @param {Object} SetContentsDispatcher
 * @param {Object} SetsFactory
 * @param {Object} SET_CONTENTS_ACTIONS
 * @param {Object} DEFAULT_ITEM_CONFIG
 */
function ItemDecorationActions(
  UiSharedService,
  SetContentsStore,
  SetContentsDispatcher,
  SetsFactory,
  SchedulingService,
  SET_CONTENTS_ACTIONS,
  DEFAULT_ITEM_CONFIG
) {
  const factory = {};

  /**
   * Assign config to each item
   * Check if the item has `entity` property,
   * if so use it to compare `type`, otherwise use `name`
   * This condition is provided to solve configuration
   * problems with objects like Assets and Articles which
   * have a general `type` property.
   * @return {void}
   * @private
   */
  function _assignConfigToItems() {
    const state = SetContentsStore.getState();
    const { config } = state;
    const flatConfig = SetsFactory.flattenConfig(config);

    const items = state.items.map((item) => {
      const config = flatConfig.find((configItem) => {
        const fieldToCompare = configItem.entity
          ? configItem.entity
          : configItem.name;

        return fieldToCompare === item.type;
      });

      return {
        ...item,
        config: config || DEFAULT_ITEM_CONFIG,
        hasConfigError: !config,
      };
    });

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.assignConfigToItems,
      items,
    });
  }

  /**
   * Check if any of the appearance fields have an value set
   * @param {Array} fields
   * @param {Object} item
   * @return {boolean}
   * @private
   */
  function _checkAppearanceForValues(fields, item) {
    const comibinedData = {
      ...item.contentItem[Object.keys(item.contentItem)[0]],
      ...item.scheduledItem[Object.keys(item.scheduledItem)[0]],
    };

    return fields
      .map((field) => field.name)
      .some((field) => !!comibinedData[field]);
  }

  /**
   * _isActive - if expired and future are both false, the content item is current
   * @param  {Object} item
   * @return {boolean}
   */
  function _isCurrent(item) {
    return _hasSchedules(item) && !item.expired && !item.future;
  }

  /**
   * _hasSchedules
   * @param  {Object} item
   * @return {Boolean}
   */
  function _hasSchedules(item) {
    return item.schedule_urls ? !!item.schedule_urls.length : true;
  }

  /**
   * Build the config object used by the tooltip component
   * @param {Array.<Object>} fields
   * @param {Object} item
   * @return {{message: string, messageItems: Array}}
   * @private
   */
  function _buildTooltip(fields, item) {
    const appearanceUndefinedText = !item.hasAppearanceDataSet
      ? " but they are not set"
      : "";
    const comibinedData = {
      ...item.contentItem[Object.keys(item.contentItem)[0]],
      ...item.scheduledItem[Object.keys(item.scheduledItem)[0]],
    };

    return {
      message: `This item has appearance options${appearanceUndefinedText}`,
      messageItems: fields.map((field) => {
        let value = comibinedData[field.name];

        if (UiSharedService.isUrl(value)) {
          value = "selected";
        }

        return {
          title: field.display_name,
          data: value,
        };
      }),
    };
  }

  /**
   * Assign appearance attributes to an item
   * @private
   */
  function _assignAppearanceStatus() {
    const items = [...SetContentsStore.getState().items];
    const itemsWithAppearanceStatus = items.map((item) => {
      item.appearanceFields = item.config.fields
        ? item.config.fields.filter((field) => field.tab === "Appearance")
        : [];
      item.hasAppearanceAvailable = !!item.appearanceFields.length;

      if (item.hasAppearanceAvailable) {
        item.hasAppearanceDataSet = _checkAppearanceForValues(
          item.appearanceFields,
          item
        );
        item.tooltipConfig = _buildTooltip(item.appearanceFields, item);
      }

      return item;
    });

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.updateItems,
      items: itemsWithAppearanceStatus,
    });
  }

  /**
   * _assignActiveStatus - Determines if set contents item is valid
   * based on the items schedules and the content items schedules.
   */
  function _assignActiveStatus() {
    const items = [...SetContentsStore.getState().items];
    const itemsWithValidityStatus = items.map((item) => {
      const contentItem = item.contentItem[Object.keys(item.contentItem)[0]];
      const scheduledItem =
        item.scheduledItem[Object.keys(item.scheduledItem)[0]];

      item.isActive = _isCurrent(contentItem) && _isCurrent(scheduledItem);

      return item;
    });

    SetContentsDispatcher.dispatch({
      actionType: SET_CONTENTS_ACTIONS.updateItems,
      items: itemsWithValidityStatus,
    });
  }

  factory.decorateItems = () => {
    _assignConfigToItems();
  };

  factory.assignAppearanceStatus = () => {
    _assignAppearanceStatus();
  };

  factory.assignActiveStatus = () => {
    _assignActiveStatus();
  };

  return factory;
}

export default ItemDecorationActions;
