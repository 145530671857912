import template from "./role-expander.html";

class RoleExpanderController {
  /**
   * @constructor
   * @param {Object} MessageService
   * @param {Object} UsersService
   */
  constructor(MessageService, UsersService) {
    this.MessageService = MessageService;
    this.UsersService = UsersService;

    this._setupSubscriptions();
  }

  /**
   * Setup pub/sub subscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    const updateChannel = `RoleExpander.Update.${this.uid}`;

    this.MessageService.subscribe(updateChannel, () => {
      this._buildString();
    });
  }

  /**
   * Life-cycle hook.
   * @returns {void}
   */
  $onInit() {
    this._buildString();
  }

  /**
   * Builds the visible string for the component
   * @private
   * @returns {void}
   */
  _buildString() {
    this.UsersService.userRoleStore.getData().then((roles) => {
      this.roleString = "";

      roles
        .filter((role) => this.urls.indexOf(role.self) > -1)
        .map((availableRole) => availableRole.name)
        .forEach((roleName, index) => {
          this.roleString += index > 0 ? `, ${roleName}` : `${roleName}`;
        });
    });
  }
}

const roleExpanderComponent = {
  bindings: {
    uid: "<",
    urls: "<",
  },
  controller: RoleExpanderController,
  controllerAs: "component",
  template,
};

export default roleExpanderComponent;
