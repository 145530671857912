import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { isObject } from "lodash";

import MediaPreviewBlock from "components/_react/editor/media-preview-block/media-preview-block";
import BlockHeader from "components/_react/editor/media-block/components/block-header/block-header";

/**
 * @class  Video Preview Component for rendering video entities in preview mode
 * @param {Object} props
 */
export default function VideoPreview(props) {
  if (isObject(props.data.media)) {
    return (
      <Fragment>
        <BlockHeader
          type="video"
          headerText={`Episode: ${props.data.media.title}`}
          isEditing={false}
        />
        <MediaPreviewBlock data={props.data.media} />
      </Fragment>
    );
  }

  return null;
}

VideoPreview.propTypes = {
  data: PropTypes.shape({
    media: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        title: PropTypes.string,
      }),
    ]),
  }).isRequired,
};
