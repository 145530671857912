/**
 * @param {object} ApiService
 * @param {object} ApiRequestConfigFactory
 * @returns {{}}
 * @constructor
 */
function UsersFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  /**
   * Get a list of all customers
   * @param {object} config
   * @returns {Promise}
   */
  factory.getUserList = (config) => ApiService.get("/api/cms-users/", config);

  /**
   * Get current user
   * @returns {Promise}
   */
  factory.getCurrentUser = () => ApiService.get("/api/cms-users/me/");

  /**
   * Gets a list of all user roles
   * @returns {Promise}
   */
  factory.getRolesList = () =>
    ApiService.get(
      "/api/cms-roles/",
      ApiRequestConfigFactory.createRequestConfig()
    );

  return factory;
}

export default UsersFactory;
