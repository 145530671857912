import angular from "angular";
import permissionsManagerComponent from "./permissions-manager.component";
import PERMISSIONS_OPTIONS from "./permissions-manager.constants";

const PermissionsManager = angular
  .module("Skylark.Components.PermissionsManager", [])
  .constant("PERMISSIONS_OPTIONS", PERMISSIONS_OPTIONS)
  .component("permissionsManager", permissionsManagerComponent);

export default PermissionsManager;
