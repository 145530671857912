import _ from "lodash";
import NotificationMessage from "skylarklib/constants/notification-text";
import { fetchBaseConfig } from "store/entities-config/entities-config.action";
import { getBaseConfig } from "store/entities-config/entities-config.selector";
import {
  getApiQuerySelector,
  getCountApiQuerySelector,
  getEntityListingData,
  getEntityListingLoading,
} from "store/listing/entity-listing/entity-listing.selector";
import {
  generateActiveFilter,
  generateDefaultActiveFilter,
} from "skylarklib/helpers/entity-listing-filter-helper";
import {
  fetchEntityListing,
  fetchEntityListingCount,
  resetSearchFilter,
  setActiveFilters,
} from "store/listing/entity-listing/entity-listing.actions";
import { ENTITY_LISTING_NAMESPACE } from "store/listing/entity-lisitng-page.constant";
import template from "./entity-listing.html";

/**
 *
 * EntityListingPageController - Generic listing page
 * controller for Brands, Seasons, Episodes,
 * Schedules and Assets.
 *
 */
class EntityListingPageController {
  /**
   * @constructor
   * @param {Object} $location
   * @param {Object} $scope
   * @param {Object} $q
   * @param {Object} ReduxConnector
   * @param {Object} RouteService
   * @param {Object} EntityFactory
   * @param {Object} ApiFilterFactory
   * @param {Object} GlobalParamsService
   * @param {Object} HistoryService
   * @param {Object} LoadingService
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} ConfigurationFactory
   * @param {Object} TagsFactory
   * @param {Object} SkylarkApiInfoService
   */
  constructor(
    $location,
    $scope,
    $q,
    ReduxConnector,
    RouteService,
    EntityFactory,
    ApiFilterFactory,
    GlobalParamsService,
    HistoryService,
    LoadingService,
    MessageService,
    NotificationService,
    ConfigurationFactory,
    TagsFactory,
    SkylarkApiInfoService
  ) {
    this.$location = $location;
    this.$scope = $scope;
    this.$q = $q;
    this.ReduxConnector = ReduxConnector;
    this.RouteService = RouteService;
    this.EntityFactory = EntityFactory;
    this.ApiFilterFactory = ApiFilterFactory;
    this.GlobalParamsService = GlobalParamsService;
    this.HistoryService = HistoryService;
    this.LoadingService = LoadingService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ConfigurationFactory = ConfigurationFactory;
    this.TagsFactory = TagsFactory;
    this.SkylarkApiInfoService = SkylarkApiInfoService;

    this._init();
  }

  /**
   * _initializes the page
   * @returns {void}
   */
  _init() {
    this.entityName = this.EntityFactory.getEntityName();
    this.entityType = this.EntityFactory.getEntityType();
    this.isEditorialSchedules = this.entityName === "editorial-schedules";
    this.isLicenceSchedules = this.entityName === "licensing";

    this.channelNames = {
      deleteIndividualEntity: "EntityListing.Delete",
      saveIndividualEntity: "EditableModal.Save",
    };

    this.searchAndFilterNamespace = ENTITY_LISTING_NAMESPACE;

    /**
     * If connectToStore is ran before init, it will have config null and entityName defined
     * which will end up in another request for the same config that's already defined in the reducer
     *
     */
    this.connectToStore();
    this._getBaseConfig();
    this._setEntityWatchers();
    this._setupSubscriptions();

    this.cognitoEnabled = true;
    this.SkylarkApiInfoService.hasCognitoAuth().then((cognitoEnabled) => {
      this.cognitoEnabled = cognitoEnabled;
    });
  }

  /**
   * Sets up pub/sub subscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this.channelNames.saveIndividualEntity,
      (channel, data) => {
        this._createEntity(data);
      }
    );

    this.MessageService.subscribe(
      this.channelNames.deleteIndividualEntity,
      () => {
        this._getEntitiesInList();
        this._getEntitiesCount();
      }
    );
  }

  /**
   * redux watchers
   */
  _setEntityWatchers() {
    const unwatch = this.$scope.$watch(
      () => this.config,
      (config) => {
        if (!_.isEmpty(config)) {
          this._setSearchAndFilters();
          this._assignConfigValues();
          this._buildModalOptions();
          this._getEntitySubTypes();
          unwatch();
        }
      }
    );

    this.unwatchEntityListingData = this.$scope.$watch(
      () => this.entityListingData,
      (entityListingData) => {
        this._handleAvailableEntities(entityListingData.results);
      }
    );

    this.unwatchEntityListingLoading = this.$scope.$watch(
      () => this.entityListingLoading,
      (entityListingLoading) => {
        this.LoadingService.inlineLoader(entityListingLoading);
      }
    );

    this.unwatchQueryChange = this.$scope.$watch(
      () => this.query,
      (query) => {
        // Update the URL with the query
        this._updateChosenParams(query);
      }
    );
  }

  /**
   * _getBaseConfig
   * get the entity listing config by entity name
   *
   * @returns {void}
   */
  _getBaseConfig() {
    // It can happen that the config is already in the reducer, so we don't need to fetch it again
    if (this.entityName) {
      if (_.isEmpty(this.config)) {
        this.store.fetchBaseConfig(this.entityName).then(({ response }) => {
          this._getBaseConfigForType(response);
        });
      } else {
        // Even if we've fetched the base config previously, the config for a given type may not exist in the reducer yet
        this._getBaseConfigForType(this.config);
      }
    }
  }

  /**
   * _getBaseConfigForType
   * Gets the base config by entity name and type
   * Used for entities like sets, assets which have types such as playlist or homepage
   * This config is only fetched when the base cms-config for an entity contains "has_types: true"
   *
   * @param {Object} baseConfig - the entity base config
   * @returns {void}
   */
  _getBaseConfigForType(baseConfig) {
    const entityHasTypes =
      Object.prototype.hasOwnProperty.call(baseConfig, "has_types") &&
      baseConfig.has_types;

    if (_.isEmpty(this.typeBaseConfig) && entityHasTypes && this.entityType) {
      this.store.fetchBaseConfig(this.entityName, this.entityType);
    }
  }

  /**
   * Assigns class available values from config
   * @returns {void}
   * @private
   */
  _assignConfigValues() {
    this.columns = this.config.columns || 0;
    this.limit = this.config.limit || 10;
  }

  /**
   * _buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: { create: "EditableModal.Save" },
      entity: this.config.name,
    };
  }

  /**
   * set initial search criteria when not already a query for that entity is defined
   * we want to keep the filters as they were last set, but as well reuse the defaults if they may have not set yet.
   */
  _setSearchAndFilters() {
    const filters = {
      dynamicProperties: [
        ...generateDefaultActiveFilter(
          this.entityName,
          this.entityType,
          this.config
        ).dynamicProperties,
        ...generateActiveFilter(this.$location.search()).dynamicProperties,
        ...generateActiveFilter(this.query).dynamicProperties,
      ],
    };

    this.store.setActiveFilters({
      entityName: this.entityName,
      entityType: this.entityType,
      namespace: this.searchAndFilterNamespace,
      ...filters,
    });
  }

  /**
   * builds the create entity Url
   * @param {String} entityName
   * @param {String} entityType
   * @returns  {String}
   */
  buildCreateEntityUrl(
    entityName = this.entityName,
    entityType = this.entityType
  ) {
    return this.RouteService.buildCreateEntityURL(entityName, entityType);
  }

  /**
   * Returns if this entity type allows entities to be edited in the list
   * Checks for presence of tabs which is required for entity detail views
   * @returns {boolean}
   */
  isEditable() {
    return !_.get(this.config, "tabs.length", false) && !this.hasSubTypes();
  }

  /**
   * Checks if this entity has types associated with it
   * @returns {boolean}
   */
  hasSubTypes() {
    return this.config.has_types;
  }

  /**
   * Update the chosen params from message service
   * @param {object} query
   * @returns {void}
   */
  _updateChosenParams(query) {
    if (this.query === undefined) {
      return;
    }
    this.filterQuery = query;
    this.$location.search(query);
    this._getEntitiesInList();
  }

  /**
   * _createSlugFromName
   * this is to create a valid entity type slug when the entity does not have
   * a slug field.
   * @param  {Object} type
   * @return {String} configured slug
   */
  _createSlugFromName(type) {
    if (!type.slug) {
      const nameField = type.name ? "name" : "label";
      const invalidChars = /[^a-zA-Z0-9_-]/g;

      return _.kebabCase(type[nameField].toLowerCase()).replace(
        invalidChars,
        ""
      );
    }

    return type.slug;
  }

  /**
   * fetches results for the new query, and cancel any pending requests
   */
  _getEntitiesInList() {
    if (_.isEmpty(this.config)) {
      return;
    }

    this.cancelToken && this.cancelToken();

    const promise = new Promise((resolve) => {
      this.cancelToken = resolve;
    });

    this.store.fetchEntityListing({
      entityName: this.entityName,
      entityType: this.entityType,
      query: this.query,
      config: this.config,
      cancelToken: promise,
      namespace: this.searchAndFilterNamespace,
    });
  }

  /**
   * update count
   */
  _getEntitiesCount() {
    this.store.fetchEntityListingCount({
      entityName: this.entityName,
      entityType: this.entityType,
      query: this.query,
      namespace: this.searchAndFilterNamespace,
    });
  }

  /**
   * Handles the listing of available entities after response from API
   * @param {Object} availableEntities
   * @returns {void}
   */
  _handleAvailableEntities(availableEntities) {
    this.data = [...availableEntities];

    if (this.entityName === "tags") {
      this._assignTagTypesToEntities();
    }

    if (this.entityName === "assets") {
      this._assignTypesToAssets();
    }
  }

  /**
   * get types when not defined
   * @return {Promise}
   */
  _getEntitySubTypes() {
    if (!this.hasSubTypes()) {
      return this.$q.resolve();
    }

    if (this.types) {
      return this.$q.resolve(this.types);
    }

    return this.EntityFactory.getTypes(this.entityName).then((data) => {
      this.types = data.objects.map((type) => ({
        ...type,
        slug: this._createSlugFromName(type),
      }));

      return this.types;
    });
  }

  /**
   * Individually assigns a category to each of the tags in the view
   * @todo hook up with the reducer
   * @returns {void}
   * @private
   */
  _assignTagTypesToEntities() {
    this.TagsFactory.getCategories().then((data) => {
      this.data.forEach((entity) => {
        const categories = data.objects;
        const type = categories.find(
          (category) => category.self === entity.category_url
        );

        if (type && type.name) {
          entity.type = type.name;
        }
      });
    });
  }

  /**
   * assign asset types to assets
   * @returns {void}
   */
  _assignTypesToAssets() {
    if (this.hasSubTypes()) {
      this._getEntitySubTypes().then((types) => {
        this.data = this.data.reduce((acc, curr) => {
          const type = types.find((type) => type.self === curr.asset_type_url);
          const newAsset = { ...curr, type: type ? type.name : "" };

          return acc.concat([newAsset]);
        }, []);
      });
    }
  }

  /**
   * Saves an entity
   * @param {object} entity
   * @returns {void}
   */
  _createEntity(entity) {
    const name = this._getEntityName(entity);
    const entityToRequest =
      this.isEditorialSchedules || this.isLicenceSchedules
        ? "schedules"
        : this.entityName;

    if (this.isEditorialSchedules || this.isLicenceSchedules) {
      entity.rights = this.isLicenceSchedules;
    }

    this.EntityFactory.create(entityToRequest, entity)
      .then(() => {
        this.NotificationService.notifyInfo(
          NotificationMessage.createSuccess(name)
        );
        this._getEntitiesInList();
        this._getEntitiesCount();
      })
      .catch((err) => {
        // Always show default creation error
        this.NotificationService.notifyError(
          NotificationMessage.createError(entity.name || this.entityName)
        );
        this.NotificationService.notifyError(err);
      });
  }

  /**
   * _getEntityName
   * @private
   * @param   {Object} entity - entity data
   * @returns {string} the name to use in notifications
   */
  _getEntityName(entity) {
    return (
      entity.title || entity.name || `${entity.first_name} ${entity.last_name}`
    );
  }

  connectToStore() {
    const mapDispatchToThis = {
      fetchBaseConfig,
      fetchEntityListing,
      fetchEntityListingCount,
      setActiveFilters,
      resetSearchFilter,
    };

    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  mapStateToThis(state) {
    return {
      config: getBaseConfig(state, this.entityName).data,
      typeBaseConfig: getBaseConfig(state, this.entityName, this.entityType)
        .data,
      entityListingData: getEntityListingData(
        state,
        this.entityName,
        this.entityType,
        this.searchAndFilterNamespace
      ),
      entityListingLoading: getEntityListingLoading(
        state,
        this.entityName,
        this.entityType,
        this.searchAndFilterNamespace
      ),
      query: getApiQuerySelector(
        state,
        this.entityName,
        this.entityType,
        this.searchAndFilterNamespace
      ),
      countQuery: getCountApiQuerySelector(
        state,
        this.entityName,
        this.entityType,
        this.searchAndFilterNamespace
      ),
    };
  }

  $onDestroy() {
    this.disconnect();
    this.unwatchEntityListingData && this.unwatchEntityListingData();
    this.unwatchEntityListingLoading && this.unwatchEntityListingLoading();
    this.unwatchQueryChange && this.unwatchQueryChange();
    this.cancelToken && this.cancelToken();
  }
}

const entityListingPageComponent = {
  controller: EntityListingPageController,
  controllerAs: "page",
  template,
};

export default entityListingPageComponent;
