/**
 * Document Factory for handling documents
 * is inheriting MediaFactory
 * @param {object} _
 * @param {object} $q
 * @param {object} SchedulesFactory
 * @param {object} MediaFactory
 * @param {object} GlobalParamsService
 * @returns {object}
 */
function DocumentFactory(
  _,
  $q,
  SchedulesFactory,
  MediaFactory,
  GlobalParamsService
) {
  const factory = {
    ...MediaFactory,
  };

  /**
   * create - Creates new image
   * resource and uploads document. Returns notify
   * event for progress of document upload
   *
   * @param {File} file - file to upload
   * @param {object} postData - the data
   * @returns {Promise}
   */
  factory.createDocument = ({ file, ...postData }) => {
    const createdDocument = MediaFactory.create("documents", postData);

    if (file) {
      return createdDocument.then((data) =>
        MediaFactory.upload(data.upload_document_url, file)
      );
    }

    // the upload was through an url not a file, we need to wait until backend successfully made the upload
    return createdDocument.then((data) =>
      MediaFactory.waitUntilUploadFinished(data)
    );
  };

  /**
   * creating an document with always schedule
   * @param {object} data
   * @return {Promise}
   */
  factory.createDocumentWithAlwaysSchedule = (data) => {
    data = { ...data };
    return SchedulesFactory.getAlwaysSchedule().then((schedule) => {
      data.schedule_urls = [schedule.self];
      return factory.createDocument(data);
    });
  };

  /**
   * filter documents by type
   * @param {array} documents
   * @param {string} type - url of document type
   * @return {Array}
   */
  factory.filterByType = (documents, type) =>
    _.filter(documents, { document_type_url: type });

  /**
   * change position of item, this will start rearranging of items when needed,
   * and returns a new list of documents with updated position
   * @param {Array} documents
   * @param {Object} document
   * @param {Number} newPosition
   * @return {Promise<Array>} new array of sorted documents
   */
  factory.changePosition = (documents, document, newPosition) => {
    if (document.position === newPosition) {
      return $q.resolve(documents);
    }

    const minPosition = 1;
    const typeFilteredDocs = factory.filterByType(
      documents,
      document.document_type_url
    );
    const maxPosition = typeFilteredDocs.length;

    if (newPosition < minPosition) {
      newPosition = minPosition;
    }

    if (newPosition > maxPosition) {
      newPosition = maxPosition;
    }

    const updatedDocument = _.merge(document, { position: newPosition });

    return factory.rearrangePositions(documents, updatedDocument);
  };

  /**
   * update the position of the documents in the backend which require rearranging
   * is updating documents after all request are processed
   * @param {Array<Object>} documents
   * @param {Object} updatedDocument
   * @return {Promise<Array>} new array of sorted documents
   */
  factory.rearrangePositions = (documents, updatedDocument) => {
    const rearranged = factory.rearrange(documents, updatedDocument);
    const requests = rearranged.map((document) =>
      MediaFactory.update(document.self, document)
    );

    return $q.all(requests).then((updatedDocuments) => {
      const mergedDocuments = documents.map((document) => {
        const index = _.findIndex(updatedDocuments, { uid: document.uid });
        if (index < 0) {
          return document;
        }
        return _.assign({}, delete document.$$hashKey, document, {
          position: updatedDocuments[index].position,
        });
      });
      return _.sortBy(mergedDocuments, ["position"]);
    });
  };

  /**
   * rearrange documents position and filter documents out where nothing has changed
   * @param {Array<Object>} documents
   * @param {Object} updatedDocument
   * @return {Array}
   */
  factory.rearrange = (documents, updatedDocument) => {
    const typeFilteredDocs = factory.filterByType(
      documents,
      updatedDocument.document_type_url
    );
    const sorted = factory.sortDocuments(typeFilteredDocs, updatedDocument);

    return sorted
      .map((document, index) => {
        const newPosition = index + 1;
        if (
          document.position === newPosition &&
          updatedDocument.uid !== document.uid
        ) {
          return;
        }

        return _.assign({}, document, { position: newPosition });
      })
      .filter((o) => !!o);
  };

  /**
   * sorting documents by their position
   * @param {Array<Object>} documents
   * @param {Object} updatedDocument
   * @return {Array}
   */
  factory.sortDocuments = (documents, updatedDocument) => {
    documents = _.cloneDeep(documents);
    _.remove(documents, { uid: updatedDocument.uid });
    documents = _.sortBy(documents, ["position"]);
    documents.splice(updatedDocument.position - 1, 0, updatedDocument);

    return documents;
  };

  /**
   * delete a document from the list and rearranges items
   * it is returning a new array list of documents
   * @param {Array} documents
   * @param {Object} deleteDoc
   * @return {Promise<Array>}
   */
  factory.deleteDocument = (documents, deleteDoc) => {
    const copiedDocuments = _.cloneDeep(documents);
    const group = factory.filterByType(
      copiedDocuments,
      deleteDoc.document_type_url
    );
    const index = _.findIndex(group, { uid: deleteDoc.uid });
    const nextDocument = group[index + 1];
    _.remove(copiedDocuments, { uid: deleteDoc.uid });

    if (!nextDocument) {
      return $q.resolve(copiedDocuments);
    }

    return factory.rearrangePositions(copiedDocuments, nextDocument);
  };

  /**
   * update a document
   * it is not mutating the initial document, returning a new array of documents
   * it is handling document file uploads
   * change of position from old and new group when it is assigned to a new group
   * fetching schedule again, as it get lost when document is updated
   * @param {Array} documents
   * @param {Object} updatedDoc
   * @return {Promise<Array>}
   */
  factory.updateDocument = (documents, updatedDoc) => {
    const filter = {
      ...GlobalParamsService.getDefaultParams(),
      fields_to_expand: "schedule_urls",
    };

    if (updatedDoc.file) {
      return factory
        .upload(updatedDoc.upload_document_url, updatedDoc.file)
        .then(() => MediaFactory.get(updatedDoc.self, filter))
        .then((updatedDoc) => factory.changeGroup(documents, updatedDoc));
    }

    return MediaFactory.get(updatedDoc.self, filter).then((updatedDoc) =>
      factory.changeGroup(documents, updatedDoc)
    );
  };

  /**
   * use that method when you know the document changed group
   * if it didn't changed it will give back the original documents list
   * otherwise updated list with positioning updates on all participated group changes
   * @param {Array} documents
   * @param {Object} updatedDoc
   * @return {Promise<Array>}
   */
  factory.changeGroup = (documents, updatedDoc) => {
    const index = _.findIndex(documents, { uid: updatedDoc.uid });
    const oldDocument = documents[index];
    const typeChanged =
      oldDocument.document_type_url !== updatedDoc.document_type_url;

    documents = _.cloneDeep(documents);
    documents[index] = updatedDoc;

    if (!typeChanged) {
      return $q.resolve(documents);
    }

    return factory
      .rearrangePositions(documents, updatedDoc)
      .then((updatedDocuments) => {
        const group = factory.filterByType(
          updatedDocuments,
          oldDocument.document_type_url
        );
        if (!group.length) {
          return updatedDocuments;
        }

        return factory.rearrangePositions(updatedDocuments, group[0]);
      });
  };

  return factory;
}

export default DocumentFactory;
