import _ from "lodash";

class DateInputService {
  /**
   * @constructor
   * @param {Object} momentJS
   * @param {Object} MessageService
   */
  constructor(momentJS, MessageService) {
    this.momentJS = momentJS;
    this.MessageService = MessageService;
    // Object that will have [uuid] property that will contain date strings
    this.dates = {};
  }

  /**
   * Initialises the values
   * @param {string} date
   * @param {string} uuid
   * @returns {void}
   */
  initialiseValues(date, uuid) {
    this.updateDate(uuid, date);
  }

  /**
   * @param {string} uuid - uuid
   * @param {string?} date - the date value
   *
   * @returns {void}
   *
   */
  updateDate(uuid, date) {
    // If the date doesn't exist, set it to an empty string
    if (!this.dates[uuid]) {
      this.dates[uuid] = "";
    }

    if (date) {
      this.dates[uuid] = date;
    }

    this.MessageService.publish(`DateInput.update.${uuid}`);
  }

  /**
   *
   * @param {string} uuid - uuid
   */
  resetDateByUUID(uuid) {
    this.dates[uuid] = null;
  }

  /**
   * @param {string} uuid
   *
   * @return {string}
   */
  getValueByUUID(uuid) {
    if (_.isEmpty(this.dates[uuid])) {
      return "";
    }

    return this.dates[uuid];
  }

  getFormattedValueByUUID(uuid) {
    if (_.isEmpty(this.dates[uuid])) {
      return "";
    }

    return this.momentJS(this.dates[uuid]).format("YYYY-MM-DD");
  }
}

export default DateInputService;
