const dropdownOptions = {
  selectionFlags: [
    {
      name: "all",
      status: true,
    },
    {
      name: "none",
      status: false,
    },
  ],
  viewMode: [
    {
      name: "Added",
      status: true,
    },
    {
      name: "Not Added",
      status: false,
    },
  ],
};

export default dropdownOptions;
