/**
 *  searchFactory
 *  @description - for multi-search endpoints with hierarchical structure
 *
 */

/**
 * SearchFactory
 * @param {service} ApiService
 * @param {service} $q
 * @param {service} _ lodash
 * @constructor
 */
function SearchFactory(ApiService, ApiRequestConfigFactory, $q, _) {
  const factory = {};

  let currentEntity;
  let currentField;
  let currentAncestors = [];
  let currentSearchParams = [];
  let currentRelationshipParams = [];
  let currentOperator = null;

  /**
   * buildQuery
   * @description decide whether to build a collection of query objects or a query obj
   * @param {object} - searchParam the final queryObj
   * @returns {promise}
   */

  factory.buildQuery = (searchParam) => {
    const queryObj = factory.buildQueryObject(searchParam);

    if (!searchParam.ancestors.length && searchParam.containSelf) {
      return factory.buildArrayOfQueryObjects(searchParam.entity, queryObj);
    }
    return queryObj;
  };

  /**
   * addRelationships
   * @description - adds relationship query params
   * @param {object} searchObject - built search object with q and ancestors
   * @param {array} relationships
   * @return {object} obj - modified object or original object
   */

  factory.addRelationships = (queryObject) => {
    const obj = { ...queryObject };
    if (currentRelationshipParams.length) {
      const newRelationships = factory.buildLuceneQuery(
        currentRelationshipParams
      );
      if (queryObject.q) {
        newRelationships.push(queryObject.q);
      }
      obj.q = newRelationships.join(currentOperator);
    }

    return obj;
  };

  /**
   * _buildLuceneQuery
   * @access private
   * @param   {Array} arr - Array
   * @returns {Array} new array with terms in lucene format
   */
  factory.buildLuceneQuery = (arr) =>
    arr.map((term) => `${_.snakeCase(term.entity)}:${term.q}`);

  /**
   * buildQueryObject
   * @memberOf  SearchFactory
   * @param {object} searchParam - complete searchParam object with ancestors
   * @returns {object} queryObj - API-ready query object
   */

  factory.buildQueryObject = (param) => {
    const queryObj = {};
    if (param.entity === currentEntity) {
      queryObj.ancestors = param.ancestors;
      queryObj.q = `${currentField}:${param.q}`;
    } else {
      queryObj.ancestors = param.ancestors;
    }

    return factory.addRelationships(queryObj);
  };

  /**
   * buildArrayOfQueryObjects
   * @memberOf  SearchFactory
   * @description  only called when we are dealing with an entity that can contain entities of the same type
   * @param {string} entity - entity name
   * @param {object} builtQueryObj - an API-ready query object
   * @returns {Promise} promise
   */
  factory.buildArrayOfQueryObjects = (entity, builtQueryObj) => {
    const queryObjectsCollection = [];
    queryObjectsCollection.push(builtQueryObj);

    return factory.getData(entity, { q: `${builtQueryObj.q}` }).then((data) => {
      if (data.objects.length) {
        const queryObj = {};
        queryObj.ancestors = data.objects.map((item) => item.self);
        queryObjectsCollection.push(factory.addRelationships(queryObj));
        return queryObjectsCollection;
      }
      return builtQueryObj;
    });
  };

  /**
   * merge params when searching with OR -
   * in this case, only one entity type is allowed
   * @param   {Array} searchParams
   * @returns {Array} searchParams with a merged object or original searchParams
   */
  factory.mergeParamsByType = (searchParams) => {
    if (currentOperator === " OR " && searchParams.length) {
      const paramWithMergedQueries = { ...searchParams[0] };
      paramWithMergedQueries.q = searchParams
        .map((param, index) => {
          if (index > 0) {
            return `${currentField}:${param.q}`;
          }
          return param.q;
        })
        .join(currentOperator);

      return [paramWithMergedQueries];
    }

    return searchParams;
  };

  /**
   * getQueryObject - this is what entities should call
   * @access public
   * @param   {Array} searchParams - list of search params from multisearch widget
   * @param   {String} entity - entity name
   * @returns {Promise}
   */
  factory.getQueryObject = (
    searchParams,
    entity,
    operator = "AND",
    field = "title"
  ) => {
    currentOperator = ` ${operator} `;
    currentEntity = entity;
    currentField = field;
    currentRelationshipParams = factory.filterByType(
      searchParams,
      "relationship"
    );
    currentSearchParams = factory.mergeParamsByType(
      factory.filterByType(searchParams, "hierarchical")
    );

    if (currentSearchParams.length) {
      return factory.getHierarchicalSearchParams();
    }
    return $q.resolve(factory.addRelationships({}));
  };

  /**
   * getHierarchicalSearchParams
   * @memberOf SearchFactory
   * @returns {promise}
   */
  factory.getHierarchicalSearchParams = () =>
    factory
      .recursiveSearch(currentSearchParams[0], currentAncestors)
      .then((data) => {
        if (!_.isEmpty(data)) {
          return factory.buildQuery(data);
        }
        return {};
      });

  /**
   * filter params by their type
   * @param   {Array} list - list of params
   * @param   {string} type - type name - hierarchical OR relationship
   * @returns {Array} list by type
   */
  factory.filterByType = (list, type) =>
    list.filter((term) => term.type === type);

  /**
   * recursiveSearch
   * @memberOf SearchFactory
   * @description checks if we need to keep iterating over params or if we are ready to build a final param
   * @param {object} param - current param being iterated
   * @param {array} ancestors - ancestors field
   * @returns {Promise}
   */
  factory.recursiveSearch = (param, ancestors) => {
    if (param.entity === currentEntity || !currentSearchParams.length) {
      return $q.resolve(factory.buildFinalParam(param));
    }
    return factory.handleCurrentParam(param, ancestors);
  };

  /**
   * buildFinalParam
   * @param {object} param - final results from recursive search
   * @returns {Object}
   */
  factory.buildFinalParam = (param) => {
    const finalParam = param;
    finalParam.ancestors = [].concat(currentAncestors);
    return finalParam;
  };

  /**
   * handleCurrentParam
   * @memberOf  SearchFactory
   * @description gets ancestors for the currentParam and either resolves or calls recursive search again
   * @param {object} param - current param being parsed
   * @param {array} ancestors - current ancestors for this param
   * @returns {Promise}
   */
  factory.handleCurrentParam = (param, ancestors) =>
    factory.getAncestors(param, ancestors, currentField).then((data) => {
      if (data.objects.length) {
        currentSearchParams.shift();
        currentAncestors = data.objects.map((obj) => obj.self);

        const searchParam = currentSearchParams.length
          ? currentSearchParams[0]
          : param;

        return factory.recursiveSearch(searchParam, currentAncestors);
      }
      return {};
    });

  /**
   * getAncestors
   * @memberOf SearchFactory
   * @description  makes an api call to get the results from passed on param
   * @param {object} searchQuery
   * @param {array} ancestors
   * @returns {Promise}
   */
  factory.getAncestors = (searchQuery, ancestors, currentField = "title") => {
    const queryObj = {};

    queryObj.q = `${currentField}:${searchQuery.q}`;
    queryObj.ancestors = ancestors;

    return factory.getData(searchQuery.entity, queryObj);
  };

  /**
   * getData
   * @memberOf  SearchFactory
   * @param {string} entity - entity name
   * @param {object} filters - query params
   * @returns {promise}
   */
  factory.getData = (entity, filters) =>
    ApiService.get(
      `/api/${entity}/`,
      ApiRequestConfigFactory.createRequestConfig({
        requestConfig: {
          params: filters,
        },
        useGlobalParams: true,
      })
    );

  /**
   * resetState
   * @memberOf  SearchFactory
   * @description avoid state persistence inside the factory after object has been built
   * @returns {void}
   */
  factory.resetState = () => {
    currentEntity = null;
    currentAncestors.length = 0;
    currentSearchParams.length = 0;
    currentRelationshipParams.length = 0;
    currentOperator = null;
    currentField = undefined;
  };

  return factory;
}

export default SearchFactory;
