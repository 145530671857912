import template from "./add-item-button.html";

class AddItemButtonCtrl {
  /**
   * $onInit
   * @returns {void}
   */
  $onInit() {
    this._buildLists();
    this._buildModalOptions();
    this._buildCopy();
  }

  /**
   * _buildLists
   * @returns {void}
   */
  _buildLists() {
    this.contentTypesRaw = this.config.content_types || [];
    // content types filterd for images-edit config to stop it showing in add new item list
    this.contentTypes = this.contentTypesRaw.filter(
      (type) => !type.isHiddenFromMenu
    );
    this.setTypes = this.config.set_types || [];
    this.articleTypes = this.config.article_types || [];
    this.assetTypes = this.config.asset_types || [];
    this.dynamicObjectTypes = this.config.dynamic_object_types || [];
  }

  /**
   * _buildCopy
   * @returns {void}
   */
  _buildCopy() {
    this.buttonCopy = this.context === "Article" ? "Add Media" : "Add New Item";
  }

  /**
   * _buildModalOptions
   * @returns {void}
   */
  _buildModalOptions() {
    const addMessage = `Modal.Save.${this.context}.base`;
    this.modalOptions = {};

    this.modalOptions.general = {
      channels: {
        add: addMessage,
        create: `Modal.Create.${this.context}.base`,
      },
    };

    this.setTypes.forEach((setType) => {
      this.modalOptions[`${setType.name}`] = {
        filters: {
          set_type_slug: setType.name,
        },
        channels: {
          add: addMessage,
        },
      };
    });

    this.articleTypes.forEach(
      (articleType) =>
        (this.modalOptions[`${articleType.name}`] = {
          filters: {
            article_type__slug: articleType.name,
          },
          channels: {
            add: addMessage,
          },
        })
    );

    this.assetTypes.forEach(
      (assetType) =>
        (this.modalOptions[`${assetType.name}`] = {
          filters: {
            asset_type__slug: assetType.name,
          },
          channels: {
            add: addMessage,
          },
        })
    );
  }
}

const addItemButtonComponent = {
  controller: AddItemButtonCtrl,
  controllerAs: "component",
  bindings: {
    config: "<",
    context: "@",
  },
  template,
};

export default addItemButtonComponent;
