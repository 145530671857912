function momentFormat(momentJS) {
  momentJS.locale("en-gb");

  return (input, format) => {
    if (!input) {
      return "";
    }

    return momentJS(input).format(format);
  };
}

export default momentFormat;
