/**
 * ApiService
 *
 *
 *
 * @description Api Service
 *
 * @todo Need to add better data normalization
 * @TODO Promise reject with multiple parameters is not how it works, a reject allows only one parameter,
 * this means the status is never passed on
 *
 * as a utility.
 */

import { constructRequestUrl } from "../../../helpers/api/api";

/**
 * ApiService
 * @param {Object} $http
 * @param {Object} $q
 * @param {Object} $cookies
 * @param {Object} $rootScope
 * @param {Object} $jQuery
 * @param {Object} _
 * @param {Object} APP_SETTINGS
 * @param {Object} GlobalParamsService
 * @param {Object} ApiUrlAdapterService
 * @param {Object} AmplifyService
 * @returns {Object} service
 */
function ApiService(
  $http,
  $q,
  $cookies,
  $rootScope,
  $jQuery,
  _,
  APP_SETTINGS,
  GlobalParamsService,
  ApiUrlAdapterService,
  AmplifyService
) {
  const service = {};

  /**
   * rejectCodes - authorization and permissions errors
   *
   * @type {Array}
   *
   */
  service.rejectCodes = [401, 403];

  /**
   * getRequestConfig - get headers with auth token attached
   *
   * @param  {Object} requestConfigOverrides - base HTTP config
   * @returns {Object} updated object with correct headers
   *
   */
  service.getRequestConfig = (requestConfigOverrides, addAuthHeader = true) => {
    const deferred = $q.defer();

    const acceptLanguage =
      GlobalParamsService.getLanguage() ||
      GlobalParamsService.getLanguageWithDefaults();

    const extendedHeaders = {
      headers: {
        "Accept-Language": acceptLanguage,
      },
    };

    service.getAuthorizationToken().then((token) => {
      if (token && addAuthHeader) {
        extendedHeaders.headers.Authorization = `${
          __AUTH_TOKEN_PREFIX__ || "Bearer"
        } ${token}`;
        /*
         * adding cache-control when authorization header is present. This is to ensure that the images are served
         * by Cloudfront when requested using the resized image url and authorization header is not passed.
         * In this scenario, the image can be cached and served from cache.
         */
        extendedHeaders.headers["Cache-Control"] = "no-cache";
      }

      deferred.resolve(_.merge({}, extendedHeaders, requestConfigOverrides));
    });

    return deferred.promise;
  };

  /**
   * get authorization token from cookie
   * @returns {string || undefined}
   */
  service.getAuthorizationToken = () => {
    const deferred = $q.defer();

    // V8 and above use aws-amplify
    if (__V8_ENVIRONMENT__) {
      // On error return an empty string
      AmplifyService.getToken()
        .then((token) => {
          deferred.resolve(token);
          $rootScope.$apply();
        })
        .catch(() => {
          deferred.resolve("");
          $rootScope.$apply();
        });
    } else {
      // V7 and below use auth cookie
      const auth = $cookies.getObject("token");
      deferred.resolve(auth && auth.token ? auth.token : "");
    }

    return deferred.promise;
  };

  /**
   * onData - Process successful requests
   *
   * @param  {Object} data - request data
   * @param  {Object} deferred - Promise to resolve
   * @returns {Promise}
   *
   */
  service.onData = (data, deferred) => deferred.resolve(data);

  /**
   * onError - Process failed requests
   *
   * This method will have to be alot more fully featured
   * when we get into permissions and what not.
   *
   * @param  {Object} err - Error message
   * @param  {Integer} status - HTTP status code
   * @param  {Object} deferred - Promise to resolve
   * @returns {Promise}
   *
   */
  service.onError = (err, status, deferred) => {
    if ($jQuery.inArray(status, service.rejectCodes) > -1) {
      $rootScope.$broadcast("$unauthorized");
    }

    return deferred.reject({ ...err, status });
  };

  /**
   * get - Perform a HTTP get request
   *
   * @param  {String} url - Skylark endpoint
   * @param  {Object} [requestConfigOverrides] - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.get = (url, requestConfigOverrides = {}, addAuthHeader = true) => {
    const deferred = $q.defer();
    const entityUrl = ApiUrlAdapterService.replaceUrl(url);
    const requestUrl = constructRequestUrl(entityUrl);

    service
      .getRequestConfig(requestConfigOverrides, addAuthHeader)
      .then((config) => {
        $http
          .get(requestUrl, config)
          .success((data) => {
            service.onData(data, deferred);
          })
          .error((err, status) => service.onError(err, status, deferred));
      });

    return deferred.promise;
  };

  /**
   * put - Perform a HTTP put request
   *
   * @param  {String} url - Skylark endpoint
   * @param {Object} data
   * @param  {Object} requestConfigOverrides - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.put = (url = "", data, requestConfigOverrides) => {
    const deferred = $q.defer();
    const entityUrl = ApiUrlAdapterService.replaceUrl(url);
    const requestUrl = constructRequestUrl(entityUrl);

    const collapsedData = service.collapseData(data);

    service.getRequestConfig(requestConfigOverrides).then((config) => {
      $http
        .put(requestUrl, collapsedData, config)
        .success((data) => service.onData(data, deferred))
        .error((err, status) => deferred.reject(err, status));
    });

    return deferred.promise;
  };

  /**
   * patch - Perform a HTTP patch request
   *
   * @param  {String} url - Skylark endpoint
   * @param {Object} data
   * @param  {Object} requestConfigOverrides - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.patch = (url = "", data, requestConfigOverrides) => {
    const deferred = $q.defer();
    const entityUrl = ApiUrlAdapterService.replaceUrl(url);
    const requestUrl = constructRequestUrl(entityUrl);

    service.getRequestConfig(requestConfigOverrides).then((config) => {
      $http
        .patch(requestUrl, data, config)
        .success((data) => service.onData(data, deferred))
        .error((err, status) => deferred.reject(err, status));
    });

    return deferred.promise;
  };

  /**
   * get - Perform a HTTP post request
   *
   * @param  {String} url - Skylark endpoint
   * @param  {Object} data
   * @param  {Object} requestConfigOverrides - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.post = (url, data, requestConfigOverrides) => {
    const deferred = $q.defer();
    const requestUrl = constructRequestUrl(url);

    service.getRequestConfig(requestConfigOverrides).then((config) => {
      $http
        .post(requestUrl, data, config)
        .success((data) => service.onData(data, deferred))
        .error((err, status) => deferred.reject(err, status));
    });

    return deferred.promise;
  };

  /**
   * Post file
   *
   * @param  {String} url - Skylark endpoint
   * @param  {file} file
   * @param  {Object} config - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.postMultipart = (url, file, config) => {
    const deferred = $q.defer();
    const formData = new FormData();
    formData.append("file", file);

    const requestUrl = constructRequestUrl(url);

    service.getAuthorizationToken().then((token) => {
      $jQuery
        .ajax({
          type: "POST",
          url: requestUrl,
          data: formData,
          processData: false,
          contentType: false,
          beforeSend: (xhr) => {
            if (token) {
              xhr.setRequestHeader(
                "Authorization",
                `${__AUTH_TOKEN_PREFIX__ || "Bearer"} ${token}`
              );
              xhr.setRequestHeader(
                "Accept-Language",
                config.headers["Accept-Language"] || "en"
              );
              xhr.setRequestHeader("Cache-Control", "no-cache");
            }
          },
          xhr: () => {
            const xhr = new XMLHttpRequest();

            xhr.upload.addEventListener(
              "progress",
              (e) => {
                if (e.lengthComputable) {
                  const percent = (e.loaded / e.total) * 100;
                  // let percent = Math.floor((e.loaded / e.total) * 100);
                  deferred.notify(percent);
                }
              },
              false
            );

            return xhr;
          },
        })
        .then((data) => service.onData(data, deferred))
        .catch((err, status) => deferred.reject(err, status));
    });

    return deferred.promise;
  };

  /**
   * delete - Perform a HTTP delete request
   *
   * @param  {String} url - Skylark endpoint
   * @param  {Object} requestConfigOverrides - request params
   * @returns {Object} $q - deferred promise
   *
   */
  service.delete = (url, requestConfigOverrides = {}) => {
    const deferred = $q.defer();
    const requestUrl = constructRequestUrl(url);

    service.getRequestConfig(requestConfigOverrides).then((config) => {
      $http
        .delete(requestUrl, config)
        .success((data) => service.onData(data, deferred))
        .error((err, status) => deferred.reject(err, status));
    });

    return deferred.promise;
  };

  /**
   * is collapsing all extend properties on the past in data, so that the backend has no problems matching objects
   * @param {Object} data
   * @returns {Object}
   */
  service.collapseData = (data) => {
    const collapsedData = { ...data };
    for (const propertyName in collapsedData) {
      if (Object.hasOwnProperty.call(data, propertyName)) {
        const property = collapsedData[propertyName];
        if (Array.isArray(property)) {
          collapsedData[propertyName] = property.map((attribute) => {
            if (typeof attribute === "string") {
              return attribute;
            }

            if (attribute.self) {
              return attribute.self;
            }

            return attribute;
          });
        }

        if (_.isObject(property) && property.self) {
          collapsedData[propertyName] = property.self;
        }
      }
    }

    return collapsedData;
  };

  return service;
}

export default ApiService;
