import defaultTemplate from "./dropdown-filter.html";
import checkboxTemplate from "./dropdown-with-checkbox.html";
import multisearchTemplate from "./dropdown-multisearch.html";
import translationTemplate from "./dropdown-translation.html";
import versionTemplate from "./dropdown-version-picker.html";

let globalId = 0;

/**
 * Dropdown Controller
 *
 * apply optional ng-model, when the dropdown is part of a form and it needs to be validated.
 * If ng-model is defined then the form change it state for example when dropdown changed
 */
class dropdownController {
  /**
   * @constructor
   * @param {Object} $compile
   * @param {Object} $scope
   * @param {Object} $element
   */
  constructor($compile, $scope, $element) {
    this.$compile = $compile;
    this.$scope = $scope;
    this.$element = $element;

    globalId += 1;
    this.$dropdown = angular.element(
      `<div id="dropdown-${globalId}" data-test="dropdown-${globalId}"></div>`
    );
  }

  /**
   * Initialise the directive
   * @returns {void}
   */
  $onInit() {
    const template = this._getTemplateFromStyle();
    this._compileTemplate(template);
    this.ngModel = this.$element.controller("ngModel");
  }

  /**
   * select action in dropdown, is setting the ngModelValue when defined,
   * and calling the binding function with { item }
   * @param {*} item - selected item in the dropdown
   */
  select(item) {
    if (this.ngModel) {
      this.ngModel.$setViewValue(item);
    }

    if (typeof this.$scope.dropdownAction === "function") {
      this.$scope.dropdownAction({ item });
    }
  }

  /**
   * UI method to display the dropdown options
   * @returns {void}
   */
  showDropdown() {
    this.$dropdownOptions = this.$dropdown.find(".options");

    this.$dropdownOptions.addClass("options--visible");
  }

  /**
   * UI method to hide the dropdown options
   * @returns {void}
   */
  hideDropdown() {
    this.$dropdownOptions.removeClass("options--visible");
  }

  /**
   * Complie the correct template for the dropdown
   * @param {Object} template
   * @private
   * @returns {void}
   */
  _compileTemplate(template) {
    this.$dropdown.html(template);
    this.$compile(this.$dropdown)(this.$scope);

    this.$element.append(this.$dropdown);
  }

  /**
   * return template from defined style
   * @private
   */
  _getTemplateFromStyle() {
    switch (this.$scope.dropdownStyle) {
      case "checkbox":
        return checkboxTemplate;
      case "multisearch":
        return multisearchTemplate;
      case "translation":
        return translationTemplate;
      case "version":
        return versionTemplate;
      default:
        return defaultTemplate;
    }
  }
}

/**
 * The dropdown directive
 * @returns {Object}
 */
function dropdownDirective() {
  return {
    restrict: "EA",
    scope: {
      dropdownData: "=",
      dropdownCurrent: "=",
      dropdownModified: "=",
      dropdownType: "=",
      dropdownAction: "&",
      dropdownPlaceholderAction: "&",
      dropdownPlaceholder: "@",
      dropdownStyle: "@",
    },
    require: "?ngModel",
    controller: dropdownController,
    controllerAs: "component",
  };
}

export default dropdownDirective;
