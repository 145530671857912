class MetadataService {
  /**
   * @constructor
   * @param {Object} $q
   * @param {Object} GlobalParamsService
   * @param {Object} LanguagesService
   * @param {Object} EntityFactory
   */
  constructor($q, GlobalParamsService, LanguagesService, EntityFactory) {
    this.$q = $q;
    this.GlobalParamsService = GlobalParamsService;
    this.LanguagesService = LanguagesService;
    this.EntityFactory = EntityFactory;
  }

  /**
   * Builds the string to append to the directive's title
   * @param {object} initialLanguage
   * @param {object} config
   * @param {boolean} isNewInstance
   * @param {boolean} isCreating
   * @returns {promise}
   */
  buildTitleSuffix(initialLanguage = "en", config, isNewInstance, isCreating) {
    const deferred = this.$q.defer();

    if (config.name === "metadata") {
      this.LanguagesService.getLanguage(initialLanguage).then((language) => {
        const languageObject = language || {};
        this._buildTranslationCopy(
          languageObject.name,
          isNewInstance,
          isCreating,
          deferred
        );
      });
    } else {
      deferred.resolve("");
    }

    return deferred.promise;
  }

  /**
   * _buildDisplayTitle
   * @param {string} currentLanguageName - the current language name
   * @param {boolean} isNewInstance
   * @param {boolean} isCreating
   * @param {promise} deferred
   * @returns {void}
   */
  _buildTranslationCopy(
    currentLanguageName,
    isNewInstance,
    isCreating,
    deferred
  ) {
    if (!isCreating) {
      deferred.resolve(`(${currentLanguageName})`);
    } else {
      this.LanguagesService.getLanguage(
        this.GlobalParamsService.getLanguage()
      ).then((currentGlobalLanguage) => {
        const languageToDisplay = currentGlobalLanguage
          ? currentGlobalLanguage.name
          : "English";
        const text = isNewInstance
          ? `(${languageToDisplay})`
          : `(translating from ${currentLanguageName} to ${languageToDisplay})`;

        deferred.resolve(text);
      });
    }
  }
}

export default MetadataService;
