import React from "react";
import { createIcon } from "@chakra-ui/icons";

export const SkylarkExperienceIcon = createIcon({
  displayName: "SkylarkExperienceIcon",
  viewBox: "0 0 38 35",
  path: (
    <g fill="none">
      <mask
        height="35"
        id="mask0_3:5233"
        maskUnits="userSpaceOnUse"
        style={{ maskType: "alpha" }}
        width="38"
        x="0"
        y="0"
      >
        <path
          clipRule="evenodd"
          d="M37.145 14.8765C37.9042 16.1914 37.9042 17.8115 37.145 19.1265L29.7838 31.8765C29.0246 33.1914 27.6216 34.0015 26.1032 34.0015H11.912C10.3936 34.0015 8.99058 33.1914 8.23139 31.8765L0.870173 19.1265C0.110984 17.8115 0.110984 16.1914 0.870174 14.8765L8.23139 2.12646C8.99058 0.811509 10.3936 0.00146484 11.912 0.00146484H26.1032C27.6216 0.00146484 29.0246 0.81151 29.7838 2.12646L37.145 14.8765Z"
          fill="white"
          fillRule="evenodd"
        />
      </mask>
      <g mask="url(#mask0_3:5233)">
        <path
          clipRule="evenodd"
          d="M6.97973 21.2509C6.22054 19.936 4.81751 19.126 3.29914 19.126H0.845703L6.97992 29.751H6.95322L9.40717 34.001H9.43361L9.43374 34.0012H14.3412L14.3411 34.001H28.5058L30.9596 29.751H11.8873L6.97973 21.2509Z"
          fill="#FF6C51"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M37.1193 14.8742L28.5312 -0.000976562H23.6237L23.6238 -0.000756432H9.40757L6.95361 4.24924H26.0776L30.985 12.7492C31.7442 14.0642 33.1473 14.8742 34.6656 14.8742H37.1193ZM28.5312 33.9992L37.1193 19.124H34.6656C33.1473 19.124 31.7442 19.9341 30.985 21.249L23.6237 33.9992H28.5312Z"
          fill="#0E1825"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M3.30012 14.8762C4.81849 14.8762 6.22152 14.0662 6.98071 12.7512L14.3422 0.000976562H9.43471L0.84668 14.8762H3.30012Z"
          fill="#226DFF"
          fillRule="evenodd"
        />
        <path
          clipRule="evenodd"
          d="M18.9902 20.1875C20.7506 20.1875 22.1777 18.7604 22.1777 17C22.1777 15.2396 20.7506 13.8125 18.9902 13.8125C17.2298 13.8125 15.8027 15.2396 15.8027 17C15.8027 18.7604 17.2298 20.1875 18.9902 20.1875ZM18.9902 24.4375C23.0979 24.4375 26.4277 21.1076 26.4277 17C26.4277 12.8924 23.0979 9.5625 18.9902 9.5625C14.8826 9.5625 11.5527 12.8924 11.5527 17C11.5527 21.1076 14.8826 24.4375 18.9902 24.4375Z"
          fill="#0E1825"
          fillRule="evenodd"
        />
      </g>
    </g>
  ),
});
