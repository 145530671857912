import template from "./modal-translation-settings.html";

/**
 * @file A modal for the translation settings
 */
/**
 * @memberOf  Components.Modal
 * @classdesc Renders a language selection modal for translations
 * save or cancel.
 * Expects an options object with the following format:
 * {
 *   channels: {
 *     save: string
 *   }
 * }
 */
class ModalTranslationSettingsCtrl {
  /**
   * @constructor
   * @param    {object} GlobalParamsService
   * @param    {object} MessageService
   * @param    {object} EntityDimensionsService
   * @memberof ModalTranslationSettingsCtrl
   */
  constructor(
    GlobalParamsService,
    MessageService,
    EntityDimensionsService,
    ModalTriggerService,
    ContentSettingsService
  ) {
    this.GlobalParamsService = GlobalParamsService;
    this.MessageService = MessageService;
    this.EntityDimensionsService = EntityDimensionsService;
    this.ModalTriggerService = ModalTriggerService;
    this.ContentSettingsService = ContentSettingsService;
  }

  /**
   * $onInit
   * @memberof ModalDimensionsSettings
   */
  $onInit() {
    this._setSubscriptions();
  }

  /**
   * $onDestroy
   * @returns {void}
   */
  $onDestroy() {
    this.MessageService.unregisterChannel("LocalNav.createNewLanguage");
  }

  /**
   * set initial subscriptions
   * @memberof ModalDimensionsSettings
   */
  _setSubscriptions() {
    this.MessageService.publish(this.options.channels.toggle);

    this.MessageService.registerChannel("LocalNav.createNewLanguage", 1);
    this.MessageService.on("LocalNav.createNewLanguage", () => {
      this.MessageService.publish(
        "ContentSettings.createNewLanguage",
        this.languageToApply
      );
      this.destroyModal();
    });
  }

  /**
   * @returns  {Boolean}
   * @memberof ModalTranslationSettingsCtrl
   */
  isConfirmDisabled() {
    return this.data.iso_code === this.GlobalParamsService.getLanguage();
  }

  /**
   * _buildNotificationModalOptions
   * @param {string} newLanguageName - the new language name
   * @returns {void}
   */
  _buildNotificationModalOptions(newLanguageName) {
    const currentLanguage = this.ContentSettingsService.getCurrentLanguage();
    const currentLanguageName = currentLanguage
      ? currentLanguage.name
      : "No Language set";

    const modalOptions = {
      notificationType: "createNewLanguageVersion",
      channels: {
        confirm: "LocalNav.createNewLanguage",
      },
      redirect: true,
      placeholders: {
        newLanguage: newLanguageName,
        currentLanguage: currentLanguageName,
      },
    };

    return modalOptions;
  }

  /**
   * save
   * @returns  {void}
   * @memberof ModalTranslationSettingsCtrl
   */
  save() {
    if (this.EntityDimensionsService.isLanguageAvailable(this.data.iso_code)) {
      this.MessageService.publish("Language.changed");
      this.MessageService.publish(this.options.channels.save, this.data);
      this.destroyModal();
    } else {
      const languageNotificationOptions = this._buildNotificationModalOptions(
        this.data.name
      );
      this.languageToApply = this.data.iso_code;
      this.ModalTriggerService.triggerNotification(languageNotificationOptions);
    }
  }

  /**
   * save
   * @returns  {void}
   * @memberof ModalTranslationSettingsCtrl
   */
  cancel() {
    this.destroyModal();
  }
}

const modalTranslationSettingsComponent = {
  restrict: "E",
  bindings: {
    data: "=",
    config: "<",
    options: "<",
    destroyModal: "&",
  },
  controller: ModalTranslationSettingsCtrl,
  controllerAs: "module",
  template,
};

export default modalTranslationSettingsComponent;
