import _ from "lodash";
import { createSelector } from "reselect";

export const getSelectedFilter = ({ search }) => search.selectedFilter;
export const getSearchQuery = ({ search }) => search.searchQuery;
export const getResultsVisibility = ({ search }) => search.resultsVisible;
export const getSearchData = ({ search }) => search.data;
export const getSearchError = ({ search }) => search.error;

/**
 * Mapping title properties to a displayTitle
 * @param {array<object>} results - array of results
 * @return {array<object>}
 */
export const mapResults = (results) =>
  results.map((item) => {
    // Strip the "self" and "uid" fields from the result, to get only the useful fields to build the title
    const headingFields = _.keys(_.omit(item, ["self", "uid"]));

    // Take the visual fields and build a string with its values
    const displayTitle = _.values(_.pick(item, headingFields)).join(" ");
    return { ..._.omit(item, headingFields), displayTitle };
  });

/**
 * Sort results based on if the result contains or not the search query
 *
 * @param {array<object>} results - array of results
 * @param {string} searchQuery - search query
 * @return {array<object>}
 */
export const sortResults = (results, searchQuery) =>
  results.sort((first, second) => {
    const values = [first.displayTitle, second.displayTitle];
    const lowered = [values[0].toLowerCase(), values[1].toLowerCase()];
    const loweredString = searchQuery.toLowerCase();

    /*
     * Build indexes from where the search query is found in the string.
     * If the search query "Brad" is found early in the string, eg: "Brad Pitt on Holidays",
     * it'll have a low index. Otherwise, if it's deeper in the string, it'll have an higher index
     * We want string with the query found early to show up first, so we invert the sign and subtract
     * the length of the word.
     * So, eg: "Brad Pitt on Holidays" will have an index of -(0 - string.length) which is the highest index
     * and will probably be sorted as first
     *
     */
    const indexes = lowered.map((value) => {
      const { length } = value;
      const index = value.indexOf(loweredString);

      return index === -1 ? index : -(index - length);
    });

    return indexes[1] - indexes[0];
  });

/**
 * using the multi search data and doing preparing the results for rendering
 * @param {Object} state
 * @return {Array<Object>}
 */
export const suggestedResults = createSelector(
  [getSearchData, getSearchQuery],
  ({ results, matches }, searchQuery) => {
    if (!results.length) {
      return {
        results,
        matches,
      };
    }

    const mappedResults = mapResults(results);
    const sortedResults = sortResults(mappedResults, searchQuery);

    return {
      results: sortedResults,
      matches,
    };
  }
);

/**
 * `results.length` can be 0 even at bootstrapping.
 * `matches` tho, is `null` only at reducer bootstrap, therefore we need to check every time
 * because the results container should be visible even with 0 matches, to show "0 results"
 *
 */
export const hasResultsOrMatchesSelector = createSelector(
  [getSearchData],
  ({ results, matches }) => results.length || matches !== null
);
