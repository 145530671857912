/**
 * @file Modal tabs (for edit modal mostly)
 */
/**
 * @memberOf  Components.Modal
 * @classdesc Component "decorator"
 * for handling tabbed views inside modules,
 * that keeps track of tab state.
 */
class ModalTabsCtrl {
  /**
   * @constructor
   * @param {Object} $timeout
   * @param {Object} _
   */
  constructor($timeout, _) {
    this.$timeout = $timeout;
    this._ = _;
  }

  /**
   * init
   * @param   {Array} config
   * @returns {void}
   */
  init(config) {
    this.sortedTabs = this._getTabs(config);
    this.currentTab = this.sortedTabs[0];
    this.isTabbing = false;
  }

  /**
   * switch tab
   * @param {string} tabName - tab name
   * @returns {void}
   *
   */
  changeTab(tabName) {
    if (tabName !== this.currentTab) {
      this.isTabbing = true;
      this.currentTab = tabName;
      this.$timeout(() => {
        this.isTabbing = false;
      });
    }
  }

  /**
   * getTabs
   * @param   {Array} config
   * @returns {Array} array with tab names
   */
  _getTabs(config) {
    return this._.uniq(config.map((field) => field.tab));
  }
}

/**
 * modalTabsDirective
 * @returns {Object} Directive Definition Object
 */
function modalTabsDirective() {
  return {
    restrict: "A",
    scope: false,
    controller: ModalTabsCtrl,
    controllerAs: "decorator",
    link: (scope) => {
      const config = scope.module.config.fields || scope.module.config;
      scope.decorator.init(config);
    },
  };
}

export default modalTabsDirective;
