/**
 * Maskable
 * @author Lewis Nixon <lewis.nixon@ostmodern.co.uk>
 * @copyright 2015 OstModern
 * @description Add masks to DOM elements when they need to be disabled.
 */

/**
 * Directive to add to DOM elements when they need to be disabled.
 * @param  {Object} UtilitiesService - Service
 * @param  {Object} $timeout - Angular Service
 * @returns {Object} Directive Definition Object
 */
function maskableDirective(UtilitiesService, $timeout) {
  return {
    scope: {
      triggeredBy: "@",
    },

    link(scope, element, attrs) {
      const options = {
        template: '<div class="mask"></div>',
        triggeredBy: attrs.triggeredBy,
      };
      let animatingTimer;

      /**
       * add mask class
       * @returns {void}
       */
      function addMask() {
        if (animatingTimer) {
          $timeout.cancel(animatingTimer);
        } else {
          element.append(options.template);
        }

        element.find(".mask").addClass("active");
      }

      /**
       * remove mask class
       * @returns {void}
       */
      function removeMask() {
        const mask = element.find(".mask");
        mask.removeClass("active");

        animatingTimer = $timeout(() => {
          animatingTimer = null;
          mask.remove();
        }, 200);
      }

      attrs.$observe("triggeredBy", (maskableActive) => {
        if (UtilitiesService.toBoolean(maskableActive)) {
          addMask();
        } else {
          removeMask();
        }
      });
    },
  };
}

export default maskableDirective;
