import useAngularInjection from "./use-angular-injection";

const useAngularApply = () => {
  const $rootScope = useAngularInjection("$rootScope");

  const apply = (callback) => {
    if ($rootScope.$$phase) {
      $rootScope.$evalAsync(callback);
    }
    $rootScope.$apply(callback);
  };

  return [apply];
};

export default useAngularApply;
