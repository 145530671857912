import angular from "angular";
import editableFormDirective from "./editable-form.directive";
import EDITABLE_FORM_SETTINGS from "./editable-form-settings.constant";

const EditableForm = angular
  .module("Skylark.Components.EditableForm", [])
  .directive("editableForm", editableFormDirective)
  .constant("EDITABLE_FORM_SETTINGS", EDITABLE_FORM_SETTINGS);

export default EditableForm;
