import React, { useEffect, useMemo, useState } from "react";
import { useDebounce } from "react-use";
import SkylarkIcon from "../skylark-icon/skylark-icon.component";

interface Range {
  start: number;
  end: number;
}

interface PaginationProps {
  start: number;
  limit: number;
  updateRange: (range: Range) => void;
  count: number;
}

interface computeFn {
  (start: number, limit: number, count: number): number;
}

export const computeStart: computeFn = (start, limit, count) => {
  if (start < 0 || count === 0 || start >= count) {
    return 0;
  }

  return start;
};

export const computeEnd: computeFn = (start, limit, count) => {
  const end = computeStart(start, limit, count) + limit;

  return end >= count ? count : end;
};

const Pagination = ({ start, limit, updateRange, count }: PaginationProps) => {
  const [range, setRange] = useState({
    start: computeStart(start, limit, count),
    end: computeEnd(start, limit, count),
  });

  useEffect(() => {
    setRange({
      start: computeStart(start, limit, count),
      end: computeEnd(start, limit, count),
    });
  }, [start, limit, count]);

  const getNextPage = () => {
    cancelUpdate();
    const tempStart = range.start + limit;
    const tempEnd = computeEnd(range.end, limit, count);

    setRange({
      start: tempStart,
      end: tempEnd,
    });
  };

  const getPrevPage = () => {
    cancelUpdate();
    const startMod = range.start % limit;

    const tempStart =
      range.start - limit < 0 ? range.start - startMod : range.start - limit;
    let tempEnd = range.end - limit;

    if (range.end % limit !== 0) {
      tempEnd = range.end - (range.end % limit);
    }

    setRange({
      start: tempStart,
      end: tempEnd,
    });
  };

  const [, cancelUpdate] = useDebounce(
    () => {
      updateRange(range);
    },
    250,
    [range]
  );

  const leftButtonClassNames = useMemo(
    () =>
      range.start === 0
        ? "controls__arrow controls__arrow--left controls__arrow--disabled"
        : "controls__arrow controls__arrow--left",
    [range.start]
  );

  const rightButtonClassNames = useMemo(
    () =>
      range.end >= count
        ? "controls__arrow controls__arrow--right controls__arrow--disabled"
        : "controls__arrow controls__arrow--right",
    [range.end, count]
  );

  return (
    <div className="pagination">
      <p className="pagination__count" data-test="pagination-count">
        <strong className="pagination__count--strong">{`${
          range.end <= 0 ? range.start : range.start + 1
        }-${range.end}`}</strong>{" "}
        of <strong className="pagination__count--strong">{count}</strong>
      </p>
      <div className="pagination__controls">
        <div className="controls">
          <button
            aria-label="prev"
            className={leftButtonClassNames}
            disabled={range.start === 0}
            type="button"
            onClick={getPrevPage}
          >
            <SkylarkIcon
              extraClassName="controls__arrow-icon"
              iconName="left"
            />
          </button>
          <button
            aria-label="next"
            className={rightButtonClassNames}
            disabled={range.end >= count}
            type="button"
            onClick={getNextPage}
          >
            <SkylarkIcon
              extraClassName="controls__arrow-icon"
              iconName="right"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
