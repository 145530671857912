import ApiConstant from "store/api/api.constant";
import * as ToasterAction from "./notification.action";

const ABORT_STATUS = -1;

/**
 * Notification Middleware which is using the API requests to trigger notifications
 * @returns {function(*): function(*=): *}
 * @constructor
 */
const NotificationMiddleware =
  () =>
  (next) =>
  /**
   * triggers a success toaster when api was successful and successMessage is declared
   * if an api request is failing it shows the error from that api call or the errorMessage when defined
   * the error or success message can be a function. In case of error message, the parameter is the error,
   * and for success message, the parameter is the api response
   *
   * @param {Object} action
   * @param {string} action.type - action type
   * @param {string|function} [action.successMessage] - message which shows up on successful api response from api middleware
   * @param {string|function} [action.errorMessage] - message which shows up on failure api response from api middleware
   * @param {string} [action.error] - error from api middleware
   */
  (action) => {
    const { type, successMessage, errorMessage, error, response, errorStatus } =
      action;

    if (ApiConstant.isSuccessType(type)) {
      let toasterMessage = successMessage;

      if (typeof successMessage === "function") {
        toasterMessage = successMessage(response);
      }

      if (toasterMessage) {
        next(ToasterAction.showMessage(toasterMessage));
      }
    }

    if (ApiConstant.isFailureType(type)) {
      const isCancelled = errorStatus === ABORT_STATUS;

      if (isCancelled) {
        return next(action);
      }

      let toasterErrorMessage = errorMessage || error;

      if (typeof errorMessage === "function") {
        toasterErrorMessage = errorMessage(error);
      }

      if (toasterErrorMessage) {
        next(ToasterAction.showError(toasterErrorMessage));
      }
    }

    return next(action);
  };
export default NotificationMiddleware;
