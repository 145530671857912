const changelogConstants = {
  actionTypeCopy: {
    created: "Created",
    removed: "Removed",
    edited: "Edited",
    added: "Added",
    placed: "Placed",
    unplaced: "Unplaced",
    positioned: "Repositioned",
    deleted: "Deleted",
  },
  deletedEntity: "This item no longer exists.",
};

export default changelogConstants;
