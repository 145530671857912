/**
 * @file BatchAddingService
 * @description A service to store and manage selected data in data lists.
 */
class BatchAddingService {
  /**
   * BatchAddingService
   * @param   {Object} _
   */
  constructor(_) {
    this._ = _;

    this.selectedItems = [];
    this.savedSelectedItems = [];
  }

  /**
   * getSelectedItems
   * @access public
   * @returns {array} this.selectedItems - list of items which have been selected
   */
  getSelectedItems() {
    return this.savedSelectedItems;
  }

  /**
   * number of selected items;
   * @return {number}
   */
  getSelectedAmount() {
    return this.savedSelectedItems.length;
  }

  /**
   * remove selected items
   * @returns {Array} this.savedSelectedItems - current selected items
   */
  removeSelectedItems() {
    this._.remove(this.savedSelectedItems, (cur) =>
      this.selectedItems.some((item) => cur.self === item.self)
    );
    this.selectedItems.length = 0;

    return this.savedSelectedItems;
  }

  /**
   * gets slice of selected items
   * @access public
   * @param  {Number} start - start index
   * @param  {Number} end - end index
   * @returns {Array} slice of array delimited by start and end params
   */
  getPage(start, end) {
    return this.savedSelectedItems.slice(start, end);
  }

  /**
   * addSelectedItem - adds selected item to list
   * @access private
   * @param {item} item to add to selected items
   * @returns {Array} this.selectedItems - temporary selected items
   */
  addSelectedItem(item) {
    this.selectedItems.push(item);

    return this.selectedItems;
  }

  /**
   * removeSelectedItem
   * @access private
   * @param {object} item - item to add to selected items
   * @returns {array} list of items which have been selected
   */
  removeSelectedItem(item) {
    this._.remove(this.selectedItems, (cur) => cur.uid === item.uid);

    return this.selectedItems;
  }

  /**
   * manageSelected
   * @access public
   * @param {object} item - item to change status
   * @returns {function} addSelectedItem OR removeSelectedItem
   */
  manageSelected(item) {
    const isAlreadySelected = this.isItemSelected(item);
    if (item.isSelected && !isAlreadySelected) {
      return this.addSelectedItem(item);
    }
    if (!item.isSelected) {
      return this.removeSelectedItem(item);
    }
  }

  /**
   * save currently selected items to saved selected items list
   * @returns {Array} this.savedSelectedItems - a stored list of selected items
   */
  saveSelectedItems() {
    const newItems = this.selectedItems
      .filter((item) => item.isSelected)
      .map((item) => {
        item.isSelected = false;

        return item;
      })
      .reverse();

    this.selectedItems.length = 0;
    // eslint-disable-next-line prefer-spread
    this.savedSelectedItems.unshift.apply(this.savedSelectedItems, newItems);

    return this.savedSelectedItems;
  }

  /**
   * isItemSelected
   * @access public
   * @description helper to determine whether the item is already in the selected list
   * @param {object} selectedItem - singular entity from the list of data
   * @returns {boolean} whether the item has been previously selected
   */
  isItemSelected(selectedItem) {
    return this.selectedItems.some((item) => item.self === selectedItem.self);
  }

  /**
   * isWholePageSelected
   * @access public
   * @param {Array} list - list of items
   * @returns {Boolean} whether every item in this page is selected
   */
  isWholePageSelected(list) {
    if (list.length) {
      return list.every((item) => item.isSelected);
    }
  }

  /**
   * isItemInSelectedLists
   * @param {object} item - data item
   * @returns {boolean} - whether this item is selected or not
   */
  isItemInSelectedLists(item) {
    return (
      this.savedSelectedItems.some(
        (savedItem) => savedItem.self === item.self
      ) || this.selectedItems.some((savedItem) => savedItem.self === item.self)
    );
  }

  /**
   * resetSelectedItems
   * @access public
   * @returns {void}
   */
  resetSelectedItems() {
    this.selectedItems = [];
    this.savedSelectedItems = [];

    return this.selectedItems;
  }
}

export default BatchAddingService;
