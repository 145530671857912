import ReduceReducers from "reduce-reducers";
import {
  createApiReducer,
  createAttachDataReducer,
  createResetDataApiReducer,
} from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import EntityConstant from "./entity.constant";

/**
 * update schedule url which got changed
 * @param scheduleUrls
 * @param updateScheduleUrl
 */
const updateScheduleUrls = (scheduleUrls, updateScheduleUrl) =>
  scheduleUrls.map((schedule) => {
    if (schedule.uid === updateScheduleUrl.uid) {
      return updateScheduleUrl;
    }
    return schedule;
  });

const initialState = {
  data: {},
};

const get = createResetDataApiReducer(
  initialState,
  EntityConstant.FETCH_ENTITY
);
const getAndAttach = createAttachDataReducer(
  initialState,
  EntityConstant.FETCH_AND_ATTACH_ENTITY
);
const update = createAttachDataReducer(
  initialState,
  EntityConstant.UPDATE_ENTITY
);
const create = createApiReducer(initialState, EntityConstant.CREATE_ENTITY);

const entityReducer = (state = initialState, action) => {
  switch (action.type) {
    case EntityConstant.SET_CURRENT_ENTITY:
      return {
        ...state,
        entityName: action.entityName,
        entityType: action.entityType,
      };
    case EntityConstant.CREATE_ENTITY:
      return {
        ...state,
        data: {},
        creating: true,
      };
    case EntityConstant.CREATE_TRANSLATION:
      return {
        ...state,
        creating: true,
        translating: true,
      };
    case EntityConstant.CREATE_ENTITY_CANCELLED:
    case ApiConstant.getSuccessType(EntityConstant.CREATE_ENTITY):
      return {
        ...state,
        creating: false,
        translating: false,
      };
    case ApiConstant.getSuccessType(EntityConstant.UPDATE_SCHEDULE): {
      const updatedScheduleUrls = updateScheduleUrls(
        state.data.schedule_urls,
        action.response
      );

      return {
        ...state,
        data: {
          ...state.data,
          schedule_urls: updatedScheduleUrls,
        },
      };
    }
    default:
      return state;
  }
};

const EntityReducer = ReduceReducers(
  initialState,
  get,
  getAndAttach,
  update,
  create,
  entityReducer
);

export default EntityReducer;
