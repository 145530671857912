import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { KEYCODES } from "skylarklib/constants/keycodes.constants";
import EntityList from "components/_react/entity-list/entity-list";

import Button from "components/_react/button/button";
import { BUTTON_SECONDARY_ON_DARK } from "skylarklib/constants/button.constants";
import Loader from "components/_react/loader/loading.component";
import MessageOverlay from "components/_react/message-overlay/message-overlay";

import ModalHeader from "components/_react/modal/modal-header/modal-header";

import { MODAL_LISTING_NAMESPACE } from "store/listing/modal-listing.constants";
import PaginationWithFilter from "components/_react/pagination/pagination-with.filter";
import SearchAndFilter from "components/_react/search-and-filter/search-and-filter.component";
import { generateDefaultActiveFilter } from "skylarklib/helpers/entity-listing-filter-helper";
import { ThemeContext } from "components/_react/theme/theme";
import styles from "./modal-list.scss";

/**
 * ModalList
 */
export default class ModalList extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    options: PropTypes.shape({
      metadata: PropTypes.object,
      entityType: PropTypes.object,
    }).isRequired,
    configuration: PropTypes.object,
    modalList: PropTypes.arrayOf(Object).isRequired,
    destroyModal: PropTypes.func.isRequired,
    fetchEntityListing: PropTypes.func.isRequired,
    resetSearchFilter: PropTypes.func.isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    configuration: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.destroyModal = this.destroyModal.bind(this);
    this.confirmModalAction = this.confirmModalAction.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
    this.getListData = this.getListData.bind(this);
    this.resetSearch = this.resetSearch.bind(this);

    this.listRef = React.createRef();

    this.state = {
      selectedEntity: {},
    };
  }

  /**
   * componentWillMount lifecycle hook
   */
  componentWillMount() {
    document.addEventListener("keydown", this.handleKeyPress, false);

    this.getModalListConfig();
  }

  componentDidMount() {
    this.setSearchAndFilters();
    this.getListData();
  }

  /**
   * componentDidUpdate
   */
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.configuration !== this.props.configuration) {
      this.setSearchAndFilters();
    }

    if (prevProps.query !== this.props.query) {
      this.getListData();
    }
  }

  /**
   * componentWillUnmount lifecycle hook
   */
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
    this.props.resetSearchFilter({ namespace: MODAL_LISTING_NAMESPACE });
  }

  /**
   * setConfig
   * @param {Object} modalConfig
   */
  setConfig(modalListConfig) {
    return modalListConfig.content_types.find(
      (type) => type.name === this.props.options.metadata.entityType
    );
  }

  /**
   * set filter for listing items
   */
  setSearchAndFilters() {
    const { entityType } = this.props.options.metadata;

    if (_.isEmpty(this.props.configuration)) {
      return;
    }

    const filters = {
      dynamicProperties: [
        ...generateDefaultActiveFilter(
          entityType,
          undefined,
          this.props.configuration
        ).dynamicProperties,
      ],
    };

    this.props.setActiveFilters({
      entityName: entityType,
      ...filters,
      namespace: MODAL_LISTING_NAMESPACE,
    });
  }

  /**
   * getListData - fetches the correct modal list
   */
  getListData() {
    const { entityType } = this.props.options.metadata;

    if (_.isEmpty(this.props.configuration) || !this.props.query) {
      return;
    }

    this.props.fetchEntityListing({
      entityName: entityType,
      query: this.props.query,
      config: this.props.configuration,
      namespace: MODAL_LISTING_NAMESPACE,
    });
  }

  /**
   * getModalListConfig
   */
  getModalListConfig() {
    if (_.isEmpty(this.props.configuration)) {
      this.props.fetchBaseConfig(this.props.options.metadata.entityType);
    }
  }

  /**
   * scroll the list element to the top when paginating
   */
  scrollListToTop() {
    this.listRef.scrollTo(0, 0);
  }

  /**
   * cancels the notification when user presses esc key
   * only works when modal is actually cancellable.
   * @param  {Object} event
   */
  handleKeyPress(event) {
    if (event.keyCode === KEYCODES.ESCAPE) {
      this.destroyModal();
    }
  }

  /**
   * destroyModal
   * @return {void}
   */
  destroyModal() {
    this.props.destroyModal();
  }

  /**
   * confirmModal
   */
  confirmModalAction() {
    try {
      typeof this.props.options.onSave === "function" &&
        this.props.options.onSave({
          selectedItem: [this.state.selectedEntity],
          type: this.props.options.metadata.type,
          location: this.props.options.metadata.location,
        });
    } finally {
      this.destroyModal();
    }
  }

  /**
   * toggleSelected
   */
  toggleSelected(entity) {
    this.setState({
      selectedEntity: entity,
    });
  }

  /**
   * shouldSetItems
   * @param  {Object} nextProps
   * @return {Boolean}
   */
  shouldSetItems(nextProps) {
    const { modalList: previousModalList, loading } = this.props;
    const { modalList: nextModalList } = nextProps;

    return (
      (!nextProps.loading && loading) ||
      (!nextProps.loading &&
        !loading &&
        nextModalList.length !== previousModalList.length)
    );
  }

  /**
   * hasSelectedEntities
   * @return {Booleam}
   */
  hasSelectedEntities() {
    return Object.keys(this.state.selectedEntity).length;
  }

  /**
   * isListEmpty
   * @return {Boolean}
   */
  isListEmpty() {
    return !this.props.modalList.length && !this.props.loading;
  }

  /**
   * reset search and Filter
   */
  resetSearch() {
    this.props.resetSearchFilter({ namespace: MODAL_LISTING_NAMESPACE });
    this.setSearchAndFilters();
  }

  /**
   * render
   * @returns {Node}
   */
  render() {
    return (
      <div className={styles["modal-list"]}>
        {!_.isEmpty(this.props.configuration) && (
          <div>
            <ThemeContext.Provider value={""}>
              <ModalHeader
                title={`Add ${this.props.configuration.singular_name}`}
              />
              <div className={styles["modal-list__body"]}>
                <div className={styles["modal-list__body-header"]}>
                  <div className={styles["modal-list__filters"]}>
                    <SearchAndFilter
                      config={this.props.configuration}
                      entityName={this.props.options.metadata.entityType}
                      namespace={MODAL_LISTING_NAMESPACE}
                    ></SearchAndFilter>
                  </div>
                  <div className={styles["modal-list__actions"]}>
                    <PaginationWithFilter
                      config={this.props.configuration}
                      entityName={this.props.options.metadata.entityType}
                      namespace={MODAL_LISTING_NAMESPACE}
                    ></PaginationWithFilter>
                  </div>
                </div>
                <div className={styles["modal-list__data"]}>
                  <EntityList
                    context="modal"
                    canSelectMultiple={false}
                    config={this.props.configuration}
                    items={this.props.modalList}
                    onSelect={this.toggleSelected}
                    createListRef={(listElem) => (this.listRef = listElem)}
                  />
                  {this.isListEmpty() && (
                    <MessageOverlay>
                      <p className={styles["modal-list__message-reset"]}>
                        Please&nbsp;
                        <span
                          className={styles["modal-list__message-reset-button"]}
                          role="button"
                          tabIndex="0"
                          onClick={() => this.resetSearch()}
                        >
                          reset your search
                        </span>
                      </p>
                    </MessageOverlay>
                  )}
                  <Loader loading={this.props.loading} />
                </div>
              </div>
              <div className={styles["modal-list__footer"]}>
                <div className={styles["modal-list__footer-button-wrap"]}>
                  <Button
                    buttonType="standard"
                    icon="tick"
                    id="modal-list-save"
                    copy={`Add ${this.props.configuration.singular_name}`}
                    action={this.confirmModalAction}
                    isDisabled={!this.hasSelectedEntities()}
                    disabledDark
                  />
                </div>
                <div className={styles["modal-list__footer-button-wrap"]}>
                  <Button
                    buttonType="standard"
                    copy="Cancel"
                    id="modal-list-cancel"
                    action={this.destroyModal}
                    buttonColor={BUTTON_SECONDARY_ON_DARK}
                  />
                </div>
              </div>
            </ThemeContext.Provider>
          </div>
        )}
      </div>
    );
  }
}
