/**
 * Created by danielbanting on 08/08/2016.
 */

class PageTitleController {
  /**
   * @constructor
   * @param {Object} $state
   * @param {Object} $transitions
   * @param   {Object} $jQuery
   */
  constructor($state, $jQuery, $transitions) {
    this.$state = $state;
    this.$jQuery = $jQuery;
    this.$transitions = $transitions;
  }

  /**
   * @returns {void}
   * @public
   */
  init() {
    this.defaultTitle = "Skylark Classic";
    this._updatePageTitle();
    this._listenForRouteChange();
  }

  /**
   * listen for route change event
   * @returns {void}
   * @private
   */
  _listenForRouteChange() {
    this.$transitions.onStart({}, (transition) => {
      const toState = transition.to();

      this.pageTitle = toState.pageTitle || toState.title;
      this._updatePageTitle();
    });
  }

  /**
   * @param {string} str
   * @returns {string}
   * @private
   */
  _toTitleCase(str) {
    return str.replace(
      /\w\S*/g,
      (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    );
  }

  /**
   * get formatted page title
   * @returns {string} formatted page title
   * @private
   */
  _getFormattedPageTitle() {
    const formattedPageTitleParts = [];
    this.pageTitle
      .toLowerCase()
      .split(":")
      .forEach((item) => {
        formattedPageTitleParts.push(this._toTitleCase(item));
      });

    return formattedPageTitleParts.join(" ");
  }

  /**
   * update page title in dom
   * @returns {void}
   * @private
   */
  _updatePageTitle() {
    if (this.pageTitle === "Home" || !this.pageTitle) {
      this.$jQuery("#page-title").html(this.defaultTitle);
      return;
    }
    this.$jQuery("#page-title").html(
      `${this._getFormattedPageTitle()} - ${this.defaultTitle}`
    );
  }
}

/**
 * @returns {object}
 * @private
 */
function pageTitleDirective() {
  return {
    restrict: "A",
    controller: PageTitleController,
    controllerAs: "attributePageTitle",
    link: (scope) => {
      scope.attributePageTitle.init();
    },
  };
}

export default pageTitleDirective;
