import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import renderColumn from "../entity-list-column-renderer/entity-list-column-renderer";

import styles from "./list-item.scss";

/**
 * ListItem
 */
export default class ListItem extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    config: PropTypes.shape().isRequired,
    context: PropTypes.string.isRequired,
    entity: PropTypes.shape().isRequired,
    onSelect: PropTypes.func.isRequired,
    isBiggest: PropTypes.bool.isRequired,
    isSelected: PropTypes.bool,
  };

  /**
   * defaultProps
   */
  static defaultProps = {
    isSelected: false,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
  }

  /**
   * onSelect
   * @param  {Object} entity
   */
  onSelect(entity) {
    this.props.onSelect(entity);
  }

  /**
   * Whether the entity being displayed is live.
   * @param {Object} entity
   * @returns {Boolean}
   * @memberof ListItem
   */
  isLive = (entity) =>
    entity.schedule_urls.length && !entity.expired && !entity.future;

  /**
   * render component
   */
  render() {
    const isFaded = !!(
      this.props.entity.schedule_urls && !this.isLive(this.props.entity)
    );

    return (
      <div
        role="listbox"
        tabIndex="0"
        className={classnames(
          styles["list-item"],
          styles[`list-item--${this.props.context}`],
          { [styles["list-item--is-biggest"]]: this.props.isBiggest },
          { [styles["list-item--is-selected"]]: this.props.isSelected },
          { [styles["list-item--is-faded"]]: isFaded }
        )}
        onClick={() => this.onSelect(this.props.entity)}
      >
        <div
          className={classnames(
            styles["list-item__columns"],
            styles[`list-item__columns--${this.props.context}`]
          )}
        >
          {this.props.config.columns.map((column) => (
            <div
              key={column.name}
              className={classnames(
                styles["list-item__column"],
                styles[`grid-column-${column.size}`]
              )}
            >
              <div className={styles["list-item__column-inner"]}>
                {renderColumn(column, { ...this.props, isFaded })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
