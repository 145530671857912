import { BlockMapBuilder, EditorState, Modifier } from "draft-js";

import { List, Repeat, Map } from "immutable";

import { SUPPORTED_FILE_TYPES } from "skylarklib/constants/global.constants";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

import { MEDIA_BLOCK_TYPES } from "components/_react/editor/text-editor/text-editor.constants";

/**
 * _isMediaPlaceholder
 * @param  {Immutable.Record} contentState
 * @param  {Immutable.Record} selection
 * @return {Boolean}
 */
function _isMediaPlaceholder(contentState, selection) {
  return (
    DraftHelpers.getBlock(contentState, selection.getStartKey()).getType() ===
    "media-placeholder"
  );
}

/**
 * _shouldCreateEmptyBlock
 * @param  {Immutable.Record} state
 * @return {Boolean}
 */
function _shouldCreateEmptyBlock(state) {
  return (
    !DraftHelpers.isCursorAtStart(state) &&
    !DraftHelpers.isSelectionAtEnd(state) &&
    !DraftHelpers.isMediaBlock(state)
  );
}

/**
 * hasImageEntities
 * @param  {Object} entityMap
 * @return {Boolean}
 */
function hasImageEntities(entityMap) {
  return Object.values(entityMap).some(
    (entity) => entity.type === "image" && entity.data.media.filePreview
  );
}

/**
 * Checks if the file type is supported in the editor.
 * @param {File} file - the file that was uploaded
 * @returns {boolean} - is it type of image
 */
function isFileSupported(file) {
  return SUPPORTED_FILE_TYPES.test(file.type);
}

/**
 * isBlockBeforeMedia
 * @param  {Immutable.Record} state
 * @return {Boolean}
 */
function isBlockBeforeMedia(state) {
  const blockBefore = state
    .getCurrentContent()
    .getBlockBefore(state.getSelection().getAnchorKey());

  return MEDIA_BLOCK_TYPES.includes(blockBefore ? blockBefore.getType() : "");
}

/**
 * Create object of data required to render image in editor
 * @param  {File} file
 * @return {Object} imageData
 */
function createImageData(file) {
  return {
    file,
    filePreview: window.URL.createObjectURL(file),
  };
}

/**
 * Creates a media block
 * @param   {Immutable.Record} newState
 * @param   {String} newState
 * @param   {Object} file ad file preview
 * @returns {Boolean}
 */
function createMediaBlock(state, type, config, media, selectionState) {
  const initialContentState = state.getCurrentContent();
  let selection = selectionState;
  let editorState = state;
  const isTextPlaceholder = DraftHelpers.isPlaceholder(editorState);
  const isImagePlaceholder = _isMediaPlaceholder(
    initialContentState,
    selection
  );

  if (isImagePlaceholder || isTextPlaceholder) {
    editorState = DraftHelpers.selectionToStart(
      editorState,
      DraftHelpers.getBlock(
        initialContentState,
        selection.getStartKey()
      ).getKey()
    );

    selection = editorState.getSelection();
  }

  if (isImagePlaceholder) {
    editorState = DraftHelpers.changeBlockType(
      editorState,
      selection,
      config.default_section
    );
    editorState = DraftHelpers.changeBlockData(
      editorState,
      selection,
      DraftHelpers.createBlockData(config, type)
    );
  }

  let contentState = editorState.getCurrentContent();
  const hasBlockAfter = contentState.getBlockAfter(selection.getAnchorKey());

  contentState = DraftHelpers.removeRange(contentState, selection, "backward");

  if (!isImagePlaceholder && !isTextPlaceholder) {
    const currentBlock = contentState.getBlockForKey(selection.getEndKey());

    contentState = DraftHelpers.splitBlock(contentState);

    const splitBlockSelection = contentState.getSelectionAfter();

    contentState = Modifier.mergeBlockData(
      contentState,
      splitBlockSelection,
      currentBlock.getData()
    );

    contentState = EditorState.push(
      editorState,
      contentState,
      "split-block"
    ).getCurrentContent();
  }

  const insertionTarget =
    DraftHelpers.isCursorAtStart(editorState) && !isTextPlaceholder
      ? contentState.getSelectionBefore()
      : contentState.getSelectionAfter();

  contentState = contentState.createEntity(type, "IMMUTABLE", {
    contentType: type,
    media,
  });

  const entityKey = contentState.getLastCreatedEntityKey();
  contentState = DraftHelpers.setBlockType(contentState, insertionTarget, type);
  const charData = DraftHelpers.createCharacterMetadata({ entity: entityKey });

  const mediaBlock = DraftHelpers.buildContentBlock(
    type,
    " ",
    { ...DraftHelpers.createBlockData(config, type), media },
    List(Repeat(charData, 1))
  );
  const fragmentArray = [mediaBlock];

  if (
    (!hasBlockAfter && !DraftHelpers.isCursorAtStart(state)) ||
    _shouldCreateEmptyBlock(editorState) ||
    isTextPlaceholder
  ) {
    fragmentArray.push(
      DraftHelpers.buildContentBlock("unstyled", "", {
        ...DraftHelpers.createBlockData(config, "paragraph"),
      })
    );
  }

  const fragment = BlockMapBuilder.createFromArray(fragmentArray);

  contentState = DraftHelpers.replaceWithFragment(
    contentState,
    insertionTarget,
    fragment
  );
  editorState = EditorState.push(editorState, contentState, "insert-fragment");
  contentState = editorState.getCurrentContent();

  const blockAfter = contentState.getBlockAfter(insertionTarget.getStartKey());

  return EditorState.push(
    editorState,
    contentState.merge({
      selectionBefore: editorState.getSelection(),
      selectionAfter: DraftHelpers.createBlockSelection(
        blockAfter.getKey(),
        0,
        0
      ),
    })
  );
}

/**
 * replaceMediaBlock
 * @description - basically the draft entity API is awful and there is absolutely no
 * efficient way of changing a core entity type value, so the process requires deleting
 * the existing entity, creating a new one, and then applying that to the content block
 * and returning a new <ContentState> map that can be merged to the previous <EditorState>
 * @returns {Function}
 */
function replaceMediaBlock(state, block, selectionState, media, type) {
  let editorState = state;
  let newBlock = block;

  newBlock = newBlock.setIn(["data", "media"], Map(media));
  newBlock = newBlock.setIn(["data", "contentType"], type);
  editorState = DraftHelpers.changeBlockData(
    editorState,
    selectionState,
    newBlock.getData()
  );

  let contentState = editorState.getCurrentContent();

  contentState = Modifier.applyEntity(contentState, selectionState, null);
  contentState = contentState.createEntity(type, "IMMUTABLE", {
    contentType: type,
    media,
  });
  contentState = Modifier.applyEntity(
    contentState,
    selectionState,
    contentState.getLastCreatedEntityKey()
  );
  contentState = DraftHelpers.setBlockType(contentState, selectionState, type);

  return EditorState.push(editorState, contentState);
}

/**
 * @description We have custom entity types of say 'image' or 'media-placeholder', so we can't
 * presume Draft will handle backspaces for them correctly, as they are looking
 * for entity types of 'atomic' only. In that case, we need to manually handle the
 * deletion of the character holding the reference to the entity within the media block,
 * and then delete the block itself, otherwise the entity character is inherited in
 * the block above as an empty <img /> tag, breaking html conversion to draft state.
 *
 * @param  {Immutable.Record} state - <ContentState>
 * @return {Immutable.Record} new <EditorState>
 */
function handleMediaDelete(editorState, isBlockMedia, selectionState) {
  const initialContentState = editorState.getCurrentContent();
  const selection = selectionState || editorState.getSelection();
  let contentState = initialContentState;

  const blockBefore = initialContentState.getBlockBefore(
    selection.getStartKey()
  );
  const blockToDelete = isBlockMedia
    ? contentState.getBlockForKey(selection.getStartKey())
    : blockBefore;

  contentState = DraftHelpers.removeRange(
    contentState,
    DraftHelpers.createBlockSelection(blockToDelete.getKey(), 0, 0),
    "backward"
  );

  const blockMap = contentState.getBlockMap().delete(blockToDelete.getKey());

  contentState = contentState.merge({
    blockMap,
    selectionAfter: isBlockMedia
      ? initialContentState.getSelectionAfter()
      : editorState.getSelection(),
  });

  const newEditorState = EditorState.forceSelection(
    editorState,
    selection.merge({
      anchorKey: blockBefore.getKey(),
      anchorOffset: blockBefore.getText().length,
      focusKey: blockBefore.getKey(),
      focusOffset: blockBefore.getText().length,
      isBackward: false,
    })
  );

  return EditorState.push(newEditorState, contentState, "remove-range");
}

/**
 * generateEntityKeyMap - to merge the correct data to the correct entity
 * @param   {Object} entities
 * @param   {Immutable.Record} contentState - <ContentState>
 * @returns {Object} entityKeyMap
 */
function generateEntityKeyMap(entities, contentState) {
  let entityStorageKey = 0;
  const entityKeyMap = {};

  contentState.getBlockMap().forEach((block) => {
    const callback = (startKey) => {
      const entityKeyString = block.getEntityAt(startKey);

      if (entityKeyMap[entityKeyString]) {
        return;
      }

      entityKeyMap[entityStorageKey] = `${entityKeyString}`;
      entityStorageKey++;
    };

    block.findEntityRanges(
      (character) => character.getEntity() !== null,
      callback
    );
  });

  return entityKeyMap;
}

/**
 * assignImagestoEntities
 * @param  {Array} entities
 * @param  {Immutable.Record} contentState - <ContentState>
 * @return {Immutable.Record} content state
 */
function assignImagestoEntities(entities, contentState) {
  const entityKeyMap = generateEntityKeyMap(entities, contentState);

  entities.forEach((entity, key) => {
    if (entity.data && entity.data.upload_image_url) {
      const media = entity.data;

      contentState.mergeEntityData(entityKeyMap[key], { media });
    }
  });

  return EditorState.createWithContent(contentState).getCurrentContent();
}

/**
 * isRemovingMedia
 * @param   {Immutable} state
 * @returns {Boolean}
 */
function isRemovingMedia(state) {
  return (
    DraftHelpers.isMediaBlock(state) ||
    (isBlockBeforeMedia(state) && DraftHelpers.isCursorAtStart(state))
  );
}

export {
  hasImageEntities,
  isBlockBeforeMedia,
  isFileSupported,
  createImageData,
  createMediaBlock,
  handleMediaDelete,
  generateEntityKeyMap,
  assignImagestoEntities,
  isRemovingMedia,
  replaceMediaBlock,
};
