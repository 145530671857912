import { createApiAction } from "store/api/api.actions";
import { buildApiUrl } from "skylarklib/helpers/entities";
import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import { ConfigType } from "store/entities-config/entities-config.types";
import {
  FETCH_ENTITY_LISTING,
  FETCH_ENTITY_LISTING_COUNT,
  namespaced,
  RESET_SEARCH_FILTER,
  SET_ACTIVE_FILTERS,
  SET_COUNT_QUERY,
} from "./entity-listing.constant";
import {
  ApiQuery,
  GenericActionParams,
  SetActiveFiltersInterface,
} from "./entity-listing.types";
import { sanitiseStringForElasticSearch } from "../../../components/_react/search/search-bar/search-bar.component";

const apiEndpointMapping = {
  "editorial-schedules": "schedules",
  licensing: "schedules",
};

/*
 * Escape any Elastic Search characters and add the *'s to the start and end,
 * remove the spaces from the previous search string's *'s
 */
const sanitiseQuery = (query: string) =>
  `*${sanitiseStringForElasticSearch(query.substring(1, query.length - 1))}*`;

/**
 * Wrapper function that changes the user API if Cognito is enabled, used for the user page
 * @param url
 * @param generatedRequestConfig
 * @returns
 */
const ApiGetCognitoWrapper = (
  url: string,
  generatedRequestConfig: any
): Promise<any> => {
  const ApiService = Injector.get("ApiService");
  const EntityFactory = Injector.get("EntityFactory");

  if (EntityFactory.shouldInterceptCognitoRequest(url, "GET")) {
    return EntityFactory.interceptCognitoRequest(url, "GET", {}).then(
      ({ updatedUrl }) => {
        // No /count API is implemented for user management, don't make request
        if (updatedUrl === "/api/users/count/") {
          return;
        }
        return ApiService.get(updatedUrl, generatedRequestConfig);
      }
    );
  }

  return ApiService.get(url, generatedRequestConfig);
};

/**
 * This will take care of retrieving the listing entities, with the use of the query
 */
export const fetchEntityListing = ({
  entityName,
  entityType,
  query,
  config,
  cancelToken,
  namespace,
}: GenericActionParams & {
  query: ApiQuery;
  config: ConfigType;
  cancelToken?: Promise<any>;
}): any => {
  const ApiFilterFactory = Injector.get("ApiFilterFactory");
  const ApiRequestConfigFactory = Injector.get("ApiRequestConfigFactory");
  const backendEntity = apiEndpointMapping[entityName] || entityName;

  const url = buildApiUrl(backendEntity);
  const filters = ApiFilterFactory.appendToFilters(config, query);

  const requestConfig = {
    params: filters,
  };

  // instead could use the api service as it is
  const generatedRequestConfig = {
    ...ApiRequestConfigFactory.createRequestConfig({
      useGlobalParams: true,
      // Use the current global language or default back to en when it is not set
      language: ApiRequestConfigFactory.getLanguage(false),
      requestConfig,
    }),
    timeout: cancelToken,
  };

  const type = namespaced(namespace, FETCH_ENTITY_LISTING);

  // Before the request is sent, escape any Elastic Search reserved characters
  const { q } = generatedRequestConfig?.params || {};
  if (q && q.startsWith("*") && q.endsWith("*")) {
    generatedRequestConfig.params.q = sanitiseQuery(q);
  }

  return {
    ...createApiAction(type, ApiGetCognitoWrapper(url, generatedRequestConfig)),
    entityName,
    entityType,
  };
};

/**
 * the fetch and the listing should have the same query builder so that they are in sync,
 * otherwise issue could happen where the count is different to the listings result
 */
export const fetchEntityListingCount = ({
  entityName,
  entityType,
  countQuery,
  namespace,
}: GenericActionParams & {
  countQuery: Omit<ApiQuery, "start" | "limit">;
}): any => {
  const ApiRequestConfigFactory = Injector.get("ApiRequestConfigFactory");
  const requestConfig = {
    params: countQuery,
  };

  const backendEntity = apiEndpointMapping[entityName] || entityName;
  const url = `/api/${backendEntity}/count/`;

  const generatedRequestConfig = ApiRequestConfigFactory.createRequestConfig({
    useGlobalParams: true,
    // Use the current global language or default back to en when it is not set
    language: ApiRequestConfigFactory.getLanguage(false),
    requestConfig,
  });

  const type = namespaced(namespace, FETCH_ENTITY_LISTING_COUNT);

  // Before the request is sent, escape any Elastic Search reserved characters
  const { q } = generatedRequestConfig?.params || {};
  if (q && q.startsWith("*") && q.endsWith("*")) {
    generatedRequestConfig.params.q = sanitiseQuery(q);
  }

  return {
    ...createApiAction(type, ApiGetCognitoWrapper(url, generatedRequestConfig)),
    entityName,
    entityType,
  };
};

export const setActiveFilters: SetActiveFiltersInterface = ({
  entityName,
  entityType,
  dynamicProperties,
  namespace,
}) => ({
  type: namespaced(namespace, SET_ACTIVE_FILTERS),
  entityName,
  entityType,
  activeFiltersParams: {
    entityName,
    entityType,
    dynamicProperties,
  },
});

export const setCountQuery = ({
  entityName,
  entityType,
  namespace,
}: GenericActionParams) => ({
  type: namespaced(namespace, SET_COUNT_QUERY),
  entityName,
  entityType,
});

/**
 * resetting results
 */
export const resetSearchFilter = ({
  entityName,
  entityType,
  namespace,
}: GenericActionParams) => ({
  type: namespaced(namespace, RESET_SEARCH_FILTER),
  entityName,
  entityType,
});
