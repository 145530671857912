import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useDebounce } from "react-use";
import {
  getSearchQuery,
  getSelectedFilter,
  hasResultsOrMatchesSelector,
} from "store/search/search.selector";
import { getBaseConfig } from "store/entities-config/entities-config.selector";
import {
  fetchSearch,
  setResultsVisibility,
  setSearchQuery,
} from "store/search/search.action";
import {
  buildEntitySearchURL,
  getSlugTypeByEntity,
} from "skylarklib/helpers/entities";
import { getGlobalParams } from "store/global-params/global-params.selector";
import useLocation from "hooks/use-location";
import {
  resetSearchFilter,
  setActiveFilters,
} from "store/listing/entity-listing/entity-listing.actions";
import { MINIMUM_CHARACTERS } from "../search.constant";

/**
 * Escapes any Elasticsearch reserved characters from a string
 * any spaces are turned into a *
 * https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#_reserved_characters
 *
 * @param {string} string
 *
 * @return {string}
 */
export const sanitiseStringForElasticSearch = (string) =>
  string
    // Remove any special characters from the search string
    // eslint-disable-next-line
    .replace(/[\+\-=&|><!(){}[\]^",*~?\/\\]/g, "* ")
    // Remove any colons
    .replace(/[:]/g, "*")
    // remove duplicated *'s (e.g. **)
    .replace(/\*+/g, "*")
    // remove duplicated *+space
    .replace(/(\*\s)+/g, "* ")
    // Remove duplicated spaces
    .replace(/\s+/g, " ")
    // lowercase the string
    .toLowerCase();

/**
 * Put outside for unit testing
 * config is a baseConfig of an object
 *
 * Generates a search query out of a config search filter
 *
 * @param {object} config
 * @param {string} searchQuery
 * @param {boolean} [useGenericField]
 *
 * @return {string}
 */
export const getSearchFilters = ({ config, searchQuery, useGenericField }) => {
  const { filters } = config;

  if (searchQuery && searchQuery.length > 0) {
    searchQuery = sanitiseStringForElasticSearch(searchQuery);
  }

  // This way it'll search on every searchable field instead of a specific one
  if (useGenericField) {
    return `*${searchQuery}*`;
  }

  // Pick every field from the config and build an `OR` query
  const { search } = filters;

  // if there are no filters, take the headingField
  if (!search || !search.param) {
    const { heading_field: headingField } = config;
    return `${headingField}:${searchQuery}*`;
  }

  const { param } = search;
  const queries = param.map((field) => `${field}:${searchQuery}*`);

  return queries.length > 1 ? queries.join(" OR ") : queries.pop();
};

// Component that renders the search bar. It's enabled when a filter is present
const SearchBar = () => {
  const dispatch = useDispatch();
  const selectedFilter = useSelector(getSelectedFilter);
  const searchQuery = useSelector(getSearchQuery);
  const globalParams = useSelector(getGlobalParams);
  const [searchConfig, setSearchConfig] = useState({});
  const hasResultsOrMatches = useSelector(hasResultsOrMatchesSelector);
  const [, { setUrl }] = useLocation();

  const entityName = useMemo(() => {
    if (selectedFilter) {
      return selectedFilter.configName;
    }
  }, [selectedFilter]);

  const entityType = useMemo(() => {
    if (selectedFilter && entityName !== selectedFilter.value) {
      return selectedFilter.value;
    }
  }, [selectedFilter, entityName]);

  const { data: config } = useSelector((state) =>
    getBaseConfig(state, entityName)
  );

  /**
   * useDebounce returns a function that can be used to cancel the debounce.
   * Every time that the [<dependency>] changes the debounce is triggered
   *
   * This specific debounce is needed to dispatch the fetchSearch action
   * in order to avoid searches every ms.
   * If `searchConfig` doesn't change in 500ms, trigger the search.
   *
   */
  const [, cancelPerformSearch] = useDebounce(
    () => {
      if (searchQuery.length >= MINIMUM_CHARACTERS) {
        dispatch(fetchSearch(searchConfig));
      }
    },
    500,
    [searchQuery, searchConfig, globalParams]
  );

  // The heading_field is the field we'll be using as search field in the multi-search.
  const headingField = useMemo(() => {
    if (_.isEmpty(selectedFilter)) {
      return;
    }

    const { configName } = selectedFilter;
    const { filters, heading_field: headingField } = config;

    if (configName === "talent") {
      return "first_name,last_name";
    }

    /**
     * The `full_name` edge case condition is awful, but in the codebase there
     * are specific cases for it and certain clients objects use it, without it
     * being a real field on the frontend. This should've never happened in the first place.
     * In the future, I believe that heading_field should be an array of actual fields on the B/E
     * That the F/E can manipulate and join.
     *
     */
    if (!headingField || headingField === "full_name") {
      if (filters && filters.search && filters.search.param) {
        return filters.search.param.join(",");
      }
      return "title";
    }

    return headingField;
  }, [config, selectedFilter]);

  const searchFilters = useMemo(
    () => getSearchFilters({ config, searchQuery, useGenericField: true }),
    [config, searchQuery]
  );

  /**
   * Pick the configs from the reducer, the configName (eg: sets, affiliates, competitions, etc)
   * and the value ( which in case of slug-type object, is the slug name) from the selectedFilter and
   * retrieve the appropriate config to build the searchConfig.
   *
   */
  useEffect(() => {
    // Cancel the debounce every time keystroke triggers this effect
    cancelPerformSearch();

    // Can't perform a search without these
    if (_.isEmpty(selectedFilter)) {
      return;
    }

    const { configName, value } = selectedFilter;

    setSearchConfig({
      field: headingField,
      resources: `/api/${configName}/`,
      searchQuery: searchFilters,
      slugType: getSlugTypeByEntity(configName, value),
    });
  }, [
    selectedFilter,
    headingField,
    searchFilters,
    dispatch,
    setSearchConfig,
    cancelPerformSearch,
  ]);

  /**
   *
   * When the value changes, we show/hide the results container based on its value.
   * This way, when it's empty, it won't show, but as soon as you start typing, it'll show the loader
   *
   *
   * @event document#input
   * @return {void}
   */
  const handleChange = ({ target }) => {
    const { value } = target;
    dispatch(setSearchQuery(value));
  };

  /**
   * When focusing the input and it's not empty and there are results
   * in the multiSearchSelector, it triggers a re-opening of the searchResults
   * which was closed by a clickAway.
   * It's using `matches` because it can happen that it's 0, in that case,
   * we want to open the results bar anyway.
   *
   * @return {void}
   */
  const handleFocus = () => {
    if (searchQuery && hasResultsOrMatches) {
      dispatch(setResultsVisibility(true));
    }
  };

  // Send the user to the listing page when pressing enter
  const handleKeypress = ({ key }) => {
    if (key === "Enter" && searchQuery) {
      const url = buildEntitySearchURL({ entityName, entityType });
      const filters = {
        dynamicProperties: [
          {
            name: "search",
            bucket: "search",
            payload: {
              value: searchQuery,
            },
          },
        ],
      };

      dispatch(resetSearchFilter(entityName, entityType));
      dispatch(
        setActiveFilters({
          entityName,
          entityType,
          ...filters,
        })
      );

      setUrl(url);
    }
  };

  /**
   * If no selectedFilter ( from the select-filter component ) is set
   * the search input can't be used, because it wouldn't know what
   * to search for
   *
   */
  const isDisabled = _.isEmpty(selectedFilter);

  return (
    <input
      className="search-input"
      type="text"
      disabled={isDisabled}
      placeholder="search"
      aria-label="search query"
      value={searchQuery}
      onKeyPress={handleKeypress}
      onChange={handleChange}
      onFocus={handleFocus}
    />
  );
};

export default SearchBar;
