import { useMemo } from "react";

import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";

/**
 * Returns a named angular dependency, provided by the Injector.
 * Use this inside React components to gain access to services, factories and whatnot.
 *
 * @example
 * const featureToggleVersionService = useAngularInjection('FeatureToggleVersionService');
 * const imagesFactory = useAngularInjection('ImagesFactory');
 *
 * @param {string} name
 */
const useAngularInjection = (name) => useMemo(() => Injector.get(name), [name]);

export default useAngularInjection;
