import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import DropdownList from "../dropdown-list/dropdown-list";

import styles from "./dropdown-article-objects.scss";

/**
 * DropdownArticleObjects
 * @param {Object} props
 */
export default function DropdownArticleObjects(props) {
  return (
    <div
      disabled={props.isDisabled}
      className={classnames(styles["dropdown-article-objects"], {
        [styles["dropdown-article-objects--disabled"]]: props.isDisabled,
        [styles["dropdown-article-objects--light"]]: props.styling,
      })}
    >
      <div className={styles["dropdown-article-objects__dropdown"]}>
        <span className={styles["dropdown-article-objects__text"]}>
          {props.copy}
        </span>
        <SkylarkIcon
          iconName={props.styling === "light" ? "replace" : "image"}
          extraClassName={styles["dropdown-article-objects__icon"]}
        />
        {!props.isDisabled && (
          <DropdownList
            extraClassName={styles["dropdown-article-objects__options"]}
            options={props.options}
            current={props.optionSelected}
            onSelect={props.onSelect}
            isCollapsed={props.isOpen}
            type="article-objects"
            displayField="display_name"
          />
        )}
      </div>
    </div>
  );
}

DropdownArticleObjects.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  optionSelected: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  copy: PropTypes.string,
  styling: PropTypes.string,
};

DropdownArticleObjects.defaultProps = {
  optionSelected: "",
  isDisabled: false,
  copy: "Insert Object",
  styling: "",
};
