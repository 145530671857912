/**
 * Redux Connector
 * @returns {Object} instance of redux
 */
function ReduxConnector($ngRedux) {
  /**
   * binding actions and state of store to the context
   * if the store is already used then it will throw a type error
   *
   * @param {Class|Object} context - context of the component
   * @param {function|null} mapState
   * @param {Object|function} [mapDispatch]
   * @returns {function} - unsubscribe function
   */
  function connect(context, mapState, mapDispatch) {
    if (!context || context.store !== undefined) {
      throw new TypeError(
        "the passed in context has already a `store` assigned"
      );
    }

    context.store = {};

    return $ngRedux.connect(
      mapState,
      mapDispatch
    )((stateSlice, dispatch) => {
      Object.assign(context, stateSlice);
      Object.assign(context.store, dispatch);
    });
  }

  return connect;
}

export default ReduxConnector;
