import React from "react";
import PropTypes from "prop-types";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import DropdownList from "../dropdown-list/dropdown-list";

import styles from "./dropdown.scss";

/**
 * Dropdown
 * @param {Object} props
 */
export default function Dropdown(props) {
  return (
    <div className={styles.dropdown}>
      <div className={styles.dropdown__dropdown}>
        <span className={styles.dropdown__text}>
          {props.optionSelected || props.placeholder}
        </span>
        <div className={styles["dropdown__icon-wrapper"]}>
          <SkylarkIcon iconName="down" extraClassName={styles.dropdown__icon} />
        </div>
        <DropdownList
          extraClassName={styles.dropdown__options}
          options={props.options}
          current={props.optionSelected || null}
          onSelect={props.onSelect}
          isCollapsed={props.isOpen}
        />
      </div>
    </div>
  );
}

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  optionSelected: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
};

Dropdown.defaultProps = {
  optionSelected: "",
  placeholder: "",
};
