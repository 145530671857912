import { MODAL_LISTING_NAMESPACE } from "store/listing/modal-listing.constants";
import { fetchBaseConfig } from "store/entities-config/entities-config.action";
import { getBaseConfig } from "store/entities-config/entities-config.selector";
import {
  getApiQuerySelector,
  getEntityListingData,
  getEntityListingLoading,
} from "store/listing/entity-listing/entity-listing.selector";
import {
  generateActiveFilter,
  generateDefaultActiveFilter,
} from "skylarklib/helpers/entity-listing-filter-helper";
import {
  fetchEntityListing,
  resetSearchFilter,
  setActiveFilters,
} from "store/listing/entity-listing/entity-listing.actions";
import template from "./modal-list.html";

/**
 * @memberof  Components.Modal
 * @classdesc Render modal list.
 * Expects an options object with the following format:
 * { channels: { add: string }, filters: string, existing: array, parent: object, categories: array}
 */
class ModalListCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $state
   * @param {Object} $cookies
   * @param {Object} _
   * @param {Object} ReduxConnector
   * @param {Object} SearchFactory
   * @param {Object} ModalService
   * @param {Object} BatchAddingService
   * @param {Object} ApiFilterFactory
   * @param {Object} MessageService
   * @param {Object} LoadingService
   * @param {Object} GlobalParamsService
   * @param {Array} DROPDOWN_OPTIONS
   */
  constructor(
    $scope,
    $state,
    $cookies,
    _,
    ReduxConnector,
    SearchFactory,
    ModalService,
    BatchAddingService,
    ApiFilterFactory,
    MessageService,
    LoadingService,
    GlobalParamsService,
    DROPDOWN_OPTIONS
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$cookies = $cookies;
    this._ = _;

    this.SearchFactory = SearchFactory;

    this.ReduxConnector = ReduxConnector;
    this.ModalService = ModalService;
    this.BatchAddingService = BatchAddingService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.MessageService = MessageService;
    this.LoadingService = LoadingService;
    this.GlobalParamsService = GlobalParamsService;

    this.data = [];
    this.isAllSelected = false;
    this.limit = undefined;
    this.namespace = MODAL_LISTING_NAMESPACE;
    this.amountSelected = 0;

    /**
     * If connectToStore is ran before init, it will have config null and entityName defined
     * which will end up in another request for the same config that's already defined in the reducer
     *
     */
    this.entityName = this.config.entity;
    if (this.config.name !== this.config.entity) {
      this.entityType = this.config.name;
    }
    this.parentData = this.options;

    this.connectToStore();
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this._setOptions();
    this._setConfig();

    this._getBaseConfig();
    this._setEntityWatchers();
    this._setupSubscriptions();
  }

  /**
   * Sets up pub/sub subscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.channelNames = {
      deleteIndividualEntity: "EntityListing.Delete",
      saveIndividualEntity: "EditableModal.Save",
    };
  }

  /**
   * _setConfig
   * @returns {void}
   */
  _setConfig() {
    this.limit = this.config.limit;
    this.action = `${this.config.name}Create`;
  }

  /**
   * _setOptions
   * @returns {void}
   */
  _setOptions() {
    this.cookieData = this.options.parent || {};
    this.relatedEntities = this.options.relatedEntities || [];
    this.categories = this.options.categories;
    this.filters = this.options.filters || "";

    this.existing = this.relatedEntities.map((entity) =>
      this._.isPlainObject(entity) ? entity.self : entity
    );
  }

  /**
   * _setSearchAndFilters
   * @returns {void}
   */
  _setSearchAndFilters() {
    const filters = {
      dynamicProperties: [
        ...generateDefaultActiveFilter(
          this.entityName,
          this.entityType,
          this.baseConfig
        ).dynamicProperties,
        ...generateActiveFilter(this.filters).dynamicProperties,
      ],
    };

    this.store.setActiveFilters({
      entityName: this.entityName,
      entityType: this.entityType,
      ...filters,
      namespace: this.namespace,
    });
  }

  /**
   * Update the chosen params from message service
   * @param {object} query
   * @returns {void}
   */
  _updateChosenParams(query) {
    if (query === undefined) {
      return;
    }
    this._getEntitiesInList();
  }

  /**
   * toggleAllSelected
   * @access public
   * @description calls toggleSelected for all items in the view
   * @returns {void}
   */
  toggleAllSelected() {
    this.changeSelectedStatus({ status: !this.isAllSelected });
  }

  /**
   * changeSelectedStatus
   * @param  {object} selectionFlag - object from flag list
   * @returns {void}
   */
  changeSelectedStatus(selectionFlag) {
    if (selectionFlag.status !== this.isAllSelected) {
      const maxAmountOfData =
        this.data.length < this.limit ? this.data.length : this.limit;
      this.isAllSelected = selectionFlag.status;
      this.data.forEach((item) =>
        this.changeItemSelectedStatus(item, this.isAllSelected)
      );
      this.amountSelected =
        this.amountSelected - (this.amountSelected % maxAmountOfData) || 0;
    }
  }

  /**
   * toggleSelected
   * @access public
   * @description toggle item isSelected (setting it to true if its unset)
   * and add or remove it to the selectedItems array
   * @param {object} item - selected item
   * @returns {void}
   */
  toggleSelected(item) {
    item.isSelected = !item.isSelected;
    this.BatchAddingService.manageSelected(item);
    this.isAllSelected = this.BatchAddingService.isWholePageSelected(this.data);
    this.incrementSelected(item);
  }

  /**
   * incrementSelected
   * @param {object} item - item that has been selected
   * @returns {void}
   */
  incrementSelected(item) {
    if (this.config.singleItem) {
      if (item.isSelected) {
        this.BatchAddingService.resetSelectedItems();
        this.BatchAddingService.manageSelected(item);
        this.data.forEach((entity) => {
          if (entity !== item) {
            this.changeItemSelectedStatus(entity, false);
          }
        });
        this.amountSelected = 1;
      } else {
        this.amountSelected = 0;
      }
    } else {
      if (item.isSelected) {
        this.amountSelected++;
      } else {
        this.amountSelected--;
      }
      this.isViewModeChangeEnabled = !this.amountSelected;
    }
  }

  /**
   * changeItemSelectedStatus
   * @param {object} item - singular entity from the list of data
   * @param {boolean} selectStatus - whether the item is selected or not
   * @returns {void}
   */
  changeItemSelectedStatus(item, selectStatus) {
    item.isSelected = selectStatus;
    this.incrementSelected(item);
    this.BatchAddingService.manageSelected(item);
  }

  /**
   * saves selected items to batch adding service
   */
  addSelected() {
    this.BatchAddingService.saveSelectedItems();
  }

  /**
   * _assignType
   * @todo  move this somewhere else
   * @access private
   * @description - if modal has been passed an array of types,
   * assign these types to the data so we can display them in the DOM.
   * Requires a type field name to be set in config.
   * @returns {void}
   */
  _assignType() {
    if (this.categories) {
      this.data.forEach((obj) => {
        const type = this.categories.find(
          (cat) => cat.self === obj[this.config.type]
        );
        if (type && type.name) {
          obj.categoryName = type.name;
        }
      });
    }
  }

  /**
   * sends selected items to relevant scope using MessageService, depending
   * on whether the modal is layering or not.
   */
  save() {
    this.BatchAddingService.saveSelectedItems();

    if (this.ModalService.isLayered()) {
      const previousInstance = this.instanceUid - 1;
      const previousLayerChannelName = `Modal.Instance.${[previousInstance]}`;
      this.MessageService.publish(
        previousLayerChannelName,
        this.BatchAddingService.getSelectedItems()
      );
    } else {
      this.MessageService.publish(
        this.options.channels.add,
        this.BatchAddingService.getSelectedItems()
      );
    }

    this._closeModal();
  }

  /**
   * cancel
   * @access public
   * @description  Called by modalBackdrop.js. Closes the modal.
   * @returns {void}
   */
  cancel() {
    this._closeModal();
  }

  /**
   * _closeModal
   * @access private
   * @description unregisterChannels here because this scope isn't neatly destroyed.
   * @returns {void}
   */
  _closeModal() {
    this.destroyModal();
  }

  /**
   * _destroyBindings
   * @access private
   * @description destroy channels
   * @returns {void}
   */
  _destroyBindings() {
    this.MessageService.unregisterChannel(this.channelNames.chosenParams);
    this.LoadingService.inlineLoader(false);
    this.currentFiltersQuery = {};
  }

  /**
   * createEntity
   * @access public
   * @deprecated
   * @description  Allows for entities to be created on the fly - a cookie with a reference to this page is saved
   * and we transition state to the relevant page.
   * @returns {void}
   */
  createEntity() {
    this.$cookies.putObject("currentLocation", this.cookieData);
    this.$state.go(`${this.action}`);
    this.destroyModal({ modal: "all" });
    this._closeModal();
  }

  /**
   * getEntityListingConfig
   * get the entity listing config by entity name
   *
   * @returns {void}
   */
  _getBaseConfig() {
    // It can happen that the config is already in the reducer, so we don't need to fetch it again
    if (this._.isEmpty(this.baseConfig)) {
      this.store.fetchBaseConfig(this.entityName);
    }
  }

  /**
   * redux watchers
   */
  _setEntityWatchers() {
    const unwatchConfig = this.$scope.$watch(
      () => this.baseConfig,
      (config) => {
        if (!this._.isEmpty(config)) {
          this._setSearchAndFilters();
          unwatchConfig();
        }
      }
    );

    this.unwatchEntityListingData = this.$scope.$watch(
      () => this.data,
      () => {
        this._handleAvailableEntities();
      }
    );

    this.unwatchEntityListingLoading = this.$scope.$watch(
      () => this.entityListingLoading,
      (entityListingLoading) => {
        this.LoadingService.inlineLoader(entityListingLoading);
      }
    );

    this.unwatchQueryChange = this.$scope.$watch(
      () => this.query,
      (query) => {
        this._updateChosenParams(query);
      }
    );
  }

  /**
   * Handles the listing of available entities after response from API
   * @returns {void}
   */
  _handleAvailableEntities() {
    this.data.forEach((obj) => {
      obj.isSelected = this.BatchAddingService.isItemInSelectedLists(obj);
    });
    this.isAllSelected = this.BatchAddingService.isWholePageSelected(this.data);
    this._assignType();
  }

  /**
   * fetches results for the new query, and cancel any pending requests
   */
  _getEntitiesInList() {
    if (this._.isEmpty(this.config)) {
      return;
    }

    this.cancelToken && this.cancelToken();

    const promise = new Promise((resolve) => {
      this.cancelToken = resolve;
    });

    this.store.fetchEntityListing({
      entityName: this.entityName,
      entityType: this.entityType,
      query: this.query,
      config: this.config,
      cancelToken: promise,
      namespace: this.namespace,
    });
  }

  /**
   * reset search filter
   */
  resetSearch() {
    this.store.resetSearchFilter({
      namespace: this.namespace,
    });
    this._setSearchAndFilters();
  }

  connectToStore() {
    const mapDispatchToThis = {
      fetchBaseConfig,
      fetchEntityListing,
      setActiveFilters,
      resetSearchFilter,
    };

    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  mapStateToThis(state) {
    return {
      baseConfig: getBaseConfig(state, this.entityName).data,
      data: getEntityListingData(
        state,
        this.entityName,
        this.entityType,
        this.namespace
      ).results,
      entityListingLoading: getEntityListingLoading(
        state,
        this.entityName,
        this.entityType,
        this.namespace
      ),
      query: getApiQuerySelector(
        state,
        this.entityName,
        this.entityType,
        this.namespace
      ),
    };
  }

  $onDestroy() {
    this.BatchAddingService.resetSelectedItems();
    this._destroyBindings();
    this.store.resetSearchFilter({
      namespace: this.namespace,
    });

    this.disconnect();
    this.unwatchEntityListingData && this.unwatchEntityListingData();
    this.unwatchEntityListingLoading && this.unwatchEntityListingLoading();
    this.unwatchQueryChange && this.unwatchQueryChange();
    this.cancelToken && this.cancelToken();
  }
}

/**
 * modalAsList
 * @description modalList definition
 * @returns {Object} Directive Definition Object
 */
function modalListDirective() {
  return {
    restrict: "E",
    controller: ModalListCtrl,
    scope: {},
    bindToController: {
      config: "=",
      options: "=",
      instanceUid: "@",
      destroyModal: "&",
    },
    controllerAs: "modal",
    template,
    link: (scope) => {
      scope.modal.init();
    },
  };
}

export default modalListDirective;
