import * as EntityAction from "store/entity/entity.action";
import * as EntityConfigAction from "store/entities-config/entities-config.action";
import * as EntitySelector from "store/entity/entity.selector";
import * as EntitiesConfigSelector from "store/entities-config/entities-config.selector";
import { buildHeading } from "store/selectors/build-heading";
import template from "./entity-detail.html";

/**
 *
 * EntityDetailPageCtrl - Generic detail page
 * controller for Brands, Seasons, Episodes,
 * Schedules and Assets.
 *
 */
class EntityDetailPageCtrl {
  /**
   * @constructor
   * @param {Object} $cookies - angular's cookie service
   * @param {Object} $scope
   * @param {Object} $state
   * @param {Object} $q
   * @param {Object} _
   * @param {Object} ReduxConnector
   * @param {Object} BreadcrumbService
   * @param {Object} EntityDimensionsService
   * @param {Object} GlobalParamsService
   * @param {Object} HistoryService
   * @param {Object} MessageService - Skylark.lib, used for pub/sub
   * @param {Object} ModalTriggerService
   * @param {Object} NotificationService - Skylark.lib, notifies user of changes & data
   * @param {Object} EntityFactory - Skylark.lib, gets the entities data for this page
   * @param {Object} EntityRelationshipFactory - Skylark.lib
   * @param {Object} SetContentsStore
   */
  constructor(
    $cookies,
    $scope,
    $state,
    $q,
    _,
    ReduxConnector,
    BreadcrumbService,
    EntityDimensionsService,
    GlobalParamsService,
    HistoryService,
    MessageService,
    ModalTriggerService,
    NotificationService,
    EntityFactory,
    EntityRelationshipFactory,
    SetContentsStore
  ) {
    this.$cookies = $cookies;
    this.$scope = $scope;
    this.$state = $state;
    this.$q = $q;
    this._ = _;
    this.ReduxConnector = ReduxConnector;
    this.BreadcrumbService = BreadcrumbService;
    this.EntityDimensionsService = EntityDimensionsService;
    this.GlobalParamsService = GlobalParamsService;
    this.HistoryService = HistoryService;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.NotificationService = NotificationService;
    this.EntityFactory = EntityFactory;
    this.EntityRelationshipFactory = EntityRelationshipFactory;
    this.SetContentsStore = SetContentsStore;

    this.entity = this.EntityFactory.getEntityName();
    this.entityId = this.EntityFactory.getEntityId();
    this.entityType = this.EntityFactory.getEntityType();
    this.newInstance = !this.entityId;
    this.showBreadcrumbs = false;

    this.connectToStore();
    this.init();
  }

  /**
   * calls initialising controller methods
   * Reset EntityRelationshipFactory to create a new instance on this page keeping state only
   * for this entity
   * @returns {void}
   */
  init() {
    this.EntityRelationshipFactory.reset();
    this.setWatchUpdates();
    this.setSubscriptions();
    this.loadData();

    if (!this.entityId) {
      this.store.createEntity();
    }
  }

  /**
   * Load the data for the page
   * @returns {void}
   */
  loadData() {
    this.requestData();
    this.requestConfig();
  }

  /**
   * triggerNotification
   * @return {Function}
   */
  triggerNotification() {
    return this.NotificationService.notifyRefresh();
  }

  /**
   * requesting data of the current entity,
   * when it is translating it will load the data from the default language,
   * because the accept-language on the header is set to `target-language, *`, ie (`de, *`)
   * but target language data doesn't exist, in this case backend returns the data from the default language,
   * for example `en`
   */
  requestData() {
    if (this.entityId) {
      this.store.fetchEntity(
        this.entity,
        this.entityId,
        this.GlobalParamsService.getDefaultParams()
      );
    }
  }

  /**
   * request config
   */
  requestConfig() {
    this.store.fetchBaseConfig(this.entity).then(({ response }) => {
      const hasTypes =
        Object.prototype.hasOwnProperty.call(response, "has_types") &&
        response.has_types;
      if (hasTypes) {
        this.EntityFactory.fetchType()
          .then((type) => {
            this.entityType = type;
          })
          .finally(() => {
            this.store.fetchBaseConfig(this.entity, this.entityType);
          });
      }
    });
  }

  /**
   * _getArticleTypeSlug
   * @param  {Object} types
   * @param  {Object} entityData
   * @return {String} article type slug
   */
  _getArticleTypeSlug(types, entityData) {
    return types.find((type) => type.self === entityData.article_type_url).slug;
  }

  /**
   * _handleExistingEntity
   * @return {void}
   */
  _handleExistingEntity() {
    if (this._.isEmpty(this.data)) {
      return;
    }

    this.EntityDimensionsService.setAvailableLanguages(this.data.all_languages);
    this.MessageService.publish("Entity.LanguagesAvailable");

    if (
      this.EntityDimensionsService.areDimensionsInvalid(
        this.data.schedule_statuses
      )
    ) {
      this._triggerDimensionNotification();
    }

    this.notifyChildren();

    this.checkRequiredRelationships();

    if (this.entity === "sets") {
      this.BreadcrumbService.contentBreadcrumbItems = [];
    }
    this.HistoryService.addToHistory(this.data.title);
    this.showBreadcrumbs = true;
  }

  /**
   * trigger invalid dimensions notifications
   * @returns {void}
   */
  _triggerDimensionNotification() {
    this.ModalTriggerService.triggerNotification({
      notificationType: "noDimensionsMatch",
      channels: {
        confirm: "LocalNav.Dimensions.addSchedule",
        cancel: "LocalNav.Dimensions.revert",
      },
    });
  }

  /**
   * Set up the messaging channels for this controller
   * @returns {void}
   */
  setSubscriptions() {
    this.channel = `${this.entity}.${this.entityId}`;

    this.SetContentsStore.registerChangeListener(() => {
      this.updateUi();
    });
  }

  /**
   * watching the scope
   */
  setWatchUpdates() {
    const unwatch = this.$scope.$watch(
      () => this.loading,
      (newData, oldData) => {
        if (
          oldData &&
          !newData &&
          !this._.isEmpty(this.config) &&
          !this._.isEmpty(this.data)
        ) {
          this._handleExistingEntity();
          unwatch();
        }
      },
      true
    );
  }

  /**
   * Update children controllers and directives of data change
   * @returns {void}
   */
  notifyChildren() {
    this.MessageService.publish(`${this.entity}.onData`, {
      config: this.config,
      data: this.data,
      channel: this.channel,
    });
  }

  /**
   * Updates pages ui
   * @access private
   * @returns {void}
   */
  updateUi() {
    if (this.relationshipData && this.relationshipData.tabIndicator) {
      this.MessageService.publish(
        "relationshipsRequiredIndicator.update",
        this.relationshipData.tabIndicator
      );
    }

    this.isEditing = this.SetContentsStore.getState().isEditing;
  }

  /**
   * The relationship field in te data model can be either an array of strings
   * (urls) or a string. In the first case (array of strings), this function
   * returns the length of the array. Otherwise, in th case that we have just a
   * string returns as length 0 or 1 depending if the string is empty or not.
   * @access private
   * @param {object} relationshipConfig
   * @returns {Integer}
   */
  getRelationshipUrlLength(relationshipConfig) {
    const relationshipFieldUrls =
      this.data[relationshipConfig.relationship_field];

    if (Array.isArray(relationshipFieldUrls)) {
      return relationshipFieldUrls.length;
    }
    return relationshipFieldUrls ? 1 : 0;
  }

  /**
   * Updates required relationship data for required config type
   * @access private
   * @param {object} relationshipConfig - the config for an individual relationship
   * @param {object} fullConfig - the config for the full relationship tab
   * @returns {void}
   */
  updateRelationshipData(relationshipConfig, fullConfig) {
    this.relationshipData = this.EntityRelationshipFactory.updateRequired(
      {
        relationshipName: relationshipConfig.name,
        isRequired: relationshipConfig.required,
        numberOfAvailableItems:
          this.getRelationshipUrlLength(relationshipConfig),
      },
      fullConfig
    );

    this.updateUi();
  }

  /**
   * Checks for required relationships.
   * @returns {void}
   */
  checkRequiredRelationships() {
    const relationshipTab = this._.find(this.config.tabs, {
      display_name: "Relationships",
    });

    if (relationshipTab) {
      this.EntityRelationshipFactory.getRelationshipConfig(this.entity).then(
        (fullConfig) => {
          if (fullConfig.required_relationships) {
            fullConfig.required_relationships.map((relationshipConfig) => {
              this.updateRelationshipData(relationshipConfig, fullConfig);
            });
          }
        }
      );
    }
  }

  /**
   * Checks for content tab
   * @returns {boolean}
   */
  isContentsPage() {
    return (
      this.$state.current.name === "setsByTypeDetail.contents" ||
      "setDetail.contents"
    );
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
      ...EntityConfigAction,
    };
    this.disconnect = this.ReduxConnector(
      this,
      this.mapStateToThis.bind(this),
      mapDispatchToThis
    );
  }

  /**
   * maps state of store to this
   * @param state
   * @returns {Object}
   */
  mapStateToThis(state) {
    const base = EntitiesConfigSelector.getBaseConfig(
      state,
      this.entity,
      this.entityType
    );
    const entity = EntitySelector.getEntity(state);

    return {
      loading: entity.loading || base.loading,
      data: entity.data,
      config: base.data,
      isCreating: entity.creating,
      isTranslating: entity.translating,
      headingField: buildHeading(state, this.entity, this.entityType),
      hasBreadcrumbs: EntitiesConfigSelector.hasBreadcrumbs(
        state,
        this.entity,
        this.entityType,
        this.BreadcrumbService.isContentBreadcrumbLocked || false
      ),
      editingState: state.editingState,
    };
  }

  $onDestroy() {
    this.disconnect();
  }
}

const entityDetailPageComponent = {
  controller: EntityDetailPageCtrl,
  controllerAs: "entity",
  template,
};

export default entityDetailPageComponent;
