/**
 * filters if end times are greater than time now
 * @param {object} scheduleTime - object with start and end time of schedule
 * @access private
 * @returns {boolean} is end time greater than now
 */
function endTimeFilter(scheduleTime) {
  return scheduleTime.ends <= Date.now();
}

/**
 * takes schedules loops them and returns parsed data object
 * @param {object} schedule schedule object containing unparsed start and end
 * date
 * @returns {{starts: number, ends: number}} schedule start and end time
 * object
 */
function scheduleMapper(schedule) {
  return {
    starts: Date.parse(schedule.starts),
    ends: Date.parse(schedule.ends),
  };
}

/**
 * returns an object array with starts and ends strings
 * @param {Array} scheduledItems - array of schedule items
 * @access private
 * @returns {Array} parsed starts and ends object
 */
function getStartEndTimesFromSchedule(scheduledItems) {
  return scheduledItems.map(scheduleMapper);
}

/**
 * filters all the ends properties by startEndTimeFilter function and
 * returns array of all the expired items
 * @param {Array} schedulesTimes array of schedule item objects
 * @access private
 * @returns {Array} all the expired schedules
 */
function findAllExpiredSchedules(schedulesTimes) {
  return schedulesTimes.filter((scheduleTime) => endTimeFilter(scheduleTime));
}

/**
 * gets the start end times of schedule, maps to array and find total of
 * expired schedules then returns total
 * @param {Array} schedules - array of schedule items
 * @access private
 * @returns {Number} total of schedules that expired
 */
function getTotalExpiredItems(schedules = []) {
  let totalExpiredItem = 0;

  if (schedules.length) {
    totalExpiredItem = findAllExpiredSchedules(
      getStartEndTimesFromSchedule(schedules)
    ).length;
  }

  return totalExpiredItem;
}

/**
 * are all the content schedules expired or is the array empty
 * @param {Array} contentSchedules - all the schedules for content item
 * @returns {boolean} isEveryContentScheduleExpired
 */
function isEveryContentScheduleExpired(contentSchedules) {
  const contentScheduleItemCount = contentSchedules.length;
  const hasContentSchedules = contentScheduleItemCount > 0;

  return (
    getTotalExpiredItems(contentSchedules) === contentScheduleItemCount &&
    hasContentSchedules
  );
}

/**
 * are all the item schedules expired
 * @param {Array} itemSchedules - all the schedules for scheduled item
 * @returns {boolean} isEveryItemScheduleExpired
 */
function isEveryItemScheduleExpired(itemSchedules) {
  return getTotalExpiredItems(itemSchedules) < itemSchedules.length;
}

/**
 * returns if all the content and scheduleditem schedules are expired or not
 * if content item has active schedule its always active, is content
 * schedules all expired its always expired and if both
 * @param {Array} itemSchedules - array of item schedules
 * @param {Array} contentSchedules - array of content schedules
 * @access private
 * @returns {boolean} is content expired
 */
function isExpired(itemSchedules, contentSchedules) {
  return (
    isEveryContentScheduleExpired(contentSchedules) ||
    (isEveryContentScheduleExpired(contentSchedules) &&
      isEveryItemScheduleExpired(itemSchedules))
  );
}

class ExpiredContentService {
  /**
   * checks if either content item .e.g. episode or schedule item's
   * schedules are expired according to pattern defined in helper
   * @param {Array} scheduledItemSchedules - array of schedule items
   * @param {Array} contentItemSchedules - array of schedules
   * @access public
   * @returns {boolean} isExpired - is the content or schedule item expired
   */
  isExpired(scheduledItemSchedules, contentItemSchedules) {
    return isExpired(scheduledItemSchedules, contentItemSchedules);
  }
}

export default ExpiredContentService;
