import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./image-update.html";

/**
 * Image Update Directive
 *
 * @description: Edit image directive for sets info
 * @todo we may have a case here for merging this with assetUpdate in the future
 */

/**
 * ImageUpdateCtrl
 * @class
 * @description  Controller for ImageUpdate Directive
 */
class ImageUpdateCtrl {
  /**
   * Directive constructor
   *
   * @constructor
   * @param {object} $location - Angular location service
   * @param {object} $cookies - Angular cookies service
   * @param {object} ImagesFactory - skylark ImagesFactory
   * @param {object} MessageService - skylark MessagesService
   * @param {object} NotificationService - skylark NotificationService
   * @param {object} _ lodash
   * @returns {void}
   */
  constructor(
    $location,
    $cookies,
    ImagesFactory,
    MessageService,
    NotificationService,
    _
  ) {
    this.$location = $location;
    this.$cookies = $cookies;
    this.ImagesFactory = ImagesFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this._ = _;

    this.entity = undefined;
    this.data = undefined;
    this.initialData = undefined;
    this.imageTypes = undefined;

    this.url = undefined;
    this.file = undefined;
    this.filePreview = undefined;

    this.imageUrl = undefined;
    this.uid = undefined;
    this.uploadProgress = undefined;
  }

  /**
   * Binds set data passed through to
   * directive constructor
   * @param  {object} data - image object
   * @param {object} entity - the entity object
   * @param {object} config - the config object
   * @param {object} positionItem - the positionItem object
   * @access private
   * @returns {void}
   * @todo  Do we need all these channels?
   */
  bindParams(data, entity, config, positionItem) {
    this.data = data;
    this.config = config.fields;
    this.entity = entity;
    this.positionItem = positionItem;
    this.uid = this.data.uid;
    this.title = this.data.title;
    this.loadData();
    this.url = this.data.self;
    this.imageUrl = this.data.upload_image_url;
    this.filePreview = this.data.url;

    // to reset form
    this.initialData = angular.extend({}, data);

    this.parentEntity = {
      parentId: this.data.uid,
      parentType: "imagery",
      parentTitle: this.data.title,
      childOf: this.$location.url(),
    };

    this.parentData = {};

    this.parentData.parent = this.parentEntity;

    this.hasChildEntity();
    this.setSubscriptions();

    this.buildModalOptions();
  }

  /**
   * Loads image type data - slightly different from the similar method in imageCreate
   * as we do not need to have a default field on this dropdown, so no concat
   * @memberOf  ImageUpdate
   * @access private
   * @returns {void}
   */
  loadData() {
    this.ImagesFactory.getImageTypes()
      .then((data) => {
        this.imageTypes = data.objects;
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * Sets subscriptions from MessageService
   * @memberOf imageUpdate
   * @listens: imageUpdate.onDragDrop
   * @listens: confirmImageDeleteById
   * @access private
   * @returns {void}
   */
  setSubscriptions() {
    this.imageUpdate = `itemblock:${this.data.uid}-haschanged`;
    this.MessageService.registerChannel(this.imageUpdate, 1);

    this.MessageService.on(this.imageUpdate, (channel, data) => {
      this.imageAdded = false;
      this.data = data;
      this.update();
    });

    this.confirmImageDeleteById = `deleteSelf.${this.data.uid}`;
    this.MessageService.registerChannel(this.confirmImageDeleteById, 1);

    this.MessageService.on(this.confirmImageDeleteById, () => {
      this.deleteSelf();
    });

    this.imageUploader = `ImageUploader.${this.data.uid}`;
    this.MessageService.registerChannel(this.imageUploader);

    this.MessageService.on(this.imageUploader, (ch, image) => {
      this.file = image.file;
    });
  }

  /**
   * Submit Form
   * @memberOf  imageCreate
   * @fires ImagesFactory.update
   * @fires MessageService.imageCreate
   * @access private
   * @returns {void}
   */
  update() {
    if (this.data.image_type_url !== this.initialData.image_type_url) {
      const type = this._.find(
        this.imageTypes,
        (current) => current.self === this.data.image_type_url
      );

      this.data.prevType = this.data.type;
      this.data.type = type.name;
      this.initialData = this.data;

      this.MessageService.publish("Images", this.data);
    } else {
      const image = this.data.imageFile || this.file;

      this.ImagesFactory.update(this.url, this.data, this.imageUrl, image)
        .then(
          (data) => {
            this.NotificationService.notifyInfo(
              NotificationMessage.updateSuccess(data.title)
            );
            this.data = data;
            this.initialData = this.data;
            this.filePreview = this.data.url;

            this.MessageService.publish("Images", this.data);
          },
          undefined,
          (progress) => (this.uploadProgress = progress)
        )
        .catch(() =>
          this.NotificationService.notifyError(
            NotificationMessage.updateError(this.data.title)
          )
        );
    }
  }

  /**
   * Clear Out the form and go back to initial data
   * @access public
   * @returns {void}
   */
  cancelEdit() {
    this.data = angular.extend({}, this.initialData);
  }

  /**
   * deleteImage
   * @memberOf  imageCreate
   * @access private
   * @description  Delete Image
   * @returns {void}
   */
  deleteSelf() {
    this.ImagesFactory.delete(this.url)
      .then(() => {
        this.NotificationService.notifyInfo(
          NotificationMessage.deleteSuccess(this.data.title)
        );
        this.MessageService.publish("Image.Delete", this.data);
      })
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.deleteError(this.data.title)
        )
      );
  }

  /**
   * Set if it has a child entity
   * @returns {void}
   */
  hasChildEntity() {
    let childEntity = this.$cookies.getObject("childEntity") || null;

    if (childEntity && childEntity.parent.parentId === this.data.uid) {
      this.$cookies.remove("childEntity");
      this.data.schedule_urls.push(childEntity.child.self);
      this.update();
    } else {
      childEntity = null;
    }
  }

  /**
   * build modal options with parentData and channels
   * @returns {void}
   */
  buildModalOptions() {
    this.modalOptions = {
      channels: { save: `itemblock:${this.data.uid}-haschanged` },
      parent: this.parentData.parent,
      entity: "images",
    };
  }
}

/**
 * imageUpdateDirective
 * @description  Directive Constructor for updating images
 * @returns {object} - Directive Definition Object
 */
function imageUpdateDirective() {
  return {
    restrict: "A",
    replace: true,
    scope: {
      img: "=",
      entity: "=",
      config: "=",
      positionItem: "&",
    },
    controller: ImageUpdateCtrl,
    controllerAs: "module",
    template,
    link: (scope) => {
      scope.module.bindParams(
        scope.img,
        scope.entity,
        scope.config,
        scope.positionItem
      );
    },
  };
}

export default imageUpdateDirective;
