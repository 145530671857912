import ReduceReducers from "reduce-reducers";
import { createApiReducer } from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import { GET_DEVICES_TYPES } from "./device-types.constants";

const get = createApiReducer({}, GET_DEVICES_TYPES);

const overrideDataSuccess = (state = {}, action) => {
  if (action.type === ApiConstant.getSuccessType(GET_DEVICES_TYPES)) {
    const { response } = action;
    const { objects } = response;

    return {
      ...state,
      data: objects,
    };
  }

  return state;
};

const DeviceTypesReducer = ReduceReducers({}, get, overrideDataSuccess);

export default DeviceTypesReducer;
