/**
 * Removes last modal instance
 * @return {Array} updated modal instances array
 */
function destroy(modalInstances) {
  modalInstances.pop();

  return modalInstances;
}

/**
 * Returns boolean value for if modal instance is visible.
 * @param  {Array}  modalInstances
 * @return {Boolean}
 */
function isOpen(modalInstances) {
  return !!modalInstances.length;
}

/**
 * isConfirming
 * @param  {Object}  actions
 * @param  {String}  location
 * @return {Boolean}
 */
function isConfirming(actions, location) {
  return actions.confirm.location === location;
}

/**
 * isAltAction
 * @param  {Object}  actions
 * @param  {String}  location
 * @return {Boolean}
 */
function isAltConfirming(actions, location) {
  return actions.alternative.location === location;
}

export { destroy, isOpen, isConfirming, isAltConfirming };
