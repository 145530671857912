import Relationship from "./+relationship/relationship.module";
import Tags from "./tags/tags.module";
import Talent from "./talent/talent.module";
import Translation from "./+translation/translation.module";
import Shared from "./shared/shared.module";
import Scheduling from "./+scheduling/scheduling.module";
import Version from "./version/version.module";
import Metadata from "./+metadata/metadata.module";
import Imagery from "./imagery/imagery.module";
import DocumentCreate from "./document/document-create/document-create.component";
import ClosedCaptionsContainer from "./+closed-captions/closed-captions-container/closed-captions-container.module";
import TextTracks from "./text-tracks/text-tracks.component";
import Genres from "./genres/genres.module";
import Credits from "./credits/credits.module";

const Modules = angular.module("Skylark.Modules", [
  Relationship.name,
  Tags.name,
  Talent.name,
  Translation.name,
  Shared.name,
  Scheduling.name,
  Version.name,
  Metadata.name,
  Imagery.name,
  DocumentCreate.name,
  ClosedCaptionsContainer.name,
  TextTracks.name,
  Genres.name,
  Credits.name,
]);

export default Modules;
