/**
 *  EntityFactory
 * @description: syntactic sugar on top of apiservice, for all simple entities
 */

/**
 * @param {Object} $q
 * @param {Object} $cookies
 * @param {Object} _
 * @param {Object} ApiRequestConfigFactory
 * @param {Object} ApiService
 * @param {Object} $state
 * @param {Object} $stateParams
 * @param {Array} CORE_ENTITIES
 * @param {Array} ENTITY_CONTENT_TYPES
 * @param {Object} SkylarkApiInfoService
 * @param {Object} NotificationService
 * @returns {Object}
 * @constructor
 */
function EntityFactory(
  $q,
  $cookies,
  _,
  ApiRequestConfigFactory,
  ApiService,
  $state,
  $stateParams,
  CORE_ENTITIES,
  ENTITY_CONTENT_TYPES,
  SkylarkApiInfoService,
  NotificationService
) {
  const factory = {};

  /**
   * getDefaults
   * @param  {string} entity
   * @returns {Object}
   * @todo: see if this is still required by the API
   */
  factory.getDefaults = (entity) => {
    const defaults = {
      seasons: {
        account_url: "/api/accounts/acco_d740bc41663e4d1587820b430c244c71/",
      },
    };

    return _.assign({}, defaults[entity]);
  };

  /**
   * buildUrl
   * @param   {String} entity
   * @param   {String} suffix
   * @returns {String}
   */
  factory.buildUrl = (entity, suffix = "") => `/api/${entity}/${suffix}`;

  /**
   *  getAll.
   *  Handles difference between endpoints here. Should always return a array objects filtered
   *  by the params passed
   *  @param {string} entity
   *  @param {object} filters
   *  @returns {Promise}
   */
  factory.getAll = (entity, filters) => {
    const url = factory.buildUrl(entity);
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    return ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig,
      })
    );
  };

  /**
   *  getAllWithParams
   *  This is for filtering with strings
   *  @param {string} url - url with params (e.g brands/?slug=test)
   *  @param {object} filters
   *  @returns {void}
   */
  factory.getAllWithParams = (url, filters) => {
    if (!_.isArray(filters)) {
      return ApiService.get(
        `/api/${url}`,
        ApiRequestConfigFactory.createRequestConfig({
          useGlobalParams: true,
          overrideGlobalLanguage: true,
          useWildCard: true,
          requestConfig: {
            params: filters,
          },
        })
      );
    }

    return factory.getWithMultipleParams(url, filters);
  };

  /**
   * getWithMultipleParams
   * @description Deal with use cases where we need to pass on multiple sets of filters to get
   *     results
   * @param {string} url - url with params (e.g brands/?slug=test)
   * @param {array} filterCollection - array of filter objects
   * @returns {Promise}
   * @todo look into possibly make all getAllWithParams always take an array by default? this
   *     relies on $q.all
   */
  factory.getWithMultipleParams = (url, filterCollection) => {
    const deferred = $q.defer();
    const requests = filterCollection.map((filters) =>
      ApiService.get(
        `/api/${url}`,
        ApiRequestConfigFactory.createRequestConfig({
          useGlobalParams: true,
          overrideGlobalLanguage: true,
          useWildCard: true,
          requestConfig: {
            params: filters,
          },
        })
      )
    );

    $q.all(requests)
      .then((data) => {
        const concatenatedData = data.reduce(
          (acc, result) => acc.concat(result.objects),
          []
        );
        deferred.resolve({ objects: concatenatedData });
      })
      .catch((err) => deferred.reject(err));

    return deferred.promise;
  };

  /**
   * get By url - returns most recent version
   * @param { string } url - url of the item
   * @param { object } filters - query params
   * @returns { Promise }
   */
  factory.getByUrl = (url, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    return ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig,
      })
    );
  };

  /**
   * get By id - returns most recent version
   * @param { string } entity name
   * @param { string } uid - episode uid
   * @param { object } filters - query params
   * @returns { Promise }
   */
  factory.get = (entity, uid, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    return ApiService.get(
      `/api/${entity}/${uid}/`,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig,
      })
    );
  };

  /**
   *  getVersions
   *  get list of episode versions for a given metadata type
   *  @param {string} entity name
   *  @param {string} uid
   *  @param {string} metadataType - language-versions or versions
   *  @returns {promise} - deferred promise
   */
  factory.getVersions = (entity, uid, metadataType) =>
    ApiService.get(
      `/api/${entity}/${uid}/${metadataType}/`,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
        useDefaultParams: true,
      })
    );

  /**
   *  getVersion
   *  get specific version of an entity
   *  @param {string} entity - name
   *  @param {string} uid
   *  @param {string} metadataType - language-versions or versions
   *  @param {number} version - number to be returned
   *  @returns {promise} - deferred promise
   */
  factory.getVersion = (entity, uid, metadataType, version) =>
    ApiService.get(
      `/api/${entity}/${uid}/${metadataType}/${version}/`,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
        useDefaultParams: true,
      })
    );

  /**
   *  getCount
   *  @param {string} entity name
   *  @param {object} filters
   *  @returns {Promise}
   */
  factory.getCount = (entity, filters = {}, isModal = false) => {
    if (!_.isArray(filters)) {
      const requestConfig = ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        // Use the current global language or default back to en when on is not set
        language: ApiRequestConfigFactory.getLanguage(isModal),
        requestConfig: {
          params: filters,
        },
      });
      requestConfig.params.limit = null;

      return ApiService.get(`/api/${entity}/count/`, requestConfig);
    }

    return factory.getMultipleCounts(entity, filters);
  };

  /**
   * getMultipleCounts
   * @description when we have a collection of filters
   * @param {string} entity - entity name
   * @param {array} filterCollection - array of filters
   * @param {string} strFilters - stringified filters from template
   * @returns {Promise} which resolves to an int value
   */
  factory.getMultipleCounts = (entity, filterCollection, strFilters = "") => {
    const suffix =
      entity === "customers" ? `${strFilters}` : `count/${strFilters}`;
    const url = factory.buildUrl(entity, suffix);

    const requests = filterCollection.map((filters) =>
      ApiService.get(
        url,
        ApiRequestConfigFactory.createRequestConfig({
          useGlobalParams: true,
          scheduleParams: filters,
          requestConfig: {
            params: filters,
          },
        })
      )
    );

    return $q.all(requests).then((data) => {
      const total = data
        .map((item) => item.count)
        .reduce((prev, curr) => prev + curr);
      return {
        count: total,
      };
    });
  };

  /**
   *  create Episode
   *  @param {object} entity - name
   *  @param {object} data - new episode data
   *  @param {Object} [filters]
   *  @returns { promise } promise object
   */
  factory.create = (entity, data, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    const url = `/api/${entity}/`;
    const config = ApiRequestConfigFactory.createRequestConfig({
      overrideGlobalLanguage: true,
      useDefaultParams: true,
      requestConfig,
    });

    if (factory.shouldInterceptCognitoRequest(url, "POST")) {
      return factory
        .interceptCognitoRequest(url, "POST", data)
        .then(({ updatedUrl, updatedData }) =>
          ApiService.post(updatedUrl, updatedData, config)
        );
    }

    return ApiService.post(url, data, config);
  };

  /**
   *  create Episode
   *  @param {object} entity - name
   *  @param {object} entity - name
   *  @param {object} data - new episode data
   *  @returns { promise } promise object
   */
  factory.createEntityItem = (entityType, entityName, data) => {
    let requestConfig;

    return ApiService.post(
      `/api/${entityType}/${entityName}/items/`,
      data,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useDefaultParams: true,
        requestConfig,
      })
    );
  };

  /**
   *  update
   *  creates new version or language version of given episode
   *  @param {string} entity - name
   *  @param {object} data - new episode data
   *  @returns {promise} promise object
   */
  factory.update = (entity, data) =>
    ApiService.put(
      `/api/${entity}/${data.uid}/`,
      data,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
      })
    );

  /**
   *  patch
   *  patch an entity
   *  @param {string} entity - name
   *  @param {object} data - fields to be edited
   *  @param { object } filters - query params
   *  @returns {promise} promise object
   *  @todo make this filter so it returns only the properties that were sent?
   */
  factory.patch = (entity, data, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    const collapseData = ApiService.collapseData(data);

    return ApiService.patch(
      `/api/${entity}/${data.uid}/`,
      collapseData,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        requestConfig,
      })
    );
  };

  /**
   *  Update an entity with the url
   *  creates new version or language version of given episode
   *  @param {string} url - the url of the entity
   *  @param {object} data - new episode data
   *  @returns {promise} promise object
   */
  factory.updateByUrl = (url, data) => {
    const config = ApiRequestConfigFactory.createRequestConfig({
      overrideGlobalLanguage: true,
    });

    if (factory.shouldInterceptCognitoRequest(url, "PUT")) {
      return factory
        .interceptCognitoRequest(url, "PUT", data)
        .then(({ updatedUrl, updatedData }) =>
          ApiService.put(updatedUrl, updatedData, config)
        );
    }

    return ApiService.put(url, data, config);
  };

  /**
   * Update an entity with the url
   * creates new version or language version of given episode
   * @param {string} url - the url of the entity
   * @param {object} data - new episode data
   * @param {object} [filters] - endpoint filters
   * @returns {promise} promise object
   */
  factory.patchByUrl = (url, data, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    const collapseData = ApiService.collapseData(data);

    return ApiService.patch(
      url,
      collapseData,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        requestConfig,
      })
    );
  };

  /**
   * upload File to url
   * @param {string} url
   * @param {FormData} file
   */
  factory.uploadFileByUrl = (url, file) =>
    ApiService.postMultipart(
      url,
      file,
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useDefaultParams: true,
        useGlobalParams: true,
      })
    );

  /**
   *  Delete an entity based on uid and type
   *  @param {string} entity - The entity type
   *  @param {string} uid - the uid of the entity
   *  @returns {promise} promise object
   */
  factory.delete = (entity, uid) => ApiService.delete(`/api/${entity}/${uid}/`);

  /**
   * Delete an entity by url
   * @param {string} url - the url of the entity
   * @returns {promise} promise object
   */
  factory.deleteByUrl = (url) => ApiService.delete(url);

  /**
   * getTagCategories - gets entity types
   * @param  {String} entity type
   * @returns {Promise} promise object
   */
  factory.getTypes = (entity) => {
    entity = entity.substring(0, entity.length - 1);

    // TODO: remove once api has solution to differences between front end and cms endpoints
    if (entity === "cms-article") {
      entity = "article";
    }

    return ApiService.get(
      `/api/${entity}-types/`,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );
  };

  /**
   * returns a matching type object
   * @param entityName
   * @param entityType
   * @return {Promise<Object>}
   */
  factory.getType = (entityName, entityType) => {
    if (!entityType) {
      return $q.resolve();
    }

    return factory.getTypes(entityName).then((types) => {
      if (!types) {
        return;
      }

      return _.find(types.objects, { slug: entityType });
    });
  };

  /**
   * get type from one article type
   * @param {string} entityId
   * @returns {Promise<Object>}
   */
  factory.getArticleType = (entityId) => {
    const articleFilterParams = {
      fields: "article_type_url, article_type_url__slug",
      fields_to_expand: "article_type_url",
    };

    return this.EntityFactory.get(
      "article",
      entityId,
      articleFilterParams
    ).then((entity) => entity.article_type_url.slug);
  };

  /**
   * fetch entity type in case the information was not part of the route
   * @returns {Promise}
   */
  factory.fetchType = () => {
    const entity = factory.getEntityName();
    const entityId = factory.getEntityId();
    const entityType = factory.getEntityType();
    const typeProperty = factory.getEntityTypeUrlKey(entity);
    const filter = {
      fields: typeProperty,
      fields_to_expand: typeProperty,
    };

    if (!typeProperty || entityType || !entityId) {
      return $q.resolve(entityType);
    }

    return factory.get(entity, entityId, filter).then((data) => {
      let type;

      if (data[typeProperty] && data[typeProperty].slug) {
        type = data[typeProperty].slug;
      }

      if (entity === "sets" && !type) {
        type = "default";
      }

      return type;
    });
  };

  /**
   * get entity items
   * @returns {Promise}
   */
  factory.getEntityItems = (entity, uid, filters) => {
    let requestConfig;

    if (filters) {
      requestConfig = {
        params: filters,
      };
    }

    const url = `/api/${entity}/${uid}/items/`;

    return ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
        requestConfig,
      })
    );
  };

  /**
   * addDataSource - save a created data source
   * @param   {object} entity - data
   * @param   {String} dataSource - data source ID
   * @param   {String} entityType - entity type
   * @returns {Promise} promise object
   */
  factory.addDataSource = (entity, dataSource, entityType) => {
    const deferred = $q.defer();

    entity.data_source_id = dataSource;
    factory
      .update(entityType, entity)
      .then((data) => {
        deferred.resolve(data);
      })
      .catch((err, status) => deferred.reject(err, status));

    return deferred.promise;
  };

  /**
   * Get the entity name from state params
   * @returns {String} - entity [sets, brands, seasons, etc.]
   */
  factory.getEntityName = () =>
    $stateParams.entity ? $stateParams.entity : $state.current.title;

  /**
   * get entity type from url
   * @param url
   * @returns {string}
   */
  factory.getEntityNameByUrl = (url) => {
    if (typeof url === "string") {
      const splitUrl = url.split("/");

      if (splitUrl.length >= 3) {
        return splitUrl[2];
      }
    }
  };

  /**
   * Get entity type from state params
   * @returns {String} - entity type [home, program, playlist, etc.]
   */
  factory.getEntityType = () => $stateParams.entityType;

  /**
   * Get entity id from state params
   * @returns {String}
   */
  factory.getEntityId = () => $stateParams.id;

  /**
   * Checks if an entity is custom from the current state params
   * @returns {Boolean}
   */
  factory.isEntityCustom = () => !!$stateParams.entity;

  /**
   * Checks if the entity is in the list of core entities
   * @param   {String} entityType
   * @returns {Boolean}
   */
  factory.isCoreEntity = (entityType) =>
    !!CORE_ENTITIES.find((entity) => entity === entityType);

  /**
   * returns the entity slug property name, as this property is entity specific,
   * for example the
   * sets -> set_type_slug, and
   * assets -> asset_type_slug
   *
   * @returns {string}
   */
  factory.getEntityTypeSlugKey = (entityName) => {
    const entityContent = ENTITY_CONTENT_TYPES[entityName];

    if (entityContent) {
      return `${entityContent}_type_slug`;
    }
  };

  factory.getEntityTypeUrlKey = (entityName) => {
    const entityContent = ENTITY_CONTENT_TYPES[entityName];

    if (entityContent) {
      return `${entityContent}_type_url`;
    }
  };

  /**
   * getArrayData
   *
   * @param {array} array of urls owned by a parent entity
   * @returns {promise} - deferred promise
   *
   */
  factory.getArrayData = (urls) => {
    const requests = urls.map((url) => ApiService.get(url));

    return $q.all(requests);
  };

  /**
   * takes an api url /api/episodes/epis_fdf3434... and returns the uid of that url
   * @param {String} url
   * @return {String}
   */
  factory.getUidFromUrl = (url) => {
    const match = url.match(/(\w{1,4}_[\w|\d]{32})/);

    if (match && match[0]) {
      return match[0];
    }

    return "";
  };

  /**
   * Determines whether the request should be intercepted and modified depending on whether cognito auth is enabled
   * @param {*} url
   * @param {*} method HTTP method
   * @returns boolean
   */
  factory.shouldInterceptCognitoRequest = (url, method) => {
    // Which requests should be intercepted when using Cognito Authentication
    const interceptRules = [
      { url: "/api/cms-users/", methods: ["POST", "GET", "PUT"] },
      { url: "/api/users/", methods: ["PUT"] },
    ];
    // If the current URL and method do not match an intercept rule, return
    return interceptRules.some(
      ({ url: _url, methods }) =>
        url.startsWith(_url) && methods.includes(method)
    );
  };

  /**
   *
   * @param {String} url
   * @param {String} method HTTP Method
   * @param {Object} data Object that will be sent to the API
   * @returns Promise<{ updatedUrl, updatedData }>
   */
  factory.interceptCognitoRequest = async (url, method, data) => {
    // Default response should just return the given url and data
    const defaultResponse = { updatedUrl: url, updatedData: data };

    const shouldIntercept = factory.shouldInterceptCognitoRequest(url, method);
    if (!shouldIntercept) {
      return defaultResponse;
    }

    const cognitoEnabled = await SkylarkApiInfoService.hasCognitoAuth();
    if (!cognitoEnabled) {
      return defaultResponse;
    }

    // Remove any fields we don't want to send to the Cognito API
    const {
      password1,
      password2,
      role_urls,
      amountSelected,
      entityType,
      isModified,
      account_id,
      affiliate_urls,
      cognito_username,
      contact_me,
      country_of_residence_url,
      customer_type_urls,
      data_source_id,
      date_joined,
      device_type_urls,
      expires,
      is_active,
      is_staff,
      is_superuser,
      language_urls,
      last_alert_seen,
      last_data_ingest,
      last_login,
      locale_urls,
      login_attempt_date,
      login_attempt_provider_url,
      operating_system_urls,
      permissions,
      preferred_language,
      receive_notifications,
      region_urls,
      self,
      source,
      uid,
      user_group_urls,
      viewing_context_urls,
      // sanitizedData are all the fields that are left
      ...sanitizedData
    } = data;

    /*
     * Before cognito the server performed the password validation, now we need to do this on the CMS side
     * password1 & password2 fields are found https://developers.skylarkplatform.com/api/skylark_api.html#users-cms-users-post
     */
    const passwordsMatch = data.password1 === data.password2;

    switch (method) {
      case "GET":
        return {
          updatedUrl: url.replace("cms-users", "users"),
          updatedData: sanitizedData,
        };
      case "POST":
        if (!passwordsMatch) {
          /*
           * For some reason the toaster doesn't show if we throw an error when the passwords don't match
           * So show a error toaster to inform the user that they don't match
           * Then continue the request with the password as an empty string
           */
          NotificationService.notifyError("Passwords don't match");
        }
        return {
          updatedUrl: "/api/users/",
          updatedData: {
            ...sanitizedData,
            password: passwordsMatch ? password1 : "",
          },
        };
      case "PUT":
        return {
          updatedUrl: url.startsWith("/api/cms-users/")
            ? `/api/users/${data.cognito_username}/`
            : url,
          updatedData: sanitizedData,
        };
      default:
        break;
    }

    return {
      updatedUrl: url,
      updatedData: sanitizedData,
    };
  };

  return factory;
}

export default EntityFactory;
