import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import styles from "./default-column.scss";

/**
 * DefaultColumn - Main column heading / title / name
 * @param {Object} props
 */
export default function DefaultColumn(props) {
  /**
   * resolve
   */
  function resolve(path, obj) {
    return path
      .split(".")
      .reduce((prev, curr) => (prev ? prev[curr] : null), obj || window.self);
  }

  const text = resolve(props.columnName, props.entity) || "---";

  return (
    <h6
      title={text}
      className={classnames(
        styles["default-column"],
        styles[`default-column--${props.type}`],
        { [styles["default-column--is-faded"]]: props.isFaded }
      )}
    >
      {text}
    </h6>
  );
}

DefaultColumn.propTypes = {
  entity: PropTypes.shape().isRequired,
  columnName: PropTypes.string.isRequired,
  isFaded: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

DefaultColumn.defaultProps = {
  type: "",
};
