const successPostfix = "_SUCCESS";
const failurePostfix = "_FAILURE";
const requestPostfix = "_REQUEST";

const ApiConstant = {
  getSuccessType: (type) => `${type}${successPostfix}`,
  getFailureType: (type) => `${type}${failurePostfix}`,
  getRequestType: (type) => `${type}${requestPostfix}`,
  isSuccessType: (type) => type.match(successPostfix),
  isFailureType: (type) => type.match(failurePostfix),
  isRequestType: (type) => type.match(requestPostfix),
};

export default ApiConstant;
