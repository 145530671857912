/**
 * Factory to populate field options based on endpoint proivided in config. i.e. multiplecheckbox
 * @param {Object} ApiService
 * @param {Object} ApiRequestConfigFactory
 * @returns {{}}
 * @constructor
 */
function OptionsFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  factory.getFieldOptions = (endpoint) =>
    ApiService.get(endpoint, ApiRequestConfigFactory.createRequestConfig());

  return factory;
}

export default OptionsFactory;
