import template from "./closed-captions-container.html";

/**
 * Creates a block of auto-save input fields (declared inside the "fields" array of the
 * config) that are comply to the validation rules that are declared in the config.
 * @class ClosedCaptionsController
 */
class ClosedCaptionsContainerController {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} EntityFactory
   */
  constructor(_, EntityFactory) {
    this._ = _;
    this.EntityFactory = EntityFactory;
    this.closedCaptions = {};

    this.expandCcUrls();
  }

  /**
   * Expands the cc urls
   *
   * @returns {void}
   *
   */
  expandCcUrls() {
    if (!this.data.cc_urls) {
      return;
    }

    this.EntityFactory.getArrayData(this.data.cc_urls).then((response) => {
      this.closedCaptions = this.config.fields.reduce(
        (obj, closedCaption) => ({
          ...obj,
          [closedCaption.id]:
            response.find((item) => closedCaption.id === item.name) || {},
        }),
        {}
      );
    });
  }

  /**
   * Returns the right object for the
   * cc_url component from the expanded list
   *
   * @param {string} languageCode
   *
   * @returns {object}
   *
   */
  getCcUrlData(languageCode) {
    return this._.find(this.closedCaptions, { language_code: languageCode });
  }
}

/**
 * Directive config
 * @returns {object} - metadata directive config
 */
function closedCaptionsDirective() {
  return {
    scope: {},
    bindToController: {
      config: "=",
      data: "=",
      isCreating: "=",
    },
    restrict: "A",
    controller: ClosedCaptionsContainerController,
    controllerAs: "module",
    template,
  };
}

export default closedCaptionsDirective;
