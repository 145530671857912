import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useClickAway, useUnmount } from "react-use";
import _ from "lodash";

import Loader from "components/_react/loader/loading.component";

import {
  getResultsVisibility,
  getSearchError,
  getSearchQuery,
  getSelectedFilter,
  hasResultsOrMatchesSelector,
  suggestedResults,
} from "store/search/search.selector";
import { setResultsVisibility } from "store/search/search.action";
import { getBaseConfig } from "store/entities-config/entities-config.selector";
import { RootState } from "store/store.types";
import { MINIMUM_CHARACTERS } from "../search.constant";
import SearchResult from "../search-result/search-result.component";
import { ConfigResult } from "./configResult";

export type HTMLElementWithTarget = Event & {
  target: HTMLElement;
};

/**
 * Search Results component
 * Takes care of rendering the search results based on the suggestedResults content
 *
 */
const SearchResults = () => {
  const [configResult, setConfigResult] = useState<ConfigResult>({
    url: {
      name: undefined,
      type: undefined,
    },
  });

  const ref = useRef(null);
  const dispatch = useDispatch();

  const selectedFilter = useSelector(getSelectedFilter);
  const searchQuery = useSelector(getSearchQuery);
  const { results, matches } = useSelector(suggestedResults);
  const isVisible = useSelector(getResultsVisibility);
  const hasResultsOrMatches = useSelector(hasResultsOrMatchesSelector);
  const error = useSelector(getSearchError);

  /**
   * generic configuration of selected filter
   */
  const { data: config } = useSelector((state: RootState) => {
    const entityName = selectedFilter && selectedFilter.configName;
    return getBaseConfig(state, entityName);
  });

  /**
   * Sets the url for the config for the search result to provide
   * the heading_field ( which will be used as title ) and the url
   * which will be used as href in the anchor
   *
   */
  useEffect(() => {
    if (
      _.isEmpty(selectedFilter) ||
      _.isEmpty(config) ||
      !hasResultsOrMatches
    ) {
      return;
    }

    const { configName, value } = selectedFilter;

    const type = configName !== value ? value : "";

    setConfigResult({
      url: {
        name: configName,
        type,
      },
    });
  }, [config, selectedFilter, dispatch, hasResultsOrMatches]);

  // Make sure that when un-mounting the component we reset all the flags
  useUnmount(() => {
    dispatch(setResultsVisibility(false));
  });

  /**
   *
   * Close the dialog when clicking outside the results
   * and the search-input
   *
   */
  useClickAway<HTMLElementWithTarget>(ref, (event) => {
    const { target } = event;

    /**
     * Ugly check to avoid closing the results when the input gets the focus.
     * Couldn't move this logic up because the HTML structure doesn't allow
     * a wrapper for the input and the results. Plus, even if ugly, this leads
     * to much way less logic than handling this on a higher level.
     * An acceptable trade-off.
     */
    if (!target.classList.contains("search-input")) {
      dispatch(setResultsVisibility(false));
    }
  });

  const matchesString = useMemo(
    () => (matches === 1 ? "1 match" : `${matches || 0} matches`),
    [matches]
  );

  const showMinimumCharacter = useMemo(
    () => !searchQuery || searchQuery.length < MINIMUM_CHARACTERS,
    [searchQuery]
  );

  if (!isVisible || error) {
    return null;
  }

  return (
    <div className="search-results" data-test="search-results" ref={ref}>
      {showMinimumCharacter && (
        <div className="search-results__message">
          <p>Type at least {MINIMUM_CHARACTERS} characters</p>
        </div>
      )}
      {!showMinimumCharacter && !hasResultsOrMatches && <Loader />}
      {!showMinimumCharacter && hasResultsOrMatches && (
        <Fragment>
          <div className="search-results__count">
            <p>{matchesString}</p>
          </div>
          {matches > 0 &&
            results.map((result) => (
              <SearchResult
                configResult={configResult}
                key={result.uid}
                result={result}
              />
            ))}
        </Fragment>
      )}
    </div>
  );
};

export default SearchResults;
