export const ComponentStyle = {
  sizes: {
    sm: {
      fontSize: "13px",
      fontWeight: "500",
    },
  },
  variants: {
    error: {
      fontSize: "13px",
      fontWeight: "500",
      color: "skylarkRed",
    },
  },
};

export default ComponentStyle;
