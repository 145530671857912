import template from "./radial-progress-indicator.html";

const defaultData = {
  channelName: "progress.update",
  text: "0",
  completionPercentage: 0,
  required: false,
};

class radialProgressIndicatorCtrl {
  /**
   * @constructor
   * @param {object} MessageService
   */
  constructor(MessageService) {
    this.MessageService = MessageService;
    this.init();
    this.setupSubscriptions();
  }

  /**
   * Initiates the directive with default data values
   * @returns {void}
   */
  init() {
    this.radius = 34;
    this.diamiter = Math.PI * (this.radius * 2);

    this.data = this.data || {};
    this.data = { ...defaultData, ...this.data };
    this.text = this.text || this.data.text;

    this.updateProgress();
  }

  /**
   * Set up pub/sub subscriptions
   * @returns {void}
   */
  setupSubscriptions() {
    this.MessageService.registerChannel(this.data.channelName);
    this.MessageService.on(this.data.channelName, (ch, data) => {
      this.data = data;
      this.text = this.data.text || this.text;
      this.updateProgress();
    });
  }

  /**
   * Update progress
   * @returns {void}
   */
  updateProgress() {
    this.progress =
      this.diamiter - this.diamiter * (this.data.completionPercentage / 100);
  }
}

/**
 * Tooltip directive
 * @access private
 * @returns {object}
 */
function radialProgressIndicatorDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      data: "<?",
      text: "<",
    },
    template,
    controller: radialProgressIndicatorCtrl,
    controllerAs: "component",
  };
}

export default radialProgressIndicatorDirective;
