/**
 * @file Modal Trigger Directive, for use in the DOM.
 */
/**
 * @memberOf  Components.Modal
 * @classdesc Triggers initialisation of modals from the DOM.
 * Use as an attribute on DOM element to be clicked.
 * Modals are initialised with a number of required properties based
 * on their type. A 'type' scope property is always required.
 */
class ModalTriggerCtrl {
  /**
   * @constructor
   * @param   {Object} $element
   * @param   {Object} ModalService
   * @param   {Object} MessageService
   */
  constructor($element, ModalService, MessageService) {
    this.ModalService = ModalService;
    this.MessageService = MessageService;
    this.$element = $element;

    this.openModal = this.openModal.bind(this);
  }

  $onInit() {
    this.$element.on("click", this.openModal);
  }

  /**
   * init
   * @returns {void}
   */
  openModal($event) {
    $event.stopPropagation();

    if (this.isDisabled) {
      return;
    }

    this.ModalService.initNewModal(
      this.data,
      this.config,
      this.type,
      this.entityType,
      this.options
    ).then((modalUid) => {
      this.modalUid = modalUid;
      this._notifyContainer();
    });
  }

  /**
   * notifyContainer
   * @returns {void}
   */
  _notifyContainer() {
    this.MessageService.publish("skylark.initModal", this.modalUid);
  }

  /**
   * remove click event
   */
  $onDestroy() {
    this.$element.off("click", this.openModal);
  }
}

/**
 * modalTrigger
 * @returns {object} directive definition object
 */
function modalTrigger() {
  return {
    restrict: "A",
    controller: ModalTriggerCtrl,
    controllerAs: "component",
    scope: {},
    bindToController: {
      data: "=",
      config: "=",
      options: "=",
      type: "@",
      entityType: "=",
      isDisabled: "=",
    },
  };
}

export default modalTrigger;
