import { createSelector } from "reselect";
import _ from "lodash";

/**
 * combine different upload process in to one object
 *
 * @param {Object} - state
 * @param {String} - entityId
 * @return {Array} matching uploads
 */
const getUploads = createSelector(
  [(state) => state.ovpAssets, (state) => state.textTracks],
  (ovps, textTracks) => {
    const filteredTextTracks = _.filter(
      textTracks,
      (track) => !track.hideFromModal
    );

    return { ...ovps, ...filteredTextTracks };
  }
);

export { getUploads };
