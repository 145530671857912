import angular from "angular";
import RelationshipItemBlock from "./relationship-item/relationship-item.directive";
import relationshipDirective from "./relationship-module/relationship-module.directive";
import RelationshipFactory from "./relationship-factory/relationship.factory";

const Relationship = angular
  .module("Skylark.Modules.Relationship", [])
  .factory("RelationshipFactory", RelationshipFactory)
  .directive("relationshipItemBlock", RelationshipItemBlock)
  .directive("relationship", relationshipDirective);

export default Relationship;
