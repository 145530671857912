import angular from "angular";
import AsyncSetActionsService from "./async-set-actions/aysnc-set-actions.service";
import AddRemoveItemsActions from "./add-remove-items-actions/add-remove-items-actions.factory";
import AsyncSetActions from "./async-set-actions/async-set-actions.factory";
import DisplayTitleActions from "./display-title-actions/display-title-actions.service";
import EntityCreationActions from "./entity-creation-actions/entity-creation-actions.factory";
import ItemDecorationActions from "./item-decoration-actions/item-decoration-actions.factory";
import PositioningActions from "./positioning-actions/positioning-actions.factory";
import PreviewActions from "./preview-actions/preview-actions.service";
import SortingActions from "./sorting-actions/sorting-actions.factory";
import UpdateItemActions from "./update-item-actions/update-item-actions.factory";
import SetContentsActionCreators from "./set-contents-actions.service";
import SET_CONTENTS_ACTIONS from "./set-contents-actions.constants";

const SetContentsActions = angular
  .module("Skylark.Pages.Sets.Shared.SetContentsActions", [])
  .constant("SET_CONTENTS_ACTIONS", SET_CONTENTS_ACTIONS)
  .factory("AddRemoveItemsActions", AddRemoveItemsActions)
  .factory("AsyncSetActions", AsyncSetActions)
  .service("AsyncSetActionsService", AsyncSetActionsService)
  .factory("DisplayTitleActions", DisplayTitleActions)
  .factory("EntityCreationActions", EntityCreationActions)
  .factory("ItemDecorationActions", ItemDecorationActions)
  .factory("PositioningActions", PositioningActions)
  .factory("PreviewActions", PreviewActions)
  .factory("SortingActions", SortingActions)
  .factory("UpdateItemActions", UpdateItemActions)
  .service("SetContentsActionCreators", SetContentsActionCreators);

export default SetContentsActions;
