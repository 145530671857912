import {
  CharacterMetadata,
  ContentBlock,
  EditorState,
  RichUtils,
  genKey,
  SelectionState,
  Modifier,
} from "draft-js";
import { List, Map } from "immutable";

import { MEDIA_BLOCK_TYPES } from "components/_react/editor/text-editor/text-editor.constants";

/**
 * Get any block based on key passed
 * @param  {Immutable.Record} contentState
 * @param  {String} key
 * @return {Immutable.Record} contentBlock
 */
function getBlock(contentState, key) {
  return contentState.getBlockForKey(key);
}

/**
 * getCurrentBlock
 * @param   {Immutable.Record} state
 * @returns {Immutable.Map} the current block
 */
function getCurrentBlock(state) {
  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const key = selection.getEndKey();

  return getBlock(contentState, key);
}

/**
 * getCurrentBlocks
 * @param   {Immutable.Record} state
 * @returns {Immutable.Map}
 */
function getCurrentBlocks(state) {
  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const startKey = selection.getStartKey();
  const endKey = selection.getEndKey();
  const blockMap = contentState.getBlockMap();

  return blockMap
    .toSeq()
    .skipUntil((block) => block.getKey() === startKey)
    .takeUntil((block) => block.getKey() === endKey)
    .concat({ [endKey]: getBlock(contentState, endKey) })
    .toOrderedMap();
}

/**
 * isSelectionAtEnd
 * @param  {Immutable.Record} state - <EditorState>
 * @return {Boolean}
 */
function isSelectionAtEnd(state) {
  return (
    getCurrentBlock(state).getLength() === state.getSelection().getEndOffset()
  );
}

/**
 * whether there is an active selection
 * @param {Immutable.Record} state
 * @returns {Boolean} [description]
 */
function isSelection(state) {
  const selection = state.getSelection();

  return !selection.isCollapsed();
}

/**
 * whether a selection spans multiple blocks
 * @param {Immutable.Record} state
 * @returns {Boolean}
 */
function isMultipleBlocks(state) {
  const selection = state.getSelection();

  return selection.getStartKey() !== selection.getEndKey();
}

/**
 * whether the cursor is at the start
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isCursorAtStart(state) {
  const selection = state.getSelection();

  return selection.getFocusOffset() === 0 && selection.getAnchorOffset() === 0;
}

/**
 * whether the current block is a placeholder
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isPlaceholder(state) {
  return getCurrentBlock(state).getType() === "placeholder";
}

/**
 * whether the current block is an image
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isMediaBlock(state) {
  return MEDIA_BLOCK_TYPES.includes(RichUtils.getCurrentBlockType(state));
}

/**
 * isMediaPlaceholder
 * @param {Immutable.Record} state
 * @returns {Boolean}
 */
function isMediaPlaceholder(state) {
  return RichUtils.getCurrentBlockType(state) === "media-placeholder";
}

/**
 * hasDisabled
 * @param  {Immutable.Record} state
 * @return {Boolean}
 */
function hasDisabled(state) {
  if (
    !state.getSelection().isCollapsed() &&
    isMultipleBlocks(state) &&
    !getCurrentBlock(state)
  ) {
    return getCurrentBlocks(state).some(
      (block) =>
        block.getIn(["data", "config", "has_block_styles"]) === false ||
        isMediaBlock(state)
    );
  }

  return (
    getCurrentBlock(state) &&
    (getCurrentBlock(state).getIn(["data", "config", "has_block_styles"]) ===
      false ||
      isMediaBlock(state))
  );
}

/**
 * whether the current block is restricted to only one of its type
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isRestricted(state) {
  return getCurrentBlock(state).getData().getIn(["config", "is_restricted"]);
}

/**
 * isBlockEmpty
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isBlockEmpty(state) {
  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const endKey = selection.getEndKey();
  const currentBlock = contentState.getBlockForKey(endKey);

  const isFullSelectionRange =
    selection.getStartOffset() === 0 && isSelectionAtEnd(state);

  return isFullSelectionRange || currentBlock.getText().length === 1;
}

/**
 * _hasAnchorChanged
 * @param  {Immutable.Record}  selection
 * @param  {Immutable.Record}  newSelection
 * @return {Boolean}
 */
function _hasAnchorChanged(selection, newSelection) {
  return selection.getAnchorOffset() !== newSelection.getAnchorOffset();
}

/**
 * _hasFocusChanged
 * @param  {Immutable.Record}  selection
 * @param  {Immutable.Record}  newSelection
 * @return {Boolean}
 */
function _hasFocusChanged(selection, newSelection) {
  return selection.getFocusOffset() !== newSelection.getFocusOffset();
}

/**
 * hasSelectionChanged
 * @param  {Immutable.Record}  oldState
 * @param  {Immutable.Record}  newState
 * @return {Boolean}
 */
function hasSelectionChanged(oldState, newState) {
  const oldSelection = oldState.getSelection();
  const nextSelection = newState.getSelection();

  return (
    _hasAnchorChanged(oldSelection, nextSelection) ||
    _hasFocusChanged(oldSelection, nextSelection)
  );
}

/**
 * get remaining block types
 * @todo  move to text-placeholders
 * @param {Immutable.Record} contentState
 * @param {String} startKey
 * @param {String} endKey
 * @returns {Seq}
 */
function _getRemainingBlockTypes(contentState, startKey, endKey) {
  const blockMap = contentState.getBlockMap();

  return blockMap
    .takeUntil((block) => block.getKey() === startKey)
    .concat(blockMap.skipUntil((block) => block.getKey() === endKey).rest());
}

/**
 * whether a block is the last of its type
 * @param   {Object} newState
 * @returns {Boolean}
 */
function isLastOfType(state) {
  const contentState = state.getCurrentContent();
  const selection = state.getSelection();
  const startKey = selection.getStartKey();
  const key = selection.getEndKey();
  const remainingBlocks = _getRemainingBlockTypes(contentState, startKey, key);

  return !contentState
    .getBlockMap()
    .filter((block) => !remainingBlocks.get(block.getKey()))
    .every((block) =>
      remainingBlocks
        .toList()
        .map((remainingBlock) => remainingBlock.getData().get("contentType"))
        .includes(block.getData().getIn(["config", "field_name"]))
    );
}

/**
 * push a new editor state to the internal editorState state
 * @param   {Immutable.Record} editorState
 * @param   {Immutable.Record} contentState
 * @param   {String} editorChangeType
 * @returns {Immutable.Record}
 */
function updateEditorState(editorState, contentState, editorChangeType) {
  return EditorState.push(editorState, contentState, editorChangeType);
}

/**
 * change the metadata for the block and commit the state change
 * @param   {Immutable.Record} editorState
 * @param   {Immutable.Record} selectionState
 * @param   {Immutable.Map} data
 * @returns {Immutable.Record}
 */
function changeBlockData(editorState, selectionState, data) {
  const newContent = Modifier.mergeBlockData(
    editorState.getCurrentContent(),
    selectionState,
    data
  );

  return updateEditorState(editorState, newContent, "change-block-data");
}

/**
 * Modifier - change the block type and commit the state change
 * @param   {Immutable.Record} editorState
 * @param   {Immutable.Record} selectionState
 * @param   {String} the new blockType
 * @returns {Immutable.Record} the newState
 *
 */
function changeBlockType(editorState, selectionState, type) {
  const newContent = Modifier.setBlockType(
    editorState.getCurrentContent(),
    selectionState,
    type
  );

  return updateEditorState(editorState, newContent, "change-block-type");
}

/**
 * _createBlockFragment
 * @param  {Immutable.Record} charData
 * @param  {Object} block data
 * @return {Array} Block Fragment array
 */
function buildContentBlock(
  type,
  text = "",
  data = {},
  characterList = List(),
  key = genKey()
) {
  return new ContentBlock({
    key,
    type,
    text,
    characterList,
    data,
  });
}

/**
 * _getDefaultConfig
 * @param  {Object} config
 * @return {Object} Block Config
 */
function _getDefaultConfig(config) {
  return config.sections.find(
    (section) => section.field_name === config.default_section
  );
}

/**
 * createBlockData
 * @param   {Object} config
 * @param   {String} type
 * @returns {Object}
 */
function createBlockData(config, type) {
  return {
    contentType: type,
    config: Map({ ..._getDefaultConfig(config) }),
  };
}

/**
 * create a selection inside a given block
 * @param   {String} key
 * @param   {Number} start
 * @param   {Number} end
 * @returns {Immutable.Record} - <SelectionState>
 */
function createBlockSelection(key, anchorOffset, focusOffset) {
  return SelectionState.createEmpty(key).merge({
    anchorKey: key,
    focusKey: key,
    anchorOffset,
    focusOffset,
    hasFocus: true,
  });
}

/**
 * creates character metadata object from provided configuration
 * @param   {Object} data
 * @returns {Immutable.Record} CharacterMetadata
 */
function createCharacterMetadata(data) {
  return CharacterMetadata.create(data);
}

/**
 * reset cursor to the start of the selection
 * @param   {Immutable.Record} editorState
 * @param   {String} key
 * @returns {Immutable.Record}
 */
function selectionToStart(editorState, key) {
  const selection = createBlockSelection(key, 0, 0);
  const newState = EditorState.acceptSelection(editorState, selection);

  return EditorState.forceSelection(newState, newState.getSelection());
}

/**
 * select the entire block by getting the length of the selected block's content
 * @param  {Immutable.Record} state - <EditorState>
 * @return {Immutable.Record} - updated <EditorState>
 */
function selectEntireBlock(state, key = state.getSelection().getEndKey()) {
  const blockLength = state
    .getCurrentContent()
    .getBlockForKey(key)
    .getText().length;

  const editorState = EditorState.acceptSelection(
    state,
    SelectionState.createEmpty(key).merge({
      anchorKey: key,
      focusKey: key,
      anchorOffset: 0,
      focusOffset: blockLength,
      hasFocus: true,
    })
  );

  return EditorState.forceSelection(editorState, editorState.getSelection());
}

/**
 * Set the block type for all selected blocks.
 * @param   {Immutable.Record} contentState
 * @param   {Immutable.Record} selectionState
 * @param   {String} blockType - like 'image' or 'header-one'
 */
function setBlockType(contentState, selectionState, blockType) {
  return Modifier.setBlockType(contentState, selectionState, blockType);
}

/**
 * Split the selected block into two blocks.
 * This should only be used if the selection is collapsed.
 * @param   {Immutable.Record} contentState
 * @param   {Immutable.Record} selectionState
 * @returns {Immutable.Record} ContentState
 */
function splitBlock(contentState, selection) {
  const selectionToUse = selection || contentState.getSelectionAfter();

  return Modifier.splitBlock(contentState, selectionToUse);
}

/**
 * This method will replace the "target" range with the fragment.
 * @param  {Immutable.Record} contentState - <ContentState>
 * @param  {Immutable.Record} target       - <SelectionState>
 * @param  {Immutable.Record} fragment     - <BlockMap>
 * @return {Immutable.Record} contentState - <ContentState>
 */
function replaceWithFragment(contentState, target, fragment) {
  return Modifier.replaceWithFragment(contentState, target, fragment);
}

/**
 * Replaces the
 * @param  {Immutable.Record}   contentState - <ContentState>
 * @param  {Immutable.Record}   selection    - <SelectionState>
 * @param  {String}             text
 * @return {Immutable.Record} - contentState - <ContentState>
 */
function replaceText(contentState, selection, text) {
  return Modifier.replaceText(contentState, selection, text);
}

/**
 * Removes an entire range of text from the editor.
 * @param   {Immutable.Record}  contentState
 * @param   {Immutable.Record}  selectionState
 * @param   {String}            direction - either 'backward' or 'forward'
 * @return  {Immutable.Record}  ContentState
 */
function removeRange(contentState, selectionState, direction) {
  return Modifier.removeRange(contentState, selectionState, direction);
}

/**
 * isUnremovableBlock
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isUnremovableBlock(state) {
  return (
    isPlaceholder(state) ||
    (isCursorAtStart(state) && isLastOfType(state) && !isMediaBlock(state))
  );
}

/**
 * isTogglingPlaceholderOn
 * @param   {Immutable.Record} state
 * @returns {Boolean}
 */
function isTogglingPlaceholderOn(state) {
  return (
    (isBlockEmpty(state) || isMultipleBlocks(state)) &&
    isLastOfType(state) &&
    !isPlaceholder(state)
  );
}

/**
 * isBackspace
 * @param   {String} command
 * @returns {Boolean}
 */
function isBackspace(command) {
  return (
    command === "backspace" ||
    command === "backspace-to-start-of-line" ||
    command === "backspace-word"
  );
}

export {
  buildContentBlock,
  getCurrentBlock,
  getCurrentBlocks,
  getBlock,
  createBlockData,
  changeBlockData,
  changeBlockType,
  createBlockSelection,
  createCharacterMetadata,
  hasDisabled,
  isSelection,
  isSelectionAtEnd,
  isMultipleBlocks,
  isCursorAtStart,
  isPlaceholder,
  isMediaPlaceholder,
  isMediaBlock,
  isRestricted,
  isBlockEmpty,
  hasSelectionChanged,
  isLastOfType,
  replaceWithFragment,
  replaceText,
  removeRange,
  selectEntireBlock,
  selectionToStart,
  setBlockType,
  splitBlock,
  updateEditorState,
  isUnremovableBlock,
  isTogglingPlaceholderOn,
  isBackspace,
};
