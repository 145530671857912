function customname(_) {
  return (input, options) => {
    if (_.isUndefined(input) || _.isPlainObject(options)) {
      return "";
    }

    const displayName = _.result(
      _.find(options, (option) => option.value === input),
      "display_name"
    );

    return displayName;
  };
}

export default customname;
