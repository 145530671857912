import React from "react";
import PropTypes from "prop-types";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import DropdownList from "../dropdown-list/dropdown-list";

import styles from "./dropdown-localisation-navigation.scss";

/**
 * DropdownLocalisationNavigation
 * @param {Object} props
 */
export default function DropdownLocalisationNavigation(props) {
  return (
    <div className={styles["dropdown-localisation-navigation"]}>
      <div className={styles["dropdown-localisation-navigation__dropdown"]}>
        <div>
          <span className={styles["dropdown-localisation-navigation__text"]}>
            {props.optionSelected || props.placeholder}
          </span>
          <SkylarkIcon
            iconName="down"
            extraClassName={styles["dropdown-localisation-navigation__icon"]}
          />
          <DropdownList
            options={props.options}
            current={props.optionSelected || ""}
            onSelect={props.onSelect}
            isCollapsed={props.isOpen}
            type="languages"
          />
        </div>
      </div>
    </div>
  );
}

DropdownLocalisationNavigation.propTypes = {
  placeholder: PropTypes.string.isRequired,
  optionSelected: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

DropdownLocalisationNavigation.defaultProps = {
  optionSelected: "",
};
