import { createApiAction } from "store/api/api.actions";

import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import ApiConstant from "store/api/api.constant";
import EntityConstant from "./entity.constant";

const setCurrentEntity = (entityName, entityType) => ({
  type: EntityConstant.SET_CURRENT_ENTITY,
  entityName,
  entityType,
});

/**
 * fetching entity data
 * @param {string} entity name
 * @param {string} entityId - episode uid
 * @param {object} [filters] - query params
 */
const fetchEntity = (entity, entityId, filters) => {
  const EntityFactory = Injector.get("EntityFactory");

  return createApiAction(
    EntityConstant.FETCH_ENTITY,
    EntityFactory.get(entity, entityId, filters)
  );
};

/**
 * extending entity data by fetching more data
 * @param entityName
 * @param entityId
 * @param [filters]
 * @return {{api: {failureType: string, promise: *, successType: string}, type: string}}
 */
const fetchAndAttachEntity = (entityName, entityId, filters) => {
  const EntityFactory = Injector.get("EntityFactory");

  return createApiAction(
    EntityConstant.FETCH_AND_ATTACH_ENTITY,
    EntityFactory.get(entityName, entityId, filters)
  );
};

/**
 * Creating a complete new entity, data needs to be removed
 * @return {{type: string}}
 */
const createEntity = () => ({
  type: EntityConstant.CREATE_ENTITY,
});

/**
 * Creating a new translation for an existing entity, the current data needs to be kept
 * @return {{type: string}}
 */
const createTranslation = () => ({
  type: EntityConstant.CREATE_TRANSLATION,
});

/**
 * the new created entity is not stored yet.
 * this will send the request to the backend with the new data.
 * @param data
 * @param {string} entityName
 * @param errorMessage
 * @return {function(...[*]=)}
 */
const saveCreatedEntity = (data, entityName, errorMessage) => {
  const EntityFactory = Injector.get("EntityFactory");

  const apiAction = createApiAction(
    EntityConstant.CREATE_ENTITY,
    EntityFactory.create(entityName, data)
  );
  return {
    ...apiAction,
    errorMessage,
  };
};

/**
 * Cancel any kind of entity or translation creation
 * @return {{type: string}}
 */
const cancelCreation = () => ({
  type: EntityConstant.CREATE_ENTITY_CANCELLED,
});

/**
 * patch entity with data
 * @param {string} self
 * @param {Object} data
 * @param {Object} [filters]
 * @param {string|function} successMessage
 * @param {string|function} errorMessage
 * @returns {{api: {failureType: string, promise: *, successType: string}, type: string}}
 */
const updateEntity = (self, data, filters, successMessage, errorMessage) => {
  const EntityFactory = Injector.get("EntityFactory");

  const apiAction = createApiAction(
    EntityConstant.UPDATE_ENTITY,
    EntityFactory.patchByUrl(self, data, filters)
  );

  return {
    ...apiAction,
    successMessage,
    errorMessage,
  };
};

/**
 * because not all components have redux, we need a way to allow a direct update of the state with fetch data from somewhere else
 * @param {object} data
 * @return {{response: {Object}, type: string}}
 */
const updateEntityWithData = (data) => ({
  type: ApiConstant.getSuccessType(EntityConstant.UPDATE_ENTITY),
  response: data,
});

/**
 * adding a new language to an entity. A new language needs to be set by a PUT request
 * @param {string} entityName
 * @param {string} entityId
 * @param {Object} data
 * @param {string} successMessage
 * @param {string} errorMessage
 * @returns {{api: {failureType: string, promise: *, successType: string}, type: string}}
 */
const addLanguageVersion = (
  entityName,
  entityId,
  data,
  successMessage,
  errorMessage
) => {
  const EntityFactory = Injector.get("EntityFactory");
  const patchedData = {
    ...data,
    uid: entityId,
  };

  const apiAction = createApiAction(
    EntityConstant.CREATE_ENTITY,
    EntityFactory.update(entityName, patchedData)
  );

  return {
    ...apiAction,
    successMessage,
    errorMessage,
  };
};

/**
 * update a schedule with new data
 * @param {string} url
 * @param {object} expandedSchedule
 * @param {string} successMessage
 * @param {string} errorMessage
 * @return {{api: {failureType: string, promise: *, successType: string}, type: string}}
 */
const updateSchedule = (
  url,
  expandedSchedule,
  successMessage,
  errorMessage
) => {
  const SchedulesFactory = Injector.get("SchedulesFactory");

  const apiAction = createApiAction(
    EntityConstant.UPDATE_SCHEDULE,
    SchedulesFactory.update(url, expandedSchedule)
  );

  return {
    ...apiAction,
    successMessage,
    errorMessage,
  };
};

export {
  setCurrentEntity,
  fetchEntity,
  fetchAndAttachEntity,
  createEntity,
  saveCreatedEntity,
  createTranslation,
  updateEntity,
  updateEntityWithData,
  cancelCreation,
  addLanguageVersion,
  updateSchedule,
};
