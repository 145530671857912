import angular from "angular";
import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./entity-documents.html";

class EntityDocumentsPageCtrl {
  constructor(
    $scope,
    EntityFactory,
    GlobalParamsService,
    MessageService,
    HistoryService,
    ConfigurationFactory,
    NotificationService,
    DocumentFactory
  ) {
    this.$scope = $scope;
    this.EntityFactory = EntityFactory;
    this.GlobalParamsService = GlobalParamsService;
    this.MessageService = MessageService;
    this.HistoryService = HistoryService;
    this.ConfigurationFactory = ConfigurationFactory;
    this.NotificationService = NotificationService;
    this.DocumentFactory = DocumentFactory;
  }

  $onInit() {
    this.documentsProperty = "document_urls";
    this.typeUrlProperty = "document_type_url";
    this.entityName = this.EntityFactory.getEntityName();
    this.entityType = this.EntityFactory.getEntityType();
    this.entityId = this.EntityFactory.getEntityId();

    this.setSubscriptions();

    if (this.$scope.$parent.entity && this.$scope.$parent.entity.data) {
      this.setData(this.$scope.$parent.entity.data);
      this.loadConfiguration();
      this.fetchDocumentData();
    }
  }

  setSubscriptions() {
    this.MessageService.subscribe(
      `${this.entityName}.onData`,
      (channel, data) => {
        this.setData(data.data);
        this.loadConfiguration();
        this.fetchDocumentData();
      }
    );

    this.MessageService.subscribe("EntityListing.Delete", (channel, data) => {
      this.onDocumentDelete(data);
    });

    this.MessageService.subscribe("EntityListing.Update", (channel, data) => {
      this.onDocumentUpdate(data);
    });
  }

  setData(data) {
    this.entityData = { ...data };
  }

  setDocuments(documents) {
    this.documents = documents;
  }

  loadConfiguration() {
    this.ConfigurationFactory.getTabConfiguration(
      this.entityName,
      "documents",
      this.entityType
    ).then((config) => {
      this.config = config;
    });
  }

  fetchDocumentData() {
    const filter = {
      ...this.GlobalParamsService.getDefaultParams(),
      fields: this.documentsProperty,
      fields_to_expand: `${this.documentsProperty},${this.documentsProperty}__schedule_urls`,
    };
    this.EntityFactory.get(this.entityName, this.entityId, filter).then(
      (data) => {
        this.documents = data[this.documentsProperty];
      }
    );
  }

  onDocumentCreated() {
    this.fetchDocumentData();
  }

  onPositionChange(document, newPosition) {
    this.DocumentFactory.changePosition(this.documents, document, newPosition)
      .then((documents) => (this.documents = documents))
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.updatePositionError
        )
      );
  }

  onDocumentDelete(deleteDoc) {
    this.DocumentFactory.deleteDocument(this.documents, deleteDoc).then(
      (documents) => this.setDocuments(documents)
    );
  }

  onDocumentUpdate(updatedDoc) {
    this.DocumentFactory.updateDocument(this.documents, updatedDoc).then(
      (documents) => this.setDocuments(documents)
    );
  }

  $onDestroy() {
    this.MessageService.unregisterChannel(`${this.entityName}.onData`);
    this.MessageService.unregisterChannel("EntityListing.Delete");
    this.MessageService.unregisterChannel("EntityListing.Update");
  }
}

const entityDocumentsPageComponent = {
  controller: EntityDocumentsPageCtrl,
  controllerAs: "component",
  template,
};

const EntityDocuments = angular
  .module("Skylark.Pages.Entity.EntityDocuments", [])
  .component("entityDocumentsPage", entityDocumentsPageComponent);

export default EntityDocuments;
