import _ from "lodash";

import { createSelector } from "reselect";
import { getBaseConfig } from "store/entities-config/entities-config.selector";
import { getEntity } from "store/entity/entity.selector";

/**
 * build heading
 * @param {Object} state
 * @param {String} entity
 * @param {String} [entityType]
 * @returns {String}
 */
export const buildHeading = createSelector(
  [getEntity, getBaseConfig, (state, entity) => entity],
  ({ data: entityData }, { data: config }, entityName) => {
    if (_.isEmpty(config)) {
      return `Untitled ${entityName}`;
    }

    const headingField = config.heading_field || "title";

    if (entityData[headingField]) {
      return entityData[headingField];
    }

    if (headingField === "full_name") {
      if (entityData.full_name || entityData.first_name) {
        return (
          entityData.full_name ||
          `${entityData.first_name} ${entityData.last_name}`
        );
      }
    }

    return `Untitled ${config.singular_name}`;
  }
);
