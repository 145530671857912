let injectorInstance;

/**
 * Angular Factory to get current instance of injector
 * this should be initiated as early as possible in the application
 * @param $injector
 * @return {{}}
 * @constructor
 */
const InjectorFactory = ($injector) => {
  const factory = {};

  factory.init = () => {
    injectorInstance = $injector;
  };

  return factory;
};

/**
 * Return an instance of the a dependency injectable Object (Service, Factory, Provider, etc)
 * if this function is called before injector is available an null object is returned
 *
 * @param name {String} - the name of the instance to retrieve
 * @return {null|*}
 */
const get = function (name) {
  if (injectorInstance) {
    return injectorInstance.get(name);
  }

  console.warn("Injector not initiated");

  return null;
};

/**
 * the wrapper around angular injector instance, which can be called by plain JavaScript
 * to gain instances of Factories or Services
 */
const Injector = {
  get,
};

export { InjectorFactory, Injector };
