class UiSharedService {
  /**
   * Returns whether the string is a url. Needed when accessing image_urls, image_types or
   * any dropdown
   * @access private
   * @param {any} value - the string to test again
   * @returns {boolean} - whether or the url is a string
   */
  isUrl(value) {
    if (typeof value === "string") {
      const urlPattern = new RegExp("http|api/", "g");

      return value.match(urlPattern);
    }
    return false;
  }
}

export default UiSharedService;
