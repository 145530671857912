import angular from "angular";
import { SCHEDULE_STATUSES } from "./skylarklib/constants/search-and-filter";
import { ENTITY_CONTENT_TYPES } from "./skylarklib/constants/entity-content-types";

export const UID_PATTERN = "\\w{4}_[0-9a-f]{32}";

const Metadata = angular
  .module("Skylark.Metadata", [])
  .constant("APP_SETTINGS", {
    APP_TITLE: "Skylark CMS",
    NAMESPACE: "skylark",
    ALWAYS_SCHEDULE_SLUG: "/api/schedules/always/",
    // Value added by webpack at build time
    IN_NGINX_CONTAINER: __IN_NGINX_CONTAINER__,
  })
  .constant("CORE_ENTITIES", [
    "sets",
    "brands",
    "seasons",
    "episodes",
    "channels",
    "schedules",
    "editorial-schedules",
    "licensing",
    "tags",
    "talent",
    "assets",
    "articles",
    "cms-articles",
    "links",
    "credits",
  ])
  .constant("ENTITY_CONTENT_TYPES", ENTITY_CONTENT_TYPES)
  .constant("UID_PATTERN", UID_PATTERN)
  .constant("PIKADAY_CONFIG", {
    theme: "skylark",
    position: "bottom left",
  })
  .constant("DATE_FORMATTING", {
    format: "l",
  })
  .constant("API_ENDPOINTS", {
    sets: [
      {
        version: "0",
        slug: "sets",
      },
      {
        version: "4.1",
        slug: "cms-sets",
      },
    ],
  })
  .constant("SCHEDULE_STATUSES", SCHEDULE_STATUSES)
  .constant("USERLISTS", {
    bookmarks: {
      name: "cms-bookmarks",
    },
  });

export default Metadata;
