import ReduceReducers from "reduce-reducers";
import { createAttachDataReducer } from "store/api/api.reducer";
import ApiConstant from "store/api/api.constant";
import NavigationConstant from "./navigation.constant";

const initialState = {
  /**
   * Contains items from a navigation/static.json config
   *
   *
   */
  data: [],
};

const fetch = createAttachDataReducer(
  initialState,
  NavigationConstant.FETCH_NAVIGATION
);

const overrideDataSuccess = (state = initialState, action) => {
  if (
    action.type ===
    ApiConstant.getSuccessType(NavigationConstant.FETCH_NAVIGATION)
  ) {
    const { response } = action;
    const { items: data } = response;

    return {
      ...state,
      data,
    };
  }
  return state;
};

const NavigationReducer = ReduceReducers(
  initialState,
  fetch,
  overrideDataSuccess
);

export default NavigationReducer;
