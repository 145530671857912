import React, { useEffect } from "react";
import { react2angular } from "react2angular";
import { useDispatch } from "react-redux";

import withStoreProvider from "hocs/with-store-provider";
import { setResultsVisibility } from "store/search/search.action";
import useLocation from "hooks/use-location";
import SelectFilter from "../search/select-filter/select-filter.component";
import SearchBar from "../search/search-bar/search-bar.component";
import SearchResults from "../search/search-results/search-results.component";

const TopNavigationSearch = () => {
  const dispatch = useDispatch();
  const [{ pathname }] = useLocation();

  /**
   * hide the results when navigation has changed
   */
  useEffect(() => {
    dispatch(setResultsVisibility(false));
  }, [dispatch, pathname]);

  return (
    <div className="top-navigation-search">
      <div className="search-container">
        <div className="search-bar-container">
          <SelectFilter />
          <SearchBar />
        </div>
        <SearchResults />
      </div>
    </div>
  );
};

const reactTopNavigationSearch = angular
  .module("Skylark.Components.React.TopNavigationSearch", [])
  .component(
    "topNavigationSearch",
    react2angular(withStoreProvider(TopNavigationSearch))
  );

export default TopNavigationSearch;

export { reactTopNavigationSearch };
