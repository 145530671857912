import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import styles from "./button.scss";

const BUTTON_PRIMARY = "primary";
const BUTTON_SECONDARY_ON_DARK = "secondary-on-dark";
const BUTTON_SECONDARY_ON_LIGHT = "secondary-on-light";
const BUTTON_TERTIARY = "tertiary";
const BUTTON_DISABLED = "disabled";
const BUTTON_LOCKED = "locked";

/**
 * Button
 * @param {Object} props
 */
export default function Button(props) {
  const disabledDarkBackground = props.isDisabled && props.disabledDark;
  const disabledSecondaryOnDark =
    props.isDisabled && props.buttonColor === BUTTON_SECONDARY_ON_DARK;

  return (
    <button
      className={classnames(
        styles[`button-${props.buttonType}`],
        styles[`button-${props.buttonColor}`],
        { [styles["button-disabled"]]: props.isDisabled },
        {
          [styles["button-disabled--dark-background"]]: disabledDarkBackground,
        },
        { [styles["button-disabled--small-light"]]: disabledSecondaryOnDark }
      )}
      {...(props.id && { id: props.id })}
      onClick={!props.isDisabled ? props.action : null}
    >
      <span
        className={classnames(styles[`button-${props.buttonType}__text`], {
          [styles[`button-${props.buttonType}__text--has-icon`]]: props.icon,
        })}
      >
        {props.copy}
      </span>
      {props.icon && (
        <span className={styles[`button-${props.buttonType}__icon-container`]}>
          <SkylarkIcon
            iconName={props.icon}
            extraClassName={classnames(
              styles[`button-${props.buttonType}__icon`],
              styles[`button-${props.buttonColor}__icon`],
              { [styles["button-disabled__icon"]]: props.isDisabled },
              {
                [styles["button-disabled--dark-background__icon"]]:
                  disabledDarkBackground,
              },
              {
                [styles["button-disabled--small-light__icon"]]:
                  disabledSecondaryOnDark,
              }
            )}
          />
        </span>
      )}
    </button>
  );
}

Button.propTypes = {
  action: PropTypes.func.isRequired,
  buttonType: PropTypes.oneOf(["standard", "small", "medium"]).isRequired,
  copy: PropTypes.string.isRequired,
  icon: PropTypes.string,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  disabledDark: PropTypes.bool,
  buttonColor: PropTypes.oneOf([
    BUTTON_PRIMARY,
    BUTTON_SECONDARY_ON_DARK,
    BUTTON_SECONDARY_ON_LIGHT,
    BUTTON_TERTIARY,
    BUTTON_DISABLED,
    BUTTON_LOCKED,
  ]),
};

Button.defaultProps = {
  icon: "",
  id: "",
  isDisabled: false,
  disabledDark: false,
  buttonColor: BUTTON_PRIMARY,
};
