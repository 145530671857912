import template from "./content-breadcrumb.html";

/**
 * Class - controller for angular directive contentNavigation
 */
class ContentBreadcrumbClass {
  /**
   * @constructor
   * @param {object} ConfigurationFactory
   * @param {object} BreadcrumbService - service that handles breadcrumbs
   * @param {object} MessageService - pub sub service
   * @param {object} EntityFactory - Entity factory
   * @param {object} RouteService
   * @param {object} _ - lodash
   */
  constructor(
    ConfigurationFactory,
    BreadcrumbService,
    MessageService,
    EntityFactory,
    RouteService,
    _
  ) {
    this.ConfigurationFactory = ConfigurationFactory;
    this.BreadcrumbService = BreadcrumbService;
    this.MessageService = MessageService;
    this.EntityFactory = EntityFactory;
    this.RouteService = RouteService;
    this._ = _;
  }

  /**
   * $onDestroy
   */
  $onDestroy() {
    this.MessageService.unregisterChannel("Language.changed");
  }

  /**
   * initialise directive
   * @returns {void}
   */
  init() {
    this.entityName = this.EntityFactory.getEntityName();
    this.entityId = this.EntityFactory.getEntityId();
    this.entityType = this.EntityFactory.getEntityType();

    this._setupSubscriptions();
    this._reset();
  }

  /**
   * Helper function to see if the current page is in the breadcrumb array
   * @private
   * @returns {boolean}
   */
  _isCurrentlyInBreadcrumbs() {
    return this.BreadcrumbService.contentBreadcrumbItems.some(
      (item) => this.entityId === item.id
    );
  }

  /**
   * Helper function determines whether or not there is already a child of this level
   * in the breadcrumb. For example, it if there is already an episode in the breadcrumb and this
   * entity is an episode it would return true. Works different for brands which can have children
   * brands so the only time we are navigating to a sibling is when the brand already has children
   * of another type.
   * @private
   * @returns {boolean}
   */
  _isReplacingItem() {
    let isReplacing = false;

    if (this.entityName !== "assets") {
      const matchingTypeItem =
        this.BreadcrumbService.contentBreadcrumbItems.find(
          (item) => item.type === this.entityName
        );
      isReplacing =
        matchingTypeItem &&
        this.entityData.self.indexOf(matchingTypeItem.url) < 0;
    }

    if (this.entityName === "brands") {
      const hasChildren =
        this.BreadcrumbService.contentBreadcrumbItems.length > 1;
      const hasChildrenOfDifferentType =
        !!this.BreadcrumbService.contentBreadcrumbItems.find(
          (item) => item.type !== "brands"
        );

      isReplacing = hasChildrenOfDifferentType || (isReplacing && hasChildren);
    }

    return isReplacing;
  }

  /**
   * _reset - _resets all breadcrumbItems and overviewItemsCount to empty values
   * @private
   * @returns {void}
   */
  _reset() {
    if (
      !this._isCurrentlyInBreadcrumbs() &&
      !this.BreadcrumbService.isContentBreadcrumbLocked
    ) {
      this.BreadcrumbService.isHistoryBreadcrumbVisible =
        this.BreadcrumbService.isHistoryBreadcrumbVisible &&
        !this._isReplacingItem();
      this.BreadcrumbService.startNewContentBreadcrumb();
      this.breadcrumbItems = this.BreadcrumbService.contentBreadcrumbItems;
      this._buildUiItems();
    } else {
      this.breadcrumbItems = this.BreadcrumbService.contentBreadcrumbItems;
      this.BreadcrumbService.unlockContentBreadcrumb();
    }

    this._updateActiveItems();
  }

  /**
   * run sequence of steps to setup scope environment
   * @access private
   * @returns {void}
   */
  _buildUiItems() {
    if (!this._.isEmpty(this.entityData)) {
      this._buildBreadCrumbItems();
    }
  }

  /**
   * Update the active state of each item based on if the current entity matches the breadcrumbs
   * @private
   * @returns {void}
   */
  _updateActiveItems() {
    this.BreadcrumbService.contentBreadcrumbItems.forEach((item) => {
      item.active = this.entityId === item.id;
    });
  }

  /**
   * handler for update breadcrumb on channel
   * @access private
   * @param {any} data - returned data from channel
   * @returns {void}
   */
  _updateBreadcrumbHandler(data) {
    this.EntityFactory.getByUrl(data.parent_url).then((response) => {
      this.entityData = response;
      this._reset();
    });
  }

  /**
   * setupSubscriptions - register MessageService channels to listen on
   * Requires arrow function for call of updateBreadcrumb to avoid needing to bind this
   * @access private
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.registerChannel("updateBreadcrumb");
    this.MessageService.on("updateBreadcrumb", (ch, data) => {
      this._updateBreadcrumbHandler(data);
    });

    this.MessageService.subscribe("Language.changed", () => {
      this.BreadcrumbService.startNewContentBreadcrumb();
    });
  }

  /**
   * @param  {string} parentUrl - url of parent entity
   * @access private
   * @returns {void}
   */
  _fetchParentData(parentUrl) {
    const entityName = this.EntityFactory.getEntityNameByUrl(parentUrl);
    const entityContentTypeSlugName =
      this.EntityFactory.getEntityTypeSlugKey(entityName);
    const filter = {
      fields: [
        "self",
        "uid",
        "title",
        "parent_url",
        entityContentTypeSlugName,
      ].join(","),
    };

    this.EntityFactory.getByUrl(parentUrl, filter).then((response) => {
      const entityName = this.EntityFactory.getEntityNameByUrl(parentUrl);
      const entityContentTypeSlugName =
        this.EntityFactory.getEntityTypeSlugKey(entityName);
      const entityType = response[entityContentTypeSlugName];

      this.addBreadcrumb(response, response.uid, entityName, entityType);

      if (response.parent_url && response.parent_url !== parentUrl) {
        this._fetchParentData(response.parent_url);
      } else {
        // once all the data loaded reserve order so brands first
        this.breadcrumbItems.reverse();
        this.MessageService.publish("HistoryBreadcrumb.Update");
      }
    });
  }

  /**
   * _buildBreadCrumbItems - add all entity types to breadcrumb
   * @access private
   * @returns {void}
   */
  _buildBreadCrumbItems() {
    this.addBreadcrumb(
      this.entityData,
      this.entityId,
      this.entityName,
      this.entityType
    );

    if (this.entityData.parent_url) {
      this._fetchParentData(this.entityData.parent_url);
    }

    this.MessageService.publish("HistoryBreadcrumb.Update");
  }

  /**
   * use data to add breadcrumb
   * the title is set asynchronously as it needs to fetch the config first to decide what property is used for a title
   *
   * @param {object} entityData
   * @param {string} entityId
   * @param {string} entityName
   * @param {string} entityType
   */
  addBreadcrumb(entityData, entityId, entityName, entityType) {
    const url = this.RouteService.buildURL({
      uid: entityId,
      name: entityName,
      type: entityType,
    });

    const breadcrumb = {
      id: entityId,
      type: entityName,
      url,
    };

    this.breadcrumbItems.push(breadcrumb);
    this.fetchEntityTitle(entityData, entityName, entityType).then((title) => {
      breadcrumb.title = title;
    });
  }

  /**
   * fetching config of entity name and type if exists,
   * and then using the configured heading_field to get the property which is used for displaying a title
   *
   * @param data
   * @param entityName
   * @param entityType
   * @returns {Promise<string>}
   */
  fetchEntityTitle(data, entityName, entityType) {
    return this.ConfigurationFactory.getBaseConfiguration(
      entityName,
      entityType
    )
      .then((config) => data[config.heading_field])
      .catch(() => data.title);
  }

  /**
   * Called by DOM when content breadcrumb is used to naviagte. Reset the previous breadcrumb so
   * as not to cause any odd side effect with "backloop" logic.
   * @returns {void}
   */
  navigateTo() {
    this.BreadcrumbService.previousBreadcrumb = undefined;
  }
}

/**
 * contentNavigationDirective - angular directive setup
 * @access private
 * @returns {object} - directive object config
 */
function contentBreadcrumbDirective() {
  return {
    restrict: "E",
    replace: true,
    scope: {},
    bindToController: {
      entityData: "=",
    },
    controller: ContentBreadcrumbClass,
    controllerAs: "component",
    template,
    link: (scope) => {
      scope.component.init();
    },
  };
}

export default contentBreadcrumbDirective;
