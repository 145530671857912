import template from "./entity-imagery.html";

/**
 * Images Tab
 * @todo look at all these calls to getImages
 */

class EntityImageryPageCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} $state
   * @param {Object} $stateParams
   * @param {Object} ApiService
   * @param {Object} ImagesFactory
   * @param {Object} ConfigurationFactory
   * @param {Object} ApiRequestConfigFactory
   * @param {Object} EntityFactory
   * @param {Object} GlobalParamsService
   * @param {Object} HistoryService
   * @param {Object} MessageService
   * @param {Object} NotificationService
   * @param {Object} _
   */
  constructor(
    $scope,
    $state,
    $stateParams,
    ApiService,
    ImagesFactory,
    ConfigurationFactory,
    ApiRequestConfigFactory,
    EntityFactory,
    GlobalParamsService,
    HistoryService,
    MessageService,
    NotificationService,
    _
  ) {
    this.$scope = $scope;
    this.$state = $state;
    this.$stateParams = $stateParams;
    this.ApiService = ApiService;
    this.ImagesFactory = ImagesFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.EntityFactory = EntityFactory;
    this.GlobalParamsService = GlobalParamsService;
    this.HistoryService = HistoryService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this._ = _;

    this.data = undefined;
    this.config = undefined;

    this.images = undefined;
    this.imagesGroupBy = "image_type";
    this.entity = this.$stateParams.entity
      ? this.$stateParams.entity
      : this.$state.current.title;

    this.channel = undefined;

    this.imageAdded = false;

    this.getData();
    this.setSubscriptions();

    if (
      this.$scope.$parent.entity &&
      !this._.isEmpty(this.$scope.$parent.entity.data)
    ) {
      this.data = angular.extend({}, this.$scope.$parent.entity.data);

      // @todo: assess if we need this
      this.channel = this.$scope.$parent.entity.channel;
      this.loadConfiguration();
      this.getImages();
    }
  }

  /**
   * loadConfiguration
   * conditionally loads remote configuration based on entity
   * @returns {void}
   */
  loadConfiguration() {
    if (this.entity === "sets") {
      this.ConfigurationFactory.getSetImageryConfigurationByType(
        this.data.set_type_slug
      )
        .then((config) => {
          this.config = config;
        })
        .catch(() => this.NotificationService.notifyRefresh());
    } else {
      this.ConfigurationFactory.getTabConfiguration(this.entity, "imagery")
        .then((config) => {
          this.config = config;
        })
        .catch(() => this.NotificationService.notifyRefresh());
    }
  }

  /**
   * setSubscriptions
   *
   * @listens imageCreate#Image.Create;
   * @listens imageUpdate#Images;
   * @listens $scope.$destroy
   *
   * @returns {void}
   */
  setSubscriptions() {
    const updateChannelName = "Images";
    const createChannelName = "Image.Create";
    const deleteChannelName = "Image.Delete";

    this.MessageService.subscribe(createChannelName, (channel, data) => {
      this.imageAdded = data.uid;
      this.updateParent();
    });

    this.MessageService.subscribe(deleteChannelName, (channel, data) => {
      const dataTypeIndex = this.findIndexOfType(this.images, data.type);
      const imageIndex = this.findIndexOfImageInType(
        this.images[dataTypeIndex].objects,
        data
      );

      this.images[dataTypeIndex].objects.splice(imageIndex, 1);

      this.updateReorderedList(this.images[dataTypeIndex].objects);
    });

    this.MessageService.subscribe(updateChannelName, (channel, data) => {
      this.imageAdded = false;
      if (!data) {
        this.updateParent();
      } else {
        this.positionItem(data, data.prevType);
      }
    });
  }

  /**
   * Gets the data
   * @returns {void}
   */
  getData() {
    this.dataChannel = `${this.entity}.onData`;
    this.MessageService.registerChannel(this.dataChannel);

    this.MessageService.on(this.dataChannel, (channel, data) => {
      this.data = angular.extend({}, data.data);

      this.channel = data.channel;
      this.loadConfiguration();
      this.getImages();
    });
  }

  /**
   * getImages
   * @fires imageUpdate.uid#click (if new image)
   * @returns {void}
   */
  getImages() {
    this.EntityFactory.get(this.entity, this.data.uid, {
      ...this.GlobalParamsService.getDefaultParams(),
      fields: "image_urls",
    })
      .then((entity) => {
        this.data.image_urls = entity.image_urls;
        return this.ImagesFactory.getImagesGroupedBy(
          this.data.image_urls,
          this.imagesGroupBy
        );
      })
      .then((images) => {
        this.images = images.map((group) => {
          group.objects = this._.sortBy(group.objects, "position");

          return group;
        });
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * updateParent
   * updates enclosing controller
   * @returns {void}
   */
  updateParent() {
    this.ApiService.get(
      `${this.data.self}`,
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
        useDefaultParams: true,
        useGlobalParams: true,
      })
    ).then((data) => {
      this.data = data;
      // update enclosing controller
      if (
        this._.isPlainObject(this.$scope.$parent) &&
        this._.isPlainObject(this.$scope.$parent.entity)
      ) {
        this.$scope.$parent.entity.data = angular.extend({}, data);
      }
      this.getImages();
    });
  }

  /**
   * returns a list of images in the specified image type
   * @param {string} type - image type name
   * @returns {array} array of image entities, from the object property, or empty array
   */
  getListOfType(type) {
    const imageType = this._.find(this.images, (cur) => cur.type === type);
    const list = imageType ? imageType.objects : [];

    return list;
  }

  /**
   * counter
   * @description  returns current size of the image type collection
   * @param {string} imageType as a name string
   * @returns {int} amount of objects inside the collection
   */
  counter(imageType) {
    const selectedType = this.getListOfType(imageType);

    return selectedType.length;
  }

  /**
   * reorderList
   * @param {array} arr - array to be ordered and filtered
   * @returns {array} new array with resources that need updating
   */
  reorderList(arr) {
    const newArr = arr
      .map((cur, index) => {
        if (cur.position !== index + 1) {
          cur.position = index + 1;

          return cur;
        }
        return null;
      })
      .filter((cur) => !!cur);

    return newArr;
  }

  /**
   * updateReorderedList
   * @param {array} arr - list of images to be updated and saved
   * @returns {void}
   */
  updateReorderedList(arr) {
    this.ImagesFactory.updateGroup(this.reorderList(arr))
      .then(() => this.updateParent())
      .catch(() =>
        this.NotificationService.notifyError(
          "Something went wrong when updating images. Please try again."
        )
      );
  }

  /**
   * findIndexOfType
   * @param {array} list - list of types
   * @param {string} imageType - string representing image of type
   * @returns {int} index
   */
  findIndexOfType(list, imageType) {
    return this._.findIndex(list, (cur) => cur.type === imageType);
  }

  /**
   * findIndexOfImageInType
   * @param {array} list - list of images
   * @param {object} image object
   * @returns {int} index
   *
   */
  findIndexOfImageInType(list, image) {
    return this._.findIndex(list, (cur) => cur.self === image.self);
  }

  /**
   * positionItem
   * @param {object} image - image that has changed
   * @param {string} type - name of new image type (optional, for images that are moving position)
   * @returns {void}
   */
  positionItem(image, type) {
    if (image.position < 1) {
      return this.NotificationService.notifyError(
        "You must enter a number equal or larger than 1."
      );
    }

    const currentType = type || image.type;
    const imageList = this.getListOfType(currentType);
    imageList.splice(this.findIndexOfImageInType(imageList, image), 1);

    if (!type) {
      image.position =
        image.position > imageList.length
          ? imageList.length + 1
          : image.position;

      this.ImagesFactory.update(image.self, image)
        .then((data) => {
          const newIndex = image.position - 1;
          imageList.splice(newIndex, 0, data);

          this.updateReorderedList(imageList);
        })
        .catch(() => `Something went wrong while updating ${image.title}.`);
    } else {
      image.position = this.counter(image.type) + 1;

      this.ImagesFactory.update(image.self, image).then(() =>
        this.updateReorderedList(imageList)
      );
    }
  }
}

const entityImageryPageComponent = {
  controller: EntityImageryPageCtrl,
  controllerAs: "imagery",
  template,
};

export default entityImageryPageComponent;
