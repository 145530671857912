import React from "react";
import PropTypes from "prop-types";

import { SanitizeHTML } from "skylarklib/helpers";

import styles from "./text-preview.scss";

/**
 * TextPreview - previewing text blocks in the editor in preview mode
 * @param {Object} props
 */
export default function TextPreview(props) {
  return (
    <p
      className={styles[`text-preview__${props.data.content_type}`]}
      dangerouslySetInnerHTML={{
        __html: SanitizeHTML.sanitize(props.data.content),
      }}
    />
  );
}

TextPreview.propTypes = {
  data: PropTypes.shape({
    content_type: PropTypes.string,
    content: PropTypes.string,
  }).isRequired,
};
