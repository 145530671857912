import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./entity-epg.html";

/**
 * @fileOverview  EPG controller
 * Renders EPG tab for channels.
 */

class EntityEPGPageCtrl {
  /**
   * @constructor
   * @param   {object} $scope
   * @param   {object} _
   * @param   {object} EntityFactory
   * @param   {object} ConfigurationFactory
   * @param   {object} MessageService
   * @param   {object} NotificationService
   * @param   {object} ApiFilterFactory
   * @param   {object} momentJS
   */
  constructor(
    $scope,
    _,
    EntityFactory,
    ConfigurationFactory,
    MessageService,
    NotificationService,
    ApiFilterFactory,
    momentJS,
    ModalService
  ) {
    this.$scope = $scope;
    this._ = _;
    this.EntityFactory = EntityFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.momentJS = momentJS;
    this.ModalService = ModalService;
    this.fieldToUse = "slots__between";

    this.init();
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    this._setupDataChannel();
    if (
      this.$scope.$parent.entity &&
      !this._.isEmpty(this.$scope.$parent.entity.data)
    ) {
      this._assignParentData(this.$scope.$parent.entity);
      this._buildModalOptions();
    }
  }

  /**
   * _assignParentData
   * @param {Object} parent from where to extract data
   * @returns {void}
   */
  _assignParentData(parent) {
    this.data = angular.extend({}, parent.data);
    this.channel = parent.channel;
    this.ConfigurationFactory.getTabConfiguration("channels", "epg").then(
      (config) => {
        this.config = config;
        this.dateInterval = this._buildInitialDate();
        this.dateFilter = this._buildDateFilter(this.dateInterval);
        this._setupSubscriptions();
        this._getItems();
      }
    );
  }

  /**
   * _setupDataChannel
   * @returns {void}
   */
  _setupDataChannel() {
    this.dataChannel = "channels.onData";
    this.MessageService.registerChannel(this.dataChannel);

    this.MessageService.on(this.dataChannel, (channel, parent) => {
      this._assignParentData(parent);
    });

    this.modalSaveChannel = "itemblock:channel-epgsaved";
    this.MessageService.registerChannel(this.modalSaveChannel);

    this.MessageService.on(this.modalSaveChannel, (channel, dataToSend) => {
      this.EntityFactory.createEntityItem("channels", this.data.uid, dataToSend)
        .then(() => {
          this.NotificationService.notifyInfo(
            NotificationMessage.addSuccess("EPG Item")
          );
          this._getItems();
        })
        .catch(() => {
          this.NotificationService.notifyError(
            NotificationMessage.addError("EPG Item")
          );
        });
    });

    this.modalEditChannel = "itemblock:channel-epgedited";
    this.MessageService.registerChannel(this.modalEditChannel);

    this.MessageService.on(this.modalEditChannel, (channel, data) => {
      this.EntityFactory.updateByUrl(
        `/api/channels/${this.data.uid}/items/${data.epgId}/`,
        data.dataToSend
      )
        .then(() => {
          this.NotificationService.notifyInfo(
            NotificationMessage.updateSuccess("EPG Item")
          );
          this._getItems();
        })
        .catch(() => {
          this.NotificationService.notifyError(
            NotificationMessage.updateError("EPG Item")
          );
        });
    });

    this.modalDeleteChannel = "itemblock:channel-epgdelete";
    this.MessageService.registerChannel(this.modalDeleteChannel);

    this.MessageService.on(this.modalDeleteChannel, (channel, data) => {
      this.EntityFactory.deleteByUrl(
        `/api/channels/${this.data.uid}/items/${data}/`
      )
        .then(() => {
          this.NotificationService.notifyInfo(
            NotificationMessage.deleteSuccess("EPG Item")
          );
          this._getItems();
        })
        .catch(() => {
          this.NotificationService.notifyError(
            NotificationMessage.deleteError("EPG Item")
          );
        });
    });
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe("DateRange.changeDate", (channel, data) => {
      this.dateInterval.start = data.start;
      this.dateInterval.end = data.end;

      this.dateFilter = this._buildDateFilter(data);
      this._getItems();
    });
  }

  /**
   * buildDateFilter
   * @param {Object} dateInterval
   * @returns {Object} API friendly filter object
   */
  _buildDateFilter(dateInterval) {
    const start = dateInterval.start.format();
    const end = dateInterval.end.format();
    const filterObject = {};
    filterObject[this.fieldToUse] = `${start},${end}`;

    return filterObject;
  }

  /**
   * _buildDateInterval
   * @returns {void}
   */
  _buildInitialDate() {
    const todaysStart = this.momentJS().startOf("day");
    const todaysEnd = this.momentJS().endOf("day");

    return {
      start: todaysStart,
      end: todaysEnd,
    };
  }

  /**
   * _getItems - get entity EPG items
   * @returns {void}
   */
  _getItems() {
    this.filters = this.ApiFilterFactory.appendToFilters(
      this.config,
      this.dateFilter
    );
    this.EntityFactory.getEntityItems("channels", this.data.uid, this.filters)
      .then((data) => {
        this.contentItems = data.objects;
        this.MessageService.publish("EntityEPG.dateChange", this.contentItems);
      })
      .catch(() => this.NotificationService.notifyRefresh());
  }

  /**
   * Builds the options for the modal
   * @private
   * @returns {void}
   */
  _buildModalOptions() {
    this.modalOptions = {
      channels: {
        createEpgItem: this.modalSaveChannel,
        editEpgItem: this.modalEditChannel,
        deleteEpgItem: this.modalDeleteChannel,
      },
      parent: {
        parentId: this.data.uid,
        parentTitle: this.data.title,
      },
      entity: "epg-item",
      has_datasource: true,
    };
  }

  /**
   * Opens EPG Modal to edit selected item
   * @param {Object} item
   */
  openEditEpgModal(item) {
    const { modalOptions } = this;
    this.ModalService.initNewModal(
      item,
      {},
      "epg",
      "epg-item",
      modalOptions
    ).then((modalUid) => {
      this._notifyContainer(modalUid);
    });
  }

  /**
   * notifyContainer
   * @returns {void}
   */
  _notifyContainer(modalUid) {
    this.MessageService.publish("skylark.initModal", modalUid);
  }
}

const entityEPGPageComponent = {
  controller: EntityEPGPageCtrl,
  controllerAs: "epg",
  template,
};

export default entityEPGPageComponent;
