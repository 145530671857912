import template from "./set-contents-banner.html";

class SetContentsBannerController {
  /**
   * @constructor
   * @param {Object} SetContentsActionCreators
   */
  constructor(
    $rootScope,
    SetContentsActionCreators,
    SetContentsStore,
    MessageService,
    ModalTriggerService,
    momentJS
  ) {
    this.$rootScope = $rootScope;
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.SetContentsStore = SetContentsStore;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.momentJS = momentJS;

    this._setupListeners();
    this._setupSubscriptions();
    this._buildNotificationOptions();
  }

  /**
   * Angular hook $onDestroy
   */
  $onDestroy() {
    this.MessageService.unregisterChannel(
      "Modal.Notification.Discard.SetContents"
    );
    this.MessageService.unregisterChannel(
      "Modal.Notification.Save.SetContents"
    );
  }

  /**
   * Registers store Listeners
   * @private
   */
  _setupListeners() {
    this.SetContentsStore.registerChangeListener(() => {
      this._updateLoadingState();
    });
  }

  /**
   * Setup pub subscriptions
   * @private
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      "Modal.Notification.Discard.SetContents",
      () => {
        this.discardChanges();
        this._togglePreviewMode();
      }
    );

    this.MessageService.subscribe("Modal.Notification.Save.SetContents", () => {
      this.saveChanges();
      this._togglePreviewMode();
    });
  }

  /**
   * _buildNotificationOptions
   * @returns {void}
   */
  _buildNotificationOptions() {
    this.notificationOptions = {
      notificationType: "discardChanges",
      channels: {
        alternative: "Modal.Notification.Discard.SetContents",
        confirm: "Modal.Notification.Save.SetContents",
      },
    };
  }

  /**
   * Update the loading state for this element
   * @private
   */
  _updateLoadingState() {
    const state = this.SetContentsStore.getState();
    this.isLoading = state.isLoading;
    this.isSaving = state.isSaving;
  }

  /**
   * ngClick for finishing editing
   */
  finishEditing() {
    if (this.hasChanged()) {
      this.ModalTriggerService.triggerNotification(this.notificationOptions);
    } else {
      this._togglePreviewMode();
    }
  }

  /**
   * Toggles Set contents out of edit mode
   * @private
   */
  _togglePreviewMode() {
    this.$rootScope.isEditMode = false;
    this.SetContentsActionCreators.togglePreviewMode();
    this.SetContentsActionCreators.fetchPreviewItems(
      this.momentJS().startOf("day").toISOString(),
      this.momentJS().endOf("day").toISOString()
    );
  }

  /**
   * @returns {boolean}
   */
  hasChanged() {
    const state = this.SetContentsStore.getState();

    return (
      !!state.items.find((item) => item.hasBeenUpdated || item.hasBeenAdded) ||
      state.removedItemUrls.length > 0
    );
  }

  /**
   * Discard changes and reload
   * @returns {void}
   */
  discardChanges() {
    this.isLoading = true;

    this.SetContentsActionCreators.discardChanges();
    this.SetContentsActionCreators.fetchSetData();
  }

  /**
   * Save changes to the set
   * @returns {void}
   */
  saveChanges() {
    if (!this.isSaving && !this.isLoading) {
      this.isSaving = true;
      this.isLoading = true;
      this.SetContentsActionCreators.saveSetData();
    }
  }
}

const setContentsBannerComponent = {
  template,
  controller: SetContentsBannerController,
  controllerAs: "component",
};

export default setContentsBannerComponent;
