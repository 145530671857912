/**
 * entity reducer
 * @param   {Object} state - article status
 * @param   {Object} action
 * @returns {Object}
 */
export default function (state = {}, action) {
  switch (action.type) {
    case "REQUEST_ARTICLE_STATUSES":
      return { ...state, isFetching: true };
    case "RECEIVE_ARTICLE_STATUSES":
      return { ...state, isFetching: false, data: action.data.objects };
    default:
      return state;
  }
}
