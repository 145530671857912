import angular from "angular";
import PropTypes from "prop-types";
import React, { Fragment, useMemo } from "react";
import { react2angular } from "react2angular";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import useAngularApply from "hooks/use-angular-apply";
import { isFunction } from "lodash";

const IngestIndicatorComponent = ({
  field,
  isEditing,
  ingestSourceId,
  ingestSourceFields,
  toggleDataSource,
  digest,
}) => {
  const [apply] = useAngularApply();

  /**
   * @param {Array<String>} ingestSourceFields - list of ingested field names
   * @param {Object} field - config for the field that may have a data source
   * @return {boolean}
   */
  const linked = useMemo(() => {
    if (!ingestSourceFields || !Array.isArray(ingestSourceFields)) {
      return false;
    }
    const names = field.names || [field.name];
    return names.some((name) => ingestSourceFields.includes(name));
  }, [ingestSourceFields, field]);

  /**
   * wrapper class name with active state
   * @param {boolean} isEditing - form edit mode
   * @return {String}
   */
  const activeClassName = useMemo(() => {
    const wrapperClass = "ingest-indicator__wrapper";
    return `${wrapperClass} ${isEditing ? "active" : ""}`;
  }, [isEditing]);

  /**
   * trigger toggle of data source fields when function is defined
   */
  const toggle = () => {
    if (isFunction(toggleDataSource)) {
      apply(toggleDataSource(field, isEditing));
    }
  };

  return ingestSourceId ? (
    <div
      className={activeClassName}
      onClick={toggle}
      data-test="ingest-indicator"
    >
      {linked && (
        <SkylarkIcon
          iconName={"link"}
          extraClassName={
            "ingest-indicator__icon ingest-indicator__icon--locked"
          }
        ></SkylarkIcon>
      )}
      {!linked && (
        <Fragment>
          <SkylarkIcon
            iconName={"unlink"}
            extraClassName={
              "ingest-indicator__icon ingest-indicator__icon--unlocked"
            }
          ></SkylarkIcon>
          <span className="ingest-indicator__text">
            Unlinked from Data Source
          </span>
        </Fragment>
      )}
    </div>
  ) : null;
};

IngestIndicatorComponent.propTypes = {
  field: PropTypes.object,
  ingestSourceId: PropTypes.string,
  ingestSourceFields: PropTypes.array,
  isEditing: PropTypes.bool,
  toggleDataSource: PropTypes.func,
};

const reactIngestIndicator = angular
  .module("Skylark.Components.React.IngestIndicator", [])
  .component("ingestIndicator", react2angular(IngestIndicatorComponent));

export default IngestIndicatorComponent;

export { reactIngestIndicator };
