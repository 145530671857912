import React, { Component } from "react";
import PropTypes from "prop-types";
import { convertToRaw } from "draft-js";
import classnames from "classnames";
import _ from "lodash";

import { ArticleMedia } from "skylarklib/helpers";

import { MODAL_TYPES } from "skylarklib/constants/modal.constants";

import Button from "components/_react/button/button";
import EditBanner from "components/_react/edit-banner/edit-banner";

import * as MediaHelpers from "./helpers/media-helpers/media-helpers";
import HTMLHelpers from "./helpers/html-helpers/html-helpers";

import TextEditor from "./text-editor/text-editor-container";
import EditorPreview from "./editor-preview/editor-preview";

import styles from "./editor.scss";

/**
 * Editor
 */
export default class Editor extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    configuration: PropTypes.shape({
      tab: PropTypes.object,
    }),
    entity: PropTypes.shape({
      data: PropTypes.shape({
        uid: PropTypes.string,
        self: PropTypes.string,
        items: PropTypes.array,
        title: PropTypes.string,
        article_type_url: PropTypes.string,
      }).isRequired,
      entityName: PropTypes.string,
      entityType: PropTypes.string,
    }).isRequired,
    editor: PropTypes.shape({
      hasChanged: PropTypes.bool,
      editorState: PropTypes.shape({
        getCurrentContent: PropTypes.func,
      }),
    }).isRequired,
    editingState: PropTypes.shape({
      isEditing: PropTypes.bool.isRequired,
    }).isRequired,
    toggleEditing: PropTypes.func.isRequired,
    saveArticle: PropTypes.func.isRequired,
    resetEditorState: PropTypes.func.isRequired,
    discardEditorChanges: PropTypes.func.isRequired,
    triggerModal: PropTypes.func.isRequired,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    configuration: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   * resetting the editor state here throws state away on language reload,
   * ensuring we do not get old data back
   */
  constructor(props) {
    super(props);

    this.uid = props.entity.data.uid;
    this.entity = props.entity.entityName;

    this.toggleEditingState = this.toggleEditingState.bind(this);
    this.finishEditing = this.finishEditing.bind(this);
    this.discard = this.discard.bind(this);
    this.save = this.save.bind(this);
    this.onModalSave = this.onModalSave.bind(this);
    this.onModalDiscard = this.onModalDiscard.bind(this);
    this.handleScrollEvents = this.handleScrollEvents.bind(this);
    this._calculateScrollPosition = this._calculateScrollPosition.bind(this);

    this.fixedControlsOffset = 48;
    this.stickyEvents = ["resize", "scroll"];

    this.state = {
      modalOptions: {},
      controlsFixed: false,
      isSaving: false,
    };

    this.props.resetEditorState();
  }

  /**
   * componentWillMount lifecycle hook
   */
  componentWillMount() {
    this._configureModal();
    this._addEventListeners();
  }

  /**
   * componentDidUpdate
   * @param  {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    if (
      !_.isEmpty(this.props.entity.data) &&
      _.isEmpty(this.props.configuration.data) &&
      !this.props.configuration.loading
    ) {
      this.props.fetchEditorConfig(
        this.props.entity.entityName,
        this.props.entity.data.article_type_slug
      );
    }

    if (
      !_.isEmpty(this.props.configuration.data) &&
      !_.isEmpty(this.props.configuration.data) &&
      !this.isMediaExpanded()
    ) {
      this.props.expandEditorMedia(
        this.props.entity.data,
        this.props.configuration.data
      );
    }
  }

  /**
   * componentWillUnMount lifecycle hook
   */
  componentWillUnmount() {
    this._removeEventListeners();
    if (this.props.editingState.isEditing) {
      this.discard();
      this.toggleEditor();
    }
  }

  /**
   * check if data needs to be expanded
   * @return {boolean}
   */
  isMediaExpanded() {
    return !this.props.entity.data.items.some(
      ({ media }) => typeof media === "string"
    );
  }

  /**
   * onModalSave
   * @param  {Object}  actions
   */
  onModalSave() {
    if (!this.state.isSaving) {
      this.save(this.entity, this.uid);
      this.toggleEditor();
    }
  }

  /**
   * setModalDiscardSubscription
   */
  onModalDiscard() {
    if (!this.state.isSaving) {
      this.discard();
      this.toggleEditor();
    }
  }

  /**
   * addEventListeners
   */
  _addEventListeners() {
    this.stickyEvents.forEach((event) =>
      window.addEventListener(event, this.handleScrollEvents)
    );
  }

  /**
   * _removeEventListeners
   */
  _removeEventListeners() {
    this.stickyEvents.forEach((event) =>
      window.removeEventListener(event, this.handleScrollEvents)
    );
  }

  /**
   * toggleSavingState
   */
  toggleIsSaving() {
    this.setState({
      isSaving: !this.state.isSaving,
    });
  }

  /**
   * configure the modal
   * @returns {void}
   */
  _configureModal() {
    const updatedState = Object.assign(this.state.modalOptions, {
      metadata: {
        alternative: this.onModalDiscard,
        confirm: this.onModalSave,
        name: "notification",
        location: "editorContainer",
        type: MODAL_TYPES.DISCARD_CHANGES,
      },
    });

    this.setState({ modalOptions: updatedState });
  }

  /**
   * toggle editing state
   */
  toggleEditingState() {
    this.props.toggleEditing();
  }

  /**
   * toggleEditor
   */
  toggleEditor() {
    this.toggleEditingState();
    this.toggleIsSaving();
  }

  /**
   * finish editing
   */
  finishEditing() {
    if (this._hasChanged()) {
      this.props.triggerModal(this.state.modalOptions);
    } else {
      this.toggleEditingState();
    }
  }

  /**
   * update entity
   */
  save() {
    const content = this.props.editor.editorState.getCurrentContent();
    const { entityMap } = convertToRaw(content);

    if (MediaHelpers.hasImageEntities(entityMap)) {
      this._handleImageRequests(entityMap).then((results) => {
        // https://stackoverflow.com/questions/48742791/draftjs-contentstate-getblockmap-is-not-a-function
        this.saveArticle(
          MediaHelpers.assignImagestoEntities(
            results,
            content
          ).getCurrentContent()
        );
      });
    } else {
      this.saveArticle(content);
    }
  }

  /**
   * saveArticle
   * @param  {Immutable.Record} contentState
   */
  saveArticle(contentState) {
    const rawContentState = convertToRaw(contentState);

    const types = rawContentState.blocks.map((block) => block.data.contentType);
    const items = this._isEmpty(types)
      ? []
      : HTMLHelpers.convertToHTML(contentState, types);

    this.props.saveArticle(
      this.props.entity.data.self,
      this.props.configuration.data,
      { items }
    );
  }

  /**
   * _handleImageRequests
   * @return {Array} Array of promises
   */
  _handleImageRequests(entities) {
    return Promise.all(this._processEntities(entities));
  }

  /**
   * _processEntities
   * @param  {Object} entities
   * @return {Array} Array of promises
   */
  _processEntities(entities) {
    const entityArray = Object.keys(entities).map((key) => entities[key]);

    return entityArray.map((entity) => {
      if (entity.type.toLowerCase() === "image" && entity.data.media.file) {
        return ArticleMedia.createImage(entity.data.media.file).then(
          (image) => image
        );
      }

      return Promise.resolve(entity).then((result) => result);
    });
  }

  /**
   * discard
   */
  discard() {
    this.props.discardEditorChanges(this.props.entity.data.title);
  }

  /**
   * an item is empty when its an empty array or it only contains placeholders
   * @param   {Array} types
   * @returns {Boolean}
   */
  _isEmpty(types) {
    return !types.length || types.every((type) => type === "placeholder");
  }

  /**
   * has editor state changed
   * @return {Boolean}
   */
  _hasChanged() {
    return this.props.editor.hasChanged;
  }

  /**
   * handleScrollEvents
   * @param {Object} event
   */
  handleScrollEvents(event) {
    const { target } = event;
    const { scrollTop } =
      target === window
        ? target.document.scrollingElement
        : target.documentElement;

    this._calculateScrollPosition(scrollTop);
  }

  /**
   * _calculateScrollPosition
   * @param  {number} docScrollPosition
   */
  _calculateScrollPosition(docScrollPosition) {
    const { top } = this.editorContainer.getBoundingClientRect();

    this._registerFixedControls({
      distanceFromTop: top,
      docScrollPosition,
    });
  }

  /**
   * _registerFixedControls
   * @param  {Number} distanceFromTop
   * @param  {Number} docScrollPosition
   */
  _registerFixedControls({ distanceFromTop, docScrollPosition }) {
    const editorPositionTop =
      docScrollPosition + distanceFromTop - this.fixedControlsOffset;
    const controlsFixed = docScrollPosition >= editorPositionTop;

    if (this.state.controlsFixed !== controlsFixed) {
      this.setState({ controlsFixed });
    }
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    if (!_.isEmpty(this.props.configuration.data)) {
      return (
        <div
          className={styles["editor-container"]}
          ref={(node) => (this.editorContainer = node)}
        >
          {!this.props.editingState.isEditing ? (
            <div className={styles["editor-container--preview"]}>
              <div
                className={classnames(styles["editor-container__header"], {
                  [styles["editor-container__header--fixed"]]:
                    this.state.controlsFixed,
                })}
              >
                <Button
                  buttonType="standard"
                  icon="pencil"
                  copy="Edit Article"
                  action={this.toggleEditingState}
                  id="edit-article"
                />
              </div>
              <EditorPreview data={this.props.entity.data.items} />
            </div>
          ) : (
            <div>
              <EditBanner
                hasChanged={!!this.props.editor.hasChanged}
                save={this.save}
                discard={this.discard}
                finish={this.finishEditing}
              />
              <div>
                <TextEditor
                  save={this.save}
                  items={this.props.entity.data.items}
                  controlsFixed={this.state.controlsFixed}
                />
              </div>
            </div>
          )}
        </div>
      );
    }

    return null;
  }
}
