import { combineReducers } from "redux";
import ReduceReducers from "reduce-reducers";
import { createResetDataApiReducer } from "store/api/api.reducer";
import {
  FETCH_ENTITY_CONFIG_BASE,
  FETCH_ENTITY_CONFIG_INFORMATION,
  FETCH_ENTITY_CONFIG_RELATIONSHIP,
  FETCH_ENTITY_CONFIG_VERSION,
  FETCH_ENTITY_CONFIG_EDITOR,
  IGNORE_THIS_TYPE,
  NAMESPACE,
  NONE_TYPE,
  RESET_ENTITIES_CONFIG,
  RESET_ENTITY_CONFIG,
} from "./entities-config.constant";

const initialState = {};

/**
 * Uses same action from entity-config. As of now, they are two different reducers
 * but they will merged into one.
 *
 */
const EntitiesConfigReducer = (state = initialState, action) => {
  const { type, entity, entityType = NONE_TYPE } = action;

  if (type.startsWith(NAMESPACE) && entity) {
    const currentConfig = state[entity] && state[entity][entityType];

    return {
      ...state,
      [entity]: {
        ...state[entity],
        [entityType]: ConfigReducer(currentConfig, action),
      },
    };
  }

  if (type === RESET_ENTITIES_CONFIG) {
    return initialState;
  }

  return state;
};

/**
 * creating slices for each config partition
 */
const baseConfigReducer = createResetDataApiReducer(
  {},
  FETCH_ENTITY_CONFIG_BASE
);
const informationConfigReducer = createResetDataApiReducer(
  {},
  FETCH_ENTITY_CONFIG_INFORMATION
);
const versionConfigReducer = createResetDataApiReducer(
  {},
  FETCH_ENTITY_CONFIG_VERSION
);
const relationshipConfigReducer = createResetDataApiReducer(
  {},
  FETCH_ENTITY_CONFIG_RELATIONSHIP
);
const editorConfigReducer = createResetDataApiReducer(
  {},
  FETCH_ENTITY_CONFIG_EDITOR
);

/**
 * combine the slices on one level
 */
const combineConfigReducer = combineReducers({
  base: baseConfigReducer,
  information: informationConfigReducer,
  version: versionConfigReducer,
  relationship: relationshipConfigReducer,
  editor: editorConfigReducer,
});

/**
 * Reset config reduce per configuration slice
 */
const ResetReducer = (state, action) => {
  if (action.type === RESET_ENTITY_CONFIG) {
    state = undefined;
  }
  return state;
};

const ConfigReducer = ReduceReducers({}, ResetReducer, combineConfigReducer);

export const defaultState = combineConfigReducer(
  {
    base: {},
    information: {},
    version: {},
    relationship: {},
    editor: {},
  },
  { type: IGNORE_THIS_TYPE }
);

export default EntitiesConfigReducer;
