import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./closed-caption.html";

/**
 * Isolated component for a closed-caption
 *
 * @class ClosedCaptionController
 *
 */
class ClosedCaptionController {
  /**
   * @constructor
   * @param {Object} _
   * @param {Object} NotificationService
   * @param {Object} EntityFactory
   * @param {Object} MetadataFactory
   * @param {Object} SchedulesFactory
   *
   */
  constructor(
    _,
    NotificationService,
    EntityFactory,
    MetadataFactory,
    SchedulesFactory
  ) {
    this._ = _;
    this.NotificationService = NotificationService;
    this.EntityFactory = EntityFactory;
    this.MetadataFactory = MetadataFactory;
    this.SchedulesFactory = SchedulesFactory;

    this.isTyping = false;
    this.entity = "closed-captions";
    this.api_field = "cc_urls";
  }

  /**
   * Creates the data object that we are going to insert into the request body,
   * for creating or updating the given field.
   * @returns {Promise}
   */
  _createRequestData(values) {
    const data = { ...values };
    data.language_code = this.field.language_code;
    data.asset_urls = [this.assetData.self];
    data.name = this.field.id;

    return this.SchedulesFactory.getAlwaysSchedule().then((schedule) => ({
      ...data,
      schedule_urls: [schedule.self],
    }));
  }

  /**
   * Handles the successful API response for creating/updating the data.
   * @param {String} successMessage
   * @param {Object} data
   * @returns {void}
   */
  handleResponse({ successMessage, data }) {
    this.assetData[this.api_field].push(data.self);
    this.data = data;
    this.NotificationService.notifyInfo(successMessage);
  }

  /**
   * Update or deletes the entity
   * if the field is empty and the resource already exists
   * it deletes the resource
   *
   * @returns {Promise}
   */
  onUpdate(values) {
    if (this._.isEmpty(values.url)) {
      if (this.data && this.data.self) {
        return this.EntityFactory.delete(this.entity, this.data.uid)
          .then(() => {
            this._.remove(
              this.assetData[this.api_field],
              (ccUrl) => ccUrl === this.data.self
            );
            this.data = null;
          })
          .catch(() =>
            this.NotificationService.notifyError(
              NotificationMessage.generalError
            )
          );
      }
    }

    return this._createRequestData(values)
      .then((data) => {
        if (data.self) {
          return this.MetadataFactory.updateEntity(
            this.entity,
            data,
            this.field.display_name
          );
        }

        return this.MetadataFactory.createEntity(
          this.entity,
          data,
          this.field.display_name
        );
      })
      .then((response) => this.handleResponse(response))
      .catch(() =>
        this.NotificationService.notifyError(NotificationMessage.urlError)
      );
  }
}

const closedCaptionComponent = {
  controller: ClosedCaptionController,
  controllerAs: "module",
  bindings: {
    data: "<ccUrlData", // needs to be data, because that is what the fields are referencing to
    ccUrlData: "<", // needs to be data, because that is what the fields are referencing to
    field: "<",
    index: "<",
    assetData: "<",
    hasAutoSave: "<",
  },
  template,
};

export default closedCaptionComponent;
