function TagsFactory($q, ApiService, ApiRequestConfigFactory) {
  const factory = {};

  factory.getTags = () =>
    ApiService.get(
      "/api/tags/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  factory.getByUrl = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  factory.save = (data) =>
    data.uid
      ? ApiService.put(data.self, data)
      : ApiService.post(
          "/api/tags/",
          data,
          ApiRequestConfigFactory.createRequestConfig({
            overrideGlobalLanguage: true,
          })
        );

  factory.getCategories = () =>
    ApiService.get(
      "/api/tag-categories/",
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  factory.getCategory = (url) =>
    ApiService.get(
      url,
      ApiRequestConfigFactory.createRequestConfig({
        useGlobalParams: true,
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  return factory;
}

export default TagsFactory;
