import mime from "mime-types";

/**
 * this service is communicating to Pelican, S3, in order to upload the asset and ingesting it into the system
 *
 * The upload is going through some steps which is in detail explained on the `upload` function.
 * During the upload the system is sending events via the MessagingService:
 *    `VideoUpload.Started` - when the upload is started
 *    `VideoUpload.Failed` - when at any step the upload process failed
 *    `VideoUpload.Completed` - when the upload is complete
 *    `VideoUpload.Progress` - after every chunk being uploaded
 *    `VideoUpload.IngestStarted` - when the upload to S3 is done and OVP started with ingesting the data
 *    `VideoUpload.Canceled` - when upload got canceled
 * all Messages contain `pelicanId`, `entityId` and `accountUrl`
 *
 * Messages which can be send to the service to intercept with running tasks
 *    `VideoUpload.Cancel` - will cancel the running process, when possible. Cancellation works only when upload to S3 and before
 */
class OvpAssetUploadService {
  /**
   *
   * @param $http
   * @param $q
   * @param $timeout
   * @param WindowService
   * @param ApiService
   * @param ApiRequestConfigFactory
   * @param MessageService
   * @param OvpAssetUploadStoreService
   * @param UtilitiesService
   * @param OVP_ASSET_UPLOAD
   * @param AmplifyService
   * @param NotificationService
   * @param _
   */
  constructor(
    $http,
    $q,
    $timeout,
    WindowService,
    ApiService,
    ApiRequestConfigFactory,
    MessageService,
    OvpAssetUploadStoreService,
    UtilitiesService,
    OVP_ASSET_UPLOAD,
    AmplifyService,
    NotificationService,
    _
  ) {
    this.$http = $http;
    this.$q = $q;
    this.$timeout = $timeout;
    this.WindowService = WindowService;
    this.ApiService = ApiService;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
    this.MessageService = MessageService;
    this.OvpAssetUploadStoreService = OvpAssetUploadStoreService;
    this.UtilitiesService = UtilitiesService;
    this.OVP_ASSET_UPLOAD = OVP_ASSET_UPLOAD;
    this.AmplifyService = AmplifyService;
    this.NotificationService = NotificationService;
    this._ = _;
  }

  /**
   * kick off upload by using entities information and the file
   * passing data to Pelican for creating a pre-signed Url
   * uploading chunks of file to signed url until file is completely uploaded
   * updated of progress after each chunk
   *
   * upload is not finished yet, the bucket url will be passed to the provider for video processing
   * updating of progress in a set interval
   *
   * In case of failure
   * promise will be rejected and the video reference is cleaned up from pelican
   * @param {File} file
   * @param {Object} options
   * @param {String} options.entityId
   * @param {String} options.accountUrl
   * @param {Number} [options.pelicanId]
   * @param {String} options.provider
   * @returns {Promise}
   */
  upload(file, options) {
    const { entityId, accountUrl, provider } = options;
    let { pelicanId } = options;
    const cancelable = this.$q.defer();
    const { size: fileSize, name: fileName } = file;
    let cancelled = false;
    const retryErrorMessage = "Upload to S3 Failed";

    if (!(file instanceof File)) {
      return this.$q.reject(new TypeError("file is no instance of File"));
    }

    if (!accountUrl) {
      return this.$q.reject(new TypeError("accountUrl is undefined"));
    }

    if (!entityId) {
      return this.$q.reject(new TypeError("entityId is undefined"));
    }

    if (
      __V8_ENVIRONMENT__ &&
      this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.PROVIDERS.includes(provider)
    ) {
      const workflowServiceOptions = {
        ...options,
        pelicanId: entityId,
      };
      return this.uploadWorkflowService(file, workflowServiceOptions)
        .then(() => this.completeWorkflowServiceUpload(workflowServiceOptions))
        .then(() =>
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
            workflowServiceOptions
          )
        )
        .catch((err) => {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
            {
              ...workflowServiceOptions,
              status_description: this._.has(err, "message")
                ? err.message
                : err,
            }
          );
        });
    }

    let fetchingUploadBody;
    if (pelicanId) {
      fetchingUploadBody = this.isRetryPossible(pelicanId);
    } else {
      if (!provider) {
        return this.$q.reject(new TypeError("provider is undefined"));
      }

      fetchingUploadBody = this.createPresignedUrl({
        entityId,
        accountUrl,
        file,
        provider,
      });
    }

    return fetchingUploadBody
      .then((uploadBody) => {
        pelicanId = uploadBody.id;
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
          {
            pelicanId,
            entityId,
            accountUrl,
            fileSize,
            fileName,
            provider,
            file,
          }
        );

        this.WindowService.attachOnBeforeUnloadWarning(pelicanId);
        this.MessageService.subscribe(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD,
          (
            channel,
            {
              pelicanId: _pelicanId,
              entityId: _entityId,
              accountUrl: _accountUrl,
            }
          ) => {
            if (
              entityId === _entityId &&
              pelicanId === _pelicanId &&
              accountUrl === _accountUrl
            ) {
              cancelable.resolve();
              cancelled = true;
            }
          }
        );
        this.MessageService.lockChannel(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD
        );

        return this.uploadUntilComplete(file, uploadBody, cancelable.promise);
      })
      .then(null, null, (progress) => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
          {
            pelicanId,
            entityId,
            accountUrl,
            progress: {
              percentage: progress,
              status: this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
            },
          }
        );
      })
      .catch((error) => {
        if (cancelled) {
          return this.$q.reject(error);
        }

        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
          { pelicanId, entityId, accountUrl, file }
        );

        return this.$q.reject(new TypeError(retryErrorMessage));
      })
      .then(() => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
          {
            pelicanId,
            entityId,
            accountUrl,
            provider,
          }
        );

        this.WindowService.detachOnBeforeUnloadWarning(pelicanId);

        return this.setUploadComplete(pelicanId);
      })
      .then(() => this.pullStatusUntilIngestComplete(pelicanId))
      .then(() =>
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
          { pelicanId, entityId, accountUrl }
        )
      )
      .then(() => {
        if (pelicanId) {
          return this.cleanUpUpload(pelicanId);
        }
      })
      .catch((error) => {
        if (error && error.message === retryErrorMessage) {
          return this.$q.reject(error);
        }

        this.WindowService.detachOnBeforeUnloadWarning(pelicanId);

        if (cancelled) {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
            { pelicanId, entityId, accountUrl }
          );

          return this.$q.resolve();
        }

        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
          {
            pelicanId,
            entityId,
            accountUrl,
            status_description: this._.has(error, "message")
              ? error.message
              : error,
          }
        );

        if (pelicanId) {
          this.cleanUpUpload(pelicanId);
        }

        return this.$q.reject(error);
      });
  }

  /**
   * reingestWorkflowServiceAsset - moves a processed asset back to the root of the bucket so that it is reingested by the workflow-service
   * @param {*} assetId
   */
  reingestWorkflowServiceAsset(assetId, params, originalOvpAsset) {
    if (__V8_ENVIRONMENT__) {
      this.MessageService.publish(
        this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
        params
      );
      return this.AmplifyService.copyProcessedAssetToRoot(assetId)
        .then(() => {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
            params
          );

          return this.pullStatusUntilIngestComplete(
            params.pelicanId,
            params.accountUrl,
            Date.now()
          );
        })
        .then(() =>
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
            params
          )
        )
        .catch((err) => {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
            params
          );
          this.NotificationService.notifyError(
            `Re-Encoding failed: ${err.message}`
          );
        });
    }
  }

  /**
   *
   * Uploads a given file to the workflow-service's S3 watchfolder using aws-amplify
   *
   * @param {File} file
   * @param {Object} options
   * @param {String} options.entityId
   * @param {String} options.accountUrl
   * @param {String} options.provider
   * @returns {Promise}
   */
  uploadWorkflowService(file, { entityId, accountUrl, provider }) {
    const defaultMessageBody = {
      entityId,
      accountUrl,
      provider,
      pelicanId: entityId,
    };

    const { size: fileSize } = file;
    // Don't use filename in objectKey in case unsupported characters are given
    const now = new Date();
    let mimeFileExtension = mime.extension(file.type) || "";
    if (mimeFileExtension === "qt") {
      // Workaround for .qt file extensions as they have been deprecated in favour of .mov
      // https://github.com/jshttp/mime-types/issues/90
      mimeFileExtension = "mov";
    } else if (mimeFileExtension === "mpga") {
      // workaround for renaming mpga file to mp3. The mime-type for mp3 is returned as mpga
      mimeFileExtension = "mp3";
    }

    const objectKey = `${entityId}-${now.valueOf()}.${mimeFileExtension}`;

    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
      {
        ...defaultMessageBody,
        fileSize,
        fileName: objectKey,
        file,
      }
    );

    const uploadViaAmplify = this.AmplifyService.upload(objectKey, file, {
      metadata: {
        asset: JSON.stringify({
          uid: entityId,
        }),
        "encoded-original-filename": encodeURIComponent(file.name),
        "original-upload-timestamp-iso": now.toISOString(),
        "upload-method": "skylark-classic-ui",
      },
      tagging: `assetId=${entityId}`,
      progressCallback: (progress) => {
        if (progress.loaded !== progress.total) {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
            {
              ...defaultMessageBody,
              progress: {
                percentage: progress.loaded / progress.total,
                status: this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
              },
            }
          );
        }
      },
      errorCallback: () => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED_S3,
          { ...defaultMessageBody, file }
        );
      },
    });

    const cancelMessageCallback = (c, p) => {
      if (entityId === p.entityId && accountUrl === p.accountUrl) {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
          defaultMessageBody
        );
        this.AmplifyService.cancelUpload(uploadViaAmplify, "upload cancelled");
      }
    };

    // Subscribe to cancel events
    this.MessageService.subscribe(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD,
      cancelMessageCallback
    );

    uploadViaAmplify.then(() => {
      this.MessageService.off(
        this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.CANCEL_UPLOAD,
        cancelMessageCallback
      );

      this.MessageService.publish(
        this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_PROGRESS,
        {
          ...defaultMessageBody,
          progress: {
            percentage: 100,
            status: this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
          },
        }
      );
    });

    return uploadViaAmplify;
  }

  completeWorkflowServiceUpload(data) {
    const { pelicanId, accountUrl } = data;

    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
      {
        pelicanId,
      }
    );

    // Allow the Workflow Service time to detect the file has been uploaded
    return this.UtilitiesService.delayPromise(
      this.OVP_ASSET_UPLOAD.PELICAN.INITIAL_DELAY
    ).then(() => this.pullStatusUntilIngestComplete(pelicanId, accountUrl));
  }

  /**
   *
   * We send the url to pelican as the `filename`
   * and after we trigger the upload and the ingest start.
   * This is because we don't have to actually upload a file
   * but basically need to wait pelican to ingest it
   *
   * @param {string} url
   * @param {string} entityId
   * @param {string} accountUrl
   * @param {string} provider
   * @return {Promise}
   */
  uploadFileFromUrl({ url, entityId, accountUrl, provider }) {
    let pelicanId = null;

    if (!provider) {
      return this.$q.reject(new TypeError("provider is undefined"));
    }

    return this.uploadViaUrl({ url, entityId, accountUrl, provider })
      .then((uploadBody) => {
        pelicanId = uploadBody.id;
        const { filename: fileName } = uploadBody;
        const messagePayload = {
          pelicanId,
          entityId,
          accountUrl,
          fileName,
          provider,
        };

        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
          messagePayload
        );
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
          messagePayload
        );

        return this.setUploadComplete(pelicanId);
      })
      .then(() => this.pullStatusUntilIngestComplete(pelicanId))
      .then(() =>
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
          {
            pelicanId,
            entityId,
            accountUrl,
          }
        )
      )
      .catch((error) => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
          {
            pelicanId,
            entityId,
            accountUrl,
            status_description: this._.has(error, "message")
              ? error.message
              : error,
          }
        );
        return this.$q.reject(error);
      });
  }

  /**
   * Recursive call, resolves when complete or rejected at any time
   * it will send a promise.notify after each upload with a number between 0 - 1 indicating the upload progress in percentage
   *
   * @param {File} file
   * @param {Integer} id - pelican id
   * @param {Object} upload_request
   * @param {Integer} upload_request.bytes_start
   * @param {Integer} upload_request.bytes_count
   * @param {Promise} [timeout] - a promise on resolve abort http request
   * @returns {Promise}
   */
  uploadUntilComplete(file, { id, upload_request: uploadRequest }, timeout) {
    const deferred = this.$q.defer();
    const {
      bytes_start: bytesStart,
      bytes_count: bytesCount,
      url,
      method,
      headers,
    } = uploadRequest;
    const sliceStart = bytesStart;
    const sliceEnd = bytesStart + bytesCount;
    const nextChunk = file.slice(sliceStart, sliceEnd, file.type);

    this.uploadToSignedUrl({ url, method, headers, data: nextChunk, timeout })
      .then(({ headers, statusText }) => {
        const ETag = headers("ETag");
        const response = {
          headers: {
            ETag,
          },
          content: statusText,
        };

        if (!ETag) {
          return this.$q.reject(
            new TypeError("ETag is not exposed or is null")
          );
        }

        return this.updateProgress(id, {
          response,
          bytes_start: bytesStart,
          bytes_count: bytesCount,
        });
      })
      .then(null, null, (chunkProgress) => {
        const uploaded = bytesStart / file.size;
        const deltaUploaded = (nextChunk.size / file.size) * chunkProgress;
        deferred.notify(uploaded + deltaUploaded);
      })
      .then((uploadBody) => {
        const { upload_request: uploadRequest } = uploadBody;

        if (uploadRequest === null) {
          deferred.resolve();
        } else {
          return this.uploadUntilComplete(file, uploadBody, timeout).then(
            deferred.resolve,
            deferred.reject,
            deferred.notify
          );
        }
      }, deferred.reject);

    return deferred.promise;
  }

  /**
   * Uses the information from Pelican to make an upload
   *
   * @param {String} url - pre-signed url where to upload
   * @param {String} method - the upload method (PUT, POST)
   * @param {Blob} data
   * @param {Object} headers
   * @param {Promise} [timeout] - a promise on resolve abort http request
   * @returns {Promise}
   */
  uploadToSignedUrl({ url, method, data, headers, timeout }) {
    const deferred = this.$q.defer();
    const allowedMethods = ["PUT", "POST"];
    delete headers["Content-Length"];

    if (!allowedMethods.includes(method)) {
      return this.$q.reject(new TypeError(`Method ${method} is not supported`));
    }

    this.$http({
      url,
      headers,
      data,
      method,
      timeout,
      uploadEventHandlers: {
        progress: ({ lengthComputable, loaded, total }) => {
          if (navigator.onLine && lengthComputable) {
            const percent = loaded / total;
            deferred.notify(percent);
          }
        },
      },
    })
      .then(deferred.resolve)
      .catch(deferred.reject);

    return deferred.promise;
  }

  /**
   * after the upload to bucket is complete, direct information of progress is not available,
   * pulling updates from pelican what the ingest status of the video is. This function will call itself until
   * upload is complete or failed
   *
   * when uploaded stuck in a loop and had no changes for a while then we abort the pull
   *
   * when upload is complete the promise will resolve and dispatch message `VideoUpload.Complete` with parameter ID
   *
   * @param {Integer} pelicanId
   * @param {String} workflowServiceAccountUrl - The Account URL used when uploading via the workflow-service
   * @param {Integer} [started] - timestamp of the start of pulling loop
   * @returns {Promise}
   */
  pullStatusUntilIngestComplete(
    pelicanId,
    workflowServiceAccountUrl = "",
    started = Date.now(),
    seenToBeProcessing = false,
    timesSeenAsCompleted = 0
  ) {
    const isWorkflowUpload = workflowServiceAccountUrl !== "";
    return this.getUploadStatus(pelicanId, isWorkflowUpload)
      .then((responseBody) => {
        if (isWorkflowUpload) {
          if (this._.has(responseBody, "ingest_status")) {
            const { ingest_status, ovps } = responseBody;

            if (
              ingest_status ===
              this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.COMPLETED
            ) {
              const matchingOvpObject = ovps.find(
                ({ account_url }) => account_url === workflowServiceAccountUrl
              );
              if (matchingOvpObject) {
                // If seenToBeProcessing is still false after receiving the COMPLETED status multiple times, mark the sync as completed
                const ignoreSeenToBeProcessingFlag = timesSeenAsCompleted > 19;
                if (seenToBeProcessing || ignoreSeenToBeProcessingFlag) {
                  return this.$q.resolve(matchingOvpObject);
                }
                timesSeenAsCompleted++;
              }
            } else if (
              ingest_status ===
              this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.FAILED
            ) {
              const errMessage = this._.has(responseBody, "status_description")
                ? responseBody.status_description
                : `ingest of Id ${pelicanId} failed`;
              return this.$q.reject(new Error(errMessage));
            } else if (
              ingest_status ===
                this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.QUEUED ||
              ingest_status ===
                this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.PROCESSING
            ) {
              seenToBeProcessing = true;
              // Only change the UI to processing once the workflow service status changes
              this.MessageService.publish(
                this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
                {
                  pelicanId,
                }
              );
            }
          }
        } else {
          const { status, error_message: errorMessage } = responseBody;
          if (
            status === this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.INGEST_ERROR
          ) {
            return this.$q.reject(
              new Error(errorMessage || `ingest of Id ${pelicanId} failed`)
            );
          }

          if (status === this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.COMPLETE) {
            return this.$q.resolve();
          }
        }

        return this.UtilitiesService.delayPromise(
          this.OVP_ASSET_UPLOAD.PELICAN.FETCH_INTERVAL
        ).then(() =>
          this.pullStatusUntilIngestComplete(
            pelicanId,
            workflowServiceAccountUrl,
            started,
            seenToBeProcessing,
            timesSeenAsCompleted
          )
        );
      })
      .catch((error) => {
        if (navigator.onLine) {
          return this.$q.reject(error);
        }

        return this.UtilitiesService.delayPromise(
          this.OVP_ASSET_UPLOAD.PELICAN.FETCH_INTERVAL
        ).then(() =>
          this.pullStatusUntilIngestComplete(
            pelicanId,
            workflowServiceAccountUrl,
            started,
            seenToBeProcessing,
            timesSeenAsCompleted
          )
        );
      });
  }

  /**
   * requests from Pelican with the options a new signed url
   *
   * @param {File} file
   * @param {String} entityId
   * @param {String} accountUrl
   * @param {String} provider
   * @returns {Promise}
   */
  createPresignedUrl({ file, entityId, accountUrl, provider }) {
    const storage = this.OVP_ASSET_UPLOAD.PELICAN.STORAGE.INTERNAL;

    return this.postToPelican({
      name: file.name,
      size: file.size,
      entityId,
      accountUrl,
      storage,
      provider,
    });
  }

  /**
   * @param {String} url - the url of the file you want to upload
   * @param {String} entityId
   * @param {String} accountUrl
   * @param {String} provider
   * @returns {Promise}
   */
  uploadViaUrl({ url, entityId, accountUrl, provider }) {
    const storage = this.OVP_ASSET_UPLOAD.PELICAN.STORAGE.EXTERNAL;

    return this.postToPelican({
      name: url,
      size: 0,
      entityId,
      accountUrl,
      storage,
      provider,
    });
  }

  /**
   * @param {String} name - the filename or the url
   * @param {Number} size - size of the file if available
   * @param {String} entityId
   * @param {String} accountUrl
   * @param {String} storage - S3 or External, for now.\
   * @param {String} provider
   * @returns {Promise}
   */
  postToPelican({ name, size, entityId, accountUrl, storage, provider }) {
    return this.ApiService.post(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/`,
      {
        skylark_parent_type: this.OVP_ASSET_UPLOAD.PELICAN.PARENT_TYPE,
        skylark_type: this.OVP_ASSET_UPLOAD.PELICAN.UPLOAD_TYPE,
        skylark_parent_id: entityId,
        skylark_attributes: [
          {
            key: "account_url",
            value: accountUrl,
          },
        ],
        storage,
        provider,
        provider_config:
          this.OVP_ASSET_UPLOAD.PELICAN.PROVIDER_CONFIG[provider],
        filename: name,
        file_size: size,
        keep_storage:
          provider === "bitmovin" &&
          storage !== this.OVP_ASSET_UPLOAD.PELICAN.STORAGE.EXTERNAL,
      }
    );
  }

  /**
   *
   * fetches pending uploads and is filtering by entityId and accountUrl
   * this mechanism is helpful to validate that not another video upload is triggered
   * while and upload is still in progress,
   * as only one asset per account for a single entity is allowed
   *
   * @param {String} entityId
   * @param {String} accountUrl
   * @returns {Promise<Object>}
   */
  getUploadByEntityAndAccount({ entityId, accountUrl }) {
    return this.getPendingUploads(entityId).then((pendingUploads) => {
      const matchingUpload = pendingUploads.results.filter((upload) => {
        const uploadAccountUrl = this.getAccountUrlFromResponseBody(upload);
        const isSameEntity = upload.skylark_parent_id === entityId;
        const isSameAccountUrl = uploadAccountUrl === accountUrl;
        const isProcessing = [
          this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.CREATED,
          this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
          this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.UPLOADED,
        ].includes(upload.status);

        return isSameEntity && isSameAccountUrl && isProcessing;
      });

      return matchingUpload[0];
    });
  }

  /**
   * before allowing another upload, a check is need if one is already running
   * when one upload is still handled by an event, than soft recovering the state
   * when one upload is not handled by an event but still processed by pelican, than hard recovering the state
   * and continue processing the upload
   *
   * @param {String} entityId
   * @param {String} accountUrl
   * @returns {Promise}
   */
  recoverPendingUpload({ entityId, accountUrl }) {
    return this.getUploadByEntityAndAccount({ entityId, accountUrl }).then(
      (responseBody) => {
        if (!responseBody) {
          return;
        }

        const { id: pelicanId, status, provider } = responseBody;

        if (this.OvpAssetUploadStoreService.getUpload(pelicanId)) {
          return;
        }

        return this.hardRecoverUpload({
          pelicanId,
          status,
          entityId,
          accountUrl,
          filename: undefined,
          file_size: undefined,
          provider,
        });
      }
    );
  }

  /**
   * before allowing another workflow service upload, a check is need if one is already running
   * when the workflow service says that an ingest is queued or processing then restart waiting for it to complete
   * otherwise if an error has happened, display it
   *
   * @param {String} entityId
   * @param {String} account
   * @returns {Promise}
   */
  recoverWorkflowServiceUpload(entityId, account) {
    const accountUrl = account.self;
    const pelicanId = entityId;

    return this.getUploadStatus(entityId, true).then((responseBody) => {
      // Do nothing if ingest_status does not exist
      if (this._.has(responseBody, "ingest_status")) {
        const { ingest_status } = responseBody;

        const uploadStartedBody = {
          pelicanId,
          entityId,
          accountUrl,
          fileName: entityId,
          provider: account.slug,
        };

        if (
          ingest_status ===
            this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.QUEUED ||
          ingest_status ===
            this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.PROCESSING
        ) {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
            uploadStartedBody
          );
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
            {
              entityId,
              accountUrl,
              pelicanId,
            }
          );
          this.pullStatusUntilIngestComplete(pelicanId, accountUrl)
            .then(() =>
              this.MessageService.publish(
                this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
                { pelicanId, entityId, accountUrl }
              )
            )
            .catch((error) => {
              this.MessageService.publish(
                this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
                {
                  pelicanId,
                  entityId,
                  accountUrl,
                  status_description: this._.has(error, "message")
                    ? error.message
                    : error,
                }
              );
            });
        } else if (
          ingest_status ===
          this.OVP_ASSET_UPLOAD.WORKFLOW_SERVICE.INGEST_STATUS.FAILED
        ) {
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_STARTED,
            uploadStartedBody
          );
          const errMessage = this._.has(responseBody, "status_description")
            ? responseBody.status_description
            : `ingest of Id ${entityId} failed`;
          this.MessageService.publish(
            this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
            {
              pelicanId: entityId,
              entityId,
              accountUrl,
              status_description: errMessage,
            }
          );
        }
      }
    });
  }

  /**
   * a hard recovery is needed when page got refreshed and no job is running for continuing the upload
   * the upload can not be recovered when it is in state of `created`, `in_progress`, `ingest_error`
   *
   * when upload is in state `uploaded` than next step is waiting until ingest is finished
   *
   * @param pelicanId
   * @param status
   * @param entityId
   * @param accountUrl
   * @param filename
   * @param fileSize
   * @param provider
   * @returns {Promise}
   */
  hardRecoverUpload({
    pelicanId,
    status,
    entityId,
    accountUrl,
    filename: fileName,
    file_size: fileSize,
    provider,
  }) {
    const recoverImpossible = [
      this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.CREATED,
      this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
      this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.INGEST_ERROR,
    ].includes(status);

    if (recoverImpossible) {
      return this.cleanUpUpload(pelicanId);
    }
    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.INGEST_STARTED,
      {
        pelicanId,
        entityId,
        accountUrl,
        fileName,
        fileSize,
        provider,
      }
    );

    return this.pullStatusUntilIngestComplete(pelicanId)
      .then(() => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_COMPLETE,
          {
            pelicanId,
            entityId,
            accountUrl,
          }
        );
      })
      .catch((error) => {
        this.MessageService.publish(
          this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_FAILED,
          {
            pelicanId,
            entityId,
            accountUrl,
            status_description: this._.has(error, "message")
              ? error.message
              : error,
          }
        );
        return this.$q.reject(error);
      })
      .finally(() => {
        if (pelicanId) {
          return this.cleanUpUpload(pelicanId);
        }
      });
  }

  /**
   * an upload can be continued when the status is in progress,
   *
   * @param {Integer} pelicanId
   * @returns {Promise} uploadBody | reject with error
   */
  isRetryPossible(pelicanId) {
    return this.getUploadStatus(pelicanId).then((uploadBody) => {
      const { status } = uploadBody;
      const statusesWithRetryPossibility = [
        this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.CREATED,
        this.OVP_ASSET_UPLOAD.PELICAN.STATUS_TYPES.IN_PROGRESS,
      ];

      if (!statusesWithRetryPossibility.includes(status)) {
        return this.$q.reject(new Error("Sorry, upload cannot be continued"));
      }

      return uploadBody;
    });
  }

  /**
   * cancel an Upload when it stopped uploading, for example it failed but waits for retry
   *
   * @param {Integer} pelicanId
   * @param {String} entityId
   * @param {String} accountUrl
   */
  cancelStoppedUpload(pelicanId, entityId, accountUrl) {
    this.cleanUpUpload(pelicanId);
    this.WindowService.detachOnBeforeUnloadWarning(pelicanId);
    this.MessageService.publish(
      this.OVP_ASSET_UPLOAD.MESSAGE_SERVICE.UPLOAD_CANCELED,
      { pelicanId, entityId, accountUrl }
    );
  }

  /**
   * get all running or not cleaned up uploads from Pelican
   *
   * @param {integer} id
   * @returns {Promise}
   */
  getUploadStatus(id, isWorkflowUpload = false) {
    if (isWorkflowUpload) {
      return this.ApiService.get(`/api/assets/${id}/`);
    }
    return this.ApiService.get(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/${id}/`
    );
  }

  /**
   * set update progress of file
   *
   * @param {Integer} id
   * @param {Object} progressStatus - needs header and content of bucket upload response, header requires ETag
   * @param {Object} progressStatus.response
   * @param {Object} progressStatus.response.headers
   * @param {String} progressStatus.response.headers.ETag
   * @param {*} progressStatus.response.content
   * @param {Integer} progressStatus.bytes_start
   * @param {Integer} progressStatus.bytes_count
   * @returns {Promise}
   */
  updateProgress(id, progressStatus) {
    return this.ApiService.post(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/${id}/update_progress/`,
      progressStatus
    );
  }

  /**
   * let Pelican know that the upload to bucket is complete, so that the file can be build together
   *
   * @param {Integer} id
   * @returns {Promise}
   */
  setUploadComplete(id) {
    return this.ApiService.post(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/${id}/complete/`
    );
  }

  /**
   * when upload fails, is canceled, or complete
   * let Pelican now that we can remove that object from the service
   *
   * @param {Integer} id
   * @returns {Promise}
   */
  cleanUpUpload(id) {
    return this.ApiService.delete(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/${id}/`
    );
  }

  /**
   * get all pending uploads, this will be all uploads which have been not cleaned up
   *
   * @returns {Promise}
   */
  getPendingUploads(entityId) {
    let config;
    if (entityId) {
      config = this.ApiRequestConfigFactory.createRequestConfig({
        requestConfig: {
          params: {
            skylark_parent_id: entityId,
          },
        },
      });
    }

    return this.ApiService.get(
      `${this.OVP_ASSET_UPLOAD.PELICAN.PELICAN_URL}/uploads/`,
      config
    );
  }

  /**
   * helper for retrieving the AccountUrl from response body
   *
   * @param {Object} responseBody
   * @param {Object} responseBody.skylark_attributes
   * @param {String} responseBody.skylark_attributes.key
   * @param {String} responseBody.skylark_attributes.value
   * @returns {String}
   */
  getAccountUrlFromResponseBody({ skylark_attributes }) {
    return skylark_attributes
      .map((attribute) =>
        attribute.key === "account_url" ? attribute.value : null
      )
      .find((attribute) => attribute);
  }
}

export default OvpAssetUploadService;
