import { setCurrentEntity } from "store/entity/entity.action";

class TransitionService {
  constructor($transitions, $ngRedux) {
    this.$transitions = $transitions;
    this.$ngRedux = $ngRedux;
  }

  init() {
    this.$transitions.onSuccess({}, this.updateEntity.bind(this));
  }

  updateEntity(transition) {
    const stateParams = transition.params("to");
    const state = transition.to();
    const entity = state.title || stateParams.entity;
    const { entityType } = stateParams;
    const entityId = stateParams.id;

    this.$ngRedux.dispatch(setCurrentEntity(entity, entityType, entityId));
  }
}

export default TransitionService;
