import template from "./date-time-range-field.html";

class DateTimeRangeFieldCtrl {
  /**
   * @constructor
   * @param {Object} DateTimeService
   * @param {Object} MessageService
   * @param {Object} momentJS
   */
  constructor(DateTimeService, MessageService, momentJS) {
    this.DateTimeService = DateTimeService;
    this.MessageService = MessageService;
    this.momentJS = momentJS;

    this._onDateAndTimeUpdate = this._onDateAndTimeUpdate.bind(this);
    this._onUpdateFields = this._onUpdateFields.bind(this);

    this.setupSubscriptions();
  }

  /**
   * Set up pub/sub subscriptions
   * @returns {void}
   */
  setupSubscriptions() {
    this.MessageService.subscribe(
      `DateAndTime.update.${this.item.uuid}`,
      this._onDateAndTimeUpdate
    );
    this.MessageService.subscribe("UpdateFields", this._onUpdateFields);
  }

  removeSubscriptions() {
    this.MessageService.off(
      `DateAndTime.update.${this.item.uuid}`,
      this._onDateAndTimeUpdate
    );
    this.MessageService.off("UpdateFields", this._onUpdateFields);
  }

  /**
   * Initialise the component
   * @returns {void}
   */
  $onInit() {
    this._initialiseValues();
  }

  /**
   * $onDestroy - native angular
   * @returns {void}
   */
  $onDestroy() {
    this.removeSubscriptions();
    this._resetData({});
  }

  /**
   * Updates the start and end timecodes
   * @private
   * @returns {void}
   */
  _updateData() {
    const isEditing =
      (this.module.isNewEntity || this.form.editing) &&
      !this.module.ignoreResetEnd;
    if (!this.isForever && isEditing) {
      this._endToEqualStart();
    }

    if (this.config.start_field) {
      this.data[this.config.start_field] = this.DateTimeService.getValueByUUID(
        this.item.uuid,
        "start",
        "combined"
      );
    }

    if (this.config.end_field) {
      this.data[this.config.end_field] = this.DateTimeService.getValueByUUID(
        this.item.uuid,
        "end",
        "combined"
      );
    }
  }

  /**
   * on date and time update
   * @private
   */
  _onDateAndTimeUpdate() {
    this._updateData();
  }

  /**
   * on update Fields
   * @param ch
   * @param data
   * @private
   */
  _onUpdateFields(ch, data) {
    this._resetData(data);
  }

  /**
   * _updateEndDate description
   * @returns {void}
   */
  _endToEqualStart() {
    const startCombined = this.DateTimeService.getValueByUUID(
      this.item.uuid,
      "start",
      "combined"
    );
    const endCombined = this.DateTimeService.getValueByUUID(
      this.item.uuid,
      "end",
      "combined"
    );

    const isAfter = this.momentJS(startCombined).isAfter(endCombined);
    const canPopulateEndDate = startCombined && !endCombined;

    if (isAfter || canPopulateEndDate) {
      const startCombined = this.DateTimeService.getValueByUUID(
        this.item.uuid,
        "start",
        "combined"
      );
      const endStartDateTime = this.momentJS(startCombined).add(1, "minutes");

      this.DateTimeService.updateDateTimes(
        this.item.uuid,
        "end",
        endStartDateTime,
        endStartDateTime.format("HH:mm")
      );
      this.DateTimeService.updateDateTimesCombined(this.item.uuid, "end");
    }
  }

  /**
   * Toggles forever
   * previousEndValue is always an ISO string
   * @returns {void}
   */
  updateForeverUi() {
    const endCombined = this.DateTimeService.getValueByUUID(
      this.item.uuid,
      "end",
      "combined"
    );

    if (this.isForever) {
      if (endCombined) {
        this.previousEndValue = endCombined;
        this.DateTimeService.resetDataTimeByUUID(this.item.uuid, "end");
      }
    } else if (!endCombined && this.previousEndValue) {
      this.DateTimeService.updateDateTimes(
        this.item.uuid,
        "end",
        this.previousEndValue,
        this.momentJS(this.previousEndValue).format("H:mm"),
        this.previousEndValue
      );
    }
    this._updateData();
  }

  /**
   * Ensures the end date cannot before before the starting date once starting date is set.
   * @returns {void}
   */
  setStartDate() {
    const startDate = this.DateTimeService.getValueByUUID(
      this.item.uuid,
      "start",
      "date"
    );
    let date;

    if (startDate) {
      date = this.momentJS(startDate).toDate();
    }

    if (this.end) {
      if (date) {
        this.end.gotoDate(date);
      }

      const minDate = date || new Date(null); // setMinDate needs to be a Date, so if nothing then reset to 1970
      this.end.setMinDate(minDate);
    }

    this.DateTimeService.updateCombinedStartDate(this.item.uuid);
  }

  /**
   * Sets the start date to match the end if the start date is undefined
   * @returns {void}
   */
  setEndDate() {
    this.DateTimeService.updateCombinedEndDate(this.item.uuid);
  }

  /**
   * Reset the field
   * @param {Object} data
   * @returns {void}
   * @private
   */
  _resetData(data) {
    this.data = {
      [this.config.start_field]: data[this.config.start_field],
      [this.config.end_field]: data[this.config.end_field],
      data_source_id: data.data_source_id,
      uid: data.uid,
    };
    this._initialiseValues();
  }

  /**
   * _initialiseValues
   * @returns {void}
   */
  _initialiseValues() {
    this.DateTimeService.initialiseValues(
      this.data[this.config.start_field],
      this.data[this.config.end_field],
      this.item.uuid
    );
    // this.isForever is controlling if end date is undefined and disabled, should the config not be set then isForever is false
    this.isForever =
      !this.data[this.config.end_field] &&
      this.config.date_settings.has_forever_option;
  }
}

/**
 * Date and time directive
 * @returns {Object}
 */
function dateTimeRangeFieldDirective() {
  return {
    restrict: "E",
    controller: DateTimeRangeFieldCtrl,
    controllerAs: "component",
    template,
    scope: {},
    bindToController: {
      data: "=",
      config: "<",
      form: "=",
      item: "=",
      module: "=",
    },
  };
}

export default dateTimeRangeFieldDirective;
