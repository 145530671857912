import template from "./required-indicator.html";

class requiredIndicatorCtrl {
  /**
   * @constructor
   * @param {object} $scope
   * @param {object} MessageService
   */
  constructor($scope, MessageService) {
    this.MessageService = MessageService;
    this.$scope = $scope;

    this.setupSubscriptions();
  }

  /**
   * Pub/sub subscriptions
   * @returns {void}
   */
  setupSubscriptions() {
    const availableChannelNames = {
      update: `${this.type}RequiredIndicator.update`,
    };

    this.MessageService.registerChannel(availableChannelNames.update);
    this.MessageService.on(availableChannelNames.update, (ch, data) => {
      this.update(data);
    });

    this.$scope.$on("$destroy", () => {
      this.deregisterSubscriptions(availableChannelNames);
    });
  }

  /**
   * De-register pub/sub subscriptions
   * @param {object} availableChannelNames - the channel names available
   * @returns {void}
   */
  deregisterSubscriptions(availableChannelNames) {
    this.MessageService.unregisterChannel(availableChannelNames.update);
  }

  /**
   * Update the component data
   * @param {object} data
   * @returns {void}
   */
  update(data) {
    this.fulfilled = data.completed;
  }
}

/**
 * Directive
 * @returns {object} directive
 */
function requiredIndicatorDirective() {
  return {
    restrict: "E",
    scope: {},
    bindToController: {
      type: "=",
    },
    template,
    controller: requiredIndicatorCtrl,
    controllerAs: "component",
  };
}

export default requiredIndicatorDirective;
