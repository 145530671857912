import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";

import styles from "./media-preview-block.scss";

/**
 * Media Preview Block
 * @param {Object} props
 * @returns {JSX}
 */
export default function MediaPreviewBlock(props) {
  if (!props.data || !props.data.image_urls) {
    return null;
  }
  const image = props.data && props.data.image_urls[0];

  return (
    <div
      className={styles["media-preview-block"]}
      suppressContentEditableWarning="true"
      contentEditable="false"
    >
      {image ? (
        <Fragment>
          <img
            src={image.url}
            alt={`Episode: ${props.data.title}`}
            className={styles["media-preview-block__image"]}
          />
          <SkylarkIcon
            iconName="episode"
            extraClassName={classnames([
              styles["media-preview-block__icon"],
              styles["media-preview-block__icon--overlay"],
            ])}
          />
        </Fragment>
      ) : (
        <Fragment>
          <SkylarkIcon
            iconName="no-view"
            extraClassName={styles["media-preview-block__icon"]}
          />
          <p>No Preview Available</p>
        </Fragment>
      )}
    </div>
  );
}

MediaPreviewBlock.propTypes = {
  data: PropTypes.shape({
    image_urls: PropTypes.arrayOf(Object),
    title: PropTypes.string,
  }).isRequired,
};
