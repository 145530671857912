import NotificationMessage from "skylarklib/constants/notification-text";
import template from "./videojs-block.html";

const videoElementSelector = ".ovp-asset__frame__player";

/**
 * waitForVideoElementToExist checks if the video element exists on the DOM, calls the callback when it does
 * @param {*} callback - function to call when the element exists
 */
const waitForVideoElementToExist = ($element, callback) => {
  const elements = $element.find(videoElementSelector);
  if (elements.length === 0) {
    setTimeout(() => waitForVideoElementToExist($element, callback), 100);
    return;
  }
  callback();
};

class VideoJsController {
  /**
   * @param _
   * @param $q
   * @param $element
   * @param $scope
   * @param videojs
   * @param ApiService
   * @param ViewingService
   * @param NotificationService
   * @param ConfigurationFactory
   * @param SchedulingService
   * @param SchedulesFactory
   * @param MessageService
   * @param EntityFactory
   *
   */
  constructor(
    _,
    $q,
    $element,
    $scope,
    videojs,
    ApiService,
    ViewingService,
    NotificationService,
    ConfigurationFactory,
    SchedulingService,
    SchedulesFactory,
    MessageService,
    EntityFactory
  ) {
    this._ = _;
    this.$q = $q;
    this.$element = $element;
    this.$scope = $scope;
    this.videojs = videojs;
    this.ApiService = ApiService;
    this.ViewingService = ViewingService;
    this.NotificationService = NotificationService;
    this.ConfigurationFactory = ConfigurationFactory;
    this.SchedulingService = SchedulingService;
    this.SchedulesFactory = SchedulesFactory;
    this.MessageService = MessageService;
    this.EntityFactory = EntityFactory;

    this.status = "pause";
    this.url = "";
    this.scheduleType = "";
    this.errorRetries = 0;
    this.errorMaxRetries = 3;
    this.hasPlaybackError = false;
    this.isPreviewBeforeUpload = false;
    this.videoTypeIsUnsupported = false;

    this._onDataChange = this._onDataChange.bind(this);
  }

  /**
   * initialize component
   * @returns {void}
   */
  $onInit() {
    this.entityName = this.EntityFactory.getEntityName();

    // entity can be null before the asset is created
    if (this.entity) {
      this._setSubscriptions();
      this._applyScheduleTypeFromConfig();
      this._updateSchedules(this.entity);
    } else {
      this.isPreviewBeforeUpload = true;
    }

    if (this.file) {
      const url = URL.createObjectURL(this.file);
      this.url = url;
      this.isPreviewBeforeUpload = true;

      waitForVideoElementToExist(this.$element, () => {
        this._setOvpAssetPlayer(url, this.file.type);
      });
    }
  }

  /**
   * on destroy
   */
  $onDestroy() {
    if (this.entity) {
      this._removeSubscription();
    }
    this._removePlayer();
  }

  /**
   * Checks the schedule and ovps existing before requesting the viewing
   * @returns {void}
   */
  _checkScheduleAndGetViewing() {
    if (
      this.hasAvailableSchedule &&
      this.entity.ovps &&
      this.entity.ovps.length
    ) {
      this.ViewingService.getViewing(this.entity.uid, this.account.slug)
        .then((url) => this._setOvpAssetPlayer(url))
        .catch(() => {
          this.NotificationService.notifyError(
            NotificationMessage.viewingError
          );
        });
    }
  }

  /**
   * Finds the ovp asset inside the current element
   * and creates an instance of videojs on that element
   *
   * @param {string} url - stream url
   * @param {string} type - optional file type
   * @returns {void}
   */
  _setOvpAssetPlayer(url, type) {
    let thumbnail = "";

    if (
      this.entity &&
      Array.isArray(this.entity.image_urls) &&
      this.entity.image_urls.length &&
      this.entity.image_urls[0].url
    ) {
      thumbnail = this.entity.image_urls[0].url;
    }

    this.ovpAssetElement = this.$element.find(videoElementSelector);
    this.ovpAssetPlayer = this.videojs(this.ovpAssetElement[0], {
      controls: true,
      preload: "auto",
      poster: thumbnail,
      sources: [
        {
          type: type || "application/x-mpegURL",
          src: url,
        },
      ],
      controlBar: {
        volumePanel: { inline: false },
        fullscreenToggle: this.ovpAssetType === "video",
        pictureInPictureToggle: this.ovpAssetType === "video",
      },
    });

    this.ovpAssetElement.on("click", () => false);

    this.ovpAssetPlayer.on(["play", "pause", "ended"], (event) => {
      this.status = event.type;

      this.$scope.$apply();
    });

    this.ovpAssetPlayer.on("ready", () => {
      this._onPlayerReadyEvents();
    });

    this.ovpAssetPlayer.on("error", (e) => {
      const err = e.target.player.error_;
      // Code 4 is a VideoJS MEDIA_ERR_SRC_NOT_SUPPORTED error
      // https://github.com/videojs/video.js/blob/b393b2b2d8d33c6b5171f14887b04d9956f7b3bc/src/js/media-error.js#L88
      if (err.code === 4) {
        this.videoTypeIsUnsupported = true;
        // Force digest
        this.$scope.$apply();
      }
    });
  }

  /**
   * Handles video controls
   * @returns {Boolean|void}
   */
  toggleOvpAsset() {
    if (!this.isPlayable()) {
      return false;
    }

    if (this.status === "play") {
      this.ovpAssetPlayer.pause();
    } else {
      this.ovpAssetPlayer.play();
    }
  }

  /**
   * Set subscriptions to channels
   *
   * @returns {void}
   *
   */
  _setSubscriptions() {
    this.MessageService.subscribe(
      `${this.entity.uid}.dataChannel`,
      this._onDataChange
    );

    this.MessageService.subscribe("refreshTextTracks", () =>
      this._refreshTextTracks()
    );
  }

  /**
   * remove subscriptions
   * @returns {void}
   */
  _removeSubscription() {
    this.MessageService.off(
      `${this.entity.uid}.dataChannel`,
      this._onDataChange
    );
  }

  /**
   * player ready events
   * @private
   */
  _onPlayerReadyEvents() {
    const tech = this.ovpAssetPlayer.tech();
    if (!tech) {
      return;
    }

    tech.on("retryplaylist", () => this._onRetryPlaylistError());
  }

  /**
   * fetching playlist error handling
   * after maximum tries of resolving resources
   * @private
   */
  _onRetryPlaylistError() {
    this.errorRetries++;
    if (this.errorRetries >= this.errorMaxRetries) {
      this._removePlayer();
      this.hasPlaybackError = true;
      this.$scope.$apply();
    }
  }

  /**
   * when modal for schedule is saved
   * @param {string} ch
   * @param {*} data
   * @private
   */
  _onDataChange(ch, data) {
    // other components can publish on this channel as well, we need to make sure this is schedule data we receive
    const isScheduleData = this._.isEqual(
      Object.keys(data).sort(),
      ["uid", "self", "schedule_urls"].sort()
    );
    if (isScheduleData) {
      this._updateSchedules(data);
    }
  }

  /**
   * Set schedules status
   * needed to preview/not preview
   * the asset
   *
   * @returns {void}
   *
   */
  _setScheduleStatus(schedule) {
    this.hasAvailableSchedule = false;

    if (Array.isArray(schedule)) {
      this.hasAvailableSchedule = schedule.some((schedule) =>
        this.SchedulingService.isAvailable(schedule)
      );
    }
  }

  /**
   * set schedule type based on the information config
   * when licensing it is a rights
   */
  _applyScheduleTypeFromConfig() {
    this.ConfigurationFactory.getEntityConfiguration(this.entityName).then(
      (config) => {
        this.assetInformationConfig = config;
        const hasLicensing = config.modules.some(
          (module) => module.name === "licensing"
        );
        if (hasLicensing) {
          this.scheduleType = "rights";
        }
      }
    );
  }

  /**
   * Updates the license on the component
   * and triggers an event to update them
   * on the relationship-item containing
   * the videojs-block
   *
   * @returns {void}
   *
   */
  _updateSchedules(data) {
    if (data.schedule_urls) {
      this.SchedulesFactory.getSchedules(data.schedule_urls)
        .then((expandedSchedules) => {
          this._setScheduleStatus(expandedSchedules);
          this._checkScheduleAndGetViewing();

          if (this.entity) {
            this.MessageService.publish(
              `Schedules.${this.entity.uid}.update`,
              expandedSchedules
            );
          }
        })
        .catch(() =>
          this.NotificationService.notifyError(NotificationMessage.generalError)
        );
    }
  }

  /**
   * reloading src
   */
  _refreshTextTracks() {
    if (!this.ovpAssetPlayer) {
      return;
    }

    this.ViewingService.getViewing(this.entity.uid, this.account.slug).then(
      (url) => {
        this.ovpAssetPlayer.reset();
        this.ovpAssetPlayer.src(url);
      }
    );
  }

  /**
   * remove player
   */
  _removePlayer() {
    if (!this.ovpAssetPlayer) {
      return;
    }

    this.ovpAssetPlayer.dispose();
    this.ovpAssetPlayer = null;
  }

  /**
   * Once the file is uploaded, it must have a schedule to be played
   * @returns boolean whether to allow the player to be played
   */
  isPlayable() {
    return (
      (this.hasAvailableSchedule && !this.isPreviewBeforeUpload) ||
      (this.isPreviewBeforeUpload && !this.videoTypeIsUnsupported)
    );
  }
}

const videoJsBlockComponent = {
  bindings: {
    account: "<",
    ovpAssetType: "<",
    entity: "<",
    file: "<",
  },
  template,
  controller: VideoJsController,
  controllerAs: "component",
};

export default videoJsBlockComponent;
