import { createApiAction } from "store/api/api.actions";

import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";
import {
  FETCH_ENTITY_CONFIG_BASE,
  FETCH_ENTITY_CONFIG_INFORMATION,
  FETCH_ENTITY_CONFIG_RELATIONSHIP,
  FETCH_ENTITY_CONFIG_VERSION,
  FETCH_ENTITY_CONFIG_EDITOR,
  RESET_ENTITY_CONFIG,
} from "./entities-config.constant";

const resetConfig = (entity) => ({
  type: RESET_ENTITY_CONFIG,
  entity,
});

/**
 * dispatch action with ConfigurationFactory.getBaseConfiguration to fetch entity
 * @param { string } entity - the name of the entity like, episodes, articles, brands, etc
 * @param { string } [entityType] -  the entity type, usually defined by slug
 */
const fetchBaseConfig = (entity, entityType) => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");
  const action = createApiAction(
    FETCH_ENTITY_CONFIG_BASE,
    ConfigurationFactory.getBaseConfiguration(entity, entityType)
  );

  return {
    ...action,
    entity,
    entityType,
  };
};

/**
 * dispatch action with ConfigurationFactory.getBaseConfiguration to fetch entity
 * @param { string } entity - the name of the entity like, episodes, articles, brands, etc
 * @param { string } entityType -  the entity type, usually defined by slug
 */
const fetchInformationConfig = (entity, entityType) => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");
  const action = createApiAction(
    FETCH_ENTITY_CONFIG_INFORMATION,
    ConfigurationFactory.getInformationConfiguration(entity, entityType)
  );

  return {
    ...action,
    entity,
    entityType,
  };
};

/**
 * dispatch action with ConfigurationFactory.getTabConfiguration to fetch entity
 * @param { string } entity - the name of the entity like, episodes, articles, brands, etc
 */
const fetchVersionConfig = (entity) => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");
  const action = createApiAction(
    FETCH_ENTITY_CONFIG_VERSION,
    ConfigurationFactory.getTabConfiguration(entity, "versions")
  );

  return {
    ...action,
    entity,
  };
};

const fetchRelationshipConfig = (entity) => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");
  const action = createApiAction(
    FETCH_ENTITY_CONFIG_RELATIONSHIP,
    ConfigurationFactory.getTabConfiguration(entity, "relationships")
  );

  return {
    ...action,
    entity,
  };
};

const fetchEditorConfig = (entity, entityType) => {
  const ConfigurationFactory = Injector.get("ConfigurationFactory");
  const action = createApiAction(
    FETCH_ENTITY_CONFIG_EDITOR,
    ConfigurationFactory.getTabConfiguration(entity, "editor", entityType)
  );

  return {
    ...action,
    entity,
    entityType,
  };
};

export {
  resetConfig,
  fetchBaseConfig,
  fetchInformationConfig,
  fetchVersionConfig,
  fetchRelationshipConfig,
  fetchEditorConfig,
};
