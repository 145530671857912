import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import * as DraftHelpers from "components/_react/editor/helpers/draft-helpers/draft-helpers";

import ImagePlayback from "../../../image-playback/image-playback";
import BlockHeader from "../block-header/block-header";
import BlockFocus from "../block-focus/block-focus";

import styles from "./image-block.scss";

/**
 * @class  Image Block Component for rendering images in the editor
 * @param {Object} props
 */
export default class ImageBlock extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    blockProps: PropTypes.shape({
      replaceMedia: PropTypes.func.isRequired,
      onModalSave: PropTypes.func,
    }).isRequired,
    block: PropTypes.shape({
      getKey: PropTypes.func,
      getLength: PropTypes.func,
    }).isRequired,
    selection: PropTypes.shape({
      getAnchorKey: PropTypes.func,
    }).isRequired,
    data: PropTypes.shape({
      media: PropTypes.shape({
        filePreview: PropTypes.string,
        url: PropTypes.string,
      }),
    }).isRequired,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.replaceImage = this.replaceImage.bind(this);

    this.isGif = false;
  }

  componentDidMount() {
    this.getImageType();
  }

  componentDidUpdate() {
    this.getImageType();
  }

  /**
   *
   * Gets the type of the passed image
   *
   *
   */
  getImageType() {
    const url = this.props.data.media.filePreview || this.props.data.media.url;
    const isBlob = url.includes("blob:");

    if (isBlob) {
      fetch(url).then((response) => {
        const blob = response.blob();

        if (blob) {
          this.isGif = ["image/gif", "image/webp"].includes(blob.type);
        }
      });
    } else {
      this.isGif = url.includes(".gif") || url.includes(".webp");
    }
  }

  /**
   * replaceImage - used for replacing with another image, or a media placeholder
   * @param   {String} type
   * @param   {Object} media
   */
  replaceImage(type, media) {
    const { block } = this.props;
    const selectionState = DraftHelpers.createBlockSelection(
      block.getKey(),
      0,
      block.getLength()
    );

    this.props.blockProps.replaceMedia(selectionState, block, media, type);
  }

  /**
   * render
   * @returns {JSX}
   */
  render() {
    const url = this.props.data.media.filePreview || this.props.data.media.url;

    return (
      <BlockFocus
        block={this.props.block}
        selection={this.props.selection}
        render={(state) => (
          <Fragment>
            <div
              className={classnames(styles["image-block"], {
                [styles["image-block--has-focus"]]: state.hasFocus,
              })}
            >
              <BlockHeader
                type="image"
                headerText="Image"
                renderMediaBlock={this.replaceImage}
                block={this.props.block}
                onModalSave={this.props.blockProps.onModalSave}
              />
              <div
                className={styles["image-block__container"]}
                suppressContentEditableWarning="true"
                contentEditable="false"
              >
                {this.isGif ? (
                  <ImagePlayback src={url} />
                ) : (
                  <img
                    className={styles["image-block__image"]}
                    src={url}
                    alt=""
                  />
                )}
              </div>
            </div>
          </Fragment>
        )}
      />
    );
  }
}
