import template from "./date-range-filter.html";

/**
 * @dateFilter Component
 * Component to filter content by date range.
 *
 */
class DateRangeFilterCtrl {
  /**
   * @constructor
   * @param {Object} SetContentsActionCreators
   * @param {Object} MessageService
   * @param {Object} momentJS
   */
  constructor(SetContentsActionCreators, MessageService, momentJS) {
    this.SetContentsActionCreators = SetContentsActionCreators;
    this.MessageService = MessageService;
    this.momentJS = momentJS;

    this.setupSubscription();
  }

  /**
   * Setup pub/sub subscripions
   * @returns {void}
   */
  setupSubscription() {
    this.MessageService.subscribe("DateAndTime.updateFilters", (ch, date) => {
      const start = this.momentJS(date).startOf("day").toISOString();
      const end = this.momentJS(date).endOf("day").toISOString();

      this._filterByDateRange(start, end);
    });
  }

  /**
   * Filter preview items based on date range
   * @param  {String} start ISO date
   * @param  {String} end ISO date
   */
  _filterByDateRange(start, end) {
    this.SetContentsActionCreators.fetchPreviewItems(start, end);
  }
}

/**
 * Component definition
 * @returns {Object}
 */
function dateFilterComponent() {
  return {
    template,
    controller: DateRangeFilterCtrl,
    controllerAs: "component",
  };
}

export default dateFilterComponent;
