import NotificationMessage from "skylarklib/constants/notification-text";

class BookmarkService {
  /**
   * Creates an instance of BookmarkService.
   * @param {Object} $rootScope
   * @param {Object} USERLISTS
   * @param {Object} APP_SETTINGS
   * @param {Object} UserlistsFactory
   * @param {Object} ConfigurationFactory
   * @param {Object} AlwaysScheduleService
   * @param {Object} _
   * @memberof BookmarkService
   */
  constructor(
    $rootScope,
    USERLISTS,
    APP_SETTINGS,
    UserlistsFactory,
    ConfigurationFactory,
    AlwaysScheduleService,
    ApiFilterFactory,
    EntityTypeHelpersService,
    MessageService,
    NotificationService,
    _
  ) {
    this.$rootScope = $rootScope;
    this.USERLISTS = USERLISTS;
    this.APP_SETTINGS = APP_SETTINGS;
    this.UserlistsFactory = UserlistsFactory;
    this.ConfigurationFactory = ConfigurationFactory;
    this.AlwaysScheduleService = AlwaysScheduleService;
    this.ApiFilterFactory = ApiFilterFactory;
    this.EntityTypeHelpersService = EntityTypeHelpersService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this._ = _;

    this.filters = {
      title: this.USERLISTS.bookmarks.name,
      limit: 1,
    };
  }

  /**
   * Initialises bookmark service.
   * @memberof BookmarkService
   */
  init() {
    return this._getConfig()
      .then((config) => (this.config = config))
      .then(() => this._getList())
      .then((data) =>
        data.objects.length ? data.objects[0] : this._createList()
      )
      .then((data) => (this.bookmarks = data))
      .then(() => this.fetchItems())
      .then(() => (this.$rootScope.hasBookmarks = true))
      .catch(() => {
        this.NotificationService.notifyError(
          NotificationMessage.bookmarkError("fetch")
        );
      });
  }

  /**
   * gets a list of bookmarks
   * @returns {Promise}
   * @memberof BookmarkService
   */
  _getList() {
    const filters = this.ApiFilterFactory.appendToFilters(
      this.config,
      this.filters
    );

    return this.UserlistsFactory.get(filters);
  }

  /**
   * gets config for bookmarks
   * @returns {Promise}
   * @memberof BookmarkService
   */
  _getConfig() {
    return this.ConfigurationFactory.getEntityBaseConfiguration("userlists");
  }

  /**
   * creates a new bookmarks list scheduled to always
   * @returns {Promise}
   * @memberof BookmarkService
   */
  _createList() {
    return this.AlwaysScheduleService.getAlwaysSchedule().then((data) =>
      this.UserlistsFactory.create({
        title: this.filters.title,
        schedule_urls: [data.self],
      })
    );
  }

  /**
   * gets items for a given list
   * @returns {Promise}
   * @memberof BookmarkService
   */
  _getItems() {
    const filters = this.ApiFilterFactory.appendToFilters(this.config.items, {
      order: this.config.items.default_order,
    });

    return this.UserlistsFactory.getItems(this.bookmarks.uid, filters);
  }

  /**
   * Fetches bookmark items
   * @returns {Promise}
   * @memberof BookmarkService
   */
  fetchItems() {
    return this._getItems().then((data) => (this.items = data.objects));
  }

  /**
   * build bookmarks list for DOM
   * @returns {Object}
   * @memberof BookmarkService
   */
  buildBookmarkList() {
    const items = this.items
      .map((item) => item.content_url)
      .map((entity) => this._buildBookmark(entity));

    return {
      display_name: "Bookmarks",
      items,
    };
  }

  /**
   * @param {Object} entity
   * @returns {Object}
   * @memberof BookmarkService
   */
  _buildBookmark(entity) {
    return {
      display_name: entity.title || entity.name,
      name: this.EntityTypeHelpersService.getRawType(entity.self),
      type: entity.set_type_slug || entity.article_type_slug || "",
      uid: entity.uid,
      self: entity.self,
    };
  }

  /**
   * finds a particular entity within the bookmarked items
   * @param {String} url
   * @returns {undefined|object}
   * @memberof BookmarkService
   */
  findItem(url) {
    return this.items.find((item) => item.content_url.self === url);
  }

  /**
   *
   * whether we should allow for new bookmarks
   * @memberof BookmarkService
   */
  isEnabled() {
    return this.items.length < this.config.limit;
  }

  /**
   * bookmarks an item
   * @returns {undefined|void}
   * @param {String} itemUrl
   * @memberof BookmarkService
   */
  addItem(itemUrl) {
    const item = this.findItem(itemUrl);
    const filters = this.ApiFilterFactory.appendToFilters(
      this.config.items,
      {}
    );

    return (
      !item &&
      this.AlwaysScheduleService.getAlwaysSchedule()
        .then((data) => data.self)
        .then((schedule) =>
          this.UserlistsFactory.addItem(
            this.bookmarks.uid,
            { content_url: itemUrl, schedule_urls: [schedule] },
            filters
          )
        )
        .then((data) => this.items.push(data))
        .then(() => this.MessageService.publish("Bookmark.Add", this.items))
    );
  }

  /**
   * removes an item from bookmarks
   * @param {String} itemUrl
   * @returns {undefined|void}
   * @memberof BookmarkService
   */
  removeItem(itemUrl) {
    const item = this.findItem(itemUrl);

    return (
      item &&
      this.UserlistsFactory.removeItem(this.bookmarks.uid, item.uid)
        .then(() =>
          this._.remove(this.items, (item) => item.content_url.self === itemUrl)
        )
        .then(() => this.MessageService.publish("Bookmark.Remove", this.items))
    );
  }
}

export default BookmarkService;
