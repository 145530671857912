class BatchRequestService {
  /**
   * @constructor
   * @param {object} ApiService
   * @param {object} ApiRequestConfigFactory
   */
  constructor(ApiService, ApiRequestConfigFactory) {
    this.ApiService = ApiService;
    this.ApiRequestConfigFactory = ApiRequestConfigFactory;
  }

  /**
   * send all the batch requests to api service to be processed
   * @param {array} requests
   * @param {object} config
   * @returns {*|IHttpPromise<T>|Object|HttpPromise}
   */
  sendRequests(requests, config) {
    return this.ApiService.post(
      "/api/batch/",
      JSON.stringify(requests),
      this.ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        requestConfig: config,
      })
    );
  }
}

export default BatchRequestService;
