function ApiFilterFactory(
  $q,
  API_FILTERING,
  ConfigurationFactory,
  EntityFactory
) {
  const factory = {};

  /**
   * get fields from config and append to queryParams
   * @param {string} configFieldName - name of field in config
   * @param {object} config - config
   * @param {object} filters - original filter params
   * @access private
   * @returns {object} new query params
   */
  factory._appendFiltersFieldsToConfig = (configFieldName, config, filters) => {
    if (configFieldName in config) {
      return {
        ...filters,
        [API_FILTERING[configFieldName]]: config[configFieldName].join(","),
      };
    }

    return filters;
  };

  /**
   * get fields from config and append to queryParams
   * @param {object} config - config
   * @param {object} filters - original filter params
   * @access public
   * @returns {object} new query params
   */
  factory.appendToFilters = (config, filters) => {
    const removalFilters = factory._appendFiltersFieldsToConfig(
      "fields_to_filter",
      config,
      filters
    );
    const expandingFilters = factory._appendFiltersFieldsToConfig(
      "fields_to_expand",
      config,
      filters
    );

    return { ...expandingFilters, ...removalFilters };
  };

  /**
   * create a filter to ignore already added items in the modal
   * when no entities are related yet, then no filter is required
   * when it is the content tab, the relationships are parent_url as it is a child/parent relationship
   * when non content tab, and the relationships are smaller then the pagination, a uid filter is applied,
   *   as at this stage all entities related are known
   * when there are more items related then visible on one page, then the related_url filter is applied,
   *   as not all entities are known,
   *   In order to do this, one of the entities is fetched and assumption of the property url is made.
   *   i.e. episodes has more than 9 related ratings, then the following filter is applied /episodes/?-rating_url=/api/ratings/rate_uid/
   *
   * @param {String} self - entities self url
   * @param {Array<Object>} relatedEntities - a list of entity objects containing uid and self
   * @param {Number} [totalCount] - the total amount of items, when the total is bigger then the list of related items,
   *                                then the relationship property needs to be used
   * @param {String} [reverseRelationshipName] - when know the reverse relationships name then this is used,
   *                                             otherwise assumptions will be made
   * @return {Promise<Object>}
   */
  factory.getExcludeItemsFilter = (
    self,
    relatedEntities,
    totalCount,
    reverseRelationshipName
  ) => {
    if (!relatedEntities || relatedEntities.length === 0) {
      return $q.resolve({});
    }

    if (typeof reverseRelationshipName === "string") {
      return $q.resolve({
        [`-${reverseRelationshipName}`]: self,
      });
    }

    const uidList = relatedEntities.map((entity) => {
      if (typeof entity === "string") {
        return EntityFactory.getUidFromUrl(entity);
      }
      return entity.uid;
    });

    if (!totalCount || totalCount === relatedEntities.length) {
      return $q.resolve({
        "-uid": uidList.join(","),
      });
    }

    return EntityFactory.getByUrl(relatedEntities[0].self).then(
      (relatedEntity) => {
        const potentialRelationshipsKeys = Object.keys(relatedEntity).filter(
          (key) => /_urls?$/.test(key)
        );

        let reverseRelationshipKey = potentialRelationshipsKeys.find((key) => {
          if (!relatedEntity[key]) {
            return false;
          }

          return relatedEntity[key].includes(self);
        });

        if (reverseRelationshipKey) {
          reverseRelationshipKey = reverseRelationshipKey.replace(/s$/, ""); // in case the property is _urls remove the s, as it filters only by one url
          return {
            [`-${reverseRelationshipKey}`]: self,
          };
        }

        // the best we can at this point
        return $q.resolve({
          "-uid": uidList.join(","),
        });
      }
    );
  };

  /**
   * make a filter for type restriction either this is already defined on the configuration or
   * is pulled from the target object
   * @param {String} restrictTo
   * @param {String} restrictToType
   * @return {Promise<Object>}
   */
  factory.getRestrictionFilter = (restrictTo, restrictToType) => {
    if (!restrictToType) {
      return $q.resolve({});
    }

    return ConfigurationFactory.getEntityBaseConfiguration(restrictTo).then(
      (config) => {
        const tabsParam =
          config &&
          config.filters &&
          config.filters.tabs &&
          config.filters.tabs.param;

        return factory.getRestrictionTabsParamFilter(restrictToType, tabsParam);
      }
    );
  };

  /**
   *
   * @param {String} type
   * @param {String} tabsParam
   * @return {Object}
   */
  factory.getRestrictionTabsParamFilter = (type, tabsParam) => {
    if (type && tabsParam) {
      return {
        [tabsParam]: type,
      };
    }

    return {};
  };

  return factory;
}

export default ApiFilterFactory;
