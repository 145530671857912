/**
 * @param {object} ApiService
 * @returns {{}}
 * @constructor
 */
function ArticleTypeFactory(ApiService, ApiRequestConfigFactory) {
  const factory = {};

  /**
   * create
   * @access public
   * @param   {Object} data - data for the dynamic object we are creating
   * @returns {Promise}
   */
  factory.getArticleTypes = () =>
    ApiService.get(
      "/api/article-types/",
      ApiRequestConfigFactory.createRequestConfig({
        overrideGlobalLanguage: true,
        useWildCard: true,
      })
    );

  factory.getArticleStatus = () => ApiService.get("/api/article-status/");

  return factory;
}

export default ArticleTypeFactory;
