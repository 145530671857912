import ConnectToStore from "hocs/connect-to-store/connect-to-store";

import LinkTooltipWrapper from "./link-tooltip";

const config = {
  props: ["editor"],
  actions: [],
};

export default ConnectToStore(config, LinkTooltipWrapper);
