import moment from "moment-timezone";

import {
  TRANSITIONING_SETTINGS,
  MULTIPLE_STATUSES,
  DATE_THRESHOLD,
} from "../schedules.constants";

const rank = ["standard", "upcoming", "warning"];

/**
 * Gets the sub status of a schedule if it is not expired, based on a date threshold.
 * A live or future schedule will have a transitioning state if they fall into this threshold.
 * @param {Object} schedule
 * @returns {String}
 */
function _getSubStatus(schedule) {
  const status = TRANSITIONING_SETTINGS[schedule.status];

  if (moment(schedule[status.field]).diff(moment(), "days") < DATE_THRESHOLD) {
    return status.transitioning;
  }

  return status.active;
}

/**
 * Gets the styling for a multiple schedule label from the constants file.
 * The styling does not always match the same business rules than a single schedule.
 * @param {Object} schedule
 */
function _getStyle(schedule, status) {
  return MULTIPLE_STATUSES[status].styling;
}

/**
 * @param {Array} styles
 * @returns {Number} the index of the higher ranked style.
 */
function _findRank(styles) {
  return styles.reduce((acc, curr) => {
    const currIndex = rank.indexOf(curr);

    return currIndex > acc ? currIndex : acc;
  }, 0);
}

/**
 * Get whether the schedule is a rights or editorial schedule
 * @param {Object} schedule
 * @returns {String}
 */
function getScheduleType(schedule) {
  return schedule.rights ? "rights" : "editorial";
}

/**
 * Get a schedule status for display purposes. Allowed Statuses are:
 * [Live|Expiring, Planned|Future, Expired] being that expiring and planned are
 * transitioning states of Live and Future, respectively. Therefore, if a schedule status is not
 * expired, we must work out the sub status.
 *
 * @param {Object} schedule
 * @returns {String}
 */
function getScheduleStatus(schedule) {
  return schedule.status === "expired"
    ? schedule.status
    : _getSubStatus(schedule);
}

/**
 * gets the Multiple Schedules style, according to order of priority.
 * @param {Array} schedules
 * @returns {String} the styling to apply
 */
function getMultipleSchedulesStyle(schedules) {
  const styles = schedules
    .map((schedule) => getScheduleStatus(schedule))
    .map((status, index) => _getStyle(schedules[index], status))
    .reduce((acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]), []);

  if (styles.length === 1) {
    return styles.join("");
  }
  if (styles.includes("upcoming") && styles.includes("warning")) {
    return "warning-upcoming";
  }

  return rank[_findRank(styles)];
}

export { getScheduleStatus, getScheduleType, getMultipleSchedulesStyle };
