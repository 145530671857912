/**
 * DimensionsFactory
 * @param {Object} ApiService
 * @returns {Object} DimensionsFactory instance
 */
function DimensionsFactory(ApiService) {
  const factory = {};

  /**
   * getDimensionByType - Fetches dimension types from Skylark Dimensions API Endpoint
   * @param {string} type
   * @returns {Promise}
   */
  factory.getDimensionByType = (type) =>
    ApiService.get(`/api/dimensions/${type}/`, { params: { order: "name" } });

  return factory;
}

export default DimensionsFactory;
