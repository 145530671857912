import { Amplify } from "@aws-amplify/core";
import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";
import {
  S3Client,
  CreateMultipartUploadCommand,
  CompleteMultipartUploadCommand,
  UploadPartCopyCommand,
  DeleteObjectCommand,
  GetObjectCommand,
} from "@aws-sdk/client-s3";
import { amplifyConfig } from "@skylark/lib";
import moment from "moment-timezone";

const config = __V8_ENVIRONMENT__
  ? amplifyConfig({
      cognitoRegion: __COGNITO_AWS_REGION__,
      cognitoUserPoolId: __COGNITO_USER_POOL_ID__,
      cognitoUserPoolWebClientId: __COGNITO_CLIENT_ID__,
      cognitoIdentityPoolId: __COGNITO_IDENTITY_POOL_ID__,
      cookieDomain: __COGNITO_COOKIE_DOMAIN__,
      storageBucket: __AMPLIFY_STORAGE_BUCKET__,
    })
  : {};

const defaultStorageConfig = {
  customPrefix: {
    public: "",
    private: "",
    protected: "",
  },
};

// Pre V8 doesn't use Cognito Auth
Amplify.configure(config);

export const getToken = async () => {
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
};

export const isAuthenticated = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    return !!user;
  } catch (err) {
    return false;
  }
};

const upload = (key, file, config) =>
  Storage.put(key, file, {
    ...defaultStorageConfig,
    ...config,
  });

const cancelUpload = (request, message) => Storage.cancel(request, message);

/**
 * getAssetS3Object - Finds the latest modified S3 Object for an asset ID
 * @param {*} assetId
 * @returns
 */
const getAssetS3Object = async (assetId) => {
  const allFiles = await Storage.list("", defaultStorageConfig);
  const matchingAssets = allFiles.filter((asset) =>
    asset.key.includes(`processed/${assetId}`)
  );
  const sortedAssets = matchingAssets.sort((a, b) =>
    moment(a.lastModified).isAfter(b.lastModified) ? -1 : 1
  );
  const [latestModifiedAsset] = sortedAssets;
  if (!latestModifiedAsset) {
    throw new Error(
      `Asset for ${assetId} not found in processed in ${__AMPLIFY_STORAGE_BUCKET__}`
    );
  }
  return latestModifiedAsset;
};

/**
 * getMetadataForS3Object - Gets the metadata for an S3 object
 * @param {*} client - S3Client
 * @param {*} objectKey - S3 Object key to get the metadata for
 * @returns
 */
const getMetadataForS3Object = async (client, objectKey) => {
  const getOriginalObjectCommand = new GetObjectCommand({
    Bucket: __AMPLIFY_STORAGE_BUCKET__,
    Key: objectKey,
  });
  const originalObject = await client.send(getOriginalObjectCommand);
  return originalObject.Metadata;
};

/**
 * copyS3ObjectUsingMultipartCopy - Copies an existing object in S3 to another location using MultipartCopy
 * Implements https://docs.aws.amazon.com/AmazonS3/latest/userguide/CopyingObjectsMPUapi.html in JS
 * @param {*} client - S3Client
 * @param {*} assetId - ID of the asset related to this Object
 * @param {*} originalObject - Original S3 Object
 * @param {*} newObjectKey - Object key to copy the object to
 * @param {*} metadata - Original S3 Object metadata (containing )
 */
const copyS3ObjectUsingMultipartCopy = async (
  client,
  assetId,
  originalObject,
  newObjectKey,
  metadata
) => {
  // Initiate the multipart upload
  const createMultipartUploadCommand = new CreateMultipartUploadCommand({
    Bucket: __AMPLIFY_STORAGE_BUCKET__,
    Key: newObjectKey,
    Tagging: `assetId=${assetId}`,
    Metadata: metadata,
  });
  const createMultipartUploadResponse = await client.send(
    createMultipartUploadCommand
  );

  // Copy the object using 50 MB parts
  const partSize = 50 * 1024 * 1024;
  let bytePosition = 0;
  let partNum = 1;
  const objectSize = originalObject.size;

  const uploadRequests = [];

  // Upload each part
  while (bytePosition < objectSize) {
    const lastByte = Math.min(bytePosition + partSize - 1, objectSize - 1);

    const copyRequest = new UploadPartCopyCommand({
      Bucket: __AMPLIFY_STORAGE_BUCKET__,
      Key: newObjectKey,
      CopySource: `${__AMPLIFY_STORAGE_BUCKET__}/${originalObject.key}`,
      PartNumber: partNum,
      UploadId: createMultipartUploadResponse.UploadId,
      CopySourceRange: `bytes=${bytePosition}-${lastByte}`,
    });
    uploadRequests.push(client.send(copyRequest));
    bytePosition += partSize;
    partNum += 1;
  }

  const uploadResponses = await Promise.all(uploadRequests);

  const Parts = uploadResponses.map((res, index) => ({
    ETag: res.CopyPartResult.ETag,
    PartNumber: index + 1,
  }));

  // Complete the multipart upload
  const completeRequest = new CompleteMultipartUploadCommand({
    Bucket: __AMPLIFY_STORAGE_BUCKET__,
    Key: newObjectKey,
    UploadId: createMultipartUploadResponse.UploadId,
    MultipartUpload: {
      Parts,
    },
  });
  await client.send(completeRequest);
};

/**
 * deleteS3Object - deletes an object from S3
 * @param {*} client S3Client
 * @param {*} objectKey S3 Object key to delete
 */
const deleteS3Object = async (client, objectKey) => {
  // Once the object has been copied, delete the original
  const deleteProcessedObjectRequest = new DeleteObjectCommand({
    Bucket: __AMPLIFY_STORAGE_BUCKET__,
    Key: objectKey,
  });
  await client.send(deleteProcessedObjectRequest);
};

/**
 * copyProcessedAssetToRoot - Copies an asset from the processed folder to the root of the S3 bucket
 * Fixes: https://skylarkplatform.atlassian.net/browse/SL-2161
 * This will only find files uploaded by the CMS (not via the bucket) as it appends the asset ID to the file name
 * The asset should be moved into a `${provider}/processed` folder after it has been ingested
 * If multiple files exist for the same asset ID, the one with the most recent lastModified property will be used
 * @param {*} assetId - The Asset ID, used to find the file in the S3 bucket
 */
const copyProcessedAssetToRoot = async (assetId) => {
  const originalAssetObject = await getAssetS3Object(assetId);
  const newAssetKey = originalAssetObject.key.split("processed/")[1];

  // Get credentials and authenticate with bucket
  // https://github.com/aws-amplify/amplify-js/issues/335#issuecomment-367629338
  const credentials = await Auth.currentCredentials();
  const client = new S3Client({
    region: __COGNITO_AWS_REGION__,
    credentials: Auth.essentialCredentials(credentials),
  });

  const originalObjectMetadata = await getMetadataForS3Object(
    client,
    originalAssetObject.key
  );

  await copyS3ObjectUsingMultipartCopy(
    client,
    assetId,
    originalAssetObject,
    newAssetKey,
    originalObjectMetadata
  );

  await deleteS3Object(client, originalAssetObject.key);
};

function AmplifyService() {
  const service = {
    getToken,
    isAuthenticated,
    upload,
    cancelUpload,
    copyProcessedAssetToRoot,
  };

  return service;
}

export default AmplifyService;
