import React, { Component } from "react";
import PropTypes from "prop-types";

import Button from "components/_react/button/button";
import { BUTTON_SECONDARY_ON_DARK } from "skylarklib/constants/button.constants";
import ModalHeader from "../modal-header/modal-header";
import getNotificationTypeConfig from "./service/modal-notification.service";

import styles from "./modal-notification.scss";

/**
 * ModalNotification
 */
export default class ModalNotification extends Component {
  /**
   * propTypes
   * @type {Object}
   */
  static propTypes = {
    options: PropTypes.shape({
      metadata: PropTypes.object,
      placeholders: PropTypes.object,
    }).isRequired,
    destroyModal: PropTypes.func.isRequired,
    confirmModalNotification: PropTypes.func,
    alternativeModalNotification: PropTypes.func,
    resetModalNotification: PropTypes.func,
  };

  /**
   * defaultProps
   * @type {Object}
   */
  static defaultProps = {
    modal: undefined,
    destroyModal: undefined,
    confirmModalNotification: undefined,
    alternativeModalNotification: undefined,
    resetModalNotification: undefined,
  };

  /**
   * @constructor
   * @param   {Object} props
   */
  constructor(props) {
    super(props);

    this.destroyModal = this.destroyModal.bind(this);
    this.confirmModalAction = this.confirmModalAction.bind(this);
    this.alternativeModalAction = this.alternativeModalAction.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);

    this.state = {
      destructionTriggered: false,
    };
  }

  /**
   * componentWillMount lifecycle hook
   */
  componentWillMount() {
    this._getNotificationConfig();

    document.addEventListener("keydown", this.handleKeyPress, false);
  }

  /**
   * componentWillReceiveProps
   * @param  {Object} nextProps
   */
  componentWillReceiveProps(nextProps) {
    this.modalNotificationSubscriber(nextProps);
  }

  /**
   * componentWillUnmount lifecycle hook
   */
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress, false);
  }

  /**
   * modalNotificationSubscriber
   * @param  {Object} nextProps
   */
  modalNotificationSubscriber(nextProps) {
    if (
      !this.state.destructionTriggered &&
      nextProps.modalNotification.isActionTriggered
    ) {
      this.destroyModal();
      this.toggleDestructionState();
    }
  }

  /**
   * setDestructionState
   */
  toggleDestructionState() {
    this.setState({
      destructionTriggered: !this.state.destructionTriggered,
    });
  }

  /**
   * cancels the notification when user presses esc key
   * only works when modal is actually cancellable.
   * @param  {Object} event
   */
  handleKeyPress(event) {
    if (event.keyCode === 27 && this.state.notificationConfig.cta.cancel) {
      this.destroyModal();
    }
  }

  /**
   * _getNotificationConfig
   * @private
   * @returns {void}
   */
  _getNotificationConfig() {
    const { options } = this.props;

    this.setState({
      notificationConfig: getNotificationTypeConfig(
        options.metadata.type,
        options.placeholders
      ),
    });
  }

  /**
   * destroyModal
   * @return {void}
   */
  destroyModal() {
    this.props.destroyModal();
    this.props.resetModalNotification();
  }

  /**
   * confirmModal
   */
  confirmModalAction() {
    if (typeof this.props.options.metadata.alternative === "function") {
      this.props.options.metadata.confirm();
    }

    this.props.confirmModalNotification(this.props.options.metadata.type);
  }

  /**
   * alternativeModalAction
   */
  alternativeModalAction() {
    if (typeof this.props.options.metadata.alternative === "function") {
      this.props.options.metadata.alternative();
    }

    this.props.alternativeModalNotification(this.props.options.metadata.type);
  }

  /**
   * render
   * @returns {Node}
   */
  render() {
    const { body, cta, title } = this.state.notificationConfig;

    return (
      <div className={styles["modal-notification"]}>
        <ModalHeader title={title} />
        <div className={styles["modal-notification__body"]}>
          <div className={styles["modal-notification__content"]}>
            {body.map((paragraph) => (
              <p
                className={styles["modal-notification__body-message"]}
                key={paragraph.key}
              >
                {paragraph.text}
              </p>
            ))}
          </div>
        </div>
        <div className={styles["modal-notification__footer"]}>
          <div className={styles["modal-notification__footer-button-wrap"]}>
            <Button
              buttonType="standard"
              icon="tick"
              id="modal-notification-confirm"
              copy={cta.confirm.text}
              action={this.confirmModalAction}
            />
          </div>
          {cta.alternative && (
            <div className={styles["modal-notification__footer-button-wrap"]}>
              <Button
                buttonType="standard"
                copy={cta.alternative.text}
                id="modal-notification-alternative"
                action={this.alternativeModalAction}
              />
            </div>
          )}
          {cta.cancel && (
            <div className={styles["modal-notification__footer-button-wrap"]}>
              <Button
                buttonType="standard"
                copy={cta.cancel.text}
                id="modal-notification-cancel"
                action={this.destroyModal}
                buttonColor={BUTTON_SECONDARY_ON_DARK}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}
