import ApiConstant from "store/api/api.constant";
import EntityConstant from "store/entity/entity.constant";

import { Injector } from "skylarklib/angularjs/factory/injector/injector.factory";

/**
 * rerouting app base on events
 * for example after entity is created, routing to its entrance page
 * @return {function(*): function(*=): *}
 * @constructor
 */
const RoutingMiddleware = () => (next) => (action) => {
  const { type, response } = action;

  if (type === ApiConstant.getSuccessType(EntityConstant.CREATE_ENTITY)) {
    const $location = Injector.get("$location");
    const EntityFactory = Injector.get("EntityFactory");
    const RouteService = Injector.get("RouteService");

    const name = EntityFactory.getEntityName();
    const type = EntityFactory.getEntityType();

    const { uid } = response;
    const url = RouteService.buildURL({ name, type, uid });

    $location.path(url);
  }

  return next(action);
};

export default RoutingMiddleware;
