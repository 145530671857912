import * as EntityAction from "store/entity/entity.action";
import template from "./permissions-manager.html";

class PermissionsManagerCtrl {
  /**
   * @constructor
   * @param {Object} $scope
   * @param {Object} MessageService
   * @param {Object} ModalTriggerService
   * @param {Object} ReduxConnector
   * @param {Object} PERMISSIONS_OPTIONS
   */
  constructor(
    $scope,
    MessageService,
    ModalTriggerService,
    ReduxConnector,
    PERMISSIONS_OPTIONS
  ) {
    this.$scope = $scope;
    this.MessageService = MessageService;
    this.ModalTriggerService = ModalTriggerService;
    this.ReduxConnector = ReduxConnector;
    this.PERMISSIONS_OPTIONS = PERMISSIONS_OPTIONS;

    this.connectToStore();
  }

  /**
   * init
   * @returns {void}
   */
  $onInit() {
    this._setupSubscriptions();
  }

  $onChanges() {
    this.editability = this.data.editability;
    this._notifyForms();
  }

  /**
   * _setupSubscriptions
   * @returns {void}
   */
  _setupSubscriptions() {
    this.MessageService.subscribe(
      this._buildModalOptions().channels.save,
      (channel, data) => {
        this._savePermissions(data);
      }
    );

    this.MessageService.lockChannel(this._buildModalOptions().channels.save);
  }

  /**
   * _buildModalOptions
   * @returns {object}
   */
  _buildModalOptions() {
    return {
      title: "Object permissions",
      channels: {
        save: `Modal.Settings.${this.data.uid}.save`,
      },
    };
  }

  /**
   * trigger modal to change the permission
   */
  triggerModal() {
    const data = { editability: this.editability };
    const config = this.PERMISSIONS_OPTIONS;
    const type = "settings";
    const entityType = undefined;
    const options = this._buildModalOptions();

    this.ModalTriggerService.triggerModal(
      data,
      config,
      type,
      entityType,
      options
    );
  }

  /**
   * _savePermissions - updates entity permissions in the API on publish from modal
   * @param {object} data edited in modal
   * @returns {void}
   */
  _savePermissions(data) {
    const patchedData = {
      editability: data.editability,
      uid: this.data.uid,
    };

    const successMessage =
      "You have successfully changed editability permissions.";
    const errorMessage =
      "Something went wrong when trying to change editability permissions.";

    this.store.updateEntity(
      this.data.self,
      patchedData,
      null,
      successMessage,
      errorMessage
    );
  }

  /**
   * _notifyForms
   * @returns {void}
   */
  _notifyForms() {
    if (this.editability === "read-only") {
      this.MessageService.publish(`${this.data.uid}.Metadata.CancelEdit`);
      this.MessageService.publish("Forms.CancelAllForms");
    }
  }

  /**
   * connecting to redux with actions and store
   */
  connectToStore() {
    const mapDispatchToThis = {
      ...EntityAction,
    };

    this.disconnect = this.ReduxConnector(this, null, mapDispatchToThis);
  }

  /**
   * destroy lifecycle
   * @return {void}
   */
  $onDestroy() {
    this.disconnect();
    this.MessageService.unregisterChannel(
      this._buildModalOptions().channels.save
    );
  }
}

const PermissionsManager = {
  bindings: {
    data: "<",
  },
  controller: PermissionsManagerCtrl,
  controllerAs: "component",
  template,
};

export default PermissionsManager;
