/**
 * @class RouteHelpers
 */
class RouteService {
  /**
   * constructor
   * @memberof RouteService
   */
  constructor(EntityFactory) {
    this.EntityFactory = EntityFactory;
  }

  /**
   * builds the create entity Url
   * @param    {String} entity
   * @param    {String} entityType
   * @returns  {String}
   * @memberof RouteService
   */
  buildCreateEntityURL(entity, entityType) {
    const customEntity = this.EntityFactory.isCoreEntity(entity)
      ? ""
      : "custom/";
    const typeName = entityType ? `/${entityType}` : "";

    return `/${customEntity}${entity}/create${typeName}/`;
  }

  /**
   * TODO: Migrate this kind of helper to agnostic JS
   *
   * Builds url based on entity, entity type and core entity conditional
   * @param {Object} data
   * @returns  {String}
   * @memberof RouteService
   */
  buildURL({ name, type, uid }) {
    const customString = this.EntityFactory.isCoreEntity(name) ? "" : "custom/";
    const entityTypeString = type ? `/type-${type}` : "";
    const entityUid = uid ? `/${uid}/` : "";

    return `/${customString}${name}${entityTypeString}${entityUid}`;
  }
}

export default RouteService;
