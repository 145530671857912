import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { theme } from "@skylark/react";

/**
 * Provides Chakra UI to Component
 *
 * @param WrappedComponent
 * @return {function(*): JSX.Element}
 */
const withChakraUiProvider = (WrappedComponent) => (props) =>
  (
    <ChakraProvider resetCSS={false} theme={theme}>
      <WrappedComponent {...props} />
    </ChakraProvider>
  );

export default withChakraUiProvider;
