import NotificationMessage from "skylarklib/constants/notification-text";

class DefaultLanguageService {
  /**
   * @constructor
   * @param   {Object} $rootScope
   * @param   {Object} $cookies
   * @param   {Object} $q
   * @param   {Object} ApiService
   * @param   {Object} GlobalParamsService
   * @param   {Object} LanguagesService
   * @param   {Object} MessageService
   * @param   {Object} NotificationService
   * @param   {Object} SkylarkApiInfoService
   * @param   {Object} CONTENT_SETTINGS
   */
  constructor(
    $rootScope,
    $cookies,
    $q,
    ApiService,
    GlobalParamsService,
    LanguagesService,
    MessageService,
    NotificationService,
    SkylarkApiInfoService,
    CONTENT_SETTINGS
  ) {
    this.$rootScope = $rootScope;
    this.$cookies = $cookies;
    this.$q = $q;
    this.ApiService = ApiService;
    this.GlobalParamsService = GlobalParamsService;
    this.LanguagesService = LanguagesService;
    this.MessageService = MessageService;
    this.NotificationService = NotificationService;
    this.SkylarkApiInfoService = SkylarkApiInfoService;
    this.CONTENT_SETTINGS = CONTENT_SETTINGS;
  }

  /**
   * init
   * @returns {void}
   */
  init() {
    const availableLanguagesRequest = this.LanguagesService.getLanguages();
    const apiDefaultLanguageRequest =
      this.SkylarkApiInfoService.getDefaultLanguage();

    this.$q
      .all([availableLanguagesRequest, apiDefaultLanguageRequest])
      .then(([availableLanguages, apiDefaultLanguage]) =>
        this.setInitialDefault(availableLanguages, apiDefaultLanguage)
      )
      .catch(() =>
        this.NotificationService.notifyError(
          NotificationMessage.languageLoadingError
        )
      );
  }

  /**
   * determine the default language on page load,
   * by using the cookie, api defaults, and available languages
   * @param {array} availableLanguages
   * @param {string} apiDefaultLanguage - en, es, etc.
   */
  setInitialDefault(availableLanguages, apiDefaultLanguage) {
    const cookieLanguage = this.$cookies.get("skylark.GlobalLanguage");
    const cookieIsInLanguages = availableLanguages.some(
      ({ iso_code: isoCode }) => isoCode === cookieLanguage
    );
    let initialLanguage;

    if (cookieIsInLanguages) {
      initialLanguage = cookieLanguage;
    } else {
      initialLanguage = apiDefaultLanguage;
    }

    this.defaultLanguage = apiDefaultLanguage;
    this.setInitialLanguage(initialLanguage);
    this.MessageService.publish(this.CONTENT_SETTINGS.LANGUAGE_SET);
  }

  /**
   * getDefault
   * @returns
   * @memberof DefaultLanguageService
   */
  getDefault() {
    return this.defaultLanguage;
  }

  /**
   * isCurrentDefault
   * @memberof DefaultLanguageService
   */
  isDefault(isoCode) {
    return isoCode === this.getDefault();
  }

  /**
   * setInitialLanguage
   * @param {string} language - a language ISO code
   * @returns {void}
   */
  setInitialLanguage(language) {
    this.GlobalParamsService.init(language);
    this.$rootScope.hasDefaultLanguage = true;
  }
}

export default DefaultLanguageService;
