import React, { useContext, useRef } from "react";
import Select, { components } from "react-select";
import classnames from "classnames";
import SkylarkIcon from "components/_react/skylark-icon/skylark-icon.component";
import { ThemeContext } from "components/_react/theme/theme";

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <SkylarkIcon extraClassName="dropdown__arrow-icon" iconName="down" />
  </components.DropdownIndicator>
);

export type Option = {
  value: string;
  label: string;
};

export interface DropdownInterface {
  onChangeAction: (Option) => void;
  options: Array<Option>;
  value: Option;
  placeholder?: string;
  ariaLabel?: string;
}

/**
 * using exposed methods to trigger menu open or closed by focus() or blue() https://react-select.com/advanced#methods
 */
const Dropdown = ({
  onChangeAction,
  options,
  value,
  placeholder,
  ariaLabel = "dropdown",
}: DropdownInterface) => {
  const selectRef = useRef(null);
  const theme = useContext(ThemeContext);

  // Whenever the selectedOption changes, then call the passed action
  const setSelectedOption = (option) => {
    if (option) {
      onChangeAction(option);
    }
  };

  return (
    <div
      className="dropdown-widget__container"
      data-test="dropdown-widget-container"
      onMouseLeave={() => selectRef.current.blur()}
      onMouseOver={() => selectRef.current.focus()}
    >
      <Select
        aria-label={ariaLabel}
        blurInputOnSelect={true}
        className={classnames(
          "dropdown-widget",
          theme && `dropdown-widget--${theme}`
        )}
        classNamePrefix="dropdown-widget"
        closeMenuOnSelect={true}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        isSearchable={false}
        openMenuOnFocus={true}
        options={options}
        placeholder={placeholder}
        ref={selectRef}
        value={value}
        onChange={setSelectedOption}
      />
    </div>
  );
};

export default Dropdown;
