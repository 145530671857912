class ModalMultiLanguageService {
  /**
   * @constructor
   * @param {Object} _
   */
  constructor(_) {
    this._ = _;
  }

  /**
   * multilingualData object containing all languages for this item
   * @param {Object} value
   */
  set multilingualData(value) {
    this._multilingualData = value;
  }

  /**
   * multilingualData object containing all languages for this item
   * @return {Object}
   */
  get multilingualData() {
    return this._multilingualData;
  }

  /**
   * translatableFields array of fields that can translated
   * @param {array} value
   */
  set translatableFields(value) {
    this._translatableFields = value;
  }

  /**
   * translatableFields array of fields that can translated
   * @return {array|*}
   */
  get translatableFields() {
    return this._translatableFields;
  }

  /**
   * Get the current language with translatable fields
   * @return {Object}
   * @private
   */
  _getCurrentLanguageWithoutTranslation() {
    return this._.cloneDeep(
      this._.omit(
        this.multilingualData[this._currentLanguage],
        this.translatableFields
      )
    );
  }

  /**
   * Get the full name for a languages
   * @param availableLanguages
   */
  getCurrentLanguageName(availableLanguages) {
    return availableLanguages.find(
      (language) => language.iso_code === this._currentLanguage
    ).name;
  }

  /**
   * Get the initial language version to use in the modal
   * @param {string } displayedLanguage language key
   */
  getInitialLanguageVersion(displayedLanguage) {
    this._currentLanguage = displayedLanguage;

    return this.multilingualData[displayedLanguage];
  }

  /**
   * Update only the language keys on an existing language version
   * @param {sting} language
   * @private
   */
  _updateNonLanguageKeys(language) {
    return {
      ...this.multilingualData[language],
      ...this._getCurrentLanguageWithoutTranslation(),
      language,
    };
  }

  /**
   * Switch language version in the modal
   * @param {string} targetLanguage language key
   * @return {Object}
   */
  switchLanguageVersion(targetLanguage) {
    if (!this.multilingualData[targetLanguage]) {
      this.multilingualData[targetLanguage] = {
        ...this._getCurrentLanguageWithoutTranslation(),
        language: targetLanguage,
      };
    } else {
      this.multilingualData[targetLanguage] =
        this._updateNonLanguageKeys(targetLanguage);
    }

    this._currentLanguage = targetLanguage;

    return this.multilingualData[targetLanguage];
  }

  /**
   * Copies non language data across all language fields
   */
  prepareMultilingualDataToSave() {
    const updatedMultilingualData = {};

    Object.keys(this.multilingualData).forEach((languageKey) => {
      updatedMultilingualData[languageKey] =
        languageKey !== this._currentLanguage
          ? this._updateNonLanguageKeys(languageKey)
          : this.multilingualData[languageKey];
    });

    this.multilingualData = updatedMultilingualData;
  }
}

export default ModalMultiLanguageService;
