export const SCHEDULE_STATUSES = [
  {
    name: "All",
    slug: "all",
  },
  {
    name: "Unscheduled",
    slug: "unscheduled",
  },
  {
    name: "Expired",
    slug: "expired",
  },
  {
    name: "Live",
    slug: "current",
  },
  {
    name: "Future",
    slug: "future",
  },
];

export const FILTER_TO_PAYLOAD = {
  all: "schedule",
  unscheduled: "schedule",
  expired: "schedule",
  current: "schedule",
  future: "schedule",
  device_types: "schedule",
  order: "pagination",
  start: "pagination",
  limit: "pagination",
};
