class ModalMultiSourceService {
  /**
   * @constructor
   * @param _
   */
  constructor(_) {
    this._ = _;
  }

  /**
   * combineData
   * @param {Object} itemOne
   * @param {Object} itemTwo
   * @param {Array} fieldsToCopy
   * @return {Object}
   */
  combineData(itemOne, itemTwo, fieldsToCopy) {
    this.fieldsMerged = [...fieldsToCopy, "imageFile"];

    const combinedData = {};

    Object.keys(itemOne).forEach((language) => {
      combinedData[language] = {
        ...itemOne[language],
        ...this._.pick(itemTwo[language], this.fieldsMerged),
      };
    });

    return combinedData;
  }

  /**
   * Split combined data into scheduledItem and CombinedItem
   * @param {Object} combinedData
   * @return {Object}
   */
  splitData(combinedData) {
    const splitData = {
      scheduledItem: {},
      contentItem: {},
    };

    Object.keys(combinedData).forEach((language) => {
      splitData.scheduledItem[language] = this._.omit(
        combinedData[language],
        this.fieldsMerged
      );
      splitData.contentItem[language] = this._.pick(
        combinedData[language],
        this.fieldsMerged
      );
    });

    return splitData;
  }
}

export default ModalMultiSourceService;
